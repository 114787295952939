import { KitchenService} from '../../../../../services/KitchenService/KitchenService';
import { ISaveBottomUnitData } from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import { ThreeBottomUnit } from '../ThreeBottomUnit';
import { ISaveCorpusData } from 'common-code/lib/interfaces/saveData/ISaveCorpusData';
import { CommonHelper } from 'common-code';
import { ThreeBottomSingleHoleHandleUnitCorpus } from '../../../details/ThreeCorpus/types/ThreeBottomSingleIntegratedHandleUnitCorpus';

export class ThreeBottomSingleHoleHandleUnit extends ThreeBottomUnit {
    corpus: ThreeBottomSingleHoleHandleUnitCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomSingleHoleHandleUnitCorpus {
        return new ThreeBottomSingleHoleHandleUnitCorpus(CommonHelper.deepCopy(corpusData), this)
    }
}