import React from 'react'
import { cnIntegratedHandles } from './IntegratedHandles'
import { cnModal, Modal } from '../../../../../components/Modal'
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle'
import { Button } from '../../../../../components/Button'
import { Select } from '../../../../../components/Select/Select'
import {
    IIntegratedHandle,
    integratedHandlesFunctionalTypes,
    integratedHandlesPositionTypes,
    integratedHandlesSideTypes,
} from './initionalIntegratedHandle'
import { Tumbler } from '../../../../../components/Tumbler/Tumbler'

interface IModalIntegratedHandlesProps {
    modal: boolean
    handleToggleModal: () => void
    newIntegratedHandle: IIntegratedHandle
    setNewIntegratedHandle: (newIntegratedHandle: IIntegratedHandle) => void
    handleSubmit: () => void
    keyValue: string
}

function ModalIntegratedHandles({
    modal,
    handleToggleModal,
    newIntegratedHandle,
    setNewIntegratedHandle,
    handleSubmit,
    keyValue,
}: IModalIntegratedHandlesProps) {
    const onInputChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setNewIntegratedHandle({
            ...newIntegratedHandle,
            [e.target.name]: e.target.value,
        })
    }
    const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewIntegratedHandle({
            ...newIntegratedHandle,
            [e.target.name]: e.target.checked,
        })
    }
    const {
        height,
        width,
        functionalType,
        intervalPointA,
        intervalPointAX,
        intervalPointAY,
        intervalPointAZ,
        intervalPointB,
        intervalPointBX,
        intervalPointBY,
        intervalPointBZ,
        isSideType,
        sideType,
        isPositionType,
        positionType,
        error,
    } = newIntegratedHandle

    const onFocus = () => {
        setNewIntegratedHandle({ ...newIntegratedHandle, error: '' })
    }
    const isEdit = keyValue.includes('edit')
    return (
        <Modal
            className={cnIntegratedHandles(cnModal())}
            visible={modal}
            callBack={handleToggleModal}
            key={keyValue}
        >
            <h4 className={cnIntegratedHandles('Header')}>
                {isEdit ? 'Редактирование ' : 'Добавление'} интегрированной
                ручки
            </h4>

            <div className={cnIntegratedHandles('Content')}>
                <div>
                    <label htmlFor="height">Высота интегрированной ручки</label>
                    <Textinput
                        className={cnIntegratedHandles('Textinput')}
                        value={height}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e)
                        }
                        name="height"
                        onFocus={onFocus}
                        type="number"
                    />
                </div>
                <div>
                    <label htmlFor="width">Ширина интегрированной ручки</label>
                    <Textinput
                        className={cnIntegratedHandles('Textinput')}
                        value={width}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e)
                        }
                        name="width"
                        onFocus={onFocus}
                        type="number"
                    />
                </div>

                <Tumbler
                    className={cnIntegratedHandles('Tumbler')}
                    checked={intervalPointA}
                    callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChecked(e)
                    }
                    label="Указать позицию A интегрированной ручки"
                    name="intervalPointA"
                />
                {intervalPointA && (
                    <>
                        <h4 className={cnIntegratedHandles('Title')}>
                            Позиция A
                        </h4>

                        <div className={cnIntegratedHandles('Box-Input')}>
                            <div>
                                <label htmlFor="intervalPointAX">
                                    Позиция A по горизонтали
                                </label>
                                <Textinput
                                    className={cnIntegratedHandles('Textinput')}
                                    value={intervalPointAX}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => onInputChange(e)}
                                    name="intervalPointAX"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>

                            <div>
                                <label htmlFor="intervalPointAY">
                                    Позиция A по вертикали
                                </label>
                                <Textinput
                                    className={cnIntegratedHandles('Textinput')}
                                    value={intervalPointAY}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => onInputChange(e)}
                                    name="intervalPointAY"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>

                            <div>
                                <label htmlFor="intervalPointAZ">
                                    Позиция A в глубину
                                </label>
                                <Textinput
                                    className={cnIntegratedHandles('Textinput')}
                                    value={intervalPointAZ}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => onInputChange(e)}
                                    name="intervalPointAZ"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                        </div>
                    </>
                )}
                <Tumbler
                    className={cnIntegratedHandles('Tumbler')}
                    checked={intervalPointB}
                    callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChecked(e)
                    }
                    label="Указать позицию B интегрированной ручки"
                    name="intervalPointB"
                />
                {intervalPointB && (
                    <>
                        <h4 className={cnIntegratedHandles('Title')}>
                            Позиция B
                        </h4>

                        <div className={cnIntegratedHandles('Box-Input')}>
                            <div>
                                <label htmlFor="intervalPointBX">
                                    Позиция B по горизонтали
                                </label>
                                <Textinput
                                    className={cnIntegratedHandles('Textinput')}
                                    value={intervalPointBX}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => onInputChange(e)}
                                    name="intervalPointBX"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>

                            <div>
                                <label htmlFor="intervalPointBX">
                                    Позиция B по вертикали
                                </label>
                                <Textinput
                                    className={cnIntegratedHandles('Textinput')}
                                    value={intervalPointBY}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => onInputChange(e)}
                                    name="intervalPointBY"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>

                            <div>
                                <label htmlFor="intervalPointBX">
                                    Позиция B в глубину
                                </label>
                                <Textinput
                                    className={cnIntegratedHandles('Textinput')}
                                    value={intervalPointBZ}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => onInputChange(e)}
                                    name="intervalPointBZ"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                        </div>
                    </>
                )}

                <div>
                    <label htmlFor="functionalType">Тип ручки</label>
                    <Select
                        className={cnIntegratedHandles('Select')}
                        placeholder={'Выберите значение'}
                        showPlaceholder={false}
                        options={integratedHandlesFunctionalTypes}
                        value={functionalType}
                        callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                            onInputChange({
                                ...e,
                                target: { ...e.target, name: 'functionalType' },
                            })
                        }
                    />
                </div>

                <Tumbler
                    className={cnIntegratedHandles('Tumbler')}
                    checked={isSideType}
                    callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChecked(e)
                    }
                    label="Указать направление сторыны модуля"
                    name="isSideType"
                />
                {isSideType && (
                    <div>
                        <label htmlFor="sideType">Направление сторыны модуля</label>
                        <Select
                            className={cnIntegratedHandles('Select')}
                            placeholder={'Выберите значение'}
                            showPlaceholder={false}
                            options={integratedHandlesSideTypes}
                            value={sideType}
                            callBack={(
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) =>
                                onInputChange({
                                    ...e,
                                    target: { ...e.target, name: 'sideType' },
                                })
                            }
                        />
                    </div>
                )}
                <Tumbler
                    className={cnIntegratedHandles('Tumbler')}
                    checked={isPositionType}
                    callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChecked(e)
                    }
                    label="Указать тип позиционирования"
                    name="isPositionType"
                />
                {isPositionType && (
                    <div>
                        <label htmlFor="positionType">Тип позиционирования</label>
                        <Select
                            className={cnIntegratedHandles('Select')}
                            placeholder={'Выберите значение'}
                            showPlaceholder={false}
                            options={integratedHandlesPositionTypes}
                            value={positionType}
                            callBack={(
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) =>
                                onInputChange({
                                    ...e,
                                    target: {
                                        ...e.target,
                                        name: 'positionType',
                                    },
                                })
                            }
                        />
                    </div>
                )}

                {error && <p className="text-danger">{error}</p>}
            </div>

            <Button
                className={cnIntegratedHandles('Button')}
                type={'button'}
                text={`${
                    isEdit ? 'Изменить' : 'Добавить'
                } интегрированную ручку`}
                mods={{
                    mods: {
                        bkg: 'white',
                        color: 'lightGreen',
                        border: 'lightGreen',
                        fill: 'lightGreen',
                    },
                    hover: {
                        mods: {
                            bkg: 'lightGreen',
                            color: 'white',
                            fill: 'white',
                        },
                    },
                }}
                callBack={handleSubmit}
            />
        </Modal>
    )
}

export default ModalIntegratedHandles
