import React, {MutableRefObject} from 'react';
import {cn} from '@bem-react/classname';
import './CreateObjectGroup.css';
import {CreateCommonObject} from '../CreateCommonObject/CreateCommonObject';
import {useTranslation} from 'react-i18next';
import {IClassNameProps} from '@bem-react/core';
import {ICreateObjectData} from '../../../../common-code/interfaces/createData/ICreateObjectData';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {ICreateObjectDomElements} from '../../../interfaces/ICreateObjectDomElements';
import {ICreateGroup} from '../../../../common-code/interfaces/createData/ICreateGroup';
import {Svg} from '../../components/Svg';
import {searchInCreateObjectData} from '../../../helpers/searchInCreateObjectData';
import ExportGroupModules from '../WizardEdit/ExportGroupModules/ExportGroupModules';

export const cnCreateObjectGroup = cn('CreateObjectGroup');

export interface ICreateObjectGroupProps extends IClassNameProps {
    groupData: ICreateGroup;
    onCreateObject: (objectData: ICreateObjectData, width: string | null, corpusWidth: string | null) => void;
    active?: boolean;
    service: KitchenService;
    onClick: () => void;
    createObjectDomElements: MutableRefObject<ICreateObjectDomElements>;
    searchText: string;
    type?: string;
}

export const CreateObjectGroup: React.FC<ICreateObjectGroupProps> =
    ({
         className,
         groupData,
         active,
         onClick,
         onCreateObject,
         service,
         createObjectDomElements,
         searchText,
         type
     }) => {

    const {t} = useTranslation();
    const items: ICreateObjectData[] = groupData.items.filter(value => !value.disable);
    const isEmpty: boolean = groupData.items.filter(value => value.disable || !searchInCreateObjectData(value, searchText)).length === groupData.items.length;
    const icon: string = active ? groupData.icon + '-active' : groupData.icon;

    if (isEmpty) {
        return null;
    }

    return (
        <div className={cnCreateObjectGroup({}, [className])} onClick={onClick}>
            <div className={cnCreateObjectGroup('Header')}>
                <Svg icon={icon} className={cnCreateObjectGroup('GroupIcon')}/>
                <div className={cnCreateObjectGroup('GroupName')}>
                    {t(groupData.title)}
                </div>
                {active || <Svg icon={'arrowDown'} className={cnCreateObjectGroup('Arrow')}/>}
            </div>
            {
                searchText.length > 0 || active ?
                    <div className={cnCreateObjectGroup('Items')}>
                        {items.map((objectData, index) => {
                            return (
                                <CreateCommonObject
                                    key={objectData.uid + index}
                                    className={cnCreateObjectGroup('Item')}
                                    objectData={objectData}
                                    createAction={onCreateObject}
                                    service={service}
                                    createObjects={createObjectDomElements}
                                    searchText={searchText}
                                    type={type}
                                />
                            );
                        })}
                        {type === "editModule" && <ExportGroupModules groupData={groupData}/>}                  
                    </div> : null
            }
        </div>
    );
}