import {IClassNameProps} from '@bem-react/core';
import React, {useState} from 'react';
import './FacadeMaterials.css';
import {cnFacadeMaterials} from "./index";
import {TObjectSelectListType} from '../../../types/TObjectSelectListType';
import {IFacadeMaterialData} from '../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {IFacadeData} from '../../../../common-code/interfaces/materials/IFacadeData';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {useTranslation} from 'react-i18next';
import {FacadeMaterialsChangeGroup} from './ChangeGroup/FacadeMaterials-ChangeGroup';
import {ObjectSelectItem} from '../../components/ObjectSelectItem/ObjectSelectItem';

export interface IFacadeMaterialsProps extends IClassNameProps {
    type?: TObjectSelectListType;
    listMaterialData?: IFacadeMaterialData[];
    materialData?: IFacadeMaterialData;
    listFacadeData?: IFacadeData[];
    title?: string;
    onSelectObject?: (objectData: IFacadeMaterialData) => void;
    service?: KitchenService;
}

export const FacadeMaterials: React.FC<IFacadeMaterialsProps> =
    ({
         className,
         listMaterialData,
         materialData,
         listFacadeData,
         title,
         onSelectObject,
         service
     }) => {
        const {t} = useTranslation();
        const [activeFacadeGroup, setActiveFacadeGroup] = useState<string | boolean>(materialData ? materialData.facade : false);

        const openFacadeMaterials = (id: string) => {
            setActiveFacadeGroup(id);
        }

        const closeFacadeGroup = () => {
            setActiveFacadeGroup(false);
        }

        if (!service) {
            return null;
        }

        return (
            <div className={cnFacadeMaterials({}, [className])}>
                <div className={cnFacadeMaterials('Header')}>
                    <div className={cnFacadeMaterials('Title')}>{`${t('Выберите')} ${title?.toLowerCase()}`}</div>
                    {!materialData || <FacadeMaterialsChangeGroup active={activeFacadeGroup !== false} openFacades={closeFacadeGroup}/>}
                </div>
                <div className={cnFacadeMaterials('Body')}>
                    <div className={cnFacadeMaterials('Items')}>
                        {
                            !activeFacadeGroup ?
                                listFacadeData?.map((facade) => {
                                    return (
                                        <ObjectSelectItem
                                            key={facade.id}
                                            className={cnFacadeMaterials('Item')}
                                            prevTitle={t('Коллекция') || undefined}
                                            title={facade.title}
                                            image={facade.image}
                                            color={facade.color}
                                            active={facade.id === materialData?.facade}
                                            callBack={() => {
                                                openFacadeMaterials(facade.id);
                                            }}
                                        />
                                    );
                                })
                                :
                                listMaterialData?.filter(material => material.facade === activeFacadeGroup)
                                    .map((material) => {
                                        if (material.disable) {
                                            return null;
                                        }
                                        if (material.isCustom && material.canChange === false) {
                                            return null;
                                        }
                                        return (
                                            <ObjectSelectItem
                                                key={material.id}
                                                className={cnFacadeMaterials('Item')}
                                                title={material.title}
                                                image={material.image}
                                                color={material.color}
                                                active={material.id === materialData?.id}
                                                callBack={() => {
                                                    if (onSelectObject) {
                                                        onSelectObject(material);
                                                    }
                                                }}
                                            />
                                        );
                                    })
                        }
                    </div>
                </div>
            </div>
        );
    }