import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_MENU} from "../constants";
import {AppState} from "../redux/AppStore";

export const useComponentVisible = (forMenu: string, excludesClass?: string[]) => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const isMenuOpen = useSelector((state:AppState) => state.ui[forMenu].visible);

    const setIsComponentVisible = (isMenuOpen: boolean) => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: forMenu,
                value: isMenuOpen
            },
        });
    };

    const handleHideDropdown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            if (excludesClass) {
                for (let excludeClass of excludesClass) {
                    const element: Element | null = document.querySelector(`.${excludeClass}`);
                    if (element && element.contains(event.target as Node)) {
                        setIsComponentVisible(isMenuOpen);
                        return;
                    }
                }
            }
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, setIsComponentVisible };
}