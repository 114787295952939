import React, {RefObject} from 'react';
import {IClassNameProps} from "@bem-react/core";
import {IProjectData} from '../../../../../common-code/interfaces/project/IProjectData';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {cnWizard} from '../Wizard';
import './Wizard-Kitchen.css'
import {KitchenParams} from '../../KitchenParams/KitchenParams';

export interface IWizardKitchenProps extends IClassNameProps {
    projectData: IProjectData;
    service: KitchenService;
    containerRef: RefObject<HTMLDivElement>;
}

export const WizardKitchen: React.FC<IWizardKitchenProps> =
    ({
         projectData,
         service,
         containerRef
     }) => {
        return (
            <div className={cnWizard('Kitchen', {})}>
                <KitchenParams
                    service={service}
                    projectData={projectData}
                    containerRef={containerRef}/>
            </div>
        );
    };