import {KitchenService} from '../KitchenService';
import {Color, DoubleSide, Group, Mesh, MeshBasicMaterial, Object3D, RepeatWrapping, Texture, TextureLoader} from 'three';
import {LoadingManager} from 'three/src/loaders/LoadingManager';
import {ITextureCache} from '../../../interfaces/ITextureCache';
import {IMaterialTextures} from '../../../interfaces/IMaterialTextures';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {
    FACADE_DETAIL_TYPE_BACK, FACADE_DETAIL_TYPE_CUSTOM, FACADE_DETAIL_TYPE_CUSTOM_GLASS,
    FACADE_DETAIL_TYPE_GLASS, FACADE_DETAIL_TYPE_GRID, FACADE_DETAIL_TYPE_INSERT,
    FACADE_DETAIL_TYPE_MAIN, FACADE_DETAIL_TYPE_PATINA, FACADE_DETAIL_TYPE_SECOND
} from '../../../../../common-code/constants';
import {ThreeFacade} from '../../../objects/threeD/details/ThreeFacade/ThreeFacade';
import {CommonObject} from '../../../objects/CommonObject/CommonObject';
import {ThreeEquipment} from '../../../objects/threeD/equipments/ThreeEquipment/ThreeEquipment';
import {ThreeHandle} from '../../../objects/threeD/details/ThreeHandle/ThreeHandle';
import {HIDE_TEXTURE_LOADING, LOADED_TEXTURES, SHOW_TEXTURE_LOADING} from '../../../../constants';
import {TCacheTexture} from '../../../types/TCacheTexture';
import {TCacheModel} from '../../../types/TCacheModel';
import {TLoadingModel} from '../../../types/TLoadingModel';
import {ITextureData} from '../../../../../common-code/interfaces/materials/ITextureData';
import {CommonHelper} from 'common-code';
import {
    ThreeConstructiveModel
} from '../../../objects/threeD/constructive/ThreeConstructiveModel/ThreeConstructiveModel';
import {IFacadeModelData} from '../../../../../common-code/interfaces/materials/IFacadeModelData';
import {
    OFFLINE_CONSTRUCTIVE,
    OFFLINE_EQUIPMENT,
    OFFLINE_FACADE,
    OFFLINE_HANDLE,
    OFFLINE_TEXTURE
} from '../../../constants';

export class CacheManager {
    service: KitchenService;
    textures: ITextureCache;
    textureLoader: TextureLoader;
    gltfLoader: GLTFLoader;
    cacheTextures: { [id: string]: TCacheTexture };
    cacheTextureImages: { [id: string]: Texture };
    cacheModels: { [id: string]: TCacheModel };
    loadingModels: { [id: string]: TLoadingModel };
    loading: boolean;
    sketchMaterials: {
        default: MeshBasicMaterial;
        room: MeshBasicMaterial;
    }

    constructor(service: KitchenService, manager?: LoadingManager) {
        this.service = service;
        this.textures = CacheManager.initTextures();
        this.textureLoader = new TextureLoader(manager);
        this.gltfLoader = new GLTFLoader(manager);
        this.cacheTextures = {};
        this.cacheTextureImages = {};
        this.cacheModels = {};
        this.loadingModels = {};
        this.sketchMaterials = CacheManager.initSketchMaterials();
        this.loading = false;
    }

    public remove() {
        this.cacheTextures = {};
        this.cacheTextureImages = {};
        this.cacheModels = {};
        this.loadingModels = {};
    }

    public getDefaultSketchViewMaterial():MeshBasicMaterial {
        return  this.sketchMaterials.default;
    }

    public getRoomSketchViewMaterial():MeshBasicMaterial {
        return  this.sketchMaterials.room;
    }

    public loadFacadeThreeModel(facade: ThreeFacade) {
        if (!facade.threeModelData) {
            return;
        }
        let object: Object3D;
        let modelPath: string;
        let cacheModelId: string;
        let threeModelData: IFacadeModelData;

        if (!facade.threeModelData) {
            return;
        }
        threeModelData = facade.threeModelData;
        cacheModelId = 'facade' + threeModelData.id;
        this.setLoadingModels('facade', threeModelData.id, facade);
        if (this.cacheModels[cacheModelId] &&
            this.cacheModels[cacheModelId].isLoad) {
            this.clearLoadingModel('facade', threeModelData.id, facade);
            return;
        }
        modelPath = this.service.isOffline() ? OFFLINE_FACADE :
            '/static-files/facade/' + threeModelData.id.substring(0, 3) + '/' +
            threeModelData.id + '.' + threeModelData.ext;
        console.log('modelPath', modelPath);
        this.cacheModels[cacheModelId] = {isLoad: false, details: []};
        this.startTextureLoading();
        this.gltfLoader.load(modelPath, (gltf) => {
            let objects: Object3D[] = [];
            let child: Object3D;
            for (object of gltf.scene.children) {
                // facade test shadows
                object.receiveShadow = true
                object.castShadow = true

                // Дизайнер назвал все Mesh с большой буквы
                object.name = this.setFacadeDetailName(object.name.toLocaleLowerCase());
                if (object instanceof Group) {
                    for (child of object.children) {
                        child.name = object.name;
                                            }
                }
                objects.push(object);
            }
            this.cacheModels[cacheModelId].isLoad = true;
            this.cacheModels[cacheModelId].details = objects;
            this.clearLoadingModel('facade', threeModelData.id, facade);
        }, () => {},
            () => {
                this.clearLoadingModel('facade', threeModelData.id, facade);
            });
    }

    public loadEquipmentThreeModel(equipment: ThreeEquipment) {
        let object: Object3D;
        let modelPath: string;
        let cacheModelId: string;

        cacheModelId = 'equipment' + equipment.threeModelData.id;
        this.setLoadingModels('equipment', equipment.threeModelData.id, equipment);
        if (this.cacheModels[cacheModelId] &&
            this.cacheModels[cacheModelId].isLoad) {
            this.clearLoadingModel('equipment', equipment.threeModelData.id, equipment);
            return;
        }

        modelPath = this.service.isOffline() ? OFFLINE_EQUIPMENT :
            '/static-files/equipmentModel/' + equipment.threeModelData.id.substring(0, 3) + '/' +
            equipment.threeModelData.id + '.' + equipment.threeModelData.ext;
        this.cacheModels[cacheModelId] = {isLoad: false, details: []};
        this.startTextureLoading();
        this.gltfLoader.load(modelPath, (gltf) => {
            let objects: Object3D[] = [];
            for (object of gltf.scene.children) {
                object.castShadow = true;
                object.receiveShadow = true;
                objects.push(object);
            }
            this.cacheModels[cacheModelId].isLoad = true;
            this.cacheModels[cacheModelId].details = objects;
            this.clearLoadingModel('equipment', equipment.threeModelData.id, equipment);
        }, () => {},
            () => {
                this.clearLoadingModel('equipment', equipment.threeModelData.id, equipment);
            });
    }

    public loadConstructiveThreeModel(constructive: ThreeConstructiveModel) {
        let object: Object3D;
        let modelPath: string;
        let cacheModelId: string;

        cacheModelId = 'constructive' + constructive.threeModelData.id;
        this.setLoadingModels('constructive', constructive.threeModelData.id, constructive);
        if (this.cacheModels[cacheModelId] &&
            this.cacheModels[cacheModelId].isLoad) {
            this.clearLoadingModel('constructive', constructive.threeModelData.id, constructive);
            return;
        }

        modelPath = this.service.isOffline() ? OFFLINE_CONSTRUCTIVE :
            '/static-files/constructive/' + constructive.threeModelData.id.substring(0, 3) + '/' +
            constructive.threeModelData.id + '.' + constructive.threeModelData.ext;
        this.cacheModels[cacheModelId] = {isLoad: false, details: []};
        this.startTextureLoading();
        this.gltfLoader.load(modelPath, (gltf) => {
            let objects: Object3D[] = [];
            for (object of gltf.scene.children) {
                objects.push(object);
            }
            this.cacheModels[cacheModelId].isLoad = true;
            this.cacheModels[cacheModelId].details = objects;
            this.clearLoadingModel('constructive', constructive.threeModelData.id, constructive);
        }, () => {},
            () => {
                this.clearLoadingModel('constructive', constructive.threeModelData.id, constructive);
            });
    }

    public loadHandleThreeModel(handle: ThreeHandle) {
        let object: Object3D;
        let modelPath: string;
        let cacheModelId: string;

        cacheModelId = 'handle' + handle.threeModelData.id;
        this.setLoadingModels('handle', handle.threeModelData.id, handle);
        if (this.cacheModels[cacheModelId] &&
            this.cacheModels[cacheModelId].isLoad) {
            this.clearLoadingModel('handle', handle.threeModelData.id, handle);
            return;
        }

        modelPath = this.getHandleModelLoadPath(handle);
        this.cacheModels[cacheModelId] = {isLoad: false, details: []};
        this.startTextureLoading();
        this.gltfLoader.load(modelPath, (gltf) => {
                let objects: Object3D[] = [];
                for (object of gltf.scene.children) {
                    // Mesh
                    if(object instanceof Mesh){

                        object.material.color = new Color(0Xafc9e1);
                        object.material.metalness = 1;
                        object.material.roughness = 0.4;
                        object.castShadow = true;
                        object.receiveShadow = true;
                    }
                    objects.push(object);
                }
                this.cacheModels[cacheModelId].isLoad = true;
                this.cacheModels[cacheModelId].details = objects;
                this.clearLoadingModel('handle', handle.threeModelData.id, handle);
            }, () => {},
            () => {
                this.clearLoadingModel('handle', handle.threeModelData.id, handle);
            });
    }

    public isLoaded(trySteps: number = 100): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const interval = setInterval(() => {
                if (this.checkIsLoaded()) {
                    clearInterval(interval);
                    resolve(true);
                } else if (trySteps < 1) {
                    clearInterval(interval);
                    reject();
                }
                trySteps--;
            }, 100);
        });
    }

    protected getHandleModelLoadPath(handle: ThreeHandle): string {
        if (this.service.isOffline()) {
            return OFFLINE_HANDLE;
        }
        if (handle.handleData.offerId) {
            return '/static-files/handleModel/' + handle.threeModelData.id.substring(0, 3) + '/' +
                handle.threeModelData.id + '.' + handle.threeModelData.ext;
        }

        return '/static-files/handle/' + handle.handleData.id + '/' +
            handle.threeModelData.id + '.' + handle.threeModelData.ext;
    }

    protected checkIsLoaded(): boolean {
        for (let index in this.cacheModels) {
            if (!this.cacheModels[index].isLoad) {
                return false;
            }
        }
        for (let index in this.cacheTextures) {
            if (!this.cacheTextures[index].isLoad) {
                return false;
            }
        }

        return true;
    }

    protected setLoadingModels(type: string, id: string, parent: CommonObject) {
        if (!this.loadingModels[type + id]) {
            this.loadingModels[type + id] = {type: type, parents: [parent]};
        } else {
            this.loadingModels[type + id].parents.push(parent);
        }
    }

    protected clearLoadingModel(type: string, id: string, parent: CommonObject) {
        let index: string;
        let parentItem: CommonObject;

        if (!this.loadingModels[type + id]) {
            console.warn('clearLoadingModel not found ', type + id);
            return;
        }
        for (index in this.loadingModels[type + id].parents) {
            parentItem = this.loadingModels[type + id].parents[index];
            if (parentItem.getId() === parent.getId()) {
                this.loadingModels[type + id].parents.splice(+index, 1);
            }
        }
        parent.setLoadModel(this.loadingModels[type + id].type, this.cacheModels[type + id].details);
        if (this.loadingModels[type + id].parents.length <= 0) {
            delete this.loadingModels[type + id];
        }
        if (Object.keys(this.loadingModels).length <= 0) {
            this.service.sendToRedux({type: LOADED_TEXTURES})
        }
    }

    protected setFacadeDetailName(name: string) {
        switch (name) {
            case FACADE_DETAIL_TYPE_GLASS:
                return FACADE_DETAIL_TYPE_GLASS;
            case FACADE_DETAIL_TYPE_CUSTOM_GLASS:
                return FACADE_DETAIL_TYPE_CUSTOM_GLASS;
            case FACADE_DETAIL_TYPE_BACK:
                return FACADE_DETAIL_TYPE_BACK;
            case FACADE_DETAIL_TYPE_PATINA:
                return FACADE_DETAIL_TYPE_PATINA;
            case FACADE_DETAIL_TYPE_SECOND:
                return FACADE_DETAIL_TYPE_SECOND;
            case FACADE_DETAIL_TYPE_INSERT:
                return FACADE_DETAIL_TYPE_INSERT;
            case FACADE_DETAIL_TYPE_GRID:
                return FACADE_DETAIL_TYPE_GRID;
            case FACADE_DETAIL_TYPE_CUSTOM:
                return FACADE_DETAIL_TYPE_CUSTOM;
            case FACADE_DETAIL_TYPE_MAIN:
            default:
                return FACADE_DETAIL_TYPE_MAIN;
        }
    }

    public loadMaterialTextures(materialId: string, textures?: ITextureData[]): IMaterialTextures {
        let textureData: ITextureData;
        let resultTextures: IMaterialTextures = {};
        let needLoadTextures: boolean = false;
        let cacheTexture: TCacheTexture;

        if (textures && textures.length > 0) {
            for (textureData of textures) {
                cacheTexture = this.loadTexture(materialId, textureData);
                resultTextures[textureData.type] = cacheTexture.texture;
                if (!cacheTexture.isLoad) {
                    needLoadTextures = true;
                }
            }
        }

        if (needLoadTextures) {
            this.startTextureLoading();
        }

        return resultTextures;
    }

    public loadTexture(materialId: string, textureData: ITextureData): TCacheTexture {
        let textureId: string;
        let textureImage: {texture: Texture, isLoad: boolean};

        textureId = CommonHelper.md5({
            id: materialId,
            type: textureData.type,
            repeat: textureData.repeat,
            offset: textureData.offset
        });
        if (this.cacheTextures[textureId]) {
            return this.cacheTextures[textureId];
        }
        textureImage = this.loadTextureImage(materialId, textureData, textureId);
        this.cacheTextures[textureId] = {
            isLoad: textureImage.isLoad,
            texture: textureImage.texture
        };

        return this.cacheTextures[textureId];
    }

    protected loadTextureImage(materialId: string, textureData: ITextureData, textureId: string): {texture: Texture, isLoad: boolean} {
        let textureImageId: string;
        let texture: Texture;
        let imagePath: string;

        textureImageId = CommonHelper.md5({
            id: materialId,
            type: textureData.type
        });
        if (this.cacheTextureImages[textureImageId]) {
            if (textureData.repeat.x !== 1 || textureData.repeat.y !== 1 || textureData.offset || textureData.offset) {
                texture = this.cacheTextureImages[textureImageId].clone();
                texture.wrapT = RepeatWrapping;
                texture.wrapS = RepeatWrapping;
                texture.repeat.set(textureData.repeat.x, textureData.repeat.y);
                if (textureData.offset) {
                    texture.offset.set(textureData.offset.x, textureData.offset.y);
                }
                texture.needsUpdate = true;

                return {texture: texture, isLoad: true};
            }
            return {texture: this.cacheTextureImages[textureImageId], isLoad: true};
        }
        imagePath = this.service.isOffline() ? OFFLINE_TEXTURE : textureData.path
        this.cacheTextureImages[textureImageId] = this.textureLoader.load(imagePath, () => {
            this.cacheTextures[textureId].texture.wrapT = RepeatWrapping;
            this.cacheTextures[textureId].texture.wrapS = RepeatWrapping;
            this.cacheTextures[textureId].texture.repeat.set(textureData.repeat.x, textureData.repeat.y);
            if (textureData.offset) {
                this.cacheTextures[textureId].texture.offset.set(textureData.offset.x, textureData.offset.y);
            }
            this.cacheTextures[textureId].isLoad = true;
            this.cacheTextures[textureId].texture.needsUpdate = true;
        });

        return {texture: this.cacheTextureImages[textureImageId], isLoad: false};
    }

    public startTextureLoading() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.showLoadingSpin();
        this.processTextureLoading();
    }

    public stopTextureLoading() {
        this.loading = false;
        this.service.sendToRedux({type: LOADED_TEXTURES})
        this.hideLoadingSpin();
    }

    protected showLoadingSpin() {
        this.service.sendToRedux({type: SHOW_TEXTURE_LOADING})
    }

    protected hideLoadingSpin() {
        this.service.sendToRedux({type: HIDE_TEXTURE_LOADING})
    }

    protected processTextureLoading() {
        if (this.checkIsLoaded()) {
            setTimeout(() => {
                this.stopTextureLoading();
            }, 100);
        } else {
            setTimeout(() => {
                this.processTextureLoading();
            }, 100);
        }
    }

    private static initTextures(): ITextureCache {
        return {
            room: {
                floor: {map: null},
                wall: {map: null}
            }
        };
    }

    private static initSketchMaterials() {
        return {
            // default: new MeshBasicMaterial({color: '#ede8c2', transparent: true, opacity: 0.5}),
            // default: new MeshBasicMaterial({color: '#ede8c2'}),
            default: new MeshBasicMaterial({
                color: 0xffdd7b,
                side: DoubleSide,
                transparent: true,
                opacity: 0.6,
                precision: 'highp'
            }),
            room: new MeshBasicMaterial({color: '#cecece'})
        }
    }
}