import axios from "axios";

export const fetchOptions = async (path: string): Promise<Record<string, string> | undefined> => {
  const response = await axios.get<Record<string, string>>(`/api/entity/${path}`);

  return response.data;
};

export const saveOptions = async (hingeFurnitureTypes: Record<string, string>, path: string): Promise<Record<string, string> | undefined> => {
  const response = await axios.post<Record<string, string>>(
    `/api/entity/${path}`,
    JSON.stringify(hingeFurnitureTypes),
    {
      headers: { 'Content-Type': 'application/json' },
    },
  );

  return response.data;
};
