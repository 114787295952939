import {
    SIDE_TYPE_ANGLE,
    SIDE_TYPE_ARC,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_DEFAULT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_NONE,
    SIDE_TYPE_RIGHT,
    SIDE_TYPE_TOP,
    T_I_HANDLE_FUNCTIONAL_TYPE_BETWEEN_BOXES,
    T_I_HANDLE_FUNCTIONAL_TYPE_DEFAULT,
} from 'common-code/lib/constants'
import { IDetailPosition } from 'common-code/lib/interfaces/geometry/IDetailPosition'
import { ISaveIntegratedHandleData } from 'common-code/lib/interfaces/saveData/ISaveIntegratedHandleData'
import { TIntegratedHandleFunctionalType } from 'common-code/lib/types/materials/TIntegratedHandleFunctionalType'
import { TDirectionSideType } from 'common-code/lib/types/TDirectionSideType'
import { TPositionSideType } from 'common-code/lib/types/TPositionSideType'

export interface IIntegratedHandle {
    id: number
    height: string
    width: string
    functionalType: string
    intervalPointA: boolean
    intervalPointAX: string
    intervalPointAY: string
    intervalPointAZ: string
    intervalPointB: boolean
    intervalPointBX: string
    intervalPointBY: string
    intervalPointBZ: string
    isSideType: boolean
    sideType: string
    isPositionType: boolean
    positionType: string
    error: string
}

export const initionalIntegratedHandle = (
    item: ISaveIntegratedHandleData | null
): IIntegratedHandle =>
    item
        ? {
              id: item.id,
              height: item.height.toString(),
              width: item.width.toString(),
              functionalType: item.functionalType,
              intervalPointA:
                  !!item.interval?.pointA?.x ||
                  !!item.interval?.pointA?.y ||
                  !!item.interval?.pointA?.z,
              intervalPointAX: item.interval?.pointA?.x?.toString() || '0',
              intervalPointAY: item.interval?.pointA?.y?.toString() || '0',
              intervalPointAZ: item.interval?.pointA?.z?.toString() || '0',
              intervalPointB:
                  !!item.interval?.pointB?.x ||
                  !!item.interval?.pointB?.y ||
                  !!item.interval?.pointB?.z,
              intervalPointBX: item.interval?.pointB?.x?.toString() || '0',
              intervalPointBY: item.interval?.pointB?.y?.toString() || '0',
              intervalPointBZ: item.interval?.pointB?.z?.toString() || '0',
              isSideType: !!item.sideType,
              sideType: item.sideType || 'default',
              isPositionType: !!item.positionType,
              positionType: item.positionType || 'none',
              error: '',
          }
        : {
              id: 0,
              height: '100',
              width: '100',
              functionalType: 'default',
              intervalPointA: true,
              intervalPointAX: '0',
              intervalPointAY: '0',
              intervalPointAZ: '0',
              intervalPointB: false,
              intervalPointBX: '0',
              intervalPointBY: '0',
              intervalPointBZ: '0',
              isSideType: false,
              sideType: 'default',
              isPositionType: false,
              positionType: 'none',
              error: '',
          }

export const integratedHandlesFunctionalTypes = [
    {
        value: T_I_HANDLE_FUNCTIONAL_TYPE_DEFAULT,
        content: T_I_HANDLE_FUNCTIONAL_TYPE_DEFAULT,
    },
    {
        value: T_I_HANDLE_FUNCTIONAL_TYPE_BETWEEN_BOXES,
        content: T_I_HANDLE_FUNCTIONAL_TYPE_BETWEEN_BOXES,
    },
]

export const integratedHandlesSideTypes = [
    { value: SIDE_TYPE_LEFT, content: SIDE_TYPE_LEFT },
    {
        value: SIDE_TYPE_RIGHT,
        content: SIDE_TYPE_RIGHT,
    },
    {
        value: SIDE_TYPE_DEFAULT,
        content: SIDE_TYPE_DEFAULT,
    },
    {
        value: SIDE_TYPE_TOP,
        content: SIDE_TYPE_TOP,
    },
    {
        value: SIDE_TYPE_BOTTOM,
        content: SIDE_TYPE_BOTTOM,
    },
]

export const integratedHandlesPositionTypes = [
    { value: SIDE_TYPE_NONE, content: SIDE_TYPE_NONE },
    { value: SIDE_TYPE_ARC, content: SIDE_TYPE_ARC },
    { value: SIDE_TYPE_ANGLE, content: SIDE_TYPE_ANGLE },
]
export const validateIntegratedHandle = (
    newIntegratedHandle: IIntegratedHandle
) => {
    const { height, width } = newIntegratedHandle
    if (!height || height === '0') {
        return 'Высота должна быть больше нуля'
    }
    if(Number(height) > 1600){
        return 'Высота не должна быть больше 1600'
    }
    if (!width || width === '0') {
        return 'Ширина должна быть больше нуля'
    }
    if(Number(width) > 2000){
        return 'Ширина не должна быть больше 2000'
    }

    return ''
}

export const createIntegratedHandle = (
    newIntegratedHandle: IIntegratedHandle
): ISaveIntegratedHandleData => {
    const {
        height,
        width,
        functionalType,
        intervalPointA,
        intervalPointAX,
        intervalPointAY,
        intervalPointAZ,
        intervalPointB,
        intervalPointBX,
        intervalPointBY,
        intervalPointBZ,
        isSideType,
        sideType,
        isPositionType,
        positionType,
    } = newIntegratedHandle
    const handle = {
        id: 0,
        height: Number(height),
        width: Number(width),
        functionalType: functionalType as TIntegratedHandleFunctionalType,
    } as ISaveIntegratedHandleData
    if (intervalPointA || intervalPointB) {
        const pointA = {} as IDetailPosition
        if (intervalPointA) {
            if (intervalPointAX) pointA.x = Number(intervalPointAX)
            if (intervalPointAY) pointA.y = Number(intervalPointAY)
            if (intervalPointAZ) pointA.z = Number(intervalPointAZ)
        }
        const pointB = {} as IDetailPosition
        if (intervalPointB) {
            if (intervalPointBX) pointB.x = Number(intervalPointBX)
            if (intervalPointBY) pointB.y = Number(intervalPointBY)
            if (intervalPointBZ) pointB.z = Number(intervalPointBZ)
        }
        handle.interval = {
            pointA,
            pointB,
        }
    }

    if (isSideType) handle.sideType = sideType as TDirectionSideType
    if (isPositionType) handle.positionType = positionType as TPositionSideType

    return handle
}
