import {composeU, IClassNameProps} from "@bem-react/core";
import React, {ChangeEvent} from "react";
import {withInputPhone} from "./_type/Input_type_phone";
import {withInputText} from "./_type/Input_type_text";
import './Input.css';
import {withInputTextarea} from "./_type/Input_type_textarea";
import {TInputType} from "../../../types/TInputType";
import {cnInput} from "./index";
import {withInputPassword} from "./_type/Input_type_password";

export interface IInputProps<T> extends IClassNameProps {
    type?: TInputType;
    value?: string;
    name?: string;
    placeholder?: string;
    onChange?: (event: ChangeEvent<T>) => void;
    disabled?: boolean;
    maxLength?: number;
}

export const InputBase: React.FC<IInputProps<HTMLInputElement>> = ({className, type}) => {
    return (
        <div className={cnInput({}, [className])}>
            Replace Input {type}
        </div>
    );
};

export const Input = composeU(
    withInputPhone,
    withInputText,
    withInputTextarea,
    withInputPassword
)(InputBase);