import React, {useState} from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_RADIOBUTTON} from '../../../../../common-code/constants';
import {IObjectOptionProps} from '../ObjectOption';
import {useTranslation} from 'react-i18next';
import {IOptionRadioButton} from '../../../../../common-code/interfaces/option/IOptionRadioButton';
import {TSelectItem} from '../../../../../common-code/types/TSelectItem';
import {cnObjectOption} from '../index';
import {RadioButton} from '../../RadioButton/RadioButton';

export interface IObjectOptionTypeRadioButtonProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_RADIOBUTTON;
    option?: IOptionRadioButton;
    defaultValue?: string;
}

const ObjectOptionTypeRadioButton: React.FC<IObjectOptionTypeRadioButtonProps> =
    ({
         option,
         group,
         className,
         onChange,
         defaultValue,
     }) => {

        const [value, setValue] = useState<string>(defaultValue !== undefined ? '' + defaultValue : (option && option.defaultValue ? option.defaultValue : ''));
        const {t} = useTranslation();

        if (!option || !onChange) {
            return null;
        }

        const getOptions = (radioButtonItems: TSelectItem[]): TSelectItem[] => {
            let isChangeValue: boolean = false;
            let options: TSelectItem[] = [];
            let optionItem: TSelectItem;
            let radioButtonItem: TSelectItem;

            for (radioButtonItem of radioButtonItems) {
                if (radioButtonItem.disabled && radioButtonItem.id === value) {
                    isChangeValue = true;
                }
                options.push({
                    id: radioButtonItem.id,
                    title: radioButtonItem.title,
                    disabled: radioButtonItem.disabled
                });
            }
            if (isChangeValue) {
                for (optionItem of options) {
                    if (!optionItem.disabled) {
                        setValue(optionItem.id);
                        isChangeValue = false;
                        break;
                    }
                }
                if (isChangeValue) {
                    setValue('');
                }
            }

            return options;
        };

        const onChangeRadioButton = (value: string) => {
            onChange(option.id, value, group ? group.id : undefined);
            setValue(value);
        }

        return (
            <div className={cnObjectOption({}, [className])}>
                <div className={cnObjectOption('Title', {type: OPTION_TYPE_RADIOBUTTON}, [className+'Title'])}>
                    {t(option.title)}
                </div>
                <div className={cnObjectOption('Value', [className+'Value'])}>
                    <RadioButton
                        type={option.radioType}
                        className={cnObjectOption('RadioButton')}
                        options={getOptions(option.items)}
                        value={value}
                        callBack={onChangeRadioButton}
                    />
                </div>
            </div>

        );
    }

export const withObjectOptionTypeRadioButton = withBemMod<IObjectOptionTypeRadioButtonProps, IObjectOptionProps>(
        cnObjectOption(),
        {type: OPTION_TYPE_RADIOBUTTON},
        () => ObjectOptionTypeRadioButton
    )
;