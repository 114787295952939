import {Box3} from 'three';
import {TPoint2D} from '../../../../common-code/types/TPoint2D';
import {ISaveRoomData} from '../../../../common-code/interfaces/saveData/ISaveRoomData';
import {IThreeService} from '../../interfaces/IThreeService';
import {CommonHelper} from 'common-code';
import {CHANGE_ROOM_DATA} from '../../../constants';
import {ISavePoint2DData} from '../../../../common-code/interfaces/saveData/ISavePoint2DData';
import {ISaveWallData} from '../../../../common-code/interfaces/saveData/ISaveWallData';
import {ISaveConstructiveData} from '../../../../common-code/interfaces/saveData/ISaveConstructiveData';
import {ISaveFloorData} from '../../../../common-code/interfaces/saveData/ISaveFloorData';
import {ISaveRoofData} from '../../../../common-code/interfaces/saveData/ISaveRoofData';
import {Wall} from '../Wall/Wall';
import {IHistoryRoomState} from '../../interfaces/history/IHistoryRoomState';
import {HISTORY_STATE_TYPE_ROOM} from '../../constants';

export class Room {
    protected roomData: ISaveRoomData;
    protected service: IThreeService;
    protected walls: Wall[];
    protected visible: boolean;
    protected deleted: boolean;

    constructor(roomData: ISaveRoomData, roomService: IThreeService) {
        this.roomData = roomData;
        this.service = roomService;
        this.walls = [];
        this.visible = true;
        this.deleted = false;
    }

    public isChangeData(roomData: ISaveRoomData): boolean {
        return (!CommonHelper.deepCompare(this.getSaveData().sizes, roomData.sizes)) ||
            (this.getSaveData().id !== roomData.id);
    }

    public createView() {
        this.service.sendToRedux({
            type: CHANGE_ROOM_DATA,
            payload: CommonHelper.deepCopy(this.roomData)
        });
    }

    public rebuild(newData: ISaveRoomData): IHistoryRoomState | undefined {
        const oldData: ISaveRoomData = this.getSaveData();

        if (!this.setSaveData(newData)) {
            return;
        }
        this.removeChildren();
        this.initState();
        this.createView();
        return {
            type: HISTORY_STATE_TYPE_ROOM,
            data: {
                oldData: oldData,
                newData: this.getSaveData()
            }
        }
    }

    public initState() {

    }

    public getId(): string {
        return this.roomData.id;
    }

    public getHeight() {
        return +this.roomData.sizes.height;
    }

    public getSaveData(isTemplate?: boolean): ISaveRoomData {
        return {
            id: this.roomData.id,
            version: this.roomData.version,
            name: this.roomData.name,
            sizes: {...this.roomData.sizes},
            points: this.getPointsSaveData(),
            walls: this.getWallsSaveData(),
            constructive: this.getConstructiveSaveData(),
            floor: this.getFloorSaveData(),
            roof: this.getRoofSaveData(),
            template: isTemplate !== undefined ? isTemplate : this.roomData.template
        };
    }

    public setSaveData(roomData: ISaveRoomData): ISaveRoomData | undefined {
        if (!this.isChangeData(roomData)) {
            return;
        }
        this.roomData = roomData;

        return this.roomData;
    }

    public isVisible(): boolean {
        return this.visible;
    }

    public getWalls(): Wall[] {
        return this.walls;
    }

    public getPolygon(): TPoint2D[] {
        throw new Error('replace-Room-getPolygon');
    }

    public getBox(): Box3 {
        throw new Error('replace-Room-getBox');
    }

    public isDeleted(): boolean {
        return this.deleted;
    }

    protected removeChildren() {
        this.deleted = true;
    }

    protected getPointsSaveData(): ISavePoint2DData[] {
        throw new Error('replace-Room-getPointsSaveData');
    }

    protected getWallsSaveData(): ISaveWallData[] {
        throw new Error('replace-Room-getWallsSaveData');
    }

    protected getConstructiveSaveData(): ISaveConstructiveData[] {
        throw new Error('replace-Room-getConstructiveSaveData');
    }

    protected getFloorSaveData(): ISaveFloorData {
        throw new Error('replace-Room-getFloorSaveData');
    }

    protected getRoofSaveData(): ISaveRoofData {
        throw new Error('replace-Room-getRoofSaveData');
    }
}