import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {useState} from "react";
import './ObjectSelectItem.css';
import {useTranslation} from "react-i18next";
import {ObjectSelectItemHeight} from './Height/ObjectSelectItem-Height';
import {ObjectSelectItemDepths} from './Depths/ObjectSelectItem-Depths';
import {ObjectSelectItemLengths} from './Lengths/ObjectSelectItem-Lengths';
import {Svg} from '../Svg';
import {Button, cnButton} from '../Button';
import {IMaterialData} from '../../../../common-code/interfaces/materials/IMaterialData';

export const cnObjectSelectItem = cn('ObjectSelectItem');

export interface IObjectSelectItemProps extends IClassNameProps {
    prevTitle?: string;
    title: string;
    image?: string;
    color?: string;
    active?: boolean;
    disabled?: boolean;
    buttonData?: {selectText?: string; text?: string}
    detailData?: IMaterialData;
    callBack: () => void;
    onChangeDepth?: (material: IMaterialData, value: number) => void;
}

export const ObjectSelectItem: React.FC<IObjectSelectItemProps> =
    ({
         className,
         prevTitle,
         title,
         image,
         color,
         active,
         disabled,
         callBack,
         detailData,
         buttonData,
         onChangeDepth
    }) => {
    const {t} = useTranslation();
    const [hoverItem, setHoverItem] = useState<boolean>(false);

    const getSelectButtonText = (): string => {
        if (buttonData && buttonData.selectText) {
            return buttonData.selectText;
        }
        return t('Выбрано');
    };

    const getButtonText = (): string => {
        if (buttonData && buttonData.text) {
            return buttonData.text;
        }

        return t('Выбрать')
    };

    return (
        <div
            className={cnObjectSelectItem({hover: hoverItem, active: !disabled && active, animated: true}, [className])}
            onMouseEnter={() => {setHoverItem(true)}}
            onMouseLeave={() => {setHoverItem(false)}}
        >
            <div className={cnObjectSelectItem('Wrapper')}>
                <div className={cnObjectSelectItem('Images')}>
                    {image && image.length ? <img className={cnObjectSelectItem('Image')} src={image} alt={title}/> :
                        color && color.length ? <div className={cnObjectSelectItem('Color')} style={{backgroundColor: color}}/> :
                            <Svg className={cnObjectSelectItem('Icon')} icon={'no-photo'} label={title}/>
                    }
                </div>
                <div className={cnObjectSelectItem('Name')}>{prevTitle ? `${prevTitle} ${title}` : title}</div>
                <ObjectSelectItemLengths lengths={detailData?.widths} className={cnObjectSelectItem('Note')}/>
                <ObjectSelectItemHeight height={detailData?.depth} className={cnObjectSelectItem('Note')}/>
                <div className={cnObjectSelectItem('Buttons')}>
                    <ObjectSelectItemDepths onChangeDepth={onChangeDepth} detailData={detailData} className={cnObjectSelectItem('Heights')}/>
                    <Button
                        className={cnObjectSelectItem(cnButton())}
                        type={'button'}
                        leftIcon={{path: 'change', width: 20, height: 20, marginRight: 10}}
                        text={!disabled && active ? getSelectButtonText() : getButtonText()}
                        disabled={disabled}
                        mods={{
                            mods: {bkg: 'white', color: 'lightGreen', fill: 'lightGreen', border: 'lightGreen'},
                            active: {state: active || false, mods: {bkg: 'lightGreen', color: 'white', fill: 'white', border: 'green'}},
                            hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white', border: 'green'}},
                            disabled: {state: disabled || false, mods: {bkg: 'lightGray', color: 'lightBlack', cursor: 'default'}}
                        }}
                        callBack={!disabled ? callBack : () => {}}
                    />
                </div>

            </div>
        </div>
    );
}