import {ThreeBuiltInEquipment} from '../ThreeBuiltInEquipment';
import {Box3, Vector3} from 'three';

export class ThreeMountedExtractEquipment extends ThreeBuiltInEquipment {

    protected initPosition(): Vector3 {
        let cover: Box3;
        let initPosition: Vector3;

        initPosition = new Vector3();

        cover = this.unit.getCoverBox()
        initPosition.y = cover.min.y;

        return initPosition;
    }
}