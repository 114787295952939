import React from 'react';
import { cnCreateForm } from '../../../FormModule';
import { Button } from '../../../../../../components/Button';
import { Modal, cnModal } from '../../../../../../components/Modal';
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle';
import { Tumbler } from '../../../../../../components/Tumbler/Tumbler';
import { INewBox } from './initialBox';

function ModalBoxes({ 
        handleToggleModal,
        setNewBox,
        newBox,
        handleSubmit,
        keyValue
    }: {
        handleToggleModal: () => void,
        setNewBox: (value: React.SetStateAction<INewBox>) => void,
        newBox: INewBox,
        handleSubmit: () => void,
        keyValue: string
    }) {

    const { length, height, depth, initPositionX, initPositionY, thickness, bottomThickness, railGap, frontSide,  error, modal } = newBox;

    const onFocus = () => {
        setNewBox({ ...newBox, error: ''});
    }
    const onInputChange = (e:  React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        setNewBox({ ...newBox, [e.target.name]: e.target.value, error: ''});
    };

    const onChecked = (e:React.ChangeEvent<HTMLInputElement>) => {
        setNewBox({ ...newBox, [e.target.name]: e.target.checked });
    }

    const isEdit = keyValue.includes('edit');

  return (
        <Modal
            className={cnCreateForm(cnModal())}
            visible={modal}
            callBack={handleToggleModal}
            key={keyValue}
        >

                <h4 className={cnCreateForm('Header')}>{isEdit ? 'Редактировать ' : 'Добавить'} ящик</h4>

                <div className={cnCreateForm('Content')}>

                        <div className={cnCreateForm('Box-Input')}>
                            <div>
                                <label htmlFor="length">Ширина ящика в %</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={length}
                                    maxLength={3}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="length"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                            <div>
                                <label htmlFor="height">Высота ящика в мм</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={height}
                                    maxLength={3}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="height"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                            <div>
                                <label htmlFor="depth">Глубина ящика в %</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={depth}
                                    maxLength={3}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="depth"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                        </div>

                        <div className={cnCreateForm('Box-Input')}>

                            <div>
                                <label htmlFor="initPositionX">Позиция ящика по горизонтали (оси X) в мм или в процентах (от%0 до %100)</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={initPositionX}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="initPositionX"
                                    maxLength={3}
                                    onFocus={onFocus}
                                />
                            </div>

                            <div>
                                <label htmlFor="initPositionY">Позиция ящика по вертикали (оси Y) в мм или в процентах (от%0 до %100)</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={initPositionY}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="initPositionY"
                                    maxLength={3}
                                    onFocus={onFocus}
                                />
                            </div>

                        </div>

                        <div className={cnCreateForm('Box-Input')}>

                            <div>
                                <label htmlFor="thickness">Толщина стенки ящика в мм *</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={thickness}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="thickness"
                                    maxLength={3}
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>

                            <div>
                                <label htmlFor="bottomThickness">Толщина нижней части (дно) ящика в мм *</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={bottomThickness}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="bottomThickness"
                                    maxLength={3}
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>

                        </div>

                        <div>
                            <label htmlFor="railGap">Расстояние для направляющих ящика (от корпуса до ящика) в мм *</label>
                            <Textinput
                                className={cnCreateForm('Textinput')}
                                value={railGap}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="railGap"
                                maxLength={3}
                                onFocus={onFocus}
                                type="number"
                            />
                        </div>

                        <Tumbler
                            className={cnCreateForm('Tumbler')}
                            checked={frontSide}
                            callBack={(e) => onChecked(e)}
                            label="Добавить дополнительную корпусную часть на переднюю стенку ящика"
                            name="frontSide"
                        />

                        {/* <Tumbler
                            className={cnCreateForm('Tumbler')}
                            checked={sizes}
                            callBack={(e) => onChecked(e)}
                            label="Добавить размеры ящика"
                            name="sizes"
                        />

                        {sizes && <div className={cnCreateForm('Box-Input')}>
                            <div>
                                <label htmlFor="sezeLength">Длина ящика в мм</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={sezeLength}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="sezeLength"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                            <div>
                                <label htmlFor="sezeWidth">Ширина ящика в мм</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={sezeWidth}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="sezeWidth"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                            <div>
                                <label htmlFor="sezeHeight">Высота ящика в мм</label>
                                <Textinput
                                    className={cnCreateForm('Textinput')}
                                    value={sezeHeight}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                    name="sezeHeight"
                                    onFocus={onFocus}
                                    type="number"
                                />
                            </div>
                        </div>} */}


                        <p className="text-danger">{error}</p>
                    </div>
                    <Button
                        className={cnCreateForm('Button')}
                        type={'button'}
                        text={isEdit ? 'Изменить ящик' : 'Добавить ящик '}
                        leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                        mods={{
                            mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                            hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                        }}
                        callBack={handleSubmit}
                    />
        </Modal>
  )
}

export default ModalBoxes