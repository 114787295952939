import React from 'react';
import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import './Messages.css'
import {useDispatch} from 'react-redux';
import {TMessage} from '../../../types/TMessage';
import {
    MESSAGE_TYPE_ERROR,
    MESSAGE_TYPE_INFO,
    MESSAGE_TYPE_SUCCESS,
    MESSAGE_TYPE_WARNING,
    REMOVE_MESSAGE
} from '../../../constants';
import {Button, cnButton} from '../../components/Button';
import {TMessageType} from '../../../types/TMessageType';

export const cnMessages = cn('Messages');
export interface IMessagesProps extends IClassNameProps {
    messages: TMessage[];
}
export const Messages: React.FC<IMessagesProps> = ({className, messages}) => {
    const dispatch = useDispatch();
    const removeMessage = (message: TMessage) => {
        dispatch({
            type: REMOVE_MESSAGE,
            payload: message.params?.id
        })
    }
    const getIconFillByType = (type: TMessageType): string => {
        switch (type) {
            case MESSAGE_TYPE_SUCCESS:
                return 'lightGreen';
            case MESSAGE_TYPE_WARNING:
                return 'lightOrange';
            case MESSAGE_TYPE_ERROR:
                return 'lightRed';
            case MESSAGE_TYPE_INFO:
            default:
                return 'lightBlack';

        }
    }
    return (
        <div className={cnMessages({}, [className])}>
            {messages.map((message, index) => {
                return (
                    <div className={cnMessages('Item', {type: message.type})} key={index}>
                        <div className={cnMessages('ItemContent', {type: message.type})}>
                            {message.message}
                        </div>
                        <Button
                            className={cnMessages(cnButton(), {type: message.type})}
                            type={'button'}
                            leftIcon={{path: 'close', width: 16, height: 16, fill: getIconFillByType(message.type)}}
                            mods={{}}
                            callBack={() => {removeMessage(message)}}
                        />
                    </div>);
            })}
        </div>
    );
}