import React, {forwardRef, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './Button.css';
import {IMods} from "../../../interfaces/IMods";
import {IIcon} from "../../../interfaces/IIcon";
import {useModsGenerator} from "../../../hooks/useModsGenerator";
import {Svg} from "../Svg";

export const cnButton = cn('Button');

export interface IButtonProps extends IClassNameProps {
    type: 'submit' | 'reset' | 'button',
    leftIcon?: IIcon,
    text?: string | null,
    rightIcon?: IIcon,
    hover?: boolean;
    disabled?: boolean;
    mods: IMods;
    callBack: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    child?: any;
}

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(({
                                                                       className,
                                                                       type,
                                                                       leftIcon,
                                                                       text,
                                                                       rightIcon,
                                                                       hover,
                                                                       disabled,
                                                                       mods,
                                                                       callBack,
                                                                       child,
                                                                   }, ref) => {

    const [isHover, setIsHover] = useState<boolean>(false);
    const modsGenerator = useModsGenerator();
    const newMods: IMods = mods;

    if (newMods.hover) {
        if (hover) {
            newMods.hover.state = hover;
        } else {
            newMods.hover.state = isHover;
        }
    }
    if (disabled) {
        if (!newMods.mods) {
            newMods.mods = {};
        }
        newMods.mods.disabled = true;
    }

    return (
        <button
            className={cnButton({...modsGenerator(newMods)}, [className])}
            type={type}
            ref={ref}
            disabled={disabled}
            onMouseEnter={() => {
                setIsHover(true)
            }}
            onMouseLeave={() => {
                setIsHover(false)
            }}
            onClick={(event) => {
                type !== 'submit' ? callBack() : callBack(event)
            }}
        >
            <div className={cnButton('Wrapper')}>
                {leftIcon &&
                    <Svg
                        icon={leftIcon.path}
                        className={cnButton('Icon', {type: 'left', fill: leftIcon.fill})}
                        label={leftIcon.path}
                        style={{
                            width: `${leftIcon.width}px`,
                            height: `${leftIcon.height}px`,
                            transform: `rotateZ(${leftIcon.rotation}deg)`,
                            marginRight: `${leftIcon.marginRight}px`,
                            transition: leftIcon.animated ? '.4s' : undefined
                        }}
                    />
                }
                {text && <p className={cnButton('Text')}>{text}</p>}
                {child ? child : null}
                {rightIcon &&
                    <Svg
                        icon={rightIcon.path}
                        className={cnButton('Icon', {type: 'right', fill: rightIcon.fill})}
                        label={rightIcon.path}
                        style={{
                            width: `${rightIcon.width}px`,
                            height: `${rightIcon.height}px`,
                            transform: `rotateZ(${rightIcon.rotation}deg)`,
                            marginLeft: `${rightIcon.marginLeft}px`,
                            transition: rightIcon.animated ? '.4s' : undefined
                        }}
                    />
                }
            </div>
        </button>
    );
});