import {ThreeConstructiveModel} from "../ThreeConstructiveModel";
import {Vector3} from "three";
import {TLevelBoxes} from "../../../../../types/TLevelBoxes";
import {ThreeSize} from "../../../ThreeSize/ThreeSize";
import {SIDE_TYPE_BOTTOM} from '../../../../../../../common-code/constants';

export class ThreeBottomConstructiveModel extends ThreeConstructiveModel {
    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        } else {
            if (this.view3d.position.y !== this.defaultYPosition()) {
                this.view3d.position.y = this.defaultYPosition();
            }
        }
        this.afterSetPosition();
    }

    public defaultYPosition(): number {
        return this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    protected correctMovePosition(movePosition: Vector3) {
        if (movePosition.y !== this.defaultYPosition()) {
            movePosition.y = this.defaultYPosition();
        }
    }

    protected setSizesPosition(levelBoxes?: TLevelBoxes) {
        let size: ThreeSize;

        if (!levelBoxes) {
            return;
        }
        for (size of this.sizes) {
            size.setPositionFromLevelBox(levelBoxes.bottom.max.y - this.view3d.position.y);
        }
    }
}