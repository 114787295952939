import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeShelfUnitCorpus} from '../../../details/ThreeCorpus/types/ThreeShelfUnitCorpus';
import {ISaveTopUnitShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitShelfData';
import {ISaveShelfUnitCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfUnitCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitShelf extends ThreeTopUnit {
    corpus: ThreeShelfUnitCorpus;
    saveData: ISaveTopUnitShelfData;

    constructor(options: ISaveTopUnitShelfData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveShelfUnitCorpusData): ThreeShelfUnitCorpus {
        return new ThreeShelfUnitCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}