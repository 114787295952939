import {cn} from '@bem-react/classname';
import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {TCollectionProperty} from '../../../../common-code/types/TCollectionProperty';
import "./Property.css";

export const cnProperty = cn('Property');

export interface IPropertyProps extends IClassNameProps {
    property: TCollectionProperty;
}

export const Property: React.FC<IPropertyProps> = ({property, className}) => {
    return (
        <div className={cnProperty({}, [className])}>
            <div className={cnProperty('Title')}>
                {property.title}
            </div>
            <div className={cnProperty('Value')}>
                {property.value}
            </div>
        </div>
    );
}