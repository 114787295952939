import React from 'react'
import { cnCreateForm } from '../../FormModule';
import Trash from '../../../icons/Trash';
import { ISideType } from './SideTypes';

function SydeType({side, sideTypes, setSideTypes}:
     {side: ISideType, sideTypes: {id: string, title: string}[], setSideTypes: React.Dispatch<React.SetStateAction<{id: string, title: string}[]>>}) {

    const handleDeleteValue = () => {
        if (!side) return;
        setSideTypes(sideTypes.filter(el => el.id !== side.id))
    }

  return (
    <>
        <div className={cnCreateForm('Children')}>
          <span>Сторона открывания: {side?.title}</span>
          <div className={cnCreateForm('Box-Btn')}>
            <Trash handleDeleteValue={handleDeleteValue} />
          </div>
        </div>
    </>
  )
}


export default SydeType