import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {useMemo} from "react";
import './MainMenu.css';
import {LINK_TARGET_BLANK, LINK_TYPE_DEFAULT, LINK_TYPE_NAV} from "../../constants";
import {ILinkTypeDefault} from "../../../interfaces/ILinkTypeDefault";
import {ILinkTypeNav} from "../../../interfaces/ILinkTypeNav";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {TOGGLE_MENU} from "../../../constants";
import {Link} from "../../components/Link/Link";
import {cnLink} from "../../components/Link";
import {Svg} from "../../components/Svg";
import {City, cnCity} from "../City";
import {useComponentVisible} from "../../../hooks/useComponentVisible";
import {cnMenuBurger} from "../../components/MenuBurger";
import {IUserData} from "../../../../common-code/interfaces/IUserData";
import {ROLE_GUEST} from "../../../../common-code/constants";

export const cnMainMenu = cn('MainMenu');

export interface IMainMenuProps extends IClassNameProps {

}

export const MainMenu: React.FC<IMainMenuProps> = ({className}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMenuOpen = useSelector((state:AppState) => state.ui.MainMenu.visible);
    const userData: IUserData = useSelector((state: AppState) => state.user);
    const {ref} = useComponentVisible('MainMenu', [cnMenuBurger()]);

    const hideMenu = () => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: 'MainMenu',
                value: false
            }
        });
    };

    const mainMenuItems: (ILinkTypeNav | ILinkTypeDefault)[] = useMemo(() => {
        const menuItems: (ILinkTypeNav | ILinkTypeDefault)[] = [
            {
                type: LINK_TYPE_NAV,
                href: '/collections',
                text: t('Модульные гарнитуры')
            } as ILinkTypeNav,
            // {
            //     type: LINK_TYPE_NAV,
            //     href: '/kuhni-na-zakaz',
            //     text: t('Кухни на заказ')
            // } as ILinkTypeNav,
            {
                type: LINK_TYPE_NAV,
                href: '/complect-collections',
                text: t('Кухни комплектом')
            } as ILinkTypeNav,
            {
                type: LINK_TYPE_DEFAULT,
                href: 'https://3d.narod-kuhni.ru/',
                target: LINK_TARGET_BLANK,
                icon: {path: '3d-model', width: 20, height: 20, marginRight: 8},
                text: t('Старая версия')
            } as ILinkTypeDefault,
            {
                type: LINK_TYPE_DEFAULT,
                href: 'https://narod-kuhni.ru/',
                target: LINK_TARGET_BLANK,
                icon: {path: 'shop', width: 20, height: 20, marginRight: 8},
                text: t('В интернет-магазин')
            } as ILinkTypeDefault
        ];

        if (!userData.roles.includes(ROLE_GUEST)) {
            menuItems.splice(
                menuItems.length - 2,
                0,
                {
                    type: LINK_TYPE_NAV,
                    href: '/projects',
                    text: t('Мои проекты')
                } as ILinkTypeNav)
        }

        return menuItems;
    }, [t, userData]);

    return (
        <div ref={ref} className={cnMainMenu({visible: isMenuOpen}, [className])}>
            <div className={cnMainMenu('Items')}>
                <div className={cnMainMenu('Item')}>
                    <City className={cnMainMenu(cnCity())} size={'small'} onClick={hideMenu}/>
                </div>
                {mainMenuItems.map((menuItem, index) => {
                    return (
                        <div
                            key={index}
                            className={cnMainMenu('Item', {type: menuItem.type === LINK_TYPE_NAV ? 'innerLink' : false})}
                        >
                            <Link
                                className={cnMainMenu(cnLink())}
                                type={menuItem.type}
                                href={menuItem.href}
                                target={'target' in menuItem ? menuItem.target : undefined}
                                icon={'icon' in menuItem ? menuItem.icon : undefined}
                                text={menuItem.text}
                                mods={{mods: {color: 'lightBlack', fill: 'black'}, hover: {mods: {color: 'black'}}}}
                                onClick={hideMenu}
                            >
                                {menuItem.type === LINK_TYPE_DEFAULT &&
                                    <Svg
                                        className={cnLink('Icon')}
                                        icon={'new-tab'}
                                        label={menuItem.text}
                                        style={{width: `${20}px`, height: `${20}px`}}
                                    />
                                }
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}