import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cnCreateObjectOptions} from '../CreateObjectOptions';
import {CreateOptionGroup} from '../../CreateOptionGroup/CreateOptionGroup';
import {CreateOption} from '../../CreateOption/CreateOption';
import {CommonObjectHelper} from '../../../../3d/helpers/CommonObjectHelper/CommonObjectHelper';
import {ICreateObjectData} from '../../../../../common-code/interfaces/createData/ICreateObjectData';

export interface ICreateObjectOptionsListProps extends IClassNameProps {
    objectData: ICreateObjectData;
    createOptions: any;
    handleOptionChange: (fieldId: string, value: any, groupId?: string) => void;
}
export const CreateObjectOptionsList: React.FC<ICreateObjectOptionsListProps> =
    ({
         objectData,
         handleOptionChange,
         createOptions,
     }) => {
        return (
            <div className={cnCreateObjectOptions('Options')}>
                {objectData.options.map((option) => {
                    if ('isGroup' in option) {
                        return <CreateOptionGroup
                            key={option.id}
                            group={option}
                            onChange={handleOptionChange}
                            createOptions={createOptions}
                            disableSelects={objectData.disableSelects}
                        />;
                    } else {
                        return <CreateOption
                            key={option.id}
                            option={CommonObjectHelper.prepareCreateOption(
                                option,
                                objectData.disableSelects ? objectData.disableSelects[option.id] : undefined
                            )}
                            type={option.type}
                            onChange={handleOptionChange}
                            createOptions={createOptions}
                        />;
                    }
                })}
            </div>
        );
    }