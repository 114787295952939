import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';

export class ThreeTopCorpus extends ThreeCorpus {
    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getDepth() - this.getBackThickness()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getDepth() - this.getBackThickness()),
            'top',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
    }
}