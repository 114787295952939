import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeBottomBarPCorpus} from '../../../details/ThreeCorpus/types/ThreeBottomBarPCorpus';
import {
    SIDE_TYPE_BACK,
    SIDE_TYPE_FRONT,
    SIDE_TYPE_RIGHT,
    SIDE_TYPE_TOP
} from '../../../../../../../common-code/constants';
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {TDetailOptionalSizes} from '../../../../../../../common-code/types/TDetailOptionalSizes';
import {ITabletopData} from '../../../../../../../common-code/interfaces/materials/ITabletopData';

export class ThreeBottomUnitBarP extends ThreeBottomUnit {
    corpus: ThreeBottomBarPCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomBarPCorpus {
        return new ThreeBottomBarPCorpus(CommonHelper.deepCopy(corpusData), this)
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index: string;
        let depth: number;
        let initSizes: TDetailOptionalSizes | undefined;
        let direction: number;
        let tabletopHeight: number;
        let materialData: ITabletopData;

        direction = this.getSideType() === SIDE_TYPE_RIGHT ? 1 : -1;
        if (tabletops) {
            for (index in tabletops) {
                materialData = this.service.getTabletopMaterial(tabletops[index].material);
                tabletopHeight = materialData.height;
                switch (tabletops[index].positionType) {
                    case SIDE_TYPE_TOP:
                        depth = this.getDepth();
                        initSizes = tabletops[index].initSizes;
                        if (initSizes && initSizes.width && !isNaN(+initSizes.width)) {
                            depth = +initSizes.width;
                        }
                        tabletops[index].initSizes = {
                            length: this.getWidth() + tabletopHeight * 2,
                            width: depth
                        };
                        tabletops[index].position = {
                            x: direction * this.getDepth()/2 - direction * depth/2,
                            y: this.getHeight()/2 + tabletopHeight/2,
                            z: 0
                        };
                        tabletops[index].rotation = {x: 0, y: Math.PI/2, z: 0};
                        break;
                    case SIDE_TYPE_FRONT:
                        depth = this.getDepth();
                        initSizes = tabletops[index].initSizes;
                        if (initSizes && initSizes.width && !isNaN(+initSizes.width)) {
                            depth = +initSizes.width;
                        }
                        tabletops[index].initSizes = {
                            length: this.getHeight(),
                            width: depth
                        };
                        tabletops[index].position = {
                            x: direction * this.getDepth()/2 - direction * depth/2,
                            y: 0,
                            z: this.getWidth()/2 + tabletopHeight/2
                        };
                        tabletops[index].rotation = {x: Math.PI/2, y: Math.PI/2, z: 0};
                        break;
                    case SIDE_TYPE_BACK:
                        depth = this.getDepth();
                        initSizes = tabletops[index].initSizes;
                        if (initSizes && initSizes.width && !isNaN(+initSizes.width)) {
                            depth = +initSizes.width;
                        }
                        tabletops[index].initSizes = {
                            length: this.getHeight(),
                            width: depth
                        };
                        tabletops[index].position = {
                            x: direction * this.getDepth()/2 - direction * depth/2,
                            y: 0,
                            z: -this.getWidth()/2 - tabletopHeight/2
                        };
                        tabletops[index].rotation = {x: Math.PI/2, y: Math.PI/2, z: 0};
                        break;
                }
            }
        }
        return tabletops;
    }
}