import {
    SIDE_TYPE_ANGLE,
    SIDE_TYPE_ARC,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_DEFAULT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_NONE,
    SIDE_TYPE_RIGHT,
    SIDE_TYPE_TOP,
} from 'common-code/lib/constants'
import { ITableTop } from './TableTops'
export const initionalTabletop = (): ITableTop => {
    return {
        id: 0,
        functionalType: '',
        initSizes: {
            length: 0,
            width: 0,
        },
    }
}
export const tableTopsSideTypes = [
    { value: SIDE_TYPE_LEFT, content: SIDE_TYPE_LEFT },
    {
        value: SIDE_TYPE_RIGHT,
        content: SIDE_TYPE_RIGHT,
    },
    {
        value: SIDE_TYPE_DEFAULT,
        content: SIDE_TYPE_DEFAULT,
    },
    {
        value: SIDE_TYPE_TOP,
        content: SIDE_TYPE_TOP,
    },
    {
        value: SIDE_TYPE_BOTTOM,
        content: SIDE_TYPE_BOTTOM,
    },
]
export const tableTopsPositionTypes = [
    { value: SIDE_TYPE_NONE, content: SIDE_TYPE_NONE },
    { value: SIDE_TYPE_ARC, content: SIDE_TYPE_ARC },
    { value: SIDE_TYPE_ANGLE, content: SIDE_TYPE_ANGLE },
]
