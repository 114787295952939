import { INewBox } from "./initialBox";

export const validateBox = (newBox : INewBox, maxHeight : number, maxWidth : number ) => {
    const { length, height, depth, initPositionX, initPositionY, thickness, 
        bottomThickness, railGap } = newBox;

    if(!length || !height || !depth || !thickness || !bottomThickness || !railGap){
        return 'Необходимо заполнить все поля';
    }
    if(Number(length) < 0 || Number(length) > 100) {
        return 'Значение ширины ящика должно быть в диапазоне от 0 до 100%';
    }
    if(Number(depth) < 0 || Number(depth) > 100) {
        return 'Значение глубины ящика должно быть в диапазоне от 0 до 100%';
    }
    if(Number(height) < 0 || Number(height) > maxHeight) {
        return `Значение высоты ящика должно быть в диапазоне от 0 до ${maxHeight} мм`;
    }
    if( (initPositionX as string).includes('%')){
        if(isNaN(Number((initPositionX as string).replace('%', ""))) || Number((initPositionX as string).replace('%', "")) < 0 || 
        Number((initPositionX as string).replace('%', "")) > 100 ) {
            return 'Значение начальной позиции ящика по горизонтали должно быть в диапазоне от 0 до 100%';
        } 
    } else if( isNaN(Number(initPositionX)) || Number(initPositionX) < 0 || Number(initPositionX) > maxWidth) {
        return 'Значение начальной позиции ящика должно быть в диапазоне от 0 до максимальной ширины корпуса';
    }
    if( (initPositionY as string).includes('%')){ 
        if (isNaN(Number((initPositionY as string).replace('%', ""))) || Number((initPositionY as string).replace('%', "")) < 0 || 
            Number((initPositionY as string).replace('%', "")) > 100 ) {
        return 'Значение начальной позиции ящика по вертикали должно быть в диапазоне от 0 до 100%';
        }
    } else if( isNaN(Number(initPositionY))  || Number(initPositionY) < 0 || Number(initPositionY) > maxHeight) {
        return 'Значение начальной позиции ящика должно быть в диапазоне от 0 до максимальной высоты корпуса';
    }

    if(Number(thickness) < 1 || Number(thickness) > 100) {
        return 'Значение толщины ящика должно быть в диапазоне от 1 до 100 мм';
    }
    if(Number(bottomThickness) < 1 || Number(bottomThickness) > 20) {
        return 'Значение толщины нижней части (дна) ящика должно быть в диапазоне от 1 до 20 мм';
    }
    if(Number(railGap) < 1 || Number(railGap) > 100) {
        return 'Значение расстояния между ящиком и стенкой корпуса должно быть в диапазоне от 1 до 100 мм';
    }
    return "";
}
