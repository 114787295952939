import {ThreeEquipment} from '../ThreeEquipment/ThreeEquipment';
import {Box3, Vector3} from 'three';
import {
    LEVEL_TOP,
    SIDE_TYPE_BOTTOM,
    SIZE_TYPE_DEPTH,
    SIZE_TYPE_HEIGHT,
    SIZE_TYPE_WIDTH
} from '../../../../../../common-code/constants';
import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {TLevel} from "../../../../../../common-code/types/TLevel";
import {ISaveEquipmentData} from '../../../../../../common-code/interfaces/saveData/ISaveEquipmentData';
import {TLevelBoxes} from '../../../../types/TLevelBoxes';
import {ThreeSize} from '../../ThreeSize/ThreeSize';
import {ISaveSizeData} from '../../../../../../common-code/interfaces/saveData/ISaveSizeData';
import {UNIT_SIZE_TEXT_SIZE} from '../../../../constants';
import {TPlaneType} from '../../../../types/TPlaneType';
import {PLANE_TYPE_FLOOR, PLANE_TYPE_HORIZONTAL, PLANE_TYPE_VERTICAL, PLANE_TYPE_WALL} from '../../../../../constants';

export class ThreeTopEquipment extends ThreeEquipment {

    constructor(options: ISaveEquipmentData, service: KitchenService) {
        super(options, service);
        this.transparentForBack = this.saveData.transparentForBack !== undefined ?
            this.saveData.transparentForBack : true;
    }

    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        } else {
            if (this.view3d.position.y !== this.defaultYPosition()) {
                this.view3d.position.y = this.defaultYPosition();
            }
        }
        this.afterSetPosition();
    }

    public defaultYPosition(): number {
        return  Math.abs(this.getSideDistance(SIDE_TYPE_BOTTOM)) + this.service.getTopFirstYPosition();
    }

    public getLevel(): TLevel {
        return LEVEL_TOP;
    }

    protected initSizesData(): ISaveSizeData[] {
        let sizesData: ISaveSizeData[] = [];
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);

        sizesData.push({
            id: 0,
            pointA: {x: coverBox.min.x, y: coverBox.min.y, z: coverBox.min.z + this.SIZE_GAP},
            pointB: {x: coverBox.max.x, y: coverBox.min.y, z: coverBox.min.z + this.SIZE_GAP},
            type: SIZE_TYPE_WIDTH,
            decimal: 0,
            textSize: UNIT_SIZE_TEXT_SIZE,
            textInvert: true,
            rotation: {z: Math.PI},
            textIndent: 0,
        });
        sizesData.push({
            id: 0,
            pointA: {x: coverBox.max.x, y: coverBox.min.y, z: coverBox.min.z + this.SIZE_GAP},
            pointB: {x: coverBox.max.x, y: coverBox.max.y, z: coverBox.min.z + this.SIZE_GAP},
            type: SIZE_TYPE_HEIGHT,
            decimal: 0,
            rotation: {z: -Math.PI/2},
            textSize: UNIT_SIZE_TEXT_SIZE,
            textIndent: 0,
        });
        sizesData.push({
            id: 0,
            pointA: {x: coverBox.min.x + this.SIZE_GAP, y: coverBox.min.y, z: coverBox.min.z},
            pointB: {x: coverBox.min.x + this.SIZE_GAP, y: coverBox.min.y, z: coverBox.max.z},
            type: SIZE_TYPE_DEPTH,
            decimal: 0,
            textSize: UNIT_SIZE_TEXT_SIZE,
            textInvert: true,
            rotation: {y: Math.PI/2, x: Math.PI},
            textIndent: 0,
        });

        return sizesData;
    }

    protected setSizesPosition(levelBoxes?: TLevelBoxes) {
        let size: ThreeSize;

        if (!levelBoxes) {
            return;
        }
        for (size of this.sizes) {
            size.setPositionFromLevelBox(levelBoxes.top.min.y - this.view3d.position.y);
        }
    }

    protected getIntersectsTypes(): TPlaneType[] {
        return [
            PLANE_TYPE_WALL,
            PLANE_TYPE_VERTICAL,
            PLANE_TYPE_FLOOR,
            PLANE_TYPE_HORIZONTAL,
        ];
    }

    protected correctMovePosition(movePosition: Vector3) {
        this.correctTopUnitMovePosition(movePosition);
    }
}