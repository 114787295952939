import React, {ReactNode, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './Accordion.css';
import {useModsGenerator} from '../../../hooks/useModsGenerator';
import {Svg} from '../Svg';
import useHeightItemGenerator from '../../../hooks/useHeightItemGenerator';

export const cnAccordion = cn('Accordion');

export interface IAccordionProps extends IClassNameProps {
    children: ReactNode;
    text: string;
    initActive?: boolean;
}

export const Accordion: React.FC<IAccordionProps> = ({
                                                         className,
                                                         children,
                                                         text,
                                                         initActive
                                                     }) => {
    const [active, setActive] = useState<boolean>(!!initActive);
    const [hover, setHover] = useState<boolean>(false);
    const modsGenerator = useModsGenerator();
    const heightItemGenerator = useHeightItemGenerator();

    if (!children) {
        return null;
    }

    return (
        <div
            className={cnAccordion({animated: true}, [className])}
            ref={(element) => {
                heightItemGenerator({
                    element: element,
                    active: active,
                    rowsGap: 0,
                    borderWidth: 0,
                    padding: 0,
                    defaultHeight: 60
                })
            }}
        >
            <div
                className={cnAccordion('Header',
                    {...modsGenerator({hover: {state: hover, mods: {cursor: 'pointer'}}})}
                )}
                onMouseEnter={() => {
                    setHover(true)
                }}
                onMouseLeave={() => {
                    setHover(false)
                }}
                onClick={() => {
                    setActive(!active)
                }}
            >
                <p className={cnAccordion('Text')}>{text}</p>
                <Svg icon={'arrowDown'}
                     className={cnAccordion('Icon', {animated: true, transform: active})}
                     label={'arrowDown'}/>
            </div>
            <div className={cnAccordion('Wrapper')}>
                {children}
            </div>
        </div>
    );
};