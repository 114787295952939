import React from "react";
import { IPlinthData } from "../../../../../common-code/interfaces/materials/IPlinthData";
import { defaultValues, initialErrors } from "./defaultValues";
import { INewTextureData } from "../FormMaterial/Textures/Textures";
import { transliterate } from "../utils/transliterate";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import Trash from "../icons/Trash";
import { Tumbler } from "../../../components/Tumbler/Tumbler";
import { Button } from "../../../components/Button";
import { cn } from "@bem-react/classname";
import "./FormPlinth.css";
import InputFile from "./InputFile/InputFile";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import Textures from "./Textures/Textures";
// import { useSelector } from "react-redux";
// import { AppState } from "../../../../redux/AppStore";


export const cnFormPlinth = cn("FormPlinth");

export interface INewPlinthData extends IPlinthData {
  texturesNew?: INewTextureData[];
  imageNew?: File | null;
}

function FormPlinth({
  itemPlinth,
  createPlinth,
}: {
  itemPlinth: INewPlinthData | null;
  createPlinth: (plinth: INewPlinthData) => void;
}) {
  // const itemPlinth = useSelector((state: AppState) => state.wizard.editMaterial.itemPlinth);
  const [newPlinth, setNewPlinth] = React.useState<INewPlinthData>(
    defaultValues(itemPlinth)
  );

  const formRef = React.useRef<HTMLFormElement>(null);
  const scrollToForm = () =>
    formRef?.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  const {
    //обязательные
    title,
    depth,
    height,
    image,

    //необязательные
    color,
    colorFrom1C,
    // disableFacades,
    emissiveColor,
    isDefault,
    imageNew,
  } = newPlinth;

  const [isColor, setIsColor] = React.useState(!!color);
  const [isEmissiveColor, setIsEmissiveColor] = React.useState(!!emissiveColor);
  const [isColorFrom1C, setIsColorFrom1C] = React.useState(!!colorFrom1C);
  const [errors, setErrors] = React.useState(initialErrors);

  const onInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNewPlinth({ ...newPlinth, [e.target.name]: e.target.value });
  };

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPlinth({ ...newPlinth, [e.target.name]: e.target.checked });
  };

  const onFocus = () => {
    setNewPlinth({ ...newPlinth });
    setErrors(initialErrors);
  };

  const CreatePlinthNew = () => {
    const plinth: INewPlinthData = {
      id: transliterate(title),
      title: title,
      depth: depth,
      height: height,
      image: image,
    };

    // if (disableFacades) plinth.disableFacades = disableFacades;
    if (isColor) {
      plinth.color = color;
    } else {
      if (newPlinth.textures && newPlinth.textures.length > 0) {
        plinth.textures = newPlinth.textures;
      }
      if (newPlinth.texturesNew && newPlinth.texturesNew?.length > 0) {
        plinth.texturesNew = newPlinth.texturesNew;
      }
    }

    if (isColorFrom1C) plinth.colorFrom1C = colorFrom1C;
    if (isDefault) plinth.isDefault = isDefault;
    if (isEmissiveColor) plinth.emissiveColor = emissiveColor;

    if (imageNew) {
      plinth.imageNew = imageNew;
    } else if (image) {
      plinth.image = image;
    }

    return plinth;
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!title) {
      setErrors({ ...errors, title: "Поле название цоколя не может быть пустым" });
      scrollToForm();
      return;
    } else if (title.length < 5) {
      setErrors({ ...errors, title: "Минимальная длина названия цоколя 5 символов" });
      scrollToForm();
      return;
    } else if (!depth) {
      setErrors({ ...errors, depth: "Поле глубина не может быть пустым" });
      scrollToForm();
      return;
    } else if (depth > 100) {
      setErrors({ ...errors, depth: "Максимальная глубина 100" });
      scrollToForm();
      return;
    } else if (!height) {
      setErrors({ ...errors, height: "Поле высота не может быть пустым" });
      scrollToForm();
      return;
    } else if (height > 1000) {
      setErrors({ ...errors, height: "Максимальная высота 1000" });
      scrollToForm();
      return;
    } 

    const plinth = CreatePlinthNew();
    createPlinth(plinth);
    console.log("plinth", plinth);
  };

  const addFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPlinth({
      ...newPlinth,
      imageNew: Array.from(e.target.files as FileList)[0],
      image: "",
    });
    setErrors(initialErrors);
  };

  const filterFunction = () => {
    setNewPlinth({ ...newPlinth, imageNew: null, image: "" });
    setErrors(initialErrors);
  };

  const deleteImage = () => {
    setNewPlinth({ ...newPlinth, image: "" });
    setErrors(initialErrors);
  };

  const edit = !!itemPlinth;

  return (
    <div className={cnFormPlinth("")}>
      <div className={cnFormPlinth("Container")}>
        <h3 className={cnFormPlinth("Header")}>
          {edit ? "Редактирование цоколя" : "Добавление цоколя"}
        </h3>

        <form
          className={cnFormPlinth("Content")}
          onSubmit={(e) => handleSubmit(e)}
          ref={formRef}
        >
          <label htmlFor="title">Название цоколя *</label>
          <Textinput
            className={cnFormPlinth("Textinput")}
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e)
            }
            name="title"
            type="text"
            maxLength={50}
            onFocus={onFocus}
          />
          {errors.title && <p className="text-danger">{errors.title}</p>}

          <Tumbler
            className={cnFormPlinth("Tumbler")}
            checked={isColor as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsColor(e.target.checked)
            }
            label="Указать цвет"
            name="isColor"
          />

          {isColor ? (
            <>
              <ColorPicker
                color={color}
                onChange={(color) => setNewPlinth({ ...newPlinth, color })}
              />
            </>
          ) : (
            <>
              <h4 className={cnFormPlinth("Title")}>Изображение цоколей</h4>
              {image && !imageNew && (
                <div className={cnFormPlinth("Children")}>
                  <span>{image.split("/").at(-1) as string}</span>
                  <img width={50} height={50} src={image} alt="..." />
                  <div className={cnFormPlinth("Box-Btn")}>
                    <Trash handleDeleteValue={deleteImage} />
                  </div>
                </div>
              )}

              <InputFile
                file={imageNew as File | null}
                addFiles={addFiles}
                filterFunction={filterFunction}
              />
              {errors.image && <p className="text-danger">{errors.image}</p>}

              <Textures setNewPlinth={setNewPlinth} newPlinth={newPlinth} />
            </>
          )}

          <Tumbler
            className={cnFormPlinth("Tumbler")}
            checked={isColorFrom1C as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsColorFrom1C(e.target.checked)
            }
            label="Указать цвет цоколя из 1C"
            name="isColorFrom1C"
          />

          {isColorFrom1C && (
            <div>
              <label htmlFor="colorFrom1C">Цвет цоколя из 1C</label>
              <Textinput
                className={cnFormPlinth("Textinput")}
                value={colorFrom1C}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onInputChange(e)
                }
                name="colorFrom1C"
                type="text"
              />
            </div>
          )}

          <Tumbler
            className={cnFormPlinth("Tumbler")}
            checked={isEmissiveColor as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsEmissiveColor(e.target.checked)
            }
            label="Указать оттенок свечения"
          />

          {isEmissiveColor && (
            <div className={cnFormPlinth("Form-Group")}>
              <label htmlFor="emissiveColor">Оттенок свечения</label>
              {/* <input type="color" id='emissiveColor' name="emissiveColor" value={emissiveColor} onChange={(e) => onInputChange(e)} className={cnFormMaterial('СolorInput')}/> */}
              <ColorPicker
                color={emissiveColor}
                onChange={(emissiveColor) =>
                  setNewPlinth({ ...newPlinth, emissiveColor })
                }
              />
            </div>
          )}

          <div>
            <label htmlFor="depth">Толщина цоколей *</label>
            <Textinput
              className={cnFormPlinth("Textinput")}
              value={depth}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="depth"
              type="number"
            />
          </div>
          {errors.depth && <p className="text-danger">{errors.depth}</p>}

          <div>
            <label htmlFor="depth">Высота цоколей *</label>
            <Textinput
              className={cnFormPlinth("Textinput")}
              value={height}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="height"
              type="number"
            />
          </div>
          {errors.height && <p className="text-danger">{errors.height}</p>}

          <Tumbler
            className={cnFormPlinth("Tumbler")}
            checked={isDefault as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Определить этот цоколь, как цоколь по умолчанию"
            name="isDefault"
          />

          <div className={cnFormPlinth("Footer")}>
            <Button
              className={cnFormPlinth("Button")}
              type={"submit"}
              text={"Подтвердить изменения"}
              leftIcon={undefined}
              mods={{
                mods: {
                  bkg: "white",
                  color: "lightGreen",
                  border: "lightGreen",
                  fill: "lightGreen",
                },
                hover: {
                  mods: { bkg: "lightGreen", color: "white", fill: "white" },
                },
              }}
              callBack={() => {}}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormPlinth;
