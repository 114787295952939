import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle';
import { useTranslation } from 'react-i18next';
import { cnCreateForm } from '../FormModule';

interface IInputNumberProps {
  min: number
  max: number
  label: string
  name: string
  required: boolean
}

function InputNumber({ min, max, label, name, required} : IInputNumberProps) {

  const { control, formState: { errors } } = useFormContext();

    const {t} = useTranslation();

  return (
  <div>
  <label>{t(label)}</label>
    <Controller
        control={control}
        rules={{
          required: required,
          min: min,
          max: max,
          maxLength: 5,
          pattern: /^-?[0-9]+$/,
        }}
        render={({ field: { onChange, value } }) => (
        <Textinput
                    className={cnCreateForm('Textinput')}
                    placeholder={t(label) }
                    value={value}
                    onChange={onChange}
                    type="number"

                /> 
        )}
        name={name}
    />
    {errors?.[name]?.type === "required" && <p className='text-danger'>{`Не указана ${label.toLowerCase()}`}</p>} 
    {errors?.[name]?.type === "maxLength" && (<p className='text-danger'>Необходимо указать не более 5 символов</p>)}
    {errors?.[name]?.type === "pattern" && (<p className='text-danger'>Необходимо указать число</p>)}
    {(errors?.[name]?.type === "min" || errors?.[name]?.type === "max") && (<p className='text-danger'>{`Необходимо указать число от ${min} до ${max}`}</p>)}
  </div>
  )
}

export default InputNumber