import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Mesh, Shape, Vector2, Vector3} from 'three';
import {ThreeKUnit} from '../../../units/ThreeKUnit/ThreeKUnit';
import {CommonObjectHelper} from '../../../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';
import {ISaveBarRavennaCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveBarRavennaCorpusData';

export class ThreeBottomBarRavennaCorpus extends ThreeCorpus {
    saveData: ISaveBarRavennaCorpusData;

    constructor(options: ISaveBarRavennaCorpusData, unit: ThreeKUnit) {
        super(options, unit);
        this.saveData = options;
    }

    public sideWidthGap(): number {
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_RIGHT:
                return 16;
            default:
                return -16;
        }
    }

    protected addCoverPointsToUnit() {
        let coverPoints: Vector3[];

        coverPoints = [
            new Vector3(-this.getWidth() / 2 - this.sideWidthGap(), -this.getHeight() / 2, -this.getDepth() / 2),
            new Vector3(this.getWidth() / 2 - this.sideWidthGap(), this.getHeight() / 2, this.getDepth() / 2),
        ];
        this.unit.addCoverPoints(coverPoints);
        this.addCoverPoints(coverPoints);
    }

    protected getBackGap(): number {
        return this.saveData.backGap;
    }

    protected getBackCell(): number {
        return this.saveData.backCell || 800;
    }

    protected getFrontCell(): number {
        return this.saveData.frontCell || 400;
    }

    protected getSidePanel(): number {
        return this.saveData.sidePanel || 600;
    }

    protected createShape() {
        let points: { [key: string]: Vector2 } = {};
        let circlePoints: TPoint2D[];
        let circlePoint: TPoint2D;
        this.addUniquePoint(
            new Vector2(-this.getWidth() / 2 + this.sideWidthGap(), -this.getDepth() / 2 + this.getBackGap()),
            points
        );
        this.addUniquePoint(
            new Vector2(-this.getWidth() / 2 + this.sideWidthGap(), this.getDepth() / 2 - this.getWidth() / 2),
            points
        );
        circlePoints = CommonObjectHelper.getCirclePoints(
            {x: -this.getWidth() / 2 + this.sideWidthGap(), y: this.getDepth() / 2 - this.getWidth() / 2},
            {x: this.getWidth() / 2 + this.sideWidthGap(), y: this.getDepth() / 2 - this.getWidth() / 2},
            {x: this.sideWidthGap(), y: this.getDepth() / 2 - this.getWidth() / 2},
            this.getWidth() / 2,
            true
        );
        for (circlePoint of circlePoints) {
            this.addUniquePoint(
                new Vector2(circlePoint.x, circlePoint.y),
                points
            );
        }
        this.addUniquePoint(
            new Vector2(this.getWidth() / 2 + this.sideWidthGap(), this.getDepth() / 2 - this.getWidth() / 2),
            points
        );
        this.addUniquePoint(
            new Vector2(this.getWidth() / 2 + this.sideWidthGap(), -this.getDepth() / 2 + this.getBackGap()),
            points
        );

        this.shape = new Shape(Object.values(points));
    }

    protected createShapePanels() {
        let bottom: Mesh;

        bottom = this.createShapePanel();
        bottom.position.y = -this.getHeight() / 2 + this.getThickness() / 2;
        bottom.name = 'bottom';
        this.view3d.add(bottom);
    }

    protected createPanels() {
        let lineWidth: number;
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getHeight() - this.getThickness(), this.getThickness()),
            'back',
            new Vector3(
                this.sideWidthGap(),
                this.getThickness() / 2,
                -this.getDepth() / 2 + this.getBackGap() + this.getThickness() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getHeight() - this.getThickness() * 2, this.getThickness()),
            'inner1',
            new Vector3(
                this.sideWidthGap(),
                0,
                -this.getDepth() / 2 + this.getBackGap() + this.getBackCell() - this.getThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getHeight() - this.getThickness() * 2, this.getThickness()),
            'inner2',
            new Vector3(
                this.sideWidthGap(),
                0,
                -this.getDepth() / 2 + this.getBackGap() + this.getBackCell() + this.getThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getHeight() - this.getThickness(), this.getThickness()),
            'front',
            new Vector3(
                this.sideWidthGap(),
                this.getThickness() / 2,
                -this.getDepth() / 2 + this.getBackGap() + this.getBackCell() + this.getFrontCell() - this.getThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getSidePanel()),
            'side',
            new Vector3(
                this.widthSide() + this.getThickness() / 2,
                this.getThickness() / 2,
                -this.getDepth() / 2 + this.getSidePanel() / 2
            )
        );

        lineWidth = this.getBackCell() + this.getFrontCell() - this.getThickness() * 2;
        this.createPanel(
            new BoxGeometry(100, this.getThickness(), lineWidth),
            'line1',
            new Vector3(
                -this.getWidth() / 2 + this.sideWidthGap() + 100 / 2,
                this.getHeight() / 2 - this.getThickness() / 2,
                -this.getDepth() / 2 + this.getBackGap() + lineWidth / 2 + this.getThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(100, this.getThickness(), lineWidth),
            'line2',
            new Vector3(
                this.getWidth() / 2 + this.sideWidthGap() - 100 / 2,
                this.getHeight() / 2 - this.getThickness() / 2,
                -this.getDepth() / 2 + this.getBackGap() + lineWidth / 2 + this.getThickness()
            )
        );
    }

    protected widthSide(): number {
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_RIGHT:
                return this.sideWidthGap() - this.getWidth() / 2;
            default:
                return this.sideWidthGap() + this.getWidth() / 2;
        }
    }

    protected getShapeShelfLength(): number {
        return !isNaN(+this.saveData.shapeShelfLength) ? +this.saveData.shapeShelfLength : 0;
    }

    protected createShelfShape() {
        let points: { [key: string]: Vector2 } = {};
        let circlePoints: TPoint2D[];
        let circlePoint: TPoint2D;
        this.addUniquePoint(
            new Vector2(-this.getWidth() / 2 + this.sideWidthGap(), 0),
            points
        );
        this.addUniquePoint(
            new Vector2(-this.getWidth() / 2 + this.sideWidthGap(), this.getShapeShelfLength()),
            points
        );
        circlePoints = CommonObjectHelper.getCirclePoints(
            {x: -this.getWidth() / 2 + this.sideWidthGap(), y: this.getShapeShelfLength()},
            {x: this.getWidth() / 2 + this.sideWidthGap(), y: this.getShapeShelfLength()},
            {x: this.sideWidthGap(), y: this.getShapeShelfLength()},
            this.getWidth() / 2,
            true
        );
        for (circlePoint of circlePoints) {
            this.addUniquePoint(
                new Vector2(circlePoint.x, circlePoint.y),
                points
            );
        }
        this.addUniquePoint(
            new Vector2(this.getWidth() / 2 + this.sideWidthGap(), this.getShapeShelfLength()),
            points
        );
        this.addUniquePoint(
            new Vector2(this.getWidth() / 2 + this.sideWidthGap(), 0),
            points
        );

        this.shelfShape = new Shape(Object.values(points));
    }

    protected calculateShapeShelvesZPosition(): number {
        return -this.getDepth()/2 + (this.getBackCell() + this.getFrontCell()) + this.getShapeShelfLength()/2 + this.getThickness();
    }

    protected createShapeShelves() {
        let bottom: Mesh;
        let inner: Mesh;
        let top: Mesh;
        let zPosition: number;

        zPosition = this.calculateShapeShelvesZPosition();
        bottom = this.createShapeShelf();
        bottom.position.y = -this.getHeight() / 2 + this.getThickness() / 2 + (this.getHeight() / 3);
        bottom.position.z = zPosition;
        bottom.name = 'bottomShelf';
        this.view3d.add(bottom);
        inner = this.createShapeShelf();
        inner.position.y = -this.getHeight() / 2 + this.getThickness() / 2 + (this.getHeight() / 3) * 2;
        inner.position.z = zPosition;
        inner.name = 'innerShelf';
        this.view3d.add(inner);
        top = this.createShapeShelf();
        top.position.y = -this.getHeight() / 2 - this.getThickness() / 2 + (this.getHeight() / 3) * 3;
        top.position.z = zPosition;
        top.name = 'topShelf';
        this.view3d.add(top);
    }

    protected createBackPanels() {
    }
}