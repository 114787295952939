import {THeaderType} from '../types/THeaderType';
import {TScreenSizes} from '../types/TScreenSizes';
import {useScreenSizes} from './useScreenSizes';
import {HEADER_TYPE_BIG, HEADER_TYPE_MIDDLE, HEADER_TYPE_SMALL} from '../ui/constants';

export const useHeaderType = (): THeaderType => {
    const screenSizes: TScreenSizes = useScreenSizes();
    if (screenSizes.width <= 480) {
        return HEADER_TYPE_SMALL;
    }
    if (screenSizes.width <= 1200) {
        return HEADER_TYPE_MIDDLE;
    }

    return HEADER_TYPE_BIG;
}