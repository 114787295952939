import {ThreeSize} from './ThreeSize';
import {Vector3} from 'three';
import {ICoverMainPoints} from '../../../interfaces/ICoverMainPoints';
import {ThreeMathHelper} from '../../../helpers/ThreeMathHelper/ThreeMathHelper';
import {TThreeLine} from '../../../types/TThreeLine';
import {ISaveSizeData} from '../../../../../common-code/interfaces/saveData/ISaveSizeData';

export class ThreeSizeUnion extends ThreeSize {
    sizes: ThreeSize[];

    constructor(options: ISaveSizeData, sizes: ThreeSize[]) {
        let firstSize: ThreeSize;
        let points: TThreeLine;

        firstSize = sizes[0];
        if (!firstSize) {
            throw new Error('error-ThreeSizeUnion-constructor');
        }
        super(options, firstSize.unit);
        this.sizes = sizes;
        points = this.initPoints();
        this.pointA = points.pointA;
        this.pointB = points.pointB;
        this.length = options.length !== undefined && !isNaN(+options.length) ?
            +options.length :
            +(ThreeMathHelper.getLength(this.pointA, this.pointB)).toFixed(this.decimal);
    }

    public setCenterPosition() {
        this.view3d.userData.centerPosition = this.view3d.position;
    }

    public getGlobalPosition(): Vector3 {
        return this.view3d.position;
    }

    public getChildren(): ThreeSize[] {
        return this.sizes;
    }

    public getSortChildIds(): string[] {
        let ids: { [n: number]: number } = {};
        let size: ThreeSize;

        for (size of this.sizes) {
            ids[size.getId()] = size.getId();
        }

        return Object.keys(ids).sort((a: string, b: string) => {
            return +a - +b;
        });
    }

    public hide() {
        super.hide();
        this.setIsUnionSizes(false);

    }

    public show() {
        super.show();
        this.setIsUnionSizes(true);

    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        this.setIsUnionSizes(true);
    }

    public createView(isRebuild?: boolean) {
        this.service.addToSceneObject3D(this.view3d);
        this.view3d.name = 'SizeUnion';
        this.view3d.userData.vector = new Vector3();
        this.view3d.matrixAutoUpdate = false;
        this.setPosition(this.initPosition());
        this.setRotation(this.initRotation());
        this.updateAllMatrices();
        this.setCenterPosition();
    }

    public remove() {
        this.setIsUnionSizes(false);
        this.service.removeFromSceneObject3D(this.view3d);
        super.remove();
    }

    protected setIsUnionSizes(isUnion: boolean) {
        let size: ThreeSize;

        for (size of this.sizes) {
            size.setIsUnion(isUnion);
        }
    }

    protected initPoints(): TThreeLine {
        let firstSize: ThreeSize;
        let lastSize: ThreeSize;
        let firstGlobalMainPoints: ICoverMainPoints;
        let lastGlobalMainPoints: ICoverMainPoints;
        let pointA: Vector3, pointB: Vector3;

        firstSize = this.sizes[0];
        lastSize = this.sizes[this.sizes.length - 1];
        firstGlobalMainPoints = firstSize.getGlobalMainPoints();
        lastGlobalMainPoints = lastSize.getGlobalMainPoints();
        if (ThreeMathHelper.getLength(firstGlobalMainPoints.back.pointB, lastGlobalMainPoints.back.pointA) >
            ThreeMathHelper.getLength(firstGlobalMainPoints.back.pointA, lastGlobalMainPoints.back.pointA)) {
            pointA = firstGlobalMainPoints.back.pointB;
        } else {
            pointA = firstGlobalMainPoints.back.pointA;
        }
        if (ThreeMathHelper.getLength(lastGlobalMainPoints.back.pointB, firstGlobalMainPoints.back.pointA) >
            ThreeMathHelper.getLength(lastGlobalMainPoints.back.pointA, firstGlobalMainPoints.back.pointA)) {
            pointB = lastGlobalMainPoints.back.pointB;
        } else {
            pointB = lastGlobalMainPoints.back.pointA;
        }

        return {pointA: pointA, pointB: pointB};
    }
}