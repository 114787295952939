import {ILinkProps} from "../Link";
import {LINK_TYPE_NAV} from "../../../constants";
import {IIcon} from "../../../../interfaces/IIcon";
import {IMods} from "../../../../interfaces/IMods";
import React, {ReactNode, useState} from "react";
import {useModsGenerator} from "../../../../hooks/useModsGenerator";
import {NavLink as ReactLink} from "react-router-dom";
import {Svg} from "../../Svg";
import {withBemMod} from "@bem-react/core";
import {cnLink} from "../index";

export interface ILinkTypeNavProps extends ILinkProps {
    type?: typeof LINK_TYPE_NAV;
    href?: string;
    icon?: IIcon;
    text?: string;
    mods?: IMods;
    children?: ReactNode;
    onClick?(): void;
}

const LinkTypeNav: React.FC<ILinkTypeNavProps> =
    ({
        className,
        href,
        icon,
        text,
        mods,
        children,
        onClick
    }) => {
    const [hover, setHover] = useState<boolean>(false);
    const modsGenerator = useModsGenerator();

    if (mods?.hover) {
        mods.hover.state = hover;
    }

    return (
        <ReactLink
            className={cnLink({...modsGenerator(mods || {})}, [className])}
            to={href || '/'}
            onClick={onClick}
        >
            <div
                className={cnLink('Wrapper')}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
            >
                <>
                    {icon &&
                        <Svg
                            className={cnLink('Icon')}
                            icon={icon.path}
                            label={text || icon.path}
                            style={{width: `${icon.width}px`, height: `${icon.height}px`, marginRight: `${icon.marginRight}px`}}
                        />
                    }
                    {text && <p className={cnLink('Text')}>{text}</p>}
                    {children && <div className={cnLink('Children', {stretch: !(icon && text)})}>{children}</div>}
                </>
            </div>
        </ReactLink>
    );
};

export const withLinkTypeNav = withBemMod<ILinkTypeNavProps, ILinkProps>(
    cnLink(),
    {type: LINK_TYPE_NAV},
    () => LinkTypeNav
);