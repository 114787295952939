import React from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_JSON} from '../../../../../common-code/constants';
import {IObjectOptionProps} from '../ObjectOption';
import {IOptionHiddenJson} from '../../../../../common-code/interfaces/option/IOptionHiddenJson';
import {cnObjectOption} from '../index';


export interface IObjectOptionTypeJsonProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_JSON;
    option?: IOptionHiddenJson;
}

const ObjectOptionTypeJson: React.FC<IObjectOptionTypeJsonProps> =
    ({
         option,
         className
    }) => {
    if (!option) {
        return null;
    }
    return (
        <input type={'hidden'} className={cnObjectOption('HiddenJson', [className])} value={JSON.stringify(option.value)}/>

    );
}

export const withObjectOptionTypeJson = withBemMod<IObjectOptionTypeJsonProps, IObjectOptionProps>(
    cnObjectOption(),
    { type: OPTION_TYPE_JSON },
    () => ObjectOptionTypeJson
);