import React from 'react';
import {useTranslation} from 'react-i18next';
import {withBemMod} from '@bem-react/core';
import {ROLE_MANAGER} from '../../../../../common-code/constants';
import {cnWizardBuy, IWizardBuyProps} from '../index';
import {WizardOrderForm} from '../../WizardOrderForm/WizardOrderForm';

const WizardBuyUserManager: React.FC<IWizardBuyProps> =
    ({
        user,
         service,
         className
     }) => {
        const {t} = useTranslation();
        return (
            <div className={cnWizardBuy({user: user}, [className])}>
                <div className={cnWizardBuy('Title')}>{t('Оформление заказа')}</div>
                <div className={cnWizardBuy('Form')}>
                    {!service || <WizardOrderForm service={service} user={user}/> }
                </div>
            </div>
        );
    };
export const withWizardBuyUserManager = withBemMod<IWizardBuyProps>(
    cnWizardBuy(),
    { user: ROLE_MANAGER },
    () => WizardBuyUserManager
);