import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';
import {BOTTOM_UNIT_TOP_PANEL_WIDTH} from '../../../../../constants';

export class ThreeBottomCorpus extends ThreeCorpus {

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth() - this.getBackThickness()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), BOTTOM_UNIT_TOP_PANEL_WIDTH),
            'top1',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                -this.getDepth()/2 + BOTTOM_UNIT_TOP_PANEL_WIDTH/2 + this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), BOTTOM_UNIT_TOP_PANEL_WIDTH),
            'top2',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                this.getDepth()/2 - BOTTOM_UNIT_TOP_PANEL_WIDTH/2
            )
        );
    }
}