import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';

export class ThreeBottomBarStandCorpus extends ThreeCorpus {

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness()*2, this.getThickness(), this.getDepth() - this.getThickness()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
        this.getDepth() / 2 - (this.getDepth() - this.getThickness())/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness()*2, this.getThickness(), this.getDepth() - this.getThickness()),
            'top',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                this.getDepth() / 2 - (this.getDepth() - this.getThickness())/2
            )
        );
    }

    protected createBackPanels() {

    }
}