import {CommonObject} from '../CommonObject/CommonObject';
import {EditorService} from '../../services/EditorService/EditorService';
import {ThreeMathHelper} from '../../helpers/ThreeMathHelper/ThreeMathHelper';
import {Mesh, Vector2, Vector3} from 'three';
import {ThreeWall} from '../threeD/rooms/ThreeWall/ThreeWall';
import {ISegmentPoints} from '../../interfaces/ISegmentPoints';
import {ISaveWallData} from '../../../../common-code/interfaces/saveData/ISaveWallData';
import {TLine} from '../../../../common-code/types/TLine';
import {TwoDWall} from '../twoD/rooms/TwoDWall/TwoDWall';
import {TwoDSize} from '../twoD/TwoDSize/TwoDSize';

export class Wall extends CommonObject {
    sort: number;
    depth: number;
    points?: ISegmentPoints;
    stickPoints?: ISegmentPoints;
    visiblePoints?: ISegmentPoints;
    rightNeighbor?: ThreeWall | TwoDWall;
    leftNeighbor?: ThreeWall | TwoDWall;
    body?: Mesh;
    front?: Mesh;
    size?: TwoDSize;
    saveData: ISaveWallData;

    protected readonly data: ISaveWallData;

    constructor(wallData: ISaveWallData, service: EditorService) {
        super(wallData, service);
        this.saveData = wallData;
        this.depth = wallData.depth;
        this.sort = wallData.sort;
        this.data = wallData;
        this.hasCover = false;
    }

    public getDirection() {
        return this.saveData.direction;
    }

    protected calculateShiftPoint(
        point: Vector2,
        pointA: Vector2,
        pointB: Vector2,
        shift: number
    ): Vector3 {
        let shiftPoint: Vector2;

        shiftPoint = ThreeMathHelper.getShiftPoint2D(point, pointA, pointB, shift);

        return new Vector3(
            shiftPoint.x,
            0,
            shiftPoint.y
        );
    }

    public getPoints2D(): TLine {
        throw new Error('error-Wall-getPoints2D');
    }


}