import React, {MutableRefObject} from "react";
import {IClassNameProps} from "@bem-react/core";
import './Wizard-ReplaceMenu.css';
import {IReplaceData} from '../../../../interfaces/IReplaceData';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {ICreateObjectDomElements} from '../../../../interfaces/ICreateObjectDomElements';
import {cnWizard} from '../Wizard';
import {cnReplaceMenu, ReplaceMenu} from '../../ReplaceMenu/ReplaceMenu';

export interface IWizardReplaceMenuProps extends IClassNameProps {
    replaceData: IReplaceData
    service: KitchenService;
    createObjects: MutableRefObject<ICreateObjectDomElements>;
    onClose: (value: boolean) => void;
}

export const WizardReplaceMenu: React.FC<IWizardReplaceMenuProps> = ({className, replaceData, service, createObjects, onClose}) => {
    return (
        <div className={cnWizard(cnReplaceMenu(), [className])}>
            <ReplaceMenu replaceData={replaceData} service={service} createObjects={createObjects} onClose={onClose}/>
        </div>
    );
};