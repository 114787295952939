import {Reducer} from "redux";
import {CLOSE_SESSION, START_SESSION} from '../../constants';
import {IClientSessionData} from '../../../common-code/interfaces/session/IClientSessionData';

export const SessionReducer: Reducer = (state: IClientSessionData | null = null, action) => {
    switch (action.type) {
        case START_SESSION: {
            if (action.payload) {
                return {...action.payload};
            }
            return {};
        }
        case CLOSE_SESSION: {
            return {};
        }
        default: {
            return state;
        }
    }
};