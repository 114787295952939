import {ThreeFacade} from '../ThreeFacade';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';
import {TLine} from '../../../../../../../common-code/types/TLine';
import {
    FACADE_DEFAULT_BEVEL_SEGMENTS,
    FACADE_DEFAULT_BEVEL_SIZE,
    FACADE_DEFAULT_BEVEL_THICKNESS
} from '../../../../../constants';
import {
    BoxGeometry, DoubleSide,
    ExtrudeGeometry,
    FrontSide, Material,
    Mesh,
    MeshBasicMaterial, Object3D
} from 'three';
import {FACADE_MODEL_TYPE_GLASS} from '../../../../../../../common-code/constants';
import {MathHelper} from 'common-code';

export class ThreeSquareFacade extends ThreeFacade {

    public setDummyTransparent(value: boolean) {
        let child: Object3D;
        let childMesh: Mesh;
        let material: Material;

        for (child of this.dummy.children) {
            if (child.name === 'dummyBody' && 'isMesh' in child) {
                childMesh = child as Mesh;
                if (Array.isArray(childMesh.material)) {
                    for (material of childMesh.material) {
                        material.transparent = value;
                    }
                } else if ('isMaterial' in childMesh.material) {
                    childMesh.material.transparent = value;
                }
            }
        }
    }

    protected addToScene() {
        this.view3d.name = 'ThreeSquareFacade';
        this.unit.view3d.add(this.view3d);
    }

    protected createShape() {
        let index;
        let bevelPoints;

        bevelPoints = this.getBevelShapePoints();
        this.shape.moveTo(bevelPoints[0].x, bevelPoints[0].y);
        for (index = 1; index < bevelPoints.length; index++) {
            if (bevelPoints.hasOwnProperty(index)) {
                this.shape.lineTo(bevelPoints[index].x, bevelPoints[index].y);
            }
        }
        this.shape.lineTo(bevelPoints[0].x, bevelPoints[0].y);
    }

    protected getShapePoints(): TPoint2D[] {
        return [
            {x: -this.getWidth() / 2, y: -this.getHeight() / 2},
            {x: -this.getWidth() / 2, y: this.getHeight() / 2},
            {x: this.getWidth() / 2, y: this.getHeight() / 2},
            {x: this.getWidth() / 2, y: -this.getHeight() / 2},
        ];
    }

    protected getBevelShapePoints() {
        let points: TPoint2D[];
        let bevelPoints: TPoint2D[];
        let prevPoint: TPoint2D;
        let point: TPoint2D;
        let nextPoint: TPoint2D;
        let index;

        points = this.getShapePoints();
        bevelPoints = [];
        for (index = 0; index < points.length; index++) {
            if (points.hasOwnProperty(index)) {
                if (index === 0) {
                    prevPoint = points[points.length - 1];
                    point = points[index];
                    nextPoint = points[index + 1];
                } else if (index === points.length - 1) {
                    prevPoint = points[index - 1];
                    point = points[index];
                    nextPoint = points[0];
                } else {
                    prevPoint = points[index - 1];
                    point = points[index];
                    nextPoint = points[index + 1];
                }
                bevelPoints.push(this.getPointWithBevel(prevPoint, point, nextPoint));
            }
        }

        return bevelPoints;
    }

    protected getPointWithBevel(prevPoint: TPoint2D, point: TPoint2D, nextPoint: TPoint2D): TPoint2D {
        let prevLine: TLine, line: TLine, resultPoint: TPoint2D | undefined;

        // Initially FACADE_DEFAULT_BEVEL_SIZE instead of 0
        prevLine = MathHelper.getParallelLinePoints({x: prevPoint.x, y: prevPoint.y}, {
            x: point.x,
            y: point.y
        }, 0);
        line = MathHelper.getParallelLinePoints({x: point.x, y: point.y}, {
            x: nextPoint.x,
            y: nextPoint.y
        }, 0);

        if (prevLine && line) {
            resultPoint = MathHelper.getIntersectionPoint(prevLine, line);
        }
        if (!resultPoint) {
            resultPoint = {
                x: point.x,
                y: point.y
            };
        }
        return resultPoint;
    }

    protected createDummy() {
        let geometry;
        let body;
        let glass;
        let glassCarcass;
        let extrudeSettings;

        extrudeSettings = {
            steps: 1,
            depth: this.getDepth() - FACADE_DEFAULT_BEVEL_SIZE * 2,
            bevelEnabled: true,
            bevelThickness: FACADE_DEFAULT_BEVEL_THICKNESS,
            bevelSize: FACADE_DEFAULT_BEVEL_SIZE,
            bevelSegments: FACADE_DEFAULT_BEVEL_SEGMENTS
        };
        this.dummy.matrixAutoUpdate = false;
        geometry = new ExtrudeGeometry(this.shape, extrudeSettings);
        body = new Mesh(geometry, new MeshBasicMaterial({
            transparent: true,
            opacity: 0.4,
            side: FrontSide
        }));
        body.name = 'dummyBody';
        body.matrixAutoUpdate = false;
        this.dummy.add(body);
        this.createMeshCarcass(body, '#929292', 'dummyCarcass');
        if (this.getModelType() === FACADE_MODEL_TYPE_GLASS) {
            geometry = new BoxGeometry(this.getWidth() - 120, this.getHeight() - 120, this.getDepth());
            glass = new Mesh(geometry, new MeshBasicMaterial({
                transparent: true,
                opacity: 0.1,
                side: DoubleSide
            }));
            glass.name = 'dummyGlass';
            glass.matrixAutoUpdate = false;
            glass.position.z = 1;
            this.dummy.add(glass);
            glassCarcass = this.createMeshCarcass(glass, '#929292', 'dummyGlassCarcass');
            glassCarcass.position.z = 1;
            this.dummy.add(glassCarcass);
        }
        this.dummy.name = 'Dummy';
        this.view3d.add(this.dummy);
    }

    protected scaleThreeModel() {
        if (this.threeModelData) {
            this.threeModel.scale.setY(this.getHeight()/this.threeModelData.height);
            this.threeModel.scale.setX(this.getWidth()/this.threeModelData.width);
        }
        this.updateAllMatrices();
    }
}