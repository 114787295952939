import React from "react";
import { HANDLE_MODEL_TYPES as handleModelTypes } from "common-code/lib/constants";
import { alignValues, locations } from "../../constants/constants";
import { cnCreateForm } from "../../FormModule";
import { Select } from "../../../../../components/Select/Select";
import { editOption } from "../../../utils/editOption";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { Tumbler } from "../../../../../components/Tumbler/Tumbler";
import { IFacade } from "./initialFacade";

function Handle({
  facadeNew,
  onInputChange,
  onChecked,
}: {
  facadeNew: IFacade;
  onInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const {
    handle,
    handleAlignX,
    handleAlignY,
    handleAlign,
    handleMargin,
    handleModelType,
    location,
    handleMarginX,
    handleMarginY,
    handleMarginZ,
    handleRotation,
    handleRotationX,
    handleRotationY,
    handleRotationZ,
  } = facadeNew;
  return (
    <>
      <hr />
      <h4 className={cnCreateForm("Title")}>Ручка</h4>

      <Tumbler
        className={cnCreateForm("Tumbler")}
        checked={handle}
        callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
        label="Добавить ручку"
        name="handle"
      />

      {handle && (
        <div className={cnCreateForm("Modal-Content")}>
          <div>
            <label htmlFor="handleModelType">Добавить ручку</label>
            <Select
              className={cnCreateForm("Select")}
              placeholder={"Выберите значение"}
              showPlaceholder={false}
              options={editOption(handleModelTypes)}
              value={handleModelType}
              callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                onInputChange({
                  ...e,
                  target: { ...e.target, name: "handleModelType" },
                })
              }
            />

            <>
              <Tumbler
                className={cnCreateForm("Tumbler")}
                checked={handleAlign}
                callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChecked(e)
                }
                label="Добавить выравнивание для ручки"
                name="handleAlign"
              />

              <div className={cnCreateForm("Box-Input")}>
                {handleAlign && (
                  <>
                    <div>
                      <label htmlFor="handleAlignX">По горизонтали</label>
                      <Select
                        className={cnCreateForm("Select")}
                        placeholder={"Выберите значение"}
                        showPlaceholder={false}
                        options={editOption(alignValues)}
                        value={handleAlignX}
                        callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          onInputChange({
                            ...e,
                            target: { ...e.target, name: "handleAlignX" },
                          })
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="handleAlignY">По вертикали</label>
                      <Select
                        className={cnCreateForm("Select")}
                        placeholder={"Выберите значение"}
                        showPlaceholder={false}
                        options={editOption(alignValues)}
                        value={handleAlignY}
                        callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          onInputChange({
                            ...e,
                            target: { ...e.target, name: "handleAlignY" },
                          })
                        }
                      />
                    </div>
                  </>
                )}

                <div>
                  <label htmlFor="location">Ор-я в пространстве</label>
                  <Select
                    className={cnCreateForm("Select")}
                    placeholder={"Выберите значение"}
                    showPlaceholder={false}
                    options={editOption(locations)}
                    value={location as string}
                    callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      onInputChange({
                        ...e,
                        target: { ...e.target, name: "location" },
                      })
                    }
                  />
                </div>
              </div>

              <Tumbler
                className={cnCreateForm("Tumbler")}
                checked={handleMargin}
                callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChecked(e)
                }
                label="Добавить отступы для ручки"
                name="handleMargin"
              />

              {handleMargin && (
                <div className={cnCreateForm("Box-Input")}>
                  <div>
                    <label htmlFor="handleMarginX">Отступ по горизонтали</label>
                    <Textinput
                      className={cnCreateForm("Textinput")}
                      value={handleMarginX}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onInputChange(e)
                      }
                      name="handleMarginX"
                      type="number"
                    />
                  </div>
                  <div>
                    <label htmlFor="handleMarginY">Отступ по вертикали</label>
                    <Textinput
                      className={cnCreateForm("Textinput")}
                      value={handleMarginY}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onInputChange(e)
                      }
                      name="handleMarginY"
                      type="number"
                    />
                  </div>
                  <div>
                    <label htmlFor="handleMarginZ">Отступ в глубину</label>
                    <Textinput
                      className={cnCreateForm("Textinput")}
                      value={handleMarginZ}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onInputChange(e)
                      }
                      name="handleMarginZ"
                      type="number"
                    />
                  </div>
                </div>
              )}
              <Tumbler
                className={cnCreateForm("Tumbler")}
                checked={handleRotation as boolean}
                callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChecked(e)
                }
                label="Изменить положение ручки в пространстве"
                name="handleRotation"
              />

              {handleRotation && (
                <>
                  <div>
                    <label htmlFor="handleRotationX">
                      Указать вращение ручки по оси x (градусы от -90 до 90)
                    </label>
                    <Textinput
                      className={cnCreateForm("Textinput")}
                      value={handleRotationX}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onInputChange(e)
                      }
                      name="handleRotationX"
                      type="number"
                      maxLength={3}
                    />
                  </div>
                  <div>
                    <label htmlFor="handleRotationY">
                      Указать вращение ручки по оси y (градусы от -90 до 90)
                    </label>
                    <Textinput
                      className={cnCreateForm("Textinput")}
                      value={handleRotationY}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onInputChange(e)
                      }
                      name="handleRotationY"
                      type="number"
                      maxLength={3}
                    />
                  </div>
                  <div>
                    <label htmlFor="handleRotationZ">
                      Указать вращение ручки по оси z (градусы от -90 до 90)
                    </label>
                    <Textinput
                      className={cnCreateForm("Textinput")}
                      value={handleRotationZ}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onInputChange(e)
                      }
                      name="handleRotationZ"
                      type="number"
                      maxLength={3}
                    />
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
}

export default Handle;
