import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {cnProjects} from '../Projects';
import {IProjectFilterData} from '../../../../../common-code/interfaces/project/IProjectFilterData';
import {IProjectFilterItem} from '../../../../../common-code/interfaces/project/IProjectFilterItem';
import {useTranslation} from 'react-i18next';
import {ObjectOption} from '../../../components/ObjectOption/ObjectOption';
import {CommonObjectHelper} from '../../../../3d/helpers/CommonObjectHelper/CommonObjectHelper';
import {IOption} from '../../../../../common-code/interfaces/option/IOption';

export interface IProjectsFilterProps extends IClassNameProps {
    filterData: IProjectFilterData[];
    setFilterData: (filterData: IProjectFilterData[]) => void;
    filterItems: IProjectFilterItem[];
}

export const ProjectsFilter: React.FC<IProjectsFilterProps> =
    ({
         className,
         filterData,
         setFilterData,
         filterItems,
     }) => {
    const {t} = useTranslation();

    const onFilterItemChange = (fieldId: string, value: any) => {
        let newFilterData: IProjectFilterData[] = [];
        let filterDataItem: IProjectFilterData;
        let isSet: boolean = false;
        let filterItem: IProjectFilterItem;

        for (filterDataItem of filterData) {
            if (filterDataItem.name === fieldId) {
                filterDataItem.value = value as string;
                isSet = true;
            }
            newFilterData.push(filterDataItem)
        }
        if (!isSet) {
            for (filterItem of filterItems) {
                if (filterItem.name === fieldId) {
                    newFilterData.push({
                        type: filterItem.type,
                        name: filterItem.name,
                        value: value as string
                    });
                    break;
                }
            }
        }
        setFilterData(newFilterData);
    }

    return (
        <div className={cnProjects('Filter', {}, [className])}>
            <div className={cnProjects('FilterTitle')}>{t('Фильтр по проектам')}</div>

            {filterItems.map(filterItem => {
                let itemOption: IOption = CommonObjectHelper.prepareFilerOption(
                    filterItem,
                    filterData);
                return (
                    <div className={cnProjects('FilterItem')} key={filterItem.name}>
                        <ObjectOption
                            className={cnProjects('Option')}
                            option={itemOption}
                            key={filterItem.name + 'ObjectOption'}
                            type={itemOption.type}
                            onChange={onFilterItemChange}
                            createOptions={{}}
                        />
                    </div>
                );
            })}
        </div>
    );
}