import React from 'react'
import {
    HANDLE_MODEL_TYPE_DEFAULT,
    HANDLE_MODEL_TYPE_DEFAULT_V2,
    HANDLE_MODEL_TYPE_SMALL,
    MODEL_EXTENSION_GLB,
} from 'common-code/lib/constants'
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle'
import { cnModal, Modal } from '../../../../components/Modal'
import { cnCreateForm } from '../../FormModule/FormModule'
import { Button } from '../../../../components/Button'
import { INewHandleModelData } from '../defaultValues'
import { cnFormHandle } from '../FormHandle'
import { Select } from '../../../../components/Select/Select'

const optionsType = [
    { value: HANDLE_MODEL_TYPE_DEFAULT, content: HANDLE_MODEL_TYPE_DEFAULT },
    {
        value: HANDLE_MODEL_TYPE_DEFAULT_V2,
        content: HANDLE_MODEL_TYPE_DEFAULT_V2,
    },
    { value: HANDLE_MODEL_TYPE_SMALL, content: HANDLE_MODEL_TYPE_SMALL },
]
const optionsExt = [
    { value: MODEL_EXTENSION_GLB, content: MODEL_EXTENSION_GLB },
]

interface IShelfProps {
    modal: boolean
    handleToggleModal: () => void
    keyValue: string
    handleSubmit: () => void
    newHandleModel: INewHandleModelData
    setNewHandleModel: (newHandleModel: INewHandleModelData) => void
}

function ModalHandleModel({
    modal,
    handleToggleModal,
    newHandleModel,
    handleSubmit,
    setNewHandleModel,
    keyValue,
}: IShelfProps) {
    const onInputChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setNewHandleModel({
            ...newHandleModel,
            [e.target.name]: e.target.value,
        })
    }

    const onFocus = () => setNewHandleModel({ ...newHandleModel, error: '' })

    const { width, height, depth, type, ext, error } = newHandleModel

    const edit = keyValue.includes('edit')

    return (
        <Modal
            className={cnFormHandle(cnModal())}
            visible={modal}
            callBack={handleToggleModal}
            key={keyValue}
        >
            <h4 className={cnFormHandle('Header')}>
                {edit
                    ? 'Редактирование модели ручки'
                    : 'Добавление модели ручки'}
            </h4>

            <div className={cnFormHandle('Content')}>
                <div>
                    <label htmlFor="width">Ширина модели ручки</label>
                    <Textinput
                        className={cnFormHandle('Textinput')}
                        value={width}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e)
                        }
                        name="width"
                        type="number"
                        maxLength={3}
                        onFocus={onFocus}
                    />
                </div>
                <div>
                    <label htmlFor="height">Высота модели ручки</label>
                    <Textinput
                        className={cnFormHandle('Textinput')}
                        value={height}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e)
                        }
                        name="height"
                        type="number"
                        maxLength={3}
                        onFocus={onFocus}
                    />
                </div>
                <div>
                    <label htmlFor="depth">Глубина модели ручки</label>
                    <Textinput
                        className={cnFormHandle('Textinput')}
                        value={depth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e)
                        }
                        name="depth"
                        type="number"
                        maxLength={3}
                        onFocus={onFocus}
                    />
                </div>
                <label htmlFor="type">Тип ручки</label>
                <Select
                    className={cnFormHandle('Select')}
                    placeholder={'Выберите значение'}
                    showPlaceholder={false}
                    options={optionsType}
                    value={type}
                    callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        onInputChange({
                            ...e,
                            target: { ...e.target, name: 'type' },
                        })
                    }
                />
                    <label htmlFor="ext">Расширение для файла</label>
                <Select
                    className={cnFormHandle('Select')}
                    placeholder={'Выберите значение'}
                    showPlaceholder={false}
                    options={optionsExt}
                    value={ext}
                    callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        onInputChange({
                            ...e,
                            target: { ...e.target, name: 'ext' },
                        })
                    }
                />

                {error && <div className='text-danger'>{error}</div>} 
            </div>

            <Button
                className={cnCreateForm('Button')}
                type={'button'}
                text={edit ? 'Сохранить' : 'Добавить новую модель'}
                leftIcon={{
                    path: 'plus2',
                    width: 16,
                    height: 16,
                    marginRight: 8,
                }}
                mods={{
                    mods: {
                        bkg: 'white',
                        color: 'lightGreen',
                        border: 'lightGreen',
                        fill: 'lightGreen',
                    },
                    hover: {
                        mods: {
                            bkg: 'lightGreen',
                            color: 'white',
                            fill: 'white',
                        },
                    },
                }}
                callBack={handleSubmit}
            />
        </Modal>
    )
}

export default ModalHandleModel
