import {IClassNameProps} from "@bem-react/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {cnObjectSelectItem} from '../ObjectSelectItem';


export interface ICreateSelectObjectheightProps extends IClassNameProps {
    height?: number;
}

export const ObjectSelectItemHeight: React.FC<ICreateSelectObjectheightProps> =
    ({
         className,
         height,
    }) => {
        const {t} = useTranslation();

    if (!height) {
        return null;
    }
    return (
        <div
            className={cnObjectSelectItem('Height', [className])}
        >
            {t('Высота')}: {height} {t('мм')};
        </div>
    );
}