import React from "react";
import {Popup} from '../../../components/Popup';
import {useTranslation} from 'react-i18next';
import { cnCreateForm } from "../FormModule/FormModule";
import { Button as ButtonIcon } from '@yandex/ui/Button/Button';

function Trash({handleDeleteValue} : {handleDeleteValue: () => void}) {

    const [hoverIcon, setHoverIcon] = React.useState<string | null>(null);
    const {t} = useTranslation();

    return (  
        
        <div 
            className={cnCreateForm('Btn-Icon')}
            onMouseEnter={() => {setHoverIcon('trash')}}
            onMouseLeave={() => {setHoverIcon(null)}} >
            <Popup
                active={'trash' === hoverIcon}
                position={{x: -25, y: 0}}>
                {t('Удалить')}
            </Popup>
            <ButtonIcon
                type="button"
                className={cnCreateForm('Btn')}
                onClick={handleDeleteValue}
                >
        

                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4.75L1 4.75" stroke="#6c9763" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 7.5L5 11.5" stroke="#6c9763" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 7.5V11.5" stroke="#6c9763" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                        d="M11 4.75L11 14C11 14.1326 10.9473 14.2598 10.8536 14.3536C10.7598 14.4473 10.6326 14.5 10.5 14.5L2.5 14.5C2.36739 14.5 2.24021 14.4473 2.14645 14.3536C2.05268 14.2598 2 14.1326 2 14L2 4.75"
                        stroke="#6c9763"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.5 4V2.2C9.5 1.88174 9.37357 1.57652 9.14853 1.35147C8.92348 1.12643 8.61826 1 8.3 1L4.7 1C4.38174 1 4.07652 1.12643 3.85147 1.35147C3.62643 1.57652 3.5 1.88174 3.5 2.2L3.5 4"
                        stroke="#6c9763"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg> 
            </ButtonIcon>
        </div>    
    );
}

export default Trash;