import {IClassNameProps} from '@bem-react/core';
import {ICreateGroup} from '../../../../common-code/interfaces/createData/ICreateGroup';
import {ICreateObjectData} from '../../../../common-code/interfaces/createData/ICreateObjectData';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import React, {ChangeEvent, MutableRefObject, useState} from 'react';
import {ICreateObjectDomElements} from '../../../interfaces/ICreateObjectDomElements';
import {cn} from '@bem-react/classname';
import {CreateCommonObject} from '../CreateCommonObject/CreateCommonObject';
import {CreateObjectGroup} from '../CreateObjectGroup/CreateObjectGroup';
import {useTranslation} from 'react-i18next';
import {Button} from '../../components/Button';
import {Textinput} from '@yandex/ui/Textinput/desktop/bundle';
import './ModulesCatalog.css';
import {ModulesCatalogFilter} from './Filter/ModulesCatalog-Filter';

export const cnModulesCatalog = cn('ModulesCatalog');

export interface IModulesCatalogProps extends IClassNameProps {
    data: Array<ICreateGroup | ICreateObjectData>
    onCreateObject: (objectData: ICreateObjectData, width: string | null, corpusWidth: string | null) => void;
    service: KitchenService;
    createObjectDomElements: MutableRefObject<ICreateObjectDomElements>;
    title: string;
    hasFilter: boolean;
    type?: string;
}

export const ModulesCatalog: React.FC<IModulesCatalogProps> =
    ({
         className,
         data,
         onCreateObject,
         service,
         createObjectDomElements,
         title,
         hasFilter,
         type
     }) => {
                const {t} = useTranslation();
        const [searchText, setSearchText] = useState<string>('');
        const [activeGroup, setActiveGroup] = useState<string | boolean>('');

        const onBackCatalog = () => {
            setSearchText('');
            setActiveGroup(false);
        }

        return (
            <div className={cnModulesCatalog({}, [className])}>
                <div className={cnModulesCatalog('Header', {filter: hasFilter})}>
                    {hasFilter && <ModulesCatalogFilter service={service} />}
                    <div className={cnModulesCatalog('Title')}>
                        {title}
                    </div>
                    <div className={cnModulesCatalog('SearchContainer')}>
                        <Button className={cnModulesCatalog('Back')} type={'button'}
                                leftIcon={{path: 'arrow-left', height: 20, width: 20, marginRight: 5}}
                                disabled={searchText === '' && !activeGroup}
                                mods={{mods: {active: searchText !== '' || !!activeGroup}}}
                                callBack={onBackCatalog} text={t('Назад') || ''}/>
                        <div className={cnModulesCatalog('Search')}>
                            <Textinput
                                className={cnModulesCatalog('SearchText')}
                                placeholder={t('Поиск по каталогу')}
                                value={searchText}
                                minLength={3}
                                debounceTimeout={300}
                                onInput={(value: ChangeEvent<HTMLInputElement>) => {
                                    setSearchText(value.target.value);
                                }}
                            />
                            <Button className={cnModulesCatalog('SearchButton')}
                                    type={'button'} mods={{}}
                                    leftIcon={{path: 'search2', width: 20, height: 20, fill: 'lightBlack'}}
                                    callBack={() => {}}/>
                        </div>
                    </div>
                </div>
                <div className={cnModulesCatalog('ContentContainer', {filter: hasFilter})}>
                    <div className={cnModulesCatalog('Content')}>
                        {data.map((itemData, index) => {
                            if ('isGroup' in itemData) {
                                if (itemData.items.length <= 0) {
                                    return null;
                                }
                                if (activeGroup && itemData.id !== activeGroup) {
                                    return null;
                                }
                                
                                return <CreateObjectGroup
                                    key={itemData.id + index}
                                    groupData={itemData}
                                    active={itemData.id === activeGroup}
                                    onClick={() => {
                                        setActiveGroup(itemData.id);
                                    }}
                                    onCreateObject={onCreateObject}
                                    service={service}
                                    createObjectDomElements={createObjectDomElements}
                                    searchText={searchText}
                                    type={type}
                                />;
                            } else if('options' in itemData) {       
                                return <CreateCommonObject
                                    objectData={itemData}
                                    key={itemData.uid + index}
                                    createAction={onCreateObject}
                                    service={service}
                                    createObjects={createObjectDomElements}
                                    searchText={searchText}
                                    type={type}
                                />;
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }