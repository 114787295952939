import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTopShelfFiguredCorpus} from "../../../details/ThreeCorpus/types/ThreeTopShelfFiguredCorpus";
import {
    ISaveTopUnitShelfFiguredData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitShelfFiguredData';
import {
    ISaveTopShelfFiguredCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopShelfFiguredCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitShelfFigured extends ThreeTopUnit {
    corpus: ThreeTopShelfFiguredCorpus;

    constructor(options: ISaveTopUnitShelfFiguredData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);

    }

    protected initCorpus(corpusData: ISaveTopShelfFiguredCorpusData): ThreeTopShelfFiguredCorpus {
        return new ThreeTopShelfFiguredCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}