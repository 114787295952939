import React, { useState } from 'react'
import { OPTION_TYPE_RADIOBUTTON } from "common-code/lib/constants";
import {  useFormContext } from 'react-hook-form';
import SelectForm from '../../components/SelectForm';
import { cnCreateForm } from '../../FormModule';
import SydeType from './SydeType';
import { Button } from '../../../../../components/Button';
import { ChangeEvent } from '@yandex/ui/Menu/Menu';
import { IOption } from 'common-code/lib/interfaces/option/IOption';
import { IOptionRadioButton } from '../../../../../../../common-code/interfaces/option/IOptionRadioButton';
import ModalSideTypes from './ModalSideTypes';
import { IFormInput } from '../../initialValue';
import { TSelectItem } from 'common-code/lib/types/TSelectItem';

export const sideTypeDefault = {
    "left": "Левая",
    "right": "Правая"
}

export interface ISideType {
    id: string, 
    title: string
}

function SideTypes() {

    const { getValues, setValue, formState: { errors } } = useFormContext<IFormInput>();
    const [value, setValueState] = useState('');
    const [stateError, setStateError] = useState('');
    const [visible, setVisible] = useState(false);

    const [ sideTypes, setSideTypes ] = useState<Array<TSelectItem>>( getValues("sideType") ? (getValues("sideType") as TSelectItem[]) : []);     

    const arrOptions = sideTypes?.map(el => ({ value: el.id, content: el.title }));

    const sideType: IOption | null = sideTypes.length > 0 ? 
     {
        id: "sideType",
        defaultValue:  sideTypes?.find(el => el.id === getValues("sideTypeDefault")) ? getValues("sideTypeDefault") : sideTypes[0].id,
        type: OPTION_TYPE_RADIOBUTTON,
        title: "Сторона открывания",
        items: sideTypes,
        sort: 3,
      }  as IOptionRadioButton
      : null;

    React.useEffect(() => {
            if(sideTypes.length > 0 ) {;
            setValue("sideType", sideType );
            setValue("sideTypeDefault", sideType?.defaultValue);
        } else {
            setValue("sideType", null);
            setValue("sideTypeDefault", null);
        }

    // eslint-disable-next-line
    }, [sideType]);

    const handleSubmit = () => {
        if (!value) {
            setStateError("Необходимо указать сторону открывания");
            return;
        }
        if (sideTypes.some(el => el.id === value)) {
            setStateError("Такая сторона уже существует");
            return;
        }

        setSideTypes([...sideTypes, { id: value, title: sideTypeDefault[value as keyof typeof sideTypeDefault] }]);
        setVisible(false);
        setValueState('');
    };

    const handleToggle = () => {
        setVisible(!visible);
    };

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setValueState(e.target.value);
        setStateError('');
    }


  return (
   <>
        <h4 className={cnCreateForm('Header')}>Сторона открывания *</h4>

        {sideTypes.map((side, index) => (
               <SydeType key={index} side={side} setSideTypes={setSideTypes} sideTypes={sideTypes} />
            ))}
        
        {errors?.["sideType"]?.type === "required" && <p className='text-danger' role="alert">Не указана сторона открывания</p>} 

        { sideTypes.length > 0 &&
                <SelectForm  options={arrOptions} label="Сторона открывания по умолчанию *" name="sideTypeDefault" />
        }

        <Button
                className={cnCreateForm('Button')}
                type={'button'}
                text={'Добавить сторону открывания'}
                leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                mods={{
                    mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                    hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                }}
                callBack={handleToggle}
            />

        <ModalSideTypes 
            visible={visible} 
            handleToggle={handleToggle} 
            handleSubmit={handleSubmit} 
            value={value} 
            handleChange={handleChange} 
            stateError={stateError} 
        />

        <hr/>
   </>
  )
}

export default SideTypes