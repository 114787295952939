import {IClassNameProps} from '@bem-react/core';
import React, {MutableRefObject} from 'react';
import {cnWizard} from '../Wizard';
import {cn} from '@bem-react/classname';
import './Wizard-MoveObject.css'
import {ICreateObjectDomElement} from '../../../../interfaces/ICreateObjectDomElement';
import {Svg} from '../../../components/Svg';

export const cnWizardMoveObject = cn('cnWizardMoveObject');
export interface IWizardMoveObject extends IClassNameProps {
    grabbingObject:  MutableRefObject<ICreateObjectDomElement | undefined>;
    mousePosition: {x: number | null, y: number | null}
}

export const WizardMoveObject: React.FC<IWizardMoveObject> = ({
                                                                  className,
                                                                  grabbingObject,
                                                                  mousePosition
}) => {
    if (grabbingObject.current && mousePosition.x !== null && mousePosition.y !== null) {
        return (
            <div style={{position: 'fixed',top: mousePosition.y - 25, left: mousePosition.x - 52}}
                 className={cnWizardMoveObject({}, [cnWizard(cnWizardMoveObject()), className])}>
                {
                    grabbingObject.current.data.svgImage ?
                        <Svg icon={grabbingObject.current.data.svgImage}
                             className={cnWizardMoveObject('Image')}
                             label={grabbingObject.current.data.title}/> :
                        (
                            grabbingObject.current.data.image.length > 0 ?
                                <img src={grabbingObject.current.data.image}
                                     alt={grabbingObject.current.data.title}
                                     className={cnWizardMoveObject('Image')}/> :
                                <Svg icon={'no-photo'}
                                     className={cnWizardMoveObject('Image', {noPhoto: true})}
                                     label={grabbingObject.current.data.title}/>
                        )
                }
            </div>
        );
    }

    return null;

};