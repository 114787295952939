import {ThreeCorpus} from '../ThreeCorpus';
import {CylinderGeometry, Mesh, Shape, Vector2} from 'three';
import {CommonObjectHelper} from '../../../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';

export class ThreeTopBarRavennaCorpus extends ThreeCorpus {

    protected createPanels() {

    }

    protected createOtherDetails() {
        this.createCylinder();
    }

    protected removeOtherDetails() {
        this.removeCylinder();
    }

    protected createCylinder() {
        let cylinder: Mesh;

        cylinder = new Mesh(new CylinderGeometry(20, 20, this.getHeight() - this.getThickness()), this.getBodyMaterial());
        cylinder.position.y = -this.getThickness()/2;
        cylinder.position.z = this.getDepth()/2 - this.getWidth();
        cylinder.name = 'cylinder';
        this.view3d.add(cylinder);
    }

    protected removeCylinder() {

    }

    protected createShape() {
        let points: {[key: string]: Vector2} = {};
        let circlePoints: TPoint2D[];
        let circlePoint: TPoint2D;
        this.addUniquePoint(
            new Vector2(-this.getWidth() / 2, -this.getDepth() / 2),
            points
        );
        this.addUniquePoint(
            new Vector2(-this.getWidth() / 2, this.getDepth() / 2 - this.getWidth()/2),
            points
        );
        circlePoints = CommonObjectHelper.getCirclePoints(
            {x: -this.getWidth() / 2, y: this.getDepth() / 2 - this.getWidth()/2},
            {x: this.getWidth() / 2, y: this.getDepth() / 2 - this.getWidth()/2},
            {x:  0, y: this.getDepth() / 2 - this.getWidth()/2},
            this.getWidth()/2,
            true
        );
        for (circlePoint of circlePoints) {
            this.addUniquePoint(
                new Vector2(circlePoint.x, circlePoint.y),
                points
            );
        }
        this.addUniquePoint(
            new Vector2(this.getWidth() / 2, this.getDepth() / 2 - this.getWidth()/2),
            points
        );
        this.addUniquePoint(
            new Vector2(this.getWidth() / 2, -this.getDepth() / 2),
            points
        );

        this.shape = new Shape(Object.values(points));
    }

    protected createShapePanels() {
        let top: Mesh;

        top = this.createShapePanel();
        top.position.y = this.getHeight() / 2 - this.getThickness() / 2;
        top.name = 'top';
        this.view3d.add(top);
    }

    protected createBackPanels() {

    }
}