import React, { useState } from 'react';

import { cnFormMaterial, INewMaterialData } from '../FormMaterial';

import EnableFacade from './EnableFacade';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../redux/AppStore';
import { Tumbler } from '../../../../components/Tumbler/Tumbler';
import { Select } from '../../../../components/Select/Select';
import { Button } from '../../../../components/Button';

function EnableFacades ({newMaterial, setNewMaterial, value} : {newMaterial: INewMaterialData , setNewMaterial: (e: INewMaterialData)=> void, value: "enableFacades" | "disableFacades"}) {

    const facades  = useSelector((state: AppState) => state.wizard.facades);
    const facadesOptions = facades.map((el: {id: string, title: string}) => ({value: el.id, content: el.title}));


    
    const [addEnableFacades, setAddEnableFacades] = useState(false);
    const [error, setError] = useState("");
    const [enableFacade, setEnableFacade] = useState ("");


    const enableFacadeAdd = () => {

        if(!enableFacade) {
            setError("Поле не может быть пустым");
            return;
        }
        if(newMaterial[value]?.includes(enableFacade)) {
            setError("Фасад уже добавлен");
            return;
        }

        setNewMaterial({ ...newMaterial, [value]: [...(newMaterial[value] as string[]), enableFacade] });
        setError("");
        setEnableFacade("")
    }
        
  return (
    <>
        <Tumbler
            className={cnFormMaterial('Tumbler')}
            checked={addEnableFacades as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => setAddEnableFacades(e.target.checked)}
            label={`Добавить ${value === "enableFacades" ? "включаемые" : "исключаемые"} фасады`}
        />
    
        { addEnableFacades && 
            <> 
                <div className={cnFormMaterial('Modal-Content')}>

                    <h4 className={cnFormMaterial('Title')}>{`Добавление ${value === "enableFacades" ? "включаемых" : "исключаемых"} фасадов`}</h4>

                    <div className={cnFormMaterial('Values')}>

                        <div className={cnFormMaterial('Add-Values')} >

                            <p className={cnFormMaterial('Error')}>{error}</p>


                            <label htmlFor="type">Добавить  фасад</label>
                            <Select
                                className={cnFormMaterial('Select')}
                                value={enableFacade}
                                callBack={(e: React.ChangeEvent<HTMLSelectElement>) => setEnableFacade(e.target.value)}
                                options={facadesOptions}
                                placeholder={"Выберите значение"}
                            />
                        

                            <Button
                                className={cnFormMaterial('Button')}
                                type={'button'}
                                text={`Добавить  фасад`}
                                leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                                mods={{
                                    mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                                    hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                                }}
                                callBack={enableFacadeAdd}
                            />
                        </div> 

                

                        {newMaterial[value] && (newMaterial[value] as string[]).length > 0 && 

                            <div className={cnFormMaterial('Add-Values')}>
                                {newMaterial[value]?.map( (facade: string, index: number)=>
                                    <EnableFacade
                                        key={index}
                                        facade={facade}
                                        index={index}
                                        newMaterial={newMaterial}
                                        setNewMaterial={setNewMaterial}
                                        value={value}
                                        setError={setError}
                                    />
                                )}
                            </div> 
                        }
                    
                    </div>
                </div>
            </>
        }
    </>
  )
}

export default EnableFacades