import React, {MutableRefObject, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cnWizard} from '../Wizard';
import './Wizard-Modules.css'
import {cn} from '@bem-react/classname';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {ICreateObjectDomElements} from '../../../../interfaces/ICreateObjectDomElements';
import {ICreateGroup} from '../../../../../common-code/interfaces/createData/ICreateGroup';
import {ThreeUnit} from '../../../../3d/objects/threeD/ThreeUnit/ThreeUnit';
import {ICreateObjectData} from '../../../../../common-code/interfaces/createData/ICreateObjectData';
import {cnModulesCatalog, ModulesCatalog} from '../../ModulesCatalog/ModulesCatalog';
import {cnModal, Modal} from '../../../components/Modal';
import {CreateObjectOptions} from '../../CreateObjectOptions/CreateObjectOptions';

export const cnWizardModules = cn('WizardModules');

export interface IWizardModulesProps extends IClassNameProps {
    service: KitchenService;
    createObjects: MutableRefObject<ICreateObjectDomElements>;
    modules: ICreateGroup[];
    title: string;
    id: string;
}

export const WizardModules: React.FC<IWizardModulesProps> =
    ({
         service,
         createObjects,
         modules,
         title,
         id
     }) => {
        const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
        const [unitsForEquipments, setUnitsForEquipments] = useState<ThreeUnit[]>([]);
        const [objectData, setObjectData] = useState<ICreateObjectData | undefined>(undefined);
        const [width, setWidth] = useState<string | null>(null);
        const [corpusWidth, setCorpusWidth] = useState<string | null>(null);

        if (!modules) {
            return null;
        }        

        const onCreateObject = (objectData: ICreateObjectData, width: string | null, corpusWidth: string | null) => {
            let unitsForEquipments: ThreeUnit[];

            setWidth(width);
            setCorpusWidth(corpusWidth);
            setObjectData(objectData);
            if (objectData.builtIn) {
                unitsForEquipments = service.tryCreateBuiltInEquipment(objectData);
                if (unitsForEquipments.length > 0) {
                    setUnitsForEquipments(unitsForEquipments);
                }
            } else {
                setShowCreateModal(true);
            }
        }
        const onCloseModal = (visible: boolean) => {
            let unit: ThreeUnit;
            setObjectData(undefined);
            setShowCreateModal(visible);
            for (unit of unitsForEquipments) {
                unit.clearSelectCoverSelectColor();
            }
            setUnitsForEquipments([]);
        }
        

        return (
            <div className={cnWizardModules({}, [cnWizard(cnWizardModules())])}>
                <ModulesCatalog
                    key={id}
                    hasFilter={id === 'modules'}
                    className={cnWizardModules(cnModulesCatalog())}
                    data={modules}
                    onCreateObject={onCreateObject}
                    service={service}
                    createObjectDomElements={createObjects}
                    title={title}
                />
                {showCreateModal && objectData ?
                    <Modal
                        className={cnWizardModules(cnModal())}
                        visible={showCreateModal}
                        callBack={onCloseModal}
                        key={id+'Modal'}
                    >
                        <CreateObjectOptions
                            objectData={objectData}
                            service={service}
                            onClose={onCloseModal}
                            width={width}
                            corpusWidth={corpusWidth}
                        />
                    </Modal> : null
                }
            </div>
        );
    };