import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./AmoForm.css";
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {cnInput} from "../../components/Input";
import {
    INPUT_TYPE_PHONE,
    INPUT_TYPE_TEXT,
    INPUT_TYPE_TEXTAREA,
} from "../../constants";
import {Input} from "../../components/Input/Input";
import {Checkbox, cnCheckbox} from "../../components/Checkbox";
import {Button, cnButton} from "../../components/Button";

export const cnAmoForm = cn('AmoForm');

export interface IAmoFormProps extends IClassNameProps {
    callBack: (value: boolean) => void;
}

export const AmoForm: React.FC<IAmoFormProps> = ({className, callBack}) => {
    const [userName, setUserName] = useState<string>('');
    const [userPhone, setUserPhone] = useState<string>('');
    const [userComment, setUserComment] = useState<string>('');
    const [checkWhatsApp, setCheckWhatsApp] = useState<boolean>(true);
    const [checkPersonal, setCheckPersonal] = useState<boolean>(true);
    const [formErrors, setFormErrors] = useState<any>({});
    const {t} = useTranslation();

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (formErrors && formErrors[event.target.name]) {
            delete formErrors[event.target.name];
            setFormErrors(formErrors);
        }
        switch (event.target.name) {
            case "userName":
                setUserName(event.target.value);
                break;
            case "userPhone":
                setUserPhone(event.target.value);
                break;
            case "userComment":
                setUserComment(event.target.value);
                break;
        }
    };

    const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (formErrors && formErrors[event.target.name]) {
            delete formErrors[event.target.name];
            setFormErrors(formErrors);
        }
        switch (event.target.name) {
            case "checkWhatsApp":
                setCheckWhatsApp(event.target.checked);
                break;
            case "checkPersonal":
                setCheckPersonal(event.target.checked);
                break;
        }
    };

    const validateForm = (): boolean => {
        let newFormErrors: any = {};

        if (userName.length <= 0) {
            newFormErrors['userName'] = t('Имя обязательно для заполнения');
        }
        if (userPhone.length <= 0 || !validatePhone(userPhone)) {
            newFormErrors['userPhone'] = t('Телефон обязателен для заполнения');
        }
        if (!checkPersonal) {
            newFormErrors['checkPersonal'] = t('Необходимо согласиться у условиями обработки персональных данных');
        }

        return Object.keys(newFormErrors).length <= 0;
    };

    const validatePhone = (phoneValue: string) => {
        phoneValue = clearPhone(phoneValue);

        return (phoneValue.length === 11) && phoneValue[0] === '8' && phoneValue[1] === '9';
    };

    const clearPhone = (phone: string): string => {
        return phone.replace(/(\+7)/, '8')
            .replaceAll(/(\+7)|(\()|(\))|(-)|(_)|(\s)/g, '');
    };

    const processSending = () => {
        // TODO! Отправить форму на сервер!
        if (!validateForm()) {
            return;
        }

        callBack(false);
    }

    return (
        <div className={cnAmoForm({}, [className])}>
            <p className={cnAmoForm('Header')}>{t('Заказать расчет кухни')}</p>
            <Input
                className={cnAmoForm(cnInput({error: !!(formErrors && formErrors['userName'])}))}
                type={INPUT_TYPE_TEXT}
                value={userName}
                name={'userName'}
                placeholder={t('Имя *') || undefined}
                onChange={onChangeInput}
            />
            <Input
                className={cnAmoForm(cnInput({error: !!(formErrors && formErrors['userPhone'])}))}
                type={INPUT_TYPE_PHONE}
                value={userPhone}
                name={'userPhone'}
                placeholder={t('Телефон *') || undefined}
                onChange={onChangeInput}
            />
            <Checkbox
                className={cnAmoForm(cnCheckbox({color: 'lightBlack'}))}
                checked={checkWhatsApp}
                callBack={onChangeCheckbox}
                name={'checkWhatsApp'}
                label={<>{`${t('Отправить результат на WhatsApp.')} `}</>
                }
            />
            <Input
                className={cnAmoForm(cnInput(), {stretch: true})}
                type={INPUT_TYPE_TEXTAREA}
                value={userComment}
                name={'userComment'}
                placeholder={t('Вопрос или комментарий') || undefined}
                onChange={onChangeInput}
            />
            <Checkbox
                className={cnAmoForm(cnCheckbox({color: 'lightBlack', error: !!(formErrors && formErrors['checkPersonal'])}))}
                checked={checkPersonal}
                callBack={onChangeCheckbox}
                name={'checkPersonal'}
                label={<>{`${t('Согласен на обработку моих персональных данных.')} `}</>
                }
            />
            <div className={cnAmoForm('Line')}></div>
            <p className={cnAmoForm('Notes')}>{t('Поля, отмеченные * обязательны для заполнения')}</p>
            {(Object.keys(formErrors).length > 0) &&
                <div className={cnAmoForm('Errors')}>
                    {Object.keys(formErrors).map(key =>
                        <p
                            key={key}
                            className={cnAmoForm('Error')}
                        >
                            {formErrors[key]}
                        </p>
                    )}
                </div>
            }
            <Button
                className={cnAmoForm(cnButton())}
                leftIcon={{path: 'calc', width: 20, height: 20, marginRight: 8}}
                text={t('Заказать расчет') || undefined}
                callBack={() => {processSending()}}
                mods={{mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}}}
                type={'button'}
            />
        </div>
    );
}