import {ThreeCorpus} from "../ThreeCorpus";
import {ThreeKUnit} from "../../../units/ThreeKUnit/ThreeKUnit";
import {BoxGeometry, BufferGeometry, Euler, Mesh, Vector3} from "three";
import {ThreeShelf} from "../../ThreeShelf/ThreeShelf";
import {ISaveRackFrameCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveRackFrameCorpusData';
import {ISaveShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfData';
import {CommonHelper} from 'common-code';
import {IMaterialData} from '../../../../../../../common-code/interfaces/materials/IMaterialData';

export class ThreeRackFrameCorpus extends ThreeCorpus {
    frames: Mesh[];
    saveData: ISaveRackFrameCorpusData;
    constructor(options: ISaveRackFrameCorpusData, unit: ThreeKUnit) {
        super(options, unit);
        this.saveData = options;
        this.frames = [];
    }

    public getFrameWidth(): number {
        return +this.saveData.frame.width;
    }

    public getFrameDepth(): number {
        return +this.saveData.frame.depth;
    }

    public getCentralShelf(): boolean | undefined {
        return this.saveData.centralShelf;
    }

    public getInnerWidth() {
        return this.saveData.sizes.length - this.getFrameWidth() * 2;
    }

    public getInnerHeight() {
        return this.saveData.sizes.height - this.getFrameWidth() * 2;
    }

    public getInnerDepth() {
        return this.saveData.sizes.width - this.getFrameWidth() * 2;
    }

    protected createBackPanels() {

    }

    public getPanelMaterialId(): string | undefined {
        return this.saveData.panelMaterial;
    }

    public getFrameMaterialId(): string | undefined {
        return this.saveData.frameMaterial;
    }

    protected createShelves() {
        let shelfData: ISaveShelfData;
        let createShelfData: ISaveShelfData;
        let shelf: ThreeShelf;

        for (shelfData of this.saveData.shelves) {
            createShelfData = CommonHelper.deepCopy(shelfData);
            createShelfData.materialId = this.getPanelMaterialId();
            shelf = new ThreeShelf(createShelfData, this);
            shelf.initState();
            shelf.createView();
            this.shelves.push(shelf);
        }
    }

    protected createPanels() {

    }

    protected initMaterialData(materialId?: string): IMaterialData {
        return this.service.getFrameMaterial(materialId);
    }
    protected createFrames() {

        this.createFrame(
            new BoxGeometry(this.getFrameWidth(), this.getHeight(), this.getFrameDepth()),
            'verticalLeftBack',
            new Vector3(
                -this.getWidth()/2 + this.getFrameWidth()/2,
                0,
                -this.getDepth()/2 + this.getFrameDepth()/2
            )
        );
        this.createFrame(
            new BoxGeometry(this.getFrameWidth(), this.getHeight(), this.getFrameDepth()),
            'verticalLeftFront',
            new Vector3(
                -this.getWidth()/2 + this.getFrameWidth()/2,
                0,
                this.getDepth()/2 - this.getFrameDepth()/2
            )
        )
        this.createFrame(
            new BoxGeometry(this.getFrameWidth(), this.getHeight(), this.getFrameDepth()),
            'verticalRightBack',
            new Vector3(
                this.getWidth()/2 - this.getFrameWidth()/2,
                0,
                -this.getDepth()/2 + this.getFrameDepth()/2
            )
        )
        this.createFrame(
            new BoxGeometry(this.getFrameWidth(), this.getHeight(), this.getFrameDepth()),
            'verticalRightFront',
            new Vector3(
                this.getWidth()/2 - this.getFrameWidth()/2,
                0,
                this.getDepth()/2 - this.getFrameDepth()/2
            )
        )
        this.createFrame(
            new BoxGeometry(this.getWidth() - this.getFrameWidth() * 2, this.getFrameDepth(), this.getFrameWidth(),),
            'verticalBackTop',
            new Vector3(
                0,
                this.getHeight()/2 - this.getFrameWidth()/2,
                -this.getDepth()/2 + this.getFrameDepth()/2
            )
        )
        this.createFrame(
            new BoxGeometry(this.getWidth() - this.getFrameWidth() * 2, this.getFrameDepth(), this.getFrameWidth(),),
            'verticalFrontTop',
            new Vector3(
                0,
                this.getHeight()/2 - this.getFrameWidth()/2,
                this.getDepth()/2 - this.getFrameDepth()/2
            )
        )
        this.createFrame(
            new BoxGeometry(this.getFrameWidth(), this.getFrameDepth(), this.getDepth() - this.getFrameDepth() * 2,),
            'verticalLeftTop',
            new Vector3(
                -this.getWidth()/2 + this.getFrameWidth()/2,
                this.getHeight()/2 - this.getFrameWidth()/2,
                0
            )
        )
        this.createFrame(
            new BoxGeometry(this.getFrameWidth(), this.getFrameDepth(), this.getDepth() - this.getFrameDepth() * 2,),
            'verticalRightTop',
            new Vector3(
                this.getWidth()/2 - this.getFrameWidth()/2,
                this.getHeight()/2 - this.getFrameWidth()/2,
                0
            )
        )
        this.createFrame(
            new BoxGeometry(this.getWidth() - this.getFrameWidth() * 2, this.getFrameDepth(), this.getFrameWidth(),),
            'verticalBackBottom',
            new Vector3(
                0,
                -this.getHeight()/2 + this.getFrameWidth()/2,
                -this.getDepth()/2 + this.getFrameDepth()/2
            )
        )
        this.createFrame(
            new BoxGeometry(this.getWidth() - this.getFrameWidth() * 2, this.getFrameDepth(), this.getFrameWidth(),),
            'verticalFrontBottom',
            new Vector3(
                0,
                -this.getHeight()/2 + this.getFrameWidth()/2,
                this.getDepth()/2 - this.getFrameDepth()/2
            )
        )
        this.createFrame(
            new BoxGeometry(this.getFrameWidth(), this.getFrameDepth(), this.getDepth() - this.getFrameDepth() * 2,),
            'verticalLeftBottom',
            new Vector3(
                -this.getWidth()/2 + this.getFrameWidth()/2,
                -this.getHeight()/2 + this.getFrameWidth()/2,
                0
            )
        )
        this.createFrame(
            new BoxGeometry(this.getFrameWidth(), this.getFrameDepth(), this.getDepth() - this.getFrameDepth() * 2,),
            'verticalRightBottom',
            new Vector3(
                this.getWidth()/2 - this.getFrameWidth()/2,
                -this.getHeight()/2 + this.getFrameWidth()/2,
                0
            )
        )

        if (this.getCentralShelf()) {
            this.createFrame(
                new BoxGeometry(this.getWidth() - this.getFrameWidth() * 2, this.getFrameDepth(), this.getFrameWidth(),),
                'verticalBackCenter',
                new Vector3(
                    0,
                    0,
                    -this.getDepth()/2 + this.getFrameDepth()/2
                )
            )
            this.createFrame(
                new BoxGeometry(this.getWidth() - this.getFrameWidth() * 2, this.getFrameDepth(), this.getFrameWidth(),),
                'verticalFrontCenter',
                new Vector3(
                    0,
                    0,
                    this.getDepth()/2 - this.getFrameDepth()/2
                )
            )
            this.createFrame(
                new BoxGeometry(this.getFrameWidth(), this.getFrameDepth(), this.getDepth() - this.getFrameDepth() * 2,),
                'verticalLeftCenter',
                new Vector3(
                    -this.getWidth()/2 + this.getFrameWidth()/2,
                    0,
                    0
                )
            )
            this.createFrame(
                new BoxGeometry(this.getFrameWidth(), this.getFrameDepth(), this.getDepth() - this.getFrameDepth() * 2,),
                'verticalRightCenter',
                new Vector3(
                    this.getWidth()/2 - this.getFrameWidth()/2,
                    0,
                    0
                )
            )

        }
    }

    protected createFrame(geometry: BufferGeometry, name: string, position?: Vector3, rotation?: Euler) {
        let frame: Mesh;

        frame = new Mesh(geometry, this.getBodyMaterial());
        frame.matrixAutoUpdate = false;
        frame.name = name;
        if (position) {
            frame.position.copy(position);
        }
        if (rotation) {
            frame.rotation.copy(rotation);
        }
        this.view3d.add(frame);
        this.frames.push(frame);

        return frame;
    }
}