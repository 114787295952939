import React from 'react';
import Trash from '../../../../icons/Trash';
import { cnCreateForm } from '../../../FormModule';
import { ISaveBoxData } from 'common-code/lib/interfaces/saveData/ISaveBoxData';
import Edit from '../../../../icons/Edit';
import ModalBoxes from './ModalBoxes';
import { INewBox, initialBox } from './initialBox';
import { validateBox } from './validateBox';
import { useFormContext } from 'react-hook-form';
import { IFormInput } from '../../../initialValue';

interface IBoxProps {
  arrBoxes: ISaveBoxData[];
  setArrBoxes: (arr: ISaveBoxData[]) => void;
  box: ISaveBoxData;
  index: number;
  createBox: (box: INewBox) => ISaveBoxData;
}


function Box ({
      arrBoxes, 
      setArrBoxes, 
      box,
      index,
      createBox
    } : IBoxProps) {

    const { getValues }= useFormContext<IFormInput>();
    const [newBox, setNewBox] = React.useState<INewBox>(initialBox(box));

    const handleSubmit =  () => {
      const maxHeight = getValues('height') ? Number(getValues('height')) : 500;
      const maxWidth = getValues('defaultWidth') ? Number(getValues('defaultWidth')) : 500;
      const error = validateBox(newBox,maxHeight,maxWidth);
      if (error) {
          setNewBox({ ...newBox, error});
          return;
      }

      const boxEdit = createBox(newBox);
      setArrBoxes(arrBoxes.map((el) => el.initPosition === box.initPosition ? boxEdit : el));
      setNewBox({ ...newBox, modal: false});
  }
    
    const handleDeleteBox = () =>setArrBoxes(arrBoxes.filter((el, ind)=> ind !== index));

    const handleToggleModal = () => {
      setNewBox({ ...newBox, modal: !newBox.modal});
  }

  return (
    <div className={cnCreateForm('Children')}>
        <span>Ящик позиция по горизонтали: {box?.initPosition?.x} по вертикали: {box?.initPosition?.y}</span>

        <div className={cnCreateForm('Box-Btn')}>
          <Edit handleToggleModal={handleToggleModal} />
          <Trash handleDeleteValue={handleDeleteBox} />
        </div>

        <ModalBoxes 
            handleToggleModal={handleToggleModal}
            setNewBox={setNewBox}
            newBox={newBox}
            handleSubmit={handleSubmit}
            keyValue={`#editBox-${index}`}
        />
    </div>
  )
}

export default Box