import React from 'react';
import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import './LoadingTextureData.css'
import {Spin} from '@yandex/ui/Spin/bundle';
import {useTranslation} from 'react-i18next';
export const cnLoadingTextureData = cn('LoadingTextureData');
export interface ILoadingTextureDataProps extends IClassNameProps {
    loading: boolean;
}

export const LoadingTextureData: React.FC<ILoadingTextureDataProps> = ({className, loading}) => {
    const {t} = useTranslation();
    return (
        <div className={cnLoadingTextureData({visible: loading}, [className])}>
            <div className={cnLoadingTextureData('Content')}>
                <Spin
                    className={cnLoadingTextureData('Spin')}
                    view={'default'}
                    size={'xxs'}
                    progress={loading}
                />
                <span>{t('Загрузка текстур')}...</span>
            </div>

        </div>
    );
}