import {CommonDetail} from '../../../CommonDetail/CommonDetail';
import {ThreeCorpus} from '../ThreeCorpus/ThreeCorpus';
import {MeshStandardMaterial, Shape, Vector3} from "three";
import {ISaveBoxData} from '../../../../../../common-code/interfaces/saveData/ISaveBoxData';
import {TSizes} from '../../../../../../common-code/types/geometry/TSizes';
import {CommonHelper} from 'common-code';
import {KitchenHelper} from 'common-code';

export class ThreeBox extends CommonDetail {
    saveData: ISaveBoxData;
    corpus: ThreeCorpus;
    sizes: TSizes;
    shape?: Shape;
    bodyMaterial?: MeshStandardMaterial;

    constructor(options: ISaveBoxData, corpus: ThreeCorpus, shape?: Shape) {
        super(options, corpus);
        this.corpus = corpus;
        this.saveData = options;
        this.sizes = this.initSizes();
        this.shape = shape;
    }

    public initState(isRebuild?: boolean) {
        this.createMeshes();
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        this.corpus.view3d.add(this.view3d);
        super.createView(isRebuild);
    }

    public getData(): ISaveBoxData {
        let data: ISaveBoxData = CommonHelper.deepCopy(this.saveData);
        data.sizes = {...this.sizes};
        return data;
    }

    public getThickness() {
        return +this.saveData.thickness;
    }

    public getBottomThickness() {
        return +this.saveData.bottomThickness;
    }

    public getWidth() {
        return +this.sizes.length;
    }

    public getDepth() {
        return +this.sizes.width;
    }

    public getHeight() {
        return +this.sizes.height;
    }

    public getRailGap() {
        return +this.saveData.railGap;
    }

    public getFrontSide() {
       return this.saveData.frontSide;
    }

    protected initPosition(): Vector3 {
        let position: Vector3;

        position = new Vector3();
        if (this.saveData.initPosition) {
            if (this.saveData.initPosition.x !== undefined) {
                position.x = -this.corpus.getInnerWidth()/2 + KitchenHelper.calculateSizeByParent(
                    this.saveData.initPosition.x,
                    this.corpus.getInnerWidth(),
                    this.service.getDataForSizeByParent()
                );
            }
            if (this.saveData.initPosition.y !== undefined) {
                position.y = -this.corpus.getInnerHeight()/2 + KitchenHelper.calculateSizeByParent(
                    this.saveData.initPosition.y,
                    this.corpus.getInnerHeight(),
                    this.service.getDataForSizeByParent()
                );
            }
            if (this.saveData.initPosition.z !== undefined) {
                position.z = -this.corpus.getDepth()/2 + this.corpus.getBackThickness() +
                    KitchenHelper.calculateSizeByParent(
                        this.saveData.initPosition.z,
                        this.corpus.getInnerDepth(),
                        this.service.getDataForSizeByParent()
                    );
            }
        }
        if (this.saveData.position) {
            if (this.saveData.position.x !== undefined && !isNaN(+this.saveData.position.x)) {
                position.x = +this.saveData.position.x;
            }
            if (this.saveData.position.y !== undefined && !isNaN(+this.saveData.position.y)) {
                position.y = +this.saveData.position.y;
            }
            if (this.saveData.position.z !== undefined && !isNaN(+this.saveData.position.z)) {
                position.z = +this.saveData.position.z;
            }
        }

        position.x += this.getWidth() / 2;
        position.y += this.getHeight() / 2 + this.getBottomThickness() / 2;

        return position;
    }

    protected initSizes(): TSizes {
        let sizes: TSizes;

        sizes = {
            length: KitchenHelper.calculateSizeByParent(
                this.saveData.length,
                this.corpus.getInnerWidth(),
                this.service.getDataForSizeByParent()
            ),
            width: KitchenHelper.calculateSizeByParent(
                this.saveData.depth,
                this.corpus.getInnerDepth(),
                this.service.getDataForSizeByParent()
            ),
            height: KitchenHelper.calculateSizeByParent(
                this.saveData.height,
                this.corpus.getInnerHeight(),
                this.service.getDataForSizeByParent()
            )
        }

        return sizes;
    }

    protected createMeshes() {

    }

    protected createBodyMaterial(): MeshStandardMaterial {
        if (!this.bodyMaterial) {
            this.bodyMaterial = this.corpus.getBodyMaterial();
        }

        return this.bodyMaterial;
    }

}