import React, { useState } from "react";
import { INewPlinthData, cnFormPlinth } from "../FormPlinth";
import { TTextureType } from "common-code/lib/types/TTextureType";
import { TTextureExtension } from "common-code/lib/types/TTextureExtension";
import { Tumbler } from "../../../../components/Tumbler/Tumbler";
import { ITextureData } from "common-code/lib/interfaces/materials/ITextureData";
import Texture from "./Texture";
import { Button } from "../../../../components/Button";
import TextureNew from "./TextureNew";
import ModalTexture from "../../FormMaterial/Textures/ModalTexture";
// import ModalTexture from "./ModalTexture"
import { TPoint2D } from "../../../../../../common-code/code/lib/types/TPoint2D";
import { TImageSizes } from "../../../../../../common-code/code/lib/types/TImageSizes";

export interface INewTexture {
    path: string;
    type: string;
    file: string;
    fileNew: File | null;
    repeatX: string;
    repeatY: string;
    offset: boolean;
    offsetX: string;
    offsetY: string;
    sizes: boolean;
    sizesWidth: string;
    sizesHeight: string;
    visible: boolean;
    error: string;
    url: string;
  }

  export interface INewTextureData {
    fileNew: File;
    type: TTextureType;
    repeat: TPoint2D;
    offset?: TPoint2D;
    sizes?: TImageSizes;
    url?: string;
  }

function Textures({
  newPlinth,
  setNewPlinth,
}: {
  
  newPlinth: INewPlinthData;
  setNewPlinth: (e: INewPlinthData) => void;
}) {
  const initionalTexture: INewTexture = {
    path: "",
    type: "texture",
    file: "",
    fileNew: null,
    repeatX: "1",
    repeatY: "1",

    offset: false,
    offsetX: "0",
    offsetY: "0",

    sizes: false,
    sizesWidth: "0",
    sizesHeight: "0",

    visible: false,
    error: "",
    url: "",
  };
  const { textures, texturesNew } = newPlinth;
  const [addTextures, setAddTextures] = useState(!!textures && textures.length > 0);
  const [newTexture, setNewTexture] = useState(initionalTexture);
  const { visible } = newTexture;

  const createTextureNew = ({
    type,
    repeatX,
    repeatY,
    offset,
    offsetX,
    offsetY,
    sizes,
    sizesWidth,
    sizesHeight,
    fileNew,
    url,
  }: {
    type: string;
    repeatX: string;
    repeatY: string;
    offset: boolean;
    offsetX: string;
    offsetY: string;
    sizes: boolean;
    sizesWidth: string;
    sizesHeight: string;
    fileNew: File | null;
    url: string;
  }) => {
    const textureNew: INewTextureData = {
      fileNew: fileNew as File,
      type: type as TTextureType,

      repeat: {
        x: Number(repeatX),
        y: Number(repeatY),
      },
    };

    if (offset) {
      textureNew.offset = {
        x: Number(offsetX),
        y: Number(offsetY),
      };
    }
    if (sizes) {
      textureNew.sizes = {
        width: Number(sizesWidth),
        height: Number(sizesHeight),
      };
    }

    textureNew.url = URL.createObjectURL(textureNew.fileNew);
    console.log("textureNew", textureNew);

    return textureNew;
  };



  const validateTexture = ({
    type,
    fileNew,
    path,
  }: {
    type: string;
    fileNew: File | null;
    path: string;
  }) => {
    if (!fileNew && !path) {
      return "Загрузите текстуру";
    } else if (
      !["jpg", "png", "jpeg"].includes(
        fileNew?.name.split(".")[1] as TTextureExtension
      )
    ) {
      return "Необходимо загрузить текстуру с расширением  jpg , png , jpeg";
    }

    if (!type) {
      return "Укажите тип текстуры";
    }
    return "";
  };

  const handleSubmit = () => {
    const error = validateTexture(newTexture);
    if (error) {
      setNewTexture({ ...newTexture, error: error });
      return;
    }
    const textureNew = createTextureNew(newTexture);

    const texturesNew = newPlinth.texturesNew?.find(
      (texture) => texture.type === textureNew.type
    )
      ? newPlinth.texturesNew.map((texture) =>
          texture.type === textureNew.type ? textureNew : texture
        )
      : [...(newPlinth.texturesNew as INewTextureData[]), textureNew];

    const textures =
      newPlinth.textures &&
      newPlinth.textures.find((texture) => texture.type === textureNew.type)
        ? newPlinth.textures.filter(
            (texture) => texture.type !== textureNew.type
          )
        : newPlinth.textures;

    setNewPlinth({ ...newPlinth, texturesNew, textures });
    setNewTexture(initionalTexture);
  };

  const handleToggleModal = () =>{
    console.log(newTexture)
    setNewTexture({ ...newTexture, visible: !visible, error: "" });
  }
    

  return (
    <>
      <Tumbler
        className={cnFormPlinth("Tumbler")}
        checked={addTextures as boolean}
        callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
          setAddTextures(e.target.checked)
        }
        label="Добавить текстуру"
      />

      {addTextures && (
        <>
          <h4 className={cnFormPlinth("Title")}>Текстуры</h4>

          {textures && textures.length > 0 && (
            <div className={cnFormPlinth("Add-Values")}>
              {textures.map((texture: ITextureData, index: number) => (
                <Texture
                  key={index}
                  texture={texture}
                  index={index}
                  newPlinth={newPlinth}
                  setNewPlinth={setNewPlinth}
                  validateTexture={validateTexture}
                  createTextureNew={createTextureNew}
                />
              ))}
            </div>
          )}

          {texturesNew && texturesNew.length > 0 && (
            <div className={cnFormPlinth("Add-Values")}>
              {texturesNew.map((texture: INewTextureData, index: number) => (
                <TextureNew
                  key={index}
                  texture={texture}
                  index={index}
                  newPlinth={newPlinth}
                  setNewPlinth={setNewPlinth}
                  validateTexture={validateTexture}
                  createTextureNew={createTextureNew}
                />
              ))}
            </div>
          )}

          <Button
            className={cnFormPlinth("Button")}
            type={"button"}
            text={`Добавить текстуру`}
            leftIcon={{ path: "plus2", width: 16, height: 16, marginRight: 8 }}
            mods={{
              mods: {
                bkg: "white",
                color: "lightGreen",
                border: "lightGreen",
                fill: "lightGreen",
              },
              hover: {
                mods: { bkg: "lightGreen", color: "white", fill: "white" },
              },
            }}
            callBack={handleToggleModal}
          />

          <ModalTexture
            handleToggleModal={handleToggleModal}
            setNewTexture={setNewTexture}
            newTexture={newTexture}
            handleSubmit={handleSubmit}
            keyValue="addTexture"
          />
        </>
      )}
    </>
  );
}

export default Textures;
