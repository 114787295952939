import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './Select.css';
import {Select as SelectBase} from '@yandex/ui/Select/desktop/bundle';
import {Option} from '@yandex/ui/Select';
import usePointerEvent from "../../../hooks/usePointerEvent";
import useInstanceOfRootElement from "../../../hooks/useInstanceOfRootElement";
import {PointerEvent} from 'react';

export const cnSelect = cn('Select');

export interface ISelectProps extends IClassNameProps {
    placeholder: string;
    showPlaceholder?: boolean;
    options: Option[];
    value: string;
    opened?: boolean;
    callBack: (event: ChangeEvent<HTMLSelectElement>) => void;
    defaultValue?: string | number | readonly string[] | undefined;
}

export const Select: React.FC<ISelectProps> = ({ className, placeholder, showPlaceholder, options, value, opened, callBack, defaultValue }) => {
    const [openedSelect, setOpenedSelect] = useState<boolean>(false);
    const currentElement = useRef<HTMLDivElement>(null);
    const instanceOfRootElement = useInstanceOfRootElement();

    const onChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        callBack(event);
        setOpenedSelect(false);
    }

    const onClickSelect = () => {
        setOpenedSelect(!openedSelect);
    }

    useEffect(() => {
        if (opened !== false) {
            return;
        }

        setOpenedSelect(false);
    }, [opened]);

    const downEffect = (event: PointerEvent) => {
        const isInstance: boolean = instanceOfRootElement({
            instance: event.target as HTMLElement,
            rootElement: currentElement.current as HTMLElement
        });
        if (!isInstance) {
            setOpenedSelect(false);
        }
    }

    usePointerEvent({downEffect}, []);

    return (
        <div
            className={cnSelect({ placeholder: showPlaceholder }, [className])}
            ref={currentElement}
        >
            <SelectBase
                theme={'normal'}
                placeholder={placeholder}
                showAlwaysPlaceholder={showPlaceholder}
                options={options}
                value={value}
                onChange={onChangeSelect}
                onClick={onClickSelect}
                opened={openedSelect}
                defaultValue={defaultValue}
            />
        </div>
    );
};