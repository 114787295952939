import React from 'react';
import {IClassNameProps} from "@bem-react/core";
import {cn} from '@bem-react/classname';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {IProjectServiceData} from '../../../../../common-code/interfaces/project/IProjectServiceData';
import {Tumbler} from '../../../components/Tumbler/Tumbler';
import {RangeNumber} from '../../../components/RangeNumber/RangeNumber';

export const cnWizardServices = cn('WizardServices')

export interface IWizardServicesItemProps extends IClassNameProps {
    service: KitchenService;
    data: IProjectServiceData;
    index: number;
}

export const WizardServicesItem: React.FC<IWizardServicesItemProps> =
    ({
         data,
         className,
         index,
         service
     }) => {

    const onChangeIsAuto = (value: boolean, data: IProjectServiceData) => {
        service.setServiceIsAuto(value, data);
    }
    const onChangeCount = (value: number, data: IProjectServiceData, ) => {
        service.setServiceCount(value, data);
    }
        return (
            <tr className={cnWizardServices('Item', [className])}>
                <td className={cnWizardServices('Cell', {type: 'index'})}>{index}</td>
                <td className={cnWizardServices('Cell', {type: 'vendorCode'})}>{data.vendorCode}</td>
                <td className={cnWizardServices('Cell', {type: 'name'})}>{data.name}</td>
                <td className={cnWizardServices('Cell', {type: 'autoCalculate'})}>
                    {
                        data.isAutoEnable ?
                            <Tumbler size={'m'}
                                     checked={!!data.isAuto}
                                     callBack={() => {
                                         onChangeIsAuto(!data.isAuto, data);
                                     }}
                            /> : '-'
                    }
                </td>
                <td className={cnWizardServices('Cell', {type: 'count'})}>
                    <RangeNumber min={0} max={999} showSlider={false}
                                 disabled={data.isAuto}
                                 value={data.count}
                                 step={1}
                                 callBack={(value: number) => {
                                     onChangeCount(value, data);
                                 }} viewControl={true}/>
                </td>
                <td className={cnWizardServices('Cell', {type: 'price'})}>{data.price}</td>
                <td className={cnWizardServices('Cell', {type: 'sum'})}>{data.sum}</td>
            </tr>
        );
    };