import React, {FC} from 'react'
import {composeU, IClassNameProps} from '@bem-react/core';
import {TOptionType} from '../../../../common-code/types/TOptionType';
import {withObjectOptionTypeSelect} from './_type/ObjectOption_type_select';
import {withObjectOptionTypeNumber} from './_type/ObjectOption_type_number';
import {withObjectOptionTypeHiddenNumber} from './_type/ObjectOption_type_hiddenNumber';
import {withObjectOptionTypeHiddenText} from './_type/ObjectOption_type_hiddenText';
import {withObjectOptionTypeCheckbox} from './_type/ObjectOption_type_checkbox';
import {withObjectOptionTypeJson} from './_type/ObjectOption_type_json';
import {withObjectOptionTypeRange} from './_type/ObjectOption_type_range';
import {withObjectOptionTypeText} from './_type/ObjectOption_type_text';
import './ObjectOption.css';
import {withObjectOptionTypeCorpusMaterial} from './_type/ObjectOption_type_corpusMaterial';
import {withObjectOptionTypeRadioButton} from "./_type/ObjectOption_type_radioButton";
import {IOption} from '../../../../common-code/interfaces/option/IOption';
import {IOptionGroup} from '../../../../common-code/interfaces/option/IOptionGroup';
import {cnObjectOption} from './index';
import {withObjectOptionTypeFacadeMaterial} from './_type/ObjectOption_type_facadeMaterial';

export interface IObjectOptionProps extends IClassNameProps {
    type?: TOptionType;
    option?: IOption;
    group?: IOptionGroup;
    onChange?: (fieldId: string, value: any, groupId?: string) => void;
}

export const ObjectOptionBase: FC<IObjectOptionProps> =
    ({
         className,
         type,
         option
     }) => {
    return (
        <div className={cnObjectOption({}, [className])}>
            Replace ObjectOption {type} {!option || JSON.stringify( option)}
        </div>
    );
}
export const ObjectOption = composeU(
    withObjectOptionTypeSelect,
    withObjectOptionTypeNumber,
    withObjectOptionTypeHiddenNumber,
    withObjectOptionTypeHiddenText,
    withObjectOptionTypeCheckbox,
    withObjectOptionTypeJson,
    withObjectOptionTypeRange,
    withObjectOptionTypeText,
    withObjectOptionTypeCorpusMaterial,
    withObjectOptionTypeFacadeMaterial,
    withObjectOptionTypeRadioButton,
)(ObjectOptionBase);