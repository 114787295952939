import React from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_FACADE_MATERIAL} from '../../../../../common-code/constants';
import {cnObjectOption} from '../index';
import {IObjectOptionProps} from '../ObjectOption';
import {useTranslation} from 'react-i18next';
import {IOptionFacadeMaterial} from '../../../../../common-code/interfaces/option/IOptionFacadeMaterial';
import {IFacadeMaterialItem} from '../../../../../common-code/interfaces/materials/IFacadeMaterialItem';
import {Button} from '../../Button';
import {Image} from '@yandex/ui/Image';
import {Svg} from '../../Svg';


export interface IObjectOptionTypeFacadeMaterialProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_FACADE_MATERIAL;
    option?: IOptionFacadeMaterial;
    defaultValue?: string;
}

const ObjectOptionTypeFacadeMaterial: React.FC<IObjectOptionTypeFacadeMaterialProps> =
    ({
         option,
         group,
         className,
         onChange,
         defaultValue
     }) => {
        const value: string = defaultValue !== undefined ?
            '' + defaultValue : (option && option.value ? option.value : '');
        const {t} = useTranslation();

        if (!option || !onChange) {
            return null;
        }

        const activeItem: IFacadeMaterialItem | undefined = option.items.filter(item => item.facadeMaterial.id === value)[0];

        const onChangeFacadeMaterial = (value: string) => {
            onChange(option.id, value, group ? group.id : undefined);
        }

        return (
            <div className={cnObjectOption({}, [className])}>
                <div className={cnObjectOption('WrapperTitle')}>
                    <div className={cnObjectOption('Title', [className + 'Title'])}>
                        {t(option.title)}:
                    </div>
                    <div className={cnObjectOption('TitleNameColor')}>
                        {activeItem ? t(activeItem.facadeMaterial.title) : t('Не выбрано')}
                    </div>
                </div>
                <div className={cnObjectOption('Value', [className + 'Value'])}>
                    {option.items.map((item: IFacadeMaterialItem) => {
                        return (
                            <Button
                                key={item.facadeMaterial.id}
                                className={cnObjectOption('ButtonImage')}
                                type={'button'}
                                mods={{
                                    mods: {active: activeItem?.facadeMaterial.id === item.facadeMaterial.id},
                                    hover: {mods: {active: true}}
                                }}
                                callBack={() => {
                                    onChangeFacadeMaterial(item.facadeMaterial.id)
                                }}
                                child={
                                    item.material.image ?
                                        <Image
                                            src={item.material.image}
                                            alt={t(item.material.title) || ''}
                                            width={50}
                                            height={50}
                                            className={cnObjectOption('Image')}
                                        />
                                        :
                                        item.material.color ?
                                            <div style={{
                                                backgroundColor: item.material.color,
                                                width: 50,
                                                height: 50,
                                            }} className={cnObjectOption('Color')}/>
                                            :
                                            <Svg
                                                style={{
                                                    backgroundColor: 'white',
                                                    width: 50,
                                                    height: 50,
                                                    fill: 'lightBlack'
                                                }}
                                                icon={'pic'}
                                                className={cnObjectOption('Icon')}
                                                label={t(item.material.title) || ''}/>
                                }
                            />
                        )
                    })
                    }
                </div>
            </div>

        );
    }

export const withObjectOptionTypeFacadeMaterial = withBemMod<IObjectOptionTypeFacadeMaterialProps, IObjectOptionProps>(
        cnObjectOption(),
        {type: OPTION_TYPE_FACADE_MATERIAL},
        () => ObjectOptionTypeFacadeMaterial
    )
;