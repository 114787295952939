import {withBemMod} from "@bem-react/core";
import React, {ChangeEvent, useState} from "react";
import {useIMask} from "react-imask";
import {cnInput} from "../index";
import {IInputProps} from "../Input";
import {INPUT_TYPE_PHONE} from "../../../constants";

export interface IInputPhoneProps<HTMLInputElement> extends IInputProps<HTMLInputElement> {
    value?: string;
    name?: string;
    placeholder?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement> | InputEvent) => void;
    disabled?: boolean;
}

const InputPhone: React.FC<IInputPhoneProps<HTMLInputElement>> = ({className, onChange, value, name, placeholder, disabled}) => {
    const {ref} = useIMask({mask: '+{7} (000) 000-00-00'}, {
        onAccept: (value, mask, event) => {
            if (event && onChange) {
                onChange(event);
            }
        },
    });
    const [focus, setFocus] = useState<boolean>(false);

    return (
        <input
            className={cnInput({focus: focus}, [className])}
            ref={ref}
            type={'tel'}
            defaultValue={value}
            name={name}
            disabled={disabled}
            placeholder={placeholder || '+7 (___) ___-__-__'}
            onFocus={() => {setFocus(true)}}
            onBlur={() => {setFocus(false)}}
        />
    );
};

export const withInputPhone = withBemMod<IInputPhoneProps<HTMLInputElement>>(
    cnInput(),
    { type: INPUT_TYPE_PHONE },
    () => InputPhone
);