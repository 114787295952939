import {IClassNameProps} from "@bem-react/core";
import {cn} from "@bem-react/classname";
import React from "react";
import './PageWizardEdit.css';
import {WizardEdit} from '../../ui/elements/WizardEdit/WizardEdit';

export const cnPageWizard = cn('PageWizard');

export interface IPageWizardProps extends IClassNameProps {

}

export const PageWizardEdit: React.FC<IPageWizardProps> = ({className}) => {
    // const needSave: boolean = useSelector((state: AppState) => state.wizard.options.needSave);

    return (
        <div className={cnPageWizard({}, [className])}>
            <WizardEdit />
        </div>
    );
}