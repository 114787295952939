import React from "react";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { Tumbler } from "../../../../components/Tumbler/Tumbler";
import { Select } from "../../../../components/Select/Select";
import { Button } from "../../../../components/Button";
import InputFile from "../InputFile/InputFile";
import { cnModal, Modal } from "../../../../components/Modal";
import { INewTexture } from "./Textures";
import { cnFormMaterial } from "../FormMaterial";
import Trash from "../../icons/Trash";

const textureTypes = [
  { value: "texture", content: "Текстура" },
  { value: "normal", content: "Нормаль" },
  { value: "roughness", content: "Глубина" },
  { value: "diffuse", content: "Дифуз" },
];

interface IModalTextureProps {
  handleToggleModal: () => void;
  setNewTexture: (value: INewTexture) => void;
  newTexture: INewTexture;
  handleSubmit: () => void;
  keyValue: string;
}

function ModalTexture({
  handleToggleModal,
  setNewTexture,
  newTexture,
  handleSubmit,
  keyValue,
}: IModalTextureProps) {

  const {
    path,
    type,
    fileNew,
    repeatX,
    repeatY,
    offset,
    offsetX,
    offsetY,
    sizes,
    sizesWidth,
    sizesHeight,
    visible,
    error,
  } = newTexture;
  

  const onInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNewTexture({
      ...newTexture,
      [e.target.name]: e.target.value,
      error: "",
    });
  };

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTexture({
      ...newTexture,
      [e.target.name]: e.target.checked,
      error: "",
    });
  };

  const onFocus = () => setNewTexture({ ...newTexture, error: "" });

  // add files
  const addFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTexture({
      ...newTexture,
      fileNew: Array.from(e.target.files as FileList)[0],
      path: "",
      file: "",
      error: "",
    });
  };

  // функция удаления выбранного фото
  const filterFunction = () => {
    setNewTexture({ ...newTexture, fileNew: null });
  };

  const deleteTexture = () =>
    setNewTexture({ ...newTexture, path: "", file: "", error: "" });

  const isEdit = keyValue.includes("edit");

  return (
    <Modal
      className={cnFormMaterial(cnModal())}
      visible={visible}
      callBack={handleToggleModal}
      key={keyValue}
    >
      <h4 className={cnFormMaterial("Header")}>
        {isEdit ? "Редактировать " : "Добавить"} текстуру
      </h4>

      <div className={cnFormMaterial("Content")}>
        <p className={cnFormMaterial("Error")}>{error}</p>

        {path && (
          <div className={cnFormMaterial("Children")}>
            <span>{path.split("/").at(-1) as string}</span>
            <img width={50} height={50} src={path} alt="..." />
            <div className={cnFormMaterial("Box-Btn")}>
              <Trash handleDeleteValue={deleteTexture} />
            </div>
          </div>
        )}

        <InputFile
          file={fileNew}
          addFiles={addFiles}
          filterFunction={filterFunction}
        />

        <label htmlFor="type">Тип текстуры</label>
        <Select
          className={cnFormMaterial("Select")}
          value={type}
          callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
            onInputChange({ ...e, target: { ...e.target, name: "type" } })
          }
          options={textureTypes}
          placeholder={"Выберите значение"}
        />

        <label htmlFor="repeatX">Повторение тектуры по горизонтали</label>
        <Textinput
          className={cnFormMaterial("Textinput")}
          value={repeatX}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e)
          }
          name="repeatX"
          type="number"
          maxLength={30}
          onFocus={onFocus}
        />

        <label htmlFor="repeatY">Повторение тектуры по вертикали</label>
        <Textinput
          className={cnFormMaterial("Textinput")}
          value={repeatY}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onInputChange(e)
          }
          name="repeatY"
          type="number"
          maxLength={30}
          onFocus={onFocus}
        />

        <Tumbler
          className={cnFormMaterial("Tumbler")}
          checked={offset as boolean}
          callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
          label="Добавить смещение текстуры"
          name="offset"
        />

        {offset && (
          <>
            <label htmlFor="offsetX">Смещение тектуры по горизонтали</label>
            <Textinput
              className={cnFormMaterial("Textinput")}
              value={offsetX}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="offsetX"
              type="number"
              maxLength={30}
              onFocus={onFocus}
            />

            <label htmlFor="offsetY">Смещение тектуры по вертикали</label>
            <Textinput
              className={cnFormMaterial("Textinput")}
              value={offsetY}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="offsetY"
              type="number"
              maxLength={30}
              onFocus={onFocus}
            />
          </>
        )}

        <Tumbler
          className={cnFormMaterial("Tumbler")}
          checked={sizes as boolean}
          callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
          label="Указать размеры текстуры"
          name="sizes"
        />

        {sizes && (
          <>
            <label htmlFor="sizesWidth">Ширина текстуры</label>
            <Textinput
              className={cnFormMaterial("Textinput")}
              value={sizesWidth}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="sizesWidth"
              type="number"
              maxLength={30}
              onFocus={onFocus}
            />

            <label htmlFor="sizesHeight">Высота текстуры</label>
            <Textinput
              className={cnFormMaterial("Textinput")}
              value={sizesHeight}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="sizesHeight"
              type="number"
              maxLength={30}
              onFocus={onFocus}
            />
          </>
        )}

        <Button
          className={cnFormMaterial("Button")}
          type={"button"}
          text={`${isEdit ? "Добавить" : "Сохранить"} текстуру`}
          leftIcon={undefined}
          mods={{
            mods: {bkg: "white",color: "lightGreen",border: "lightGreen",fill: "lightGreen",},
            hover: {mods: { bkg: "lightGreen", color: "white", fill: "white" },},
          }}
          callBack={handleSubmit}
        />
      </div>
    </Modal>
  );
}

export default ModalTexture;
