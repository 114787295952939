import React, {ChangeEvent, useState} from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_CHECKBOX} from '../../../../../common-code/constants';
import {IObjectOptionProps} from '../ObjectOption';
import {useTranslation} from 'react-i18next';
import {IOptionCheckbox} from '../../../../../common-code/interfaces/option/IOptionCheckbox';
import {cnObjectOption} from '../index';
import {cnTumbler, Tumbler} from '../../Tumbler/Tumbler';

export interface IObjectOptionTypeCheckboxProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_CHECKBOX;
    option?: IOptionCheckbox;
    defaultValue?: boolean;
}

const ObjectOptionTypeCheckbox: React.FC<IObjectOptionTypeCheckboxProps> =
    ({
         option,
        group,
         className,
         onChange,
         defaultValue
    }) => {
    const [value, setValue] = useState<boolean>((defaultValue !== undefined) ? defaultValue : (option && option.defaultValue ? option.defaultValue : false));
    const {t} = useTranslation();

    if (!option || !onChange) {
        return null;
    }

    const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(option.id, event.target.checked, group ? group.id : undefined);
        setValue(event.target.checked);
    }
    
    return (
        <div className={cnObjectOption({}, [className])}>
            <div className={cnObjectOption('Title', [className+'Title'])}>
                {t(option.title)}
            </div>
            <div className={cnObjectOption('Value', [className+'Value'])}>
                <Tumbler className={cnObjectOption(cnTumbler({size: 'm'}))} checked={value} callBack={onChangeCheckbox}/>
            </div>
        </div>
    );
}

export const withObjectOptionTypeCheckbox = withBemMod<IObjectOptionTypeCheckboxProps, IObjectOptionProps>(
    cnObjectOption(),
    { type: OPTION_TYPE_CHECKBOX },
    () => ObjectOptionTypeCheckbox
    )
;