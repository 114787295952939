import React from "react";
import {
  CLASSNAME_END_UNITS as arrEndUnits,
  CLASSNAME_ANGLE_TWO_FACADES_UNITS as arrAngleTwoFacades,
  CLASSNAME_ANGLE_CUBE_UNITS as arrAngleCube,
  CLASSNAME_TOP_UNIT_ANGLE_DOUBLE,
  CLASSNAME_TOP_UNIT_RACK_FRAME,
  CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_CUBE_SINK,
  CLASSNAME_ANGLE_UNITS as arrAngleUnits,
  CLASSNAME_BOTTOM_UNIT_SINGLE_HANDLE,
  CLASSNAME_BOTTOM_UNIT_DOUBLE_HANDLE,
  CLASSNAME_PENAL_UNIT_SINGLE_HANDLE,
  CLASSNAME_SIDE_BOTTOM_FACADE_UNIT,
  CLASSNAME_SIDE_TOP_FACADE_UNIT,
  CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE,
  CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_BERTA
} from "common-code/lib/constants";
import { useFormContext } from "react-hook-form";
import InputForm from "../../components/InputForm";
import { cnCreateForm } from "../../FormModule";
import InputNumber from "../../components/InputNumber";
import Shelves from "./Shelves/Shelves";
import Boxes from "./Boxes/Boxes";
import Widths from "./Widths/Widths";
import { Tumbler } from "../../../../../components/Tumbler/Tumbler";
import IntegratedHandles from "../IntegratedHandles/IntegratedHandles";
import { IFormInput } from "../../initialValue";

export const additionalCorpusOptions = (classNameValue: string) => ({
  isEndUnit: arrEndUnits.includes(classNameValue), // для секций окончаний
  isAngleTwoFacades: arrAngleTwoFacades.includes(classNameValue), // для секции угловых двухфасадных
  isAngleCube: arrAngleCube.includes(classNameValue), // для секции угловых кубических
  isTopUnitAngleDouble: classNameValue === CLASSNAME_TOP_UNIT_ANGLE_DOUBLE, // для секции верхних угловых двухфасадных
  isTopUnitRackFrame: classNameValue === CLASSNAME_TOP_UNIT_RACK_FRAME, // для секции верхних стлажей
  isBottomUnitSingleHandle:
    classNameValue === CLASSNAME_BOTTOM_UNIT_SINGLE_HANDLE, // для нижней секции с интегрированной ручкой
  isBottomUnitDoubleHandle:
    classNameValue === CLASSNAME_BOTTOM_UNIT_DOUBLE_HANDLE, // для нижней секции с двумя интегрированными ручками
  isPenalUnitSingleHandle:
    classNameValue === CLASSNAME_PENAL_UNIT_SINGLE_HANDLE, // для пенала с интегрированной ручкой

  isUnitAngleNormalSingleHandle:
    classNameValue === CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE,

  isUnitAngleNormalBerta:
    classNameValue === CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_BERTA,

  isBottomAngleSink:
    classNameValue ===
    CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_CUBE_SINK, // для нижней угловой прямоугольной секции с раковиной
  isUnitAngleNormal: arrAngleUnits.includes(classNameValue), // для верхних и нижних угловых прямых шкафов ISaveTopAngleNormalCorpusData
});

function Corpus({classNameValue} : {classNameValue: string}) {
  const { getValues, setValue } = useFormContext<IFormInput>();

  const isFacade = classNameValue === CLASSNAME_SIDE_BOTTOM_FACADE_UNIT || classNameValue === CLASSNAME_SIDE_TOP_FACADE_UNIT;
  
  const {
    isEndUnit,
    isAngleTwoFacades,
    isAngleCube,
    isTopUnitAngleDouble,
    isTopUnitRackFrame,
    isBottomAngleSink,
    isUnitAngleNormal,
    isBottomUnitSingleHandle,
    isBottomUnitDoubleHandle,
    isUnitAngleNormalBerta,
    isPenalUnitSingleHandle,
    isUnitAngleNormalSingleHandle
  } = additionalCorpusOptions(classNameValue);

  const [isFrontPanel, setIsFrontPanel] = React.useState(
    !!getValues("frontPanelLength")
  );

  const [isVerticalPanel, setIsVerticalPanel] = React.useState(
    !!getValues("verticalPanelLength")
  )



  React.useEffect(() => {
    setValue("isFrontPanel", isFrontPanel);
    // eslint-disable-next-line
  }, [isFrontPanel]);

  React.useEffect(() => {
    setValue("isVerticalPanel", isVerticalPanel);
    // eslint-disable-next-line
  }, [isVerticalPanel]);

  return (
    <>
      <h3 className={cnCreateForm("Header")}>Параметры корпуса *</h3>

      <InputForm
        label="Код наименования корпуса из 1С *"
        name="corpusCatalogCode"
        required={false}
        onFocus={undefined}
      />

      <Widths />

      <InputNumber
        min={200}
        max={2500}
        label="Высота корпуса *"
        name="height"
        required={!isFacade}
      />
      <InputNumber
        min={200}
        max={1200}
        label="Глубина корпуса *"
        name="depth"
        required={!isFacade}
      />
      <InputNumber
        min={5}
        max={30}
        label="Толщина стенки корпуса *"
        name="thickness"
        required={!isFacade}
      />
      <InputNumber
        min={1}
        max={20}
        label="Толщина задней стенки *"
        name="backThickness"
        required={!isFacade}
      />

      {/* Для секций интегрированной ручкой */}
      {isBottomUnitSingleHandle && (
        <IntegratedHandles />
      )}

      {/* Для пенала с интегрированной ручкой и секции с двумя интегрированными ручками */}
      {(isBottomUnitDoubleHandle) && (
        <>
         <InputNumber
          min={-1100}
          max={1100}
          label="Положение технологического отверстия для профиля - С (по вертикали) *"
          name="techHoleCPositionY"
          required={true}
          />
          <IntegratedHandles />
        </> 
      )}

      {(isPenalUnitSingleHandle) && (
        <>
         <InputNumber
          min={-1100}
          max={1100}
          label="Положение профиля - L (по вертикали) *"
          name="techHoleLPositionY"
          required={true}
          />
          <IntegratedHandles />
        </> 
      )}

      {/* Для секций окончаний */}
      {isEndUnit && (
        <>
          <InputNumber
            min={10}
            max={
              getValues("widthValue") ? Number(getValues("widthValue")) : 1000
            }
            label="Ширина стороны до скоса *"
            name="smallWidth"
            required={true}
          />
          <InputNumber
            min={10}
            max={getValues("depth") ? Number(getValues("depth")) : 500}
            label="Глубина стороны до скоса *"
            name="smallDepth"
            required={true}
          />
        </>
      )}

      {isAngleTwoFacades && (
        <>
          <InputNumber
            min={50}
            max={
              getValues("widthValue")
                ? Number(getValues("widthValue"))
                : getValues("depth")
                ? Number(getValues("depth"))
                : 1000
            }
            label="Ширина боковой панели *"
            name="sidePanel"
            required={true}
          />
          <>
            <Tumbler
              className={cnCreateForm("Tumbler")}
              checked={isFrontPanel as boolean}
              callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                setIsFrontPanel(e.target.checked)
              }
              label="Добавить переднюю панель *"
            />
            {isFrontPanel && (
              <InputNumber
                min={5}
                max={800}
                label="Ширина передней глухой панели *"
                name="frontPanelLength"
                required={true}
              />
            )}
          </>
        </>
      )}
      {/* Для секции угловых кубических */}
      {isAngleCube && (
        <>
          <InputNumber
            min={3}
            max={
              getValues("widthValue")
                ? Number(getValues("widthValue"))
                : getValues("depth")
                ? Number(getValues("depth"))
                : 1000
            }
            label="Глубина боковой панели *"
            name="sideDepth"
            required={true}
          />
          <InputNumber
            min={3}
            max={
              getValues("widthValue")
                ? Number(getValues("widthValue"))
                : getValues("depth")
                ? Number(getValues("depth"))
                : 1000
            }
            label="Глубина задней боковой панели(не работает) *"
            name="backSideDepth"
            required={true}
          />
        </>
      )}

      {/* Для секции верхних угловых двухфасадных */}
      {isTopUnitAngleDouble && (
        <>
          <InputNumber
            min={3}
            max={
              getValues("widthValue")
                ? Number(getValues("widthValue"))
                : getValues("depth")
                ? Number(getValues("depth"))
                : 1000
            }
            label="Глубина боковой панели *"
            name="sideDepth"
            required={true}
          />
        </>
      )}

      {/* Для секции верхих стелажей */}
      {isTopUnitRackFrame && (
        <>
          <InputNumber
            min={5}
            max={200}
            label="Ширина каркаса *"
            name="frameWidth"
            required={true}
          />
          <InputNumber
            min={5}
            max={200}
            label="Глубина каркаса *"
            name="frameDepth"
            required={true}
          />
        </>
      )}
      {/* Для секции нижних угловых боковина во всю длину прямоугольный с раковиной */}
      {isBottomAngleSink && (
        <>
          <InputNumber
            min={5}
            max={200}
            label="Ширина профиля *"
            name="rearPlinthWidth"
            required={true}
          />
          <InputNumber
            min={5}
            max={200}
            label="Глубина профиля *"
            name="rearPlinthDepth"
            required={true}
          />
          <InputNumber
            min={5}
            max={200}
            label="Высота профиля *"
            name="rearPlinthHeight"
            required={true}
          />

          <InputNumber
            min={5}
            max={200}
            label="Ширина царги *"
            name="tsargaWidth"
            required={true}
          />
          <InputNumber
            min={5}
            max={200}
            label="Глубина царги *"
            name="tsargaDepth"
            required={true}
          />
          <InputNumber
            min={5}
            max={200}
            label="Высота царги *"
            name="tsargaHeight"
            required={true}
          />

          <InputNumber
            min={5}
            max={200}
            label="Ширина ограждения *"
            name="railWidth"
            required={true}
          />
          <InputNumber
            min={5}
            max={200}
            label="Глубина ограждения *"
            name="railDepth"
            required={true}
          />
          <InputNumber
            min={5}
            max={200}
            label="Высота ограждения *"
            name="railHeight"
            required={true}
          />
        </>
      )}

      {/* Для верхних и нижних угловых прямых шкафов */}
      {isUnitAngleNormal && (
        <>
          <Tumbler
            className={cnCreateForm("Tumbler")}
            checked={isFrontPanel as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsFrontPanel(e.target.checked)
            }
            label= {isUnitAngleNormalBerta? "Добавить переднюю глухую панель - 100мм *" : "Добавить переднюю глухую панель *"}
          />
          {isFrontPanel && (
            <InputNumber
              min={5}
              max={2000}
              label={ isUnitAngleNormalBerta? "Отступ передней глухой панели *" : "Ширина передней глухой панели *"}
              name="frontPanelLength"
              required={true}
            />
          )}

          { (isUnitAngleNormalSingleHandle || isUnitAngleNormalBerta )&& (
            <>
              <Tumbler
                className={cnCreateForm("Tumbler")}
                checked={isVerticalPanel as boolean}
                callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setIsVerticalPanel(e.target.checked)
              }
              label= {"Добавить переднюю вертикальную панель *"}
              />

              {(isVerticalPanel)&&  (
                <>
                  <InputNumber
                    min={5}
                    max={800}
                    label="Ширина передней вертикальной панели *"
                    name="verticalPanelLength"
                    required={true}
                  />
                  <InputNumber
                    min={-42}
                    max={42}
                    label="Отступ передней вертикальной панели *"
                    name="verticalPanelGap"
                    required={true}
                  />
                </>
              )}   
              { (isUnitAngleNormalSingleHandle) && (
                <IntegratedHandles/> 
              )}
            </>
          )}

        </>

      )}

      { isUnitAngleNormalBerta && (
        <>
          {/* <Tumbler
              className={cnCreateForm("Tumbler")}
              checked={isDockingPanel as boolean}
              callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                setIsDockingPanel(e.target.checked)
            }
            label="Добавить стыковочные панели *"
          />
          {isDockingPanel && (
            <>
              <InputNumber
                min={0}
                max={100}
                label="Отступ передней вертикальной панели *"
                name="dockingPanelGap"
                required={true}
              />
            </>
          )}    */}
        </>
      )}

      


      <hr />

      <Shelves />

      <Boxes />
    </>
  );
}

export default Corpus;
