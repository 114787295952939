import React, { useEffect, useState } from "react";
import { cn } from "@bem-react/classname";
import { IClassNameProps } from "@bem-react/core";
import "./CreateObjectOptions.css";
import { useTranslation } from "react-i18next";
import { useEffectDidMount } from "../../../../hooks/useEffectDidMount";
import { ICreateObjectData } from "../../../../../common-code/interfaces/createData/ICreateObjectData";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import useCreateOptions from "../../../../hooks/useCreateOptions";
import { IModulePriceData } from "../../../../../common-code/interfaces/catalog/IModulePriceData";
import { Svg } from "../../../components/Svg";
import { Button } from "../../../components/Button";
import { CreateObjectOptionsList } from "../../CreateObjectOptions/List/CreateObjectOptions-List";
import { CreateObjectOptionsPriceAmount } from "../../CreateObjectOptions/PriceAmount/CreateObjectOptions-PriceAmount";

export const cnCreateObjectOptions = cn("CreateObjectOptions");

export interface ICreateObjectOptionsProps extends IClassNameProps {
  objectData: ICreateObjectData;
  width: string | null;
  corpusWidth: string | null;
  service: KitchenService;
  onClose: (value: boolean) => void;
  handleFocus: () => void;
}

export const CreateObjectOptions: React.FC<ICreateObjectOptionsProps> = ({
  className,
  objectData,
  width,
  corpusWidth,
  service,
  onClose,
  handleFocus,
}) => {
  const { t } = useTranslation();
  const currentWidth = width && !isNaN(+width) ? +width : undefined;
  const currentCorpusWidth =
    corpusWidth && !isNaN(+corpusWidth) ? +corpusWidth : undefined;
  const [createOptions, setCreateOptions] = useCreateOptions(
    objectData,
    service,
    currentWidth,
    currentCorpusWidth
  );
  const [modulePrice, setModulePrice] = useState<
    IModulePriceData | undefined
  >();
  const [priceError, setPriceError] = useState<string>("");
  const [priceNote, setPriceNote] = useState<string>("");

  const btnRef = React.useRef<HTMLButtonElement>(null);

  // useEffect(() => {
  //     setTimeout(() => {
  //         // btnRef.current?.click();

  //      onCreateObject();
  //     }, 1000);
  // }, []);

  const handleOptionChange = (
    fieldId: string,
    value: any,
    groupId?: string
  ) => {
    setCreateOptions(fieldId, value, groupId);
  };

  const onCreateObject = () => {
    // handleFocus();
    if (!modulePrice) {
      // console.error('onCreateObject error!');
      return;
    }
    if (objectData.builtIn) {
      onClose(false);
    } else {
      service.deleteAllObjects();
      const createObject = service.createDynamicCommonObjectEDIT(
        objectData.uid,
        createOptions,
        modulePrice,
        objectData.catalogCode
      );
      if (createObject) {
        onClose(false);
      } else {
        console.error("Что-то пошло не так");
      }
    }
  };

  useEffectDidMount(() => {
    setPriceNote(t("Идет расчет...") || "");
    service
      .loadCreateObjectPrices(objectData)
      .then(() => {
        updateModulePrice();
      })
      .catch(() => {
        setPriceError(t("Не удалось получить цены с сервера") || "");
      });
  });

  const updateModulePrice = () => {
    const modulePrice: IModulePriceData = service.calculateCreateObjectPrice(
      objectData,
      createOptions
    );
    setModulePrice(modulePrice);
    setPriceNote(modulePrice.note || "");
    if (modulePrice.errors.length > 0) {
      setPriceError(
        modulePrice.errors
          .map((error) => {
            return error.message;
          })
          .join(" ")
      );
    } else {
      setPriceError("");
    }
  };
  useEffect(updateModulePrice, [createOptions, objectData, service]);

  return (
    <div className={cnCreateObjectOptions({}, [className])}>
      <div className={cnCreateObjectOptions("Body")}>
        <div className={cnCreateObjectOptions("Header")}>
          <div
            className={cnCreateObjectOptions("Images")}
            onClick={onCreateObject}
          >
            {objectData.svgImage ? (
              <Svg
                icon={objectData.svgImage}
                className={cnCreateObjectOptions("Icon")}
                label={t(objectData.title) || ""}
              />
            ) : objectData.image.length > 0 ? (
              <img
                src={objectData.image}
                alt={t(objectData.title) || ""}
                className={cnCreateObjectOptions("Image")}
              />
            ) : (
              <Svg
                icon={"no-photo"}
                className={cnCreateObjectOptions("Icon")}
                label={t(objectData.title) || ""}
              />
            )}
          </div>
          <div className={cnCreateObjectOptions("NameContainer")}>
            <div className={cnCreateObjectOptions("Name")}>
              {t(objectData.title)}
            </div>
            {objectData.heightText.length > 0 ||
            objectData.depthText.length > 0 ? (
              <div className={cnCreateObjectOptions("Sizes")}>
                {objectData.heightText.length > 0 ? (
                  <div className={cnCreateObjectOptions("SizesItem")}>
                    <p className={cnCreateObjectOptions("SizesTitle")}>
                      {t("Высота")}
                    </p>
                    <p className={cnCreateObjectOptions("SizesValue")}>
                      {objectData.heightText}
                    </p>
                  </div>
                ) : null}
                {objectData.depthText.length > 0 ? (
                  <div className={cnCreateObjectOptions("SizesItem")}>
                    <p className={cnCreateObjectOptions("SizesTitle")}>
                      {t("Глубина")}
                    </p>
                    <p className={cnCreateObjectOptions("SizesValue")}>
                      {objectData.depthText}
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <CreateObjectOptionsList
          objectData={objectData}
          createOptions={createOptions}
          handleOptionChange={handleOptionChange}
        />

        <CreateObjectOptionsPriceAmount
          objectData={objectData}
          service={service}
          modulePrice={modulePrice}
        />
        <div className={cnCreateObjectOptions("Footer")}>
          {modulePrice && !priceError ? (
            <Button
              className={cnCreateObjectOptions("Button")}
              ref={btnRef}
              type={"button"}
              // Change callback here
              text={t("Добавить модуль на 3D-сцену") || undefined}
              leftIcon={{
                path: "plus2",
                width: 16,
                height: 16,
                marginRight: 10,
              }}
              mods={{
                mods: { bkg: "lightGreen", color: "white", fill: "white" },
              }}
              callBack={onCreateObject}
            />
          ) : (
            <div className={cnCreateObjectOptions("PriceError")}>
              {priceError ? t("Нельзя добавить модуль в проект") : ""}
            </div>
          )}
        </div>
        <div className={cnCreateObjectOptions("Notes")}>
          <div className={cnCreateObjectOptions("Note")}>
            {objectData.notPrice ||
              t("* при заказе сегодня и оплате в течение 3-х суток")}
            {priceNote}
          </div>
        </div>
      </div>
    </div>
  );
};
