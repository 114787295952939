import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, BufferGeometry, Euler, ExtrudeGeometry, Shape, Vector3} from 'three';
import {ThreeTopUnitShelfFigured} from "../../../units/ThreeTopUnit/types/ThreeTopUnitShelfFigured";
import {
    ISaveTopShelfFiguredCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopShelfFiguredCorpusData';

export class ThreeTopShelfFiguredCorpus extends ThreeCorpus {
    saveData: ISaveTopShelfFiguredCorpusData;
    unit: ThreeTopUnitShelfFigured;

    constructor(options: ISaveTopShelfFiguredCorpusData, unit: ThreeTopUnitShelfFigured) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public getDepthBottomPanel() {
        return this.saveData.bottomPanel
    }

    protected createBottomPanelGeometry(): BufferGeometry {
        let bottomPanelGeometry;
        let bottomPanelShape = new Shape();
        let bottomPanelExtrudeSettings = {
            steps: 1,
            depth: this.getThickness(),
            bevelEnabled: false
        };

        bottomPanelShape.moveTo(0, 0);
        bottomPanelShape.lineTo(0, 285);
        bottomPanelShape.lineTo(280, 285);
        bottomPanelShape.lineTo(280, 225.56);
        bottomPanelShape.lineTo(279.29, 221.09);
        bottomPanelShape.lineTo(277.24, 217.06);
        bottomPanelShape.lineTo(274.04, 213.86);
        bottomPanelShape.lineTo(270.01, 211.81);
        bottomPanelShape.lineTo(246.3, 208.74);
        bottomPanelShape.lineTo(229.61, 201.83);
        bottomPanelShape.lineTo(215.27, 190.83);
        bottomPanelShape.lineTo(204.27, 176.49);
        bottomPanelShape.lineTo(197.36, 159.8);
        bottomPanelShape.lineTo(195, 141.88);
        bottomPanelShape.lineTo(195, 0);
        bottomPanelShape.lineTo(0, 0);

        bottomPanelGeometry = new ExtrudeGeometry([bottomPanelShape], bottomPanelExtrudeSettings);

        return bottomPanelGeometry;
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getDepthBottomPanel()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                -this.getDepth() / 2 + this.getDepthBottomPanel() / 2 + this.getBackThickness()
            )
        );
        this.createPanel(this.createBottomPanelGeometry(),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness(),
                -this.getHeight() / 2,
                -this.getDepth() / 2 + this.getBackThickness()
            ),
            new Euler(
                0,
                -Math.PI / 2,
                0
            )
        );
        this.createPanel(this.createBottomPanelGeometry(),
            'right',
            new Vector3(
                this.getWidth()/2,
                -this.getHeight() / 2,
                -this.getDepth() / 2 + this.getBackThickness()
            ),
            new Euler(
                0,
                -Math.PI / 2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getDepth() - this.getBackThickness()),
            'top',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
    }
}