import React from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_HIDDEN_NUMBER} from '../../../../../common-code/constants';
import {IObjectOptionProps} from '../ObjectOption';
import {IOptionHiddenNumber} from '../../../../../common-code/interfaces/option/IOptionHiddenNumber';
import {cnObjectOption} from '../index';


export interface IObjectOptionTypeHiddenNumberProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_HIDDEN_NUMBER;
    option?: IOptionHiddenNumber;
}

const ObjectOptionTypeHiddenNumber: React.FC<IObjectOptionTypeHiddenNumberProps> =
    ({
         option,
         className
    }) => {
    if (!option) {
        return null;
    }
    return (
        <input type={'hidden'} className={cnObjectOption('HiddenNumber', [className])} value={option.value}/>
    );
}

export const withObjectOptionTypeHiddenNumber = withBemMod<IObjectOptionTypeHiddenNumberProps, IObjectOptionProps>(
    cnObjectOption(),
    { type: OPTION_TYPE_HIDDEN_NUMBER },
    () => ObjectOptionTypeHiddenNumber
);