import { IFacade } from "./initialFacade";

export const validateFacade = (
  facadeNew: IFacade,
  maxDimensions: { maxHeight: number; maxWidth: number; maxDepth: number }
) => {
  const {
    initSizesWidth,
    initSizesHeight,
    sideType,
    modelType,
    geometryType,
    isGroup,
    isParent,
    isCalcSizes,
    groupId,
    functionalType,
    calcSizesWidth,
    calcSizesHeight,
    gap,
    gapBottom,
    gapLeft,
    gapRight,
    gapTop,
    margin,
    marginX,
    marginY,
    marginZ,
    handleMargin,
    handleMarginX,
    handleMarginY,
    handleMarginZ,
    hinges,
    arrHinges,
    handle,
    handleRotation,
    handleRotationX,
    handleRotationY,
    handleRotationZ,
    rotation,
    rotationX,
    rotationY,
    rotationZ,
  } = facadeNew;
  const { maxHeight, maxWidth, maxDepth } = maxDimensions;

  // Поля формы обязательные для заполнения согласно interface ISaveFacadeData
  if (!initSizesWidth || !initSizesHeight) {
    return "Необходимо заполнить поля начальных размеров фасада";
  }
  if (!sideType) {
    return "Необходимо указать сторону фасада";
  }
  if (!modelType) {
    return "Необходимо указать модель фасада";
  }
  if (!geometryType) {
    return "Необходимо указать тип геометрии фасада";
  }

  // Проверка правильности заполнения полей согласно interface ISaveFacadeData

  const checkValue = (value: string, name: string) => {
    if (
      value.includes("%") &&
      Number(value.replace("%", "")) &&
      (Number(value.replace("%", "")) < 100 ||
        Number(value.replace("%", "")) > 0)
    ) {
      return;
    } else if (
      name === "ширина" &&
      Number(value) &&
      Number(value) > 0 &&
      Number(value) < maxWidth
    ) {
      return;
    } else if (
      name === "высота" &&
      Number(value) &&
      Number(value) > 0 &&
      Number(value) < maxHeight
    ) {
      return;
    }
    return `Неверное значение поля: ${name} фасада`;
  };
  checkValue(initSizesWidth, "ширина");
  checkValue(initSizesHeight, "высота");

  if (!functionalType ) {
    return "Необходимо указать функциональный тип фасадов";
  }
  
  // Проверка полей для групповых фасадов
  if(isGroup && isParent && (!calcSizesWidth || !calcSizesHeight || !isCalcSizes)) {
    return "Необходимо указать расчетные размеры для группы фасадов";
  }

  if (
    isGroup &&
    groupId &&
    Number(groupId) &&
    (Number(groupId) > 100 || Number(groupId) < 1)
  ) {
    return "Номер группы фасадов должен быть в диапазоне от 1 до 100";
  }
  if (calcSizesWidth) {
    checkValue(calcSizesWidth, "ширина");
  }
  if (calcSizesHeight) {
    checkValue(calcSizesHeight, "высота");
  }

  // Проверка полей для отступов, зазоров, ручек и петель

  if (gap) {
    if (
      gapBottom.length === 0 ||
      gapLeft.length === 0 ||
      gapRight.length === 0 ||
      gapTop.length === 0
    ) {
      return "Небходимо указать зазоры";
    }
    if (
      Number(gapBottom) < 0 ||
      Number(gapLeft) < 0 ||
      Number(gapRight) < 0 ||
      Number(gapTop) < 0 ||
      Number(gapBottom) > 100 ||
      Number(gapLeft) > 100 ||
      Number(gapRight) > 100 ||
      Number(gapTop) > 100
    ) {
      return "Значение зазоров должно быть в диапазоне от 0 до 100";
    }
  }
  if (margin) {
    if (marginX.length === 0 || marginY.length === 0 || marginZ.length === 0) {
      return "Небходимо указать отступы";
    }
    if (
      Number(marginX) < -maxWidth ||
      Number(marginY) < -maxHeight ||
      Number(marginZ) < -maxDepth ||
      Number(marginX) > maxWidth ||
      Number(marginY) > maxHeight ||
      Number(marginZ) > maxDepth
    ) {
      return "Неверно указаны значения отступов";
    }
  }
  if (handleMargin) {
    if (
      handleMarginX.length === 0 ||
      handleMarginY.length === 0 ||
      handleMarginZ.length === 0
    ) {
      return "Небходимо указать отступы";
    }
    if (
      Number(handleMarginX) < -maxWidth ||
      Number(handleMarginY) < -maxHeight ||
      Number(handleMarginZ) < -maxDepth ||
      Number(handleMarginX) > maxWidth ||
      Number(handleMarginY) > maxHeight ||
      Number(handleMarginZ) > maxDepth
    ) {
      return "Неверно указаны значения отступов для ручки";
    }
  }

  if (hinges) {
    if (arrHinges.length === 0) {
      return "Небходимо указать петли";
    }
  }
  if (rotation) {
    if (Number(rotationX) < -90 || Number(rotationX) > 90) {
      return "Значение угла поворота фасада должно быть в диапазоне от -90 до 90 градусов";
    }
    if (Number(rotationY) < -90 || Number(rotationY) > 90) {
      return "Значение угла поворота фасада должно быть в диапазоне от -90 до 90 градусов";
    }
    if (Number(rotationZ) < -90 || Number(rotationZ) > 90) {
      return "Значение угла поворота фасада должно быть в диапазоне от -90 до 90 градусов";
    }
  }
  if (handle) {
    if (handleRotation) {
      if (Number(handleRotationX) < -90 || Number(handleRotationX) > 90) {
        return "Значение угла поворота ручки должно быть в диапазоне от -90 до 90 градусов";
      }
      if (Number(handleRotationY) < -90 || Number(handleRotationY) > 90) {
        return "Значение угла поворота ручки должно быть в диапазоне от -90 до 90 градусов";
      }
      if (Number(handleRotationZ) < -90 || Number(handleRotationZ) > 90) {
        return "Значение угла поворота ручки должно быть в диапазоне от -90 до 90 градусов";
      }
    }
  }

  return "";
};
