import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';
import {BOTTOM_UNIT_TOP_PANEL_WIDTH} from '../../../../../constants';

export class ThreeBottomSinkCorpus extends ThreeCorpus {
    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                this.getThickness() / 2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                this.getThickness() / 2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, BOTTOM_UNIT_TOP_PANEL_WIDTH, this.getThickness()),
            'front',
            new Vector3(
                0,
                this.getHeight() / 2 - BOTTOM_UNIT_TOP_PANEL_WIDTH / 2,
                this.getDepth()/2 - this.getThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, BOTTOM_UNIT_TOP_PANEL_WIDTH, this.getThickness()),
            'back',
            new Vector3(
                0,
                0,
                -this.getDepth()/2 + this.getThickness()/2
            )
        );
    }

    protected createBackPanels() {
    }
}