import React, {MutableRefObject, RefObject, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import './Wizard-CurrentStep.css'
import {useTranslation} from "react-i18next";
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {IProjectData} from '../../../../../common-code/interfaces/project/IProjectData';
import {ISaveRoomData} from '../../../../../common-code/interfaces/saveData/ISaveRoomData';
import {ICreateObjectDomElements} from '../../../../interfaces/ICreateObjectDomElements';
import {IAppConfig} from '../../../../../common-code/interfaces/config/IAppConfig';
import useCurrentStep from '../../../../hooks/useCurrentStep';
import {WizardRoom} from '../Room/Wizard-Room';
import {ICreateGroup} from '../../../../../common-code/interfaces/createData/ICreateGroup';
import {cnWizard} from '../Wizard';
import {WizardModules} from '../Modules/Wizard-Modules';
import {Button, cnButton} from '../../../components/Button';
import {cn} from '@bem-react/classname';
import {WizardKitchen} from '../Kitchen/Wizard-Kitchen';
import {
    GROUP_BIG_EQUIPMENTS,
    GROUP_BOTTOM_ANGLE_UNITS,
    GROUP_BOTTOM_END_UNITS,
    GROUP_BOTTOM_EQUIPMENTS,
    GROUP_BOTTOM_NORMAL_UNITS, GROUP_BUILTIN_EQUIPMENTS,
    GROUP_EQUIPMENTS, GROUP_FACADES,
    GROUP_PENAL_UNITS, GROUP_PORTABLE_EQUIPMENTS, GROUP_SINK_EQUIPMENTS,
    GROUP_TOP_ANGLE_UNITS,
    GROUP_TOP_END_UNITS,
    GROUP_TOP_EQUIPMENTS,
    GROUP_TOP_NORMAL_UNITS
} from '../../../../../common-code/constants';
import {Accessories} from '../../Accessories/Accessories';
import {WizardServices} from '../../WizardServices/WizardServices';
import {WizardSpec} from '../../WizardSpec/WizardSpec';
import {WizardBuy} from '../../WizardBuy/WizardBuy';
import {TUserRole} from '../../../../../common-code/types/TUserRole';
import {useUserMod} from '../../../../hooks/useUserMod';

export const cnWizardCurrentStep = cn('WizardCurrentStep');

export interface IWizardCurrentStepProps extends IClassNameProps {
    service: KitchenService;
    projectData: IProjectData;
    roomData: ISaveRoomData;
    createObjects: MutableRefObject<ICreateObjectDomElements>;
    containerRef: RefObject<HTMLDivElement>;
    config: IAppConfig;
}

export const WizardCurrentStep: React.FC<IWizardCurrentStepProps> =
    ({
         service,
         projectData,
         roomData,
         createObjects,
         containerRef,
         className
     }) => {
        const {t} = useTranslation();
        const {currentStep, changeNextStep, changePrevStep, isFirstStep, isLastStep} = useCurrentStep(service);
        const [activeStep, setActiveStep] = useState<string | null>(null);
        const userMod: TUserRole = useUserMod();


        const renderStep = (stepId: string) => {
            if (activeStep !== stepId) {
                resetMetaData(stepId);
            }

            switch (stepId) {
                case 'room':
                    return <WizardRoom
                        containerRef={containerRef}
                        roomData={roomData}
                        projectData={projectData}
                        service={service}
                    />
                case 'kitchen':
                    return <WizardKitchen
                        containerRef={containerRef}
                        projectData={projectData}
                        service={service}
                    />
                case 'modules':
                    const unitList: ICreateGroup[] | undefined = service.getCreateGroupUnits([
                        GROUP_BOTTOM_ANGLE_UNITS,
                        GROUP_BOTTOM_END_UNITS,
                        GROUP_BOTTOM_NORMAL_UNITS,
                        GROUP_PENAL_UNITS,
                        GROUP_TOP_ANGLE_UNITS,
                        GROUP_TOP_END_UNITS,
                        GROUP_TOP_NORMAL_UNITS,
                        GROUP_FACADES,
                    ]);
                    return unitList ?
                        <WizardModules
                            service={service}
                            createObjects={createObjects}
                            modules={unitList}
                            title={t('Добавьте нужные модули на сцену')}
                            id={stepId}
                        /> :
                        <div className={cnWizard('CurrentStepError')}>{t('Загрузка каталога')}...</div>;
                case 'accessories':
                    return <Accessories service={service} className={cnWizard('Accessories')}/>;
                case 'appliances':
                    const equipmentList: ICreateGroup[] | undefined = service.getCreateGroupUnits([
                        GROUP_EQUIPMENTS,
                        GROUP_BOTTOM_EQUIPMENTS,
                        GROUP_TOP_EQUIPMENTS,
                        GROUP_BIG_EQUIPMENTS,
                        GROUP_PORTABLE_EQUIPMENTS,
                        GROUP_BUILTIN_EQUIPMENTS,
                        GROUP_SINK_EQUIPMENTS
                    ]);
                    return equipmentList ?
                        <WizardModules
                            service={service}
                            createObjects={createObjects}
                            modules={equipmentList}
                            title={t('Добавьте бытовую технику на сцену')}
                            id={stepId}
                        /> :
                        <div className={cnWizard('CurrentStepError')}>{t('Загрузка каталога')}...</div>;
                case 'services':
                    return <WizardServices service={service}/>;
                case 'spec':
                    return <WizardSpec service={service}/>;
                case 'buy':
                    return <WizardBuy service={service} user={userMod}/>;
                default:
                    return t('Не найден шаг') + ' ' + stepId;
            }
        };

        const resetMetaData = (stepId: string) => {
            setActiveStep(stepId);
        }

        return (
            <div className={cnWizardCurrentStep({step: currentStep.id}, [className])}>
                <div className={cnWizardCurrentStep('Container')}>
                    <div className={cnWizardCurrentStep('Content')}>
                        {renderStep(currentStep.id)}
                    </div>
                    <div className={cnWizardCurrentStep('Buttons')}>
                        <Button
                            className={cnWizardCurrentStep(cnButton(), {type: 'prev'})}
                            leftIcon={{path: 'arrow-left', width: 20, height: 20, marginRight: 8}}
                            text={t('Предыдущий шаг') || undefined}
                            callBack={changePrevStep}
                            mods={{
                                mods: {
                                    color: 'black',
                                    bkg: 'white',
                                    border: 'black',
                                    fill: 'black'
                                },
                                disabled: {
                                    state: isFirstStep,
                                    mods: {disabled: isFirstStep, fill: 'lightBlack'}
                                }
                            }}
                            disabled={isFirstStep}
                            type={'button'}
                        />
                        <Button
                            className={cnWizardCurrentStep(cnButton(), {type: 'next'})}
                            rightIcon={{path: 'arrow-right', width: 20, height: 20, marginLeft: 8}}
                            text={t('Следующий шаг') || undefined}
                            callBack={changeNextStep}
                            mods={{
                                mods: {
                                    color: 'black',
                                    bkg: 'white',
                                    border: 'black',
                                    fill: 'black'
                                },
                                disabled: {
                                    state: isLastStep,
                                    mods: {disabled: isLastStep, fill: 'lightBlack'}
                                }
                            }}
                            type={'button'}
                            disabled={isLastStep}
                        />
                    </div>
                </div>
            </div>
        );
    };