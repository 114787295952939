import React, {ReactNode, useRef, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {Svg} from '../Svg';
import './Tip.css';
import { Popup } from '@yandex/ui/Popup/desktop/bundle'
import {Direction} from '@yandex/ui/usePopper/directions';

export const cnTip = cn('Tip');
export interface TipProps extends IClassNameProps {
    directionIcon: 'right' | 'left';
    message: ReactNode;
    messageDirections?: Direction[];
}
export const Tip: React.FC<TipProps> = ({className, directionIcon, message, messageDirections}) => {
    const [hoverPopup, setHoverPopup] = useState<boolean>(false);
    const tipRef = useRef<HTMLDivElement>(null);

    return (
        <div className={cnTip({}, [className])}
             ref={tipRef}
             onMouseEnter={() => {
                 setHoverPopup(true)
             }}
             onMouseLeave={() => {
                 setHoverPopup(false)
             }}>
            <Svg icon={'question-'+directionIcon} className={cnTip('Svg')} />
            <Popup visible={hoverPopup}
                   view={"default"}
                   target={"anchor"}
                   anchor={tipRef}
                   directions={messageDirections}
                   className={cnTip('Message')}
            >
                {message}
            </Popup>
        </div>
    );
}