import {IClassNameProps} from "@bem-react/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {RadioButton} from '../../RadioButton/RadioButton';
import {TSelectItem} from '../../../../../common-code/types/TSelectItem';
import {cnObjectSelectItem} from '../ObjectSelectItem';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';


export interface IObjectSelectItemDepthsProps extends IClassNameProps {
    detailData?: IMaterialData;
    onChangeDepth?: (material: IMaterialData, value: number) => void;
}

export const ObjectSelectItemDepths: React.FC<IObjectSelectItemDepthsProps> =
    ({
         className,
         detailData,
         onChangeDepth
    }) => {
        const {t} = useTranslation();

    if (!detailData || !detailData.heights || !detailData.height) {
        return null;
    }

    const onApplyChangeDepth = (value: string) => {
        if (!isNaN(+value) && onChangeDepth && detailData) {
            onChangeDepth(detailData, +value);
        }
    };

    const getOptions = (items: number[]): TSelectItem[] => {
        let item: number;
        let options: TSelectItem[] = [];

        for (item of items) {
            options.push({
                id: '' + item,
                title: item + ' ' + t('мм')
            })
        }

        return options;
    }
    return (
        <div
            className={cnObjectSelectItem('Height', [className])}
        >
            {detailData.heights.length > 1 ?
                <RadioButton
                    className={cnObjectSelectItem('RadioButton')}
                    options={getOptions(detailData.heights)}
                    value={''+detailData.height}
                    callBack={onApplyChangeDepth}
                /> : <div>
                    {t('Толщина')}: {detailData.heights.map(value => { return value + t('мм') + '; ' })}
                </div>}

        </div>
    );
}