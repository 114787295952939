import {useState} from 'react';
import {ICreateObjectData} from '../../common-code/interfaces/createData/ICreateObjectData';
import {KitchenService} from '../3d/services/KitchenService/KitchenService';
import {IModulePriceData} from '../../common-code/interfaces/catalog/IModulePriceData';
import {IMaterialData} from '../../common-code/interfaces/materials/IMaterialData';
import {IFacadeMaterialData} from '../../common-code/interfaces/materials/IFacadeMaterialData';

export default function useCreateOptions(
    objectData: ICreateObjectData,
    service: KitchenService,
    width?: number,
    corpusWidth?: number
) {
    const [createOptions, setCreateOptions] =
        useState(service.getDefaultOptions(objectData, width, corpusWidth));
    const setNewOptions = (fieldId: string, value: any, groupId?: string) => {
        let newOptions = {...createOptions};
        let modulePrice: IModulePriceData;
        let corpusMaterial: IMaterialData | undefined;
        let facadeMaterial: IFacadeMaterialData | undefined;

        if (groupId && newOptions[groupId]) {
            newOptions[groupId] = {...newOptions[groupId], [fieldId]: value};
        } else {
            newOptions[fieldId] = value;
        }
        if (newOptions.isSameWidthDepth &&
            groupId === 'corpus' && fieldId === 'width') {
            newOptions[groupId] = {...newOptions[groupId], depth: value};
        }
        if (fieldId === 'facadeMaterial') {
            modulePrice = service.calculateCreateObjectPrice(objectData, newOptions);
            if (modulePrice.errors.length > 0) {
                corpusMaterial = service.findCreateObjectCorpusMaterialByFacadeMaterialId(
                    objectData,
                    newOptions,
                    value
                );
                if (corpusMaterial) {
                    newOptions.corpus = {...newOptions.corpus, material: corpusMaterial.id};
                }
            }
        }
        if (groupId === 'corpus' && fieldId === 'material') {
            modulePrice = service.calculateCreateObjectPrice(objectData, newOptions);
            if (modulePrice.errors.length > 0) {
                facadeMaterial = service.findCreateObjectFacadeMaterialByCorpusMaterialId(
                    objectData,
                    createOptions,
                    value
                );
                if (facadeMaterial) {
                    newOptions.facadeMaterial = facadeMaterial.id;
                }
            }
        }
        setCreateOptions(newOptions);
    }
    return [createOptions, setNewOptions];
}