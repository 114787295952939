import React, { useState } from 'react'
import { hingeAvailableKitCodes } from '../../../constants/constants';
import InputCheckbox from './InputCheckbox';
import { cnCreateForm } from '../../../FormModule';
import { Select } from '../../../../../../components/Select/Select';
import { editOption } from '../../../../utils/editOption';
import { Button } from '../../../../../../components/Button';
import { Tumbler } from '../../../../../../components/Tumbler/Tumbler';
import { ISaveHingeData } from 'common-code/lib/interfaces/saveData/ISaveHingeData';
import { IFacade } from '../initialFacade';
import { fetchOptions, saveOptions } from '../../../../api/OptionsServices';
import { useEffectDidMount } from '../../../../../../../hooks/useEffectDidMount';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../redux/AppStore';
import { useDispatch } from 'react-redux';
import { CHANGE_HINGES_FURNITURE_TYPES } from '../../../../../../../constants';
import AddOptionInSelect from '../../../components/AddOptionInSelect';
import { cn } from '@bem-react/classname';
import Hinge from './Hinge';
import "./Hinges.css";

export const cnHinges = cn('Hinges');

interface HingesProps {
    facadeNew: IFacade,
    setFacadeNew: (value: IFacade) => void
}

function Hinges({facadeNew, setFacadeNew} : HingesProps) {

    const dispatch = useDispatch();

    const initionalHinge = {
        id: 0,
        availableKitCodes: [],
        furnitureType: "",
    };
    
    const {hinges} = facadeNew;

    // получение типов петель
    const [hingeFurnTypes, setHingeFurnTypes] = useState<Record<string, string> >(useSelector((state: AppState) => state.wizard.editModule?.hingesFurnTypes) || {});
    // добавление типов петель
    const addHingeFurnitureTypes = (hingeFurnTypesNew: Record<string, string>) => {
        setHingeFurnTypes(hingeFurnTypesNew);
        saveOptions(hingeFurnTypesNew, "hinges-furniture-types").then((res: Record<string, string> | undefined) => {
            if(res) dispatch({ type: CHANGE_HINGES_FURNITURE_TYPES, payload: res });
        });
    }
    // если типы петель еще не получены загружаем их с сервера
    useEffectDidMount(() => {
        if (Object.keys(hingeFurnTypes).length === 0) {
            fetchOptions("hinges-furniture-types").then(
            (res: Record<string, string> | undefined) => {
              if (res) {
                setHingeFurnTypes(res);
                dispatch({ type: CHANGE_HINGES_FURNITURE_TYPES, payload: res });
              }
            }
          );
        }
      });

    const [error, setError] = useState("");
    const [hinge, setHinge] = useState < ISaveHingeData> (initionalHinge);
    
    const onInputChange = (e:  React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        setError("");
        setHinge({ ...hinge, [e.target.name]: e.target.value });
    };

    const onChecked = (e:React.ChangeEvent<HTMLInputElement>) => {
        setError("");
        setFacadeNew({ ...facadeNew, [e.target.name]: e.target.checked });
    }
    
    const {furnitureType, availableKitCodes } = hinge;

    const hingeAdd = () => {
        if ((furnitureType as string).length === 0) {
            setError("Необходимо выбрать тип петель");
            return
        }
        if((availableKitCodes as string[]).length === 0) {
            setError("Необходимо указать доступность петель");
            return
        }
        if(facadeNew.arrHinges.find((el) => el.furnitureType === hinge.furnitureType)){
            setError("Петли для указанного типа уже существуют");
            return
        }
        setFacadeNew({ ...facadeNew, arrHinges: [...facadeNew.arrHinges, hinge], error: "" });
        setHinge(initionalHinge)
    }
        
  return (
    <>
        <hr/>
        <h4 className={cnCreateForm('Title')}>Петли</h4>

        <Tumbler
            className={cnCreateForm('Tumbler')}
            checked={hinges}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Добавить петли"
            name="hinges"
        />


        {hinges && 
            <div className={cnCreateForm('Modal-Content')}>
                <div className={cnHinges("Content")}>

                    <div className={cnHinges('Section')}>

                        <p className={cnCreateForm('Error')}>{error}</p>

                        <div>
                            <label htmlFor="furnitureType">Необходимо выбрать тип петель</label>
                                <Select
                                    className={cnCreateForm('Select')}
                                    placeholder={"Выберите значение"}
                                    showPlaceholder={false}
                                    options={editOption(hingeFurnTypes)}
                                    value={(furnitureType as string)}
                                    callBack={(e) => onInputChange({...e,target:{...e.target, name: 'furnitureType'}})}
                                />
                                    <AddOptionInSelect 
                                        options={hingeFurnTypes} 
                                        setOptions={addHingeFurnitureTypes} 
                                        isReverse={true}
                                    />
                        </div>

                        <p className={cnHinges('Title')}>Комплектация модулей</p>
                            {Object.entries(hingeAvailableKitCodes).map((code, index)=>
                                <InputCheckbox 
                                    setError={setError} 
                                    hinge={hinge} 
                                    setHinge={setHinge} 
                                    code={code} 
                                    key={index}
                                />
                            )}

                        <Button
                            className={cnCreateForm('Button')}
                            type={'button'}
                            text={`Добавить петли`}
                            leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                            mods={{
                                mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                                hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                            }}
                            callBack={hingeAdd}
                        />
                    </div>

                    {facadeNew.arrHinges.length > 0 && 

                    <div  className={cnHinges('Section')} >
                        {facadeNew.arrHinges.map( (hinge: ISaveHingeData, index: number)=>
                            <Hinge
                                key={`${index}${hinge.furnitureType}`}
                                hinge={hinge}
                                index={index}
                                facadeNew={facadeNew}
                                setFacadeNew={setFacadeNew}
                            />
                        )}
                    </div>
                    }

                </div>

            </div>
        }
    </>
  )
}

export default Hinges