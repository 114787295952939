import React, {ChangeEvent} from 'react';
import {IClassNameProps} from "@bem-react/core";
import './WizardServices.css'
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {cn} from '@bem-react/classname';
import {cnTumbler, Tumbler} from '../../components/Tumbler/Tumbler';
import {useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {useTranslation} from 'react-i18next';
import {WizardServicesItems} from './Items/WizardServices-Items';
import {IProjectServiceData} from '../../../../common-code/interfaces/project/IProjectServiceData';

export const cnWizardServices = cn('WizardServices')

export interface IWizardServicesProps extends IClassNameProps {
    service: KitchenService;
}

export const WizardServices: React.FC<IWizardServicesProps> =
    ({
         service,
         className
     }) => {
        const enableServices: boolean = useSelector((state: AppState) => state.wizard.options.enableServices);
        const enableAutoServices: boolean = useSelector((state: AppState) => state.wizard.options.enableAutoServices);
        const projectServices: IProjectServiceData[] | undefined = useSelector((state: AppState) => state.wizard.project.services);
        const {t} = useTranslation();

        const onToggleEnableServices = (event: ChangeEvent<HTMLInputElement>) => {
            service.setEnableServices(event.target.checked, true);
        }
        const onToggleEnableAutoServices = (event: ChangeEvent<HTMLInputElement>) => {
            service.setEnableAutoServices(event.target.checked, true);
        }
        return (
            <div className={cnWizardServices({}, [className])}>
                <div className={cnWizardServices('AllServices')}>
                    <Tumbler size={'l'} className={cnWizardServices(cnTumbler())}
                             checked={enableServices} callBack={onToggleEnableServices}
                             label={<div className={cnWizardServices('TumblerTitle')}>{t('Услуги проекта')}</div>}/>
                </div>
                {!enableServices || <div className={cnWizardServices('Auto')}>
                    <Tumbler className={cnWizardServices(cnTumbler())}
                             checked={enableAutoServices} callBack={onToggleEnableAutoServices} label={t('Вкл/выкл авторасчет услуг')}/>
                </div>}
                {!projectServices ? <div className={cnWizardServices('Loading')}>{t('Данные загружаются')}... </div> :
                    <WizardServicesItems service={service} services={projectServices}/>
                }
            </div>
        );
    };