import {ThreeUnit} from '../../../ThreeUnit/ThreeUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {Vector3} from 'three';
import {
    KDETAIL_PRICE_TYPE_COLLECTIONS,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_NONE,
} from '../../../../../../../common-code/constants';
import {ICoverPoints} from '../../../../../interfaces/ICoverPoints';
import {TDetail} from '../../../../../types/TDetail';
import {TPositionSideType} from '../../../../../../../common-code/types/TPositionSideType';
import {ThreeAccessoryUnit} from '../ThreeAccessoryUnit';
import {ISaveApronUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveApronUnitData';
import {IApronData} from '../../../../../../../common-code/interfaces/materials/IApronData';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';

export class ThreeApronUnit extends ThreeAccessoryUnit {
    saveData: ISaveApronUnitData;

    constructor(options: ISaveApronUnitData, service: KitchenService) {
        super(options, service);
        this.saveData = this.initSaveData(options);
    }

    public initState(isRebuild?: boolean) {
        this.addCoverPoints([
            new Vector3(-this.getWidth()/2, -this.getDepth()/2, -this.getHeight()/2),
            new Vector3(this.getWidth()/2, this.getDepth()/2, this.getHeight()/2)
        ]);
        super.initState(isRebuild);
    }

    public getUnionDetailYPosition(detail: TDetail): number {
        return this.view3d.position.y - this.getSideDistance(SIDE_TYPE_BOTTOM) + detail.getUnionYPosition();
    }

    public defaultYPosition(): number {
        return this.service.getBottomUnitLegsHeight() + this.service.getBottomUnitHeight() + this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    public getApronInitWidth(positionType?: TPositionSideType): number {
        return this.getCorpusSizes().width;
    }

    public getIsDimensionsSameClassName(): boolean {
        return true;
    }

    public getAvailableHeights(): number[] | undefined {
        let availableHeights: number[] = [];
        let apronMaterial: IApronData | undefined;

        if (this.aprons && this.aprons[0]) {
            apronMaterial = this.aprons[0].getMaterialData();
        }
        if (apronMaterial && apronMaterial.heights) {
            availableHeights = apronMaterial.heights;
        }

        return availableHeights.length ? availableHeights : undefined;
    }

    protected initSaveData(options: ISaveApronUnitData): ISaveApronUnitData{
        super.initSaveData(options);
        if (options.isDimensions === undefined) {
            options.isDimensions = false;
        }
        options.sizes.height = this.initHeight(options);
        return options;
    }

    protected initHeight(options: ISaveApronUnitData): number {
        let initHeight: number;
        let apron: IApronData | undefined;

        try {
            if (options.aprons && options.aprons[0] && options.aprons[0].material) {
                apron = this.service.getApronMaterial(options.aprons[0].material);
            }
            if (apron) {
                initHeight = (apron.heights && apron.heights.includes(options.sizes.height)) ? options.sizes.height : apron.height;
            } else {
                initHeight = this.service.getApronHeight();
            }
        } catch (e) {
            initHeight = options.sizes.height;
        }

        return initHeight;
    }

    protected initApronsData(aprons?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let apron: ISaveKUnitDetailData;

        if (!aprons) {
            aprons = [{
                id: 0,
                initPosition: {x: 0, y: 0, z: 0},
                priceType: KDETAIL_PRICE_TYPE_COLLECTIONS,
                positionType: SIDE_TYPE_NONE,
                isSelfVisible: true,
            }];
        }
        for (apron of aprons) {
            apron.sizes = {
                height: this.getHeight(),
                width: this.getDepth(),
                length: this.getWidth()
            }
        }

        return aprons;
    }

    protected getMoveLines(): number[] {
        let unitGlobalPoints: ICoverPoints;
        let lines: number[];
        let unit: ThreeUnit;

        lines = [];
        const apronUnits: ThreeUnit[] = this.service.getUnits(['aprons']);
        lines.push(this.service.getTopFirstYPosition());
        lines.push(this.service.getInstallTopUnitHeight());
        lines.push(this.service.getInstallBottomUnitHeight());
        for (unit of apronUnits) {
            if (this.getId() === unit.getId()) {
                continue;
            }
            unitGlobalPoints = unit.getGlobalPoints(unit.selectCover);
            lines.push(Math.round(unitGlobalPoints.box.min.y));
            lines.push(Math.round((unitGlobalPoints.box.min.y + unitGlobalPoints.box.max.y)/2));
            lines.push(Math.round(unitGlobalPoints.box.max.y));
        }

        lines = lines.filter((item, index) => {
            return lines.indexOf(item) === index;
        });

        return lines;
    }
}