import {ThreeFacadeUnit} from '../ThreeFacadeUnit';
import {SIDE_TYPE_BOTTOM, SIDE_TYPE_LEFT} from '../../../../../../../common-code/constants';
import {TSizes} from '../../../../../../../common-code/types/geometry/TSizes';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';

export class ThreeSideBottomFacadeUnit extends ThreeFacadeUnit {
    
    public isEndUnit(): boolean {
        return true;
    }

    public defaultYPosition(): number {
        if (this.saveData.initPosition && this.saveData.initPosition.y !== undefined &&
            !isNaN(+this.saveData.initPosition.y)) {
            return this.getSideDistance(SIDE_TYPE_BOTTOM) + +this.saveData.initPosition.y;
        }

        // TODO: добавить потом отсуп для ножек от пола
        return this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    public getCorpusSizes(): TSizes {
        return {
            length: this.getDepth(),
            height: this.getHeight(),
            width: this.getWidth()
        };
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        tabletops = super.initTabletopsData(tabletops);
        if (tabletops && tabletops[0]) {
            if (!tabletops[0].initPosition) {
                tabletops[0].initPosition = {};
            }
            tabletops[0].initPosition.z = -this.getDepth()/2 + (tabletops[0].sizes && tabletops[0].sizes.width ? tabletops[0].sizes.width/2 : 300);
        }

        return tabletops;
    }

    protected calculateInitFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        let facadeData: ISaveFacadeData;
        let direction: number;

        direction = this.getSideType() === SIDE_TYPE_LEFT ? -1 : 1;
        if (facades) {
            for (facadeData of facades) {
                facadeData.rotation = {
                    y: direction * Math.PI/2
                }
                facadeData.margin = {
                    x: -direction * this.getWidth()/2,
                    y: 0,
                    z: 0
                }
                facadeData.notTransparent = true
            }
        }    
        return facades;
    }
    
}