import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {
    ThreeBottomFullLengthSidewallOvenCorpus
} from "../../../details/ThreeCorpus/types/ThreeBottomFullLengthSidewallOvenCorpus";
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnitFullLengthSidewallOven extends ThreeBottomUnit {
    corpus: ThreeBottomFullLengthSidewallOvenCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomFullLengthSidewallOvenCorpus {
        return new ThreeBottomFullLengthSidewallOvenCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}