import React, { useMemo } from "react";
import { IClassNameProps } from "@bem-react/core";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { useTranslation } from "react-i18next";
import { CreateObjectOptions } from "../CreateObjectOptions/CreateObjectOptions";
import { Button, cnButton } from "../../../components/Button";
import { cn } from "@bem-react/classname";
import FormModule from "../FormModule/FormModule";
import { WizardEditVerticalMenu } from "../VerticalMenu/WizardEdit-VerticalMenu";
import { cnWizardEdit } from "../WizardEdit";
import { ICreateGroup } from "common-code/lib/interfaces/createData/ICreateGroup";
import { unitsList } from "../unitsList";
import { TCreateGroup } from "common-code/lib/types/TCreateGroup";
import { useDispatch } from "react-redux";
import {CHANGE_EDIT_MODE, CHANGE_ITEM_MODULE, MESSAGE_TYPE_ERROR, SHOW_LOADING} from "../../../../constants";
import { leftCameraAspect } from "../utils/CameraLeftAspect";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import { useLocation } from "react-router-dom";
import { sendModuleData } from "../api/UnitListServices";
import "./EditModule.css";
import {showMessage} from '../../../../helpers';
import HookFormProvider from "../FormModule/HookFormProvider";
import { TEditMode } from "../../../../../common-code/types/TEditMode";

type TStatus = "new" | "edited";
export interface IDataModule {
  collection_id: string;
  group_id: string;
  module_id: string;
  status: TStatus;
  user:{
      name: string;
      id: number;
  }
}


interface IEditModuleProps extends IClassNameProps {
  service: KitchenService;
  saveUnitList: (unitListNew: ICreateGroup[]) => void;
  modules: ICreateGroup[] | undefined;
}

const cnEditModule = cn("EditModule");

function EditModule({
  service,
  saveUnitList,
  modules,
}: IEditModuleProps) {

  const user = useSelector((state: AppState) => state.user);
  const itemModule = useSelector((state: AppState) => state.wizard.editModule.itemModule);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const collectionId = useLocation().search
  .split("&")
  .find((param) => param.includes("collection"))
  ?.split("=")[1];

  // данные для статистики по созданию/редактированию модулей
  const dataModule = useMemo(() => (itemModule && user ? {
    collection_id: collectionId,
    group_id: itemModule?.group,
    module_id: itemModule?.uid,
    user:{
        name: user?.name,
        id: user.id,
    }
    // eslint-disable-next-line
  } : null), [itemModule]);

  // сохранение изображения для модуля
  const getImage = async (uid: string) => {
    const base64 = await service?.createDivImages("none", leftCameraAspect(service, itemModule));
    const url = await service?.saveUnitImages(uid, base64);
    return `${url?.sketch}?${new Date().getTime()}`;
  };

  // сохранить новый модуль в коллекцию
  const saveModuleCollection = async () => {  
    if (!itemModule || !itemModule.hasOwnProperty("group")) return;
    // проверка на существование модуля
    if (
      modules?.some((unit) =>
        unit.items.some((item) => item.uid === itemModule?.uid)
      )
    ) {
      alert(
        "Модуль с таким кодом по каталогу уже существует! Укажите другой код."
      );
      return;
    }
    dispatch({ type: SHOW_LOADING });
    // изображение
    const url = await getImage(itemModule.uid);
    console.log("url", url);
    
    const itemModuleNew = { ...itemModule };
    if (url) {
      itemModuleNew.image = url;
    }
    const { group, isEdit, catalogCodeEdited, notPrice, ...module } = itemModuleNew;
    const hasGroup = modules?.some((unit) => unit.id === group);
    if((group as TCreateGroup) in unitsList) {
    // add to unit list
      const unitListNew = hasGroup
        ? modules?.map((unit) =>
            unit.id === group
              ? { ...unit, items: [...unit.items, module] }
              : unit
          )
        : [
            ...(modules as ICreateGroup[]),
            {
              ...unitsList[group as TCreateGroup],
              items: [module],
            },
          ];
      saveUnitList(unitListNew as ICreateGroup[]);
      // отправка статистики
      if(!window.location.host.includes("localhost")) {
        sendModuleData({ ...dataModule, status: "new" } as IDataModule).catch((err) => {
          console.log(err);
        })
      }
    } else {
      alert("Модуль с такой группой не существует, необходимо добавить новую группу!");
    }
  };

  // заменить модуль в коллекции на измененный
  const editModuleCollection = async () => {
    if (!itemModule || !itemModule.hasOwnProperty("group")) return;
    dispatch({ type: SHOW_LOADING });
    // изображение
    const itemModuleNew = { ...itemModule };
    const url = await getImage(itemModule.uid);
      if (url) {
        itemModuleNew.image = url;
      }
    // проверка на существование модуля
    if (
      modules?.some((unit) =>
        unit.items.some((item) => item.uid === itemModuleNew?.uid)
      )
    ) {
      const { group, isEdit, catalogCodeEdited, notPrice, ...module } = itemModuleNew;
      // change in unit list
      const unitListNew = modules?.map(unit => {
        const items = unit.items;
        const existingItem = items.find(item => item.uid === module.uid);
        const newItems = existingItem
          ? items.map(item => (item.uid === module.uid ? module : item))
          : [...items, module];
        const filteredItems = existingItem
          ? items.filter(item => item.uid !== module.uid)
          : items;
        return { ...unit, items: unit.id === group ? newItems : filteredItems };
      });
         
      saveUnitList(unitListNew as ICreateGroup[]);
      // отправка статистики
      if(!window.location.host.includes("localhost")) {
        sendModuleData({ ...dataModule, status: "edited" } as IDataModule)
      }
    } else {
      alert(
        "Модуля с таким кодом по каталогу не существует! Укажите другой код."
      );
      return;
    }
  };
  const handleToggleMode = (str: TEditMode) => {
    dispatch({ type: CHANGE_EDIT_MODE, payload: str});
    dispatch({type: CHANGE_ITEM_MODULE , payload: null}); 
  };

  const emptyEntities: string[] = service.emptyLoadEntities();
  if (emptyEntities.length) {
    showMessage({
      type: MESSAGE_TYPE_ERROR,
      message: 'Сначала заполните библиотеку материалов'
    }, dispatch);
    let changeEditMode: TEditMode = '';
    let mode: string;
    for (mode of emptyEntities) {
      if (["materials", "corpusMaterials", "facadeMaterials", "facades", "plinths"].includes(mode)) {
        changeEditMode = mode as TEditMode;
        break;
      }
    }
    dispatch({ type: CHANGE_EDIT_MODE, payload: changeEditMode });
    dispatch({type: CHANGE_ITEM_MODULE , payload: null});
  }

  return (
    <>
      <HookFormProvider>
        <FormModule
          onOpenModal={() => setModal(true)}
          buttonRef={buttonRef}
        />
      </HookFormProvider>

      <div className={cnEditModule("Buttons-Container")}>
        <Button
          className={cnEditModule(cnButton(), { type: "prev" })}
          leftIcon={{path: "arrow-left",width: 20,height: 20,marginRight: 8,}}
          text={t("Предыдущий шаг") || undefined}
          callBack={() => handleToggleMode("")}
          mods={{mods: {  color: "black",  bkg: "white",  border: "black",  fill: "black",},}}
          type={"button"}
        />
      </div>

      <WizardEditVerticalMenu
        service={service}
        className={cnWizardEdit("VerticalMenu")}
      />

      {itemModule && (
        <>
          {modal ? (
            <div className={cnEditModule("Object-Options")}>
              <CreateObjectOptions
                objectData={itemModule}
                service={service}
                onClose={()=>setModal(false)}
                handleFocus={()=>buttonRef.current?.click()}
                width={null}
                corpusWidth={null}
              />
            </div>
          ) : (
            <>
              {itemModule.hasOwnProperty("group") && (
                <div className={cnEditModule("Button-Add")}>
                  <Button
                    className={cnEditModule(cnButton())}
                    type={"button"}
                    text={"Добавить новый модуль в коллекцию"}
                    leftIcon={{path: "plus2",width: 16,height: 16,marginRight: 8,}}
                    mods={{mods: {bkg: "white",color: "lightGreen",border: "lightGreen",fill: "lightGreen",},
                    hover: {mods: {  bkg: "lightGreen",  color: "white",  fill: "white",},},}}
                    callBack={saveModuleCollection}
                  />

                  {itemModule.isEdit && !itemModule.catalogCodeEdited && (
                    <Button
                      className={cnEditModule(cnButton())}
                      type={"button"}
                      text={"Заменить редактируемый модуль в коллекции"}
                      leftIcon={undefined}
                      mods={{mods: {  bkg: "white",  color: "lightGreen",  border: "lightGreen",  fill: "lightGreen",},
                      hover: {  mods: {    bkg: "lightGreen",    color: "white",    fill: "white",  },},}}
                      callBack={editModuleCollection}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default EditModule;
