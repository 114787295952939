import {
  ALIGN_CENTER,
  HANDLE_TYPE_HORIZONTAL,
} from "common-code/lib/constants";
import { IFacadeHandleData } from "../../../../../../common-code/interfaces/materials/IFacadeHandleData";

export const initialHandle = (handle: IFacadeHandleData | undefined) => {
  return handle
    ? {
        isHandle: !!handle.id,
        location: handle.location,
        handleAlign: !!handle.align,
        handleAlignX: handle.align ? handle.align.x : ALIGN_CENTER,
        handleAlignY: handle.align ? handle.align.y : ALIGN_CENTER,
        handleMargin: !!handle.margin,
        handleMarginX: handle.margin ? handle.margin.x : "0",
        handleMarginY: handle.margin ? handle.margin.y : "0",
        handleMarginZ: handle.margin ? handle.margin.z : "0",
        handlePosition: !!handle.position,
        handlePositionX: handle.position ? handle.position.x : "0",
        handlePositionY: handle.position ? handle.position.y : "0",
        handlePositionZ: handle.position ? handle.position.z : "0",
        handleRotation: !!handle.rotation,
        handleRotationX: handle.rotation ? handle.rotation.x : "0",
        handleRotationY: handle.rotation ? handle.rotation.y : "0",
        handleRotationZ: handle.rotation ? handle.rotation.z : "0",
        handleHorizontalMove: !!handle.horizontalMove,
        handleCalculate: !!handle.calculate,
      }
    : {
        isHandle: false,
        location: HANDLE_TYPE_HORIZONTAL,
        handleAlign: false,
        handleAlignX: ALIGN_CENTER,
        handleAlignY: ALIGN_CENTER,
        handleMargin: false,
        handleMarginX: "0",
        handleMarginY: "0",
        handleMarginZ: "0",
        handlePosition: false,
        handlePositionX: "0",
        handlePositionY: "0",
        handlePositionZ: "0",
        handleRotation: false,
        handleRotationX: "0",
        handleRotationY: "0",
        handleRotationZ: "0",
        handleHorizontalMove: false,
        handleCalculate: false,
      };
};
