import {CommonDetail} from '../../../CommonDetail/CommonDetail';
import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {ISaveUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveUnitDetailData';

export class ThreeUnitDetail extends CommonDetail {
    saveData: ISaveUnitDetailData;
    service: KitchenService;
    unit: ThreeUnit;

    constructor(options: ISaveUnitDetailData, unit: ThreeUnit) {
        super(options, unit);
        this.service = unit.service;
        this.unit = unit;
        this.saveData = this.initThreeUnitSaveData(options);
    }

    protected initThreeUnitSaveData(options: ISaveUnitDetailData): ISaveUnitDetailData {
        if (options.isVisible === undefined) {
            options.isVisible = true;
        }
        return options;
    }

    public getData(): ISaveUnitDetailData {
        let data: ISaveUnitDetailData = super.getData();
        delete data.isStick;
        delete data.isLevelStick;
        delete data.isWallStick;
        return data;
    }

    public hide() {
        this.setVisible(false);
    }

    public show() {
        this.setVisible(true);
    }

    public isVisible(): boolean {
        return !!this.saveData.isVisible;
    }

    public isSelfVisible(): boolean {
        return !!this.saveData.isSelfVisible;
    }

    public setVisible(isVisible: boolean) {
        if (this.saveData.isSelfVisible !== undefined) {
            this.saveData.isVisible = this.saveData.isSelfVisible;
        } else {
            this.saveData.isVisible = isVisible;
        }
        this.updateView3dVisible();
    }

    public setSelfVisible(isVisible: boolean) {
        this.saveData.isSelfVisible = isVisible;
        this.saveData.isVisible = isVisible;
        this.updateView3dVisible();
    }

    public clearSelfVisible() {
        this.saveData.isSelfVisible = undefined;
    }

    protected updateView3dVisible() {
        this.view3d.visible = !!this.saveData.isVisible;
    }
}