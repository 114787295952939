import axios from "axios";
import { ISaveTexture } from "../../../../../common-code/interfaces/materials/ISaveTexture";
import { ISaveImage } from "../../../../../common-code/interfaces/materials/ISaveImage";
import { IMaterialData } from "common-code/lib/interfaces/materials/IMaterialData";
import { TMaterialEditMode } from "../../../../../../../common/types/TMaterialEditMode";

export async function saveTexture(
  texture: ISaveTexture
): Promise<ISaveTexture | undefined> {
  const requestConfig = {
    method: "post",
    url: "/api/materiallist/save-texture",
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    data: texture,
  };
  try {
    const response = await axios(requestConfig);
    return response.data;
  } catch {
    return undefined;
  }
}

export const saveImage = async (
  imageData: ISaveImage
): Promise<ISaveImage | undefined> => {
  const requestConfig = {
    method: "post",
    url: "/api/materiallist/save-image",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: imageData,
  };

  try {
    const response = await axios(requestConfig);
    return response.data;
  } catch {
    return undefined;
  }
};

export async function saveMaterial(
  materialData: IMaterialData[],
  materialType: TMaterialEditMode,
  collectionId: string
): Promise<IMaterialData[] | undefined> {
  const requestConfig = {
    method: "post",
    url: `/api/materiallist/save-materials/${materialType}/${collectionId}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: materialData,
  };

  const response = await axios(requestConfig);
  return response.data;
}
