import {BufferGeometry, Material, Object3D, Scene, Vector3, WebGLRenderer} from 'three';
import {Camera} from 'three/src/cameras/Camera';
import {MathHelper} from 'common-code';
import {CommonHelper} from 'common-code';
import {ICommonObject3dData} from '../../interfaces/ICommonObject3dData';
import {CommonObject} from '../../objects/CommonObject/CommonObject';

export class ThreeHelper {
    public static getLineHashCode(pointA: Vector3, pointB: Vector3) {
        let line: {type: string, k: number, b: number, x?: number, y?: number, normalAngle?: number}, hashCode;

        if (pointA.x === pointB.x && pointA.z === pointB.z) {
            line = {type: 'none', k: 0, b: 0};
        } else {
            line = MathHelper.directEquation(
                {x: pointA.x, y: pointA.z},
                {x: pointB.x, y: pointB.z}
            );
        }

        switch (line.type) {
            case 'horizontal':
                delete line.x;
                if (line.y && Number.isFinite(line.y)) {
                    line.y = +line.y.toFixed(2);
                }
                break;
            case 'vertical':
                delete line.y;
                if (line.x && Number.isFinite(line.x)) {
                    line.x = +line.x.toFixed(2);
                }
                break;
            default:
                delete line.x;
                delete line.y;
                break;
        }
        if (!line.k) {
            line.k = 0;
        }
        if (!line.b) {
            line.b = 0;
        }
        line.k = +line.k.toFixed(2);
        line.b = +line.b.toFixed(2);
        delete line.normalAngle;

        hashCode = CommonHelper.md5(line);

        return hashCode;
    }

    public static getCommonObject3dData(parent: Object3D): ICommonObject3dData | undefined {
        let parentPosition: Vector3 = parent.userData.centerPosition || parent.position;
        let vector: Vector3;

        if (parent.userData.vector && parent.userData.vector instanceof Vector3) {
            vector = parent.userData.vector;
        } else {
            vector = parent.userData.vector = new Vector3();
        }
        let commonObject: CommonObject;
        if (!parent.userData.commonObject || !(parent.userData.commonObject instanceof CommonObject)) {
            return undefined;
        }
        commonObject = parent.userData.commonObject;

        return {
            commonObject: commonObject,
            parentPosition: parentPosition,
            vector: vector
        }
    }
}

export const onBeforeRenderHideForBack = function (
    renderer: WebGLRenderer,
    scene: Scene,
    camera: Camera,
    geometry: BufferGeometry,
) {
    // @ts-ignore
    let parent: Object3D = this.userData.parent || this.parent;
    let commonObject3DData = ThreeHelper.getCommonObject3dData(parent);
    if (!commonObject3DData) {
        return;
    }
    if (commonObject3DData.vector.subVectors(camera.position, commonObject3DData.parentPosition).dot(commonObject3DData.commonObject.getGlobalFrontVector()) > 0) {
        geometry.setDrawRange(0, 0); // it is too late to set visible = false, so do this, instead
        commonObject3DData.commonObject.canSelect = false;
    } else {
        commonObject3DData.commonObject.canSelect = true;
    }
}

export const onAfterRenderHideForBack = function (
    renderer: WebGLRenderer,
    scene: Scene,
    camera: Camera,
    geometry: BufferGeometry,
) {
    geometry.setDrawRange(0, Infinity);
}

export const onBeforeRenderTransparentForBack = function (
    renderer: WebGLRenderer,
    scene: Scene,
    camera: Camera,
    geometry: BufferGeometry,
    material: Material
) {
    // @ts-ignore
    let parent: Object3D = this.userData.parent || this.parent;
    let commonObject3DData = ThreeHelper.getCommonObject3dData(parent);
    if (!commonObject3DData) {
        return;
    }
    if (commonObject3DData.vector.subVectors(camera.position, commonObject3DData.parentPosition).dot(commonObject3DData.commonObject.getGlobalFrontVector())/
        commonObject3DData.vector.subVectors(camera.position, commonObject3DData.parentPosition).length() > (parent.userData.transparentAngle ?? 0)) {
        // @ts-ignore
        if (!this.userData.transparentForBack) {
            // @ts-ignore
            this.userData.transparentForBack = {
                transparent: material.transparent,
                opacity: +material.opacity
            };
        }
        material.transparent = true;
        material.opacity = 0.2;
        material.needsUpdate = true;
        // @ts-ignore
        this.renderOrder = this.userData.transparentRenderOrder ?? 6;
    } else {
        // @ts-ignore
        this.renderOrder = this.userData.commonRenderOrder ?? 0;
    }

}

export const onAfterRenderTransparentForBack = function (
    renderer: WebGLRenderer,
    scene: Scene,
    camera: Camera,
    geometry: BufferGeometry,
    material: Material
) {
    // @ts-ignore
    if (this.userData.transparentForBack) {
        // @ts-ignore
        material.transparent = !!this.userData.transparentForBack.transparent;
        // @ts-ignore
        material.opacity = +this.userData.transparentForBack.opacity;
    }
}