import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cnTumbler, Tumbler} from '../../../components/Tumbler/Tumbler';
import {cn} from '@bem-react/classname';
import './Accessories-Group.css';
import {ObjectSelect} from '../../../components/ObjectSelect/ObjectSelect';
import {TObjectSelectType} from '../../../../types/TObjectSelectType';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {ITabletopData} from '../../../../../common-code/interfaces/materials/ITabletopData';
import {ICornerData} from '../../../../../common-code/interfaces/materials/ICornerData';
import {IApronData} from '../../../../../common-code/interfaces/materials/IApronData';
import {IPlinthData} from '../../../../../common-code/interfaces/materials/IPlinthData';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';
import {IFacadeMaterialData} from '../../../../../common-code/interfaces/materials/IFacadeMaterialData';

export const cnAccessoriesGroup = cn('AccessoriesGroup');

export interface IAccessoriesGroupProps extends IClassNameProps {
    type: TObjectSelectType;
    title: string;
    service: KitchenService;
    list?: IApronData[] | ICornerData[] | IMaterialData[] | IFacadeMaterialData[] | IPlinthData[] | ITabletopData[];
    selectObject?: IApronData | ICornerData | IMaterialData | IFacadeMaterialData | IPlinthData | ITabletopData;
    onSelectObject?:(data: IApronData | ICornerData | IMaterialData | IFacadeMaterialData | IPlinthData | ITabletopData) => void;
    checkbox?: boolean;
    onChangeCheckbox?: (event: ChangeEvent<HTMLInputElement>) => void;
    onSelectObjectChangeDepth?: (material: IMaterialData, value: number) => void;
    facadeMaterials?: IFacadeMaterialData[];
}

export const AccessoriesGroup: React.FC<IAccessoriesGroupProps> =
    ({
         className,
         type,
         title,
         service,
         list,
         selectObject,
         onSelectObject,
         checkbox,
         onChangeCheckbox,
         onSelectObjectChangeDepth,
         facadeMaterials,
    }) => {
    return (
        <div className={cnAccessoriesGroup({type: type}, [className])}>
            <div className={cnAccessoriesGroup('Header')}>
                {checkbox !== undefined && onChangeCheckbox !== undefined ?
                    <Tumbler className={cnAccessoriesGroup(cnTumbler())}
                             checked={checkbox} callBack={onChangeCheckbox}/> : null
                }
                <div className={cnAccessoriesGroup('Title')}>{title}</div>
            </div>
            <div className={cnAccessoriesGroup('Body')}>
                <ObjectSelect className={cnAccessoriesGroup('ObjectSelect', {type: type})}
                              title={title}
                              service={service}
                              type={type}
                              data={{type: type, object: selectObject}}
                              list={list}
                              showObject={checkbox}
                              onSelectObject={onSelectObject}
                              onChangeDepth={onSelectObjectChangeDepth}
                              facadeMaterials={facadeMaterials}
                />
            </div>
        </div>
    );
}