import {useSearchParams} from 'react-router-dom';
import {TWizardUrlOptions} from '../types/TWizardUrlOptions';

export default function useWizardUrlOptions(): TWizardUrlOptions {
    let urlOptions: TWizardUrlOptions;
    const [searchParams] = useSearchParams();

    urlOptions = {
        room: searchParams.get('room'),
        project: searchParams.get('project'),
        collection: searchParams.get('collection'),
        corpus: searchParams.get('corpus'),
        bottomFacade: searchParams.get('b_facade'),
        topFacade: searchParams.get('t_facade'),
        screen: searchParams.get('screen') || undefined,
        unit: searchParams.get('unit') || undefined,
    };

    return urlOptions;
}