import {CommonObject} from '../../objects/CommonObject/CommonObject';
import {TInterval} from '../../types/TInterval';
import {Vector3} from 'three';
import {ISavePointData} from '../../../../common-code/interfaces/saveData/ISavePointData';
import {IThreeService} from '../../interfaces/IThreeService';

export class Point extends CommonObject {

    constructor(pointData: ISavePointData, roomService: IThreeService) {
        super(pointData, roomService);
        this.id = pointData.id;
    }

    public initState() {
        this.setClassToObject();
    }

    public calculateGlobalFrontVector() {
        throw new Error('error-Point-calculateGlobalFrontVector');
    }

    public getGlobalFrontLine(): TInterval {
        throw new Error('error-Point-getGlobalFrontLine');
    }

    public getGlobalFrontVector(): Vector3 {
        throw new Error('error-Point-getGlobalFrontVector')
    }
}