import React, {MutableRefObject, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {cnRoomParams} from '../RoomParams';
import {ICreateObjectDomElements} from '../../../../interfaces/ICreateObjectDomElements';
import {ICreateGroup} from '../../../../../common-code/interfaces/createData/ICreateGroup';
import {ICreateObjectData} from '../../../../../common-code/interfaces/createData/ICreateObjectData';
import {CreateCommonObject} from '../../CreateCommonObject/CreateCommonObject';
import {cnModal, Modal} from '../../../components/Modal';
import {CreateObjectOptions} from '../../CreateObjectOptions/CreateObjectOptions';

export interface IRoomParamsConstructiveListProps extends IClassNameProps {
    service: KitchenService;
    constructiveList: ICreateGroup[];
    createObjectDomElements: MutableRefObject<ICreateObjectDomElements>;
}

export const RoomParamsConstructiveList: React.FC<IRoomParamsConstructiveListProps>
    = ({
           service,
           className,
           constructiveList,
           createObjectDomElements
       }) => {
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [objectData, setObjectData] = useState<ICreateObjectData | undefined>(undefined);
    const [searchText] = useState<string>('');

    const onCreateConstructive = (objectData: ICreateObjectData) => {
        setObjectData(objectData);
        setShowCreateModal(true);
    }
    const onCloseModal = (visible: boolean) => {
        setObjectData(undefined);
        setShowCreateModal(visible);
    }

    return (
        <div className={cnRoomParams('ConstructiveList', [className])}>
            <div className={cnRoomParams('ConstructiveListContent')}>
                <div className={cnRoomParams('ConstructiveLeftList')}>
                    {
                        constructiveList.map((constructiveData) => {
                            return (constructiveData.items.map(
                                (constructiveItem) => {
                                return (
                                    <CreateCommonObject
                                        key={constructiveItem.uid}
                                        className={cnRoomParams('Constructive')}
                                        objectData={constructiveItem}
                                        createAction={() => {
                                            onCreateConstructive(constructiveItem)
                                        }}
                                        service={service}
                                        createObjects={createObjectDomElements}
                                        searchText={searchText}
                                    />
                                );
                            }));
                        })
                    }
                </div>
            </div>
            {showCreateModal && objectData ?
                <Modal
                    className={cnRoomParams(cnModal())}
                    visible={showCreateModal}
                    callBack={onCloseModal}
                >
                    <CreateObjectOptions
                        objectData={objectData}
                        service={service}
                        onClose={onCloseModal}
                        width={null}
                        corpusWidth={null}
                    />
                </Modal> : null
            }
        </div>
    );
};