import React, { useEffect, useRef, useState } from "react";
import { cn } from "@bem-react/classname";
import { IClassNameProps } from "@bem-react/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAppConfig } from "../../../../common-code/interfaces/config/IAppConfig";
import { AppState } from "../../../redux/AppStore";
import { IUserData } from "../../../../common-code/interfaces/IUserData";
import { KitchenEditor } from "../../../3d/editors/KitchenEditor/KitchenEditor";
import { KitchenUserControls } from "../../../3d/userControls/KitchenUserControls/KitchenUserControls";
import { KitchenService } from "../../../3d/services/KitchenService/KitchenService";
import { IWizardOptions } from "../../../interfaces/IWizardOptions";
import { TWizardUrlOptions } from "../../../types/TWizardUrlOptions";
import useWizardUrlOptions from "../../../hooks/useWizardUrlOptions";
import { IProjectData } from "../../../../common-code/interfaces/project/IProjectData";
import { ISaveRoomData } from "../../../../common-code/interfaces/saveData/ISaveRoomData";
import { ICreateObjectDomElements } from "../../../interfaces/ICreateObjectDomElements";
import { useEffectDidMount } from "../../../hooks/useEffectDidMount";
import { EditorService } from "../../../3d/services/EditorService/EditorService";
import {
  CHANGE_PROJECT_DATA,
  CHANGE_ROOM_DATA,
  EDITOR_INIT_OPTIONS,
} from "../../../constants";
import { IEditorOptions } from "../../../interfaces/IEditorOptions";
import { WizardEditCurrent } from "./CurrentStep/WizardEditCurrent";
import { CommonHelper } from "common-code";
import { cnHeader, Header } from "../../layouts/Header";
import { cnPageWizard } from "../../../pages/PageWizard/PageWizard";
import { IClientSessionData } from "../../../../common-code/interfaces/session/IClientSessionData";
import "./WizardEdit.css";

export const cnWizardEdit = cn("WizardEdit");

export const WizardEdit: React.FC<IClassNameProps> = ({ className }) => {
  const { t } = useTranslation();
  const [threeKitchenEditor, initThreeKitchenEditor] = useState<
    KitchenEditor | undefined
  >();
  const [userControls, initUserControls] = useState<
    KitchenUserControls | undefined
  >();
  const [kitchenService, initKitchenService] = useState<
    KitchenService | undefined
  >();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<HTMLDivElement>(null);
  const appConfig: IAppConfig = useSelector((state: AppState) => state.config);
  const user: IUserData = useSelector((state: AppState) => state.user);
  const kitchenOptions: IWizardOptions = useSelector(
    (state: AppState) => state.wizard.options
  );
  const [hasWebGLError, setHasWebGLError] = useState(false);
  const urlOptions: TWizardUrlOptions = useWizardUrlOptions();
  const projectData: IProjectData | undefined = useSelector(
    (state: AppState) => state.wizard.project
  );
  const roomData: ISaveRoomData | undefined = useSelector(
    (state: AppState) => state.wizard.room
  );
  const [loadError, setLoadError] = useState<boolean>(false);
  const createObjects = useRef<ICreateObjectDomElements>({});
  const session: IClientSessionData | null = useSelector(
    (state: AppState) => state.session
  );
  // флаг инициализации init kitchen service
  const [isInitState, setIsInitState] = useState<boolean>(false);

  useEffect(() => {
    if (
      kitchenService &&
      kitchenService.isReady() &&
      editorRef.current instanceof HTMLDivElement &&
      containerRef.current instanceof HTMLDivElement
    ) {
      kitchenService.resizeCanvas();
    }
  }, [kitchenService]);

  useEffectDidMount(() => {
    const loadProjectData = (
      appConfig: IAppConfig,
      user: IUserData,
      collectionId: string | null
    ): Promise<IProjectData> => {
      return new Promise<IProjectData>((resolve, reject) => {
        if (!collectionId) {
          reject();
        } else {
          resolve(
            EditorService.getDefaultProjectData(appConfig, user, collectionId)
          );
        }
      });
    };
    const loadRoomData = (): Promise<ISaveRoomData> => {
      return new Promise<ISaveRoomData>((resolve, reject) => {
        resolve(EditorService.getDefaultRoomData());
      });
    };

    loadProjectData(appConfig, user, urlOptions.collection)
      .then((projectData: IProjectData) => {
        loadRoomData().then((roomData) => {
          dispatch({
            type: CHANGE_PROJECT_DATA,
            payload: projectData,
          });
          dispatch({
            type: CHANGE_ROOM_DATA,
            payload: roomData,
          });
        });
      })
      .catch(() => {
        setLoadError(true);
      });
  });

  useEffect(() => {
    if (!threeKitchenEditor) {
      let initOptions: IEditorOptions = { ...EDITOR_INIT_OPTIONS };
      if (window.location.hostname.indexOf("localhost") !== -1) {
        initOptions.stats = true;
      }
      initThreeKitchenEditor(new KitchenEditor(window, dispatch, initOptions));
    }
  }, [threeKitchenEditor, dispatch]);

  useEffect(() => {
    if (threeKitchenEditor && !userControls) {
      initUserControls(new KitchenUserControls(threeKitchenEditor));
    }
  }, [threeKitchenEditor, userControls]);

  useEffect(() => {
    if (
      threeKitchenEditor &&
      userControls &&
      roomData &&
      projectData &&
      session &&
      !kitchenService
    ) {
      if (!projectData.collection) {
        setLoadError(true);
      } else {
        initKitchenService(
          new KitchenService(
            threeKitchenEditor,
            userControls,
            dispatch,
            urlOptions,
            CommonHelper.deepCopy(kitchenOptions),
            CommonHelper.deepCopy(appConfig),
            CommonHelper.deepCopy(projectData),
            session
          )
        );
      }
    }
  }, [
    threeKitchenEditor,
    userControls,
    appConfig,
    kitchenService,
    dispatch,
    urlOptions,
    kitchenOptions,
    roomData,
    projectData,
    session,
  ]);

  // init kitchen service
  useEffect(() => {
    try {
      if (
        kitchenService &&
        !kitchenService.isReady() &&
        roomData &&
        editorRef.current instanceof HTMLDivElement &&
        containerRef.current instanceof HTMLDivElement
      ) {
        kitchenService.initStateEDIT(editorRef.current, roomData).then(() => {
          setIsInitState(true);
        });
      }
    } catch (e) {
      setHasWebGLError(true);
    }
    return () => {
      if (kitchenService) {
        kitchenService.remove();
        dispatch({
          type: CHANGE_PROJECT_DATA,
          payload: undefined,
        });
        dispatch({
          type: CHANGE_ROOM_DATA,
          payload: undefined,
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, kitchenService]);

  if (!projectData || !roomData || loadError) {
    return (
      <div className={cnWizardEdit({}, [className])}>
        <div className={cnWizardEdit("LoadingContainer")}>
          <div className={cnWizardEdit("Loading", { error: loadError })}>
            {loadError
              ? t("Ошибка загрузки проекта!")
              : t("Загружаем проект") + "..."}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header
        className={cnPageWizard(cnHeader())}
        title={t("Редактирование коллекции кухонных гарнитуров") || undefined}
        size={"small"}
        service={kitchenService}
      />
      <div className={cnWizardEdit({}, [className])} ref={containerRef}>
        <div className={cnWizardEdit("Editor")} ref={editorRef}>
          {hasWebGLError && t("При инициализации WebGL произошла ошибка")}
        </div>
        <div className={cnWizardEdit("UI")}>
          {kitchenService && containerRef.current instanceof HTMLDivElement && (
            <WizardEditCurrent
              service={kitchenService}
              createObjects={createObjects}
              isInitState={isInitState}
            />
          )}
        </div>
      </div>
    </>
  );
};
