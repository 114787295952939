import React, { useEffect } from 'react'
import { Button, cnButton } from '../../../components/Button';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/AppStore';
import { IMaterialData } from 'common-code/lib/interfaces/materials/IMaterialData';
import { cn } from '@bem-react/classname';
import { useTranslation } from 'react-i18next';
import { MaterialItem } from './MaterialItem/MaterialItem';
import { useDispatch } from 'react-redux';
import { CHANGE_EDIT_MODE, CHANGE_MATERIALS_MODE, INIT_EDIT_MATERIAL } from '../../../../constants';
import { TEditMode } from '../../../../../common-code/types/TEditMode';
import { TMaterialEditMode } from '../../../../../../../common/types/TMaterialEditMode';
import './MaterialsList.css';

export const cnEditMaterialsList = cn('EditMaterialsList');

interface IMaterialsListProps {
    materialGroup: [type:TMaterialEditMode, title: string],
    saveMaterial: (materialListNew: IMaterialData[], type: TMaterialEditMode) => void
    handleEditMaterial: (material: IMaterialData, type: TMaterialEditMode) => void
}

function MaterialsList({
        materialGroup,
        saveMaterial,
        handleEditMaterial
    }: IMaterialsListProps) {

    const materialsMode = useSelector(
        (state: AppState) => state.wizard.editMaterial.materialsMode
        );        
    const dispatch = useDispatch();
    const [type, title] = materialGroup;
    const [showContent, setShowContent] = React.useState(materialsMode ===  title);
    const {t} = useTranslation();        

    const closeEditMaterials = () => {
        dispatch({ type: CHANGE_MATERIALS_MODE, payload: "" });
    }

    useEffect(() => {
        setShowContent(materialsMode ===  title);
        // eslint-disable-next-line
    }, [materialsMode]);

    const stateMaterials  = useSelector((state: AppState) => state.wizard[type]);  

    const handleToggleMode = (str: TEditMode) => {
        dispatch({ type: CHANGE_EDIT_MODE, payload: str});
        dispatch({ type: INIT_EDIT_MATERIAL });
      }; 
    
  return (
    <>

    { materialsMode?.length === 0 && 
    <Button
        className={cnEditMaterialsList(cnButton())}
        type={'button'}
        text={title}
        mods={{mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
        hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}}}
        callBack={() => {dispatch({ type: CHANGE_MATERIALS_MODE, payload: title });
        }}
    />}

    { showContent && 
        <div className={cnEditMaterialsList('Container')}>
            <div className={cnEditMaterialsList('Header')}>
                <Button
                    className={cnEditMaterialsList(cnButton(), {type: 'prev'})}
                    leftIcon={{path: 'arrow-left', width: 20, height: 20, marginRight: 8}}
                    text={t('Предыдущий шаг') || undefined}
                    callBack={closeEditMaterials}
                    mods={{
                        mods: {color: 'black',bkg: 'white',border: 'black',fill: 'black' }
                    }}
                    type={'button'}
                />
                <h2 className={cnEditMaterialsList('Title')}>{title}</h2>
                <Button
                    className={cnEditMaterialsList(cnButton())}
                    type={'button'}
                    text={'Добавить'}
                    leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                    mods={{
                        mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                        hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                    }}
                    callBack={() => handleToggleMode(type)}
                />
            </div>

            <div className={cnEditMaterialsList('Content')}>

           
                {stateMaterials?.map((material: IMaterialData, index: number) =>
                        <MaterialItem
                            key={material.id}
                            material={material}
                            index={index}
                            handleEditMaterial={handleEditMaterial}
                            type={type}
                            saveMaterial={saveMaterial}
                        />
                    
                )}
            </div>
            
        </div>
    }

</>
  )
}

export default MaterialsList