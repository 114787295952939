export const DEFAULT_ADD_STEP = 10;
export const DEFAULT_CLEAR_COLOR = 0xffffff;
export const WALL_STICK_LENGTH = 400;
export const BOTTOM_UNIT_TOP_PANEL_WIDTH = 80;
export const BOTTOM_UNIT_TECHNOLOGICAL_HOLE_DEPTH = 22;
export const BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT = 50;
export const BOTTON_UNIT_TECHNOLOGICAL_HOLE_C_HEIGHT = 60;
export const BOTTOM_UNIT_TOP_PANEL_WIDTH_V2 = 100;
export const BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_PANEL_WIDTH = 64;
export const TOP_UNIT_PLANK_WIDTH = 30;
export const TABLETOP_DEFAULT_BEVEL_THICKNESS = 7;
export const TABLETOP_DEFAULT_BEVEL_SIZE = 7;
export const TABLETOP_DEFAULT_BEVEL_SEGMENTS = 10;
// add smoothness to extrude geometry of tabletop
// export const TABLETOP_DEFAULT_BEVEL_SEGMENTS = 100;
export const DEFAULT_APRON_WIDTH = 600;
export const DETAIL_TABLETOPS = 'tabletops';
export const DETAIL_APRONS = 'aprons';
export const DETAIL_CORNERS = 'corners';
export const DETAIL_PLINTHS = 'plinths';
export const DETAIL_INTEGRATED_HANDLES = 'integratedHandles';
export const DETAIL_SIZES = 'sizes';
export const FACADE_DEFAULT_BEVEL_SIZE = 5;
export const FACADE_DEFAULT_BEVEL_THICKNESS = 5;
export const FACADE_DEFAULT_BEVEL_SEGMENTS = 1;
export const SCREEN_TYPE_HORIZONTAL = 'horizontal';
export const SCREEN_TYPE_VERTICAL = 'vertical';
export const ARROW_TYPE_UP = 'up';
export const ARROW_TYPE_DOWN = 'down';
export const ARROW_TYPE_FORWARD = 'forward';
export const ARROW_TYPE_BACK = 'back';
export const ARROW_TYPE_LEFT = 'left';
export const ARROW_TYPE_RIGHT = 'right';
export const ARROW_GROUP_VERTICAL = 'vertical';
export const ARROW_GROUP_LEFT_RIGHT = 'leftRight';
export const ARROW_GROUP_FORWARD_BACK = 'forwardBack';
export const IS_VERTICAL_MOVE = 'isVerticalMove';
export const IS_LEFT_RIGHT_MOVE = 'isLeftRightMove';
export const IS_FORWARD_BACK_MOVE = 'isForwardBackMove';
export const DEFAULT_WINDOW_BOTTOM_GAP = 900;
export const WINERY_UNIT_SHELF_DEEPENING = 8;
export const WASHING_MIN_WIDTH = 400;// Минимальная ширина стола под мойку
export const DETAIL_PRICE_RESULT_OK = 'ok';
export const DETAIL_PRICE_RESULT_ERROR = 'error';
export const DETAIL_PRICE_RESULT_WASTE_PIECES = 'wastePieces';

export const NEIGHBOR_GAP = 3;
export const MIN_NEIGHBOR_INTERVAL = 10;
export const DEFAULT_HORIZONTAL_LINE_STICK = 98;
export const DEFAULT_VERTICAL_LINE_STICK = 50;
/**
 * округление проверки близости объекта или точки к другому объекту или точки
 */
export const DEFAULT_NEAR_ROUNDING = 3;

export const COVER_CORRECTION_SIZE = 1;

export const SIZE_DEFAULT_LINE_INDENT = 80;
export const SIZE_DEFAULT_LINE_HEIGHT = 120;
export const SIZE_DEFAULT_TEXT_INDENT = 10;
export const SIZE_DEFAULT_TEXT_SIZE = 80;

export const UNIT_SIZE_TEXT_SIZE = 45;
export const ROOM_POINT_TYPE_3D = '3d';
export const ROOM_POINT_TYPE_2D = '2d';

export const SCREEN_WIDTH = 800;
export const SCREEN_HEIGHT = 800;

export const SELECT_COLOR_ERROR = '#fd9393';

export const RENDERER_TYPE_CUTTING = 'cutting';

export const HISTORY_STATE_TYPE_INIT = 'init';
export const HISTORY_STATE_TYPE_MOVE = 'move';
export const HISTORY_STATE_TYPE_DELETE = 'delete';
export const HISTORY_STATE_TYPE_CHANGE = 'change';
export const HISTORY_STATE_TYPE_CREATE = 'create';
export const HISTORY_STATE_TYPE_ROOM = 'room';
export const HISTORY_STATE_TYPE_EURO_ZAPIL = 'euroZapil';
export const HISTORY_STATE_TYPE_ACCESSORY = 'accessory';
export const HISTORY_STATE_TYPE_SERVICE = 'service';
export const HISTORY_STATE_TYPE_FACADE = 'facade';
export const HISTORY_STATE_TYPE_HANDLE = 'handle';

export const OFFLINE_TEXTURE = '/offline/texture/dummy.jpg';
export const OFFLINE_HANDLE = '/offline/handle/dummy.glb';
export const OFFLINE_EQUIPMENT = '/offline/equipment/dummy.glb';
export const OFFLINE_CONSTRUCTIVE = '/offline/constructive/dummy.glb';
export const OFFLINE_FACADE = '/offline/facade/dummy.glb';

export const HOB_MIN_WIDTH = 150;
export const MAX_CACHE_POOL_SIZE = 20;

export const AVAILABLE_CANVAS_CURSORS = ['ns-resize', 'ew-resize', 'default', 'copy', 'progress', 'wait', 'move'];
