import React, {ChangeEvent, useEffect, useState} from 'react';
import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import {useTranslation} from 'react-i18next';
import {cnInput} from '../../components/Input';
import {INPUT_TYPE_TEXT, LINK_TYPE_DEFAULT} from '../../constants';
import {Input} from '../../components/Input/Input';
import {Button, cnButton} from '../../components/Button';
import axios from 'axios';
import './SmsConfirm.css';
import {ISendResponse} from '../../../interfaces/ISendResponse';
import {SEND_SMS_TIMEOUT_SECONDS} from '../../../constants';
import {Link} from '../../components/Link/Link';
import {TSmsConfirmResult} from '../../../../common-code/types/TSmsConfirmResult';

export const cnSmsConfirm = cn('SmsConfirm');

export interface ISmsConfirmProps extends IClassNameProps {
    phone: string;
    successCallBack: (data: TSmsConfirmResult) => void;
    errorCallBack: () => void;
}

export const SmsConfirm: React.FC<ISmsConfirmProps> = ({className, successCallBack, errorCallBack, phone}) => {
    const {t} = useTranslation();
    const [smsCode, setSmsCode] = useState<string>('');
    const [sendingSMS, setSendingSMS] = useState(true);
    const [timerSeconds, setTimerSeconds] = useState<number>(120);
    const [errors, setErrors] = useState<any>({});

    const sendSmsCode = () => {
        if (!smsCode) {
            return;
        }
        axios.post('/auth/check-sms', {phone: phone, code: smsCode}).then((response) => {
            let result: TSmsConfirmResult;

            if (response.data) {
                result = {
                    success: response.data.success || false,
                    code: smsCode,
                    phone: phone,
                    userId: response.data.userId ? response.data.userId : undefined,
                    message: response.data.message || t('Произошла неизвестная ошибка'),
                    action: response.data.action || ''
                }
                successCallBack(result);
            } else  {
                errorCallBack();
            }
        }).catch(() => {
            errorCallBack();
        })
    }

    const sendSMSTimerPassword = () => {
        if (sendingSMS) {
            return;
        }
        setSendingSMS(true);
        axios.post<any>('/auth/send-sms', {
            phone: phone
        })
            .then((response) => {
                const responseData: ISendResponse = response.data;
                if (responseData.success) {
                    setTimerSeconds(SEND_SMS_TIMEOUT_SECONDS);
                } else {
                    setErrors({...errors, sendSms:  t('Не удалось отправить СМС, попробуйте снова')});
                }
            })
            .catch(() => {
                setSendingSMS(false);
            });
    };

    useEffect(() => {
        if (timerSeconds > 0) {
            setTimeout(() => setTimerSeconds(timerSeconds - 1), 1000);
        } else {
            setSendingSMS(false);
        }
    }, [timerSeconds]);

    return (
        <div className={cnSmsConfirm({}, [className])}>
            <div className={cnSmsConfirm('Title')}>
                {t('Чтобы подтвердить действие, введите код из СМС')}.
            </div>
            <div className={cnSmsConfirm('InputContainer')}>
                <Input
                    className={cnSmsConfirm(cnInput())}
                    type={INPUT_TYPE_TEXT}
                    value={smsCode}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setSmsCode(event.target.value);
                    }}
                />
                <Button
                    className={cnSmsConfirm(cnButton())}
                    text={t('Подтвердить')}
                    callBack={sendSmsCode}
                    mods={{mods: {color: 'white', bkg: 'lightGreen', border: 'green'}}}
                    type={'button'}
                />
            </div>
            <div className={cnSmsConfirm('SendContainer')}>
                {sendingSMS ||
                    <Link
                        className={cnSmsConfirm('Send')}
                        type={LINK_TYPE_DEFAULT}
                        text={t('Выслать новый код по SMS') || undefined}
                        mods={{mods: {color: 'black'}}}
                        onClick={sendSMSTimerPassword}
                    />
                }
                {timerSeconds <= 0 ||
                    <div className={cnSmsConfirm('Notes')}>
                        {t('Новый код вы можете сгенерировать через')}
                        <span className={cnSmsConfirm('Seconds')}>{timerSeconds} {t('сек.')}</span>
                    </div>
                }
            </div>
        </div>
    );
    }