import { IOptionGroup } from "common-code/lib/interfaces/option/IOptionGroup";
import { IOptionHiddenText } from "../../../../../../../common-code/interfaces/option/IOptionHiddenText";
import { IOptionHiddenNumber } from "../../../../../../../common-code/interfaces/option/IOptionHiddenNumber";
import {
  OPTION_TYPE_HIDDEN_NUMBER,
  OPTION_TYPE_HIDDEN_TEXT,
  OPTION_TYPE_JSON,
} from "common-code/lib/constants";
import { IOptionHiddenJson } from "../../../../../../../common-code/interfaces/option/IOptionHiddenJson";
import { additionalCorpusOptions } from "./Corpus";
import { IFormInput } from "../../initialValue";
import { IOption } from "common-code/lib/interfaces/option/IOption";

export function getCorpus(data: IFormInput): IOptionGroup {
  const {
    corpusCatalogCode,
    depth,
    height,
    width,
    thickness,
    backThickness,
    boxes,
    shelves,
  } = data;

  const corpusValues: IOptionGroup["options"] = [
    {
      id: "catalogCode",
      value: corpusCatalogCode,
      type: OPTION_TYPE_HIDDEN_TEXT,
      sort: 0,
      title: "Код каталога",
    } as IOptionHiddenText,
    width as IOption,
    {
      id: "height",
      value: Number(height),
      sort: 1,
      title: "Высота корпуса",
      type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber,
    {
      id: "depth",
      value: Number(depth),
      sort: 2,
      title: "Глубина корпуса",
      type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber,
    {
      id: "thickness",
      value: Number(thickness),
      sort: 3,
      title: "Толщина стенки",
      type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber,
    {
      id: "backThickness",
      value: Number(backThickness),
      sort: 4,
      title: "Толщина задней стенки",
      type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber,

    shelves as IOption,
    boxes as IOption,
  ];

  const corpusOptions = () => {
    const {
      className,
      smallWidth,
      smallDepth,
      sidePanel,
      sideDepth,
      backSideDepth,
      frameWidth,
      frameDepth,
      rearPlinthWidth,
      rearPlinthHeight,
      rearPlinthDepth,
      tsargaWidth,
      tsargaHeight,
      tsargaDepth,
      railWidth,
      railHeight,
      railDepth,
      frontPanelLength,
      verticalPanelLength,
      verticalPanelGap,
      isFrontPanel,
      isVerticalPanel,
      techHoleCPositionY,
      techHoleLPositionY
    } = data;
    const classNameValue = className?.value;
    // дополнительные параметры корпуса

    const smallWidthOption = {
      id: "smallWidth",
      value: Number(smallWidth),
      sort: 2,
      title: "Ширина стороны",
      type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber;

    const smallDepthOption = {
      id: "smallDepth",
      value: Number(smallDepth),
      sort: 2,
      title: "Глубина стороны",
      type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber;

    const sidePanelOption = {
      id: "sidePanel",
      value: Number(sidePanel),
      sort: 2,
      title: "Ширина боковой панели",
      type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber;

    const sideDepthOption = {
      id: "sideDepth",
      value: Number(sideDepth),
      sort: 2,
      title: "Глубина боковой панели",
      type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber;

    const backSideDepthOption = {
      id: "backSideDepth",
      value: Number(backSideDepth),
      sort: 2,
      title: "Ширина задней панели",
      type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber;

    const frameOption = {
      id: "frame",
      title: "Профиль",
      sort: 5,
      type: OPTION_TYPE_JSON,
      value: {
        width: Number(frameWidth),
        depth: Number(frameDepth),
      },
    } as IOptionHiddenJson;

    const rearPlinthOption = {
      id: "rearPlinth",
      title: "Задний профиль",
      sort: 5,
      type: OPTION_TYPE_JSON,
      value: {
        width: Number(rearPlinthWidth),
        height: Number(rearPlinthHeight),
        depth: Number(rearPlinthDepth),
      },
    } as IOptionHiddenJson;

    const tsargaOption = {
      id: "tsarga",
      title: "Царга",
      sort: 5,
      type: OPTION_TYPE_JSON,
      value: {
        width: Number(tsargaWidth),
        height: Number(tsargaHeight),
        depth: Number(tsargaDepth),
      },
    } as IOptionHiddenJson;

    const railOption = {
      id: "rail",
      title: "Ограждение",
      sort: 5,
      type: OPTION_TYPE_JSON,
      value: {
        width: Number(railWidth),
        height: Number(railHeight),
        depth: Number(railDepth),
      },
    } as IOptionHiddenJson;

    const frontPanelOption = {
      id: "frontPanel",
      title: "Передняя панель",
      sort: 5,
      type: OPTION_TYPE_JSON,
      value: {
        length: Number(frontPanelLength),
      },
    } as IOptionHiddenJson;

    const verticalPanelOption = {
      id: "verticalPanel",
      title: "Вертикальная панель",
      sort: 5,
      type: OPTION_TYPE_JSON,
      value: {
        length: Number(verticalPanelLength),
        gap: Number(verticalPanelGap)
      },
    } as IOptionHiddenJson;

    const techHolePositionOption = {
      id: "techHole",
      title: "Технологическое отверстие",
      sort: 5,
      type: OPTION_TYPE_JSON,
      value: {
        positionC: Number(techHoleCPositionY) ? Number(techHoleCPositionY): 0,
        positionL: Number(techHoleLPositionY) ? Number(techHoleLPositionY): 0,
      },
    } as IOptionHiddenJson;

    const {isEndUnit, isAngleTwoFacades, isAngleCube, isTopUnitAngleDouble, isTopUnitRackFrame, isUnitAngleNormal,isBottomAngleSink, isPenalUnitSingleHandle, isBottomUnitDoubleHandle} = additionalCorpusOptions(classNameValue);

    if (isEndUnit) return [...corpusValues, smallWidthOption, smallDepthOption]; // для секций ончаний
    else if (isAngleTwoFacades) return [...corpusValues, sidePanelOption]; // для секции угловых двухфасадных
    else if (isAngleCube) return [
        ...corpusValues,
        sideDepthOption,
        backSideDepthOption,
      ]; // для секции угловых кубических
    else if (isTopUnitAngleDouble) return [
        ...corpusValues,
        sideDepthOption,
      ]; // для секции верхних угловых двухфасадных
    else if (isTopUnitRackFrame) return [...corpusValues, frameOption]; // для секции верхних стелажей
    else if (isBottomAngleSink) return [
        ...corpusValues,
        rearPlinthOption,
        tsargaOption,
        railOption,
        sideDepthOption,
      ]; // для нижней угловой прямоугольной секции с раковиной
    else if (isUnitAngleNormal && isVerticalPanel && isFrontPanel) return [...corpusValues, frontPanelOption, verticalPanelOption]; // для верхних угловых прямых шкафов
    else if (isUnitAngleNormal && isFrontPanel) return [...corpusValues, frontPanelOption];// для верхних угловых прямых шкафов
    else if (isUnitAngleNormal && isVerticalPanel) return [...corpusValues, verticalPanelOption]; // для верхних угловых прямых шкафов
 
    else if (isPenalUnitSingleHandle || isBottomUnitDoubleHandle) return [...corpusValues, techHolePositionOption]; // для пеналов с интегрированной ручкой

    return [...corpusValues];
  };

  const corpus: IOptionGroup = {
    id: "corpus",
    sort: 0,
    title: "Корпус",
    isGroup: true,
    options: corpusOptions().filter((el) => el),
  };

  return corpus;
}
