export const LINK_TARGET_BLANK = '_blank';
export const LINK_TARGET_SELF = '_self';
export const LINK_TYPE_DEFAULT = 'default';
export const LINK_TYPE_NAV = 'nav';
export const MENU_TYPE_MAIN_MENU = 'MainMenu';
export const MENU_TYPE_USER_MENU = 'UserMenu';
export const HEADER_PHONE = '8-800-511-07-33';
export const WHATSAPP_PHONE = '79143317552';
export const INPUT_TYPE_PHONE = 'phone';
export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_TEXTAREA = 'textarea';
export const INPUT_TYPE_PASSWORD = 'password';
export const HEADER_TYPE_SMALL = 'small';
export const HEADER_TYPE_MIDDLE = 'middle';
export const HEADER_TYPE_BIG = 'big';
