import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {cnFacadeMaterials} from '../index';
import {useTranslation} from 'react-i18next';
import {Button} from '../../../components/Button';

export interface IFacadeMaterialsChangeGroupProps extends IClassNameProps {
    active: boolean;
    openFacades: () => void;
}

export const FacadeMaterialsChangeGroup: React.FC<IFacadeMaterialsChangeGroupProps> =
    ({className, openFacades, active}) => {
    const {t} = useTranslation();

    const onChangeGroup = () => {
        openFacades();
    }

    if (!active) {
        return null;
    }

    return (
        <div className={cnFacadeMaterials('ChangeGroup', [className])}>
            <Button
                className={cnFacadeMaterials('ChangeGroupButton')}
                type={'button'}
                leftIcon={{path: 'arrow-left', width: 16, height: 14, marginRight: 10}}
                mods={{
                    mods: {bkg: 'white', fill: 'lightBlack', color: 'black', border: 'black'},
                }}
                callBack={onChangeGroup}
                text={t('Сменить коллекцию') || undefined}
            />
        </div>
    );
}
