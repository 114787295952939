import {CommonObject} from '../../objects/CommonObject/CommonObject';
import {ICoverMainPoints} from '../../interfaces/ICoverMainPoints';
import {ICoverMainPoints2D} from '../../interfaces/ICoverMainPoints2D';
import {TNeighborSides} from '../../types/TNeighborSides';
import {
    AXIS_X, AXIS_Z, FILTER_TYPE_SELECT, FILTER_TYPE_TEXT, OPTION_TYPE_HIDDEN_TEXT,
    OPTION_TYPE_RADIOBUTTON,
    OPTION_TYPE_SELECT, OPTION_TYPE_TEXT,
    SIDE_TYPE_BACK,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_FRONT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT,
    SIDE_TYPE_TOP,
    UNIT_PLINTHS_TYPE_BACK_NEIGHBOR,
    UNIT_PLINTHS_TYPE_DEFAULT
} from '../../../../common-code/constants';
import {Sphere} from 'three/src/math/Sphere';
import {TAxis2DType} from '../../../../common-code/types/TAxis2DType';
import {TPoint2D} from '../../../../common-code/types/TPoint2D';
import {Euler, Path, Quaternion, Vector2, Vector3} from 'three';
import {TDetail} from '../../types/TDetail';
import {TPlinthsType} from '../../../../common-code/types/TPlinthsType';
import {ThreeUnit} from '../../objects/threeD/ThreeUnit/ThreeUnit';
import {MIN_NEIGHBOR_INTERVAL, NEIGHBOR_GAP} from '../../constants';
import {TLine} from '../../../../common-code/types/TLine';
import {TSideType} from '../../../../common-code/types/TSideType';
import {MathHelper} from 'common-code';
import {IOption} from '../../../../common-code/interfaces/option/IOption';
import {IOptionSelect} from '../../../../common-code/interfaces/option/IOptionSelect';
import {IOptionRadioButton} from '../../../../common-code/interfaces/option/IOptionRadioButton';
import {ThreeWall} from '../../objects/threeD/rooms/ThreeWall/ThreeWall';
import {i18n} from '../../../i18n';
import {ICoverMainPoints3D} from '../../interfaces/ICoverMainPoints3D';
import {TLine3D} from '../../../../common-code/types/TLine3D';
import {IGlobalSidePoints} from '../../interfaces/IGlobalSidePoints';
import {TSelectItem} from '../../../../common-code/types/TSelectItem';
import {ICreateObjectData} from '../../../../common-code/interfaces/createData/ICreateObjectData';
import {ICreateObjectDataWidths} from '../../../../common-code/interfaces/createData/ICreateObjectDataWidths';
import {DataManager} from '../../services/KitchenService/managers/DataManager';
import {IProjectFilterItem} from '../../../../common-code/interfaces/project/IProjectFilterItem';
import {IOptionText} from '../../../../common-code/interfaces/option/IOptionText';
import {IProjectFilterData} from '../../../../common-code/interfaces/project/IProjectFilterData';
import {IImportOffer} from '../../../../common-code/interfaces/api/IImportOffer';
import {TCommonInterval} from '../../../../common-code/types/TCommonInterval';
import {TPoint3D} from '../../../../common-code/types/TPoint3D';
import {ThreeKUnitDetail} from '../../objects/threeD/details/ThreeKUnitDetail/ThreeKUnitDetail';

export class CommonObjectHelper {

    public static getParallelNeighborSides(
        object1: CommonObject,
        object2: CommonObject,
        gap: number = NEIGHBOR_GAP
    ): TNeighborSides | undefined {
        let points1: ICoverMainPoints3D;
        let points2: ICoverMainPoints3D;
        let side1: TSideType;
        let side2: TSideType;
        let sides: TSideType[] = [SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT, SIDE_TYPE_FRONT, SIDE_TYPE_BACK];
        let side1Line: TLine3D;
        let side2Line: TLine3D;
        let projectPointA;
        let projectPointB;

        if (object1.getId() === object2.getId()) {
            return undefined;
        }
        points1 = this.convertMainPointsTo3D(object1.getGlobalMainPoints());
        points2 = this.convertMainPointsTo3D(object2.getGlobalMainPoints());
        for (side1 of sides) {
            for (side2 of sides) {
                side1Line = points1[side1];
                side2Line = points2[side2];
                if (MathHelper.intersectLineCirclePoints(
                        MathHelper.line3DtoLine2D(side1Line, AXIS_X, AXIS_Z),
                        MathHelper.point3Dto2D(side2Line.pointA, AXIS_X, AXIS_Z),
                        gap
                    ).length > 0 &&
                    MathHelper.intersectLineCirclePoints(
                        MathHelper.line3DtoLine2D(side1Line, AXIS_X, AXIS_Z),
                        MathHelper.point3Dto2D(side2Line.pointB, AXIS_X, AXIS_Z),
                        gap
                    ).length > 0 &&
                    MathHelper.isParallelLines(
                        MathHelper.line3DtoLine2D(side1Line, AXIS_X, AXIS_Z),
                        MathHelper.line3DtoLine2D(side2Line, AXIS_X, AXIS_Z)
                    )
                ) {
                    projectPointA = MathHelper.projectionPoint2D(
                        MathHelper.point3Dto2D(side1Line.pointA, AXIS_X, AXIS_Z),
                        MathHelper.point3Dto2D(side2Line.pointA, AXIS_X, AXIS_Z),
                        MathHelper.point3Dto2D(side2Line.pointB, AXIS_X, AXIS_Z)
                    );
                    projectPointB = MathHelper.projectionPoint2D(
                        MathHelper.point3Dto2D(side1Line.pointB, AXIS_X, AXIS_Z),
                        MathHelper.point3Dto2D(side2Line.pointA, AXIS_X, AXIS_Z),
                        MathHelper.point3Dto2D(side2Line.pointB, AXIS_X, AXIS_Z)
                    );
                    if (MathHelper.isPointInLine(
                            projectPointA,
                            MathHelper.line3DtoLine2D(side2Line, AXIS_X, AXIS_Z),
                            true) ||
                        MathHelper.isPointInLine(
                            projectPointB,
                            MathHelper.line3DtoLine2D(side2Line, AXIS_X, AXIS_Z),
                            true)) {
                        return {
                            mainLine: side1Line,
                            neighborLine: side2Line,
                            interval: MathHelper.getCommonInterval(side1Line, side2Line, 0),
                            mainSide: (side1 as TSideType),       // сторона модуля1, где сосед модуль2
                            neighborSide: (side2 as TSideType)      // сторона модуля2, где сосед модуль1
                        };

                    }
                }
            }
        }

        return undefined;
    }

    public static checkHorizontalNeighborRotation(line1: TLine3D, line2: TLine3D): boolean {
        let line2D1: TLine;
        let line2D2: TLine;

        line2D1 = MathHelper.line3DtoLine2D(line1, AXIS_X, AXIS_Z);
        line2D2 = MathHelper.line3DtoLine2D(line2, AXIS_X, AXIS_Z);

        return !(MathHelper.isEqualPoints2D(line2D1.pointA, line2D1.pointB) ||
            MathHelper.isEqualPoints2D(line2D2.pointA, line2D2.pointB));

    }

    public static getContourNeighborSides(
        detail1: ThreeKUnitDetail,
        detail2: ThreeKUnitDetail,
        gap: number = NEIGHBOR_GAP
    ): TNeighborSides[] | undefined {
        let points1: IGlobalSidePoints, points2: IGlobalSidePoints;
        let neighborSides: TNeighborSides[] = [];
        let sides: TSideType[] = [SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT, SIDE_TYPE_FRONT, SIDE_TYPE_BACK];
        let side1: TSideType, side2: TSideType;
        let side1Line: TLine3D, side2Line: TLine3D;
        let projectPoint: TPoint3D | undefined;
        let commonInterval: TCommonInterval | undefined;

        if (detail1.getId() === detail2.getId()) {
            return undefined;
        }
        if (!this.checkNearBySphere(detail1, detail1)) {
            return undefined;
        }
        points1 = detail1.getGlobalSidePoints(detail1.selectCover);
        points2 = detail2.getGlobalSidePoints(detail2.selectCover);
        for (side1 of sides) {
            for (side2 of sides) {
                for (side1Line of points1[side1]) {
                    for (side2Line of points2[side2]) {
                        projectPoint = MathHelper.projectionPoint3D(side1Line.pointA, side2Line.pointA, side2Line.pointB);
                        if (!projectPoint) {
                            continue;
                        }
                        if (MathHelper.getLength(side1Line.pointA, projectPoint) > NEIGHBOR_GAP) {
                            continue;
                        }
                        commonInterval = MathHelper.getCommonInterval(side1Line, side2Line, gap);
                        if (commonInterval)
                            if (commonInterval) {
                                neighborSides.push({
                                    mainLine: side1Line,
                                    neighborLine: side2Line,
                                    interval: commonInterval,
                                    mainSide: side1,       // сторона модуля1, где сосед модуль2
                                    neighborSide: side2,      // сторона модуля2, где сосед модуль1
                                });
                            }
                    }
                }
            }
        }

        return neighborSides.length > 0 ? neighborSides : undefined;
    }

    public static getHorizontalNeighborSides(
        object1: CommonObject,
        object2: CommonObject,
        gap: number = NEIGHBOR_GAP,
        checkSelectCover?: boolean
    ): TNeighborSides[] | undefined {
        let points1: IGlobalSidePoints, points2: IGlobalSidePoints;
        let side1: TSideType, side2: TSideType;
        let side1Line: TLine3D, side2Line: TLine3D;
        let sides: TSideType[] = [SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT, SIDE_TYPE_FRONT, SIDE_TYPE_BACK];
        let projectPointA;
        let projectPointB;
        let neighborSides: TNeighborSides[] = [];

        if (object1.getId() === object2.getId()) {
            return undefined;
        }
        if (!this.checkNearBySphere(object1, object2)) {
            return undefined;
        }

        points1 = object1.getGlobalSidePoints(checkSelectCover ? object1.selectCover : object1.cover);
        points2 = object2.getGlobalSidePoints(checkSelectCover ? object2.selectCover : object2.cover);
        for (side1 of sides) {
            for (side2 of sides) {
                for (side1Line of points1[side1]) {
                    for (side2Line of points2[side2]) {
                        if (!this.checkHorizontalNeighborRotation(side1Line, side2Line)) {
                            continue;
                        }
                        if (''+side1 === ''+side2 &&
                            MathHelper.isParallelLines(
                                MathHelper.line3DtoLine2D(side1Line, AXIS_X, AXIS_Z),
                                MathHelper.line3DtoLine2D(side2Line, AXIS_X, AXIS_Z)
                            ) &&
                            object1.getFrontVectorAngle().toFixed(10) === object2.getFrontVectorAngle().toFixed(10)) {
                            continue;
                        }
                        if (MathHelper.intersectLineCirclePoints(
                                MathHelper.line3DtoLine2D(side1Line, AXIS_X, AXIS_Z),
                                MathHelper.point3Dto2D(side2Line.pointA, AXIS_X, AXIS_Z),
                                gap
                            ).length > 0 &&
                            MathHelper.intersectLineCirclePoints(
                                MathHelper.line3DtoLine2D(side1Line, AXIS_X, AXIS_Z),
                                MathHelper.point3Dto2D(side2Line.pointB, AXIS_X, AXIS_Z),
                                gap
                            ).length > 0 &&
                            MathHelper.isParallelLines(
                                MathHelper.line3DtoLine2D(side1Line, AXIS_X, AXIS_Z),
                                MathHelper.line3DtoLine2D(side2Line, AXIS_X, AXIS_Z))
                        ) {
                            projectPointA = MathHelper.projectionPoint2D(
                                MathHelper.point3Dto2D(side1Line.pointA, AXIS_X, AXIS_Z),
                                MathHelper.point3Dto2D(side2Line.pointA, AXIS_X, AXIS_Z),
                                MathHelper.point3Dto2D(side2Line.pointB, AXIS_X, AXIS_Z)
                            );
                            projectPointB = MathHelper.projectionPoint2D(
                                MathHelper.point3Dto2D(side1Line.pointB, AXIS_X, AXIS_Z),
                                MathHelper.point3Dto2D(side2Line.pointA, AXIS_X, AXIS_Z),
                                MathHelper.point3Dto2D(side2Line.pointB, AXIS_X, AXIS_Z)
                            );
                            if (MathHelper.isPointInLine(
                                    projectPointA,
                                    MathHelper.line3DtoLine2D(side2Line, AXIS_X, AXIS_Z),
                                    true) ||
                                MathHelper.isPointInLine(
                                    projectPointB,
                                    MathHelper.line3DtoLine2D(side2Line, AXIS_X, AXIS_Z),
                                    true)) {
                                neighborSides.push({
                                    mainLine: side1Line,
                                    neighborLine: side2Line,
                                    interval: MathHelper.getCommonInterval(side1Line, side2Line, MIN_NEIGHBOR_INTERVAL),
                                    mainSide: (side1 as TSideType),       // сторона модуля1, где сосед модуль2
                                    neighborSide: (side2 as TSideType)      // сторона модуля2, где сосед модуль1
                                });
                            }
                        }
                    }
                }
            }
        }

        return neighborSides.length > 0 ? neighborSides : undefined;
    }

    public static convertMainPointsTo2D(mainPoints: ICoverMainPoints): ICoverMainPoints2D {
        let outPoints: ICoverMainPoints2D;

        outPoints = {
            back: {
                pointA: {x: mainPoints.back.pointA.x, y: mainPoints.back.pointA.z},
                pointB: {x: mainPoints.back.pointB.x, y: mainPoints.back.pointB.z},
            },
            front: {
                pointA: {x: mainPoints.front.pointA.x, y: mainPoints.front.pointA.z},
                pointB: {x: mainPoints.front.pointB.x, y: mainPoints.front.pointB.z},
            },
            top: {
                pointA: {x: mainPoints.top.pointA.x, y: mainPoints.top.pointA.z},
                pointB: {x: mainPoints.top.pointB.x, y: mainPoints.top.pointB.z},
            },
            bottom: {
                pointA: {x: mainPoints.bottom.pointA.x, y: mainPoints.bottom.pointA.z},
                pointB: {x: mainPoints.bottom.pointB.x, y: mainPoints.bottom.pointB.z},
            },
            left: {
                pointA: {x: mainPoints.left.pointA.x, y: mainPoints.left.pointA.z},
                pointB: {x: mainPoints.left.pointB.x, y: mainPoints.left.pointB.z},
            },
            right: {
                pointA: {x: mainPoints.right.pointA.x, y: mainPoints.right.pointA.z},
                pointB: {x: mainPoints.right.pointB.x, y: mainPoints.right.pointB.z},
            },
        };

        return outPoints;
    }

    public static convertMainPointsTo3D(mainPoints: ICoverMainPoints): ICoverMainPoints3D {
        let outPoints: ICoverMainPoints3D;

        outPoints = {
            back: {
                pointA: {x: mainPoints.back.pointA.x, y: mainPoints.back.pointA.y, z: mainPoints.back.pointA.z},
                pointB: {x: mainPoints.back.pointB.x, y: mainPoints.back.pointB.y, z: mainPoints.back.pointB.z},
            },
            front: {
                pointA: {x: mainPoints.front.pointA.x, y: mainPoints.front.pointA.y, z: mainPoints.front.pointA.z},
                pointB: {x: mainPoints.front.pointB.x, y: mainPoints.front.pointB.y, z: mainPoints.front.pointB.z},
            },
            top: {
                pointA: {x: mainPoints.top.pointA.x, y: mainPoints.top.pointA.y, z: mainPoints.top.pointA.z},
                pointB: {x: mainPoints.top.pointB.x, y: mainPoints.top.pointB.y, z: mainPoints.top.pointB.z},
            },
            bottom: {
                pointA: {x: mainPoints.bottom.pointA.x, y: mainPoints.bottom.pointA.y, z: mainPoints.bottom.pointA.z},
                pointB: {x: mainPoints.bottom.pointB.x, y: mainPoints.bottom.pointB.y, z: mainPoints.bottom.pointB.z},
            },
            left: {
                pointA: {x: mainPoints.left.pointA.x, y: mainPoints.left.pointA.y, z: mainPoints.left.pointA.z},
                pointB: {x: mainPoints.left.pointB.x, y: mainPoints.left.pointB.y, z: mainPoints.left.pointB.z},
            },
            right: {
                pointA: {x: mainPoints.right.pointA.x, y: mainPoints.right.pointA.y, z: mainPoints.right.pointA.z},
                pointB: {x: mainPoints.right.pointB.x, y: mainPoints.right.pointB.y, z: mainPoints.right.pointB.z},
            },
        };

        return outPoints;
    }

    public static checkNearBySphere(object1: CommonObject, object2: CommonObject): boolean {
        let globalSphere1: Sphere;
        let globalSphere2: Sphere;
        globalSphere1 = object1.getGlobalSphere();
        globalSphere2 = object2.getGlobalSphere();
        // отсекаем совсем далекие друг от друга объекты
        return globalSphere1.intersectsSphere(globalSphere2);
    }

    public static getVerticalNeighborSides(
        object1: CommonObject,
        object2: CommonObject,
        gap: number = NEIGHBOR_GAP
    ): TNeighborSides[] | undefined {
        let mainPoints1, mainPoints2;
        let points1, points2;
        let side1: TSideType, side2: TSideType;
        let sides: TSideType[] = [SIDE_TYPE_TOP, SIDE_TYPE_BOTTOM];
        let side1Line: TLine3D, side2Line: TLine3D;
        let projectPoint;

        if (object1.getId() === object2.getId()) {
            return undefined;
        }
        if (!this.checkNearBySphere(object1, object2)) {
            return undefined;
        }

        mainPoints1 = object1.getGlobalMainPoints();
        mainPoints2 = object2.getGlobalMainPoints();
        points1 = this.convertMainPointsTo3D(mainPoints1);
        points2 = this.convertMainPointsTo3D(mainPoints2);

        for (side1 of sides) {
            for (side2 of sides) {
                if (side1 === side2) {
                    continue;
                }
                side1Line = points1[side1];
                side2Line = points2[side2];
                if (MathHelper.isParallelLines(
                        MathHelper.line3DtoLine2D(side1Line, AXIS_X, AXIS_Z),
                        MathHelper.line3DtoLine2D(side2Line, AXIS_X, AXIS_Z)
                    ) &&
                    Math.abs(mainPoints1[side1].pointA.y - mainPoints2[side2].pointA.y) <= gap
                ) {
                    if (side1 === 'top') {
                        projectPoint = MathHelper.projectionPoint2D(
                            {
                                x: (side1Line.pointA.x + side1Line.pointB.x)/2,
                                y: (side1Line.pointA.z + side1Line.pointB.z)/2
                            },
                            MathHelper.point3Dto2D(side2Line.pointA, AXIS_X, AXIS_Z),
                            MathHelper.point3Dto2D(side2Line.pointB, AXIS_X, AXIS_Z)
                        );
                        if (MathHelper.isPointInLine(
                            projectPoint,
                            MathHelper.line3DtoLine2D(side2Line, AXIS_X, AXIS_Z),
                            true)) {
                            return [{
                                mainLine: side1Line,
                                neighborLine: side2Line,
                                interval: MathHelper.getCommonInterval(side1Line, side2Line, MIN_NEIGHBOR_INTERVAL),
                                mainSide: (side1 as TSideType),       // сторона модуля1, где сосед модуль2
                                neighborSide: (side2 as TSideType)      // сторона модуля2, где сосед модуль1
                            }];
                        }
                    } else {
                        projectPoint = MathHelper.projectionPoint2D(
                            {x: (side2Line.pointA.x + side2Line.pointB.x)/2,
                                y: (side2Line.pointA.z + side2Line.pointB.z)/2},
                            MathHelper.point3Dto2D(side1Line.pointA, AXIS_X, AXIS_Z),
                            MathHelper.point3Dto2D(side1Line.pointB, AXIS_X, AXIS_Z)
                        );
                        if (MathHelper.isPointInLine(
                            projectPoint,
                            MathHelper.line3DtoLine2D(side1Line, AXIS_X, AXIS_Z),
                            true)) {
                            return [{
                                mainLine: side1Line,
                                neighborLine: side2Line,
                                interval: MathHelper.getCommonInterval(side1Line, side2Line, MIN_NEIGHBOR_INTERVAL),
                                mainSide: (side1 as TSideType),       // сторона модуля1, где сосед модуль2
                                neighborSide: (side2 as TSideType)      // сторона модуля2, где сосед модуль1
                            }];
                        }
                    }
                }
            }
        }

        return undefined;
    }

    public static calculateUnionPosition2DByDetails(details: TDetail[]): TPoint2D {
        // throw new Error('asd');
        let detail: TDetail,
            pointA: TPoint2D, pointB: TPoint2D,
            firstDetail: TDetail, lastDetail: TDetail,
            firstPosition: Vector3, lastPosition: Vector3,
            axe: TAxis2DType, projectPoints: TPoint2D[], projectPoint: TPoint2D,
            globalPoints: TPoint2D[], position2d: TPoint2D;
        let point: TPoint2D;

        if (details.length < 2) {
            throw new Error('error-ThreeApronUnion-calculatePositionByAprons');
        }

        firstDetail = details[0];
        lastDetail = details[details.length - 1];

        if (firstDetail.unit instanceof ThreeWall) {
            firstPosition = firstDetail.getPosition();
        } else {
            firstPosition = firstDetail.getGlobalPosition();
        }
        if (lastDetail.unit instanceof ThreeWall) {
            lastPosition = lastDetail.getPosition();
        } else {
            lastPosition = lastDetail.getGlobalPosition();
        }
        pointA = {x: firstPosition.x, y: firstPosition.z};
        pointB = {x: lastPosition.x, y: lastPosition.z};
        projectPoints = [];
        for (detail of details) {
            globalPoints = detail.getGlobalPoints(detail.selectCover).polygon;
            for (point of globalPoints) {
                projectPoint = MathHelper.projectionPoint2D(
                    point,
                    pointA,
                    pointB
                );
                projectPoints.push(projectPoint);
            }
        }
        axe = Math.abs(pointA.x - pointB.x) < Math.abs(pointA.y - pointB.y)? 'y' : 'x';
        projectPoints.sort(function(a, b) {
            return a[axe] - b[axe];
        });
        position2d = MathHelper.getCenterPoint2D(projectPoints[0], projectPoints[projectPoints.length - 1]);

        return position2d;
    }

    public static calculateUnionRotationByDetails(details: TDetail[]): Euler {
        let firstDetail: TDetail;
        let rotation: Euler;
        let worldQuaternion;

        if (details.length < 2) {
            throw new Error('error-ThreeApronUnion-calculateUnionRotationByDetails');
        }
        firstDetail = details[0];
        firstDetail.view3d.updateMatrixWorld();
        worldQuaternion = new Quaternion();
        firstDetail.view3d.getWorldQuaternion(worldQuaternion);
        rotation = new Euler().setFromQuaternion(worldQuaternion);

        return rotation;
    }

    public static calculatePlinthsType(unit: ThreeUnit): TPlinthsType {
        let type: TPlinthsType;

        if (unit.wall || unit.neighbors.back) {
            type = UNIT_PLINTHS_TYPE_BACK_NEIGHBOR;
        } else {
            type = UNIT_PLINTHS_TYPE_DEFAULT;
        }

        return type;
    }

    public static getOptionItemTitle(item: TSelectItem, option: IOptionSelect | IOptionRadioButton) {
        if (['width', 'length', 'depth', 'height'].includes(option.id)) {
            return item.id + ' ' + i18n.t('мм')
        }

        return item.title;
    }

    public static prepareCreateOption(option: IOption, disableSelectItems?: string[], includeDisabled: boolean = true): IOption {
        switch (option.type) {
            case OPTION_TYPE_SELECT:
                return {
                    ...option,
                    items: (option as IOptionSelect).items.map(item => {
                        if (disableSelectItems && disableSelectItems.includes(item.id)) {
                            return {...item, disabled: true, title: this.getOptionItemTitle(item, (option as IOptionSelect))};
                        }
                        return {...item, title: this.getOptionItemTitle(item, (option as IOptionSelect))};
                    }).filter(item => {
                        return !item.disabled || includeDisabled;
                    })} as IOptionSelect;
            case OPTION_TYPE_RADIOBUTTON:
                return {
                    ...option,
                    items: (option as IOptionRadioButton).items.map(item => {
                        if (disableSelectItems && disableSelectItems.includes(item.id)) {
                            return {...item, disabled: true, title: this.getOptionItemTitle(item, (option as IOptionRadioButton))};
                        }
                        return {...item, title: this.getOptionItemTitle(item, (option as IOptionRadioButton))};
                    }).filter(item => {
                        return !item.disabled || includeDisabled;
                    })} as IOptionRadioButton;
        }

        return option;
    }

    public static prepareFilerOption(filterItem: IProjectFilterItem, filterData: IProjectFilterData[], sort?: number): IOption {
        let filterDataItem: IProjectFilterData | undefined;

        filterDataItem = filterData.filter(filterDataItem => filterDataItem.name === filterItem.name)[0];
        switch (filterItem.type) {
            case FILTER_TYPE_TEXT:
                return {
                    title: filterItem.title,
                    id: filterItem.name,
                    type: OPTION_TYPE_TEXT,
                    sort: sort || 0,
                    value: filterDataItem ? filterDataItem.value as string : undefined,
                } as IOptionText;
            case FILTER_TYPE_SELECT:
                return {
                    title: filterItem.title,
                    id: filterItem.name,
                    type: OPTION_TYPE_SELECT,
                    sort: sort || 0,
                    value: filterDataItem ? filterDataItem.value as string : undefined,
                    items: filterItem.values ? filterItem.values.map((value: {id: string, name: string}) =>
                    {return {id: value.id, title: value.name}}) : []
                } as IOptionSelect;
        }

        return {
            id: filterItem.name,
            type: OPTION_TYPE_HIDDEN_TEXT,
            value: filterDataItem ? filterDataItem.value as string : undefined,
            sort: sort || 0,
            title: filterItem.title,
        } as IOption;
    }

    public static getCreateObjectWidthsText(objectData: ICreateObjectData): string {
        const allWidths: ICreateObjectDataWidths = DataManager.calculateCreateObjectWidths(objectData);
        let widths: number[] = [];
        let widthOption: TSelectItem;
        if (allWidths.widths.length > 0) {
            for (widthOption of allWidths.widths) {
                if (!widthOption.disabled) {
                    widths.push(+widthOption.id);
                }
            }
        } else if (allWidths.corpusWidths.length > 0) {
            for (widthOption of allWidths.corpusWidths) {
                if (!widthOption.disabled) {
                    widths.push(+widthOption.id);
                }
            }
        }
        if (widths.length > 1) {
            return Math.min(...widths) + '-' + Math.max(...widths) + ' ' + i18n.t('мм')
        }
        if (widths.length > 0) {
            return widths[0] + ' ' + i18n.t('мм')
        }

        return objectData.widthText;
    }

    public static searchInOffer = (
        searchText: string,
        offer: IImportOffer
    ): {offer: IImportOffer, rating: number} | undefined => {
        let searchWords: string[];
        let searchWord: string;
        let rating: number = 0;

        searchWords = searchText.split(' ');
        for (searchWord of searchWords) {
            searchWord = searchWord.trim();
            if (offer.name && offer.name.toLowerCase().includes(searchWord.toLowerCase())) {
                rating++;
            }
            if (offer.importName && offer.importName.toLowerCase().includes(searchWord.toLowerCase())) {
                rating++;
            }
            if (offer.vendorCode && offer.vendorCode.toLowerCase().includes(searchWord.toLowerCase())) {
                rating += 10;
            }
            if (offer.materialName && offer.materialName.toLowerCase().includes(searchWord.toLowerCase())) {
                rating++;
            }
            if (offer.width && offer.width.toString() === searchWord) {
                rating++;
            }
            if (offer.height && offer.height.toString() === searchWord) {
                rating++;
            }
            if (offer.depth && offer.depth.toString() === searchWord) {
                rating++;
            }
        }

        return rating > 0 ? {offer: offer, rating: rating} : undefined;
    }

    public static getCirclePoints(
        start: TPoint2D,
        end: TPoint2D,
        center: TPoint2D,
        radius: number,
        clockWise: boolean): TPoint2D[] {
        let points: TPoint2D[] = [];
        let startAngle: number;
        let endAngle: number;

        const path = new Path();
        startAngle = MathHelper.getNormalAngle( new Vector2(start.x - center.x, start.y - center.y) );
        endAngle = MathHelper.getNormalAngle( new Vector2(end.x - center.x, end.y - center.y) );
        path.absarc(center.x, center.y, radius, startAngle, endAngle, clockWise);
        const arrayPath = path.getSpacedPoints(20);

        for (let point of arrayPath) {
            points.push(point);
        }

        return points;
    }
}