import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Shape, Vector3} from 'three';
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import {ThreeShelf} from '../../ThreeShelf/ThreeShelf';
import {
    ThreeBottomUnitEndFullLengthSidewallOpened
} from "../../../units/ThreeBottomUnit/types/ThreeBottomUnitEndFullLengthSidewallOpened";
import {
    ISaveBottomEndFullLengthSidewallOpenedCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomEndFullLengthSidewallOpenedCorpusData';
import {TSizes} from '../../../../../../../common-code/types/geometry/TSizes';
import {ISaveShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfData';
import {CommonHelper} from 'common-code';

export class ThreeBottomEndFullLengthSidewallOpenedCorpus extends ThreeCorpus {
    saveData: ISaveBottomEndFullLengthSidewallOpenedCorpusData;
    unit: ThreeBottomUnitEndFullLengthSidewallOpened;

    constructor(options: ISaveBottomEndFullLengthSidewallOpenedCorpusData, unit: ThreeBottomUnitEndFullLengthSidewallOpened) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public getSmallWidth(): number {
        return +this.saveData.smallWidth;
    }

    public getSmallDepth(): number {
        return +this.saveData.smallDepth;
    }

    protected createShape() {
        this.shape = new Shape();
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_RIGHT:
                this.createRightShape();
                break;
            case SIDE_TYPE_LEFT:
                this.createLeftShape();
        }
    }

    protected createRightShape() {
        this.shape.moveTo(
            -this.getWidth() / 2 + this.getThickness(),
            this.getDepth() / 2
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            -this.getDepth() / 2 + this.getThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2,
            -this.getDepth() / 2 + this.getThickness()
        );
        if (this.getSmallDepth() > 0) {
            this.shape.lineTo(
                this.getWidth() / 2,
                -this.getDepth() / 2 + this.getSmallDepth()
            );
        }
        if (this.getSmallWidth() > 0) {
            this.shape.lineTo(
                -this.getWidth() / 2 + this.getSmallWidth(),
                this.getDepth() / 2
            );
        }
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            this.getDepth() / 2
        );
    }

    protected createLeftShape() {
        this.shape.moveTo(
            -this.getWidth() / 2,
            -this.getDepth() / 2 + this.getThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            this.getDepth() / 2
        );
        if (this.getSmallWidth() > 0) {
            this.shape.lineTo(
                this.getWidth() / 2 - this.getSmallWidth(),
                this.getDepth() / 2
            );
        }
        if (this.getSmallDepth() > 0) {
            this.shape.lineTo(
                -this.getWidth() / 2,
                -this.getDepth() / 2 + this.getSmallDepth()
            );
        }
        this.shape.moveTo(
            -this.getWidth() / 2,
            -this.getDepth() / 2 + this.getThickness()
        );
    }

    protected calculateSideSizes(): {left: TSizes | undefined, right: TSizes | undefined} {
        let sideSizes: {left: TSizes | undefined, right: TSizes | undefined};

        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
            default:
                sideSizes = {
                    left: undefined,
                    right: {
                        length: this.getThickness(),
                        height: this.getHeight(),
                        width: this.getDepth()
                    }
                };
                break;
            case SIDE_TYPE_RIGHT:
                sideSizes = {
                    left: {
                        length: this.getThickness(),
                        height: this.getHeight(),
                        width: this.getDepth()
                    },
                    right: undefined
                };
                break;
        }

        return sideSizes;
    }

    protected createPanels() {
        let sideSizes: {left: TSizes | undefined, right: TSizes | undefined};

        sideSizes = this.calculateSideSizes();
        if (sideSizes.left) {
            this.createPanel(
                new BoxGeometry(sideSizes.left.length, sideSizes.left.height, sideSizes.left.width),
                'left',
                new Vector3(
                    -this.getWidth()/2 + this.getThickness() / 2,
                    this.getHeight()/2 - sideSizes.left.height/2,
                    -this.getDepth()/2 + sideSizes.left.width/2
                )
            );
        }
        if (sideSizes.right) {
            this.createPanel(
                new BoxGeometry(sideSizes.right.length, sideSizes.right.height, sideSizes.right.width),
                'right',
                new Vector3(
                    this.getWidth()/2 - this.getThickness() / 2,
                    this.getHeight()/2 - sideSizes.right.height/2,
                    -this.getDepth()/2 + sideSizes.right.width/2
                )
            );
        }
    }

    protected createShelves() {
        let shelfData: ISaveShelfData;
        let shelf: ThreeShelf;
        for (shelfData of this.saveData.shelves) {
            shelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this, this.shape);
            shelf.initState();
            shelf.createView();
            this.shelves.push(shelf);
        }
    }

    public getHeightBackWall() {
        if (this.saveData.heightBackThickness) {
            return +this.saveData.heightBackThickness;
        }

        return this.getHeight();
    }

    protected createBackPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getHeightBackWall(), this.getThickness()),
            'back',
            new Vector3(
                0,
                (this.getHeight() - this.getHeightBackWall())/2,
                -this.getDepth()/2 + this.getThickness()/2
            )
        );
    }
}