import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {
    ThreeBottomFullLengthSidewallSinkCorpus
} from "../../../details/ThreeCorpus/types/ThreeBottomFullLengthSidewallSinkCorpus";
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnitFullLengthSidewallSink extends ThreeBottomUnit {
    corpus: ThreeBottomFullLengthSidewallSinkCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomFullLengthSidewallSinkCorpus {
        return new ThreeBottomFullLengthSidewallSinkCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}