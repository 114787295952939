import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';
import {BOTTOM_UNIT_TOP_PANEL_WIDTH} from '../../../../../constants';
import {ThreeKUnit} from "../../../units/ThreeKUnit/ThreeKUnit";
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';

export class ThreeBottomFullLengthSidewallCorpus extends ThreeCorpus {
    saveData: ISaveCorpusData;

    constructor(options: ISaveCorpusData, unit: ThreeKUnit) {
        super(options, unit);
        this.saveData = options;
    }

    public getHeightBackWall() {
        if (this.saveData.heightBackThickness) {
            return +this.saveData.heightBackThickness;
        }

        return this.getHeight();
    }

    protected createBackPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getHeightBackWall(), this.getBackThickness()),
            'back',
            new Vector3(
                0,
                (this.getHeight() - this.getHeightBackWall())/2,
                -this.getDepth()/2 + this.getBackThickness()/2
            )
        );
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), BOTTOM_UNIT_TOP_PANEL_WIDTH),
            'top1',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                -this.getDepth()/2 + BOTTOM_UNIT_TOP_PANEL_WIDTH/2 + this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), BOTTOM_UNIT_TOP_PANEL_WIDTH),
            'top2',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                this.getDepth()/2 - BOTTOM_UNIT_TOP_PANEL_WIDTH/2
            )
        );
    }
}