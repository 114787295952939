export const editOption = (obj: Record<string , string>) =>{
    const arr = [];
    for (const [key, value] of Object.entries(obj)) {
      arr.push({value:key, content:value})
    }
    return arr
}

export const editReverseOption = (obj: Record<string , string>) =>{
  const arr = [];
  for (const [key, value] of Object.entries(obj)) {
    arr.push({value:value, content:key})
  }
  return arr
}