import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTopEndOpenedCorpus} from '../../../details/ThreeCorpus/types/ThreeTopEndOpenedCorpus';
import {TDirectionSideType} from '../../../../../../../common-code/types/TDirectionSideType';
import {
    ISaveTopUnitEndOpenedData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitEndOpenedData';
import {
    ISaveTopEndOpenedCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopEndOpenedCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitEndOpened extends ThreeTopUnit {
    saveData: ISaveTopUnitEndOpenedData;
    corpus: ThreeTopEndOpenedCorpus;

    constructor(options: ISaveTopUnitEndOpenedData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isEndUnit(): boolean {
        return true;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    protected initCorpus(corpusData: ISaveTopEndOpenedCorpusData): ThreeTopEndOpenedCorpus {
        return new ThreeTopEndOpenedCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}