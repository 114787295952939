export const ROLE_GUEST = 'guest';
export const ROLE_USER = 'user';
export const ROLE_MANAGER = 'manager';
export const ROLE_ADMIN = 'admin';
export const SORT_NAME = 'name';
export const SORT_PRICE = 'price';
export const SORT_RATE = 'rate';
export const ORDER_ASC = 'asc';
export const ORDER_DESC = 'desc';

export const DEFAULT_LANGUAGE = 'ru';

export const GROUP_BOTTOM_ANGLE_UNITS = 'bottomAngleUnits';
export const GROUP_BOTTOM_NORMAL_UNITS = 'bottomNormalUnits';
export const GROUP_BOTTOM_END_UNITS = 'bottomEndUnits';
export const GROUP_BOTTOM_EQUIPMENTS = 'bottomEquipments';
export const GROUP_TOP_ANGLE_UNITS = 'topAngleUnits';
export const GROUP_TOP_NORMAL_UNITS = 'topNormalUnits';
export const GROUP_TOP_END_UNITS = 'topEndUnits';
export const GROUP_TOP_EQUIPMENTS = 'topEquipments';
export const GROUP_PENAL_UNITS = 'penalUnits';
export const GROUP_BIG_EQUIPMENTS = 'bigEquipments';
export const GROUP_BAR_COUNTERS = 'barCounters';
export const GROUP_PORTABLE_EQUIPMENTS = 'portableEquipments';
export const GROUP_BUILTIN_EQUIPMENTS = 'builtInEquipments';
export const GROUP_SINK_EQUIPMENTS = 'sinkEquipments';
export const GROUP_ACCESSORIES = 'accessories';
export const GROUP_FACADES = 'facades';
export const GROUP_CONSTRUCTIVE_DOORS = 'doors';
export const GROUP_CONSTRUCTIVE_WINDOWS = 'windows';
export const GROUP_CONSTRUCTIVE_PILLARS = 'pillars';
export const GROUP_EQUIPMENTS = 'equipments';

export const KDETAIL_PRICE_TYPE_NONE = 'none';
export const KDETAIL_PRICE_TYPE_PM = 'pm';
export const KDETAIL_PRICE_TYPE_GOOD = 'good';
export const KDETAIL_PRICE_TYPE_COLLECTIONS = 'collections';

export const LEVEL_TOP = 'top';
export const LEVEL_BOTTOM = 'bottom';

export const OPTION_TYPE_NUMBER = 'number';
export const OPTION_TYPE_TEXT = 'text';
export const OPTION_TYPE_CHECKBOX = 'checkbox';
export const OPTION_TYPE_RANGE = 'range';
export const OPTION_TYPE_SELECT = 'select';
export const OPTION_TYPE_CORPUS_MATERIAL = 'corpusMaterial';
export const OPTION_TYPE_FACADE_MATERIAL = 'facadeMaterial';
export const OPTION_TYPE_TABLETOP_MATERIAL = 'tabletopMaterial';
export const OPTION_TYPE_HIDDEN_TEXT = 'hiddenText';
export const OPTION_TYPE_HIDDEN_NUMBER = 'hiddenNumber';
export const OPTION_TYPE_JSON = 'json';
export const OPTION_TYPE_RADIOBUTTON = 'radioButton';
export const OPTION_TYPE_OFFERS = 'offers';

export const GOOD_TYPE_MODULE = 'module';
export const GOOD_TYPE_CORPUS = 'corpus';
export const GOOD_TYPE_EQUIPMENT = 'equipment';
export const GOOD_TYPE_FACADE = 'facade';
export const GOOD_TYPE_FURNITURE = 'furniture';
export const GOOD_TYPE_TABLETOP = 'tabletop';
export const GOOD_TYPE_APRON = 'apron';
export const GOOD_TYPE_CORNER = 'corner';
export const GOOD_TYPE_PLINTH = 'plinth';
export const GOOD_TYPE_CORNICE = 'cornice';
export const GOOD_TYPE_SHELF = 'shelf';
export const GOOD_TYPE_EDGE = 'edge';
export const GOOD_TYPE_FLOOR = 'floor';
export const GOOD_TYPE_WALL = 'wall';
export const GOOD_TYPE_HANDLE = 'handle';
export const GOOD_TYPE_INTEGRATED_HANDLE = 'integratedHandle';
export const GOOD_TYPE_LEG = 'leg';
export const GOOD_TYPE_OTHER = 'other';
export const GOOD_TYPE_IMPORTED = 'imported';
export const GOOD_TYPE_DELETED = 'deleted';

export const SIDE_TYPE_LEFT = 'left';
export const SIDE_TYPE_RIGHT = 'right';
export const SIDE_TYPE_FRONT = 'front';
export const SIDE_TYPE_BACK = 'back';
export const SIDE_TYPE_BOTTOM = 'bottom';
export const SIDE_TYPE_NONE = 'none';
export const SIDE_TYPE_ARC = 'arc';
export const SIDE_TYPE_ANGLE = 'angle';
export const SIDE_TYPE_TOP = 'top';
export const SIDE_TYPE_DEFAULT = 'default';

export const SIDE_TYPES = {
  [SIDE_TYPE_LEFT]: 'cлева',
  [SIDE_TYPE_RIGHT]: 'cправа',
  [SIDE_TYPE_FRONT]: 'cпереди',
  [SIDE_TYPE_BACK]: 'cзади',
  [SIDE_TYPE_NONE]: 'угловой',
}

export const FACADE_MODEL_TYPE_DEFAULT = 'default';
export const FACADE_MODEL_TYPE_DECOR = 'decor';
export const FACADE_MODEL_TYPE_DECOR1 = 'decor1';
export const FACADE_MODEL_TYPE_DECOR2 = 'decor2';
export const FACADE_MODEL_TYPE_DEFAULT_VERTICAL = 'default+v';
export const FACADE_MODEL_TYPE_GLASS = 'glass';
export const FACADE_MODEL_TYPE_GLASS2 = 'glass2';
export const FACADE_MODEL_TYPE_ARC = 'arc';
export const FACADE_MODEL_TYPE_CONCAVE = 'concave';
export const FACADE_MODEL_TYPE_PLANE = 'plane';
export const FACADE_MODEL_TYPE_WARP = 'warp';
export const FACADE_MODEL_TYPE_BUTIL = 'butil';
export const FACADE_MODEL_TYPE_BUTIL2 = 'butil2';
export const FACADE_DETAIL_TYPE_MAIN = 'main';
export const FACADE_DETAIL_TYPE_SECOND = 'second';
export const FACADE_DETAIL_TYPE_INSERT = 'insert';
export const FACADE_DETAIL_TYPE_GLASS = 'glass';
export const FACADE_DETAIL_TYPE_CUSTOM_GLASS = 'custom_glass';
export const FACADE_DETAIL_TYPE_BACK = 'back';
export const FACADE_DETAIL_TYPE_PATINA = 'patina';
export const FACADE_DETAIL_TYPE_GRID = 'grid';
export const FACADE_DETAIL_TYPE_CUSTOM = 'custom';
export const FACADE_POSITION_TYPE_ANGLE = 'angle';
export const FACADE_POSITION_TYPE_CUBE = 'cube';
export const FACADE_POSITION_TYPE_LEFT = 'left';
export const FACADE_POSITION_TYPE_RIGHT = 'right';
export const FACADE_POSITION_TYPE_SIDE = 'side';
export const FACADE_POSITION_TYPE_FRONT = 'front';
export const FACADE_OPEN_TYPE_HORIZONTAL_PIVOT = 'horizontalPivot';
export const FACADE_OPEN_TYPE_VERTICAL_PIVOT = 'verticalPivot';
export const FACADE_OPEN_TYPE_BOX = 'box';
export const FACADE_OPEN_TYPE_NONE = 'none';

export const FACADE_OPEN_TYPES = {
    [FACADE_OPEN_TYPE_HORIZONTAL_PIVOT]: "Горизонтальная дверь",
    [FACADE_OPEN_TYPE_VERTICAL_PIVOT]: "Вертикальная дверь",
    [FACADE_OPEN_TYPE_BOX]: "Ящик",
    [FACADE_OPEN_TYPE_NONE]: "Не открывается"
}

export const TEXTURE_TYPE_DIFFUSE = 'diffuse';
export const TEXTURE_TYPE_ROUGHNESS = 'roughness';
export const TEXTURE_TYPE_TEXTURE = 'texture';
export const TEXTURE_TYPE_NORMAL = 'normal';

export const FACADE_FOR_SECOND = 'second';

export const FACADE_CALCULATE_AMOUNT = 'amount';
export const FACADE_CALCULATE_SELF_AMOUNT = 'self_amount'; // Тип расчета фасада, когда отдельно от модуля расчитывается, когда цена на модуль
export const FACADE_CALCULATE_NONE = 'none';

export const MODEL_EXTENSION_GLB = 'glb';

export const ALIGN_RIGHT = 'right';
export const ALIGN_LEFT = 'left';
export const ALIGN_CENTER = 'center';
export const ALIGN_TOP = 'top';
export const ALIGN_BOTTOM = 'bottom';

export const HANDLE_TYPE_VERTICAL = 'vertical';
export const HANDLE_TYPE_HORIZONTAL = 'horizontal';

export const LEG_NORMAL = 'LegNormal';
export const LEG_SMALL = 'LegSmall';

export const EQUIPMENT_CELL_NAME_OVEN = 'oven';
export const EQUIPMENT_CELL_NAME_TABLETOP = 'tabletop';
export const EQUIPMENT_CELL_NAME_MICROWAVE = 'microwave';
export const EQUIPMENT_CELL_NAME_EXTRACT = 'extract';

export const CONSTRICTIVE_TYPE_WINDOW = 'window';
export const CONSTRICTIVE_TYPE_DOOR = 'door';
export const CONSTRICTIVE_TYPE_PILLAR = 'pillar';

export const GEOMETRY_TYPE_SQUARE = 'square';
export const GEOMETRY_TYPE_ARC = 'arc';
export const GEOMETRY_TYPE_CONCAVE = 'concave';

export const FACADE_CELL_RIGHT_END = 'rightEnd';
export const FACADE_CELL_LEFT_END = 'leftEnd';
export const FACADE_CELL_DEFAULT = 'default';

export const HANDLE_MODEL_TYPE_DEFAULT = 'default';
export const HANDLE_MODEL_TYPE_DEFAULT_V2 = 'default-v2';
export const HANDLE_MODEL_TYPE_SMALL = 'small';

export const HANDLE_MODEL_TYPES = {
    [HANDLE_MODEL_TYPE_DEFAULT]: 'По умолчанию',
    [HANDLE_MODEL_TYPE_DEFAULT_V2]: 'По умолчанию-v2',
    [HANDLE_MODEL_TYPE_SMALL]: 'Маленькая',
  }

export const PILLAR_TYPE_HORIZONTAL = 'horizontal';
export const PILLAR_TYPE_VERTICAL = 'vertical';
export const PILLAR_TYPE_DEFAULT = 'default';

export const POINT_TYPE_ROOM = 'RoomPoint';
export const POINT_TYPE_WALL_CONSTRUCTIVE = 'Point';

export const CONNECTION_TYPE_DEFAULT = 'default';
export const CONNECTION_TYPE_MAIN_LEFT = 'main_left';
export const CONNECTION_TYPE_MAIN_RIGHT = 'main_right';

export const SHELF_TYPE_VERTICAL = 'vertical';
export const SHELF_TYPE_HORIZONTAL = 'horizontal';

export const SHELF_TYPES = {
    [SHELF_TYPE_VERTICAL] : "Вертикальная",
    [SHELF_TYPE_HORIZONTAL] : "Горизонтальная"
  }

export const SIZE_TYPE_WIDTH = 'width';
export const SIZE_TYPE_HEIGHT = 'height';
export const SIZE_TYPE_DEFAULT = 'default';
export const SIZE_TYPE_DEPTH = 'depth';

export const CLASSNAME_BOTTOM_UNIT_NORMAL = 'BottomUnitNormal';
export const CLASSNAME_BOTTOM_UNIT_PILASTER = 'BottomUnitPilaster';
export const CLASSNAME_BOTTOM_UNIT_BAR_STAND = 'BottomUnitBarStand';
export const CLASSNAME_BOTTOM_UNIT_BAR_P = 'ThreeBottomUnitBarP';
export const CLASSNAME_BOTTOM_UNIT_BAR_RAVENNA = 'ThreeBottomUnitBarRavenna';
export const CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL = 'BottomUnitFullLengthSidewall';
export const CLASSNAME_BOTTOM_UNIT_SINK = 'BottomUnitSink';
export const CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_SINK = 'BottomUnitFullLengthSidewallSink';
export const CLASSNAME_BOTTOM_UNIT_SINK_OPENED = 'BottomUnitSinkOpened';
export const CLASSNAME_BOTTOM_UNIT_OVEN = 'BottomUnitOven';
export const CLASSNAME_BOTTOM_UNIT_DISHWASHER = 'BottomUnitDishwasher';
export const CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_OVEN = 'BottomUnitFullLengthSidewallOven';
export const CLASSNAME_BOTTOM_UNIT_END_NORMAL = 'BottomUnitEndNormal';
export const CLASSNAME_BOTTOM_UNIT_END_PILASTER = 'BottomUnitEndPilaster';
export const CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL = 'BottomUnitEndFullLengthSidewall';
export const CLASSNAME_BOTTOM_UNIT_END_OPENED = 'BottomUnitEndOpened';
export const CLASSNAME_BOTTOM_UNIT_END_OPENED_CIRCLE = 'BottomUnitEndOpenedCircle';
export const CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_OPENED = 'BottomUnitEndFullLengthSidewallOpened';
export const CLASSNAME_BOTTOM_UNIT_END_CONSOLE = 'BottomUnitEndConsole';
export const CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_CONSOLE = 'BottomUnitEndFullLengthSidewallConsole';
export const CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL = 'BottomAngleUnitNormal';
export const CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_NORMAL_SINK = 'BottomAngleUnitFullLengthSidewallNormalSink';
export const CLASSNAME_BOTTOM_ANGLE_UNIT_TWO_FACADES_90 = 'BottomAngleUnitTwoFacades90';
export const CLASSNAME_BOTTOM_ANGLE_UNIT_CUBE = 'BottomAngleUnitCube';
export const CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_CUBE_SINK = 'BottomAngleUnitFullLengthSidewallCubeSink';
export const CLASSNAME_TOP_UNIT_NORMAL = 'TopUnitNormal';
export const CLASSNAME_TOP_UNIT_PILASTER = 'TopUnitPilaster';
export const CLASSNAME_TOP_UNIT_SHELF_FIGURED = 'TopUnitShelfFigured';
export const CLASSNAME_TOP_UNIT_END_NORMAL = 'TopUnitEndNormal';
export const CLASSNAME_TOP_UNIT_END_PILASTER = 'TopUnitEndPilaster';
export const CLASSNAME_TOP_UNIT_END_OPENED = 'TopUnitEndOpened'
export const CLASSNAME_TOP_UNIT_END_OPENED_CIRCLE = 'TopUnitEndOpenedCircle';
export const CLASSNAME_TOP_UNIT_ANGLE_NORMAL = 'TopUnitAngleNormal';
export const CLASSNAME_TOP_UNIT_ANGLE_TWO_FACADES_90 = 'TopUnitAngleTwoFacades90';
export const CLASSNAME_TOP_UNIT_ANGLE_CUBE = 'TopUnitAngleCube';
export const CLASSNAME_TOP_UNIT_ANGLE_DOUBLE = 'TopUnitAngleDouble';
export const CLASSNAME_TOP_UNIT_WINERY = 'TopUnitWinery';
export const CLASSNAME_TOP_UNIT_RACK_FRAME  = 'TopUnitRackFrame';
export const CLASSNAME_TOP_UNIT_BAR_RAVENNA = 'ThreeTopUnitBarRavenna';
export const CLASSNAME_TOP_UNIT_SHELF  = 'TopUnitShelf';
export const CLASSNAME_PENAL_UNIT_NORMAL = 'PenalUnitNormal';
export const CLASSNAME_PENAL_UNIT_FULL_LENGTH_SIDEWALL = 'PenalUnitFullLengthSidewall';
export const CLASSNAME_PENAL_UNIT_TOP = 'PenalUnitTop';
export const CLASSNAME_PENAL_UNIT_END_CONSOLE = 'PenalUnitEndConsole';
export const CLASSNAME_SIDE_BOTTOM_FACADE_UNIT = 'SideBottomFacadeUnit';
export const CLASSNAME_SIDE_TOP_FACADE_UNIT = 'SideTopFacadeUnit';
export const CLASSNAME_APRON_UNIT = 'ApronUnit';
export const CLASSNAME_TABLETOP_UNIT = 'TabletopUnit';
export const CLASSNAME_PLINTH_UNIT = 'PlinthUnit';
export const CLASSNAME_CORNER_UNIT = 'CornerUnit';
export const CLASSNAME_CORNICE_UNIT = 'CorniceUnit';
export const CLASSNAME_EQUIPMENT_SEPARATE_EXTRACT = 'EquipmentSeparateExtract';
export const CLASSNAME_EQUIPMENT_BUILTIN_EXTRACT = 'EquipmentBuiltinExtract';
export const CLASSNAME_EQUIPMENT_MOUNTED_EXTRACT = 'EquipmentMountedExtract';
export const CLASSNAME_EQUIPMENT_WASHER = 'EquipmentWasher';
export const CLASSNAME_EQUIPMENT_COOKER = 'EquipmentCooker';
export const CLASSNAME_EQUIPMENT_HOB = 'EquipmentHob';
export const CLASSNAME_EQUIPMENT_OVEN = 'EquipmentOven';
export const CLASSNAME_EQUIPMENT_DISHWASHER = 'EquipmentDishwasher';
export const CLASSNAME_EQUIPMENT_REFRIGERATOR = 'EquipmentRefrigerator';
export const CLASSNAME_EQUIPMENT_SEPARATE_SINK = 'EquipmentSeparateSink';
export const CLASSNAME_EQUIPMENT_BUILTIN_SINK = 'EquipmentBuiltInSink';
export const CLASSNAME_EQUIPMENT_BUILTIN_DISHWASHER = 'EquipmentBuiltInDishwasher';
export const CLASSNAME_EQUIPMENT_BUILTIN_WASHER = 'EquipmentBuiltInWasher';
export const CLASSNAME_CONSTRUCTIVE_DOOR = 'ConstructiveDoor';
export const CLASSNAME_CONSTRUCTIVE_DOORWAY = 'ConstructiveDoorway';
export const CLASSNAME_CONSTRUCTIVE_WINDOW = 'ConstructiveWindow';
export const CLASSNAME_CONSTRUCTIVE_PILLAR = 'ConstructivePillar';
export const CLASSNAME_CONSTRUCTIVE_WALL_ISLAND = 'ConstructiveWallIsland';
export const CLASSNAME_DOOR = 'Door'
export const CLASSNAME_WINDOW = 'Window'
export const CLASSNAME_CORPUS = 'ThreeCorpus'
export const CLASSNAME_BOTTOM_UNIT_SINGLE_HANDLE = 'BottomUnitSingleHandle';
export const CLASSNAME_BOTTOM_UNIT_DOUBLE_HANDLE = 'BottomUnitDoubleHandle';
export const CLASSNAME_PENAL_UNIT_SINGLE_HANDLE = 'PenalUnitSingleHandle';
export const CLASSNAME_PENAL_UNIT_DOUBLE_HANDLE = 'PenalUnitDoubleHandle';
export const CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE = 'BottomAngleUnitNormalSingleHandle';
export const CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_BERTA = 'BottomAngleUnitNormalBerta';

export const CLASSNAME_LIST = {
    [CLASSNAME_BOTTOM_UNIT_NORMAL] : "Шкаф нижний прямой",
    [CLASSNAME_BOTTOM_UNIT_PILASTER] : "Шкаф нижний пилястра",
    [CLASSNAME_BOTTOM_UNIT_BAR_STAND] : "Шкаф нижний барная стойка",
    [CLASSNAME_BOTTOM_UNIT_BAR_P] : "Шкаф нижний прямой барный",
    [CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL] : "Шкаф нижний боковина во всю длину",
    [CLASSNAME_BOTTOM_UNIT_SINK] : "Шкаф нижний раковина",
    [CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_SINK] : "Шкаф нижний боковина во всю длину с раковиной",
    [CLASSNAME_BOTTOM_UNIT_SINK_OPENED] : "Шкаф нижний открытый раковина",
    [CLASSNAME_BOTTOM_UNIT_OVEN] : "Шкаф нижний для духовки",
    [CLASSNAME_BOTTOM_UNIT_DISHWASHER] : "Шкаф нижний для стиральной машины",
    [CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_OVEN] : "Шкаф нижний боковина во всю длину с духовкой",
    [CLASSNAME_BOTTOM_UNIT_END_NORMAL] : "Шкаф нижний крайний прямой",
    [CLASSNAME_BOTTOM_UNIT_END_PILASTER] : "Шкаф нижний крайний пилястра",
    [CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL] : "Шкаф нижний крайний боковина во всю длину",
    [CLASSNAME_BOTTOM_UNIT_END_OPENED] : "Шкаф нижний крайний открытый",
    [CLASSNAME_BOTTOM_UNIT_END_OPENED_CIRCLE] : "Шкаф нижний крайний открытый закругленный",
    [CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_OPENED] : "Шкаф нижний крайний боковина во всю длину открытый",
    [CLASSNAME_BOTTOM_UNIT_END_CONSOLE] : "Шкаф нижний крайний консоль",
    [CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_CONSOLE] : "Шкаф нижний крайний боковина во всю длину с консолью",
    [CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL] : "Шкаф нижний угловой прямой",
    [CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_NORMAL_SINK] : "Шкаф нижний угловой боковина во всю длину с раковиной",
    [CLASSNAME_BOTTOM_ANGLE_UNIT_TWO_FACADES_90] : "Шкаф нижний угловой двухфасадный 90",
    [CLASSNAME_BOTTOM_ANGLE_UNIT_CUBE] : "Шкаф нижний угловой прямоугольный",
    [CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_CUBE_SINK]: "Шкаф нижний угловой боковина во всю длину прямоугольный с раковиной",
    [CLASSNAME_TOP_UNIT_NORMAL] : "Шкаф верхний прямой",
    [CLASSNAME_TOP_UNIT_PILASTER] : "Шкаф верхний пилястра",
    [CLASSNAME_TOP_UNIT_SHELF_FIGURED] : "Шкаф верхний фигурная полка",
    [CLASSNAME_TOP_UNIT_END_NORMAL] : "Шкаф верхний крайний прямой",
    [CLASSNAME_TOP_UNIT_END_PILASTER] : "Шкаф верхний крайний пилястра",
    [CLASSNAME_TOP_UNIT_END_OPENED] : "Шкаф верхний крайний открытый",
    [CLASSNAME_TOP_UNIT_END_OPENED_CIRCLE] : "Шкаф верхний крайний открытый закругленный",
    [CLASSNAME_TOP_UNIT_ANGLE_NORMAL] : "Шкаф верхний угловой прямой",
    [CLASSNAME_TOP_UNIT_ANGLE_TWO_FACADES_90] : "Шкаф верхний угловой двухфасадный 90",
    [CLASSNAME_TOP_UNIT_ANGLE_CUBE] : "Шкаф верхний угловой прямоугольный",
    [CLASSNAME_TOP_UNIT_ANGLE_DOUBLE] : "Шкаф верхний угловой двойной",
    [CLASSNAME_TOP_UNIT_WINERY] : "Шкаф верхний винный",
    [CLASSNAME_TOP_UNIT_RACK_FRAME] : "Шкаф верхний стеллаж",
    [CLASSNAME_TOP_UNIT_SHELF] : "Шкаф верхний полка",
    [CLASSNAME_PENAL_UNIT_NORMAL] : "Пенал прямой",
    [CLASSNAME_PENAL_UNIT_FULL_LENGTH_SIDEWALL] : "Пенал боковина во всю длину",
    [CLASSNAME_PENAL_UNIT_TOP] : "Пенал верхний",
    [CLASSNAME_PENAL_UNIT_END_CONSOLE] : "Пенал крайний консоль",
    [CLASSNAME_BOTTOM_UNIT_SINGLE_HANDLE]: "Шкаф нижний с интегрированной ручкой",
    [CLASSNAME_BOTTOM_UNIT_DOUBLE_HANDLE]: "Шкаф нижний с двумя интегрированными ручками",
    [CLASSNAME_PENAL_UNIT_SINGLE_HANDLE]: "Пенал с интегрированной ручкой",
    [CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE]: "Шкаф нижний угловой прямой с интегрированной ручкой",
    [CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_BERTA]: "Шкаф нижний угловой прямой Берта",

    [CLASSNAME_SIDE_BOTTOM_FACADE_UNIT] : "Боковой нижний фасад модуля",
    [CLASSNAME_SIDE_TOP_FACADE_UNIT] : "Боковой верхний фасад модуля",
};

export const CLASSNAME_END_UNITS = [
  CLASSNAME_TOP_UNIT_END_NORMAL,
  CLASSNAME_BOTTOM_UNIT_END_NORMAL,
  CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL,
  CLASSNAME_BOTTOM_UNIT_END_OPENED,
  CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_OPENED,
  CLASSNAME_BOTTOM_UNIT_END_OPENED_CIRCLE
];

export const CLASSNAME_ANGLE_TWO_FACADES_UNITS = [
  CLASSNAME_BOTTOM_ANGLE_UNIT_TWO_FACADES_90,
  CLASSNAME_TOP_UNIT_ANGLE_TWO_FACADES_90
];

export const CLASSNAME_ANGLE_CUBE_UNITS = [
  CLASSNAME_BOTTOM_ANGLE_UNIT_CUBE,
  CLASSNAME_TOP_UNIT_ANGLE_CUBE
];

export const CLASSNAME_ANGLE_UNITS = [
  CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL,
  CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE,
  CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_BERTA,
  CLASSNAME_TOP_UNIT_ANGLE_NORMAL
];
  
export const CLASSNAME_GROUPS = {
    [CLASSNAME_BOTTOM_UNIT_NORMAL] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_PILASTER] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_BAR_STAND] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_BAR_P] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_SINK] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_SINK] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_SINK_OPENED] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_OVEN] : GROUP_BOTTOM_NORMAL_UNITS, 
    [CLASSNAME_BOTTOM_UNIT_DISHWASHER] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_OVEN] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_END_NORMAL] : GROUP_BOTTOM_END_UNITS,
    [CLASSNAME_BOTTOM_UNIT_END_PILASTER] : GROUP_BOTTOM_END_UNITS,
    [CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL] : GROUP_BOTTOM_END_UNITS,
    [CLASSNAME_BOTTOM_UNIT_END_OPENED] : GROUP_BOTTOM_END_UNITS,
    [CLASSNAME_BOTTOM_UNIT_END_OPENED_CIRCLE] : GROUP_BOTTOM_END_UNITS,
    [CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_OPENED] : GROUP_BOTTOM_END_UNITS,
    [CLASSNAME_BOTTOM_UNIT_END_CONSOLE] : GROUP_BOTTOM_END_UNITS,
    [CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_CONSOLE] : GROUP_BOTTOM_END_UNITS,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL] : GROUP_BOTTOM_ANGLE_UNITS,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_NORMAL_SINK] : GROUP_BOTTOM_ANGLE_UNITS,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_TWO_FACADES_90] : GROUP_BOTTOM_ANGLE_UNITS,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_CUBE] : GROUP_BOTTOM_ANGLE_UNITS,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_CUBE_SINK]: GROUP_BOTTOM_ANGLE_UNITS,
    [CLASSNAME_TOP_UNIT_NORMAL] : GROUP_TOP_NORMAL_UNITS,
    [CLASSNAME_TOP_UNIT_PILASTER] : GROUP_TOP_NORMAL_UNITS,
    [CLASSNAME_TOP_UNIT_SHELF_FIGURED] : GROUP_TOP_NORMAL_UNITS,
    [CLASSNAME_TOP_UNIT_END_NORMAL] : GROUP_TOP_END_UNITS,
    [CLASSNAME_TOP_UNIT_END_PILASTER] : GROUP_TOP_END_UNITS,
    [CLASSNAME_TOP_UNIT_END_OPENED] : GROUP_TOP_END_UNITS,
    [CLASSNAME_TOP_UNIT_END_OPENED_CIRCLE] : GROUP_TOP_END_UNITS,
    [CLASSNAME_TOP_UNIT_ANGLE_NORMAL] : GROUP_TOP_ANGLE_UNITS,
    [CLASSNAME_TOP_UNIT_ANGLE_TWO_FACADES_90] : GROUP_TOP_ANGLE_UNITS,
    [CLASSNAME_TOP_UNIT_ANGLE_CUBE] : GROUP_TOP_ANGLE_UNITS,
    [CLASSNAME_TOP_UNIT_ANGLE_DOUBLE] : GROUP_TOP_ANGLE_UNITS, 
    [CLASSNAME_TOP_UNIT_WINERY] : GROUP_TOP_NORMAL_UNITS,
    [CLASSNAME_TOP_UNIT_RACK_FRAME] : GROUP_TOP_NORMAL_UNITS,
    [CLASSNAME_TOP_UNIT_SHELF] : GROUP_TOP_NORMAL_UNITS,
    [CLASSNAME_PENAL_UNIT_NORMAL] : GROUP_PENAL_UNITS,
    [CLASSNAME_PENAL_UNIT_FULL_LENGTH_SIDEWALL] : GROUP_PENAL_UNITS,
    [CLASSNAME_PENAL_UNIT_TOP] : GROUP_PENAL_UNITS,
    [CLASSNAME_PENAL_UNIT_END_CONSOLE] : GROUP_PENAL_UNITS,
    [CLASSNAME_SIDE_BOTTOM_FACADE_UNIT] : GROUP_FACADES,
    [CLASSNAME_SIDE_TOP_FACADE_UNIT] : GROUP_FACADES,
    [CLASSNAME_BOTTOM_UNIT_SINGLE_HANDLE] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_BOTTOM_UNIT_DOUBLE_HANDLE] : GROUP_BOTTOM_NORMAL_UNITS,
    [CLASSNAME_PENAL_UNIT_SINGLE_HANDLE] : GROUP_PENAL_UNITS,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE] : GROUP_BOTTOM_ANGLE_UNITS,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_BERTA] : GROUP_BOTTOM_ANGLE_UNITS
};


export const CLASSNAME_LEVELS = {
    [CLASSNAME_BOTTOM_UNIT_NORMAL]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_PILASTER]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_BAR_STAND]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_BAR_P]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_SINK]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_SINK]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_SINK_OPENED]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_OVEN]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_DISHWASHER]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_OVEN]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_END_NORMAL]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_END_PILASTER]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_END_OPENED]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_END_OPENED_CIRCLE]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_OPENED]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_END_CONSOLE]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_CONSOLE]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_NORMAL_SINK]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_TWO_FACADES_90]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_CUBE]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_CUBE_SINK]: LEVEL_BOTTOM,
    [CLASSNAME_TOP_UNIT_NORMAL]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_PILASTER]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_SHELF_FIGURED]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_END_NORMAL]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_END_PILASTER]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_END_OPENED]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_END_OPENED_CIRCLE]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_ANGLE_NORMAL]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_ANGLE_TWO_FACADES_90]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_ANGLE_CUBE]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_ANGLE_DOUBLE]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_WINERY]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_RACK_FRAME]: LEVEL_TOP,
    [CLASSNAME_TOP_UNIT_SHELF]: LEVEL_TOP,
    [CLASSNAME_PENAL_UNIT_NORMAL]: LEVEL_BOTTOM,
    [CLASSNAME_PENAL_UNIT_FULL_LENGTH_SIDEWALL]: LEVEL_BOTTOM,
    [CLASSNAME_PENAL_UNIT_TOP]: LEVEL_BOTTOM,
    [CLASSNAME_PENAL_UNIT_END_CONSOLE]: LEVEL_BOTTOM,
    [CLASSNAME_SIDE_BOTTOM_FACADE_UNIT]: LEVEL_BOTTOM,
    [CLASSNAME_SIDE_TOP_FACADE_UNIT]: LEVEL_TOP,
    [CLASSNAME_BOTTOM_UNIT_SINGLE_HANDLE]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_UNIT_DOUBLE_HANDLE]: LEVEL_BOTTOM,
    [CLASSNAME_PENAL_UNIT_SINGLE_HANDLE]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE]: LEVEL_BOTTOM,
    [CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_BERTA]: LEVEL_BOTTOM
};

export const UNIT_PLINTHS_TYPE_DEFAULT = 'default';
export const UNIT_PLINTHS_TYPE_BACK_NEIGHBOR = 'backNeighbor';

export const DEFAULT_ROOM_ID = 'new';
export const DEFAULT_PROJECT_ID = 'new';

export const AXIS_X = 'x';
export const AXIS_Y = 'y';
export const AXIS_Z = 'z';

export const DIRECT_EQUATION_TYPE_NORMAL = 'normal';
export const DIRECT_EQUATION_TYPE_VERTICAL = 'vertical';
export const DIRECT_EQUATION_TYPE_HORIZONTAL = 'horizontal';

export const CATALOG_CALCULATE_TYPE_MODULE = 'module';
export const CATALOG_CALCULATE_TYPE_DETAILS = 'details';

export const BOX_TYPE_NORMAL = 'normal';

export const HANDLE_ID_INTEGRATION = 'integration';

export const FACADE_FUNCTIONAL_TYPE_DEFAULT = 'default';
export const FACADE_FUNCTIONAL_TYPE_DISHWASHER = 'dishwasher';

export const EQUIPMENT_MODEL_NONE = 'none';
export const EQUIPMENT_MODEL_COOKER = 'cooker';
export const EQUIPMENT_MODEL_GAS_COOKER = 'gasCooker';
export const EQUIPMENT_MODEL_WASHER = 'washer';
export const EQUIPMENT_MODEL_DISHWASHER = 'dishwasher';
export const EQUIPMENT_MODEL_SEPARATE_EXTRACT = 'separateExtract';
export const EQUIPMENT_MODEL_BUILTIN_EXTRACT = 'builtInExtract';
export const EQUIPMENT_MODEL_MOUNTED_EXTRACT = 'mountedExtract';
export const EQUIPMENT_MODEL_HOB = 'hob';
export const EQUIPMENT_MODEL_GAS_HOB = 'gasHob';
export const EQUIPMENT_MODEL_MICROWAVE = 'microwave';
export const EQUIPMENT_MODEL_OVEN = 'oven';
export const EQUIPMENT_MODEL_REFRIGERATOR = 'refrigerator';
export const EQUIPMENT_MODEL_SEPARATE_SINK = 'separateSink';
export const EQUIPMENT_MODEL_BUILTIN_CIRCLE_SINK = 'builtInCircleSink';
export const EQUIPMENT_MODEL_BUILTIN_BOX_SINK = 'builtInBoxSink';
export const EQUIPMENT_MODEL_BUILTIN_DISHWASHER = 'builtInDishwasher';
export const EQUIPMENT_MODEL_BUILTIN_BOTTLE = 'builtInBottle';
export const EQUIPMENT_MODEL_BUILTIN_MICROWAVE = 'builtInMicrowave';
export const EQUIPMENT_MODEL_BUILTIN_DRYING = 'builtInDrying';
export const EQUIPMENT_MODEL_BUILTIN_SOCKET = 'socket';

export const NONE_MATERIAL = 'none';

export const NONE_EQUIPMENT = 'none';

export const TABLETOP_PLANK_TYPE_CONNECT = 'connect';
export const TABLETOP_PLANK_TYPE_END = 'end';
export const TABLETOP_PLANK_TYPE_ANGLE = 'angle';
export const TABLETOP_PLANK_TYPE_EURO_ZAPIL = 'euroZapil';

export const APRON_PLANK_TYPE_CONNECT = 'connect';
export const APRON_PLANK_TYPE_END = 'end';
export const APRON_PLANK_TYPE_ANGLE = 'angle';

export const RADIO_BUTTON_TYPE_KIT_CODE = 'kitCode';

export const FURNITURE_FT_HANDLE = 'handle';
export const FURNITURE_FT_HINGE = 'hinge';

export const FURNITURE_TYPE_NONE = 'none';

export const FILTER_TYPE_TEXT = 'text';
export const FILTER_TYPE_SELECT = 'select';
export const FILTER_TYPE_PHONE = 'phone';

export const ORDER_USER_TYPE_NEW = 'new';
export const ORDER_USER_TYPE_CHOICE = 'choice';

export const DELIVERY_TYPE_HOME = 'home';
export const DELIVERY_TYPE_SELF = 'self';

export const ORDER_RESULT_SUCCESS = 0;
export const ORDER_RESULT_UNKNOWN_ERROR = 1;
export const ORDER_RESULT_ACCESS_DENIED = 2;
export const ORDER_RESULT_CREATE_CUSTOMER_ERROR = 3;
export const ORDER_RESULT_CREATE_ERROR = 4;
export const ORDER_RESULT_NEED_AUTH = 5;
export const ORDER_RESULT_NEED_COPY = 6;
export const ORDER_RESULT_NEED_SMS = 7;

export const ERROR_MODULES_DELETE = 'delete';
export const ERROR_MODULES_SELECT = 'errorSelect';

export const CONSTRUCTIVE_MODEL_NONE = 'none';
export const CONSTRUCTIVE_MODEL_COOLER = 'cooler';
export const CONSTRUCTIVE_MODEL_GAS_BOILER = 'gasBoiler';
export const CONSTRUCTIVE_MODEL_GAS_METER = 'gasMeter';
export const CONSTRUCTIVE_MODEL_RADIATOR_SECTION = 'radiatorSection';
export const CONSTRUCTIVE_MODEL_SOCKET = 'socket';
export const CONSTRUCTIVE_MODEL_SWITCH = 'switch';
export const CONSTRUCTIVE_MODEL_VENTILATION = 'ventilation';
export const CONSTRUCTIVE_MODEL_WATER_HEATER_HORIZONTAL = 'waterHeaterHorizontal';
export const CONSTRUCTIVE_MODEL_WATER_HEATER_VERTICAL = 'waterHeaterVertical';

export const DEFAULT_HANDLE_ID = 'default';

export const AUTO_SAVE_NONE = 'none';
export const AUTO_SAVE_SERVER = 'server';
export const AUTO_SAVE_LOCAL = 'local';

export const LOCAL_STORAGE_PROJECT_NAME = 'project';

export const CLASSNAME_CONSTRUCTIVE_NONE = 'ConstructiveNone';
export const CLASSNAME_CONSTRUCTIVE_COOLER = 'ConstructiveCooler';
export const CLASSNAME_CONSTRUCTIVE_GAS_BOILER = 'ConstructiveGasBoiler';
export const CLASSNAME_CONSTRUCTIVE_GAS_METER = 'ConstructiveGasMeter';
export const CLASSNAME_CONSTRUCTIVE_RADIATOR_SECTION = 'ConstructiveRadiatorSection';
export const CLASSNAME_CONSTRUCTIVE_SOCKET = 'ConstructiveSocket';
export const CLASSNAME_CONSTRUCTIVE_SWITCH = 'ConstructiveSwitch';
export const CLASSNAME_CONSTRUCTIVE_VENTILATION = 'ConstructiveVentilation';
export const CLASSNAME_CONSTRUCTIVE_WATER_HEATER_HORIZONTAL = 'ConstructiveWaterHeaterHorizontal';
export const CLASSNAME_CONSTRUCTIVE_WATER_HEATER_VERTICAL = 'ConstructiveWaterHeaterVertical';

export const CHECK_CATALOG_DEFAULT = 'default';
export const CHECK_CATALOG_NOT_STRICT = 'notStrict';
export const CHECK_CATALOG_STRICT = 'strict';

export const DEFAULT_LEGS_HEIGHT = 100;

export const PRICE_CELL_MODULE = 'module';
export const PRICE_CELL_HINGES = 'hinges';
export const PRICE_CELL_HANDLE = 'handle';
export const PRICE_CELL_FURNITURE = 'furniture';
export const PRICE_CELL_FACADE = 'facade';
export const PRICE_CELL_CORPUS = 'corpus';
export const PRICE_CELL_EXTRA_OFFERS = 'extraOffers';
export const PRICE_CELL_APRON = 'apron';
export const PRICE_CELL_TABLETOP = 'tabletop';
export const PRICE_CELL_PLINTH = 'plinth';
export const PRICE_CELL_CORNER = 'corner';
export const PRICE_CELL_PLANKS = 'planks';
export const PRICE_CELL_CORNICE = 'cornice';
export const PRICE_CELL_NONE = 'none';

export const INTERSECTION_LINE_INTERSECT = 'intersect';
export const INTERSECTION_LINE_NO_INTERSECT = 'noIntersect';
export const INTERSECTION_LINE_COINCIDENT = 'coincident';
export const INTERSECTION_LINE_PARALLEL = 'parallel';

export const T_I_HANDLE_FUNCTIONAL_TYPE_DEFAULT = 'default';
export const T_I_HANDLE_FUNCTIONAL_TYPE_BETWEEN_BOXES = 'betweenBoxes';

export const FACADE_MATERIAL_TYPE_DEFAULT = 'default';
export const FACADE_MATERIAL_TYPE_CORPUS_MATERIAL = 'corpusMaterial';
export const FACADE_MATERIAL_TYPE_SECOND = 'secondMaterial';

export const FURNITURE_TYPE_KITCHEN = 'kitchen';
export const FURNITURE_TYPE_CABINET = 'cabinet';
export const FURNITURE_TYPE_SOFT = 'soft';

export const ALL_FACADES = 'all';

export const EDIT_MODE_MODULE = 'module';
export const EDIT_MODE_MATERIALS = 'materials';
export const EDIT_MODE_CORPUS_MATERIALS = 'corpusMaterials';
export const EDIT_MODE_FACADE_MATERIALS = 'facadeMaterials';
export const EDIT_MODE_FACADES = 'facades';
export const EDIT_MODE_PLINTHS = 'plinths';
export const EDIT_MODE_HANDLES = 'handles';
export const EDIT_MODE_NONE = '';
