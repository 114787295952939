import {Point} from '../Point/Point';
import {TPointType} from '../../../../common-code/types/TPointType';
import {TConnectionType} from '../../../../common-code/types/TConnectionType';
import {ISavePointData} from '../../../../common-code/interfaces/saveData/ISavePointData';
import {IThreeService} from '../../interfaces/IThreeService';

export class RoomPoint extends Point {
    sort: number;
    type: TPointType;
    connectionType: TConnectionType;

    constructor(pointData: ISavePointData, roomService: IThreeService) {
        super(pointData, roomService);
        this.sort = pointData.sort;
        this.type = pointData.type;
        this.ready = false;
        this.connectionType = pointData.connectionType;
    }

    public getType(): TPointType {
        return this.type;
    }

}