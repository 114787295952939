import {ThreeCorner} from './ThreeCorner';
import {Euler, MeshStandardMaterial, Vector2, Vector3} from 'three';
import {TPoint2D} from '../../../../../../common-code/types/TPoint2D';
import {CommonObjectHelper} from '../../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {TSideType} from '../../../../../../common-code/types/TSideType';
import {ISaveKUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {MathHelper} from 'common-code';

export class ThreeCornerUnion extends ThreeCorner {
    corners: ThreeCorner[];

    constructor(options: ISaveKUnitDetailData, corners: ThreeCorner[]) {
        let firstCorner: ThreeCorner;

        firstCorner = corners[0];
        if (!firstCorner) {
            throw new Error('error-ThreeCornerUnion-constructor');
        }
        super(options, firstCorner.unit);
        this.corners = corners;
    }

    public getChildren(): ThreeCorner[] {
        return this.corners;
    }

    public getSortChildIds(): string[] {
        let ids: { [n: number]: number } = {};
        let corner: ThreeCorner;

        for (corner of this.corners) {
            ids[corner.getId()] = corner.getId();
        }

        return Object.keys(ids).sort((a: string, b: string) => {
            return +a - +b;
        });
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        this.setIsUnionCorners(true);
    }

    public hide() {
        super.hide();
        this.setIsUnionCorners(false);

    }

    public show() {
        super.show();
        this.setIsUnionCorners(true);
    }

    public setIsUnionCorners(isUnion: boolean) {
        let corner: ThreeCorner;

        for (corner of this.corners) {
            corner.setIsUnion(isUnion);
        }
    }

    public setCenterPosition() {
        this.view3d.userData.centerPosition = this.view3d.position;
    }

    protected addToScene() {
        this.service.addToSceneObject3D(this.view3d);
    }

    public remove() {
        this.setIsUnionCorners(false);
        this.service.removeFromSceneObject3D(this.view3d);
        super.remove();
    }

    public getBodyMaterial(): MeshStandardMaterial {
        return new MeshStandardMaterial({
            color: this.getFirstCorner().materialData.color || '#ffffff',
            map: this.getFirstCorner().materialTextures.texture || null,
            normalMap: this.getFirstCorner().materialTextures.normal || null,
            roughnessMap: this.getFirstCorner().materialTextures.roughness || null,
            envMapIntensity: 5
        })
    }

    public getFirstCorner(): ThreeCorner {
        return this.corners[0];
    }

    public getGlobalPosition() {
        return this.view3d.position;
    }

    public getGlobalRotation(): Euler {
        return this.view3d.rotation;
    }

    public getLength(): number {
        let corner: ThreeCorner;
        let length: number = 0;

        for (corner of this.corners) {
            length += corner.getLength();
        }

        return length;
    }

    protected initPosition(): Vector3 {
        let position2d: TPoint2D;
        let position: Vector3;

        position2d = CommonObjectHelper.calculateUnionPosition2DByDetails(this.corners);
        position = new Vector3(position2d.x, this.unit.getUnionDetailYPosition(this.corners[0]), position2d.y);

        return position;
    }

    protected initRotation(): Euler {
        return CommonObjectHelper.calculateUnionRotationByDetails(this.corners);
    }

    public getDefaultPoints(type: TSideType): Vector2[] {
        let firstCorner, lastCorner,
            firstPosition, lastPosition,
            globalPoints,
            positionAngle, cornersAngle,
            leftPoints, rightPoints;

        firstCorner = this.corners[0];
        lastCorner = this.corners[this.corners.length - 1];

        firstPosition = firstCorner.getGlobalPosition();
        lastPosition = lastCorner.getGlobalPosition();
        globalPoints = firstCorner.getGlobalMainPoints();
        positionAngle = MathHelper.getNormalAngle({
            x: lastPosition.x - firstPosition.x,
            y: lastPosition.z - firstPosition.z
        });
        cornersAngle = MathHelper.getNormalAngle({
            x: globalPoints.bottom.pointB.x - globalPoints.bottom.pointA.x,
            y: globalPoints.bottom.pointB.z - globalPoints.bottom.pointA.z
        });
        if (+positionAngle.toFixed(3) === +cornersAngle.toFixed(3)) {
            leftPoints = firstCorner.getLeftPoints();
            rightPoints = lastCorner.getRightPoints();
        } else {
            leftPoints = lastCorner.getLeftPoints();
            rightPoints = firstCorner.getRightPoints();
        }
        switch (type) {
            case 'left':
                return leftPoints;
            case 'right':
                return rightPoints;
            default:
                return super.getDefaultPoints(type);
        }
    }
}