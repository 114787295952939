import {IClassNameProps} from "@bem-react/core";
import {cn} from "@bem-react/classname";
import React from "react";
import './PageIndex.css';
import {useTranslation} from "react-i18next";
import {cnHeader, Header} from "../../ui/layouts/Header";
import {Link} from "../../ui/components/Link/Link";
import {LINK_TYPE_NAV} from "../../ui/constants";
import moduleCollections from "../../assets/images/module-collections.png";
import complectCollections from "../../assets/images/complect-collections.png";

export const cnPageIndex = cn('PageIndex');

export interface IPageIndexProps extends IClassNameProps {

}

export const PageIndex: React.FC<IPageIndexProps> = ({className}) => {
    const {t} = useTranslation();

    return (
        <div className={cnPageIndex({}, [className])}>
            <Header className={cnPageIndex(cnHeader())} title={t("3D конструктор кухонных гарнитуров 'Народные кухни'") || undefined} />
            <div className={cnPageIndex('Main')}>
                <Link
                    className={cnPageIndex('Item', {color: 'green'})}
                    type={LINK_TYPE_NAV}
                    href={'/collections'}
                >
                    <div className={cnPageIndex('Wrapper')}>
                        <img className={cnPageIndex('Image')} src={moduleCollections} alt={'moduleCollections'}/>
                        <p className={cnPageIndex('ItemTitle')}>{t('Собрать кухню из готовых модулей')}</p>
                        <p className={cnPageIndex('ItemDescription')}>
                            {t('Мы предложим большое количество кухонь, состоящих из модулей стандартного размера')}
                            <br/><br/>
                            {t('Преимущества - фабричное качество и наличие на складе.')}
                        </p>
                    </div>
                </Link>
                {/*<Link*/}
                {/*    className={cnPageIndex('Item', {color: 'red'})}*/}
                {/*    type={LINK_TYPE_NAV}*/}
                {/*    href={'/kuhni-na-zakaz'}*/}
                {/*>*/}
                {/*    <div className={cnPageIndex('Wrapper')}>*/}
                {/*        <img className={cnPageIndex('Image')} src={toOrderCollections} alt={'toorderCollections'}/>*/}
                {/*        <p className={cnPageIndex('ItemTitle')}>{t('Создать индивидуальную кухню')}</p>*/}
                {/*        <p className={cnPageIndex('ItemDescription')}>*/}
                {/*            {t('Создайте уникальный проект из десятков фрезеровок и цветов фасадов.')}*/}
                {/*            <br/><br/>*/}
                {/*            {t('Преимущества - вы можете использовать нестандартные размеры модулей, но изготовление займет чуть больше времени.')}*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*</Link>*/}
                <Link
                    className={cnPageIndex('Item', {color: 'violet'})}
                    type={LINK_TYPE_NAV}
                    href={'/complect-collections'}
                >
                    <div className={cnPageIndex('Wrapper')}>
                        <img className={cnPageIndex('Image')} src={complectCollections} alt={'complectCollections'}/>
                        <p className={cnPageIndex('ItemTitle')}>{t('Выбрать готовый кухонный комплект')}</p>
                        <p className={cnPageIndex('ItemDescription')}>
                            {t('В каждой коллекции мы собрали для вас несколько готовых вариантов самых распространенных размеров.')}
                            <br/><br/>
                            {t('Преимущества - простота покупки и наличие на складе.')}
                        </p>
                    </div>
                </Link>
            </div>
        </div>
    );
}