import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {ThreeApron} from '../../details/ThreeApron/ThreeApron';
import {ThreeKUnit} from '../ThreeKUnit/ThreeKUnit';
import {ThreeTabletop} from '../../details/ThreeTabletop/ThreeTabletop';
import {ThreeCorner} from '../../details/ThreeCorner/ThreeCorner';
import {Box3, Vector3} from 'three';
import {ThreeLeg} from '../../details/ThreeLeg/ThreeLeg';
import {
    CLASSNAME_EQUIPMENT_BUILTIN_SINK,
    CLASSNAME_EQUIPMENT_HOB,
    CLASSNAME_EQUIPMENT_SEPARATE_SINK,
    LEG_NORMAL,
    LEG_SMALL,
    SIDE_TYPE_BOTTOM,
} from '../../../../../../common-code/constants';
import {ThreePlinth} from '../../details/ThreePlinth/ThreePlinth';
import {TLevelBoxes} from '../../../../types/TLevelBoxes';
import {ThreeSize} from '../../ThreeSize/ThreeSize';
import {WASHING_MIN_WIDTH} from '../../../../constants';
import {TClassName} from "../../../../../../common-code/types/TClassName";
import {ThreeLegSmall} from "../../details/ThreeLeg/types/ThreeLegSmall";
import {ThreeLegNormal} from "../../details/ThreeLeg/types/ThreeLegNormal";
import {ISaveBottomUnitData} from '../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveLegData} from '../../../../../../common-code/interfaces/saveData/ISaveLegData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnit extends ThreeKUnit {
    saveData: ISaveBottomUnitData;
    aprons: ThreeApron[];
    tabletops: ThreeTabletop[];
    corners: ThreeCorner[];
    plinths: ThreePlinth[];
    legs: ThreeLeg[];

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.aprons = [];
        this.tabletops = [];
        this.corners = [];
        this.plinths = [];
        this.legs = [];
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        this.createLegs();
    }

    public removeChildren() {
        this.removeLegs();
        super.removeChildren();
    }

    public showOnlyFacades() {
        this.removeLegs();
        super.showOnlyFacades();
    }

    protected calculateChildrenGlobalFrontVector() {
        super.calculateChildrenGlobalFrontVector();
        let leg: ThreeLeg;

        for (leg of this.legs) {
            leg.calculateGlobalFrontVector();
        }
    }

    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        } else {
            if (this.view3d.position.y !== this.defaultYPosition()) {
                this.view3d.position.y = this.defaultYPosition();
            }
        }
        this.afterSetPosition();
    }

    public getCorpusInitPosition(): Vector3 {
        let position: Vector3;

        position = super.getCorpusInitPosition();
        if (this.getDepth() === 600 && this.getDepth() > this.corpus.getDepth()) {
            position.z = this.getDepth()/2 - this.service.getTabletopFrontGap() - this.corpus.getDepth()/2;
        }

        return position;
    }

    protected correctMovePosition(movePosition: Vector3) {
        this.correctFloorUnitMovePosition(movePosition);
    }

    protected availableEquipments(): TClassName[] {
        let availableEquipments: TClassName[];

        availableEquipments = super.availableEquipments();
        if (this.getWidth() >= WASHING_MIN_WIDTH &&
            !this.hasBoxes()) {
            if (availableEquipments.indexOf(CLASSNAME_EQUIPMENT_SEPARATE_SINK) === -1) {
                availableEquipments.push(CLASSNAME_EQUIPMENT_SEPARATE_SINK);
            }
            if (availableEquipments.indexOf(CLASSNAME_EQUIPMENT_BUILTIN_SINK) === -1) {
                availableEquipments.push(CLASSNAME_EQUIPMENT_BUILTIN_SINK);
            }
        }
        if (this.service.getHobMinWidth(this.getTechnologMapFacadeId()) <= this.getWidth()) {
            if (availableEquipments.indexOf(CLASSNAME_EQUIPMENT_HOB) === -1) {
                availableEquipments.push(CLASSNAME_EQUIPMENT_HOB);
            }
        }

        return this.filterDisableEquipments(availableEquipments);
    }

    public defaultYPosition(): number {
        return this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    protected setSizesPosition(levelBoxes?: TLevelBoxes) {
        let size: ThreeSize;

        if (!levelBoxes) {
            return;
        }
        for (size of this.sizes) {
            size.setPositionFromLevelBox(levelBoxes.bottom.max.y - this.view3d.position.y);
        }
    }

    protected createLegs() {
        this.saveData.legs = this.calculateInitLegsData(this.saveData.legs);
        if (!this.saveData.legs) {
            return;
        }
        let legData: ISaveLegData;
        let leg: ThreeLeg;
        let box: Box3 = new Box3();

        for (legData of this.saveData.legs) {
            switch (this.saveData.legType) {
                case LEG_SMALL:
                    leg = new ThreeLegSmall(CommonHelper.deepCopy(legData), this);
                    break;
                case LEG_NORMAL:
                default:
                    leg = new ThreeLegNormal(CommonHelper.deepCopy(legData), this);
            }
            leg.initState();
            leg.createView();
            this.view3d.add(leg.view3d);
            box.setFromObject(leg.view3d);
            this.legs.push(leg);
            this.addCoverPoints([this.view3d.worldToLocal(box.min), this.view3d.worldToLocal(box.max)]);
        }
    }

    protected calculateInitLegsData(legs?: ISaveLegData[]): ISaveLegData[] | undefined {
        return legs;
    }
}