import React from "react";
import Trash from "../../icons/Trash";
import { cnFormMaterial, INewMaterialData } from "../FormMaterial";

function EnableFacade({
  facade,
  index,
  setNewMaterial,
  newMaterial,
  value,
  setError
}: {
  facade: string;
  index: number;
  setNewMaterial: (value: INewMaterialData) => void;
  newMaterial: INewMaterialData;
  value: "enableFacades" | "disableFacades";
  setError: (value: string) => void
}) {
  const deleteFacade = () =>
  {
    setNewMaterial({
      ...newMaterial,
      [value]: newMaterial?.[value]?.filter(
        (el: string, i: number) => i !== index
      ),
    });
    setError("");
  };

  return (
    <div className={cnFormMaterial("Children")}>
      <span>{facade}</span>
      <div className={cnFormMaterial("Btn")}>
        <Trash handleDeleteValue={deleteFacade} />
      </div>
    </div>
  );
}

export default EnableFacade;
