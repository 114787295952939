import React, {ChangeEvent, ReactNode} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './Tumbler.css';
import {Tumbler as TumblerBase} from '@yandex/ui/Tumbler/desktop/bundle';

export const cnTumbler = cn('TumblerUI');

export interface ITumblerProps extends IClassNameProps {
    checked: boolean;
    callBack: (event: ChangeEvent<HTMLInputElement>) => void;
    label?: ReactNode;
    name?: string;
    size?: "s" | "m" | "l" | undefined;
}

export const Tumbler: React.FC<ITumblerProps> =
    ({
         className,
         checked,
         callBack,
         label,
         name,
         size
    }) => {
    return (
        <div className={cnTumbler({size: size}, [className])}>
            <TumblerBase
                size={size || 'm'}
                view={'default'}
                checked={checked}
                onChange={callBack}
                labelAfter={label}
                name={name}
            />
        </div>
    );
};