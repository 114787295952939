import {ThreeBuiltInEquipment} from '../ThreeBuiltInEquipment';
import {Box3, Mesh, Object3D, Vector3} from 'three';
import {ThreeTabletop} from '../../../details/ThreeTabletop/ThreeTabletop';

export class ThreeSeparateSinkEquipment extends ThreeBuiltInEquipment {

    public createView(isRebuild?: boolean) {
        super.createView(isRebuild);
        this.hideTabletops();
    }

    public remove() {
        super.remove();
        this.showTabletops();
    }

    public hideTabletops() {
        let tabletop: ThreeTabletop;

        if (this.unit.tabletops && this.unit.tabletops.length > 0) {
            for (tabletop of this.unit.tabletops) {
                tabletop.setVisible(false);
            }
        }
    }

    public showTabletops() {
        let tabletop: ThreeTabletop;

        if (this.unit.tabletops && this.unit.tabletops.length > 0) {
            for (tabletop of this.unit.tabletops) {
                tabletop.setVisible(true);
            }
        }
    }

    public setLoadModel(type: string, details: Object3D[]) {
        super.setLoadModel(type, details);
        let child: Object3D;

        for (child of this.threeModel.children) {
            if (child.name === 'hole' && child instanceof Mesh) {
                child.material.colorWrite = false;
                child.renderOrder = 1;
            }
        }
    }

    protected initPosition(): Vector3 {
        let cover: Box3;
        let initPosition: Vector3;

        initPosition = new Vector3();

        cover = this.unit.getCoverBox()
        // правильное позиционирование относительно экстремума Bounding Box
        initPosition.y = cover.max.y - 38;
        initPosition.z -= 40;

        return initPosition;
    }
}