import React, { useEffect } from "react";
import { cnFormFacade, INewFacadeData } from "../FormFacade";
import { Tumbler } from "../../../../components/Tumbler/Tumbler";
import { Select } from "../../../../components/Select/Select";
import { editOption } from "../../utils/editOption";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { initialHandle } from "./initialHandle";
import { IFacadeHandleData } from "../../../../../../common-code/interfaces/materials/IFacadeHandleData";
import { transliterate } from "../../utils/transliterate";
import { IAlign } from "common-code/lib/interfaces/geometry/IAlign";
import { IDetailPosition } from "common-code/lib/interfaces/geometry/IDetailPosition";
import { THandleType } from "common-code/lib/types/THandleType";
import { IDetailRotation } from "../../../../../../common-code/interfaces/geometry/IDetailRotation";

export const alignValues = {
  left: "Слева",
  right: "Справа",
  top: "Сверху",
  bottom: "Снизу",
  center: "По центру",
};

export const locations: { [key: string]: string } = {
  horizontal: "Горизонтальная",
  vertical: "Вертикальная",
};

function Handle({
  facadeNew,
  setFacadeNew,
}: {
  facadeNew: INewFacadeData;
  setFacadeNew: React.Dispatch<React.SetStateAction<INewFacadeData>>;
}) {
  const [handleNew, setHandleNew] = React.useState(
    initialHandle(facadeNew.handle)
  );

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHandleNew({ ...handleNew, [e.target.name]: e.target.checked });
  };

  const onInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setHandleNew({ ...handleNew, [e.target.name]: e.target.value });
  };

  const {
    isHandle,
    location,
    handleAlignX,
    handleAlignY,
    handleAlign,
    handleMargin,
    handleMarginX,
    handleMarginY,
    handleMarginZ,
    handlePosition,
    handlePositionX,
    handlePositionY,
    handlePositionZ,
    handleRotation,
    handleRotationX,
    handleRotationY,
    handleRotationZ,
    handleHorizontalMove,
    handleCalculate,
  } = handleNew;

  const onSubmit = () => {
    const handle = {
      id: facadeNew.id ? facadeNew.id : transliterate(facadeNew.title),
    } as IFacadeHandleData;

    if (location) {
      handle.location = location as THandleType;
    }

    if (handleAlign) {
      handle.align = {
        x: handleAlignX,
        y: handleAlignY,
      } as IAlign;
    }

    if (handleMargin) {
      handle.margin = {
        x: handleMarginX,
        y: handleMarginY,
        z: handleMarginZ,
      } as IDetailPosition;
    }

    if (handlePosition) {
      handle.position = {
        x: handlePositionX,
        y: handlePositionY,
        z: handlePositionZ,
      } as IDetailPosition;
    }

    if (handleRotation) {
      handle.rotation = {
        x: handleRotationX,
        y: handleRotationY,
        z: handleRotationZ,
      } as IDetailRotation;
    }

    if(handleHorizontalMove) {
      handle.horizontalMove = handleHorizontalMove;
    }

    if(handleCalculate){
      handle.calculate = handleCalculate;
    }

    setFacadeNew({ ...facadeNew, handle });
  };

  useEffect(() => {
    if (isHandle) {
      onSubmit();
    } else {
      setFacadeNew({...facadeNew, handle: undefined});
    }
    // eslint-disable-next-line
  }, [handleNew]);

  return (
    <>
      <Tumbler
        className={cnFormFacade("Tumbler")}
        checked={isHandle}
        callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
        label="Добавить ручку"
        name="isHandle"
      />

      {isHandle && (
        <div className={cnFormFacade("Modal-Content")}>
          <h4 className={cnFormFacade("Title")}>Ручка</h4>

          <div className={cnFormFacade("Box-Input")}>
            <label htmlFor="location">Ор-я в пространстве</label>
            <Select
              className={cnFormFacade("Select")}
              placeholder={"Выберите значение"}
              showPlaceholder={false}
              options={editOption(locations)}
              value={location || "horizontal"}
              callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                onInputChange({
                  ...e,
                  target: { ...e.target, name: "location" },
                })
              }
            />
          </div>

          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={handleAlign}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Добавить выравнивание для ручки"
            name="handleAlign"
          />

          {handleAlign && (
            <>
              <div>
                <label htmlFor="handleAlignX">По горизонтали</label>
                <Select
                  className={cnFormFacade("Select")}
                  placeholder={"Выберите значение"}
                  showPlaceholder={false}
                  options={editOption(alignValues)}
                  value={handleAlignX || "center"}
                  callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    onInputChange({
                      ...e,
                      target: { ...e.target, name: "handleAlignX" },
                    })
                  }
                />
              </div>
              <div>
                <label htmlFor="handleAlignY">По вертикали</label>
                <Select
                  className={cnFormFacade("Select")}
                  placeholder={"Выберите значение"}
                  showPlaceholder={false}
                  options={editOption(alignValues)}
                  value={handleAlignY || "center"}
                  callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    onInputChange({
                      ...e,
                      target: { ...e.target, name: "handleAlignY" },
                    })
                  }
                />
              </div>
            </>
          )}

          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={handleMargin}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Добавить отступы для ручки"
            name="handleMargin"
          />

          {handleMargin && (
            <div className={cnFormFacade("Box-Input")}>
              <div>
                <label htmlFor="handleMarginX">Отступ по горизонтали</label>
                <Textinput
                  className={cnFormFacade("Textinput")}
                  value={handleMarginX}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onInputChange(e)
                  }
                  name="handleMarginX"
                  type="number"
                />
              </div>
              <div>
                <label htmlFor="handleMarginY">Отступ по вертикали</label>
                <Textinput
                  className={cnFormFacade("Textinput")}
                  value={handleMarginY}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onInputChange(e)
                  }
                  name="handleMarginY"
                  type="number"
                />
              </div>
              <div>
                <label htmlFor="handleMarginZ">Отступ в глубину</label>
                <Textinput
                  className={cnFormFacade("Textinput")}
                  value={handleMarginZ}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onInputChange(e)
                  }
                  name="handleMarginZ"
                  type="number"
                />
              </div>
            </div>
          )}

          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={handlePosition}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Добавить позиционирование для ручки"
            name="handlePosition"
          />

          {handlePosition && (
            <div className={cnFormFacade("Box-Input")}>
              <div>
                <label htmlFor="handlePositionX">
                  Позиционирование по горизонтали
                </label>
                <Textinput
                  className={cnFormFacade("Textinput")}
                  value={handlePositionX}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onInputChange(e)
                  }
                  name="handlePositionX"
                  type="number"
                />
              </div>
              <div>
                <label htmlFor="handlePositionY">
                  Позиционирование по вертикали
                </label>
                <Textinput
                  className={cnFormFacade("Textinput")}
                  value={handlePositionY}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onInputChange(e)
                  }
                  name="handlePositionY"
                  type="number"
                />
              </div>
              <div>
                <label htmlFor="handlePositionZ">
                  Позиционирование в глубину
                </label>
                <Textinput
                  className={cnFormFacade("Textinput")}
                  value={handlePositionZ}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onInputChange(e)
                  }
                  name="handlePositionZ"
                  type="number"
                />
              </div>
            </div>
          )}

          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={handleRotation}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Добавить поворот для ручки"
            name="handleRotation"
          />

          {handleRotation && (
            <div className={cnFormFacade("Box-Input")}>
              <div>
                <label htmlFor="handleRotationX">
                  Позиционирование по горизонтали
                </label>
                <Textinput
                  className={cnFormFacade("Textinput")}
                  value={handleRotationX}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onInputChange(e)
                  }
                  name="handleRotationX"
                  type="number"
                />
              </div>
              <div>
                <label htmlFor="handleRotationY">
                  Позиционирование по вертикали
                </label>
                <Textinput
                  className={cnFormFacade("Textinput")}
                  value={handleRotationY}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onInputChange(e)
                  }
                  name="handleRotationY"
                  type="number"
                />
              </div>
              <div>
                <label htmlFor="handleRotationZ">
                  Позиционирование в глубину
                </label>
                <Textinput
                  className={cnFormFacade("Textinput")}
                  value={handleRotationZ}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onInputChange(e)
                  }
                  name="handleRotationZ"
                  type="number"
                />
              </div>
            </div>
          )}

          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={handleHorizontalMove}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Горизонтальное перемещение для ручки"
            name="handleHorizontalMove"
          />

          <Tumbler
            className={cnFormFacade("Tumbler")}
            checked={handleCalculate}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Рассчитать координаты для ручки"
            name="handleCalculate"
          />

        </div>
      )}
    </>
  );
}

export default Handle;
