import React from 'react'
import { cnCreateForm } from '../../../FormModule';
import Trash from '../../../../icons/Trash';
import Edit from '../../../../icons/Edit';
import ModalWidths from './ModalWidths';
import { TSelectItem } from 'common-code/lib/types/TSelectItem';

interface IWidthProps {
    width:TSelectItem, 
    arrWidths: {id: string, title: string}[], 
    setArrWidths: React.Dispatch<React.SetStateAction<{id: string, title: string}[]>>,
    validateWidth: (value: string) => string 
}

function Width({
        width, 
        arrWidths, 
        setArrWidths,
        validateWidth
    }:IWidthProps) {

    const [visible, setVisible] = React.useState<boolean>(false);
    const [widtValue, setWidtValue] = React.useState<string>(width?.id);
    const [stateError, setStateError] = React.useState<string>('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWidtValue(e.target.value);
    }

    const handleDeleteWidth = () => {
        if (!width) return;
        setArrWidths(arrWidths.filter(el => el.id !== width.id))
    }
    const handleToggleModal = () => setVisible((prev) => !prev);

    const onFocused = () => {
        setStateError('');
    }

    const handleEditWidth = () => {
        const error = validateWidth(widtValue);
        if (error) {
            setStateError(error);
            return;
        }
        const widthEdit  = {
            id: widtValue,
            title: `${widtValue} мм` 
        };
        
        setArrWidths(arrWidths.map((el) => el.id === width?.id ? widthEdit : el));
        setVisible(false);
  }

  return (
    <>
        <div className={cnCreateForm('Children')}>
          <span>Ширина: {width?.title}</span>

          <div className={cnCreateForm('Box-Btn')}>
                <Edit handleToggleModal={handleToggleModal} />
                <Trash handleDeleteValue={handleDeleteWidth} />
          </div>
        </div>

       <ModalWidths
            visible={visible}
            handleToggleModal={handleToggleModal}
            widtValue={widtValue}
            handleChange={handleChange}
            stateError={stateError}
            onFocused={onFocused}
            handleEditWidth={handleEditWidth}
            keyValue={`#editWidth-${width?.id}`}
        />
    </>
  )
}

export default Width