import {ThreeCorpus} from "../ThreeCorpus";
import {ThreeKUnit} from "../../../units/ThreeKUnit/ThreeKUnit";
import {ThreeShelf} from "../../ThreeShelf/ThreeShelf";
import {BoxGeometry, ExtrudeGeometry, Mesh, Shape, Vector3} from "three";
import {ExtrudeGeometryOptions} from "three/src/geometries/ExtrudeGeometry";
import {
    ISaveTopAngleDoubleCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopAngleDoubleCorpusData';
import {ISaveShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfData';
import {CommonHelper} from 'common-code';

export class ThreeTopAngleDoubleCorpus extends ThreeCorpus {
    saveData: ISaveTopAngleDoubleCorpusData
    anotherShape: Shape;

    constructor(options: ISaveTopAngleDoubleCorpusData, unit: ThreeKUnit) {
        super(options, unit);
        this.saveData = options;
        this.anotherShape = new Shape();
    }

    public getSideDepth(): number {
        return +this.saveData.sideDepth;
    }

    protected createShape() {
        this.shape = new Shape();
        this.shape.moveTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            this.getDepth() / 2 - this.getThickness()
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            -this.getDepth() / 2
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            -this.getDepth() / 2
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            this.getDepth() / 2 - this.getThickness()
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            this.getDepth() / 2 - this.getThickness()
        );
        this.anotherShape = new Shape();
        this.anotherShape.moveTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            -this.getDepth() / 2 + this.getSideDepth()
        );
        this.anotherShape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            -this.getDepth() / 2
        );
        this.anotherShape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2
        );
        this.anotherShape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getSideDepth()
        );
        this.anotherShape.moveTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            -this.getDepth() / 2 + this.getSideDepth()
        );
    }

    protected createShelves() {
        let shape: Shape;
        let anotherShape: Shape;
        let shelfData: ISaveShelfData;
        let shelf: ThreeShelf;
        let anotherShelf: ThreeShelf;

        shape = new Shape();
        shape.moveTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            this.getDepth() / 2 - this.getThickness()
        );
        shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            -this.getDepth() / 2 + this.getThickness()
        );
        shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            -this.getDepth() / 2 + this.getThickness()
        );
        shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            this.getDepth() / 2 - this.getThickness()
        );
        shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            this.getDepth() / 2 - this.getThickness()
        );
        anotherShape = new Shape();
        anotherShape.moveTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            -this.getDepth() / 2 + this.getSideDepth()
        );
        anotherShape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            -this.getDepth() / 2 + this.getThickness()
        );
        anotherShape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getThickness()
        );
        anotherShape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getSideDepth()
        );
        anotherShape.moveTo(
            -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth(),
            -this.getDepth() / 2 + this.getSideDepth()
        );
        for (shelfData of this.saveData.shelves) {
            shelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this, shape);
            shelf.initState();
            shelf.createView();
            this.shelves.push(shelf);
            anotherShelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this, anotherShape);
            anotherShelf.initState();
            anotherShelf.createView();
            this.shelves.push(anotherShelf);
        }
    }

    protected createShapePanels() {
        let bottom: Mesh;
        let top: Mesh;
        let anotherBottom: Mesh;
        let anotherTop: Mesh;

        bottom = this.createShapePanel();
        bottom.position.y = -this.getHeight() / 2 + this.getThickness() / 2;
        bottom.name = 'bottom';
        this.view3d.add(bottom);
        top = this.createShapePanel();
        top.position.y = this.getHeight() / 2 - this.getThickness() / 2;
        top.name = 'top';
        this.view3d.add(top);
        anotherBottom = this.createAnotherShapePanel();
        anotherBottom.position.y = -this.getHeight() / 2 + this.getThickness() / 2;
        anotherBottom.name = 'anotherBottom';
        this.view3d.add(anotherBottom);
        anotherTop = this.createAnotherShapePanel();
        anotherTop.position.y = this.getHeight() / 2 - this.getThickness() / 2;
        anotherTop.name = 'anotherTop';
        this.view3d.add(anotherTop);
    }

    protected createAnotherShapePanel(extrudeSettings?: ExtrudeGeometryOptions): Mesh {
        let panel;
        let geometry;

        extrudeSettings = extrudeSettings || {
            steps: 1,
            depth: this.getThickness(),
            bevelEnabled: false
        };
        geometry = new ExtrudeGeometry(this.anotherShape, extrudeSettings);
        panel = new Mesh(geometry, this.getShapeBodyMaterial());
        panel.castShadow = true;
        panel.matrixAutoUpdate = false;
        panel.position.y = this.getThickness() / 2;
        panel.rotation.x = 0.5 * Math.PI;
        panel.updateMatrix();
        panel.geometry.applyMatrix4(panel.matrix);
        panel.rotation.x = 0;
        panel.position.y = 0;
        this.panels.push(panel);

        return panel;
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getSideDepth(), this.getHeight(), this.getThickness()),
            'front',
            new Vector3(
                -this.getWidth() / 2 + this.getBackThickness() + this.getSideDepth() / 2,
                0,
                this.getDepth() / 2 - this.getThickness() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getSideDepth()),
            'right',
            new Vector3(
                this.getWidth() / 2 - this.getThickness() / 2,
                0,
                -this.getDepth() / 2 + this.getSideDepth() / 2
            )
        );
    }

    protected createBackPanels() {
        let width;
        let height;

        width = this.getWidth() - this.getThickness() - this.getBackThickness();
        height = this.getHeight() - this.getThickness() * 2;
        this.createPanel(
            new BoxGeometry(width, height, this.getThickness()),
            'back1',
            new Vector3(
                this.getWidth() / 2 - this.getThickness() - width / 2,
                0,
                -this.getDepth() / 2 + this.getThickness() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getBackThickness(), this.getHeight(), this.getDepth()),
            'back2',
            new Vector3(
                -this.getWidth() / 2 + this.getBackThickness() / 2,
                0,
                0
            )
        );
    }
}