import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, BufferGeometry, Euler, ExtrudeGeometry, Shape, Vector3} from 'three';

export class ThreeBottomFullLengthSidewallSinkCorpus extends ThreeCorpus {
    protected createTriangleGeometry(): BufferGeometry {
        let triangleGeometry;
        let triangleShape = new Shape();
        let triangleExtrudeSettings = {
            steps: 1,
            depth: 4,
            bevelEnabled: false
        };

        triangleShape.moveTo(0, 0);
        triangleShape.lineTo(240, 0);
        triangleShape.lineTo(0, 300);
        triangleShape.lineTo(0, 0);

        triangleGeometry = new ExtrudeGeometry([triangleShape], triangleExtrudeSettings);

        return triangleGeometry;
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, 64, this.getThickness()),
            'front',
            new Vector3(
                0,
                this.getHeight() / 2 - 64 / 2,
                this.getDepth()/2 - this.getThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, 64, this.getThickness()),
            'back',
            new Vector3(
                0,
                150,
                -this.getDepth()/2 + this.getThickness()/2
            )
        );
        this.createPanel(this.createTriangleGeometry(),
            'backThickness1',
            new Vector3(
                -this.getWidth()/2,
                -this.getHeight()/2 + 90,
                -this.getDepth()/2
            )
        );
        this.createPanel(this.createTriangleGeometry(),
            'backThickness2',
            new Vector3(
                this.getWidth()/2,
                -this.getHeight()/2 + 90,
                -this.getDepth()/2 + this.getBackThickness()
            ),
            new Euler(
                0,
                Math.PI,
                0
            )
        );
    }

    protected createBackPanels() {
    }
}