import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';
import {ThreeKUnit} from '../../../units/ThreeKUnit/ThreeKUnit';
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import {ISaveCorpusBarPData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusBarPData';

export class ThreeBottomBarPCorpus extends ThreeCorpus {
    saveData: ISaveCorpusBarPData;

    constructor(options: ISaveCorpusBarPData, unit: ThreeKUnit) {
        super(options, unit);
        this.saveData = options;
    }

    public getDeskDepth(): number {
        return this.saveData.deskDepth;
    }

    public getBackThickness() {
        return + this.saveData.thickness;
    }

    protected createPanels() {
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                this.createSideCorpus(-1);
                break;
            case SIDE_TYPE_RIGHT:
                this.createSideCorpus(1);
                break;
        }
    }

    protected createSideCorpus(direction: number) {

        this.createPanel(
            new BoxGeometry(this.getDeskDepth(), this.getHeight() - this.getThickness(), this.getThickness()),
            'back',
            new Vector3(
                direction * this.getDepth()/2 - direction * this.getDeskDepth()/2,
                -this.getThickness() / 2,
                -this.getWidth()/2 + this.getThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getDeskDepth(), this.getHeight() - this.getThickness(), this.getThickness()),
            'front',
            new Vector3(
                direction * this.getDepth()/2 - direction * this.getDeskDepth()/2,
                -this.getThickness() / 2,
                this.getWidth()/2 - this.getThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getDeskDepth(), this.getThickness(), this.getWidth()),
            'top',
            new Vector3(
                direction * this.getDepth()/2 - direction * this.getDeskDepth()/2,
                this.getHeight() / 2 - this.getThickness() / 2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getWidth() - this.getThickness() * 2),
            'connect',
            new Vector3(
                direction * this.getDepth()/2 - direction * this.getDeskDepth() + direction * this.getThickness()/2,
                0,
                0
            )
        );
    }

    protected createBackPanels() {

    }

    protected addCoverPointsToUnit() {
        let coverPoints: Vector3[];

        coverPoints = [
            new Vector3(-this.getDepth()/2, -this.getHeight()/2, -this.getWidth()/2),
            new Vector3(this.getDepth()/2, this.getHeight()/2, this.getWidth()/2),
        ];
        this.unit.addCoverPoints(coverPoints);
        this.addCoverPoints(coverPoints);
    }
}