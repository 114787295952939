import {IHeightItemGenerator} from '../interfaces/IHeightItemGenerator';

export default function useHeightItemGenerator() {
    return (data: IHeightItemGenerator) => {
        if (!data.element) {
            return;
        }
        if (!data.active || data.disableAutoHeight) {
            data.element.style.setProperty('height', `${data.defaultHeight}px`);
            return;
        }

        let childNode: ChildNode;
        let heightChildNodes: number = 0;
        let countGaps: number = 0;

        for (childNode of Array.from(data.element.childNodes)) {
            if (childNode instanceof HTMLElement && childNode.clientHeight !== 0) {
                heightChildNodes += childNode.clientHeight;
                countGaps += 1;
            }
        }
        data.element.style.setProperty('height', `${heightChildNodes + ((countGaps - 1) * data.rowsGap) + data.padding + data.borderWidth}px`);
    }
}