import {Reducer} from "redux";
import {CHANGE_CONFIG} from '../../constants';
import {IAppConfig} from '../../../common-code/interfaces/config/IAppConfig';

export const ConfigReducer: Reducer = (state: IAppConfig | null = null, action) => {
    switch (action.type) {
        case CHANGE_CONFIG: {
            if (!action.payload) {
                return null;
            }
            return {...action.payload};
        }
        default: {
            return state;
        }
    }
};