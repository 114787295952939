import { useFormContext } from 'react-hook-form'
import { OPTION_TYPE_JSON } from 'common-code/lib/constants'
import React, { useEffect, useState } from 'react'
import { ISaveIntegratedHandleData } from 'common-code/lib/interfaces/saveData/ISaveIntegratedHandleData'
import { IOption } from 'common-code/lib/interfaces/option/IOption'
import { IOptionHiddenJson } from '../../../../../../../common-code/interfaces/option/IOptionHiddenJson'
import IntegratedHandle from './IntegratedHandle'
import { Button } from '../../../../../components/Button'
import ModalIntegratedHandles from './ModalIntegratedHandles'
import { cn } from '@bem-react/classname'
import {
    createIntegratedHandle,
    IIntegratedHandle,
    initionalIntegratedHandle,
    validateIntegratedHandle,
} from './initionalIntegratedHandle'
import { IFormInput } from '../../initialValue'
import './IntegratedHandles.css'

export const cnIntegratedHandles = cn('IntegratedHandles')

export function IntegratedHandles() {
    const { setValue, getValues } = useFormContext<IFormInput>()
    const integratedHandlesInitional = getValues('integratedHandles')
        ? (getValues('integratedHandles') as IOption).value
        : []
    const [arrIntegratedHandles, setArrIntegratedHandles] = React.useState<
        ISaveIntegratedHandleData[]
    >(integratedHandlesInitional)

    const [modal, setModal] = useState<boolean>(false)

    const integratedHandles: IOption = {
        id: 'integratedHandles',
        type: OPTION_TYPE_JSON,
        title: 'Интегрированные ручки',
        sort: 0,
        value: arrIntegratedHandles,
    } as IOptionHiddenJson

    useEffect(() => {
        if (arrIntegratedHandles.length > 0) {
            setValue('integratedHandles', integratedHandles)
        } else {
            setValue('integratedHandles', null)
        }
        // eslint-disable-next-line
    }, [integratedHandles])

    const [newIntegratedHandle, setNewIntegratedHandle] =
        useState<IIntegratedHandle>(initionalIntegratedHandle(null))

    const handleSubmit = () => {
        const error = validateIntegratedHandle(newIntegratedHandle)
        if (error) {
            setNewIntegratedHandle({ ...newIntegratedHandle, error })
            return
        }
        const handleNew = createIntegratedHandle(newIntegratedHandle)
        setArrIntegratedHandles([...arrIntegratedHandles, handleNew])
        setModal(!modal)
    }

    const handleToggleModal = () => {
        setModal(!modal)
    }

    return (
        <>
            <h4 className={cnIntegratedHandles('Header')}>
                Интегрированные ручки
            </h4>
            {arrIntegratedHandles.map((handle, index) => (
                <IntegratedHandle
                    arrIntegratedHandles={arrIntegratedHandles}
                    setArrIntegratedHandles={setArrIntegratedHandles}
                    handle={handle}
                    key={
                        index +
                        handle.functionalType +
                        handle.width +
                        handle.height +
                        handle.interval?.pointA?.x +
                        handle.interval?.pointA?.y
                    }
                    index={index}
                />
            ))}

            <Button
                className={cnIntegratedHandles('Button')}
                type={'button'}
                text={`Добавить интегрированные ручки`}
                leftIcon={{
                    path: 'plus2',
                    width: 16,
                    height: 16,
                    marginRight: 8,
                }}
                mods={{
                    mods: {
                        bkg: 'white',
                        color: 'lightGreen',
                        border: 'lightGreen',
                        fill: 'lightGreen',
                    },
                    hover: {
                        mods: {
                            bkg: 'lightGreen',
                            color: 'white',
                            fill: 'white',
                        },
                    },
                }}
                callBack={handleToggleModal}
            />

            <ModalIntegratedHandles
                modal={modal}
                handleToggleModal={handleToggleModal}
                newIntegratedHandle={newIntegratedHandle}
                setNewIntegratedHandle={setNewIntegratedHandle}
                handleSubmit={handleSubmit}
                keyValue={`#IntegratedHandle`}
            />
        </>
    )
}
export default IntegratedHandles
