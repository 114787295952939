import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form';
import { OPTION_TYPE_RADIOBUTTON } from "common-code/lib/constants";
import Width from './Width';
import { Button } from '../../../../../../components/Button';
import { cnCreateForm } from '../../../FormModule';
import SelectForm from '../../../components/SelectForm';
import { IOption } from 'common-code/lib/interfaces/option/IOption';
import { IOptionRadioButton } from '../../../../../../../../common-code/interfaces/option/IOptionRadioButton';
import { TSelectItem } from 'common-code/lib/types/TSelectItem';
import ModalWidths from './ModalWidths';
import { IOptionHiddenNumber } from '../../../../../../../../common-code/interfaces/option/IOptionHiddenNumber';
import { OPTION_TYPE_HIDDEN_NUMBER } from 'common-code/lib/constants';
import { IFormInput } from '../../../initialValue';
import { widthsValues } from '../../../constants/constants';
import { IOptionSelect } from '../../../../../../../../common-code/interfaces/option/IOptionSelect';

function Widths() {

    const { getValues, setValue, formState: { errors }, setError,clearErrors } = useFormContext<IFormInput>();
    
    // Дефолтные значения ширины для редактируемых модулей
    const widthOnce = getValues("widthValue") ? [ {id:getValues("widthValue")?.toString(), title: getValues("widthValue")?.toString() + " мм" }]: null;
    const editsWidthsVal = getValues("width") ? (getValues("width") as IOptionSelect)?.items : widthOnce;

    const [widths, setWidths] = useState<TSelectItem[]>(editsWidthsVal ? (editsWidthsVal as TSelectItem[]) : widthsValues);
    
    const [value, setValueState] = useState('');
    const [stateError, setStateError] = useState('');
    const [visible, setVisible] = useState(false);

    const width : IOption | null  = widths.length > 0 ? 
    widths.length === 1 ? 
    {
        id: 'width',
        value: Number(widths[0].id),
        sort: 0,
        title: 'Ширина корпуса',
        type: OPTION_TYPE_HIDDEN_NUMBER,
    } as IOptionHiddenNumber
    :
    {
        id: "width",
        defaultValue: widths.find(el => el.id === getValues("defaultWidth")) ? getValues("defaultWidth") : widths[0].id,
        type: OPTION_TYPE_RADIOBUTTON,
        items: widths.sort((a, b) => Number(a.id) - Number(b.id)),
        sort: 0,
        title: "Ширина корпуса",
    }  as IOptionRadioButton
    : null;

    React.useEffect(() => {
        if(widths.length > 0) {
            clearErrors("width")
            setValue("width", width);
            setValue("defaultWidth", width?.defaultValue);
        } else {
            setError("width", {
                type: "required"
            })
        }

    // eslint-disable-next-line
    }, [widths]);

    const validateWidth = (value: string) => {
        if (!value) {
            return "Необходимо указать ширину";
        }
        if (widths.some(el => el.id === value)) {
            return "Такая ширина уже существует";
        }
        if (Number(value) < 50 || Number(value) > 2000) {
            return "Значение должно быть в диапазоне от 200 до 1200";
        }
        return "";
    }

    const handleSubmit = () => {
        const error = validateWidth(value);
        if (error) {
            setStateError(error);
            return;
        }
        const widthNew = {
            id: value,
            title: `${value} мм`
        };

        setWidths([...widths, widthNew]);
        setVisible(false);
        setValueState('');
    };

    const handleToggle = () => {
        setVisible(!visible);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValueState(e.target.value);
        setStateError('');
    }

    return (
        <>
            <label>Ширина корпуса *</label>

            {widths.map((width) => (
                <Width 
                    key={width.id} 
                    width={width} 
                    arrWidths={widths} 
                    setArrWidths={setWidths} 
                    validateWidth={validateWidth}
                />
            ))}

            {errors?.["width"]?.type === "required" && <p className='text-danger' role="alert">Не указана ширина корпуса</p>} 

            {widths.length > 1 && 
                <SelectForm  
                    options={widths.map(el => ({ value: el.id, content: el.title }))} 
                    label="Ширина корпуса по умолчанию *" 
                    name="defaultWidth" 
                />
            }

            <Button
                className={cnCreateForm('Button')}
                type={'button'}
                text={'Добавить ширину'}
                leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                mods={{
                    mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                    hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                }}
                callBack={handleToggle}
            />

            <ModalWidths
                visible={visible}
                handleToggleModal={handleToggle}
                widtValue={value}
                handleChange={handleChange}
                stateError={stateError}
                onFocused={handleSubmit}
                handleEditWidth={handleSubmit}
                keyValue={`#addWidth`}
            />
        </>
    )
}

export default Widths