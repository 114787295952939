import {cn} from '@bem-react/classname';
import React, {ChangeEvent} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {useTranslation} from 'react-i18next';
import "./Accessories.css";
import {useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {ITabletopData} from '../../../../common-code/interfaces/materials/ITabletopData';
import {ICornerData} from '../../../../common-code/interfaces/materials/ICornerData';
import {IApronData} from '../../../../common-code/interfaces/materials/IApronData';
import {IPlinthData} from '../../../../common-code/interfaces/materials/IPlinthData';
import {AccessoriesGroup} from './Group/Accessories-Group';
import {
    OBJECT_SELECT_TYPE_APRON,
    OBJECT_SELECT_TYPE_CORNER,
    OBJECT_SELECT_TYPE_PLINTH,
    OBJECT_SELECT_TYPE_TABLETOP
} from '../../../constants';
import {IMaterialData} from '../../../../common-code/interfaces/materials/IMaterialData';
import {IFacadeMaterialData} from '../../../../common-code/interfaces/materials/IFacadeMaterialData';

export const cnAccessories = cn('Accessories');
export interface IAccessoriesProps extends IClassNameProps {
    service: KitchenService;
}

export const Accessories: React.FC<IAccessoriesProps> =
    ({
         service,
         className
     }) => {
        const {t} = useTranslation();
        const showTabletops: boolean = useSelector((state: AppState) => state.wizard.options.showTabletops);
        const showCorners: boolean = useSelector((state: AppState) => state.wizard.options.showCorners);
        const showAprons: boolean = useSelector((state: AppState) => state.wizard.options.showAprons);
        const showPlinths: boolean = useSelector((state: AppState) => state.wizard.options.showPlinths);
        // const euroZapil: boolean = useSelector((state: AppState) => state.wizard.options.euroZapil);
        const selectTabletop: ITabletopData | undefined = useSelector((state: AppState) => state.wizard.selectTabletop);
        const selectCorner: ICornerData | undefined = useSelector((state: AppState) => state.wizard.selectCorner);
        const selectApron: IApronData | undefined = useSelector((state: AppState) => state.wizard.selectApron);
        const selectPlinth: IPlinthData | undefined = useSelector((state: AppState) => state.wizard.selectPlinth);
        const tabletops: ITabletopData[] | undefined = useSelector((state: AppState) => state.wizard.tabletops);
        const corners: ICornerData[] | undefined = useSelector((state: AppState) => state.wizard.corners);
        const aprons: IApronData[] | undefined = useSelector((state: AppState) => state.wizard.aprons);
        const plinths: IPlinthData[] | undefined = useSelector((state: AppState) => state.wizard.plinths);
        const bottomFacadeMaterial: IFacadeMaterialData | undefined =
            useSelector((state: AppState) => state.wizard.bottomFacadeMaterial);

        // const onChangeEuroZapil = (event: ChangeEvent<HTMLInputElement>) => {
        //     service.setEuroZapil(event.target.checked, true);
        // }
        const onChangeShowTabletops = (event: ChangeEvent<HTMLInputElement>) => {
            service.setShowTabletops(event.target.checked, true);
        }
        const onSelectTabletop = (data: IMaterialData) => {
            service.setTabletop(data as ITabletopData, true);
        }
        const onTabletopChangeDepth = (tabletop: IMaterialData, height: number) => {
            service.setTabletopHeight(tabletop as ITabletopData, height, true);
        }

        const onChangeShowCorners = (event: ChangeEvent<HTMLInputElement>) => {
            service.setShowCorners(event.target.checked, true);
        }
        const onSelectCorner = (data: IMaterialData) => {
            service.setCorner(data as ICornerData, true);
        }

        const onChangeShowAprons = (event: ChangeEvent<HTMLInputElement>) => {
            service.setShowAprons(event.target.checked, true);
        }
        const onSelectApron = (data: IMaterialData) => {
            service.setApron(data as IApronData, true);
        }
        const onApronChangeDepth = (material: IMaterialData, value: number) => {
            service.setApronHeight(material as IApronData, value, true);
        }

        const onChangeShowPlinths = (event: ChangeEvent<HTMLInputElement>) => {
            service.setShowPlinths(event.target.checked, true);
        }
        const onSelectPlinth = (data: IMaterialData) => {
            service.setPlinth(data as IPlinthData);
        }

        return (
            <div className={cnAccessories({}, [className])}>
                <div className={cnAccessories('Title')}>{t('Выбрать комплектующие')}</div>
                <AccessoriesGroup type={OBJECT_SELECT_TYPE_TABLETOP} title={t('Столешница')}
                                  className={cnAccessories('Group')}
                                  checkbox={showTabletops} onChangeCheckbox={onChangeShowTabletops}
                                  service={service} list={tabletops}
                                  selectObject={selectTabletop}
                                  onSelectObject={onSelectTabletop}
                                  onSelectObjectChangeDepth={onTabletopChangeDepth}
                                  facadeMaterials={bottomFacadeMaterial ? [bottomFacadeMaterial] : undefined}
                />
                <AccessoriesGroup type={OBJECT_SELECT_TYPE_CORNER} title={t('Уплотнительный уголок')}
                                  className={cnAccessories('Group')}
                                  checkbox={showCorners} onChangeCheckbox={onChangeShowCorners}
                                  service={service} list={corners}
                                  selectObject={selectCorner}
                                  onSelectObject={onSelectCorner}
                                  facadeMaterials={bottomFacadeMaterial ? [bottomFacadeMaterial] : undefined}
                />
                <AccessoriesGroup type={OBJECT_SELECT_TYPE_APRON} title={t('Стеновая панель')}
                                  className={cnAccessories('Group')}
                                  checkbox={showAprons} onChangeCheckbox={onChangeShowAprons}
                                  service={service} list={aprons}
                                  selectObject={selectApron}
                                  onSelectObject={onSelectApron}
                                  onSelectObjectChangeDepth={onApronChangeDepth}
                                  facadeMaterials={bottomFacadeMaterial ? [bottomFacadeMaterial] : undefined}
                />
                <AccessoriesGroup type={OBJECT_SELECT_TYPE_PLINTH} title={t('Цоколь')}
                                  className={cnAccessories('Group')}
                                  checkbox={showPlinths} onChangeCheckbox={onChangeShowPlinths}
                                  service={service} list={plinths}
                                  selectObject={selectPlinth}
                                  onSelectObject={onSelectPlinth}
                                  facadeMaterials={bottomFacadeMaterial ? [bottomFacadeMaterial] : undefined}
                />
                {/*<div className={cnAccessories('Group')}>*/}
                {/*    <div className={cnAccessories('GroupHeader')}>*/}
                {/*        <Tumbler className={cnAccessories(cnTumbler())}*/}
                {/*                 checked={euroZapil} callBack={onChangeEuroZapil}/>*/}
                {/*        <div className={cnAccessories('GroupTitle')}>{t('Евро запил')}</div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        );
    };