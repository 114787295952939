import {withBemMod} from "@bem-react/core";
import React, {ChangeEvent, useState} from "react";
import {IInputProps} from "../Input";
import {cnInput} from "../index";
import {INPUT_TYPE_TEXTAREA} from "../../../constants";

export interface IInputTextareaProps<HTMLTextAreaElement> extends IInputProps<HTMLTextAreaElement> {
    value?: string;
    name?: string;
    placeholder?: string;
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    disabled?: boolean;
}

const InputTextarea: React.FC<IInputTextareaProps<HTMLTextAreaElement>> = ({className, onChange, value, name, placeholder, disabled}) => {
    const [focus, setFocus] = useState<boolean>(false);

    return (
        <textarea
            className={cnInput({focus: focus}, [className])}
            value={value}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={() => {setFocus(true)}}
            onBlur={() => {setFocus(false)}}
            onChange={onChange}
        />
    );
};

export const withInputTextarea = withBemMod<IInputTextareaProps<HTMLTextAreaElement>>(
    cnInput(),
    { type: INPUT_TYPE_TEXTAREA },
    () => InputTextarea
);