import { IOptionGroup } from "common-code/lib/interfaces/option/IOptionGroup";
import { OPTION_TYPE_HIDDEN_NUMBER } from "common-code/lib/constants";
import { IOptionHiddenNumber } from "../../../../../../../common-code/interfaces/option/IOptionHiddenNumber";
import { IFormInput } from "../../initialValue";

export function getSizes(date: IFormInput): IOptionGroup | null {
const {widthText, heightText,depthText, isSizes} = date;
const sizes = {
    id: 'sizes',
    sort: 0,
    title: 'Размеры',
    isGroup: true,
    options: []
    } as IOptionGroup;
    const width = {
        id: 'width',
        value: Number(widthText),
        type: OPTION_TYPE_HIDDEN_NUMBER,
        sort: 0,
        title: 'Ширина',
    } as IOptionHiddenNumber;
    const height = {
        id: 'height',
        value: Number(heightText),
        type: OPTION_TYPE_HIDDEN_NUMBER,
        sort: 1,
        title: 'Высота',
    } as IOptionHiddenNumber;
    const depth = {
        id: 'depth',
        value: Number(depthText),
        type: OPTION_TYPE_HIDDEN_NUMBER,
        sort: 2,
        title: 'Глубина',
    } as IOptionHiddenNumber;
        
    sizes.options = [width, height, depth].filter(el => el.value);

    return sizes.options.length && isSizes? sizes : null;
}