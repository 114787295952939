import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {useTranslation} from 'react-i18next';
import {cnKitchenParams} from '../KitchenParams';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../redux/AppStore';
import {Tip} from '../../../components/Tip/Tip';
import {TSelectItem} from '../../../../../common-code/types/TSelectItem';
import {RadioButton} from '../../../components/RadioButton/RadioButton';
import {ObjectSelect} from '../../../components/ObjectSelect/ObjectSelect';
import {OBJECT_SELECT_TYPE_CORPUS_MATERIAL} from '../../../../constants';
import {RADIO_BUTTON_TYPE_KIT_CODE} from '../../../../../common-code/constants';
import {IFacadeMaterialData} from '../../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {useProjectFacadeMaterials} from '../../../../hooks/useProjectFacadeMaterials';

export interface IKitchenParamsCommonProps extends IClassNameProps {
    service: KitchenService;
}

export const KitchenParamsCommon: React.FC<IKitchenParamsCommonProps> = ({className, service}) => {
    const {t} = useTranslation();
    const selectCorpusMaterial: IMaterialData | undefined =
        useSelector((state: AppState) => state.wizard.selectCorpusMaterial);
    const corpusMaterials: IMaterialData[] | undefined =
        useSelector((state: AppState) => state.wizard.corpusMaterials);
    const selectKitCode: TSelectItem | undefined = useSelector((state: AppState) => state.wizard.selectKitCode)
    const kitCodes: TSelectItem[] | undefined = useSelector((state: AppState) => state.wizard.kitCodes)
    const facadeMaterials: IFacadeMaterialData[] | undefined = useProjectFacadeMaterials();


    const onSelectKitCode = (value: string) => {
        let selectKitCode: TSelectItem | undefined = kitCodes?.filter(kit => kit.id === value)[0];
        if (selectKitCode) {
            service.setSelectKitCode(selectKitCode);
        }
    }
    const onSelectCorpusMaterial = (material: IMaterialData) => {
        service.setCorpusMaterial(material);

    }
    return (
        <div className={cnKitchenParams('Common', [className])}>
            {kitCodes && selectKitCode ?
                <div className={cnKitchenParams('Item')}>
                    <div className={cnKitchenParams('ItemTitle')}>
                        {t('Комплектация модулей')}
                        <Tip directionIcon={'right'}
                             message={<><p><b>Комплектация модулей</b> - это состав фурнитуры в комплете модуля.</p>
                                 <p><b>Стардарт</b> - комплектация модуля предполагаемая производителем корпуса, включает в
                                     себя недорогую фурнитуру и соответственно стоит дешевле.</p>
                                 <p><b>Комфорт</b> - комплектация модуля включает в себя более дорогую фурнитуру.</p>
                                 <p><b>Люкс</b> - комплектация модуля включает в себя самую догорую фурнитуру. Самое
                                     надежное и комфортное решение.</p></>} className={cnKitchenParams('TitleTip')}/>
                    </div>
                    <RadioButton type={RADIO_BUTTON_TYPE_KIT_CODE}
                                 options={kitCodes}
                                 value={selectKitCode.id}
                                 callBack={onSelectKitCode}/>
                </div> : null}
            {selectCorpusMaterial && corpusMaterials ?
                <div className={cnKitchenParams('Item')}>
                    <div className={cnKitchenParams('ItemTitle')}>
                        {t('Цвет корпуса')}
                        <Tip directionIcon={'right'} message={<p>Вы можете выбрать <b>цвет корпуса</b> для вашего кухонного
                            гарнитура из доступных для данной коллекции.</p>} className={cnKitchenParams('TitleTip')}/>
                    </div>
                    <ObjectSelect
                        type={OBJECT_SELECT_TYPE_CORPUS_MATERIAL}
                        className={cnKitchenParams('CorpusMaterial', {})}
                        data={{type: OBJECT_SELECT_TYPE_CORPUS_MATERIAL, object: selectCorpusMaterial}}
                        list={corpusMaterials}
                        title={t('Цвет корпуса') || ''}
                        onSelectObject={(corpusMaterial: IMaterialData) => {
                            onSelectCorpusMaterial(corpusMaterial);
                        }}
                        service={service}
                        showObject={true}
                        facadeMaterials={facadeMaterials}
                    />
                </div> : null}
        </div>
    );
};