import React, {ChangeEvent, ReactNode} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './Checkbox.css';
import {Checkbox as YandexCheckbox} from '@yandex/ui/Checkbox/desktop/bundle';

export const cnCheckbox = cn('Checkbox');

export interface ICheckboxProps extends IClassNameProps {
    checked: boolean,
    callBack: (event: ChangeEvent<HTMLInputElement>) => void,
    label?: ReactNode,
    name?: string,
}

export const Checkbox: React.FC<ICheckboxProps> = ({className, checked, callBack, name, label}) => {
    return (
        <div className={cnCheckbox({}, [className])}>
            <YandexCheckbox
                size={'m'}
                view={'default'}
                checked={checked}
                onChange={callBack}
                label={label}
                name={name}
            />
        </div>
    );
};