import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React, {ChangeEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IUserData} from '../../../../../common-code/interfaces/IUserData';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../redux/AppStore';
import {IProjectUserData} from '../../../../../common-code/interfaces/project/IProjectUserData';
import {Textinput} from '@yandex/ui/Textinput/desktop/bundle';
import {Button} from '../../../components/Button';
import axios, {AxiosResponse} from 'axios';
import {Popup} from '../../../components/Popup';
import './UserObjectSelect-List.css';
import {ORDER_USER_TYPE_CHOICE} from '../../../../../common-code/constants';

export const cnUserObjectSelectList = cn('UserObjectSelectList')

export interface IUserObjectSelectListProps extends IClassNameProps {
    userData: IProjectUserData;
    setUserData: (userData: IProjectUserData) => void;
    errors?: any;
}

export const UserObjectSelectList: React.FC<IUserObjectSelectListProps> =
    ({
         className,
         userData,
         setUserData,
         errors
    }) => {
    const {t} = useTranslation();
    const currentUser: IUserData = useSelector((state: AppState) => state.user);
    const [searchText, setSearchText] = useState<string>('');
    const [userList, setUserList] = useState<IUserData[]>([]);

    const getUserText = (): string => {
        if (userData.id) {
            if (+currentUser.id === +userData.id) {
                return t('На себя') + ' (' + getUserFullName(userData) + '; ' + t('тел') + ': ' + userData.phone + ')';
            }
            return getUserFullName(userData) + ' (' + t('тел') + ': ' + userData.phone + ')';
        }
        return t('Покупатель не выбран');
    }

    const getUserFullName = (userData: IProjectUserData): string => {
        return [userData.firstName, userData.lastName].join(' ');
    }

    const onSearchUser = (value: string) => {
        setSearchText(value);
        axios.get('/api/user/customers', {params: {search: value}})
            .then((response: AxiosResponse) => {
                if (response.data) {
                    setUserList(response.data);
                }
            })
            .catch(reason => {
                console.error(reason);
            });
    }

    const onSelectUser = (userData: IUserData) => {
        let projectUserData: IProjectUserData;

        projectUserData = {
            type: ORDER_USER_TYPE_CHOICE,
            id: userData.id,
            phone: userData.phone,
            firstName: userData.name,
            lastName: userData.lastName,
            email: userData.email
        };
        setUserData(projectUserData);
        setSearchText('');
        setUserList([]);
    }

    return (
        <div className={cnUserObjectSelectList({}, [className])}>
            <div className={cnUserObjectSelectList('Value')}>
                <div className={cnUserObjectSelectList('Title')}>
                    {t('Выбранный покупатель')}:
                </div>
                <div className={cnUserObjectSelectList('UserText')}>
                    {getUserText()}
                </div>
            </div>
            {
                errors && errors.id ?
                    <div className={cnUserObjectSelectList('Error')}>{errors.id}</div> : null
            }
            <div className={cnUserObjectSelectList('Search')}>
                <Textinput
                    className={cnUserObjectSelectList('SearchText')}
                    placeholder={t('Введите имя или фамилию или телефон')}
                    value={searchText}
                    minLength={3}
                    debounceTimeout={300}
                    onChange={(value: ChangeEvent<HTMLInputElement>) => {
                        onSearchUser(value.target.value);
                    }}
                />
                <Button className={cnUserObjectSelectList('SearchButton')}
                        type={'button'} mods={{mods: {bkg: 'white'}}}
                        leftIcon={{path: 'search2', width: 20, height: 20, fill: 'lightBlack'}}
                        callBack={() => {
                        }}/>
                {!userList.length || <Popup active={!!userList.length}
                                            position={{x: 0, y: 40}}
                                            className={cnUserObjectSelectList('SearchResult')}
                >
                    {userList.map(userItem => {
                        return (
                            <div className={cnUserObjectSelectList('SearchItem')}
                                 key={userItem.id}
                                 onClick={() => {
                                     onSelectUser(userItem)
                                 }}>
                                {userItem.name} ({t('тел')}.: {userItem.phone})
                            </div>
                        );
                    })}
                </Popup>}
            </div>
        </div>
    );
}