import React from "react";
import { IClassNameProps } from "@bem-react/core";
import { useTranslation } from "react-i18next";
import { Button, cnButton } from "../../../components/Button";
import { cn } from "@bem-react/classname";
import { IMaterialData } from "common-code/lib/interfaces/materials/IMaterialData";
import FormFacade, { INewFacadeData } from "../FormFacade/FormFacade";
import { IFacadeData } from "../../../../../common-code/interfaces/materials/IFacadeData";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import { CHANGE_EDIT_MODE, HIDE_LOADING, INIT_EDIT_MATERIAL, SHOW_LOADING } from "../../../../constants";
import { useDispatch } from "react-redux";
import { TEditMode } from "../../../../../common-code/types/TEditMode";
import { TMaterialEditMode } from "../../../../../../../common/types/TMaterialEditMode";
import "./EditFacades.css";

interface IEditFacadesProps extends IClassNameProps {
  saveFacade: (facadeListNew: IMaterialData[], facadeMode: TMaterialEditMode) => Promise<void>;
  saveImagePreview: (imageNew: File, facadeId: string, materialMode: TMaterialEditMode) => Promise<string | null>; 
}

const cnEditFacades = cn("EditFacades");

function EditFacades({
  saveFacade,
  saveImagePreview,
}: IEditFacadesProps) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialValue = useSelector((state: AppState) => state.wizard.editMaterial.itemFacade);
  const [itemFacade, setItemFacade] = React.useState<INewFacadeData | null>(initialValue || null);
  const facades = useSelector((state: AppState) => state.wizard.facades);
  const createFacade = (facade: INewFacadeData) => {
    setItemFacade(facade);
  };

  const handleToggleMode = (str: TEditMode) => {
    dispatch({ type: CHANGE_EDIT_MODE, payload: str});
    dispatch({ type: INIT_EDIT_MATERIAL });
  };

  const saveFacadesCollection = async (itemFacade: INewFacadeData) => {
    // проверяем корректность заполнения формы
    if (!itemFacade) {
      window.alert("Необходимо корректно заполнить форму.");
      return;
    }
    // если такое название уже есть
    if (
      facades.length > 0 &&
      facades.find((facade: IFacadeData) => facade.id === itemFacade.id)
    ) {
      window.alert(
        "Фасад с таким наменованием уже существует в коллекции. Укажите другое название."
      );
      return;
    }
    dispatch({ type: SHOW_LOADING });

    // сохраняем preview материала
    if (itemFacade.imageNew) {
      const imageUrl = await saveImagePreview(
        itemFacade.imageNew,
        itemFacade.id,
        "facades"
      );
      if (!imageUrl) {
        window.alert("Ошибка при сохранении превью. Попробуйте еще раз.");
        dispatch({ type: HIDE_LOADING });
        return;
      }
      itemFacade.image = imageUrl;
      // удаляем клуч imageNew
      delete itemFacade.imageNew;
    }

    // сохраняем материал
    const facadesListNew = [...facades, itemFacade];
    saveFacade(facadesListNew, "facades").then(() => {
      handleToggleMode("");
      dispatch({ type: HIDE_LOADING });
    })
  };

  const editFacadesCollection = async (itemFacade: INewFacadeData) => {
    // проверяем корректность заполнения формы
    if (!itemFacade) {
      window.alert("Необходимо корректно заполнить форму.");
      return;
    }
    // если такое название уже есть
    if (
      facades.length > 0 &&
      !facades.find((facade: IFacadeData) => facade.id === itemFacade.id)
    ) {
      window.alert(
        "Фасад с таким наменованием нет. Укажите другое название."
      );
      return;
    }
    dispatch({ type: SHOW_LOADING });

    // сохраняем preview материала
    if (itemFacade.imageNew) {
      const imageUrl = await saveImagePreview(
        itemFacade.imageNew,
        itemFacade.id,
        "facades"
      );
      if (!imageUrl) {
        window.alert("Ошибка при сохранении превью. Попробуйте еще раз.");
        dispatch({ type: HIDE_LOADING });
        return;
      }
      itemFacade.image = imageUrl;
      // удаляем ключ imageNew
      delete itemFacade.imageNew;
    }

    // сохраняем фасад
    const facadeListNew = facades.map((facade: IFacadeData) => facade.id === itemFacade.id ? itemFacade : facade);
    saveFacade(facadeListNew, "facades").then(() => {
      handleToggleMode("");
      dispatch({ type: HIDE_LOADING });
    })

  };

  return (
    <>
      <FormFacade 
        createFacade={createFacade} 
        itemFacade={itemFacade}
        />

      {itemFacade && (itemFacade.imageNew || itemFacade.image) && (
        <img
          className={cnEditFacades("Image-Facade")}
          src={
            itemFacade.imageNew
              ? URL.createObjectURL(itemFacade.imageNew as File)
              : itemFacade.image
          }
          alt={itemFacade.title}
        />
      )}

      <div className={cnEditFacades("Buttons-Container")}>
        <Button
          className={cnEditFacades(cnButton(), { type: "prev" })}
          leftIcon={{path: "arrow-left",width: 20,height: 20,marginRight: 8,}}
          text={t("Предыдущий шаг") || undefined}
          callBack={() => handleToggleMode("")}
          mods={{mods: {  color: "black",  bkg: "white",  border: "black",  fill: "black",},}}
          type={"button"}
        />
      </div>

      {itemFacade && (
        <>
          <div className={cnEditFacades("Button-Add")}>
            <Button
              className={cnEditFacades(cnButton())}
              type={"button"}
              text={"Добавить новый фасад в коллекцию"}
              leftIcon={{path: "plus2",width: 16,height: 16,marginRight: 8,}}
              mods={{mods: {  bkg: "white",  color: "lightGreen",  border: "lightGreen",  fill: "lightGreen",},
              hover: {  mods: { bkg: "lightGreen", color: "white", fill: "white" },},}}
              callBack={() => saveFacadesCollection(itemFacade)}
            />
            <Button
              className={cnEditFacades(cnButton())}
              type={"button"}
              text={"Заменить редактируемый фасад в коллекции"}
              leftIcon={undefined}
              mods={{ mods: {bkg: "white",color: "lightGreen",border: "lightGreen",fill: "lightGreen", }, 
              hover: {mods: { bkg: "lightGreen", color: "white", fill: "white" }, },
              }}
              callBack={() => editFacadesCollection(itemFacade)}
            />
          </div>
        </>
      )}
    </>
  );
}

export default EditFacades;
