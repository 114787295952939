import {IClassNameProps} from '@bem-react/core';
import React, {useState} from 'react';
import {cnWizard} from '../Wizard';
import {useSelector} from 'react-redux';
import './Wizard-ContextMenu.css'
import {IContextAction} from '../../../../interfaces/IContextAction';
import {IContextMenu} from '../../../../interfaces/IContextMenu';
import {AppState} from '../../../../redux/AppStore';
import {useModsGenerator} from '../../../../hooks/useModsGenerator';
import {cnPopup, Popup} from '../../../components/Popup';
import {Svg} from '../../../components/Svg';

export interface IWizardContextMenuProps extends IClassNameProps {
    contextAction: IContextAction
}

export const WizardContextMenu: React.FC<IWizardContextMenuProps> = ({className, contextAction}) => {
    const kitchenPlanContextMenu: IContextMenu = useSelector((state: AppState) => state.wizard.contextMenu);
    const modsGenerator = useModsGenerator();
    const [hoverIcon, setHoverIcon] = useState<number| null>(null);

    if (!kitchenPlanContextMenu.visible) {
        return null;
    }

    return (
        <Popup
            active={kitchenPlanContextMenu.visible}
            className={cnWizard(cnPopup(), [className, cnWizard('ContextMenuWrapper')])}
            position={{x: kitchenPlanContextMenu.position.x + 30, y: kitchenPlanContextMenu.position.y - 40}}
        >
            {kitchenPlanContextMenu.icons.map((iconData, index) => {
                return (
                    <div
                        key={index}
                        className={cnWizard('ContextMenuItem', {...modsGenerator({
                                hover: {state: hoverIcon === index, mods: {cursor: 'pointer'}}
                            })})}
                        onMouseEnter={() => {setHoverIcon(index)}}
                        onMouseLeave={() => {setHoverIcon(null)}}
                        onClick={() => {contextAction(iconData)}}
                    >
                        <Svg icon={iconData.icon} className={cnWizard('ContextMenuIcon', {...modsGenerator({
                                hover: {state: hoverIcon === index, mods: {fill: 'white'}}
                            })})} label={iconData.title} />
                        <Popup
                            active={index === hoverIcon}
                            position={{x: 40, y: 0}}>
                            {iconData.title}
                        </Popup>
                    </div>
                );
            })}
        </Popup>
    );
};