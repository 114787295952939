import React, {ChangeEvent, useState} from 'react';
import {applySendOrderForm, cnWizardOrderForm, IWizardOrderFormProps} from './index';
import {composeU} from '@bem-react/core';
import {withWizardOrderFormUserManager} from './_user/WizardOrderForm_user_manager';
import {Svg} from '../../components/Svg';
import {useTranslation} from 'react-i18next';
import {cnInput} from '../../components/Input';
import {INPUT_TYPE_PHONE, INPUT_TYPE_TEXT} from '../../constants';
import {Input} from '../../components/Input/Input';
import './WizardOrderForm.css';
import {cnTextarea, Textarea} from '@yandex/ui/Textarea/Textarea@desktop';
import {Checkbox, cnCheckbox} from '../../components/Checkbox';
import {Button, cnButton} from '../../components/Button';
import {IProjectManagerOrderFormData} from '../../../../common-code/interfaces/project/IProjectManagerOrderFormData';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {
    DELIVERY_TYPE_SELF,
    ORDER_RESULT_ACCESS_DENIED,
    ORDER_RESULT_CREATE_CUSTOMER_ERROR,
    ORDER_RESULT_CREATE_ERROR, ORDER_RESULT_NEED_AUTH,
    ORDER_RESULT_NEED_COPY, ORDER_RESULT_NEED_SMS,
    ORDER_RESULT_SUCCESS,
    ORDER_RESULT_UNKNOWN_ERROR,
    ORDER_USER_TYPE_NEW
} from '../../../../common-code/constants';
import {useDispatch, useSelector} from 'react-redux';
import {IUserData} from '../../../../common-code/interfaces/IUserData';
import {AppState} from '../../../redux/AppStore';
import {TLocationData} from '../../../../common-code/types/TLocationData';
import {IProjectOrderResult} from '../../../../common-code/interfaces/project/IProjectOrderResult';
import {ADD_MESSAGE, MESSAGE_TYPE_ERROR} from '../../../constants';
import {TMessage} from '../../../types/TMessage';
import {cnModal, Modal} from '../../components/Modal';
import {cnWizard} from '../Wizard/Wizard';
import {SmsConfirm} from '../SmsConfirm/SmsConfirm';
import {TSmsConfirmResult} from '../../../../common-code/types/TSmsConfirmResult';
import {processLogin} from '../../../helpers';
import {IProjectData} from '../../../../common-code/interfaces/project/IProjectData';

export const WizardOrderFormBase: React.FC<IWizardOrderFormProps> =
    ({
         className,
         service
     }) => {
        const {t} = useTranslation();
        const [formErrors, setFormErrors] = useState<any>({});
        const [userName, setUserName] = useState<string>('');
        const [userPhone, setUserPhone] = useState<string>('');
        const [userComment, setUserComment] = useState<string>('');
        const [checkPersonal, setCheckPersonal] = useState<boolean>(true);
        const currentUser: IUserData = useSelector((state: AppState) => state.user);
        const currentLocation: TLocationData | undefined = useSelector((state: AppState) => state.location.current);
        const dispatch = useDispatch();
        const [projectOrderResult, setProjectOrderResult] =
            useState<IProjectOrderResult | undefined>(undefined);
        const [confirmSms, setConfirmSms] = useState<boolean | undefined>(undefined);
        const projectData: IProjectData = useSelector((state: AppState) => state.wizard.project);

        const afterSuccessSmsConfirm = (data: TSmsConfirmResult) => {
            debugger;
            if (service && data.success && data.phone && data.code) {
                let newFormErrors: any = {};
                processLogin(data.phone, data.code, dispatch, t, newFormErrors).then(() => {
                    debugger;
                    if (data.userId) {
                        service.setProjectOwner(data.userId, projectData.id).then(() => {
                            debugger;
                            trySendOrderForm();
                        })
                    }
                    setFormErrors(newFormErrors);
                }).catch(() => {
                    debugger;
                    newFormErrors.serverError = t('Произошла неизвестная ошибка');
                    setFormErrors(newFormErrors);
                });
            } else {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: {
                        type: MESSAGE_TYPE_ERROR,
                        message: t('Вы ввели неверный код')
                    } as TMessage
                });
            }
        }

        const afterErrorSmsConfirm = () => {
            debugger;
            dispatch({
                type: ADD_MESSAGE,
                payload: {
                    type: MESSAGE_TYPE_ERROR,
                    message: t('Вы ввели неверный код')
                } as TMessage
            });
        }
        const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
            switch (event.target.name) {
                case 'userName':
                    setUserName(event.target.value);
                    break;
                case 'userPhone':
                    setUserPhone(event.target.value);
                    break;
                case 'userComment':
                    setUserComment(event.target.value);
                    break;
            }
        }

        const afterSendOrderForm = (result: IProjectOrderResult) => {
            setProjectOrderResult(result);
            switch (result.statusCode) {
                case ORDER_RESULT_SUCCESS:
                    break;
                case ORDER_RESULT_UNKNOWN_ERROR:
                case ORDER_RESULT_ACCESS_DENIED:
                case ORDER_RESULT_CREATE_CUSTOMER_ERROR:
                case ORDER_RESULT_CREATE_ERROR:
                case ORDER_RESULT_NEED_COPY:
                    dispatch({
                        type: ADD_MESSAGE,
                        payload: {
                            type: MESSAGE_TYPE_ERROR,
                            message: result.message
                        } as TMessage
                    });
                    break;
                case ORDER_RESULT_NEED_AUTH:
                case ORDER_RESULT_NEED_SMS:
                    setConfirmSms(true);
                    break;
            }
        }

        const trySendOrderForm = () => {
            if (!service) {
                return;
            }
            let newFormErrors: any = {};
            applySendOrderForm(
                service,
                getFormData(service),
                dispatch,
                t,
                newFormErrors
            ).then((result: IProjectOrderResult) => {
                afterSendOrderForm(result);
                setFormErrors(newFormErrors);
            }).catch(() => {
                setFormErrors(newFormErrors);
            })
        }

        const getFormData = (service: KitchenService): IProjectManagerOrderFormData => {
            return {
                user: currentUser,
                orderType: "order",
                comment: userComment,
                customer: {
                    type: ORDER_USER_TYPE_NEW,
                    data: {
                        userName: userName,
                        userLastName: '',
                        userPhone: userPhone
                    }
                },
                payment: '',
                delivery: {
                    type: DELIVERY_TYPE_SELF,
                    data: {
                        address: '',
                        floorNumber: 0,
                        isFloor: false,
                        locationId: currentLocation ? currentLocation.id : 0,
                        locationName: currentLocation ? currentLocation.title : '',
                        serviceLift: false,
                    }
                },
                checkStore: true,
                items: service.getProjectFormDataItems(),
                checkPersonal: checkPersonal,
            };
        }

        if (projectOrderResult && projectOrderResult.order && projectOrderResult.statusCode === ORDER_RESULT_SUCCESS) {
            <div className={cnWizardOrderForm({}, [className])}>
                <div className={cnWizardOrderForm('Order', {success: true})}>
                    {t('Ваш предзаказ успешно оформлен')}. {t('Номер заказа')}: {projectOrderResult.order.id}
                </div>
            </div>
        }

        return (
            <div className={cnWizardOrderForm({}, [className])}>
                <div className={cnWizardOrderForm('Field')}>
                    <div className={cnWizardOrderForm('FieldHeader')}>
                        <Svg icon={'man'} className={cnWizardOrderForm('FieldIcon')}/>
                        <div className={cnWizardOrderForm('FieldTitle')}>{t('Ваше имя')} *</div>
                    </div>
                    <Input
                        className={cnWizardOrderForm(cnInput(), {error: !!(formErrors && formErrors.customer && formErrors.customer.firstName)})}
                        type={INPUT_TYPE_TEXT}
                        value={userName}
                        name={'userName'}
                        placeholder={t('Введите Ваше имя') + ' *'}
                        onChange={onChangeInput}
                    />
                    {
                        (formErrors && formErrors.customer && formErrors.customer.firstName) ?
                            <div className={cnWizardOrderForm('Error')}>{formErrors.customer.firstName}</div> : null
                    }
                </div>
                <div className={cnWizardOrderForm('Field')}>
                    <div className={cnWizardOrderForm('FieldHeader')}>
                        <Svg icon={'phone'} className={cnWizardOrderForm('FieldIcon')}/>
                        <div className={cnWizardOrderForm('FieldTitle')}>{t('Ваш телефон')} *</div>
                    </div>
                    <Input
                        className={cnWizardOrderForm(cnInput(), {error: !!((formErrors && formErrors.customer && formErrors.customer.phone))})}
                        type={INPUT_TYPE_PHONE}
                        value={userPhone}
                        name={'userPhone'}
                        placeholder={t('Введите Ваш телефон') + ' *'}
                        onChange={onChangeInput}
                    />
                    {
                        (formErrors && formErrors.customer && formErrors.customer.phone) ?
                            <div className={cnWizardOrderForm('Error')}>{formErrors.customer.phone}</div> : null
                    }
                </div>
                <div className={cnWizardOrderForm('Field', {full: true})}>
                    <div className={cnWizardOrderForm('FieldHeader')}>
                        <Svg icon={'comment'} className={cnWizardOrderForm('FieldIcon')}/>
                        <div className={cnWizardOrderForm('FieldTitle')}>{t('Ваш комментарий к проекту')}</div>
                    </div>
                    <Textarea
                        className={cnWizardOrderForm(cnTextarea({error: !!(formErrors && formErrors['userComment'])}))}
                        value={userComment}
                        name={'userComment'}
                        placeholder={t('Введите Ваш комментарий к проекту')}
                        onChange={onChangeInput}
                    />
                </div>
                <div className={cnWizardOrderForm('Field', {type: 'checkbox'})}>
                    <Checkbox
                        className={cnWizardOrderForm(
                            cnCheckbox(),
                            {error: !!(formErrors && formErrors.checkPersonal)}
                        )}
                        checked={checkPersonal}
                        callBack={(event: ChangeEvent<HTMLInputElement>) => {
                            if (formErrors && formErrors.checkPersonal) {
                                setFormErrors({...formErrors, checkPersonal: undefined});
                            }
                            setCheckPersonal(event.target.checked);
                        }}
                        name={'checkWhatsApp'}
                        label={t('Согласен на обработку моих персональных данных и создание личного кабинета')}
                    />
                </div>
                <div className={cnWizardOrderForm('Field', {type: 'buttons'})}>
                    <Button
                        className={cnWizardOrderForm(cnButton())}
                        leftIcon={{path: 'shop', width: 20, height: 20, marginRight: 8}}
                        text={t('Оформить предзаказ') || undefined}
                        callBack={trySendOrderForm}
                        mods={{
                            mods: {color: 'lightGreen', bkg: 'white', border: 'green', fill: 'lightGreen'},
                            hover: {mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}}
                        }}
                        type={'button'}
                    />
                </div>
                {confirmSms && projectOrderResult && projectOrderResult.phone ?
                    <Modal
                        className={cnWizard(cnModal())}
                        visible={confirmSms}
                        callBack={() => {}}
                        closeButton={false}
                    >
                        <SmsConfirm phone={projectOrderResult.phone}
                                    successCallBack={afterSuccessSmsConfirm}
                                    errorCallBack={afterErrorSmsConfirm}  />
                    </Modal> : null
                }
            </div>
        );
    }

export const WizardOrderForm = composeU(
    withWizardOrderFormUserManager,
)(WizardOrderFormBase);