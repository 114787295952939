import { SHELF_TYPE_HORIZONTAL } from "common-code/lib/constants";
import { ISaveShelfData } from "common-code/lib/interfaces/saveData/ISaveShelfData";

export const initialShelf = (shelf: ISaveShelfData | null) => {
  const axisInit = shelf?.type === SHELF_TYPE_HORIZONTAL ? "y" : "x";
  const shelfPosition = shelf?.initPosition?.[axisInit] as string | number;

  const distanceInit = (shelfPosition: string | number): string => {  
    if (typeof shelfPosition === "number" || Number(shelfPosition)) {
      return "bottom";
    } else if (typeof shelfPosition === "string") {
      if (shelfPosition.includes("=({%")) {
        return "top";
      } else if (shelfPosition.includes("%50")) {
        return "center";
      } else if (shelfPosition.includes("%")) {
        return "percent";
      }
    }
    return "";
  };

  const distanceValue = distanceInit(shelfPosition);

  const positionInit = (
    shelfPosition: string | number | undefined,
    distanceValue: string
  ): string => {
    switch (distanceValue) {
      case "center" :
      case "percent":
        return (shelfPosition as string).replace("%", "");
      case "top":
        return (shelfPosition as string)
          .replace("=({%100} - ", "")
          .replace(")", "");
      case "bottom":
        return (shelfPosition as number).toString();
      default:
        return "50";
    }
  };

  const positionValue = positionInit(shelfPosition, distanceValue);

  return shelf
    ? {
        length: shelf.length.replace("%", ""),
        depth: shelf.depth
          .replace("=({%100} - ", "")
          .replace(")", "")
          .replace("%100", ""),
        initPosition: positionValue,
        type: shelf.type,
        thickness: shelf.thickness.toString(),
        fixed: shelf.fixed,
        distance: distanceValue,
        rotation: !!shelf.rotation,
        shelfRotationX: shelf.rotation?.x
          ? ((shelf.rotation?.x * 180) / Math.PI)?.toString()
          : "0",
        shelfRotationY: shelf.rotation?.y
          ? ((shelf.rotation?.y * 180) / Math.PI)?.toString()
          : "0",
        shelfRotationZ: shelf.rotation?.z
          ? ((shelf.rotation?.z * 180) / Math.PI)?.toString()
          : "0",
        error: "",
      }
    : {
        length: "100",
        depth: "10",
        initPosition: "50",
        type: SHELF_TYPE_HORIZONTAL,
        thickness: "16",
        fixed: true,
        distance: "center",
        rotation: false,
        shelfRotationX: "0",
        shelfRotationY: "0",
        shelfRotationZ: "0",
        error: "",
      };
};
