import React from 'react'
import { defaultValues, initialErrors } from './defaultValues'
import { transliterate } from '../utils/transliterate'
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle'
import { Tumbler } from '../../../components/Tumbler/Tumbler'
import { Button } from '../../../components/Button'
import { cn } from '@bem-react/classname'
import { IHandleData } from '../../../../../common-code/interfaces/materials/IHandleData'
import HandleModels from './HandleModels/HandleModels'
import InputFile from './InputFile/InputFile'
import './FormHandle.css'
import Trash from '../icons/Trash'

export const cnFormHandle = cn('FormHandle')

export interface INewHandleData extends IHandleData {
    imageNew?: File | null
}

function FormHandle({
    itemHandle,
    createHandle,
}: {
    itemHandle: INewHandleData | null
    createHandle: (plinth: INewHandleData) => void
}) {
    const [newHandle, setNewHandle] = React.useState<INewHandleData>(
        defaultValues(itemHandle)
    )

    const formRef = React.useRef<HTMLFormElement>(null)

    const scrollToForm = () =>
        formRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })

    const {
        //обязательные
        title,
        models,

        //необязательные
        image,
        isDefault,
        imageNew,
    } = newHandle

    const [errors, setErrors] = React.useState(initialErrors)

    const onInputChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        setNewHandle({ ...newHandle, [e.target.name]: e.target.value })
    }

    const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewHandle({ ...newHandle, [e.target.name]: e.target.checked })
    }

    const onFocus = () => {
        setNewHandle({ ...newHandle })
        setErrors(initialErrors)
    }

    const createHandleNew = () => {
        const handle: INewHandleData = {
            id: transliterate(title),
            title: title,
            models: models,
        }

        if (isDefault) handle.isDefault = isDefault
        if (imageNew) {
            handle.imageNew = imageNew
        } else if (image) {
            handle.image = image
        }

        return handle
    }
    const validateHandle = () => {
        if (!title) {
            return 'Поле название цоколя не может быть пустым'
        }
        if (title.length < 5) {
            return 'Минимальная длина названия цоколя 5 символов'
        }
        return ''
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        const error = validateHandle()
        if (error) {
            scrollToForm()
            setErrors({ ...errors, title: error })
        }
        const handle = createHandleNew()
        createHandle(handle)
        console.log('handle', handle)
    }

    const addFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewHandle({
            ...newHandle,
            imageNew: Array.from(e.target.files as FileList)[0],
            image: '',
        })
        setErrors(initialErrors)
    }

    const filterFunction = () => {
        setNewHandle({ ...newHandle, imageNew: null, image: '' })
        setErrors(initialErrors)
    }
    // функция удаления выбранного фото
    const deleteImage = () => {
        setNewHandle({ ...newHandle, image: '' })
    }

    const edit = !!itemHandle

    return (
        <div className={cnFormHandle('')}>
            <div className={cnFormHandle('Container')}>
                <h3 className={cnFormHandle('Header')}>
                    {edit ? 'Редактирование ручек' : 'Добавление ручек'}
                </h3>

                <form
                    className={cnFormHandle('Content')}
                    onSubmit={(e) => handleSubmit(e)}
                    ref={formRef}
                >
                    <label htmlFor="title">Название ручек *</label>
                    <Textinput
                        className={cnFormHandle('Textinput')}
                        value={title}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onInputChange(e)
                        }
                        name="title"
                        type="text"
                        maxLength={50}
                        onFocus={onFocus}
                    />
                    {errors.title && (
                        <p className="text-danger">{errors.title}</p>
                    )}

                    <HandleModels
                        newHandle={newHandle}
                        setNewHandle={setNewHandle}
                    />
                    <h5>Изображение ручек</h5>

                    {image && !imageNew && (
                        <div className={cnFormHandle('Children')}>
                            <span>{image.split('/').at(-1) as string}</span>
                            <img width={50} height={50} src={image} alt="..." />
                            <div className={cnFormHandle('Box-Btn')}>
                                <Trash handleDeleteValue={deleteImage} />
                            </div>
                        </div>
                    )}
                    <InputFile
                        file={imageNew as File | null}
                        addFiles={addFiles}
                        filterFunction={filterFunction}
                    />

                    <Tumbler
                        className={cnFormHandle('Tumbler')}
                        checked={isDefault as boolean}
                        callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChecked(e)
                        }
                        label="Определить как ручка по умолчанию"
                        name="isDefault"
                    />

                    <div className={cnFormHandle('Footer')}>
                        <Button
                            className={cnFormHandle('Button')}
                            type={'submit'}
                            text={'Подтвердить изменения'}
                            leftIcon={undefined}
                            mods={{
                                mods: {
                                    bkg: 'white',
                                    color: 'lightGreen',
                                    border: 'lightGreen',
                                    fill: 'lightGreen',
                                },
                                hover: {
                                    mods: {
                                        bkg: 'lightGreen',
                                        color: 'white',
                                        fill: 'white',
                                    },
                                },
                            }}
                            callBack={() => {}}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FormHandle
