import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ProjectsFilter} from './Filter/Projects-Filter';
import {IProjectFilterItem} from '../../../../common-code/interfaces/project/IProjectFilterItem';
import {IProjectFilterData} from '../../../../common-code/interfaces/project/IProjectFilterData';
import {useTranslation} from 'react-i18next';
import {LoadingApiData} from '../../helpers/LoadingApiData/LoadingApiData';
import './Projects.css';
import {IPager} from '../../../../common-code/interfaces/catalog/IPager';
import {Pager} from '../../components/Pager/Pager';
import {IProjectSmallData} from '../../../../common-code/interfaces/project/IProjectSmallData';
import {CurrencyHelper} from '../../../../domain/CurrencyHelper/CurrencyHelper';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Button} from '../../components/Button';

export const cnProjects = cn('Projects');
export interface IProjectsProps extends IClassNameProps {
}
export const Projects: React.FC<IProjectsProps> = ({className, }) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [projects, setProjects] = useState<IProjectSmallData[] | undefined>(undefined);
    const [filterItems, setFilterItems] = useState<IProjectFilterItem[]>([]);
    const [filterData, setFilterData] = useState<IProjectFilterData[]>([]);
    const [pager, setPager] = useState<IPager>({NavPageSize: 24});
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const navigateToProject = (projectId: string) => {
        navigate('/wizard/?project='+ projectId);
    }

    useEffect(() => {
        setLoading(true);

        const getFilterSendData = (filterData: IProjectFilterData[]): {[key: string]: string} => {
            let sendData: {[key: string]: string} = {};
            let item: IProjectFilterData;
            let pageNumber: string | null;

            for (item of filterData) {
                if (item.value) {
                    sendData[item.name] = item.value;
                }
            }
            sendData.count = '' + pager.NavPageSize;
            pageNumber = searchParams.get('page');
            if (pageNumber) {
                sendData.page = pageNumber;
            }
            return sendData;
        }

        axios.get('/api/project/list', {params: getFilterSendData(filterData)})
            .then((response) => {
                if (response.data && response.data.filter && response.data.filter.length > 0) {
                   setFilterItems(response.data.filter);
                }
                if (response.data && response.data.items) {
                    setProjects(response.data.items);
                }
                if (response.data && response.data.pages) {
                    setPager(response.data.pages);
                }
                setLoading(false);
            })
            .catch(() => {
                setProjects([]);
                setLoading(false);
            });
    }, [filterData, pager.NavPageSize, searchParams]);

    if (projects === undefined) {
        return (
            <div className={cnProjects({}, [className])}>
                {projects}
            </div>
        );
    }
    return (
        <div className={cnProjects({}, [className])}>
            <ProjectsFilter filterItems={filterItems}
                            filterData={filterData}
                            setFilterData={setFilterData}
            />
            <div className={cnProjects('Content')}>
                <div className={cnProjects('Items')}>
                    {projects.length ? projects.map(project => {
                        return (
                            <div className={cnProjects('Item')} key={project.id}>
                                <div className={cnProjects('ItemImages')}>
                                    {!project.image || <img className={cnProjects('ItemImage')} src={project.image}  alt={project.name}/>}
                                </div>
                                <div className={cnProjects('ItemName')}>
                                    {project.name}
                                </div>
                                <div className={cnProjects('ItemDate')}>
                                    {t('Создан')}: {project.date}
                                </div>
                                <div className={cnProjects('ItemUser')}>
                                    {t('Пользователь')}: {project.user}
                                </div>
                                <div className={cnProjects('ItemPrice')}>
                                    <div className={cnProjects('ItemPriceTitle')}>
                                        {t('Цена')}:
                                    </div>
                                    <div className={cnProjects('ItemPriceValue')}>
                                        {CurrencyHelper.formatValue(project.price)}
                                    </div>
                                </div>
                                <div className={cnProjects('ItemButtons')}>
                                    <Button type={'button'}
                                            mods={{mods: {bkg: 'white', color: 'lightGreen', fill: 'lightGreen', border: 'green'},
                                            hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white', border: 'green'}}}}
                                            callBack={() => {
                                                navigateToProject(project.id);
                                            }}
                                            leftIcon={{
                                                path: 'create',
                                                width: 12,
                                                height: 12,
                                                marginRight: 5}}
                                            className={cnProjects('Button')}
                                            text={t('Открыть') || ''}
                                    />
                                </div>
                            </div>
                        );
                    }) : <div className={cnProjects('Note')}>{t('Не нашли ни одного проекта')}</div> }
                </div>
                <Pager pager={pager} name={'page'}/>
            </div>

            <LoadingApiData loading={loading}/>
        </div>
    );
}