import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeBottomOvenCorpus} from '../../../details/ThreeCorpus/types/ThreeBottomOvenCorpus';
import {TClassName} from '../../../../../../../common-code/types/TClassName';
import {
    CLASSNAME_EQUIPMENT_BUILTIN_SINK,
    CLASSNAME_EQUIPMENT_OVEN, CLASSNAME_EQUIPMENT_SEPARATE_SINK
} from '../../../../../../../common-code/constants';
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnitOven extends ThreeBottomUnit {
    corpus: ThreeBottomOvenCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected availableEquipments(): TClassName[] {
        let availableEquipments: TClassName[];
        let sinkIndex: number;

        availableEquipments = super.availableEquipments();
        if (availableEquipments.indexOf(CLASSNAME_EQUIPMENT_OVEN) === -1) {
            availableEquipments.push(CLASSNAME_EQUIPMENT_OVEN);
        }
        sinkIndex = availableEquipments.indexOf(CLASSNAME_EQUIPMENT_SEPARATE_SINK);
        if (sinkIndex !== -1) {
            availableEquipments.splice(sinkIndex, 1);
        }
        sinkIndex = availableEquipments.indexOf(CLASSNAME_EQUIPMENT_BUILTIN_SINK);
        if (sinkIndex !== -1) {
            availableEquipments.splice(sinkIndex, 1);
        }

        return availableEquipments;
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomOvenCorpus {
        return new ThreeBottomOvenCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}