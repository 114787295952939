import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {TUserRole} from '../../../../common-code/types/TUserRole';
import {HIDE_LOADING, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_WARNING, SHOW_LOADING} from '../../../constants';
import {IProjectManagerOrderFormData} from '../../../../common-code/interfaces/project/IProjectManagerOrderFormData';
import {Action, Dispatch} from 'redux';
import {IProjectFormDataCustomer} from '../../../../common-code/interfaces/project/IProjectFormDataCustomer';
import {DELIVERY_TYPE_HOME, ORDER_USER_TYPE_CHOICE, ORDER_USER_TYPE_NEW} from '../../../../common-code/constants';
import {addFormError, validatePhone} from '../../../helpers';
import {IProjectFormDataDelivery} from '../../../../common-code/interfaces/project/IProjectFormDataDelivery';
import axios, {AxiosResponse} from 'axios';
import {TFunction} from 'i18next';
import {IProjectOrderResult} from '../../../../common-code/interfaces/project/IProjectOrderResult';

export const cnWizardOrderForm = cn('WizardOrderForm');

export interface IWizardOrderFormProps extends IClassNameProps {
    service?: KitchenService;
    user?: TUserRole;
}

export const applySendOrderForm = (
    service: KitchenService,
    formData: IProjectManagerOrderFormData,
    dispatch: Dispatch<Action>,
    t: TFunction<"translation", undefined, "translation">,
    formErrors: any,
): Promise<IProjectOrderResult> => {
    return new Promise<IProjectOrderResult>((resolve, reject) => {
        if (!validateForm(formData, formErrors, t)) {
            service.showMessage({
                type: MESSAGE_TYPE_WARNING,
                message: t('Не заполнены обязательные поля'),
                autoClose: true
            });
            dispatch({type: HIDE_LOADING});
            reject();
        } else {
            dispatch({type: SHOW_LOADING});
            service.sendProjectToSave(undefined, undefined, true).then(() => {
                sendOrderForm(formData).then((result: IProjectOrderResult) => {
                    dispatch({type: HIDE_LOADING});
                    resolve(result);
                });
            }).catch(() => {
                service.showMessage({
                    type: MESSAGE_TYPE_ERROR,
                    message: t('Произошла ошибка при оформлении заказа на стороне сервера'),
                    autoClose: true
                });
                dispatch({type: HIDE_LOADING});
                reject();
            })
        }
    });
}

const sendOrderForm = (formData: IProjectManagerOrderFormData): Promise<IProjectOrderResult> => {
    return new Promise<IProjectOrderResult>((resolve, reject) => {
        axios.post('/api/project/order/', formData)
            .then((response: AxiosResponse) => {
                if (response.data) {
                    resolve(response.data);
                } else {
                    reject();
                }
            })
            .catch(() => {
                reject();
            });
    });
}

const validateForm = (formData: IProjectManagerOrderFormData, formErrors: any, t: TFunction<"translation", undefined, "translation">): boolean => {
    validateCustomer(formData.customer, formErrors, t);
    validateDelivery(formData.delivery, formErrors, t);
    if (!formData.checkPersonal) {
        addFormError(
            formErrors,
            t('Необходимо согласиться у условиями обработки персональных данных'),
            'checkPersonal',
        );
    }

    return !Object.keys(formErrors).length;
}

const validateCustomer = (
    customer: IProjectFormDataCustomer,
    formErrors: any,
    t: TFunction<"translation", undefined, "translation">
) => {
    switch (customer.type) {
        case ORDER_USER_TYPE_CHOICE:
            if ('id' in customer.data && !customer.data.id) {
                addFormError(
                    formErrors,
                    t('Не выбран покупатель для заказа'),
                    ORDER_USER_TYPE_CHOICE,
                    'customer'
                );
            }
            break;
        case ORDER_USER_TYPE_NEW:
            if ('userName' in customer.data && !customer.data.userName) {
                addFormError(
                    formErrors,
                    t('Не заполнено имя покупателя'),
                    'firstName',
                    'customer'
                );
            }
            if ('userPhone' in customer.data) {
                if (!customer.data.userPhone) {
                    addFormError(
                        formErrors,
                        t('Не заполнен телефон покупателя'),
                        'phone',
                        'customer'
                    );
                } else if (!validatePhone(customer.data.userPhone)) {
                    addFormError(
                        formErrors,
                        t('Формат телефона должен быть') + ' +7 (9xx) xxx-xx-xx',
                        'phone',
                        'customer'
                    );
                }
            }
            break;
    }
}

const validateDelivery = (
    delivery: IProjectFormDataDelivery,
    formErrors: any,
    t: TFunction<"translation", undefined, "translation">
) => {
    switch (delivery.type) {
        case DELIVERY_TYPE_HOME:
            if (!delivery.data.address) {
                addFormError(
                    formErrors,
                    t('Не заполнен адрес доставки'),
                    'address',
                    'delivery'
                );
            }
            if (delivery.data.isFloor) {
                if (!delivery.data.floorNumber || isNaN(+delivery.data.floorNumber)) {
                    addFormError(
                        formErrors,
                        t('Не заполнен этаж'),
                        'floorNumber',
                        'delivery'
                    );
                }
            }
            break;
    }
}

