import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Euler, Vector3} from 'three';
import {ThreeTopUnitAngleNormal} from "../../../units/ThreeTopUnit/types/ThreeTopUnitAngleNormal";
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from "../../../../../../../common-code/constants";
import {TPlankType} from "../../../../../../../common-code/types/TPlankType";
import {TOP_UNIT_PLANK_WIDTH} from "../../../../../constants";
import {
    ISaveTopAngleNormalCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopAngleNormalCorpusData';


export class ThreeTopAngleNormalCorpus extends ThreeCorpus {
    saveData: ISaveTopAngleNormalCorpusData;
    unit: ThreeTopUnitAngleNormal;

    constructor(options: ISaveTopAngleNormalCorpusData, unit: ThreeTopUnitAngleNormal) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public initState() {
        this.createFrontPanels();
        this.createPlanks();
        super.initState();
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getDepth() - this.getBackThickness()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getDepth() - this.getBackThickness()),
            'top',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
    }

    protected createFrontPanels() {
        let position: Vector3;

        if (!this.saveData.frontPanel) {
            return;
        }
        position = new Vector3();
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                position.set(
                    -this.getWidth()/2 + this.saveData.frontPanel.length/2,
                    0,
                    this.getDepth()/2 + this.getThickness()/2
                );
                break;
            case SIDE_TYPE_RIGHT:
                position.set(
                    this.getWidth()/2 - this.saveData.frontPanel.length/2,
                    0,
                    this.getDepth()/2 + this.getThickness()/2
                );
                break;
        }
        this.createPanel(
            new BoxGeometry(this.saveData.frontPanel.length, this.getHeight(), this.getThickness()),
            'frontPanel',
            position
        );
    }

    protected createPlanks() {
        let position: Vector3;
        let rotation: Euler;
        let index: string;
        let plank: TPlankType;

        if (!this.saveData.planks) {
            return;
        }

        position = new Vector3();
        rotation = new Euler();
        for (index in this.saveData.planks) {
            plank = this.saveData.planks[index];
            position.y = plank.position && plank.position.y ? plank.position.y : 0;
            position.z = plank.position && plank.position.z ? plank.position.z : this.getDepth() / 2 + this.getThickness() + TOP_UNIT_PLANK_WIDTH / 2;
            rotation.x = plank.rotation && plank.rotation.x ? plank.rotation.x : 0;
            rotation.y = plank.rotation && plank.rotation.y ? plank.rotation.y : 0;
            rotation.z = plank.rotation && plank.rotation.z ? plank.rotation.z : 0;
            switch (this.unit.getSideType()) {
                case SIDE_TYPE_LEFT:
                    position.x = plank.position && plank.position.x ? -this.unit.getWidth() / 2 + plank.position.x : -this.unit.getWidth() / 2;
                    break;
                case SIDE_TYPE_RIGHT:
                    position.x = plank.position && plank.position.x ? this.unit.getWidth() / 2 - plank.position.x : this.unit.getWidth() / 2;
                    break;
            }
            this.createPanel(
                new BoxGeometry(plank.length, this.getThickness(), TOP_UNIT_PLANK_WIDTH),
                `frontPanel${index}`,
                position,
                rotation
            );
        }
    }

    protected addCoverPointsToUnit() {
        let coverPoints: Vector3[];
        if (!this.saveData.planks) {
            coverPoints = [
                new Vector3(-this.getWidth()/2, -this.getHeight()/2, -this.getDepth()/2),
                new Vector3(this.getWidth()/2, this.getHeight()/2, this.getDepth()/2),
            ];
        } else {
            coverPoints = [
                new Vector3(-this.getWidth()/2, -this.getHeight()/2, -this.getDepth()/2),
                new Vector3(this.getWidth()/2, this.getHeight()/2, this.getDepth() / 2 + this.getThickness() + TOP_UNIT_PLANK_WIDTH),
            ];
        }
        if (this.saveData.frontPanel && this.saveData.frontPanel.isDimensions) {
            coverPoints.push(new Vector3(this.getWidth()/2, this.getHeight()/2, this.getDepth() / 2 + this.getThickness()))
        }

        this.unit.addCoverPoints(coverPoints);
        this.addCoverPoints(coverPoints);
    }
}