import { ICreateGroup } from "common-code/lib/interfaces/createData/ICreateGroup";
import { IDataModule } from "../EditModule/EditModule";

export const initUnitList =  async (id: string): Promise<ICreateGroup[] | undefined> => {
    const res = await fetch(`/api/unitlist/get/${id}`);
    if (res.ok) {
        return res.json();
    }
};

export const saveUnitList = async (data: ICreateGroup[], id: string): Promise<ICreateGroup[] | undefined> => {
    const res = await fetch(`/api/unitlist/save/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })

    if (res.ok) {
        return res.json();
    }
};

export const sendModuleData = async (data: IDataModule): Promise< {created_at: string} | undefined> => {
    const res = await fetch(`https://issue.impermebel.ru/constructor/module`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })

    if (res.ok) {
        return res.json();
    }
};