import {IClassNameProps} from "@bem-react/core";
import {cn} from "@bem-react/classname";
import React, {useMemo, useState} from "react";
import './PageComplectCollections.css';
import {useTranslation} from "react-i18next";
import {cnHeader, Header} from "../../ui/layouts/Header";
import {ICollectionDto} from '../../../common-code/interfaces/ICollectionDto';
import {IBreadcrumbs} from '../../interfaces/IBreadcrumbs';
import {cnPage} from '../../main/App/Content/App-Content';
import {Breadcrumbs, cnBreadcrumbs} from '../../ui/components/Breadcrumbs ';
import {LoadingApiData} from '../../ui/helpers/LoadingApiData/LoadingApiData';
import {CatalogItem, cnCatalogItem} from '../../ui/elements/CatalogItem';
import {useEffectDidMount} from '../../hooks/useEffectDidMount';
import axios from 'axios';

export const cnPageComplectCollections = cn('PageComplectCollections');

export interface IPageComplectCollectionsProps extends IClassNameProps {

}

export const PageComplectCollections: React.FC<IPageComplectCollectionsProps> = ({className}) => {
    const {t} = useTranslation();
    const [collections, setCollections] = useState<ICollectionDto[]>([]);
    const breadcrumbs = useMemo(() => {
        return [
            {text: t("Коллекции комплектных гарнитуров"), href: '/collections'}
        ] as IBreadcrumbs[];
    }, [t]);

    useEffectDidMount(() => {
        axios.get('/api/collection/complect-list', {params: {}})
            .then((response) => {
                if (response.data && response.data.collections && response.data.collections.length > 0) {
                    setCollections(response.data.collections);
                }
            })
            .catch(() => {
            });
    });

    return (
        <div className={cnPageComplectCollections({}, [className])}>
            <Header className={cnPageComplectCollections(cnHeader())} title={t("Коллекции комплектных гарнитуров") || undefined} />
            <div className={cnPage('Wrapper')}>
                <div className={cnPage('Sections')}>
                    <Breadcrumbs className={cnPage(cnBreadcrumbs())} breadcrumbs={breadcrumbs}/>
                    <h1 className={cnPage('Title')}>{t("Коллекции модульных гарнитуров")}</h1>
                </div>
            </div>
            <div className={cnPage('Divider')}></div>
            <div className={cnPageComplectCollections('Collections')}>
                {!collections.length ?
                    <LoadingApiData loading={!collections.length}/> :
                    collections.map(collection => <CatalogItem className={cnPageComplectCollections(cnCatalogItem())} key={collection.id} collectionData={collection}/>)}
            </div>
        </div>
    );
}