import React from 'react'
import './RadioButton.css';
import {useTranslation} from 'react-i18next';
import {Button, cnButton} from '../Button';
import {cnRadioButton, IRadioButtonProps} from './index';
import {composeU} from '@bem-react/core';
import {withRadioButtonKitCode} from './_type/RadioButton_type_kitCode';

export const RadioButtonBase: React.FC<IRadioButtonProps> =
    ({
         type,
         className,
         options,
         value,
         callBack,
         name
     }) => {
        const {t} = useTranslation();

        if (!options || !options.length || !callBack) {
            return null;
        }

        return (
            <div className={cnRadioButton({type: type}, [className])}>
                {options.map((option, index) => {
                    return (
                        <Button
                            key={index}
                            className={cnRadioButton(cnButton(), {})}
                            type={'button'}
                            leftIcon={option.id === value ? {
                                path: 'ok-circle',
                                width: 16,
                                height: 16,
                                fill: 'white',
                                marginRight: 5
                            } : undefined}
                            text={t(option.title) || undefined}
                            disabled={!!option.disabled}
                            mods={{
                                mods: {bkg: 'lightGray', color: 'black', border: 'gray'},
                                hover: {mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}},
                                active: {
                                    state: option.id === value,
                                    mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}
                                },
                                disabled: {state: !!option.disabled, mods: {color: 'lightBlack', cursor: 'default'}}
                            }}
                            callBack={() => {
                                callBack(option.id, name)
                            }}
                        />
                    );
                })}
            </div>
        )
    }

export const RadioButton = composeU(
    withRadioButtonKitCode,
)(RadioButtonBase);