import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';
import {useTranslation} from 'react-i18next';
import {cnRoomParams} from '../RoomParams';
import {Button, cnButton} from '../../../components/Button';
import {cn} from '@bem-react/classname';
import './RoomParams-Decor.css';

export const cnRoomParamsDecor = cn('RoomParamsDecor');

export interface IRoomParamsDecorProps extends IClassNameProps {
    objectList: IMaterialData[];
    selectObject?: IMaterialData;
    onSelectObject: (objectData: IMaterialData) => void;
}

export const RoomParamsDecor: React.FC<IRoomParamsDecorProps>
    = ({
           className,
           objectList,
           selectObject,
           onSelectObject
       }) => {
    const {t} = useTranslation();

    return(
        <div className={cnRoomParams('Decor', [className])}>
            {objectList.map( (item) => {
                return (
                    <div
                        key={item.id}
                        className={cnRoomParamsDecor({active: item.id === selectObject?.id})}
                    >
                        <p className={cnRoomParamsDecor('Name')}>{t(item.title)}</p>
                        <img className={cnRoomParamsDecor('Image')} src={item.image} alt={t(item.title) || ''} />
                        <Button
                            className={cnRoomParamsDecor(cnButton())}
                            leftIcon={item.id === selectObject?.id ? {path: 'check', width: 12, height: 12, marginRight: 5} : undefined}
                            type={'button'}
                            text={ (item.id === selectObject?.id ? t('Выбрано') : t('Выбрать')) || ''}
                            mods={{mods: item.id === selectObject?.id ? {bkg: 'lightGreen', color: 'white', fill: 'white', border: 'green'} : {bkg: 'white', color: 'lightGreen', fill: 'lightGreen', border: 'green'}}}
                            callBack={ () => onSelectObject(item)}
                        />
                    </div>
                )
            })}
        </div>
    );
};