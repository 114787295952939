import React from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_CORPUS_MATERIAL} from '../../../../../common-code/constants';
import {IObjectOptionProps} from '../ObjectOption';
import {useTranslation} from 'react-i18next';
import {IOptionCorpusMaterial} from '../../../../../common-code/interfaces/option/IOptionCorpusMaterial';
import {cnObjectOption} from '../index';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';
import {Image} from '@yandex/ui/Image';
import {Button} from '../../Button';
import {Svg} from '../../Svg';


export interface IObjectOptionTypeCorpusMaterialProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_CORPUS_MATERIAL;
    option?: IOptionCorpusMaterial;
    defaultValue?: string;
}

const ObjectOptionTypeCorpusMaterial: React.FC<IObjectOptionTypeCorpusMaterialProps> =
    ({
         option,
         group,
         className,
         onChange,
         defaultValue
     }) => {

        const value: string = defaultValue !== undefined ?
            '' + defaultValue : (option && option.value ? option.value : '');
        const {t} = useTranslation();

        if (!option || !onChange) {
            debugger;
            return null;
        }
        const activeItem: IMaterialData | undefined = option.items.filter(item => item.id === value)[0];

        const onChangeFacadeMaterial = (value: string) => {
            onChange(option.id, value, group ? group.id : undefined);
        }

        return (
            <div className={cnObjectOption({}, [className])}>
                <div className={cnObjectOption('WrapperTitle')}>
                    <div className={cnObjectOption('Title', [className + 'Title'])}>
                        {t(option.title)}:
                    </div>
                    <div className={cnObjectOption('TitleNameColor')}>
                        {activeItem ? t(activeItem.title) : t('Не выбрано')}
                    </div>
                </div>
                <div className={cnObjectOption('Value', [className + 'Value'])}>

                    {option.items.map((item: IMaterialData) => {
                        return (
                            <Button
                                key={item.id}
                                className={cnObjectOption('ButtonImage')}
                                type={'button'}
                                mods={{
                                    mods: {active: activeItem?.id === item.id},
                                    hover: {mods: {active: true}}
                                }}
                                callBack={() => {
                                    onChangeFacadeMaterial(item.id)
                                }}
                                child={
                                    item.image ?
                                        <Image
                                            src={item.image}
                                            alt={t(item.title) || ''}
                                            width={50}
                                            height={50}
                                            className={cnObjectOption('Image')}
                                        />
                                        : item.color ?
                                            <div
                                                style={{backgroundColor: item.color, width: 50, height: 50}}
                                                className={cnObjectOption('Color')}/>
                                            :
                                            <Svg
                                                style={{
                                                    backgroundColor: 'white',
                                                    width: 50,
                                                    height: 50,
                                                    fill: 'lightBlack'
                                                }}
                                                icon={'pic'}
                                                className={cnObjectOption('Icon')}
                                                label={t(item.title) || ''}/>
                                }
                            />
                        )
                    })
                    }
                </div>
            </div>

        );
    }

export const withObjectOptionTypeCorpusMaterial = withBemMod<IObjectOptionTypeCorpusMaterialProps, IObjectOptionProps>(
        cnObjectOption(),
        {type: OPTION_TYPE_CORPUS_MATERIAL},
        () => ObjectOptionTypeCorpusMaterial
    )
;