import {BoxGeometry, Mesh, Shape} from "three";
import {ThreeBox} from "../ThreeBox";
import {ThreeCorpus} from "../../ThreeCorpus/ThreeCorpus";
import {ISaveBoxData} from '../../../../../../../common-code/interfaces/saveData/ISaveBoxData';

export class ThreeBoxNormal extends ThreeBox {

    constructor(options: ISaveBoxData, corpus: ThreeCorpus, shape?: Shape) {
        super(options, corpus);
    }

    protected createMeshes() {
        this.createLeftMesh();
        this.createRightMesh();
        this.createBackMesh();
        this.createFrontMesh();
        this.createBottomMesh();
    }

    protected createLeftMesh() {
        let left: Mesh;

        left = new Mesh(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getBottomThickness(), this.getDepth()),
            this.createBodyMaterial()
        );
        left.name = 'left';
        left.userData.transparentRenderOrder = 5;
        left.position.x = -this.getWidth() / 2 + this.getThickness() / 2 + this.getRailGap();
        left.position.z = (this.corpus.getInnerDepth() - this.getDepth()) / 2;
        left.matrixAutoUpdate = false;
        this.createMeshCarcass(left);
        this.view3d.add(left);
    }

    protected createRightMesh() {
        let right: Mesh;

        right = new Mesh(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getBottomThickness(), this.getDepth()),
            this.createBodyMaterial()
        );
        right.name = 'right';
        right.userData.transparentRenderOrder = 5;
        right.position.x = this.getWidth() / 2 - this.getThickness() / 2 - this.getRailGap();
        right.position.z = (this.corpus.getInnerDepth() - this.getDepth()) / 2;
        right.matrixAutoUpdate = false;
        this.createMeshCarcass(right);
        this.view3d.add(right);
    }

    protected createBackMesh() {
        let back: Mesh;

        back = new Mesh(
            new BoxGeometry(this.getWidth() - (this.getRailGap() * 2) - (this.getThickness() * 2), this.getHeight() - this.getBottomThickness(), this.getThickness()),
            this.createBodyMaterial()
        );
        back.name = 'back';
        back.userData.transparentRenderOrder = 5;
        back.position.z = -this.getDepth() / 2 + ((this.corpus.getInnerDepth() - this.getDepth()) / 2) + (this.getThickness() / 2);
        back.matrixAutoUpdate = false;
        this.createMeshCarcass(back);
        this.view3d.add(back);
    }

    protected createFrontMesh() {
        if (!this.getFrontSide()) {
            return;
        }

        let front: Mesh;

        front = new Mesh(
            new BoxGeometry(this.getWidth() - (this.getRailGap() * 2) - (this.getThickness() * 2), this.getHeight() - this.getBottomThickness(), this.getThickness()),
            this.createBodyMaterial()
        );
        front.name = 'front';
        front.userData.transparentRenderOrder = 5;
        front.position.z = this.getDepth() / 2 + ((this.corpus.getInnerDepth() - this.getDepth()) / 2) - (this.getThickness() / 2);
        front.matrixAutoUpdate = false;
        this.createMeshCarcass(front);
        this.view3d.add(front);
    }

    protected createBottomMesh() {
        let bottom: Mesh;

        bottom = new Mesh(
            new BoxGeometry(this.getWidth() - (this.getRailGap() * 2), this.getBottomThickness(), this.getDepth()),
            this.createBodyMaterial()
        );
        bottom.name = 'bottom';
        bottom.userData.transparentRenderOrder = 5;
        bottom.position.y = -this.getHeight() / 2;
        bottom.position.z = (this.corpus.getInnerDepth() - this.getDepth()) / 2;
        bottom.matrixAutoUpdate = false;
        this.createMeshCarcass(bottom);
        this.view3d.add(bottom);
    }
}