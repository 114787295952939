import { ThreeCorpus } from "../ThreeCorpus";
import { ThreePenalUnitEndConsole } from "../../../units/ThreePenalUnit/types/ThreePenalUnitEndConsole";
import { BoxGeometry, Euler, Vector3 } from "three";
import { ISaveCorpusData } from "../../../../../../../common-code/interfaces/saveData/ISaveCorpusData";
import {
  SIDE_TYPE_LEFT,
  SIDE_TYPE_RIGHT,
} from "../../../../../../../common-code/constants";

export class ThreePenalEndConsoleCorpus extends ThreeCorpus {
  unit: ThreePenalUnitEndConsole;

  constructor(options: ISaveCorpusData, unit: ThreePenalUnitEndConsole) {
    super(options, unit);
    this.unit = unit;
  }

  public initState(isRebuild?: boolean) {
    super.initState(isRebuild);
    // this.view3d.userData.transparentAngle = -0.4;
  }

  public getInnerWidth() {
    return this.saveData.sizes.length;
  }

  public getInnerDepth() {
    return (
      this.saveData.sizes.width - this.getThickness() - this.getBackThickness()
    );
  }

  protected createShelves() {
    super.createShelves();
    // let shelf: ThreeShelf;
    //
    // for (shelf of this.shelves) {
    //     // shelf.view3d.userData.transparentAngle = -0.4;
    // }
  }

  protected createBackPanels() {
    this.createPanel(
      new BoxGeometry(
        this.getWidth(),
        this.getHeight() - this.getThickness(),
        this.getThickness()
      ),
      "backToWall",
      new Vector3(
        0,
        this.getThickness() / 2,
        -this.getDepth() / 2 + this.getThickness() / 2
      )
    );
  }

  protected createPanels() {
    this.createPanel(
      new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth()),
      "bottom",
      new Vector3(0, -this.getHeight() / 2 + this.getThickness() / 2, 0),
      new Euler(0, 0, 0),
      true
    );
    this.createPanel(
      new BoxGeometry(
        this.getWidth(),
        this.getThickness(),
        this.getDepth() - this.getThickness() - this.getBackThickness()
      ),
      "top",
      new Vector3(
        0,
        this.getHeight() / 2 - this.getThickness() / 2,
        (this.getBackThickness() - this.getThickness()) / 2
      )
    );
    this.createPanel(
      new BoxGeometry(
        this.getWidth(),
        this.getHeight() - this.getThickness(),
        this.getThickness()
      ),
      "front",
      new Vector3(
        0,
        this.getThickness() / 2,
        this.getDepth() / 2 - this.getThickness() / 2
      )
    );
    this.createPanel(
      new BoxGeometry(
        this.getWidth(),
        this.getHeight() - this.getThickness(),
        this.getThickness()
      ),
      "right",
      new Vector3(
        0,
        this.getThickness() / 2,
        this.getDepth() / 2 - this.getThickness() / 2
      )
    );

    switch (this.unit.getSideType()) {
      case SIDE_TYPE_LEFT:
        this.createPanel(
          new BoxGeometry(
            this.getBackThickness(),
            this.getHeight() - this.getThickness() * 2,
            this.getDepth() - this.getThickness() - this.getBackThickness()
          ),
          "backRightToNeighbour",
          new Vector3(
            this.getWidth() / 2 - this.getBackThickness() / 2,
            0,
            (this.getBackThickness() - this.getThickness()) / 2
          )
        );
        break;
      case SIDE_TYPE_RIGHT:
        this.createPanel(
          new BoxGeometry(
            this.getBackThickness(),
            this.getHeight() - this.getThickness() * 2,
            this.getDepth() - this.getThickness() - this.getBackThickness()
          ),
          "BackleftToNeighbour",
          new Vector3(
            -this.getWidth() / 2 + this.getBackThickness() / 2,
            0,
            (this.getBackThickness() - this.getThickness()) / 2
          )
        );
        break;
    }
  }
}
