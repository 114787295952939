import {IOption} from '../../../interfaces/option/IOption';
import {IOptionGroup} from '../../../interfaces/option/IOptionGroup';
import {GOOD_TYPE_MODULE, LOCAL_STORAGE_PROJECT_NAME} from '../../../constants';
import {IImportOffer} from '../../../interfaces/api/IImportOffer';
import {IAPIProductItem} from '../../../interfaces/api/IAPIProductItem';
import {IModuleOffers} from '../../../interfaces/catalog/IModuleOffers';

const hash_sum = require("hash-sum");

export class CommonHelper {
    public static md5(value: any): string {
        return hash_sum(value);
    }

    public static deepCompare(
        obj1: any,
        obj2: any,
        compareOrder: boolean = true
    ): boolean {
        if (typeof obj1 !== typeof obj2) {
            return false;
        }
        if ((typeof obj1 === "string" || typeof obj1 === "number") && obj1 !== obj2) {
            return false;
        }
        const objEntries1: [string, unknown][] = Object.entries(obj1);
        const objEntries2: [string, unknown][] = Object.entries(obj2);

        return this.compareEntries(objEntries1, objEntries2, compareOrder);
    }

    public static compareEntries(
        firstEntries: [string, unknown][],
        secondEntries: [string, unknown][],
        compareOrder: boolean
    ): boolean {
        if (!firstEntries.length && secondEntries.length) {
            return false;
        }
        if (firstEntries.length !== secondEntries.length && compareOrder) {
            return false;
        }
        return firstEntries.every((value, index): boolean => {
            const secondIndex = secondEntries.findIndex(val => val[0] === value[0]);
            if ((compareOrder && index !== secondIndex) || secondIndex === -1) {
                return false;
            }
            const [k1, v1] = value;
            const [k2, v2] = secondEntries[secondIndex];
            if (k1 !== k2) {
                return false;
            }
            if (typeof v1 === "object" || typeof v2 === "object") {
                return this.deepCompare(v1, v2, compareOrder);
            }

            return v1 === v2;
        })
    }

    public static deepCopy(object: any) {
        let copy: any;

        // Handle the 3 simple types, and null or undefined
        if (null == object || "object" != typeof object) return object;

        // Handle Date
        if (object instanceof Date) {
            copy = new Date();
            copy.setTime(object.getTime());
            return copy;
        }

        // Handle Array
        if (object instanceof Array) {
            copy = [];
            for (let i = 0, len = object.length; i < len; i++) {
                copy[i] = this.deepCopy(object[i]);
            }
            return copy;
        }

        // Handle Object
        if (object instanceof Object) {
            copy = {};
            for (let attr in object) {
                if (object.hasOwnProperty(attr)) copy[attr] = this.deepCopy(object[attr]);
            }
            return copy;
        }

        throw new Error('error-CommonHelper-deepCopy');
    }

    public static instanceOfIOption(object: any): object is IOption {
        return 'type' in object;
    }

    public static instanceOfIOptionGroup(object: any): object is IOptionGroup {
        return 'options' in object;
    }

    public static getLocalProjectName(
        userUid?: string,
        corpusMaterial?: string,
        topFacade?: string,
        bottomFacade?: string
    ): string {
        let projectName: string;

        projectName = LOCAL_STORAGE_PROJECT_NAME;
        if (userUid) {
            projectName += '_' + userUid;
        }
        if (corpusMaterial) {
            projectName += '_' + corpusMaterial;
        }
        if (topFacade) {
            projectName += '_' + topFacade;
        }
        if (bottomFacade) {
            projectName += '_' + bottomFacade;
        }

        return projectName;
    }

    public static sortObject(object: any): any {
        return Object.keys(object).sort().reduce(function (result: any, key) {
            result[key] = object[key];
            return result;
        }, {});
    }

    public static relativeValue(value: number, min: number, max: number): number | undefined {
        if (isNaN(+value) || isNaN(+min) || isNaN(+max)) {
            return undefined;
        }
        return (value - min) / (max - min);
    }

    public static normalizeRangeValue(value: number, min: number, max: number): number | undefined {
        if (isNaN(+value) || isNaN(+min) || isNaN(+max)) {
            return undefined;
        }
        return (value < min) ? min : ((value > max) ? max : value);
    }

    public static generateOfferItemsHash(
        offer: IImportOffer,
        externalIdName: 'externalGuid' | 'vendorCode',
        apiExternalIdName: 'guid' | 'vendorCode'
    ): string | undefined {
        let offerItem: IAPIProductItem;
        let offerModuleItems: IModuleOffers = {};
        let offerItemId: string | undefined;


        if (offer.items && offer.items.length) {
            for (offerItem of offer.items) {
                offerItemId = offerItem[apiExternalIdName];
                if (!offerItemId || !offerItemId.length) {
                    continue;
                }
                offerItemId = '' + offerItemId;
                if (!offerModuleItems[offerItemId]) {
                    offerModuleItems[offerItemId] = +offerItem.count
                } else {
                    offerModuleItems[offerItemId] += +offerItem.count;
                }
            }
        } else if (offer.type === GOOD_TYPE_MODULE){
            offerItemId = offer[externalIdName];
            if (offerItemId) {
                offerModuleItems[offerItemId] = 1;
            }
        } else {
            return undefined;
        }

        return Object.keys(offerModuleItems).length ? JSON.stringify(this.sortObject(offerModuleItems)) : undefined;
    }
}