import {KitchenService} from '../KitchenService';
import {ThreeUnit} from '../../../objects/threeD/ThreeUnit/ThreeUnit';
import {ThreeApron} from '../../../objects/threeD/details/ThreeApron/ThreeApron';
import {ThreeTabletop} from '../../../objects/threeD/details/ThreeTabletop/ThreeTabletop';
import {ThreeCorner} from '../../../objects/threeD/details/ThreeCorner/ThreeCorner';
import {ThreePlinth} from '../../../objects/threeD/details/ThreePlinth/ThreePlinth';
import {IImportOffer} from '../../../../../common-code/interfaces/api/IImportOffer';
import {
    FACADE_CALCULATE_SELF_AMOUNT,
    GOOD_TYPE_APRON,
    GOOD_TYPE_CORNER,
    GOOD_TYPE_FACADE,
    GOOD_TYPE_HANDLE,
    GOOD_TYPE_INTEGRATED_HANDLE,
    GOOD_TYPE_PLINTH,
    GOOD_TYPE_TABLETOP,
    KDETAIL_PRICE_TYPE_COLLECTIONS,
    KDETAIL_PRICE_TYPE_GOOD,
    KDETAIL_PRICE_TYPE_PM
} from '../../../../../common-code/constants';
import {ThreeKUnitDetail} from '../../../objects/threeD/details/ThreeKUnitDetail/ThreeKUnitDetail';
import {ThreeFacade} from '../../../objects/threeD/details/ThreeFacade/ThreeFacade';
import {ILoadingDetailOffers} from '../../../interfaces/ILoadingDetailOffers';
import {ICalculateDetailPrice} from '../../../interfaces/ICalculateDetailPrice';
import {DETAIL_PRICE_RESULT_ERROR, DETAIL_PRICE_RESULT_OK, DETAIL_PRICE_RESULT_WASTE_PIECES} from '../../../constants';
import {ICalculateDetailPriceResult} from '../../../interfaces/ICalculateDetailPriceResult';
import {i18n} from '../../../../i18n';
import {ICalculateDetailCollection} from '../../../interfaces/ICalculateDetailCollection';
import {IProjectPriceData} from '../../../../../common-code/interfaces/project/IProjectPriceData';
import {IDetailPriceData} from '../../../../../common-code/interfaces/catalog/IDetailPriceData';
import {IDetailKitPriceData} from '../../../../../common-code/interfaces/catalog/IDetailKitPriceData';
import {IUnitKitPrices} from '../../../../../common-code/interfaces/catalog/IUnitKitPrices';
import {IWastePiece} from '../../../../../common-code/interfaces/catalog/IWastePiece';
import {IDetailData} from '../../../../../common-code/interfaces/materials/IDetailData';
import {IProjectServiceData} from '../../../../../common-code/interfaces/project/IProjectServiceData';
import {CommonHelper} from 'common-code';
import {ThreeHandle} from '../../../objects/threeD/details/ThreeHandle/ThreeHandle';
import {IProjectOffers} from '../../../../../common-code/interfaces/project/IProjectOffers';
import {TGoodType} from '../../../../../common-code/types/TGoodType';
import {ThreeLeg} from '../../../objects/threeD/details/ThreeLeg/ThreeLeg';
import {ThreeIntegratedHandle} from '../../../objects/threeD/details/ThreeIntegratedHandle/ThreeIntegratedHandle';
import {IAccessoryPlank} from '../../../../../common-code/interfaces/plank/IAccessoryPlank';

export class PriceManager {
    service: KitchenService;
    loadingPrices: { [key: string]: string };

    constructor(service: KitchenService) {
        this.service = service;
        this.loadingPrices = {};
    }

    public remove() {

    }

    public calculate(force?: boolean): IProjectPriceData {
        let unitsPrice: ICalculateDetailPrice;
        let apronsPrice: ICalculateDetailPrice;
        let cornersPrice: ICalculateDetailPrice;
        let facadesPrice: ICalculateDetailPrice;
        let tabletopsPrice: ICalculateDetailPrice;
        let plinthsPrice: ICalculateDetailPrice;
        let servicesPrice: ICalculateDetailPrice;
        let extraOffersPrice: ICalculateDetailPrice;
        let planksPrice: ICalculateDetailPrice;
        let edgesPrice: ICalculateDetailPrice;
        let handlesPrice: ICalculateDetailPrice;
        let integratedHandlesPrice: ICalculateDetailPrice;
        let detailsPrices: ICalculateDetailPrice[];
        let loadOfferIds: { [key: string]: string } = {};

        unitsPrice = this.calculateUnitsPrice(loadOfferIds, force);
        extraOffersPrice = this.calculateExtraOffers(loadOfferIds);
        facadesPrice = this.calculateFacades();
        handlesPrice = this.calculateHandles();
        integratedHandlesPrice = this.calculateIntegratedHandles();
        tabletopsPrice = this.calculateTabletops();
        apronsPrice = this.calculateAprons();
        cornersPrice = this.calculateCorners();
        plinthsPrice = this.calculatePlinths();
        servicesPrice = this.calculateServices();
        planksPrice = this.calculatePlanks();
        edgesPrice = this.calculateEdges();

        detailsPrices = [
            unitsPrice,
            extraOffersPrice,
            facadesPrice,
            tabletopsPrice,
            apronsPrice,
            cornersPrice,
            plinthsPrice,
            servicesPrice,
            planksPrice,
            edgesPrice,
            handlesPrice,
            integratedHandlesPrice
        ];
        this.loadCalculateOffers(detailsPrices, loadOfferIds);

        return {
            aprons: apronsPrice.price,
            oldAprons: apronsPrice.oldPrice,
            units: unitsPrice.price,
            oldUnits: unitsPrice.oldPrice,
            corners: cornersPrice.price,
            oldCorners: cornersPrice.oldPrice,
            tabletops: tabletopsPrice.price,
            oldTabletops: tabletopsPrice.oldPrice,
            plinths: plinthsPrice.price,
            oldPlinths: plinthsPrice.oldPrice,
            planks: planksPrice.price,
            oldPlanks: planksPrice.oldPrice,
            edges: edgesPrice.price,
            oldEdges: edgesPrice.oldPrice,
            facades: facadesPrice.price,
            oldFacades: facadesPrice.oldPrice,
            handles: handlesPrice.price,
            oldHandles: handlesPrice.oldPrice,
            integratedHandles: integratedHandlesPrice.price,
            oldIntegratedHandles: integratedHandlesPrice.oldPrice,
            services: servicesPrice.price,
            oldServices: servicesPrice.oldPrice,
            extraOffers: extraOffersPrice.price,
            oldExtraOffers: extraOffersPrice.oldPrice,
            full: this.calculateFullPrice(detailsPrices),
            oldFull: this.calculateOldFullPrice(detailsPrices)
        };
    }

    public calculateUnitsPrice(loadOfferIds: { [key: string]: string }, force?: boolean): ICalculateDetailPrice {
        let unitsPrice: number = 0;
        let unitsOldPrice: number = 0;
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let unitOfferIds: string[];
        let unitOfferId: string;

        units = this.service.getObjects();
        for (unit of units) {
            unit.calculatePriceData(force);
            unit.updatePriceErrors();
            unitOfferIds = unit.getLoadingOfferIds();
            if (unitOfferIds.length > 0) {
                for (unitOfferId of unitOfferIds) {
                    loadOfferIds[unitOfferId] = unitOfferId;
                }
            }
            unitsPrice += unit.getPrice();
            unitsOldPrice += unit.getOldPrice();
        }

        return {
            price: unitsPrice,
            oldPrice: unitsOldPrice > unitsPrice ? unitsOldPrice : undefined,
            loadOffers: {}
        };
    }

    private calculateFullPrice(detailsPrices: ICalculateDetailPrice[]): number {
        let price: number = 0;
        let detailsPrice: ICalculateDetailPrice;

        for (detailsPrice of detailsPrices) {
            price += detailsPrice.price;
        }

        return price;
    }

    private calculateOldFullPrice(detailsPrices: ICalculateDetailPrice[]): number | undefined {
        if (!detailsPrices.filter(value => value.oldPrice).length) {
            return undefined;
        }
        let price: number = 0;
        let detailsPrice: ICalculateDetailPrice;

        for (detailsPrice of detailsPrices) {
            price += detailsPrice.oldPrice ? detailsPrice.oldPrice : detailsPrice.price;
        }

        return price;
    }

    private loadCalculateOffers(detailsPrices: ICalculateDetailPrice[], loadOfferIds: { [key: string]: string }) {
        let index: string;
        let detailPriceData: IDetailPriceData;
        let offer: IImportOffer | undefined;
        let detailPrice: IDetailPriceData;
        let detailsPrice: ICalculateDetailPrice;
        let priceData: IDetailKitPriceData;
        let loadOfferIdsArray: string[];
        let hash: string;
        let detail: ThreeKUnitDetail | ThreeFacade | ThreeHandle | undefined;

        for (detailsPrice of detailsPrices) {
            for (index in detailsPrice.loadOffers) {
                for (detailPriceData of detailsPrice.loadOffers[index].detailPrices) {
                    offer = detailPriceData.offer;
                    if (offer) {
                        if (!this.service.checkLoadOfferPrice(offer[this.service.getOfferExternalId()])) {
                            loadOfferIds[offer[this.service.getOfferExternalId()]] = offer[this.service.getOfferExternalId()];
                        }
                    }
                }
            }
        }
        if (Object.values(loadOfferIds).length > 0) {
            loadOfferIdsArray = Object.values(loadOfferIds).sort();
            hash = CommonHelper.md5(loadOfferIdsArray);
            if (!this.loadingPrices[hash]) {
                this.loadingPrices[hash] = hash;
                this.service.loadPrices(loadOfferIdsArray).then((offerPrices: IUnitKitPrices) => {
                    if (Object.keys(offerPrices).length > 0) {
                        for (detailsPrice of detailsPrices) {
                            for (index in detailsPrice.loadOffers) {
                                detail = detailsPrice.loadOffers[index].detail;
                                if (!detail) {
                                    continue;
                                }
                                priceData = {
                                    id: '' + detail.getId(),
                                    price: 0,
                                    errors: [],
                                    kit: detailsPrice.loadOffers[index].detailPrices,
                                    count: 1
                                };
                                for (detailPrice of detailsPrice.loadOffers[index].detailPrices) {
                                    priceData.price += detailPrice.price;
                                }
                                detail.setPriceData(priceData);
                            }
                            if (detailsPrice.rebuildAccessories) {
                                this.service.rebuildAccessoriesPriceData();
                            }
                        }
                        this.service.calculateProjectPrice(true);
                    }
                    delete this.loadingPrices[hash];
                })
            }
        }
    }

    private calculateTabletops(): ICalculateDetailPrice {
        let tabletops: ThreeTabletop[];
        let tabletopsByGoods: ThreeTabletop[] = [];
        let tabletopsByCollections: ThreeTabletop[] = [];
        let tabletopsByPm: ThreeTabletop[] = [];
        let tabletop: ThreeTabletop;
        let tabletopsPriceData: { price: number, oldPrice: number };
        let tabletopsPrice: number = 0;
        let tabletopsOldPrice: number = 0;
        let loadOffers: { [key: string]: ILoadingDetailOffers } = {};

        tabletops = this.service.getTabletops();
        for (tabletop of tabletops) {
            switch (tabletop.getPriceType()) {
                case KDETAIL_PRICE_TYPE_PM:
                    tabletopsByPm.push(tabletop);
                    break;
                case KDETAIL_PRICE_TYPE_GOOD:
                    tabletopsByGoods.push(tabletop);
                    break;
                case KDETAIL_PRICE_TYPE_COLLECTIONS:
                    tabletopsByCollections.push(tabletop);
                    break;
            }
        }
        tabletopsPriceData = this.calculateDetailsByPm(tabletopsByPm, GOOD_TYPE_TABLETOP, loadOffers);
        tabletopsPrice += tabletopsPriceData.price;
        tabletopsOldPrice += tabletopsPriceData.oldPrice;
        tabletopsPriceData = this.calculateDetailsByGoods(tabletopsByGoods, GOOD_TYPE_TABLETOP, loadOffers);
        tabletopsPrice += tabletopsPriceData.price;
        tabletopsOldPrice += tabletopsPriceData.oldPrice;
        tabletopsPriceData = this.calculateDetailsByCollections(tabletopsByCollections, GOOD_TYPE_TABLETOP, loadOffers);
        tabletopsPrice += tabletopsPriceData.price;
        tabletopsOldPrice += tabletopsPriceData.oldPrice;

        return {
            price: tabletopsPrice,
            oldPrice: tabletopsOldPrice > tabletopsPrice ? tabletopsOldPrice : undefined,
            loadOffers: loadOffers
        };
    }

    private calculateAprons(): ICalculateDetailPrice {
        let aprons: ThreeApron[];
        let apronsByGoods: ThreeApron[] = [];
        let apronsByCollections: ThreeApron[] = [];
        let apronsByPm: ThreeApron[] = [];
        let apron: ThreeApron;
        let apronsPriceData: { price: number, oldPrice: number };
        let apronsPrice: number = 0;
        let apronsOldPrice: number = 0;
        let loadOffers: { [key: string]: ILoadingDetailOffers } = {};

        aprons = this.service.getAprons();
        for (apron of aprons) {
            switch (apron.getPriceType()) {
                case KDETAIL_PRICE_TYPE_PM:
                    apronsByPm.push(apron);
                    break;
                case KDETAIL_PRICE_TYPE_GOOD:
                    apronsByGoods.push(apron);
                    break;
                case KDETAIL_PRICE_TYPE_COLLECTIONS:
                    apronsByCollections.push(apron);
                    break;
            }
        }
        apronsPriceData = this.calculateDetailsByPm(apronsByPm, GOOD_TYPE_APRON, loadOffers);
        apronsPrice += apronsPriceData.price;
        apronsOldPrice += apronsPriceData.oldPrice;
        apronsPriceData = this.calculateDetailsByGoods(apronsByGoods, GOOD_TYPE_APRON, loadOffers);
        apronsPrice += apronsPriceData.price;
        apronsOldPrice += apronsPriceData.oldPrice;
        apronsPriceData = this.calculateDetailsByCollections(apronsByCollections, GOOD_TYPE_APRON, loadOffers);
        apronsPrice += apronsPriceData.price;
        apronsOldPrice += apronsPriceData.oldPrice;

        return {
            price: apronsPrice,
            oldPrice: apronsOldPrice > apronsPrice ? apronsOldPrice : undefined,
            loadOffers: loadOffers
        };
    }

    private setPriceResultToDetailFromOffers(
        details: ThreeKUnitDetail[],
        offers: IImportOffer[],
        priceData: { price: number, oldPrice: number },
        loadOffers: { [key: string]: ILoadingDetailOffers },
        wastePieces: IWastePiece[],
        type: TGoodType
    ) {
        let detailsPull: ThreeKUnitDetail[] | undefined;
        let pullDetail: ThreeKUnitDetail;
        let offer: IImportOffer;
        let detailPrice: ICalculateDetailPriceResult;
        let sortOffers: IImportOffer[];
        let detail: ThreeKUnitDetail;
        let newDetails: ThreeKUnitDetail[];

        sortOffers = [...offers].sort(function (a, b) {
            return +a.width - +b.width;
        });
        for (offer of sortOffers) {
            detailsPull = this.findCombinationDetails(details, offer.width);
            if (detailsPull) {
                for (pullDetail of detailsPull) {
                    detailPrice = this.calculateDetailPriceResult(pullDetail, [offer], wastePieces, type);
                    priceData.price += detailPrice.price;
                    priceData.oldPrice += detailPrice.oldPrice;
                    this.setPriceResultToDetail(detailPrice, pullDetail, loadOffers, type);
                }
                newDetails = [];
                for (detail of details) {
                    if (!detailsPull.includes(detail)) {
                        newDetails.push(detail);
                    }
                }
                details = newDetails;
            }
            if (details.length <= 0) {
                break;
            }
        }

        return {
            details: details,
            price: priceData.price,
            oldPrice: priceData.oldPrice
        };
    }

    private setPriceResultToDetailFromWastePieces(
        details: ThreeKUnitDetail[],
        wastePieces: IWastePiece[],
        loadOffers: { [key: string]: ILoadingDetailOffers },
        type: string
    ): ThreeKUnitDetail[] {
        let wastePiece: IWastePiece;
        let detailsPull: ThreeKUnitDetail[] | undefined;
        let pullDetail: ThreeKUnitDetail;
        let detail: ThreeKUnitDetail;
        let newDetails: ThreeKUnitDetail[];

        for (wastePiece of wastePieces) {
            detailsPull = this.findCombinationDetails(details, wastePiece.length);
            if (detailsPull !== undefined) {
                for (pullDetail of detailsPull) {
                    this.setPriceResultToDetail(
                        {
                            result: DETAIL_PRICE_RESULT_WASTE_PIECES,
                            price: 0,
                            oldPrice: 0,
                            wastePiece: wastePiece,
                            offers: {}
                        },
                        pullDetail,
                        loadOffers,
                        type
                    );
                }
                newDetails = [];
                for (detail of details) {
                    if (!detailsPull.includes(detail)) {
                        newDetails.push(detail);
                    }
                }
                details = newDetails;
            }
            if (details.length <= 0) {
                break;
            }
        }

        return details;
    }

    private calculateDetailsByCollection(
        detailsCollection: ICalculateDetailCollection,
        loadOffers: { [key: string]: ILoadingDetailOffers },
        type: TGoodType
    ): { price: number, oldPrice: number } {
        let detailMaterialData: IDetailData;
        let wastePieces: IWastePiece[];
        let wastePiece: IWastePiece;
        let offers: IImportOffer[];
        let detail: ThreeKUnitDetail;
        let bigLengthDetails: ThreeKUnitDetail[];
        let smallLengthDetails: ThreeKUnitDetail[];
        let smallLengthDetailsFromOffers: { details: ThreeKUnitDetail[], price: number, oldPrice: number };
        let price: number = 0;
        let oldPrice: number = 0;
        let detailPrice: ICalculateDetailPriceResult;

        detailMaterialData = detailsCollection.data;
        detailsCollection.items.sort((a, b) => {
            return b.getLength() - a.getLength();
        })
        wastePieces = [];
        offers = this.getAvailableDetailOffers(detailsCollection.items[0], detailMaterialData, type, loadOffers);
        if (offers.length <= 0) {
            for (detail of detailsCollection.items) {
                this.setErrorToDetail(detail, i18n.t('Ошибка расчета цены. Не найден товар в каталоге'), type);
            }
            return {price: price, oldPrice: oldPrice};
        }
        bigLengthDetails = detailsCollection.items.filter(kUnitDetail => kUnitDetail.getLength() > offers[0].width);
        smallLengthDetails = detailsCollection.items.filter(kUnitDetail => kUnitDetail.getLength() <= offers[0].width);
        for (detail of bigLengthDetails) {
            detailPrice = this.calculateDetailPriceResult(detail, offers, wastePieces, type);
            price += detailPrice.price;
            oldPrice += detailPrice.oldPrice;
            this.setPriceResultToDetail(detailPrice, detail, loadOffers, type);
        }
        smallLengthDetails = this.setPriceResultToDetailFromWastePieces(
            smallLengthDetails,
            wastePieces,
            loadOffers,
            type
        );
        smallLengthDetailsFromOffers = this.setPriceResultToDetailFromOffers(
            smallLengthDetails,
            offers,
            {price: price, oldPrice: oldPrice},
            loadOffers,
            wastePieces,
            type
        );
        smallLengthDetails = smallLengthDetailsFromOffers.details;
        price = smallLengthDetailsFromOffers.price;
        oldPrice = smallLengthDetailsFromOffers.oldPrice;
        for (detail of smallLengthDetails) {
            detailPrice = this.calculateDetailPriceResult(detail, offers, wastePieces, type);
            price += detailPrice.price;
            oldPrice += detailPrice.oldPrice;
            this.setPriceResultToDetail(detailPrice, detail, loadOffers, type);
        }
        if (wastePieces.length > 0) {
            for (wastePiece of wastePieces) {
                for (detail of detailsCollection.items) {
                    if (detail.getId() === wastePiece.parentId) {
                        loadOffers[detail.getId()].wastePiece = wastePiece;
                        break;
                    }
                }
            }
        }

        return {price: price, oldPrice: oldPrice};
    }

    private sortDetailsByCollections(details: ThreeKUnitDetail[]): { [key: string]: ICalculateDetailCollection } {
        let detailsCollection: { [key: string]: ICalculateDetailCollection } = {};
        let detail: ThreeKUnitDetail;
        let collectionId: string;

        for (detail of details) {
            collectionId = detail.getPriceCollectionId();
            if (!detailsCollection[collectionId]) {
                detailsCollection[collectionId] = {
                    data: detail.getMaterialData(),
                    items: []
                };
            }
            detailsCollection[collectionId].items.push(detail);
        }

        return detailsCollection;
    }

    private findCombinationDetails(details: ThreeKUnitDetail[], length: number): ThreeKUnitDetail[] | undefined {
        let index: number;
        let tempPull: ThreeKUnitDetail[];
        let p: number;
        let pullSum: number;
        let j: number;

        pullSum = details.reduce((partialSum: number, a: ThreeKUnitDetail) => partialSum + a.getLength(), 0);
        if (pullSum <= length) {
            return details;
        }
        for (index = 1; index < 2 ** details.length + 1; index++) {
            tempPull = [];
            p = 1;
            for (j = 0; j < details.length; j++) {
                if (index & p) {
                    tempPull.push(details[j]);
                }
                p *= 2;
            }
            pullSum = tempPull.reduce((partialSum: number, a: ThreeKUnitDetail) => partialSum + a.getLength(), 0);
            if (pullSum >= length - 100 && pullSum <= length) {
                return tempPull;
            }
        }

        return undefined;
    }

    private setWastePieceToDetail(detail: ThreeKUnitDetail, detailPrice: ICalculateDetailPriceResult) {
        detail.setPriceData({
            id: '' + detail.getId(),
            price: detailPrice.price,
            kit: [],
            count: 1,
            errors: [],
            note: i18n.t('Из остатков другого товара') || undefined,
            wastePiece: detailPrice.wastePiece
        });
    }

    private setErrorToDetail(detail: ThreeKUnitDetail, message: string, type: string) {
        detail.setPriceData({
            id: '' + detail.getId(),
            price: 0,
            kit: [],
            count: 1,
            errors: [{id: 'apronGood', group: 'price', message: message}],
        });
        detail.setError({
            id: type + 'Good',
            group: 'price',
            message: message
        });
    }

    private setPriceResultToDetail(
        detailPrice: ICalculateDetailPriceResult,
        detail: ThreeKUnitDetail,
        loadOffers: { [key: string]: ILoadingDetailOffers },
        type: string
    ) {
        let index: string;
        let itemOffer: IImportOffer | undefined;
        switch (detailPrice.result) {
            case DETAIL_PRICE_RESULT_OK:
                if (!loadOffers[detail.getId()]) {
                    loadOffers[detail.getId()] = {
                        detailPrices: [],
                        detail: detail
                    };
                }
                detail.setPriceData({
                    id: '' + detail.getId(),
                    price: detailPrice.price,
                    kit: Object.values(detailPrice.offers),
                    count: 1,
                    errors: [],
                });
                for (index in detailPrice.offers) {
                    itemOffer = detailPrice.offers[index].offer;
                    if (itemOffer !== undefined) {
                        loadOffers[detail.getId()].detailPrices.push(detailPrice.offers[index])
                    } else {
                        this.setErrorToDetail(detail, i18n.t('Ошибка расчета цены. Не найден товар в каталоге'), type);
                    }
                }
                break;
            case DETAIL_PRICE_RESULT_ERROR:
                this.setErrorToDetail(
                    detail,
                    i18n.t('Ошибка расчета цены. Не найден товар в каталоге'),
                    type
                );
                break;
            case DETAIL_PRICE_RESULT_WASTE_PIECES:
                this.setWastePieceToDetail(detail, detailPrice);
                break;
        }
    }

    private calculateDetailPriceResult(
        detail: ThreeKUnitDetail,
        offers: IImportOffer[],
        wastePieces: IWastePiece[],
        type: TGoodType,
    ): ICalculateDetailPriceResult {
        let price: number = 0;
        let oldPrice: number = 0;
        let oldItemPrice: number | undefined;
        let wastePiece: IWastePiece | undefined;
        let offer: IImportOffer;
        let normalizeOffer: IImportOffer | undefined;
        let priceOffers: { [key: string]: IDetailPriceData } = {};
        let length: number;
        let detailPriceData: IDetailPriceData;
        let isOk: boolean;
        let index: string;

        wastePiece = this.findWastePiece(detail, wastePieces);
        if (wastePiece) {
            priceOffers[wastePiece.offer[this.service.getOfferExternalId()]] =
                this.service.getDetailPriceData(
                    wastePiece.offer,
                    1,
                    0,
                    this.service.getPriceCellByGoodType(type),
                    0
                );
            return {
                result: DETAIL_PRICE_RESULT_WASTE_PIECES,
                offers: priceOffers,
                price: 0,
                oldPrice: 0,
                wastePiece: wastePiece,
            }
        }
        if (offers.length <= 0) {
            return {
                result: DETAIL_PRICE_RESULT_ERROR,
                offers: {},
                price: 0,
                oldPrice: 0,
            }
        }
        length = detail.getLength();
        while (length > offers[0].width) {
            detailPriceData = this.service.getDetailPriceData(
                offers[0],
                1,
                0,
                this.service.getPriceCellByGoodType(type),
                0
            );
            if (!priceOffers[offers[0][this.service.getOfferExternalId()]]) {
                priceOffers[offers[0][this.service.getOfferExternalId()]] = detailPriceData;
            } else {
                priceOffers[offers[0][this.service.getOfferExternalId()]].count += 1;
            }
            length -= offers[0].width;
        }
        for (offer of offers) {
            if (length <= offer.width) {
                normalizeOffer = offer;
            }
        }
        if (!normalizeOffer) {
            return {
                result: DETAIL_PRICE_RESULT_ERROR,
                offers: priceOffers,
                price: 0,
                oldPrice: 0,
            }
        }
        detailPriceData = this.service.getDetailPriceData(
            normalizeOffer,
            1,
            0,
            this.service.getPriceCellByGoodType(type),
            0
        );
        if (!priceOffers[normalizeOffer[this.service.getOfferExternalId()]]) {
            priceOffers[normalizeOffer[this.service.getOfferExternalId()]] = detailPriceData;
        } else {
            priceOffers[normalizeOffer[this.service.getOfferExternalId()]].count += 1;
        }
        if ((normalizeOffer.width - length) > 0) {
            wastePieces.push({
                offer: normalizeOffer,
                length: (normalizeOffer.width - length),
                parentId: detail.getId()
            });
        }
        isOk = true;
        for (index in priceOffers) {
            price += priceOffers[index].price * priceOffers[index].count;
            oldItemPrice = priceOffers[index].oldPrice;
            if (oldItemPrice) {
                oldPrice += oldItemPrice * priceOffers[index].count;
            }
            if (priceOffers[index].errors.length > 0) {
                isOk = false;
            }
        }

        return {
            result: isOk ? DETAIL_PRICE_RESULT_OK : DETAIL_PRICE_RESULT_ERROR,
            offers: priceOffers,
            price: price,
            oldPrice: oldPrice
        }
    }

    private findWastePiece(detail: ThreeKUnitDetail, wastePieces: IWastePiece[]): IWastePiece | undefined {
        let index: string;
        let wastePiece: IWastePiece | undefined;
        for (index in wastePieces) {
            if (wastePieces[index].length >= detail.getLength()) {
                wastePiece = {...wastePieces[index]};
                if ((wastePieces[index].length - detail.getLength()) <= this.service.getDetailMinPiece(detail, wastePieces[index].offer)) {
                    wastePieces.splice(+index, 1);
                } else {
                    wastePieces[index].length -= detail.getLength();
                }
                break;
            }
        }

        return wastePiece;
    }

    private getAvailableDetailOffers(
        detail: ThreeKUnitDetail,
        detailData: IDetailData,
        type: TGoodType,
        loadOffers: { [key: string]: ILoadingDetailOffers }
    ): IImportOffer[] {
        let availableOffers: IImportOffer[];
        let offers: IImportOffer[];
        let offer: IImportOffer | undefined;

        availableOffers = [];

        offers = this.service.getDetailCollectionOffers(detailData, type);
        switch (type) {
            case GOOD_TYPE_PLINTH:
                for (offer of offers) {
                    if (offer.depth === detail.getHeight() &&
                        offer.height === detail.getWidth() &&
                        (offer.functionalType === undefined || offer.functionalType === 'default')) {
                        availableOffers.push(offer);
                    }
                }
                break;
            case GOOD_TYPE_INTEGRATED_HANDLE:
                for (offer of offers) {
                    if (offer.depth === detail.getHeight() &&
                        offer.height >= detail.getWidth() &&
                        detail.isAvailableFunctionalType(offer.functionalType)) {
                        availableOffers.push(offer);
                    }
                }
                break;
            default:
                for (offer of offers) {
                    if (offer.height === detail.getHeight() &&
                        offer.depth >= detail.getWidth() &&
                        (offer.functionalType === undefined || offer.functionalType === 'default')) {
                        availableOffers.push(offer);
                    }
                }
                break;
        }
        for (offer of availableOffers) {
            if (!this.service.checkLoadOfferPrice(offer[this.service.getOfferExternalId()])) {
                if (!loadOffers[detail.getId()]) {
                    loadOffers[detail.getId()] = {
                        detailPrices: [],
                        detail: detail
                    };
                }
                loadOffers[detail.getId()].detailPrices.push(this.service.getDetailPriceData(
                    offer,
                    1,
                    0,
                    this.service.getPriceCellByGoodType(type),
                    0
                ));
            }
        }
        availableOffers.sort(function (a, b) {
            return +b.width - +a.width;
        });

        return availableOffers;
    }

    private calculateDetailsByPm(
        detailsByGPm: ThreeKUnitDetail[],
        type: string,
        loadOffers: { [key: string]: ILoadingDetailOffers }
    ): { price: number, oldPrice: number } {
        let allPrice: number = 0;
        let oldPrice: number = 0;
        if (detailsByGPm.length <= 0) {
            return {price: allPrice, oldPrice: oldPrice};
        }
        debugger;
        console.log(type, loadOffers);

        return {price: allPrice, oldPrice: oldPrice};
    }

    private calculateDetailsByCollections(
        detailsByCollections: ThreeKUnitDetail[],
        type: TGoodType,
        loadOffers: { [key: string]: ILoadingDetailOffers }
    ): { price: number, oldPrice: number } {
        let priceData: { price: number, oldPrice: number };
        let allPrice: number = 0;
        let oldPrice: number = 0;
        let detailsCollection: { [key: string]: ICalculateDetailCollection };
        let collectionId: string;

        detailsCollection = this.sortDetailsByCollections(detailsByCollections);
        for (collectionId in detailsCollection) {
            priceData = this.calculateDetailsByCollection(detailsCollection[collectionId], loadOffers, type);
            allPrice += priceData.price;
            oldPrice += priceData.oldPrice;
        }

        return {price: allPrice, oldPrice: oldPrice};
    }

    private calculateDetailsByGoods(
        detailsByGoods: ThreeKUnitDetail[],
        type: TGoodType,
        loadOffers: { [key: string]: ILoadingDetailOffers }
    ): { price: number, oldPrice: number } {
        let detail: ThreeKUnitDetail;
        let errorDetail: ThreeKUnitDetail;
        let offer: IImportOffer | undefined;
        let priceData: IDetailKitPriceData;
        let allPrice: number = 0;
        let oldPrice: number = 0;
        let detailPriceData: IDetailPriceData;

        if (detailsByGoods.length <= 0) {
            return {price: allPrice, oldPrice: oldPrice};
        }
        detailsByGoods.sort((a, b) => {
            return b.getLength() - a.getLength();
        });
        for (detail of detailsByGoods) {
            offer = this.service.getDetailOffer(detail, type);
            if (offer) {
                priceData = this.service.getDetailKitPriceData(detail, [offer], 1, {}, type);
                detail.setPriceData(priceData);
                allPrice += detail.getPrice();
                oldPrice += detail.getOldPrice();
                for (detailPriceData of priceData.kit) {
                    if (detailPriceData.offer &&
                        !this.service.checkLoadOfferPrice(detailPriceData.offer[this.service.getOfferExternalId()])) {
                        if (!loadOffers[detail.getId()]) {
                            loadOffers[detail.getId()] = {
                                detailPrices: [],
                                detail: detail
                            };
                        }
                        loadOffers[detail.getId()].detailPrices.push(detailPriceData);
                    }
                }
            } else {
                for (errorDetail of detail.getGroupItems()) {
                    errorDetail.setError({
                        id: type + 'Good',
                        group: 'price',
                        message: i18n.t('Не найден товар в каталоге')
                    });
                }
            }
        }

        return {price: allPrice, oldPrice: oldPrice};
    }

    private calculateCorners(): ICalculateDetailPrice {
        let corners: ThreeCorner[];
        let cornersByGoods: ThreeCorner[] = [];
        let cornersByCollections: ThreeCorner[] = [];
        let cornersByPm: ThreeCorner[] = [];
        let corner: ThreeCorner;
        let cornersPriceData: { price: number, oldPrice: number };
        let cornersPrice: number = 0;
        let cornersOldPrice: number = 0;
        let loadOffers: { [key: string]: ILoadingDetailOffers } = {};

        corners = this.service.getCorners();
        for (corner of corners) {
            switch (corner.getPriceType()) {
                case KDETAIL_PRICE_TYPE_PM:
                    cornersByPm.push(corner);
                    break;
                case KDETAIL_PRICE_TYPE_GOOD:
                    cornersByGoods.push(corner);
                    break;
                case KDETAIL_PRICE_TYPE_COLLECTIONS:
                    cornersByCollections.push(corner);
                    break;
            }
        }
        cornersPriceData = this.calculateDetailsByPm(cornersByPm, GOOD_TYPE_CORNER, loadOffers);
        cornersPrice += cornersPriceData.price;
        cornersOldPrice += cornersPriceData.oldPrice;
        cornersPriceData = this.calculateDetailsByGoods(cornersByGoods, GOOD_TYPE_CORNER, loadOffers);
        cornersPrice += cornersPriceData.price;
        cornersOldPrice += cornersPriceData.oldPrice;
        cornersPriceData = this.calculateDetailsByCollections(cornersByCollections, GOOD_TYPE_CORNER, loadOffers);
        cornersPrice += cornersPriceData.price;
        cornersOldPrice += cornersPriceData.oldPrice;

        return {
            price: cornersPrice,
            oldPrice: cornersOldPrice > cornersPrice ? cornersOldPrice : undefined,
            loadOffers: loadOffers
        };
    }

    private calculatePlinths(): ICalculateDetailPrice {
        let plinths: ThreePlinth[];
        let plinthsByGoods: ThreePlinth[] = [];
        let plinthsByCollections: ThreePlinth[] = [];
        let plinthsByPm: ThreePlinth[] = [];
        let plinth: ThreePlinth;
        let plinthsPriceData: { price: number, oldPrice: number };
        let plinthsPrice: number = 0;
        let plinthsOldPrice: number = 0;
        let loadOffers: { [key: string]: ILoadingDetailOffers } = {};

        plinths = this.service.getPlinths();
        for (plinth of plinths) {
            switch (plinth.getPriceType()) {
                case KDETAIL_PRICE_TYPE_PM:
                    plinthsByPm.push(plinth);
                    break;
                case KDETAIL_PRICE_TYPE_GOOD:
                    plinthsByGoods.push(plinth);
                    break;
                case KDETAIL_PRICE_TYPE_COLLECTIONS:
                    plinthsByCollections.push(plinth);
                    break;
            }
        }
        plinthsPriceData = this.calculateDetailsByPm(plinthsByPm, GOOD_TYPE_PLINTH, loadOffers);
        plinthsPrice += plinthsPriceData.price;
        plinthsOldPrice += plinthsPriceData.oldPrice;
        plinthsPriceData = this.calculateDetailsByGoods(plinthsByGoods, GOOD_TYPE_PLINTH, loadOffers);
        plinthsPrice += plinthsPriceData.price;
        plinthsOldPrice += plinthsPriceData.oldPrice;
        plinthsPriceData = this.calculateDetailsByCollections(plinthsByCollections, GOOD_TYPE_PLINTH, loadOffers);
        plinthsPrice += plinthsPriceData.price;
        plinthsOldPrice += plinthsPriceData.oldPrice;

        return {
            price: plinthsPrice,
            oldPrice: plinthsOldPrice > plinthsPrice ? plinthsOldPrice : undefined,
            loadOffers: loadOffers
        };
    }

    private calculateIntegratedHandles(): ICalculateDetailPrice {
        let integratedHandles: ThreeIntegratedHandle[];
        let integratedHandlesByGoods: ThreeIntegratedHandle[] = [];
        let integratedHandlesByCollections: ThreeIntegratedHandle[] = [];
        let integratedHandlesByPm: ThreeIntegratedHandle[] = [];
        let integratedHandle: ThreeIntegratedHandle;
        let integratedHandlesPriceData: { price: number, oldPrice: number };
        let integratedHandlesPrice: number = 0;
        let integratedHandlesOldPrice: number = 0;
        let loadOffers: { [key: string]: ILoadingDetailOffers } = {};

        integratedHandles = this.service.getIntegrationHandles();
        for (integratedHandle of integratedHandles) {
            switch (integratedHandle.getPriceType()) {
                case KDETAIL_PRICE_TYPE_PM:
                    integratedHandlesByPm.push(integratedHandle);
                    break;
                case KDETAIL_PRICE_TYPE_GOOD:
                    integratedHandlesByGoods.push(integratedHandle);
                    break;
                case KDETAIL_PRICE_TYPE_COLLECTIONS:
                    integratedHandlesByCollections.push(integratedHandle);
                    break;
            }
        }
        integratedHandlesPriceData = this.calculateDetailsByPm(integratedHandlesByPm, GOOD_TYPE_INTEGRATED_HANDLE, loadOffers);
        integratedHandlesPrice += integratedHandlesPriceData.price;
        integratedHandlesOldPrice += integratedHandlesPriceData.oldPrice;
        integratedHandlesPriceData = this.calculateDetailsByGoods(integratedHandlesByGoods, GOOD_TYPE_INTEGRATED_HANDLE, loadOffers);
        integratedHandlesPrice += integratedHandlesPriceData.price;
        integratedHandlesOldPrice += integratedHandlesPriceData.oldPrice;
        integratedHandlesPriceData = this.calculateDetailsByCollections(integratedHandlesByCollections, GOOD_TYPE_INTEGRATED_HANDLE, loadOffers);
        integratedHandlesPrice += integratedHandlesPriceData.price;
        integratedHandlesOldPrice += integratedHandlesPriceData.oldPrice;

        return {
            price: integratedHandlesPrice,
            oldPrice: integratedHandlesOldPrice > integratedHandlesPrice ? integratedHandlesOldPrice : undefined,
            loadOffers: loadOffers
        };
    }

    private calculateFacades(): ICalculateDetailPrice {
        let facadesPrice: number = 0;
        let facadesOldPrice: number = 0;
        let facades: ThreeFacade[];
        let facade: ThreeFacade;
        let errorFacade: ThreeFacade;
        let offer: IImportOffer | undefined;
        let offers: IImportOffer[] | undefined;
        let priceData: IDetailKitPriceData;
        let detailPriceData: IDetailPriceData;
        let loadOffers: { [key: string]: ILoadingDetailOffers } = {};

        offers = this.service.getOffersByCatalogCode(GOOD_TYPE_FACADE);
        if (!offers.length) {
            return {
                loadOffers: {},
                price: 0
            };
        }
        facades = this.service.getProjectFacades();
        for (facade of facades) {
            if (facade.getCalculateType() !== FACADE_CALCULATE_SELF_AMOUNT) {
                continue;
            }
            offer = this.service.getFacadeOffer(facade);
            if (offer) {
                priceData = this.service.getDetailKitPriceData(facade, [offer], 1, {}, GOOD_TYPE_FACADE);
                facade.setPriceData(priceData);
                facadesPrice += facade.getPrice();
                facadesOldPrice += facade.getOldPrice()
                for (detailPriceData of priceData.kit) {
                    if (detailPriceData.offer &&
                        !this.service.checkLoadOfferPrice(detailPriceData.offer[this.service.getOfferExternalId()])) {
                        if (!loadOffers[facade.getId()]) {
                            loadOffers[facade.getId()] = {
                                detailPrices: [],
                                detail: facade
                            };
                        }
                        loadOffers[facade.getId()].detailPrices.push(detailPriceData);
                    }
                }
            } else {
                for (errorFacade of facade.getGroupItems()) {
                    errorFacade.setError({
                        id: 'facadeGood',
                        group: 'price',
                        message: 'Не найден товар в каталоге'
                    });
                }
            }
        }

        return {
            price: facadesPrice,
            oldPrice: facadesOldPrice > facadesPrice ? facadesOldPrice : undefined,
            loadOffers: loadOffers
        };
    }

    private calculateHandles(): ICalculateDetailPrice {
        let handles: ThreeHandle[];
        let handle: ThreeHandle;
        let offers: IImportOffer[];
        let price: number = 0;
        let oldPrice: number = 0;
        let loadOffers: { [key: string]: ILoadingDetailOffers } = {};
        let offer: IImportOffer | undefined;
        let priceData: IDetailKitPriceData;
        let detailPriceData: IDetailPriceData;

        offers = this.service.getOffersByCatalogCode(GOOD_TYPE_HANDLE);
        if (!offers.length) {
            return {
                loadOffers: {},
                price: 0
            };
        }
        handles = this.service.getProjectHandles();
        for (handle of handles) {
            if (!handle.isCalculatePrice()) {
                continue;
            }
            offer = this.service.getHandleOffer(handle);
            if (offer) {
                priceData = this.service.getDetailKitPriceData(handle, [offer], 1, {}, GOOD_TYPE_HANDLE);
                handle.setPriceData(priceData);
                price += handle.getPrice();
                oldPrice += handle.getOldPrice()
                for (detailPriceData of priceData.kit) {
                    if (detailPriceData.offer &&
                        !this.service.checkLoadOfferPrice(detailPriceData.offer[this.service.getOfferExternalId()])) {
                        if (!loadOffers[handle.getId()]) {
                            loadOffers[handle.getId()] = {
                                detailPrices: [],
                                detail: handle
                            };
                        }
                        loadOffers[handle.getId()].detailPrices.push(detailPriceData);
                    }
                }
            }
        }

        return {
            price: price,
            oldPrice: oldPrice > price ? oldPrice : undefined,
            loadOffers: loadOffers
        };
    }

    private calculateServices(): ICalculateDetailPrice {
        let price: number = 0;
        let service: IProjectServiceData;

        if (!this.service.projectData.services) {
            return {
                price: price,
                loadOffers: {}
            };
        }
        for (service of this.service.projectData.services) {
            price += service.sum;
        }

        return {
            price: price,
            loadOffers: {}
        };
    }

    private calculatePlanks(): ICalculateDetailPrice {
        let loadOffers: { [key: string]: ILoadingDetailOffers } = {};
        let price: number = 0;
        let oldPrice: number = 0;
        let planks: IAccessoryPlank[];
        let plank: IAccessoryPlank;

        planks = this.service.getSpecPlanks();
        for (plank of planks) {
            if (!plank.priceData) {
                continue;
            }
            if (!this.service.checkLoadOfferPrice(plank.priceData.id)) {
                if (!loadOffers[plank.priceData.id]) {
                    loadOffers[plank.priceData.id] = {
                        detailPrices: [plank.priceData],
                        detail: undefined
                    };
                }
            }
            price += plank.priceData.price * plank.priceData.count;
            if (plank.priceData.oldPrice !== undefined) {
                oldPrice += plank.priceData.oldPrice * plank.priceData.count;
            }
        }
        return {
            price: price,
            oldPrice: oldPrice,
            loadOffers: loadOffers,
            rebuildAccessories: true
        };
    }

    private calculateEdges(): ICalculateDetailPrice {
        return {
            price: 0,
            loadOffers: {}
        };
    }

    private calculateAutoProjectOffers() {
        this.calculateAutoLegsProjectOffers();
        this.calculateAutoPlinthClipsProjectOffers();
        this.service.updateProjectOffers(false);
    }

    private calculateAutoLegsProjectOffers(): void {
        let legs: ThreeLeg[];
        let specLegs: { [key: string]: { legs: ThreeLeg[], offer: IImportOffer } } = {};
        let id: string;
        let leg: ThreeLeg;
        let offer: IImportOffer | undefined;

        legs = this.service.getProjectLegs();
        for (leg of legs) {
            if (leg.getHeight() !== this.service.getBottomUnitLegsHeight(leg.getTechnologMapFacadeId())) {
                id = leg.getGeometryType() + '_' + leg.getHeight();
                if (!specLegs[id]) {
                    offer = this.service.getLegOffer(leg);
                    if (offer) {
                        specLegs[id] = {
                            legs: [],
                            offer: offer
                        };
                    }
                }
                if (specLegs[id]) {
                    specLegs[id].legs.push(leg);
                }
            }
        }
        for (id in specLegs) {
            if (specLegs[id].legs.length) {
                this.service.setAutoProjectOffer(
                    specLegs[id].offer,
                    specLegs[id].legs.length,
                    {part: this.service.getDefaultOrderPart()},
                    false,
                    false
                );
            }
        }
    }

    public calculateAutoPlinthClipsProjectOffers() {
        let plinths: ThreePlinth[];
        let plinth: ThreePlinth;
        let plinthLength: number = 0;
        let clipsCount: number;
        let offer: IImportOffer | undefined;

        if (!this.service.isShowLegs()) {
            return;
        }

        plinths = this.service.getPlinths();
        for (plinth of plinths) {
            plinthLength += plinth.getLength();
        }
        offer = this.service.getFurnitureOffer("plinthClips");
        if (offer) {
            clipsCount = plinthLength > 0 ? Math.ceil((plinthLength / 1000) / 0.5) : 0;
            this.service.setAutoProjectOffer(
                offer,
                clipsCount,
                {part: this.service.getDefaultOrderPart()},
                false,
                false
            );
        }
    }

    private calculateExtraOffers(loadOfferIds: { [key: string]: string }): ICalculateDetailPrice {
        let extraOffers: IProjectOffers | undefined;
        let index: string;
        let price: number = 0;
        let oldPrice: number = 0;

        this.calculateAutoProjectOffers();
        for (extraOffers of [this.service.getProjectOffers(), this.service.getProjectBuiltInEquipmentOffers()]) {
            if (!extraOffers) {
                continue;
            }
            for (index in extraOffers) {
                if (!this.service.checkLoadOfferPrice(extraOffers[index].id)) {
                    loadOfferIds[extraOffers[index].id] = extraOffers[index].id;
                }
                if (extraOffers[index].price) {
                    price += (extraOffers[index].price as number) * extraOffers[index].count;
                }
                if (extraOffers[index].oldPrice) {
                    oldPrice += (extraOffers[index].oldPrice as number) * extraOffers[index].count;
                }
            }
        }

        return {
            price: price,
            oldPrice: oldPrice ? oldPrice : undefined,
            loadOffers: {}
        };
    }
}