import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {
    SIDE_TYPE_FRONT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_NONE,
    SIDE_TYPE_RIGHT,
    UNIT_PLINTHS_TYPE_BACK_NEIGHBOR,
    UNIT_PLINTHS_TYPE_DEFAULT
} from '../../../../../../../common-code/constants';
import {TOptionalSizes} from '../../../../../../../common-code/types/TOptionalSizes';
import {Box3} from 'three';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';
import {TDirectionSideType} from '../../../../../../../common-code/types/TDirectionSideType';
import {
    ThreeBottomEndFullLengthSidewallOpenedCorpus
} from "../../../details/ThreeCorpus/types/ThreeBottomEndFullLengthSidewallOpenedCorpus";
import {
    ISaveBottomUnitEndFullLengthSidewallOpenedData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndFullLengthSidewallOpenedData';
import {
    ISaveBottomEndFullLengthSidewallOpenedCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomEndFullLengthSidewallOpenedCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveLegData} from '../../../../../../../common-code/interfaces/saveData/ISaveLegData';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {MathHelper} from 'common-code';

export class ThreeBottomUnitEndFullLengthSidewallOpened extends ThreeBottomUnit {
    saveData: ISaveBottomUnitEndFullLengthSidewallOpenedData;
    corpus: ThreeBottomEndFullLengthSidewallOpenedCorpus;

    constructor(options: ISaveBottomUnitEndFullLengthSidewallOpenedData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isEndUnit(): boolean {
        return true;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    protected initCorpus(corpusData: ISaveBottomEndFullLengthSidewallOpenedCorpusData): ThreeBottomEndFullLengthSidewallOpenedCorpus {
        return new ThreeBottomEndFullLengthSidewallOpenedCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected calculateInitLegsData(legs?: ISaveLegData[]): ISaveLegData[] | undefined {
        if (legs) {
            let index;

            for (index in legs) {
                switch (+index) {
                    case 0:
                        legs[index].initPosition = {x: '50', z: '50'};
                        break;
                    case 1:
                        legs[index].initPosition = {x: '=({%100}-50)', z: '50'};
                        break;
                    case 2:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                                legs[index].initPosition = {x: '=({%100}-50)', z:  '=({%100}-50)'};
                                break;
                            case SIDE_TYPE_RIGHT:
                                legs[index].initPosition = {x: '=({%100}-50)', z:  this.corpus.getSmallDepth() - 50};
                                break;
                        }
                        break;
                    case 3:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                                legs[index].initPosition = {x: '50', z:  this.corpus.getSmallDepth() - 50};
                                break;
                            case SIDE_TYPE_RIGHT:
                                legs[index].initPosition = {x: '50', z:  '=({%100}-50)'};
                                break;
                        }
                        break;
                }
            }
        }

        return legs;
    }

    protected calculateSidePlinthSizes(depth: number): TOptionalSizes {
        let sizes: TOptionalSizes;
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        switch (this.getPlinthsType()) {
            case UNIT_PLINTHS_TYPE_BACK_NEIGHBOR:
                sizes = {length: depth + (Math.abs(coverBox.min.z) - this.corpus.getDepth()/2)};
                break;
            case UNIT_PLINTHS_TYPE_DEFAULT:
                sizes = {length: depth};
                break;
        }

        return sizes;
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index: string;

        if (!tabletops) {
            tabletops = [{
                id: 0,
                leftPoints: this.getSideTabletopPoints(SIDE_TYPE_LEFT),
                rightPoints: this.getSideTabletopPoints(SIDE_TYPE_RIGHT),
            }];
        }
        for (index in tabletops) {
            tabletops[index].leftPoints = this.getSideTabletopPoints(SIDE_TYPE_LEFT);
            tabletops[index].rightPoints = this.getSideTabletopPoints(SIDE_TYPE_RIGHT);
        }

        return tabletops;
    }

    protected getSideTabletopPoints(sideType: TDirectionSideType) {
        let points: TPoint2D[];
        let tabletopWidth: number;

        tabletopWidth = this.service.getTabletopWidth();
        switch (this.getSideType()) {
            case SIDE_TYPE_RIGHT:
                if (sideType === SIDE_TYPE_LEFT) {
                    points = this.getDefaultSideTabletopPoints(tabletopWidth);
                } else {
                    points = this.getSmallSideTabletopPoints(tabletopWidth);
                }
                break;
            case SIDE_TYPE_LEFT:
            default:
                if (sideType === SIDE_TYPE_RIGHT) {
                    points = this.getDefaultSideTabletopPoints(tabletopWidth);
                } else {
                    points = this.getSmallSideTabletopPoints(tabletopWidth);
                }
                break;
        }

        return points;
    }

    protected getSmallSideTabletopPoints(tabletopWidth: number): TPoint2D[] {
        let points: TPoint2D[] = [];
        let sideWidth: number;
        sideWidth = this.getWidth() - this.corpus.getSmallWidth() - this.corpus.getThickness();

        points.push({x: sideWidth, y: -tabletopWidth / 2});
        points.push({x: sideWidth, y: -this.getDepth() / 2 + this.corpus.getSmallDepth() + this.service.getTabletopFrontGap()});
        points.push({x: 0, y: tabletopWidth / 2});

        return points;
    }

    protected getDefaultSideTabletopPoints(tabletopWidth: number): TPoint2D[] {
        let points: TPoint2D[] = [];

        points.push({x: 0, y: -tabletopWidth / 2});
        points.push({x: 0, y: tabletopWidth / 2});

        return points;
    }

    protected calculateInitPlinthsData(plinths?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index;
        let angleData;

        if (plinths) {
            for (index in plinths) {
                switch (plinths[index].positionType) {
                    case SIDE_TYPE_LEFT:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                            default:
                                plinths[index].sizes = this.calculateSidePlinthSizes(this.corpus.getSmallDepth());
                                break;
                            case SIDE_TYPE_RIGHT:
                                plinths[index].sizes = this.calculateSidePlinthSizes(this.corpus.getDepth());
                                break;
                        }
                        break;
                    case SIDE_TYPE_RIGHT:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                            default:
                                plinths[index].sizes = this.calculateSidePlinthSizes(this.corpus.getDepth());
                                break;
                            case SIDE_TYPE_RIGHT:
                                plinths[index].sizes = this.calculateSidePlinthSizes(this.corpus.getSmallDepth());
                                break;
                        }
                        break;
                    case SIDE_TYPE_FRONT:
                        plinths[index].sizes = {length: this.corpus.getSmallWidth()};
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                            default:
                                plinths[index].position = {x: this.corpus.getWidth()/2 - this.corpus.getSmallWidth()/2};
                                break;
                            case SIDE_TYPE_RIGHT:
                                plinths[index].position = {x: -this.corpus.getWidth()/2 + this.corpus.getSmallWidth()/2};
                                break;
                        }
                        break;
                    case SIDE_TYPE_NONE:
                        angleData = this.getAngleData();
                        plinths[index].sizes = {length: angleData.length};
                        plinths[index].position = {x: angleData.centerPoint.x, z: angleData.centerPoint.y};
                        plinths[index].rotation = {y: angleData.normalAngle};
                        break;
                }
            }
        }

        return plinths;
    }

    protected getAngleData() {
        let pointA, pointB;
        let length: number, normalAngle, centerPoint, parallelLine, centerFacadePoint;

        switch (this.getSideType()) {
            case SIDE_TYPE_RIGHT:
                pointA = {
                    x: -this.corpus.getWidth() / 2 + this.corpus.getSmallWidth(),
                    y: this.corpus.getDepth() / 2
                };
                pointB = {x: this.corpus.getWidth() / 2, y: -this.corpus.getDepth() / 2 + this.corpus.getSmallDepth()};
                break;
            case SIDE_TYPE_LEFT:
            default:
                pointA = {x: -this.corpus.getWidth() / 2, y: -this.corpus.getDepth() / 2 + this.corpus.getSmallDepth()};
                pointB = {
                    x: this.corpus.getWidth() / 2 - this.corpus.getSmallWidth(),
                    y: this.corpus.getDepth() / 2
                };
                break;
        }

        length = MathHelper.getLength2D(pointA, pointB);
        normalAngle = 2 * Math.PI - MathHelper.getNormalAngle({x: pointB.x - pointA.x, y: pointB.y - pointA.y});
        parallelLine = MathHelper.getParallelLinePoints(pointA, pointB, this.corpus.getThickness() / 2);
        centerPoint = MathHelper.getCenterPoint(parallelLine.pointA, parallelLine.pointB);
        parallelLine = MathHelper.getParallelLinePoints(pointA, pointB, -this.corpus.getThickness() / 2);
        centerFacadePoint = MathHelper.getCenterPoint(parallelLine.pointA, parallelLine.pointB);

        return {
            length: length,
            normalAngle: normalAngle,
            centerPoint: centerPoint,
            centerFacadePoint: centerFacadePoint
        };
    }

    protected initApronsData(aprons?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        return this.initApronsCornersData(aprons);
    }

    protected initCornersData(corners?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        return this.initApronsCornersData(corners);
    }

    protected initApronsCornersData(details?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index: string;
        let coverBox: Box3;

        if (details) {
            coverBox = this.getCoverBox(0);
            for (index in details) {
                if (this.getSideType() === details[index].positionType) {
                    details[index].sizes = {length:  this.corpus.getSmallDepth() + this.service.getTabletopFrontGap()
                            + (Math.abs(coverBox.min.z) - this.corpus.getDepth()/2)}
                }
            }
        }
        return details;
    }

}