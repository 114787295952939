import React, { useEffect, useState } from 'react';
import TableTop from './TableTop';
import { useFormContext } from 'react-hook-form';
import { cnCreateForm } from '../../FormModule';
import { Button } from '../../../../../components/Button';
import { OPTION_TYPE_JSON } from "common-code/lib/constants";
import { IOptionHiddenJson } from '../../../../../../../common-code/interfaces/option/IOptionHiddenJson';
import { IOption } from 'common-code/lib/interfaces/option/IOption';
import { ISaveData } from 'common-code/lib/interfaces/saveData/ISaveData';
import ModalTableTops from './ModalTableTops';
import {CLASSNAME_END_UNITS as arrEndUnits } from 'common-code/lib/constants';
import { initionalTabletop } from './initionalTabletop';
import { IFormInput } from '../../initialValue';
import { TDirectionSideType } from 'common-code/lib/types/TDirectionSideType';
import { TPositionSideType } from 'common-code/lib/types/TPositionSideType';


export interface ITableTop {
    id: number;
    functionalType?: string;
    initSizes?: {
        length?: number
        width?: number
    }
    position?: {
        x?: number
        y?: number
        z?: number
    }
    sideType?: TDirectionSideType;
    positionType?: TPositionSideType;
}

export const functionalTypeValues = [
    {value:"endType1", content:"endType1"},
    {value:"endType2", content:"endType2"}
]

export default  function TableTops  () {

    const { setValue, getValues } = useFormContext<IFormInput>();
    const classNameValue = getValues('className')?.value; 
    const IsEndUnit = arrEndUnits.includes(classNameValue);
    const tabletopsInitional = getValues("tabletops") ? (getValues("tabletops") as IOption).value : [];
    const [arrTabletops, setArrTabletops] = React.useState <ISaveData[]> ( IsEndUnit ? {...tabletopsInitional, functionalType: "endType1" } : tabletopsInitional );
    const [modal, setModal] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const tabletops: IOption  =  {
        id: "tabletops",
        type: OPTION_TYPE_JSON,
        title: "Столешницы",
        sort: 4,
        value: arrTabletops, 
      } as IOptionHiddenJson;

    useEffect(() => {
        if (arrTabletops.length > 0) {
            setValue("tabletops", tabletops);
        } else {
            setValue("tabletops", null);
        }
        // eslint-disable-next-line
    }, [arrTabletops ])


    const [tabletopNew, setTabletopNew] = useState <ITableTop> (initionalTabletop());
    const validateTabletop = (tabletopNew:ITableTop) => {

        if(!tabletopNew.initSizes?.length || !tabletopNew.initSizes?.width || !tabletopNew.functionalType) {return ""}


        if(isNaN(tabletopNew.initSizes?.length) || tabletopNew.initSizes?.length < 0 || tabletopNew.initSizes?.length > 1200) {
            return "Длина должна быть в диапазоне от 0 до 1200";
        }

        if(isNaN(tabletopNew.initSizes?.width) || tabletopNew.initSizes?.width < 0 || tabletopNew.initSizes?.width > 1200) {
            return "Глубина должна быть в диапазоне от 0 до 1200";
        }

        if (IsEndUnit) {
            if(tabletopNew.functionalType !== "endType1" && tabletopNew.functionalType !== "endType2") {
                return "Позиция должна быть endType1 или endType2";
            }
        }

        return ""
    }
    
    const onFocus = () => {
        setError('');
    }


    const handleSubmit =  () => {

        const error = validateTabletop(tabletopNew);
        if(error){
            setError(error);
            return;
        }
        
        setArrTabletops([...arrTabletops, tabletopNew]);
        setModal(!modal);
    }
    const handleToggleModal = () => {
        setModal(!modal);
    }

  return (
    <>  
        <h4 className={cnCreateForm('Header')}>Столешница</h4>

        {arrTabletops.map((table, index) =>
            <TableTop  
                arrTabletops={arrTabletops} 
                setArrTabletops={setArrTabletops}  
                table={table} 
                key={index}
                index={index}
                validateTabletop={validateTabletop}
            />
        )}
        
        {arrTabletops.length === 0 && 
            <Button
                className={cnCreateForm('Button')}
                type={'button'}
                text={'Добавить столешницу'}
                leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                mods={{
                    mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                    hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                }}
                callBack={handleToggleModal}
            />
        }

        <ModalTableTops
            newTabletop={tabletopNew}
            setNewTabletop={setTabletopNew}
            modal={modal}
            handleToggleModal={handleToggleModal}
            onFocus={onFocus}
            error={error}
            handleSubmit={handleSubmit}
            keyValue={`#addingTabletop`}
        />
        <hr/>
    </>
  )
}

