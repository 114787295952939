import React, { useState } from 'react'
import ModalHandleModel from './ModalHandleModel'
import { cnFormHandle } from './../FormHandle'
import { IHandleModelData } from '../../../../../../common-code/interfaces/materials/IHandleModelData'
import { INewHandleModelData, initialModel } from './../defaultValues'
import { IHandleData } from '../../../../../../common-code/interfaces/materials/IHandleData'
import Edit from '../../icons/Edit'
import Trash from '../../icons/Trash'

interface IHandleDataProps {
    model: IHandleModelData
    index: number
    createModel: (newHandleModel: INewHandleModelData) => IHandleModelData
    setNewHandle: (newHandleModel: IHandleData) => void
    newHandle: IHandleData
    validateModel: (newHandleModel: INewHandleModelData) => string
}

const HadleModel = ({
    model,
    index,
    createModel,
    setNewHandle,
    newHandle,
    validateModel,
}: IHandleDataProps) => {
    const [modal, setModal] = React.useState<boolean>(false)
    const handleToggleModal = () => setModal((prev) => !prev)
    const [newHandleModel, setNewHandleModel] = useState<INewHandleModelData>(
        initialModel(model)
    )
    const handleSubmit = () => {
        const error = validateModel(newHandleModel)
        if (error) {
            setNewHandleModel({ ...newHandleModel, error })
            return
        }
        const modelNew = createModel(newHandleModel)
        setNewHandle({
            ...newHandle,
            models: newHandle.models.map((el, i) =>
                i === index ? modelNew : el
            ),
        })
        setModal(false)
    }
    const handleDeleteModel = () => {
        setNewHandle({
            ...newHandle,
            models: newHandle.models.filter((_, i) => i !== index),
        })
    }

    return (
        <div className={cnFormHandle('Children')}>
            <span>Ширина: {model.width}</span>
            <span>Высота: {model.height}</span>
            <span>Глубина: {model.depth}</span>
            <span>Тип: {model.type} </span>
            <div className={cnFormHandle('Box-Btn')}>
            <Edit handleToggleModal={handleToggleModal} />
            <Trash handleDeleteValue={handleDeleteModel} />
        </div>
            <ModalHandleModel
                modal={modal}
                handleToggleModal={handleToggleModal}
                newHandleModel={newHandleModel}
                handleSubmit={handleSubmit}
                setNewHandleModel={setNewHandleModel}
                keyValue={`#editModel${index}`}
            />
        </div>
    )
}

export default HadleModel
