import React, { ReactNode } from "react";
import { cn } from "@bem-react/classname";
import { IClassNameProps } from "@bem-react/core";
import "./SettingsMenu.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  HIDE_SETTINGS_MENU,
  SETTING_GROUP_APRONS,
  SETTING_GROUP_CORPUS,
  SETTING_GROUP_EQUIPMENTS,
  SETTING_GROUP_FACADES,
  SETTING_GROUP_GENERAL,
} from "../../../constants";
import { SettingsMenuGeneral } from "./General/SettingsMenu-General";
import { SettingsMenuCorpus } from "./Corpus/SettingsMenu-Corpus";
import { SettingsMenuFacades } from "./Facades/SettingsMenu-Facades";
import { SettingsMenuAprons } from "./Aprons/SettingsMenu-Aprons";
import { SettingsMenuEquipments } from "./Equipments/SettingsMenu-Equipments";
import { ISettingsAction } from "../../../interfaces/settingData/ISettingsAction";
import { ISaveUnitData } from "../../../../common-code/interfaces/saveData/ISaveUnitData";
import { KitchenService } from "../../../3d/services/KitchenService/KitchenService";
import { ISettingGroup } from "../../../interfaces/settingData/ISettingGroup";
import { ICreateObjectData } from "../../../../common-code/interfaces/createData/ICreateObjectData";
import { CurrencyHelper } from "../../../../domain/CurrencyHelper/CurrencyHelper";
import { ISettingGroupGeneral } from "../../../interfaces/settingData/ISettingGroupGeneral";
import { ISettingGroupFacades } from "../../../interfaces/settingData/ISettingGroupFacades";
import { ISettingGroupCorpus } from "../../../interfaces/settingData/ISettingGroupCorpus";
import { ISettingGroupAprons } from "../../../interfaces/settingData/ISettingGroupAprons";
import { ISettingGroupEquipments } from "../../../interfaces/settingData/ISettingGroupEquipments";
import { Button } from "../../components/Button";
import { Svg } from "../../components/Svg";
import { Accordion, cnAccordion } from "../../components/Accordion";
import { ISaveKUnitData } from "../../../../common-code/interfaces/saveData/ISaveKUnitData";

export const cnSettingsMenu = cn("SettingsMenu");

export interface ISettingsMenuProps extends IClassNameProps {
  action: ISettingsAction;
  unitId: number;
  data: ISaveUnitData;
  price: number;
  service: KitchenService;
  groups: { [key: string]: ISettingGroup };
  createObjectData?: ICreateObjectData;
}

export interface ISettingsMenuGroupProps extends IClassNameProps {
  data: ISaveUnitData;
  onRebuildData(method: string, value: any): void;
}

export const SettingsMenu: React.FC<ISettingsMenuProps> = ({
  className,
  action,
  unitId,
  data,
  price,
  service,
  groups,
  createObjectData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onCancelSettings = () => {
    dispatch({ type: HIDE_SETTINGS_MENU });
  };

  const onRebuildData = (method: string, value: any) => {
    service.rebuildData(unitId, method, value);
  };

  const onSetRotation = (value: number) => {
    service.onSetRotation(value, unitId);
  };

  const getGroupComponent = (group: ISettingGroup): ReactNode | null => {
    switch (group.id) {
      case SETTING_GROUP_GENERAL:
        return (
          <SettingsMenuGeneral
            data={data}
            onRebuildData={onRebuildData}
            onSetRotation={onSetRotation}
            generalData={group.data as ISettingGroupGeneral}
          />
        );
      case SETTING_GROUP_FACADES:
        return (
          <SettingsMenuFacades
            data={data}
            service={service}
            onRebuildData={onRebuildData}
            facadesData={group.data as ISettingGroupFacades}
          />
        );
      case SETTING_GROUP_CORPUS:
        return "sizes" in group.data && group.data.sizes.length ? (
          <SettingsMenuCorpus
            data={data}
            onRebuildData={onRebuildData}
            corpusData={group.data as ISettingGroupCorpus}
          />
        ) : null;
      case SETTING_GROUP_APRONS:
        return (
          <SettingsMenuAprons
            data={data}
            service={service}
            onRebuildData={onRebuildData}
            apronsData={group.data as ISettingGroupAprons}
          />
        );
      case SETTING_GROUP_EQUIPMENTS:
        return (
          <SettingsMenuEquipments
            data={data as ISaveKUnitData}
            unitId={unitId}
            service={service}
            onRebuildData={onRebuildData}
            equipmentsData={group.data as ISettingGroupEquipments}
          />
        );
    }
    return null;
  };

  const groupItems: { text: string; children: ReactNode }[] = Object.values(
    groups
  ).map((group) => {
    return {
      text: group.title || t("Свойства"),
      children: getGroupComponent(group),
    };
  });

  const geiImage = (): string | undefined => {
    if (createObjectData && createObjectData.image.length > 0) {
      return createObjectData.image;
    }
    if (data.image && data.image.length > 0) {
      return data.image;
    }

    return undefined;
  };

  return (
    <div className={cnSettingsMenu({}, [className])} onClick={action}>
      <Button
        className={cnSettingsMenu("CloseButton")}
        type={"button"}
        leftIcon={{ path: "close", width: 20, height: 20 }}
        mods={{
          mods: { bkg: "white", fill: "lightBlack" },
        }}
        callBack={onCancelSettings}
      />
      {!data || (
        <div className={cnSettingsMenu("Body")}>
          <div className={cnSettingsMenu("Header")}>
            <div className={cnSettingsMenu("Images")}>
              {geiImage() ? (
                <img
                  className={cnSettingsMenu("Image")}
                  src={geiImage()}
                  alt={t(data.name) || undefined}
                />
              ) : (
                <Svg
                  icon={createObjectData?.svgImage || "no-photo"}
                  className={cnSettingsMenu("Icon")}
                  label={t(data.name) || undefined}
                />
              )}
            </div>
            <div className={cnSettingsMenu("Info")}>
              <div className={cnSettingsMenu("Name")}>{t(data.name)}</div>
              {price > 0 ? (
                <div className={cnSettingsMenu("Price")}>
                  <p className={cnSettingsMenu("PriceTitle")}>{t("Цена")}</p>
                  <p className={cnSettingsMenu("PriceValue")}>
                    {CurrencyHelper.formatValue(price)}
                  </p>
                </div>
              ) : null}
              <div className={cnSettingsMenu("Sizes")}>
                <div className={cnSettingsMenu("SizesItem")}>
                  <p className={cnSettingsMenu("SizesTitle")}>{t("Ширина")}</p>
                  <p className={cnSettingsMenu("SizesValue")}>{`${
                    data.sizes.length
                  } ${t("мм")}.`}</p>
                </div>
                <div className={cnSettingsMenu("SizesItem")}>
                  <p className={cnSettingsMenu("SizesTitle")}>{t("Высота")}</p>
                  <p className={cnSettingsMenu("SizesValue")}>{`${
                    data.sizes.height
                  } ${t("мм")}.`}</p>
                </div>
                <div className={cnSettingsMenu("SizesItem")}>
                  <p className={cnSettingsMenu("SizesTitle")}>{t("Глубина")}</p>
                  <p className={cnSettingsMenu("SizesValue")}>{`${
                    data.sizes.width
                  } ${t("мм")}.`}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={cnSettingsMenu("Wrapper")}>
            {groupItems.length > 0
              ? groupItems.map((group, index) => {
                  if (!group.children) {
                    return null;
                  }
                  return (
                    <Accordion
                      key={index}
                      className={cnSettingsMenu(cnAccordion())}
                      text={group.text}
                      initActive={index === 0}
                    >
                      {group.children}
                    </Accordion>
                  );
                })
              : null}
          </div>
        </div>
      )}
    </div>
  );
};
