import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {useState} from "react";
import './City.css';
import {Svg} from "../../components/Svg";
import {useTranslation} from "react-i18next";
import {cnModal, Modal} from "../../components/Modal";
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {TLocationData} from '../../../../common-code/types/TLocationData';
import axios from 'axios';
import {HIDE_LOADING, SET_LOCATION, SHOW_LOADING} from '../../../constants';
import {Input} from "../../components/Input/Input";
import {cnInput} from "../../components/Input";
import {HEADER_TYPE_BIG, HEADER_TYPE_SMALL, INPUT_TYPE_TEXT} from "../../constants";
import {LoadingApiData} from "../../helpers/LoadingApiData/LoadingApiData";
import {THeaderType} from '../../../types/THeaderType';
import {useHeaderType} from '../../../hooks/useHeaderType';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';

export const cnCity = cn('City');

export interface ICityProps extends IClassNameProps {
    size?: 'small';
    service?: KitchenService;
    onClick?(): void;
}

export const City: React.FC<ICityProps> = ({className, size, onClick, service}) => {
    const {t} = useTranslation();
    const [hover, setHover] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const locations: TLocationData[] = useSelector((state: AppState) => state.location.all);
    const currentLocation: TLocationData | undefined = useSelector((state: AppState) => state.location.current);
    const [searchCity, setSearchCity] = useState<string>('');
    const [hoverItem, setHoverItem] = useState<number | null>(null);
    const [changeLocation, setChangeLocation] = useState<boolean>(false);
    const headerType: THeaderType = useHeaderType();
    const dispatch = useDispatch();

    axios.interceptors.request.use((config) => {
        setChangeLocation(true);
        return config;
    }, (error) => {
        setChangeLocation(false);
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        setChangeLocation(false);
        return response;
    }, (error) => {
        setChangeLocation(false);
        return Promise.reject(error);
    });

    const onSelectLocation = (id: number) => {
        let location: TLocationData | undefined;

        dispatch({type: SHOW_LOADING});
        location = locations.find((item: TLocationData) => {
            return id === item.id
        });
        if (location) {
            axios.post('/api/location/set', location)
                .then((response) => {
                    if (response.data && response.data.id) {
                        dispatch({
                            type: SET_LOCATION,
                            payload: location,
                        });
                        if (service && location) {
                            service.setLocation(location);
                        }
                    }
                    dispatch({type: HIDE_LOADING});
                    setShowModal(false);
                }).catch(() => {
                dispatch({type: HIDE_LOADING});
            });
        }
    };

    const onClickItem = () => {
        if (onClick) {
            onClick();
        }
        setShowModal(!showModal);
    };

    return (
        <>
            <div
                className={cnCity({hover: hover}, [className])}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
                onClick={onClickItem}
            >
                <Svg
                    className={cnCity('Icon')}
                    icon={'location'}
                    label={'location'}
                    style={{width: `${size ? 20 : 25}px`, height: `${size ? 20 : 25}px`, marginRight: `${8}px`}}
                />
                {headerType === HEADER_TYPE_BIG ? <p className={cnCity('Name', {hover: size ? hover : false})}>
                    {currentLocation ? currentLocation.title : t('Не определено')}
                </p> : null}
                {
                    headerType !== HEADER_TYPE_SMALL ?
                    <Svg
                        className={cnCity('Icon')}
                        icon={'arrowDown'}
                        label={'arrowDown'}
                        style={{width: `${15}px`, height: `${15}px`, marginLeft: size ? 'auto' : `${3}px`}}
                    /> : null
                }
            </div>
            <Modal
                className={cnCity(cnModal())}
                visible={showModal && locations.length > 0}
                callBack={setShowModal}
            >
                <>
                    <div className={cnCity('Wrapper', {blur: changeLocation})}>
                        <p className={cnCity('Title')}>{t('Выберите ваш город')}</p>
                        <p className={cnCity('Current')}>{t('Выбран')}: <span className={cnCity('CurrentName')}>{currentLocation ? currentLocation.title : t('Не определено')}</span></p>
                        <Input
                            className={cnCity(cnInput())}
                            type={INPUT_TYPE_TEXT}
                            value={searchCity}
                            placeholder={t('Поиск Вашего города') || undefined}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setSearchCity(event.target.value)}}
                        />
                        <div className={cnCity('Scroller')}>
                            {locations
                                .filter(location => location.title.toLowerCase().includes(searchCity.toLowerCase()))
                                .map(location => (
                                    <p
                                        key={location.id}
                                        className={cnCity('Item', {hover: location.id === hoverItem, active: location.id === currentLocation?.id})}
                                        onClick={() => {onSelectLocation(location.id)}}
                                        onMouseEnter={() => {setHoverItem(location.id)}}
                                        onMouseLeave={() => {setHoverItem(location.id)}}
                                    >
                                        {location.title}
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                    <LoadingApiData loading={changeLocation}/>
                </>
            </Modal>
        </>

    );
}