import {ThreePenalUnit} from '../ThreePenalUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTopCorpus} from '../../../details/ThreeCorpus/types/ThreeTopCorpus';
import {Vector3} from 'three';
import {ISavePenalUnitData} from '../../../../../../../common-code/interfaces/saveData/ISavePenalUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';
import {TPlaneType} from '../../../../../types/TPlaneType';
import {
    PLANE_TYPE_FLOOR,
    PLANE_TYPE_HORIZONTAL,
    PLANE_TYPE_VERTICAL,
    PLANE_TYPE_WALL
} from '../../../../../../constants';

export class ThreePenalUnitTop extends ThreePenalUnit {
    corpus: ThreeTopCorpus;

    constructor(options: ISavePenalUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        // this.view3d.userData.transparentAngle = -0.4;
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeTopCorpus {
        return new ThreeTopCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        } else {
            if (this.view3d.position.y !== this.defaultYPosition()) {
                this.view3d.position.y = this.defaultYPosition();
            }
        }
        this.afterSetPosition();
    }

    public defaultYPosition(): number {
        if ((this.service.getInstallTopUnitHeight() - this.getHeight()) < this.service.getInstallBottomUnitHeight()) {
            debugger;
            if ((this.service.getInstallBottomUnitHeight() + this.getHeight()) <= this.service.getRoom().getHeight()) {
                return this.service.getInstallBottomUnitHeight() + this.getHeight() / 2;
            } else {
                return this.service.getRoom().getHeight() - this.getHeight() / 2;
            }
        }
        return this.service.getInstallTopUnitHeight() - this.getHeight() / 2;
    }

    protected correctMovePosition(movePosition: Vector3) {
        this.correctTopUnitMovePosition(movePosition);
    }

    protected getIntersectsTypes(): TPlaneType[] {
        return [
            PLANE_TYPE_WALL,
            PLANE_TYPE_VERTICAL,
            PLANE_TYPE_FLOOR,
            PLANE_TYPE_HORIZONTAL,
        ];
    }
}