import React from 'react'
import {useTranslation} from 'react-i18next';
import {IClassNameProps, withBemMod} from '@bem-react/core';
import {cnObjectSelect} from '../../ObjectSelect';
import {cnRadioButton, IRadioButtonProps} from '../index';
import {Button, cnButton} from '../../Button';
import {TSelectItem} from '../../../../../common-code/types/TSelectItem';
import {RADIO_BUTTON_TYPE_KIT_CODE} from '../../../../../common-code/constants';

const RadioButtonKitCode: React.FC<IRadioButtonProps> =
    ({
         type,
         className,
         options,
         value,
         callBack,
         name
     }) => {
        const {t} = useTranslation();

        if (!options || !options.length || !callBack) {
            return null;
        }

        const getItemIcon = (selectItem: TSelectItem): string => {
            switch (selectItem.id) {
                case 'cheap':
                    return '1star';
                case 'medium':
                    return '2star';
                case 'expensive':
                    return '3star';
                default:
                    return '1star';
            }
        }



        return (
            <div className={cnRadioButton({type: type}, [className])}>
                {options.map((option, index) => {
                    return (
                        <Button
                            key={index}
                            className={cnRadioButton(cnButton(), {type: type})}
                            type={'button'}
                            leftIcon={{path: getItemIcon(option), width: 50, height: 20}}
                            rightIcon={option.id === value ? {
                                path: 'ok-circle',
                                width: 16,
                                height: 16,
                                fill: 'white',
                            } : undefined}
                            text={t(option.title) || undefined}
                            disabled={!!option.disabled}
                            mods={{
                                mods: {bkg: 'lightGray', color: 'black', border: 'gray'},
                                hover: {mods: {color: 'green', bkg: 'white', border: 'green', fill: 'red'}},
                                active: {
                                    state: option.id === value,
                                    mods: {color: 'green', bkg: 'white', border: 'green', fill: 'red'}
                                },
                                disabled: {state: !!option.disabled, mods: {color: 'lightBlack', cursor: 'default'}}
                            }}
                            callBack={() => {
                                callBack(option.id, name)
                            }}
                        />
                    );
                })}
            </div>
        )
    }

export const withRadioButtonKitCode = withBemMod<IRadioButtonProps, IClassNameProps>(
    cnObjectSelect(),
    {type: RADIO_BUTTON_TYPE_KIT_CODE},
    () => RadioButtonKitCode
);