import {ThreePenalUnit} from '../ThreePenalUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreePenalSingleIntegratedHandleCorpus} from '../../../details/ThreeCorpus/types/ThreePenalSingleIntegratedHandleCorpus';
import {ISavePenalUnitData} from '../../../../../../../common-code/interfaces/saveData/ISavePenalUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';
import {
    ISaveIntegratedHandleData
} from '../../../../../../../common-code/interfaces/saveData/ISaveIntegratedHandleData';


export class ThreePenalUnitSingleIntegratedHandle extends ThreePenalUnit {
    corpus: ThreePenalSingleIntegratedHandleCorpus;

    constructor(options: ISavePenalUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        // this.view3d.userData.transparentAngle = -0.4;
    }

    protected initCorpus(corpusData: ISaveCorpusData):ThreePenalSingleIntegratedHandleCorpus {
        return new ThreePenalSingleIntegratedHandleCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected initIntegratedHandlesData(
        integratedHandles?: ISaveIntegratedHandleData[]
    ): ISaveIntegratedHandleData[] | undefined {
        if (!integratedHandles || !integratedHandles.length) {
            return integratedHandles
        }
        let handle: ISaveIntegratedHandleData;

        for (handle of integratedHandles) {
            handle.interval = {
                pointA: this.calculateIntegratedHandlePoint(
                    handle.functionalType,
                    -this.getWidth() / 2 + this.corpus.getThickness(),
                    handle.interval?.pointA,
                    handle.sizes?.height,
                    handle.sizes?.width
                ),
                pointB: this.calculateIntegratedHandlePoint(
                    handle.functionalType,
                    this.getWidth() / 2 - this.corpus.getThickness(),
                    handle.interval?.pointB,
                    handle.sizes?.height,
                    handle.sizes?.width
                )
            }
        }

        return integratedHandles
    }
}