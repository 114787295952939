import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeBottomEndPilasterCorpus} from "../../../details/ThreeCorpus/types/ThreeBottomEndPilasterCorpus";
import {
    ISaveBottomUnitEndConsoleData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndConsoleData';
import {TDirectionSideType} from '../../../../../../../common-code/types/TDirectionSideType';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnitEndPilaster extends ThreeBottomUnit {
    saveData: ISaveBottomUnitEndConsoleData;
    corpus: ThreeBottomEndPilasterCorpus;

    constructor(options: ISaveBottomUnitEndConsoleData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isEndUnit(): boolean {
        return true;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomEndPilasterCorpus {
        return new ThreeBottomEndPilasterCorpus(CommonHelper.deepCopy(corpusData), this)
    }
}