import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';

export class ThreeBottomPilasterCorpus extends ThreeCorpus {

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getWidth(), this.getDepth()),
            'leftTop',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                this.getHeight()/2 - this.getThickness() - this.getWidth()/2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getWidth(), this.getDepth()),
            'leftBottom',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                -this.getHeight()/2 + this.getThickness() + this.getWidth()/2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness()*2, this.getDepth()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                0,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth()),
            'top',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                0
            )
        );
    };

    protected createBackPanels() {

    }
}