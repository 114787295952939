import {ThreeCorpus} from "../ThreeCorpus";
import {BoxGeometry, Shape, Vector3} from "three";
import {ThreeShelf} from "../../ThreeShelf/ThreeShelf";
import {WINERY_UNIT_SHELF_DEEPENING} from "../../../../../constants";
import {ISaveShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfData';
import {SHELF_TYPE_HORIZONTAL} from '../../../../../../../common-code/constants';
import {CommonHelper} from 'common-code';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';
import {MathHelper} from 'common-code';

export class ThreeTopWineryCorpus extends ThreeCorpus {
    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getDepth() - this.getBackThickness()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                this.getBackThickness() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'left',
            new Vector3(
                -this.getWidth() / 2 + this.getThickness() / 2,
                0,
                this.getBackThickness() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'right',
            new Vector3(
                this.getWidth() / 2 - this.getThickness() / 2,
                0,
                this.getBackThickness() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getDepth() - this.getBackThickness()),
            'top',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                this.getBackThickness() / 2
            )
        );
    }

    protected createShelves() {
        let shape: Shape;
        const wineryShapes: Shape[] = this.createWineryShapes();
        const customThickness: number = this.getDepth() - this.getBackThickness() - WINERY_UNIT_SHELF_DEEPENING;
        const saveShelfData: ISaveShelfData = {
            id: 0,
            type: SHELF_TYPE_HORIZONTAL,
            length: '%100',
            depth: '%100',
            thickness: customThickness,
            fixed: true
        };

        for (shape of wineryShapes) {
            const shelf: ThreeShelf = new ThreeShelf(CommonHelper.deepCopy(saveShelfData), this, shape);
            shelf.initState();
            shelf.createView();
            // TODO: customThickness ?
            // shelf.setPosition(new Vector3(0, 0, (customThickness - this.getDepth()) / 2 + this.getBackThickness()));
            shelf.setPosition(new Vector3(0, 0, (-this.getDepth())/2 + this.getBackThickness() + WINERY_UNIT_SHELF_DEEPENING));
            this.shelves.push(shelf);
        }
    }

    protected createWineryShapes(): Shape[] {
        const mainShape: Shape = this.createMainShape();
        const leftShape: Shape = this.createLeftShape();
        const rightShape: Shape = this.createRightShape();

        return [mainShape, leftShape, rightShape];
    }

    protected createMainShape(): Shape {
        const widthShelfEnd: number = this.getWidthEndShelf();
        const shape: Shape = new Shape();

        shape.moveTo(
            -this.getWidth() / 2 + this.getThickness(),
            -this.getHeight() / 2 + this.getThickness()
        );
        shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            -this.getHeight() / 2 + this.getThickness() + widthShelfEnd
        );
        shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            this.getHeight() / 2 - this.getThickness()
        );
        shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            this.getHeight() / 2 - this.getThickness() - widthShelfEnd
        );
        shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            -this.getHeight() / 2 + this.getThickness()
        );

        return shape;
    }

    protected createLeftShape(): Shape {
        const shape: Shape = new Shape();
        let projectionPoints: TPoint2D[] = [];

        const widthShelfEnd: number = this.getWidthEndShelf();
        const pointB: TPoint2D = {
            x: -this.getWidth() / 2 + this.getThickness(),
            y: -this.getHeight() / 2 + this.getThickness() + widthShelfEnd
        };
        projectionPoints.push(MathHelper.projectionPoint2D(
            {x: -this.getWidth() / 2 + this.getThickness(), y: this.getHeight() / 2 - this.getThickness()},
            {x: this.getWidth() / 2 - this.getThickness(), y: this.getHeight() / 2 - this.getThickness()},
            pointB
        ));
        projectionPoints.push(MathHelper.getPointByRatio2D(
            {x: projectionPoints[0].x, y: projectionPoints[0].y},
            {x: pointB.x, y: pointB.y},
            this.getThickness() / MathHelper.getLength2D(projectionPoints[0], pointB),
            true
        ));
        const turnPoint: TPoint2D = MathHelper.turnVector2D({x: pointB.x - projectionPoints[1].x, y: pointB.y - projectionPoints[1].y}, 0.5 * Math.PI, projectionPoints[1]);
        const intersectionPoint: TPoint2D | undefined = MathHelper.getIntersectionPoint(
            {pointA: projectionPoints[1], pointB: turnPoint},
            {
                pointA: {x: -this.getWidth() / 2 + this.getThickness(), y: this.getHeight() / 2 - this.getThickness()},
                pointB: {x: -this.getWidth() / 2 + this.getThickness(), y: -this.getHeight() / 2 + this.getThickness()}
            }
        );
        if (!intersectionPoint) {
            return shape;
        }
        projectionPoints.push(intersectionPoint);
        shape.moveTo(
            -this.getWidth() / 2 + this.getThickness(),
            this.getHeight() / 2 - this.getThickness()
        );
        shape.lineTo(
            projectionPoints[0].x,
            projectionPoints[0].y
        );
        shape.lineTo(
            projectionPoints[1].x,
            projectionPoints[1].y
        );
        shape.lineTo(
            projectionPoints[2].x,
            projectionPoints[2].y
        );
        shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            this.getHeight() / 2 - this.getThickness()
        );

        return shape;
    }

    protected createRightShape(): Shape {
        const shape: Shape = new Shape();
        let projectionPoints: TPoint2D[] = [];

        const widthShelfEnd = this.getWidthEndShelf();
        const pointB: TPoint2D = {
            x: this.getWidth() / 2 - this.getThickness(),
            y: this.getHeight() / 2 - this.getThickness() - widthShelfEnd
        };
        projectionPoints.push(MathHelper.projectionPoint2D(
            {x: this.getWidth() / 2 - this.getThickness(), y: -this.getHeight() / 2 + this.getThickness()},
            {x: -this.getWidth() / 2 + this.getThickness(), y: -this.getHeight() / 2 + this.getThickness()},
            pointB
        ));
        projectionPoints.push(MathHelper.getPointByRatio2D(
            {x: projectionPoints[0].x, y: projectionPoints[0].y},
            {x: pointB.x, y: pointB.y},
            this.getThickness() / MathHelper.getLength2D(projectionPoints[0], pointB),
            true
        ));
        const turnPoint: TPoint2D = MathHelper.turnVector2D({x: pointB.x - projectionPoints[1].x, y: pointB.y - projectionPoints[1].y}, 0.5 * Math.PI, projectionPoints[1]);
        const intersectionPoint: TPoint2D | undefined = MathHelper.getIntersectionPoint(
            {pointA: projectionPoints[1], pointB: turnPoint},
            {
                pointA: {x: this.getWidth() / 2 - this.getThickness(), y: -this.getHeight() / 2 + this.getThickness()},
                pointB: {x: this.getWidth() / 2 - this.getThickness(), y: this.getHeight() / 2 - this.getThickness()}
            }
        );
        if (!intersectionPoint) {
            return shape;
        }
        projectionPoints.push(intersectionPoint);
        shape.moveTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getHeight() / 2 + this.getThickness()
        );
        shape.lineTo(
            projectionPoints[0].x,
            projectionPoints[0].y
        );
        shape.lineTo(
            projectionPoints[1].x,
            projectionPoints[1].y
        );
        shape.lineTo(
            projectionPoints[2].x,
            projectionPoints[2].y
        );
        shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getHeight() / 2 + this.getThickness()
        );

        return shape;
    }

    protected getWidthEndShelf(): number {
        const diagonal: number = Math.sqrt(
            Math.pow((this.getHeight() - this.getThickness() * 2 ), 2) +
            Math.pow((this.getWidth() - this.getThickness() * 2), 2)
        );
        const hypotenuse: number = diagonal / 2;
        const cathetus: number = this.getThickness() / 2;
        const angle = Math.asin(cathetus / hypotenuse);
        const shearAngle = angle * 2;
        const pointA: TPoint2D = {x: 0, y: 0};
        const pointB: TPoint2D = {x: this.getWidth() / 2 - this.getThickness(), y: this.getHeight() / 2 - this.getThickness()};
        const turnPoint: TPoint2D = MathHelper.turnVector2D({x: pointB.x - pointA.x, y: pointB.y - pointA.y}, shearAngle, pointA);
        const intersectionPoint: TPoint2D | undefined = MathHelper.getIntersectionPoint(
            {pointA: pointA, pointB: turnPoint},
            {
                pointA: {x: this.getWidth() / 2 - this.getThickness(), y: -this.getHeight() / 2 + this.getThickness()},
                pointB: {x: this.getWidth() / 2 - this.getThickness(), y: this.getHeight() / 2 - this.getThickness()}
            }
        );
        if (!intersectionPoint) {
            return this.getThickness();
        }

        return MathHelper.getLength2D(intersectionPoint, pointB);
    }
}