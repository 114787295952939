import {ThreeIntegratedHandle} from './ThreeIntegratedHandle';
import {Euler, MeshStandardMaterial, Vector2, Vector3} from 'three';
import {TPoint2D} from '../../../../../../common-code/types/TPoint2D';
import {CommonObjectHelper} from '../../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {TSideType} from '../../../../../../common-code/types/TSideType';
import {MathHelper} from 'common-code';
import {ISaveIntegratedHandleData} from '../../../../../../common-code/interfaces/saveData/ISaveIntegratedHandleData';

export class ThreeIntegratedHandleUnion extends ThreeIntegratedHandle {
    integratedHandles: ThreeIntegratedHandle[];

    constructor(options: ISaveIntegratedHandleData, integratedHandles: ThreeIntegratedHandle[]) {
        let firstIntegratedHandle: ThreeIntegratedHandle;

        firstIntegratedHandle = integratedHandles[0];
        if (!firstIntegratedHandle) {
            throw new Error('error-ThreeIntegratedHandleUnion-constructor');
        }
        super(options, firstIntegratedHandle.unit);
        this.integratedHandles = integratedHandles;
    }

    public getChildren(): ThreeIntegratedHandle[] {
        return this.integratedHandles;
    }

    public getSortChildIds(): string[] {
        let ids: { [n: number]: number } = {};
        let integratedHandle: ThreeIntegratedHandle;

        for (integratedHandle of this.integratedHandles) {
            ids[integratedHandle.getId()] = integratedHandle.getId();
        }

        return Object.keys(ids).sort((a: string, b: string) => {
            return +a - +b;
        });
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        this.setIsUnionIntegratedHandles(true);
    }

    public hide() {
        super.hide();
        this.setIsUnionIntegratedHandles(false);

    }

    public show() {
        super.show();
        this.setIsUnionIntegratedHandles(true);
    }

    public setIsUnionIntegratedHandles(isUnion: boolean) {
        let integratedHandle: ThreeIntegratedHandle;

        for (integratedHandle of this.integratedHandles) {
            integratedHandle.setIsUnion(isUnion);
        }
    }

    public setCenterPosition() {
        this.view3d.userData.centerPosition = this.view3d.position;
    }

    protected addToScene() {
        this.service.addToSceneObject3D(this.view3d);
    }

    public remove() {
        this.setIsUnionIntegratedHandles(false);
        this.service.removeFromSceneObject3D(this.view3d);
        super.remove();
    }

    public getBodyMaterial(): MeshStandardMaterial {
        return this.getFirstIntegratedHandle().getBodyMaterial();
    }

    public getFirstIntegratedHandle(): ThreeIntegratedHandle {
        return this.integratedHandles[0];
    }

    public getGlobalPosition() {
        return this.view3d.position;
    }

    public getGlobalRotation(): Euler {
        return this.view3d.rotation;
    }

    public getLength(): number {
        let integratedHandle: ThreeIntegratedHandle;
        let length: number = 0;

        for (integratedHandle of this.integratedHandles) {
            length += integratedHandle.getLength();
        }

        return length;
    }

    protected initPosition(): Vector3 {
        let position2d: TPoint2D;
        let position: Vector3;

        position2d = CommonObjectHelper.calculateUnionPosition2DByDetails(this.integratedHandles);
        position = new Vector3(position2d.x, this.unit.getUnionDetailYPosition(this.getFirstIntegratedHandle()), position2d.y);

        return position;
    }

    protected initRotation(): Euler {
        return CommonObjectHelper.calculateUnionRotationByDetails(this.integratedHandles);
    }

    public getDefaultPoints(type: TSideType): Vector2[] {
        let firstIntegratedHandle, lastIntegratedHandle,
            firstPosition, lastPosition,
            globalPoints,
            positionAngle, integratedHandlesAngle,
            leftPoints, rightPoints;

        firstIntegratedHandle = this.integratedHandles[0];
        lastIntegratedHandle = this.integratedHandles[this.integratedHandles.length - 1];

        firstPosition = firstIntegratedHandle.getGlobalPosition();
        lastPosition = lastIntegratedHandle.getGlobalPosition();
        globalPoints = firstIntegratedHandle.getGlobalMainPoints();
        positionAngle = MathHelper.getNormalAngle({
            x: lastPosition.x - firstPosition.x,
            y: lastPosition.z - firstPosition.z
        });
        integratedHandlesAngle = MathHelper.getNormalAngle({
            x: globalPoints.bottom.pointB.x - globalPoints.bottom.pointA.x,
            y: globalPoints.bottom.pointB.z - globalPoints.bottom.pointA.z
        });
        if (+positionAngle.toFixed(3) === +integratedHandlesAngle.toFixed(3)) {
            leftPoints = firstIntegratedHandle.getLeftPoints();
            rightPoints = lastIntegratedHandle.getRightPoints();
        } else {
            leftPoints = lastIntegratedHandle.getLeftPoints();
            rightPoints = firstIntegratedHandle.getRightPoints();
        }
        switch (type) {
            case 'left':
                return leftPoints;
            case 'right':
                return rightPoints;
            default:
                return super.getDefaultPoints(type);
        }
    }
}