import { SHELF_TYPE_HORIZONTAL } from "common-code/lib/constants";

export const validateShelf = (newShelf: Record<string, string | boolean>, depthCorpus: string, maxHeight: string, defaultWidth: string ) => {
    const {length, depth, thickness, initPosition, type, distance,rotation, shelfRotationX, shelfRotationY, shelfRotationZ }= newShelf;
    
    if(!thickness){
        return 'Толщина полки не может быть пустой';
    }
    if(Number(thickness) < 1 || Number(thickness) > 100 ) {
        return 'Значение должно быть в диапазоне от 1 до 100 мм';
    }
    if(Number(depth) > Number(depthCorpus)) {
        return 'Глубина отступа полки не может быть больше глубины корпуса';
    }
    if(Number(length) < 1 || Number(length) > 100) {
        return 'Значение длины полки должно быть в диапазоне от 1 до 100%';
    }
    const maxValue = type === SHELF_TYPE_HORIZONTAL ? Number(maxHeight) : Number(defaultWidth);
    if (distance === 'percent' && (Number(initPosition) < 1 || Number(initPosition) > 99)) {
        return 'Значение должно быть в диапазоне от 1 до 99%';
    }
    if (maxValue && (distance === 'bottom' || distance === 'top') &&
        (Number(initPosition) < 1 || Number(initPosition) > maxValue)) {
        return `Значение должно быть в диапазоне от 1 до ${maxValue} мм`;
    }
    if(rotation){
        if(Number(shelfRotationX) < -90 || Number(shelfRotationX) > 90) {
            return 'Значение угла поворота полки должно быть в диапазоне от -90 до 90 градусов';
        }
        if(Number(shelfRotationY) < -90 || Number(shelfRotationY) > 90) {
            return 'Значение угла поворота полки должно быть в диапазоне от -90 до 90 градусов';
        }
        if(Number(shelfRotationZ) < -90 || Number(shelfRotationZ) > 90) {
            return 'Значение угла поворота полки должно быть в диапазоне от -90 до 90 градусов';
        }
    }
    return "";
}