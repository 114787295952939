import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';
import {BOTTOM_UNIT_TOP_PANEL_WIDTH} from '../../../../../constants';
import {ThreeBottomUnitAngleNormal} from '../../../units/ThreeBottomUnit/types/ThreeBottomUnitAngleNormal';
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import {
    ISaveBottomAngleNormalCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleNormalCorpusData';

export class ThreeBottomAngleNormalCorpus extends ThreeCorpus {
    saveData: ISaveBottomAngleNormalCorpusData;
    unit: ThreeBottomUnitAngleNormal;

    constructor(options: ISaveBottomAngleNormalCorpusData, unit: ThreeBottomUnitAngleNormal) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }


    public initState(isRebuild?: boolean) {
        this.createFrontPanels();
        super.initState(isRebuild);
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth() - this.getBackThickness()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, BOTTOM_UNIT_TOP_PANEL_WIDTH, this.getThickness()),
            'front',
            new Vector3(
                0,
                this.getHeight() / 2 - BOTTOM_UNIT_TOP_PANEL_WIDTH / 2,
                this.getDepth()/2 - this.getThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, BOTTOM_UNIT_TOP_PANEL_WIDTH, this.getThickness()),
            'back',
            new Vector3(
                0,
                0,
                -this.getDepth()/2 + this.getThickness()/2
            )
        );
    }

    protected createFrontPanels() {
        let position: Vector3;

        if (!this.saveData.frontPanel) {
            return;
        }
        position = new Vector3();
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                position.set(
                    -this.getWidth()/2 + this.saveData.frontPanel.length/2,
                    0,
                    this.getDepth()/2 + this.getThickness()/2
                );
                break;
            case SIDE_TYPE_RIGHT:
                position.set(
                    this.getWidth()/2 - this.saveData.frontPanel.length/2,
                    0,
                    this.getDepth()/2 + this.getThickness()/2
                );
                break;
        }
        this.createPanel(
            new BoxGeometry(this.saveData.frontPanel.length, this.getHeight(), this.getThickness()),
            'frontPanel',
            position
        );
    }
}