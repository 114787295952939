import React, {ChangeEvent, useState} from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_SELECT} from '../../../../../common-code/constants';
import {IObjectOptionProps} from '../ObjectOption';
import {Option} from '@yandex/ui/Select';
import {TSelectItem} from '../../../../../common-code/types/TSelectItem';
import {OptionSimple} from '@yandex/ui/Select/Select';
import {useTranslation} from 'react-i18next';
import {IOptionSelect} from '../../../../../common-code/interfaces/option/IOptionSelect';
import {cnObjectOption} from '../index';
import {Select} from '../../Select/Select';


export interface IObjectOptionTypeSelectProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_SELECT;
    option?: IOptionSelect;
    defaultValue?: string;
}

const ObjectOptionTypeSelect: React.FC<IObjectOptionTypeSelectProps> =
    ({
         option,
         group,
         className,
         onChange,
         defaultValue
     }) => {

        const [value, setValue] = useState<string>(defaultValue !== undefined ? '' + defaultValue : (option && option.defaultValue ? option.defaultValue : ''));
        const {t} = useTranslation();

        if (!option || !onChange) {
            return null;
        }
        const getOptions = (selectItems: TSelectItem[]): Option[] => {
            let isChangeValue: boolean = false;
            let options: Option[] = [];
            let optionItem: Option;
            let selectItem: TSelectItem;

            for (selectItem of selectItems) {
                if (selectItem.disabled && selectItem.id === value) {
                    isChangeValue = true;
                }
                options.push({value: selectItem.id, content: t(selectItem.title), disabled: selectItem.disabled});
            }
            if (isChangeValue) {
                for (optionItem of options) {
                    if (!(optionItem as OptionSimple).disabled) {
                        setValue((optionItem as OptionSimple).value);
                        isChangeValue = false;
                        break;
                    }
                }
                if (isChangeValue) {
                    setValue('');
                }
            }

            return options;
        };

        const onChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
            onChange(option.id, event.target.value, group ? group.id : undefined);
            setValue(event.target.value);
        }

        return (
            <div className={cnObjectOption({}, [className])}>
                <div className={cnObjectOption('Title', [className+'Title'])}>
                    {t(option.title)}
                </div>
                <div className={cnObjectOption('Value', [className+'Value'])}>
                    <Select
                        className={cnObjectOption('Select')}
                        placeholder={t(option.title)}
                        showPlaceholder={false}
                        options={getOptions(option.items)}
                        value={value}
                        callBack={onChangeSelect}
                    />
                </div>
            </div>

        );
    }

export const withObjectOptionTypeSelect = withBemMod<IObjectOptionTypeSelectProps, IObjectOptionProps>(
        cnObjectOption(),
        {type: OPTION_TYPE_SELECT},
        () => ObjectOptionTypeSelect
    )
;