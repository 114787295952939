import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {CommonHelper} from 'common-code';
import {ThreeTopBarRavennaCorpus} from '../../../details/ThreeCorpus/types/ThreeTopBarRavennaCorpus';
import {ISaveTopUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {SIDE_TYPE_BOTTOM} from '../../../../../../../common-code/constants';

export class ThreeTopUnitBarRavenna extends ThreeTopUnit {
    corpus: ThreeTopBarRavennaCorpus;

    constructor(options: ISaveTopUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeTopBarRavennaCorpus {
        return new ThreeTopBarRavennaCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    public defaultYPosition(): number {
        let yPosition: number;
        yPosition = this.service.getBottomUnitHeight() + this.service.getBottomUnitLegsHeight() +
            this.service.getTabletopHeight() + this.getSideDistance(SIDE_TYPE_BOTTOM);
        return yPosition;
    }
}