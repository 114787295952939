import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {CommonHelper} from 'common-code';
import {CommonObjectHelper} from '../../../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {ThreeFacade} from '../../../details/ThreeFacade/ThreeFacade';
import {Vector3} from 'three';
import {ThreeBottomBarRavennaCorpus} from '../../../details/ThreeCorpus/types/ThreeBottomBarRavennaCorpus';
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {
    FACADE_POSITION_TYPE_LEFT,
    FACADE_POSITION_TYPE_RIGHT,
    SIDE_TYPE_ANGLE,
    SIDE_TYPE_BACK,
    SIDE_TYPE_FRONT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT
} from '../../../../../../../common-code/constants';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {IDetailPosition} from '../../../../../../../common-code/interfaces/geometry/IDetailPosition';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';
import {TDetailOptionalSizes} from '../../../../../../../common-code/types/TDetailOptionalSizes';

export class ThreeBottomUnitBarRavenna extends ThreeBottomUnit {
    corpus: ThreeBottomBarRavennaCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomBarRavennaCorpus {
        return new ThreeBottomBarRavennaCorpus(CommonHelper.deepCopy(corpusData), this)
    }

    protected createFacades() {
        super.createFacades();
        let facade: ThreeFacade;
        let initPosition: Vector3;

        for (facade of this.facades) {
            initPosition = facade.getPosition();
            initPosition.x += this.corpus.sideWidthGap();
            facade.setPosition(initPosition);
        }
    }

    protected calculateInitFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        if (facades) {
            let facade: ISaveFacadeData;
            for (facade of facades) {
                switch (this.getSideType()) {
                    case SIDE_TYPE_RIGHT:
                        switch (facade.positionType) {
                            case FACADE_POSITION_TYPE_RIGHT:
                                if (facade.initPosition && facade.initPosition.x) {
                                    facade.initPosition.x = '=(-{%50})'
                                }
                                if (facade.initRotation && facade.initRotation.y) {
                                    facade.initRotation.y = -Math.PI / 2;
                                }
                                break;
                            case FACADE_POSITION_TYPE_LEFT:
                                if (facade.initPosition && facade.initPosition.x) {
                                    facade.initPosition.x = '=({%50})'
                                }
                                if (facade.initRotation && facade.initRotation.y) {
                                    facade.initRotation.y = Math.PI / 2;
                                }
                                break;
                        }
                        break;
                    case SIDE_TYPE_LEFT:
                    default:
                        switch (facade.positionType) {
                            case FACADE_POSITION_TYPE_RIGHT:
                                if (facade.initPosition && facade.initPosition.x) {
                                    facade.initPosition.x = '=({%50})'
                                }
                                if (facade.initRotation && facade.initRotation.y) {
                                    facade.initRotation.y = Math.PI / 2;
                                }
                                break;
                            case FACADE_POSITION_TYPE_LEFT:
                                if (facade.initPosition && facade.initPosition.x) {
                                    facade.initPosition.x = '=(-{%50})'
                                }
                                if (facade.initRotation && facade.initRotation.y) {
                                    facade.initRotation.y = -Math.PI / 2;
                                }
                                break;
                        }
                        break;
                }
            }
        }

        return facades;
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index: string;

        if (!tabletops) {
            tabletops = [{
                id: 0,
                initPosition: {x: this.corpus.sideWidthGap()},
                rightPoints: this.getCirclePoints(),
            }];
        }
        for (index in tabletops) {
            if (!tabletops[index].initPosition) {
                tabletops[index].initPosition = {};
            }
            (tabletops[index].initPosition as IDetailPosition).x = this.corpus.sideWidthGap();
            tabletops[index].rightPoints = this.getCirclePoints();
        }

        return tabletops;
    }

    protected getCirclePoints(): TPoint2D[] {
        let points: TPoint2D[];
        let start: TPoint2D;
        let end: TPoint2D;
        let center: TPoint2D;

        start = {x: 0, y: -this.getWidth() / 2};
        end = {x: 0, y: this.getWidth() / 2};
        center = {x: 0, y: 0};
        points = CommonObjectHelper.getCirclePoints(start, end, center, this.getWidth() / 2, false);

        return points;
    }

    protected calculateInitPlinthsData(plinths?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        if (plinths) {
            let plinth: ISaveKUnitDetailData;
            let initSizes: TDetailOptionalSizes | undefined;
            let plinthHeight: number;

            for (plinth of plinths) {
                if (!plinth.initPosition) {
                    plinth.initPosition = {};
                }
                initSizes = plinth.initSizes || {};
                plinthHeight = plinth.sizes && plinth.sizes.height ? plinth.sizes.height : this.service.getPlinthDepth()
                switch (plinth.positionType) {
                    case SIDE_TYPE_LEFT:
                        initSizes.length = this.getDepth() - this.getWidth() / 2 + 30;
                        plinth.initPosition.x =  -this.corpus.getWidth()/2 + plinthHeight / 2 + this.corpus.sideWidthGap();
                        break
                    case SIDE_TYPE_RIGHT:
                        initSizes.length = this.getDepth() - this.getWidth() / 2 + 30;
                        plinth.initPosition.x =  this.corpus.getWidth()/2 - plinthHeight / 2 + this.corpus.sideWidthGap();
                        break
                    case SIDE_TYPE_FRONT:
                        plinth.initPosition = {
                            x: this.corpus.sideWidthGap(),
                            z: this.corpus.getDepth() / 2 - this.corpus.getWidth() / 2
                        }
                        initSizes = plinth.initSizes;
                        plinth.initPosition.x = this.corpus.sideWidthGap();
                        break;
                    case SIDE_TYPE_BACK:
                        plinth.initPosition.x = this.corpus.sideWidthGap();
                        break;
                    case SIDE_TYPE_ANGLE:
                        plinth.initPosition.x = this.corpus.sideWidthGap();
                        switch (this.getSideType()) {
                            case SIDE_TYPE_RIGHT:
                                break;
                            case SIDE_TYPE_LEFT:
                                break;
                        }
                        break;
                    default:
                        initSizes = plinth.initSizes;
                        break;
                }

                plinth.initSizes = initSizes;
            }
        }

        return plinths;
    }

}