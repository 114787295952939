import {composeU, IClassNameProps} from "@bem-react/core";
import {TLinkTarget} from "../../../types/TLinkTarget";
import './Link.css';
import {IIcon} from "../../../interfaces/IIcon";
import {IMods} from "../../../interfaces/IMods";
import React, {ReactNode} from "react";
import {TLinkType} from "../../../types/TLinkType";
import {withLinkTypeDefault} from "./_type/Link_type_default";
import {withLinkTypeNav} from "./_type/Link_type_nav";
import {cnLink} from "./index";

export interface ILinkProps extends IClassNameProps {
    type?: TLinkType;
    href?: string;
    target?: TLinkTarget;
    icon?: IIcon;
    text?: string;
    mods?: IMods;
    children?: ReactNode;
    onClick?(): void;
}

const LinkBase: React.FC<ILinkProps> =
    ({
         className,
         type
    }) => {
    return (
        <div className={cnLink({}, [className])}>
            Replace Link {type}
        </div>
    );
};

export const Link = composeU(
    withLinkTypeDefault,
    withLinkTypeNav
)(LinkBase);