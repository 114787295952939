import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React, {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../redux/AppStore';
import './DeliveryObjectSelect-Home.css';
import {cnInput} from '../../../components/Input';
import {INPUT_TYPE_TEXT} from '../../../constants';
import {Input} from '../../../components/Input/Input';
import {IProjectDeliveryData} from '../../../../../common-code/interfaces/project/IProjectDeliveryData';
import {Tumbler} from '../../../components/Tumbler/Tumbler';
import {TLocationData} from '../../../../../common-code/types/TLocationData';
import {DELIVERY_TYPE_HOME} from '../../../../../common-code/constants';

export const cnDeliveryObjectSelectHome = cn('DeliveryObjectSelectHome')

export interface IDeliveryObjectSelectHomeProps extends IClassNameProps {
    deliveryData: IProjectDeliveryData;
    setDeliveryData: (data: IProjectDeliveryData) => void;
    errors?: any;
}

export const DeliveryObjectSelectHome: React.FC<IDeliveryObjectSelectHomeProps> =
    ({
         className,
         deliveryData,
         setDeliveryData,
         errors
    }) => {
    const {t} = useTranslation();
    const currentLocation: TLocationData | undefined = useSelector((state: AppState) => state.location.current);

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        let newDeliveryData: IProjectDeliveryData;

        newDeliveryData = {...deliveryData};
        newDeliveryData.type = DELIVERY_TYPE_HOME;
        switch (event.target.name) {
            case 'address':
                newDeliveryData.address = event.target.value;
                break;
            case 'floorNumber':
                newDeliveryData.floorNumber = !isNaN(+event.target.value) ? +event.target.value : 0;
                break;
        }
        setDeliveryData(newDeliveryData);
    }

    const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        let newDeliveryData: IProjectDeliveryData;

        newDeliveryData = {...deliveryData};
        newDeliveryData.type = DELIVERY_TYPE_HOME;
        switch (event.target.name) {
            case 'isFloor':
                newDeliveryData.isFloor = event.target.checked;
                break;
            case 'serviceLift':
                newDeliveryData.serviceLift = event.target.checked;
                break;
        }
        setDeliveryData(newDeliveryData);
    }

    if (!currentLocation) {
        return null;
    }

    return (
        <div className={cnDeliveryObjectSelectHome({}, [className])}>
            <div className={cnDeliveryObjectSelectHome('Title')}>
                {t('Адрес доставки')}:
            </div>

            <div className={cnDeliveryObjectSelectHome('Address')}>
                <div className={cnDeliveryObjectSelectHome('City')}>{currentLocation.title}:</div>
                <div className={cnDeliveryObjectSelectHome('Street')}>
                    <Input
                        className={cnDeliveryObjectSelectHome(cnInput(), {error: !!(errors && errors.address)})}
                        type={INPUT_TYPE_TEXT}
                        value={deliveryData.address}
                        name={'address'}
                        placeholder={t('Введите полный адрес') + ' *'}
                        onChange={onChangeInput}
                    />
                    {
                        errors && errors.address ?
                            <div className={cnDeliveryObjectSelectHome('Error')}>{errors.address}</div> : null
                    }
                </div>
            </div>
            <div className={cnDeliveryObjectSelectHome('FloorContainer')}>
                <Tumbler
                    className={cnDeliveryObjectSelectHome('Tumbler')}
                    checked={!!deliveryData.isFloor}
                    callBack={onChangeCheckbox}
                    name={'isFloor'}
                    label={t('Требуется подъем на этаж')}
                />
                <div className={cnDeliveryObjectSelectHome('Floor')}>
                    <div className={cnDeliveryObjectSelectHome('FloorTitle')}>{t('Этаж')}:</div>
                    <div className={cnDeliveryObjectSelectHome('FloorValue')}>
                        <Input
                            className={cnDeliveryObjectSelectHome(cnInput(), {error: !!(errors && errors.floorNumber)})}
                            type={INPUT_TYPE_TEXT}
                            value={deliveryData.floorNumber ? '' + deliveryData.floorNumber : ''}
                            name={'floorNumber'}
                            maxLength={2}
                            placeholder={t('На какой этаж поднимаем') || ''}
                            onChange={onChangeInput}
                        />
                        {
                            errors && errors.floorNumber ?
                                <div className={cnDeliveryObjectSelectHome('Error')}>{errors.floorNumber}</div> : null
                        }
                    </div>
                </div>
                <Tumbler
                    className={cnDeliveryObjectSelectHome('Tumbler')}
                    checked={!!deliveryData.serviceLift}
                    callBack={onChangeCheckbox}
                    name={'serviceLift'}
                    label={t('В доме есть грузовой лифт')}
                />
            </div>
        </div>
    );
}