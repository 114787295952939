import React from 'react';
import {IClassNameProps} from "@bem-react/core";
import {cn} from '@bem-react/classname';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {AppState} from '../../../../redux/AppStore';
import {WizardServicesItem} from '../Item/WizardServices-Item';
import {CurrencyHelper} from '../../../../../domain/CurrencyHelper/CurrencyHelper';
import {IProjectServiceData} from '../../../../../common-code/interfaces/project/IProjectServiceData';

export const cnWizardServices = cn('WizardServices')

export interface IWizardServicesItemsProps extends IClassNameProps {
    service: KitchenService;
    services: IProjectServiceData[];
}

export const WizardServicesItems: React.FC<IWizardServicesItemsProps> =
    ({
         service,
         services,
         className
     }) => {
        const enableServices: boolean = useSelector((state: AppState) => state.wizard.options.enableServices);
        const {t} = useTranslation();

        const totalSum = services
            .map(item => item.sum)
            .reduce((sum1: number, sum2) => sum1 + sum2);
        if (!enableServices) {
            return (
                <div className={cnWizardServices('Items', {empty: true})}>
                    {t('Услуги выключены')}.
                </div>
            );
        }
        if (services.length <= 0) {
            return (
                <div className={cnWizardServices('Items', {empty: true})}>
                    {t('Нет ни одной услуги для отображения')}.
                </div>
            );
        }

        return (
            <>
                <table className={cnWizardServices('Items', [className])}>
                    <tbody>
                    <tr className={cnWizardServices('Item', {header: true})}>
                        <td className={cnWizardServices('Cell', {header: true, type: 'index'})}>{t('№')}</td>
                        <td className={cnWizardServices('Cell', {header: true, type: 'vendorCode'})}>{t('Код услуги')}</td>
                        <td className={cnWizardServices('Cell', {header: true, type: 'name'})}>{t('Наименование')}</td>
                        <td className={cnWizardServices('Cell', {header: true, type: 'autoCalculate'})}>{t('Авторасчет')}</td>
                        <td className={cnWizardServices('Cell', {header: true, type: 'count'})}>{t('Кол-во')}</td>
                        <td className={cnWizardServices('Cell', {header: true, type: 'price'})}>{t('Цена')}</td>
                        <td className={cnWizardServices('Cell', {header: true, type: 'sum'})}>{t('Стоимость')}</td>
                    </tr>
                    {services.sort((a, b) => {
                        return a.sort < b.sort ? -1 : 1;
                    }).map((serviceItem, index) => {
                        return (
                            <WizardServicesItem key={index} index={index + 1} service={service} data={serviceItem} />
                        );
                    })}
                    <tr className={cnWizardServices('Total')}>
                        <td className={cnWizardServices('TotalTitle')}>{t('Итого')}:</td>
                        <td className={cnWizardServices('TotalValue')}>{CurrencyHelper.formatValue(totalSum)}</td>
                    </tr>
                    </tbody>
                </table>

            </>
        );
    };