import {BufferGeometry, CylinderGeometry, Mesh} from 'three';
import {ThreeLeg} from "../ThreeLeg";
import {ThreeKUnit} from "../../../units/ThreeKUnit/ThreeKUnit";
import {ISaveLegSmallData} from '../../../../../../../common-code/interfaces/saveData/ISaveLegSmallData';

export class ThreeLegSmall extends ThreeLeg {
    saveData: ISaveLegSmallData;
    unit: ThreeKUnit;

    constructor(options: ISaveLegSmallData, unit: ThreeKUnit) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public getWidth() {
        return +this.saveData.width;
    }



    public getGeometryType(): string {
        return 'small';
    }

    protected createBody() {
        let legGeometry: BufferGeometry;
        legGeometry = new CylinderGeometry(this.getWidth(), this.getWidth(), this.getHeight(), 15, 1);

        let leg: Mesh = new Mesh(legGeometry, this.bodyMaterial)
        leg.position.y = -this.getHeight()/2;
        leg.name = 'leg';
        leg.matrixAutoUpdate = false;
        this.view3d.add(leg);
    }
}