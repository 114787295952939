import {ThreeBottomUnit} from "../ThreeBottomUnit";
import {ThreeBottomEndConsoleCorpus} from "../../../details/ThreeCorpus/types/ThreeBottomEndConsoleCorpus";
import {KitchenService} from "../../../../../services/KitchenService/KitchenService";
import {TDirectionSideType} from "../../../../../../../common-code/types/TDirectionSideType";
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from "../../../../../../../common-code/constants";
import {
    ISaveBottomUnitEndConsoleData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndConsoleData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {ISaveLegData} from '../../../../../../../common-code/interfaces/saveData/ISaveLegData';

export class ThreeBottomUnitEndConsole extends ThreeBottomUnit {
    saveData: ISaveBottomUnitEndConsoleData;
    corpus: ThreeBottomEndConsoleCorpus;

    constructor(options: ISaveBottomUnitEndConsoleData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isEndUnit(): boolean {
        return true;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomEndConsoleCorpus {
        return new ThreeBottomEndConsoleCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        if (tabletops) {
            let tabletop: ISaveKUnitDetailData;

            for (tabletop of tabletops) {
                if (tabletop.margin) {
                    switch (this.getSideType()) {
                        case SIDE_TYPE_LEFT:
                            tabletop.margin.x = tabletop.margin.x && !isNaN(+tabletop.margin.x) ? -Math.abs(+tabletop.margin.x) : 0;
                            break;
                        case SIDE_TYPE_RIGHT:
                            tabletop.margin.x = tabletop.margin.x && !isNaN(+tabletop.margin.x) ? Math.abs(+tabletop.margin.x) : 0;
                            break;
                    }
                }
            }
        }

        return tabletops;
    }

    protected calculateInitLegsData(legs?: ISaveLegData[]): ISaveLegData[] | undefined {
        if (legs) {
            let index;

            for (index in legs) {
                switch (+index) {
                    case 0:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                                legs[index].initPosition = {x: '50', z: '50'};
                                break;
                            case SIDE_TYPE_RIGHT:
                                legs[index].initPosition = {x: '=({%100}-50)', z: '50'};
                                break;
                        }
                        break;
                    case 1:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                                legs[index].initPosition = {x: '50', z: '=({%100}-50)'};
                                break;
                            case SIDE_TYPE_RIGHT:
                                legs[index].initPosition = {x: '=({%100}-50)', z: '=({%100}-50)'};
                                break;
                        }
                        break;
                }
            }
        }

        return legs;
    }
}