import {ThreeTopUnit} from "../ThreeTopUnit";
import {ThreeTopWineryCorpus} from "../../../details/ThreeCorpus/types/ThreeTopWineryCorpus";
import {KitchenService} from "../../../../../services/KitchenService/KitchenService";
import {ISaveTopUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitWinery extends ThreeTopUnit {
    corpus: ThreeTopWineryCorpus;

    constructor(options: ISaveTopUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeTopWineryCorpus {
        return new ThreeTopWineryCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}