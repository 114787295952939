import {TUserRole} from '../../common-code/types/TUserRole';
import {ROLE_MANAGER, ROLE_GUEST, ROLE_ADMIN} from '../../common-code/constants';
import {useUserRole} from './useUserRole';

export const useUserMod = (): TUserRole => {
    const userRole: TUserRole = useUserRole();

    if ([ROLE_ADMIN, ROLE_MANAGER].includes(userRole)) {
        return ROLE_MANAGER;
    }

    return ROLE_GUEST;
}