import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {cnCollectionDetail} from '../CollectionDetail';
import {ICollectionDto} from '../../../../../common-code/interfaces/ICollectionDto';
import {useTranslation} from 'react-i18next';
import {ProjectItem} from '../../ProjectItem/ProjectItem';

export interface ICollectionDetailProjectsProps extends IClassNameProps {
    collection: ICollectionDto;
}

export const CollectionDetailProjects: React.FC<ICollectionDetailProjectsProps> = ({collection}) => {
    const {t} = useTranslation();

    if (collection.lastProjects === undefined ||
        (Object.values(collection.lastProjects).length <= 0)) {
        return null;
    }
    return (
        <div className={cnCollectionDetail('Projects')}>
            <div className={cnCollectionDetail('Subtitle')}>
                {t('Примеры проектов кухонных гарнитуров')}:
            </div>
            <div className={cnCollectionDetail('ProjectsItems')}>
                {Object.values(collection.lastProjects).map((project) => {
                    return (
                        <ProjectItem className={cnCollectionDetail('Project')} key={project.id} project={project}/>
                    );
                })}
            </div>
        </div>
    );
}