import { TDirectionSideType } from "common-code/lib/types/TDirectionSideType";
import { IParamsValue, IValue } from "./initialValue";

  // создание значения
  export const createValue = ({
    positionType,
    marginX,
    marginY,
    marginZ,
    initPositionX,
    initPositionY,
    initPositionZ,
    initSizesLength,
    margin,
    initPosition,
    initSizes,
    rotation,
    rotationX,
    rotationY,
    rotationZ,
    isSideType,
    sideType,
  }: IParamsValue): IValue => {
    const valueNew: IValue = {
      id: 0,
      positionType,
    };

    if (initSizes) {
      valueNew.initSizes = {
        length: Number(initSizesLength),
      };
    }
    if (rotation) {  
      valueNew.rotation = {
        x: (Number(rotationX) / 180) * Math.PI,
        y: (Number(rotationY) / 180) * Math.PI,
        z: (Number(rotationZ) / 180) * Math.PI,
      };
    }
    if (initPosition) {
      valueNew.initPosition = {
        x: Number(initPositionX),
        y: Number(initPositionY),
        z: Number(initPositionZ),
      };
    }
    if (margin) {
      valueNew.margin = {
        x: Number(marginX),
        y: Number(marginY),
        z: Number(marginZ),
      };
    }
    if (isSideType) {
      valueNew.sideType = sideType as TDirectionSideType;
    }
    return valueNew;
  };
  