import {IClassNameProps} from "@bem-react/core";
import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {PageIndex} from "../../../pages/PageIndex";
import {PageCollections} from "../../../pages/PageCollections";
import {PageZakaz} from "../../../pages/PageZakaz";
import {PageComplectCollections} from "../../../pages/PageComplectCollections";
import {cn} from "@bem-react/classname";
import {useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {PageProjects} from '../../../pages/PageProjects';
import {PageCollectionDetail} from "../../../pages/PageCollectionDetail";
import "./App-Content.css";
import {PageWizard} from '../../../pages/PageWizard/PageWizard';
import {PageAutofit} from '../../../pages/PageAutofit/PageAutofit';
import {LoadingApiData} from '../../../ui/helpers/LoadingApiData/LoadingApiData';
import {LoadingTextureData} from '../../../ui/helpers/LoadingTextureData/LoadingTextureData';
import {AppMessages} from '../../../ui/elements/AppMessages/AppMessages';
import { PageWizardEdit } from "../../../pages/PageWizardEdit/PageWizardEdit";

export const cnPage = cn('Page');

export interface IAppContentProps extends IClassNameProps {

}

export const AppContent: React.FC<IAppContentProps> = () => {
    const isModalActive = useSelector((state:AppState) => state.ui.Modal.visible);
    const apiLoading: boolean = useSelector((state: AppState) => state.ui.apiLoading);
    const textureLoading: boolean = useSelector((state: AppState) => state.ui.textureLoading);

    return (
        <div className={cnPage({blur: isModalActive})}>
            <Routes>
                <Route path={'/'}>
                    <Route index element={<PageIndex/>}/>
                    <Route path={'collections'} element={<PageCollections/>}/>
                    <Route path={'kuhni-na-zakaz'} element={<PageZakaz/>}/>
                    <Route path={'complect-collections'} element={<PageComplectCollections/>}/>
                    <Route path={'projects'} element={<PageProjects/>}/>
                    <Route path={'collection/*'} element={<PageCollectionDetail/>}/>
                    <Route path={'wizard/*'} element={<PageWizard/>}/>
                    <Route path={'wizardEdit/*'} element={<PageWizardEdit/>}/>
                    <Route path={'autofit/*'} element={<PageAutofit/>}/>
                    <Route path={'logout'} element={<Navigate to="/" replace />}/>
                </Route>
            </Routes>
            <AppMessages />
            {apiLoading ? <LoadingApiData loading={apiLoading}/> : null}
            {textureLoading ? <LoadingTextureData loading={textureLoading}/> : null}
        </div>
    );
}