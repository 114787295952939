import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';

export class ThreeBottomOvenCorpus extends ThreeCorpus {

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                this.getThickness() / 2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                this.getThickness() / 2,
                0
            )
        );
    }

    protected createBackPanels() {
    }
}