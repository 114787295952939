import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeBottomBarStandCorpus} from "../../../details/ThreeCorpus/types/ThreeBottomBarStandCorpus";
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnitBarStand extends ThreeBottomUnit {
    corpus: ThreeBottomBarStandCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomBarStandCorpus {
        return new ThreeBottomBarStandCorpus(CommonHelper.deepCopy(corpusData), this)
    }
}