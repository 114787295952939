import {
    Box3,
    BoxGeometry,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    Vector3
} from 'three';
import {onAfterRenderHideForBack, onBeforeRenderHideForBack} from '../../../../helpers/ThreeHelper/ThreeHelper';
import {
    DOOR_FRAME_DEPTH,
    DOOR_FRAME_WIDTH,
    SETTING_GROUP_GENERAL,
} from '../../../../../constants';
import {SIDE_TYPE_BOTTOM, SIZE_TYPE_HEIGHT, SIZE_TYPE_WIDTH} from '../../../../../../common-code/constants';
import {ThreeWallConstructive} from '../ThreeWallConstructive/ThreeWallConstructive';
import {ThreeWall} from '../../rooms/ThreeWall/ThreeWall';
import {ISaveDoorwayData} from '../../../../../../common-code/interfaces/saveData/ISaveDoorwayData';
import {ISettingGroup} from '../../../../../interfaces/settingData/ISettingGroup';
import {IOption} from '../../../../../../common-code/interfaces/option/IOption';
import {ISettingGroupGeneral} from '../../../../../interfaces/settingData/ISettingGroupGeneral';
import {IOptionRange} from '../../../../../../common-code/interfaces/option/IOptionRange';
import {ISaveSizeData} from '../../../../../../common-code/interfaces/saveData/ISaveSizeData';
import {UNIT_SIZE_TEXT_SIZE} from '../../../../constants';

export class ThreeDoorway extends ThreeWallConstructive {
    material: MeshStandardMaterial | null;
    saveData: ISaveDoorwayData;

    constructor(doorData: ISaveDoorwayData, wall: ThreeWall) {
        super(doorData, wall);
        this.saveData = doorData;
        this.material = null;
        // this.canMove = false;
    }

    public createView(isRebuild?: boolean) {
        this.createDoorway();
        this.createFrames();
        this.addCoverPoints([
            new Vector3(-this.getWidth()/2, -this.getHeight()/2, 0),
            new Vector3(this.getWidth()/2, this.getHeight()/2, DOOR_FRAME_DEPTH/2),
        ]);
        if (this.saveData.frames && this.saveData.includeSizeFrames) {
            this.addCoverPoints([
                new Vector3(-this.getWidth()/2 - this.getFrameWidth(), -this.getHeight()/2, 0),
                new Vector3(this.getWidth()/2 + this.getFrameWidth(), this.getHeight()/2 + this.getFrameWidth(), DOOR_FRAME_DEPTH/2),
            ]);
        }
        this.calculateGlobalFrontVector();
        super.createView(isRebuild);
    }

    public getFrameWidth(): number {
        if (!this.saveData.frames) {
            return 0;
        }
        return this.saveData.frameWidth || DOOR_FRAME_WIDTH;
    }

    public defaultYPosition(): number {
        return this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    public getSettingsGroups(): {[key: string]: ISettingGroup} {
        let groups: {[key: string]: ISettingGroup};
        let option: IOption;

        groups = super.getSettingsGroups();
        if ((groups[SETTING_GROUP_GENERAL].data as ISettingGroupGeneral).other) {
            for (option of (groups[SETTING_GROUP_GENERAL].data as ISettingGroupGeneral).other) {
                if (option.id === 'height') {
                    if ('max' in option) {
                        if ((option as IOptionRange).max >= (this.service.getRoom().getHeight() - this.getFrameWidth())) {
                            (option as IOptionRange).max = (this.service.getRoom().getHeight() - this.getFrameWidth())
                        }
                    }
                }
            }
        }

        return groups;
    }

    protected initSizesData(): ISaveSizeData[] {
        let sizesData: ISaveSizeData[] = [];
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        sizesData.push({
            id: 0,
            pointA: {x: coverBox.min.x, y: coverBox.max.y, z: coverBox.max.z + this.SIZE_GAP},
            pointB: {x: coverBox.max.x, y: coverBox.max.y, z: coverBox.max.z + this.SIZE_GAP},
            type: SIZE_TYPE_WIDTH,
            decimal: 0,
            textSize: UNIT_SIZE_TEXT_SIZE,
            textIndent: 0,
        });
        sizesData.push({
            id: 0,
            pointA: {x: coverBox.max.x, y: coverBox.min.y, z: coverBox.max.z + this.SIZE_GAP},
            pointB: {x: coverBox.max.x, y: coverBox.max.y, z: coverBox.max.z + this.SIZE_GAP},
            type: SIZE_TYPE_HEIGHT,
            decimal: 0,
            rotation: {z: -Math.PI/2},
            textSize: UNIT_SIZE_TEXT_SIZE,
            textIndent: 0,
        });

        return sizesData;
    }

    protected initPosition(): Vector3 {
        let initPosition: Vector3;

        initPosition = new Vector3(0, this.getHeight() / 2, 0);
        if (this.saveData.initPosition) {
            if (this.saveData.initPosition.x !== undefined && !isNaN(+this.saveData.initPosition.x)) {
                initPosition.x = +this.saveData.initPosition.x;
            }
            if (this.saveData.initPosition.y !== undefined && !isNaN(+this.saveData.initPosition.y)) {
                initPosition.y = +this.saveData.initPosition.y;
            }
            if (this.saveData.initPosition.z !== undefined && !isNaN(+this.saveData.initPosition.z)) {
                initPosition.z = +this.saveData.initPosition.z;
            }
        }
        if (this.saveData.position) {
            if (this.saveData.position.x !== undefined && !isNaN(+this.saveData.position.x)) {
                initPosition.x = this.saveData.position.x;
            }
            if (this.saveData.position.y !== undefined && !isNaN(+this.saveData.position.y)) {
                initPosition.y = this.saveData.position.y;
            }
            if (this.saveData.position.z !== undefined && !isNaN(+this.saveData.position.z)) {
                initPosition.z = this.saveData.position.z;
            }
        }
        initPosition.y = this.defaultYPosition();

        return initPosition;
    }


    private getBodyMaterial(): MeshStandardMaterial {
        if (!this.material) {
            this.material = new MeshStandardMaterial({color: '#919191',emissiveIntensity:4})
        }

        return this.material;
    }

    private createDoorway() {
        let doorway;

        doorway = new Mesh(
            new BoxGeometry(this.getWidth(), this.getHeight(), this.getDepth() + 2),
            new MeshBasicMaterial()
        );
        doorway.matrixAutoUpdate = false;
        doorway.material.colorWrite = false;
        doorway.position.y = 0;
        doorway.renderOrder = 1;
        doorway.position.z = -this.getDepth()/2 + 30;
        doorway.name = 'doorway';
        doorway.onBeforeRender = onBeforeRenderHideForBack;
        doorway.onAfterRender = onAfterRenderHideForBack;
        doorway.userData.notSwitchView = true;
        this.view3d.add(doorway);
    }

    private createFrames() {
        let geometry;
        let frames = [];
        let frame;
        let carcass;

        if (!this.saveData.frames) {
            return;
        }

        geometry = new BoxGeometry(this.getFrameWidth(), this.getHeight() + this.getFrameWidth(), DOOR_FRAME_DEPTH);
        frames[0] = new Mesh(geometry, this.getBodyMaterial());
        frames[0].position.x = this.getWidth() / 2 + this.getFrameWidth() / 2;
        frames[0].position.y = this.getFrameWidth() / 2;
        frames[1] = frames[0].clone();
        frames[1].position.x = -this.getWidth() / 2 - this.getFrameWidth() / 2;
        frames[1].position.y = this.getFrameWidth() / 2;
        geometry = new BoxGeometry(this.getWidth(), this.getFrameWidth(), DOOR_FRAME_DEPTH);
        frames[2] = new Mesh(geometry, this.getBodyMaterial());
        frames[2].position.y = this.getHeight() / 2 + this.getFrameWidth() / 2;
        for (frame of frames) {
            frame.onBeforeRender = onBeforeRenderHideForBack;
            frame.onAfterRender = onAfterRenderHideForBack;
            frame.name = 'frame';
            frame.matrixAutoUpdate = false;
            carcass = this.createMeshCarcass(frame);
            carcass.userData.parent = this.view3d;
            carcass.onBeforeRender = onBeforeRenderHideForBack;
            carcass.onAfterRender = onAfterRenderHideForBack;
            this.view3d.add(frame);
        }
    }
}