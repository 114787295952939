import React, {useState} from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import "./LoginForm.css"
import {useTranslation} from 'react-i18next';
import {Tabs} from '../../components/Tabs/Tabs';
import {ITabsMenuTabProps} from '@yandex/ui/TabsMenu/Tab/TabsMenu-Tab';
import {ITabsPanesPaneProps} from '@yandex/ui/TabsPanes/Pane/TabsPanes-Pane';
import {LoginFormUser} from './User/LoginForm-User';
import {LoginFormManager} from './Manager/LoginForm-Manager';

export const cnLoginForm = cn('LoginForm');

export interface ILoginFormProps extends IClassNameProps {
}

export const LoginForm: React.FC<ILoginFormProps> = ({className}) => {
    const [loginType, setLoginType] = useState<'user' | 'manager'>('user')
    const {t} = useTranslation();

    const tabs: ITabsMenuTabProps[] = [
        {id: 'user', content: t('Покупатель')},
        {id: 'manager', content: t('Менеджер')},
    ];
    const tabsContent: ITabsPanesPaneProps[] = [
        {id: 'user', content: <LoginFormUser />},
        {id: 'manager', content: <LoginFormManager />},
    ];

    return (
        <>
            <div className={cnLoginForm({}, [className])}>
                <p className={cnLoginForm('Header')}>{t('Войти/Зарегистрироваться')}</p>
                <Tabs initActiveTab={loginType} tabs={tabs} tabsContent={tabsContent} callbackSetActiveTab={(value: string) => {
                    if (['user', 'manager'].includes(value)) {
                        setLoginType(value as 'user' | 'manager')
                    }
                }}/>
            </div>
        </>
    );
};