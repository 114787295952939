import React from "react";
import { cnSettingsMenu, ISettingsMenuGroupProps } from "../SettingsMenu";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ISettingGroupFacades } from "../../../../interfaces/settingData/ISettingGroupFacades";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { IFacadeMaterialData } from "../../../../../common-code/interfaces/materials/IFacadeMaterialData";
import { AppState } from "../../../../redux/AppStore";
import { ISettingFacade } from "../../../../interfaces/settingData/ISettingFacade";
import { TDirectionSideType } from "../../../../../common-code/types/TDirectionSideType";
import { ObjectSelect } from "../../../components/ObjectSelect/ObjectSelect";
import {
  OBJECT_SELECT_TYPE_FACADE,
  OBJECT_SELECT_TYPE_FACADE_MATERIAL,
} from "../../../../constants";
import { ObjectOption } from "../../../components/ObjectOption/ObjectOption";
import { OPTION_TYPE_RADIOBUTTON } from "../../../../../common-code/constants";
import { IMaterialData } from "../../../../../common-code/interfaces/materials/IMaterialData";

export interface ISettingsMenuFacadesGroupProps
  extends ISettingsMenuGroupProps {
  facadesData: ISettingGroupFacades;
  service: KitchenService;
}

export const SettingsMenuFacades: React.FC<ISettingsMenuFacadesGroupProps> = ({
  className,
  onRebuildData,
  facadesData,
  service,
}) => {
  const facadeMaterials: IFacadeMaterialData[] | undefined = useSelector(
    (state: AppState) => state.wizard.facadeMaterials
  );
  const { t } = useTranslation();
  const onSelectFacadeMaterial = (
    facadeMaterial: IFacadeMaterialData,
    settingFacade: ISettingFacade
  ) => {
    onRebuildData("tryUnitSetFacadeMaterial", {
      facadeMaterial: facadeMaterial.id,
      settingFacade: settingFacade,
    });
  };

  const onChangeSideType = (
    settingFacade: ISettingFacade,
    value: TDirectionSideType
  ) => {
    onRebuildData("trySetFacadeSideType", {
      sideType: value,
      settingFacade: settingFacade,
    });
  };

  return (
    <div className={cnSettingsMenu("Facades", [className])}>
      {facadesData.items.map((settingFacade: ISettingFacade, index) => {
        return (
          <div className={cnSettingsMenu("Facade")} key={index}>
            <ObjectSelect
              key={index}
              className={cnSettingsMenu("Facade")}
              type={OBJECT_SELECT_TYPE_FACADE_MATERIAL}
              data={{
                type: OBJECT_SELECT_TYPE_FACADE,
                object: settingFacade.facadeMaterial,
              }}
              list={facadeMaterials}
              onSelectObject={(facadeMaterial: IMaterialData) => {
                return onSelectFacadeMaterial(
                  facadeMaterial as IFacadeMaterialData,
                  settingFacade
                );
              }}
              service={service}
            />
            {settingFacade.sideTypes ? (
              <div className={cnSettingsMenu("FacadeSideType")}>
                <ObjectOption
                  type={OPTION_TYPE_RADIOBUTTON}
                  option={{
                    type: OPTION_TYPE_RADIOBUTTON,
                    title: t("Открывание двери"),
                    items: settingFacade.sideTypes,
                    defaultValue: settingFacade.sideType,
                  }}
                  defaultValue={settingFacade.sideType}
                  onChange={(fieldId: string, value: any) => {
                    onChangeSideType(settingFacade, value);
                  }}
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
