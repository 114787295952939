import React from "react";
import { cnCreateForm } from "../../../FormModule";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { IFacade } from "../initialFacade";

function FacadeCalcSizes({
  facadeNew,
  onInputChange,
}: {
  facadeNew: IFacade;
  onInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
}) {
  const { calcSizesWidth, calcSizesHeight } = facadeNew;
  return (
    <>
      <h4 className={cnCreateForm("Title")}>Расчетные размеры</h4>
      <small>
        * Для указания размера в процентах перед вводом значения указать %
        (например %100), для указания размера в мм указать число (например 200)
      </small>
      <div className={cnCreateForm("Box-Input")}>
        <div>
          <label htmlFor="calcSizesWidth">Ширина фасада расчетная *</label>
          <Textinput
            className={cnCreateForm("Textinput")}
            value={calcSizesWidth}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e)
            }
            name="calcSizesWidth"
          />
        </div>
        <div>
          <label htmlFor="calcSizesHeight">Высота фасада расчетная *</label>
          <Textinput
            className={cnCreateForm("Textinput")}
            value={calcSizesHeight}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e)
            }
            name="calcSizesHeight"
          />
        </div>
      </div>
    </>
  );
}

export default FacadeCalcSizes;
