import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {useEffect, useRef, useState} from "react";
import './CatalogItem.css';
import {LINK_TYPE_NAV} from "../../constants";
import {Link} from "../../components/Link/Link";
import {cnLink} from "../../components/Link";
import {ICollectionDto} from "../../../../common-code/interfaces/ICollectionDto";
import {useTranslation} from "react-i18next";
import {useLocation} from 'react-router-dom';
import {Button, cnButton} from "../../components/Button";
import {cnModal, Modal} from "../../components/Modal";
import {cnPageCollections} from "../../../pages/PageCollections";
import {AmoForm, cnAmoForm} from "../AmoForm";

export const cnCatalogItem = cn('CatalogItem');

export interface ICatalogItemProps extends IClassNameProps {
    collectionData: ICollectionDto;
}

export const CatalogItem: React.FC<ICatalogItemProps> = ({className, collectionData}) => {
    const [viewHidden, setViewHidden] = useState<boolean>(true);
    const [isHidden, setIsHidden] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const imagesContainer = useRef<HTMLDivElement>(null);
    const {t} = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const resizeEffect = () => {
            if (!imagesContainer.current) {
                return;
            }
            imagesContainer.current.style.height = `${+imagesContainer.current.clientWidth / 1.75}px`;
        }

        window.addEventListener('resize', resizeEffect, false);

        return () => {
            window.removeEventListener('resize', resizeEffect, false);
        };
    }, [imagesContainer]);

    const renderButtons = (autofit: boolean) => {
        switch (location.pathname) {
            case '/collections':
                return <>
                    <Button
                        className={cnCatalogItem(cnButton())}
                        type={'button'}
                        leftIcon={{path: 'list', width: 20, height: 20, marginRight: 8}}
                        text={t('Создайте за меня!') || undefined}
                        mods={{mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}}}
                        callBack={() => {setShowModal(true)}}
                    />
                    {autofit &&
                        <Button
                            className={cnCatalogItem(cnButton())}
                            type={'button'}
                            leftIcon={{path: 'magic', width: 20, height: 20, marginRight: 8}}
                            text={t('Авторасчет') || undefined}
                            mods={{mods: {color: 'white', bkg: 'lightOrange', border: 'orange', fill: 'white'}}}
                            callBack={() => {
                                // TODO!
                                console.warn('Выполнить переадресацию на страницу autofit!');
                            }}
                        />
                    }
                </>;
            case '/complect-collections':
                return <>
                    <Button
                        className={cnCatalogItem(cnButton())}
                        type={'button'}
                        leftIcon={{path: 'whatsapp', width: 20, height: 20, marginRight: 8}}
                        text={t('Запрос в WhatsApp') || undefined}
                        mods={{mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}}}
                        callBack={() => {
                            // TODO!
                            console.warn('Передать данные в WhatsApp!');
                        }}
                    />
                </>;
            default:
                return null
        }
    }

    return (
        <>
            <div className={cnCatalogItem({}, [className])}>
                <Link
                    className={cnCatalogItem(cnLink())}
                    type={LINK_TYPE_NAV}
                    href={`/collection/${collectionData.id}`}
                >
                    <div
                        className={cnCatalogItem('Images')}
                        ref={imagesContainer}
                    >
                        <img className={cnCatalogItem('Image')} src={collectionData.image} alt={collectionData.name}/>
                    </div>
                </Link>
                <div className={cnCatalogItem('Content')}>
                    <div className={cnCatalogItem('Info', {hidden: isHidden})}>
                        <p className={cnCatalogItem('Title')}>{collectionData.name}</p>
                        <p className={cnCatalogItem('Price')}>{`${t('от')} ${collectionData.pmPrice} ${t('руб/мп')}`}</p>
                        {collectionData.byOrder &&
                            <p className={cnCatalogItem('Error')}>{t('Коллекция только под заказ')}.</p>
                        }
                        {collectionData.closed &&
                            <p className={cnCatalogItem('Error')}>{t('Внимание! Коллекция снята с производства!')}</p>
                        }
                        <p className={cnCatalogItem('Description')}>{collectionData.description}</p>
                    </div>
                    {viewHidden &&
                        <p
                            className={cnCatalogItem('More')}
                            onClick={() => {setIsHidden(!isHidden)}}
                            ref={(element) => {
                                if (!element || !(element.previousSibling instanceof HTMLDivElement)) {
                                    return;
                                }
                                if (element.previousSibling.scrollHeight <= 130) {
                                    setViewHidden(false);
                                }
                            }}
                        >
                            {isHidden ? t('показать') : t('скрыть')}
                        </p>
                    }
                    <div className={cnCatalogItem('Actions')}>
                        <div className={cnCatalogItem('Buttons')}>
                            {renderButtons(collectionData.autofit)}
                        </div>
                        <Link
                            className={cnCatalogItem(cnLink(), {textual: true})}
                            type={LINK_TYPE_NAV}
                            href={`/collection/${collectionData.id}`}
                        >
                            <p>{t('Подробнее')}</p>
                        </Link>
                    </div>
                </div>
            </div>
            {location.pathname === '/collections' &&
                <Modal
                    className={cnPageCollections(cnModal())}
                    visible={showModal}
                    callBack={setShowModal}
                >
                    <AmoForm className={cnPageCollections(cnAmoForm())} callBack={setShowModal}/>
                </Modal>
            }
        </>
    );
}