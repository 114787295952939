import {ThreeEquipment} from '../ThreeEquipment/ThreeEquipment';
import {Vector3} from 'three';
import {SIDE_TYPE_BOTTOM} from '../../../../../../common-code/constants';
import {TLevelBoxes} from '../../../../types/TLevelBoxes';
import {ThreeSize} from '../../ThreeSize/ThreeSize';
import {IHistoryObjectData} from '../../../../interfaces/history/IHistoryObjectData';
import {ISaveUnitData} from '../../../../../../common-code/interfaces/saveData/ISaveUnitData';
import {ISaveKUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {ISaveEquipmentData} from '../../../../../../common-code/interfaces/saveData/ISaveEquipmentData';

export class ThreeBottomEquipment extends ThreeEquipment {

    public setPosition(position?: Vector3): void {
        if (position) {
            this.view3d.position.copy(position);
        } else {
            if (this.view3d.position.y !== this.defaultYPosition()) {
                this.view3d.position.y = this.defaultYPosition();
            }
        }
        this.afterSetPosition();
    }

    public defaultYPosition(): number {
        return this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    public trySetLegsHeight(height: number): IHistoryObjectData | undefined {
        let oldSaveData: ISaveUnitData;
        let newSaveData: ISaveUnitData;
        let position: Vector3;
        let plinthData: ISaveKUnitDetailData;
        let isRebuild: boolean = false;

        oldSaveData = this.getData();
        newSaveData = this.getData();
        newSaveData.sizes.height = this.service.getBottomUnitHeight() + height
        if (newSaveData.plinths && newSaveData.plinths.length) {
            isRebuild = true;
            for (plinthData of newSaveData.plinths) {
                delete plinthData.sizes?.width;
            }
        }
        if (isRebuild || newSaveData.sizes.height !== oldSaveData.sizes.height) {
            this.rebuild(newSaveData);
            position = this.getPosition();
            position.y = this.defaultYPosition();
            this.setPosition(position);

            return {
                oldData: oldSaveData,
                newData: newSaveData
            };
        }

        return undefined;
    }

    protected initSaveData(options: ISaveEquipmentData): ISaveEquipmentData {
        super.initSaveData(options);
        options.sizes.height = this.service.getBottomUnitHeight() + this.service.getBottomUnitLegsHeight()

        return options;
    }

    protected setSizesPosition(levelBoxes?: TLevelBoxes) {
        let size: ThreeSize;

        if (!levelBoxes) {
            return;
        }
        for (size of this.sizes) {
            size.setPositionFromLevelBox(levelBoxes.bottom.max.y - this.view3d.position.y);
        }
    }

    protected correctMovePosition(movePosition: Vector3) {
        this.correctFloorUnitMovePosition(movePosition);
    }
}