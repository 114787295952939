import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTopPilasterCorpus} from "../../../details/ThreeCorpus/types/ThreeTopPilasterCorpus";
import {ISaveTopUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitPilaster extends ThreeTopUnit {
    corpus: ThreeTopPilasterCorpus;

    constructor(options: ISaveTopUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeTopPilasterCorpus {
        return new ThreeTopPilasterCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}