import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cnWizard} from '../Wizard/Wizard';
import './AppMessages.css'
import {cn} from '@bem-react/classname';
import {useSelector} from 'react-redux';
import {TMessage} from '../../../types/TMessage';
import {AppState} from '../../../redux/AppStore';
import {Messages} from '../Messages/Messages';

export const cnAppMessages = cn('AppMessages');
export interface IAppMessagesProps extends IClassNameProps {
}

export const AppMessages: React.FC<IAppMessagesProps> = ({className}) => {
    const messages: TMessage[] = useSelector((state: AppState) => state.wizard.messages);

    if (messages.length <= 0) {
        return null;
    }
    return (
        <div className={cnAppMessages({}, [cnWizard(cnAppMessages()), className])}>
            <Messages messages={messages}/>
        </div>
    );
};