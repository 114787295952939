import {Reducer} from "redux";
import {
    CHANGE_HISTORY_REDO,
    CHANGE_HISTORY_UNDO,
    HIDE_LOADING,
    HIDE_TEXTURE_LOADING, LOADING_PRICE,
    SHOW_LOADING,
    SHOW_TEXTURE_LOADING,
    TOGGLE_MENU,
    TOGGLE_MODAL
} from "../../constants";
import {IUIConfig} from '../../interfaces/IUIConfig';

const initState: IUIConfig = {
    MainMenu: {
        visible: false,
    },
    UserMenu: {
        visible: false,
    },
    Modal: {
        visible: false,
    },
    loadingPrice: false,
    apiLoading: false,
    appLoading: true,
    textureLoading: false,
    history: {
        redo: false,
        undo: false
    }
};

export const UIReducer:Reducer = (state = initState, action) => {
    switch (action.type) {
        case TOGGLE_MENU:
            return { ...state, [action.payload.forMenu]: {visible: action.payload.value} };
        case TOGGLE_MODAL:
            return { ...state, Modal: {visible: action.payload.value} };
        case SHOW_LOADING: {
            return {...state, apiLoading: true};
        }
        case HIDE_LOADING: {
            return {...state, apiLoading: false};
        }
        case SHOW_TEXTURE_LOADING: {
            return {...state, textureLoading: true};
        }
        case HIDE_TEXTURE_LOADING: {
            return {...state, textureLoading: false};
        }
        case LOADING_PRICE: {
            return {...state, loadingPrice: !!action.payload};
        }
        case CHANGE_HISTORY_UNDO: {
            return {...state, history: {...state.history, undo: !!action.payload}};
        }
        case CHANGE_HISTORY_REDO: {
            return {...state, history: {...state.history, redo: !!action.payload}};
        }
        default:
            return state;
    }
};