import {ThreeCorpus} from "../ThreeCorpus";
import {BoxGeometry, Vector3} from "three";
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from "../../../../../../../common-code/constants";
import {ThreeShelf} from "../../ThreeShelf/ThreeShelf";
import {
    ThreeBottomUnitEndFullLengthSidewallConsole
} from "../../../units/ThreeBottomUnit/types/ThreeBottomUnitEndFullLengthSidewallConsole";
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {ISaveShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfData';
import {CommonHelper} from 'common-code';

export class ThreeBottomEndFullLengthSidewallConsoleCorpus extends ThreeCorpus {
    unit: ThreeBottomUnitEndFullLengthSidewallConsole;

    constructor(options: ISaveCorpusData, unit: ThreeBottomUnitEndFullLengthSidewallConsole) {
        super(options, unit);
        this.unit = unit;
    }

    public getInnerWidth() {
        return this.saveData.sizes.length - this.getThickness();
    }

    public getInnerDepth() {
        return this.saveData.sizes.width - this.getThickness() - this.getBackThickness();
    }

    protected createShelves() {
        let shelfData: ISaveShelfData;
        let shelf: ThreeShelf;
        for (shelfData of this.saveData.shelves) {
            switch (this.unit.getSideType()) {
                case SIDE_TYPE_LEFT:
                    shelfData.initPosition = {...shelfData.initPosition, x: `=({%50}-${this.getThickness() / 2})`};
                    break;
                case SIDE_TYPE_RIGHT:
                    shelfData.initPosition = {...shelfData.initPosition, x: `=({%50}+${this.getThickness() / 2})`};
                    break;
            }
            shelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this);
            shelf.initState();
            shelf.createView();
            this.shelves.push(shelf);
        }
    }

    protected createBackPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getHeight(), this.getBackThickness()),
            'back',
            new Vector3(
                0,
                0,
                -this.getDepth() / 2 + this.getBackThickness() / 2
            )
        );
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getHeight(), this.getThickness()),
            'front',
            new Vector3(
                0,
                0,
                this.getDepth() / 2 - this.getThickness() / 2
            )
        );

        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                this.createPanel(
                    new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getThickness() - this.getBackThickness()),
                    'right',
                    new Vector3(
                        this.getWidth() / 2 - this.getThickness() / 2,
                        0,
                        (this.getBackThickness() - this.getThickness()) / 2
                    )
                );
                break;
            case SIDE_TYPE_RIGHT:
                this.createPanel(
                    new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getThickness() - this.getBackThickness()),
                    'left',
                    new Vector3(
                        -this.getWidth() / 2 + this.getThickness() / 2,
                        0,
                        (this.getBackThickness() - this.getThickness()) / 2
                    )
                );
                // this.createPanel(
                //     new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth() - this.getThickness() - this.getBackThickness()),
                //     'top',
                //     new Vector3(
                //         0,
                //         this.getHeight() / 2 - this.getThickness() / 2,
                //         (this.getBackThickness() - this.getThickness()) / 2
                //     )
                // );
                // this.createPanel(
                //     new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth()),
                //     'bottom',
                //     new Vector3(
                //         0,
                //         -this.getHeight() / 2 + this.getThickness() / 2,
                //         0
                //     )
                // );
                break;
        }
    }
}