import React from 'react'
import Trash from '../../../icons/Trash';
import { ITableTop } from './TableTops';
import { cnCreateForm } from '../../FormModule';
import Edit from '../../../icons/Edit';
import ModalTableTops from './ModalTableTops';

interface ITableTopProps {
  table: ITableTop
  index: number
  arrTabletops: ITableTop[]
  setArrTabletops: (arr: ITableTop[]) => void
  validateTabletop: (tableTopNew: ITableTop) => string
}

function TableTop ({
      arrTabletops,
      setArrTabletops, 
      table,
      index,
      validateTabletop
    }:ITableTopProps) {

    const [modal, setModal] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>('');
    const [newTabletop, setNewTabletop] = React.useState<ITableTop>(table);

    const handleToggleModal = () => setModal((prev) => !prev);
    const onFocus = () => {
      setError('');
    }

    const handleDeleteTable = () =>
      setArrTabletops(arrTabletops.filter((_el, i) => i !== index));

    const handleEditTable = () => {
        const error = validateTabletop(newTabletop);
        if(error){
            setError(error);
            return ;
        }
        setArrTabletops(arrTabletops.map((el) => el.functionalType === table.functionalType ? newTabletop : el));
        setModal(!modal);
    }

    return (
      <div className={cnCreateForm('Children')}>
        <span>Столешница</span>
        <div className={cnCreateForm('Box-Btn')}>
          <Edit handleToggleModal={handleToggleModal} />
          <Trash handleDeleteValue={handleDeleteTable} />
        </div>

        <ModalTableTops
            newTabletop={newTabletop}
            setNewTabletop={setNewTabletop}
            modal={modal}
            handleToggleModal={handleToggleModal}
            error={error}
            onFocus={onFocus}
            handleSubmit={handleEditTable}
            keyValue={`#editTabletop${index}`}
        />
      </div>
    )
    }


export default TableTop