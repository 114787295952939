import React, {useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {useSelector} from 'react-redux';
import './Wizard-Steps.css'
import {useTranslation} from 'react-i18next';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {TStep} from '../../../../../common-code/types/TStep';
import {AppState} from '../../../../redux/AppStore';
import useCurrentStep from '../../../../hooks/useCurrentStep';
import {useModsGenerator} from '../../../../hooks/useModsGenerator';
import {cn} from '@bem-react/classname';

export const cnWizardSteps = cn('WizardSteps');
export interface IWizardStepsProps extends IClassNameProps {
    service: KitchenService;
}

export const WizardSteps: React.FC<IWizardStepsProps> = ({ className, service }) => {
    const steps: TStep[] = useSelector((state: AppState) => state.wizard.steps);
    const {currentStep, changeStep} = useCurrentStep(service);
    const [currentItem, setCurrentItem] = useState<number| null>(null);
    const modsGenerator = useModsGenerator();
    const {t} = useTranslation();

    const changeCurrentStep = (id: string) => {
        changeStep(id);
    }

    return (
        <div className={cnWizardSteps({}, [className])}>
            <div className={cnWizardSteps('Container')}>
                <div className={cnWizardSteps('Steps')}>
                    {steps.map((step: TStep) => {
                        return (
                            <div
                                key={step.id}
                                className={cnWizardSteps('Step', {...modsGenerator({
                                        // hover: {state: currentItem === step.sort, mods: {cursor: 'pointer', bkg: 'white'}},
                                        // active: {state: currentStep.id === step.id, mods: {bkg: 'white'}},
                                        mods: {complete: step.sort < currentStep.sort, current: step.sort === currentStep.sort, view: step.id}
                                    })})}
                                onClick={() => {changeCurrentStep(step.id)}}
                                onMouseEnter={() => {setCurrentItem(step.sort)}}
                                onMouseLeave={() => {setCurrentItem(null)}}>
                                <div className={cnWizardSteps('StepTitle', {...modsGenerator({
                                        hover: {state: currentItem === step.sort, mods: {color: 'red'}},
                                        active: {state: currentStep.id === step.id, mods: {color: 'red'}}
                                    })})}>{t(step.title)}</div>
                                <div className={cnWizardSteps('StepCircle')}></div>
                            </div>
                        );
                    })}
                </div>
            </div>

        </div>
    );
};