import React from "react";
import { cnCreateForm } from "../../../FormModule";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { Tumbler } from "../../../../../../components/Tumbler/Tumbler";
import { IFacade } from "../initialFacade";

function FacadeMargins({
  facadeNew,
  onInputChange,
  onChecked,
}: {
  facadeNew: IFacade;
  onInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { margin, marginX, marginY, marginZ } = facadeNew;
  return (
    <>
      <hr />
      <h4 className={cnCreateForm("Title")}>Отступы</h4>
      <Tumbler
        className={cnCreateForm("Tumbler")}
        checked={margin}
        callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
        label="Добавить отступы для фасада"
        name="margin"
      />

      {margin && (
        <div className={cnCreateForm("Box-Input")}>
          <div>
            <label htmlFor="marginX">Отступ по горизонтали</label>
            <Textinput
              className={cnCreateForm("Textinput")}
              value={marginX}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="marginX"
              type="number"
            />
          </div>
          <div>
            <label htmlFor="marginY">Отступ по вертикали</label>
            <Textinput
              className={cnCreateForm("Textinput")}
              value={marginY}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="marginY"
              type="number"
            />
          </div>
          <div>
            <label htmlFor="marginZ">Отступ в глубину</label>
            <Textinput
              className={cnCreateForm("Textinput")}
              value={marginZ}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="marginZ"
              type="number"
            />
          </div>
        </div>
      )}
      <hr />
      <br />
    </>
  );
}

export default FacadeMargins;
