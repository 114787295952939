import {ThreeBottomUnit} from "../ThreeBottomUnit";
import {KitchenService} from "../../../../../services/KitchenService/KitchenService";
import {ThreeTabletop} from "../../../details/ThreeTabletop/ThreeTabletop";
import {Box3, Vector3} from "three";
import {
    ALIGN_BOTTOM,
    ALIGN_LEFT,
    ALIGN_RIGHT,
    SIDE_TYPE_FRONT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_NONE,
    SIDE_TYPE_RIGHT,
} from "../../../../../../../common-code/constants";
import {TDirectionSideType} from "../../../../../../../common-code/types/TDirectionSideType";
import {CommonHelper} from "common-code";
import {ISaveFacadeData} from "../../../../../../../common-code/interfaces/saveData/ISaveFacadeData";
import {ISaveKUnitDetailData} from "../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData";
import {
    ISaveBottomAngleNormalSingleHandleCorpusData
} from "../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleNormalSingleHandleCorpusData";
import { ThreeBottomAngleNormalBertaCorpus } from "../../../details/ThreeCorpus/types/ThreeBottomAngleNormalBertaCorpus";
import { ISaveBottomUnitAngleNormalBertaData } from "../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleNormalBertaData";

export class ThreeBottomUnitAngleNormalBerta extends ThreeBottomUnit {
    corpus: ThreeBottomAngleNormalBertaCorpus;
    saveData: ISaveBottomUnitAngleNormalBertaData;

    constructor(
        options: ISaveBottomUnitAngleNormalBertaData,
        service: KitchenService
    ) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    public isAngleUnit(): boolean {
        return true;
    }

    public getInitTabletopPosition(tabletop: ThreeTabletop): Vector3 {
        let position: Vector3;
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        position = new Vector3(
            (coverBox.min.x + coverBox.max.x) / 2,
            coverBox.max.y + tabletop.getHeight() / 2,
            this.getZInitTabletopPosition(tabletop)
        );
        switch (this.getSideType()) {
            case SIDE_TYPE_RIGHT:
                position.x = coverBox.min.x + tabletop.getLength() / 2;
                break;
            case SIDE_TYPE_LEFT:
                position.x = coverBox.max.x - tabletop.getLength() / 2;
                break;
        }

        return position;
    }

    protected initCorpus(
        corpusData: ISaveBottomAngleNormalSingleHandleCorpusData
    ): ThreeBottomAngleNormalBertaCorpus {
        return new ThreeBottomAngleNormalBertaCorpus(
            CommonHelper.deepCopy(corpusData),
            this
        );
    }

    protected calculateInitFacadesData(
        facades?: ISaveFacadeData[]
    ): ISaveFacadeData[] | undefined {
        if (facades) {
            let facade: ISaveFacadeData;
            for (facade of facades) {
                facade.align =
                    this.getSideType() === SIDE_TYPE_RIGHT
                        ? {x: ALIGN_LEFT, y: facade.align ? facade.align.y : ALIGN_BOTTOM}
                        : {
                            x: ALIGN_RIGHT,
                            y: facade.align ? facade.align.y : ALIGN_BOTTOM,
                        };
                if (facade.margin) {
                    facade.margin.x =
                        this.getSideType() === SIDE_TYPE_RIGHT
                            ? Math.abs(facade.margin.x)
                            : Math.abs(facade.margin.x) * -1;
                }
                // if (facade.rotation && facade.rotation.y !== undefined && !isNaN(facade.rotation.y)) {
                //     console.log('facade.rotation.y')
                //     facade.rotation.y = this.getSideType() === SIDE_TYPE_LEFT ?
                //         Math.abs(+facade.rotation.y) - Math.PI :
                //         Math.abs(+facade.rotation.y);
                // }
            }
        }

        return facades;
    }

    protected calculateInitPlinthsData(
        plinths?: ISaveKUnitDetailData[]
    ): ISaveKUnitDetailData[] | undefined {
        if (plinths) {
            let plinth: ISaveKUnitDetailData;
            for (plinth of plinths) {
                if (plinth.initPosition && plinth.initPosition.x !== undefined) {
                    switch (plinth.positionType) {
                        case SIDE_TYPE_FRONT:
                            plinth.initPosition.x =
                                this.getSideType() === SIDE_TYPE_RIGHT
                                    ? Math.abs(+plinth.initPosition.x)
                                    : Math.abs(+plinth.initPosition.x) * -1;
                            break;
                        case SIDE_TYPE_NONE:
                            plinth.initPosition.x =
                                this.getSideType() === SIDE_TYPE_RIGHT
                                    ? Math.abs(+plinth.initPosition.x) * -1
                                    : Math.abs(+plinth.initPosition.x);
                            break;
                    }
                    if (plinth.margin && plinth.margin.x !== undefined) {
                        plinth.margin.x =
                            this.getSideType() === SIDE_TYPE_RIGHT
                                ? Math.abs(+plinth.margin.x)
                                : Math.abs(+plinth.margin.x) * -1;
                    }
                }
            }
        }

        return plinths;
    }
}
