import {ThreeUnitDetail} from '../ThreeUnitDetail/ThreeUnitDetail';
import {Euler, Group, Object3D, Vector3} from 'three';
import {ThreeFacade} from '../ThreeFacade/ThreeFacade';
import {
    ALIGN_BOTTOM,
    ALIGN_CENTER,
    ALIGN_LEFT,
    ALIGN_RIGHT,
    ALIGN_TOP,
    FACADE_OPEN_TYPE_VERTICAL_PIVOT,
    FURNITURE_FT_HANDLE,
    FURNITURE_TYPE_NONE,
    HANDLE_MODEL_TYPE_DEFAULT,
    HANDLE_TYPE_HORIZONTAL,
    HANDLE_TYPE_VERTICAL, PRICE_CELL_HANDLE
} from '../../../../../../common-code/constants';
import {THandleModelType} from '../../../../../../common-code/types/THandleModelType';
import {ISaveHandleData} from '../../../../../../common-code/interfaces/saveData/ISaveHandleData';
import {IHandleData} from '../../../../../../common-code/interfaces/materials/IHandleData';
import {IHandleModelData} from '../../../../../../common-code/interfaces/materials/IHandleModelData';
import {IHandlePriceParams} from '../../../../../../common-code/interfaces/catalog/IHandlePriceParams';
import {IDetailKitPriceData} from '../../../../../../common-code/interfaces/catalog/IDetailKitPriceData';
import {IFacadeHandleData} from '../../../../../../common-code/interfaces/materials/IFacadeHandleData';
import {IOffer} from '../../../../../../common-code/interfaces/catalog/IOffer';
import {IDetailPriceData} from '../../../../../../common-code/interfaces/catalog/IDetailPriceData';
import {THandleType} from '../../../../../../common-code/types/THandleType';
import {i18n} from '../../../../../i18n';

export class ThreeHandle extends ThreeUnitDetail {
    saveData: ISaveHandleData;
    facade: ThreeFacade;
    handleData: IHandleData;
    threeModelData: IHandleModelData;
    threeModel: Group;
    priceData?: IDetailKitPriceData;

    constructor(options: ISaveHandleData, facade: ThreeFacade) {
        super(options, facade.unit);
        this.facade = facade;
        this.saveData = this.initThreeUnitSaveData(options);
        this.handleData = this.initHandleData();
        this.threeModelData = this.getThreeModelData();
        this.threeModel = new Group();
    }

    public initState(isRebuild?: boolean) {
        this.createThreeModel();
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        if (!isRebuild) {
            this.addToScene();
        }
        super.createView(isRebuild);
    }

    public setHandleData(handle?: IHandleData) {
        let handleData: ISaveHandleData | undefined;
        let facadeHandleData: IFacadeHandleData | IHandleData | undefined;
        let newHandle: IHandleData | undefined;

        facadeHandleData = handle && !handle.notPrice ? handle : this.facade.facadeData.handle;
        if (handle) {
            this.saveData.modelId = handle.id;
        }
        if (this.handleData.notPrice) {
            delete this.saveData.margin;
        }

        handleData = this.facade.calculateHandleData(this.saveData, facadeHandleData);
        if (handleData && handleData.modelId) {
            newHandle = this.service.getHandleData(handleData.modelId);
            if (newHandle) {
                delete handleData.position;
                delete handleData.rotation;
                this.saveData = handleData;
                this.handleData = newHandle;
            }
        }
    }

    public rebuild() {
        this.view3d.remove(this.threeModel);
        this.threeModelData = this.getThreeModelData();
        this.threeModel = new Group();
        this.initState(true);
        this.createView(true);
    }

    public isCalculatePrice(): boolean {
        return this.handleData.notPrice !== undefined ?
            this.handleData.notPrice : true;
    }

    public getPrice() {
        let priceData: IDetailKitPriceData | undefined;

        priceData = this.getPriceData();
        if (priceData) {
            return priceData.price;
        }
        return 0;
    }

    public setPriceData(priceData: IDetailKitPriceData) {
        this.priceData = priceData;
        debugger;
        this.service.setOrderPartsToDetailPrice(priceData, this.service.getOrderParts());
    }

    public getPriceData(): IDetailKitPriceData | undefined {
        return this.priceData;
    }

    public getOfferIds(): string[] {
        let offerIds: string[] = [];
        let offers: IOffer[];
        let offer: IOffer;

        offers = this.getOffers();
        for (offer of offers) {
            offerIds.push(offer.importOffer[this.service.getOfferExternalId()]);
        }

        return offerIds;
    }

    public getOffers(): IOffer[] {
        let offers: IOffer[] = [];
        let itemPriceData: IDetailPriceData;

        if (this.priceData && this.priceData.kit) {
            for (itemPriceData of this.priceData.kit) {
                if (itemPriceData.offer) {
                    offers.push({
                        importOffer: itemPriceData.offer,
                        price: itemPriceData.price,
                        oldPrice: itemPriceData.oldPrice,
                        stock: itemPriceData.stock,
                        active: itemPriceData.active,
                        count: itemPriceData.count,
                        part: itemPriceData.part,
                    });
                }
            }
        }

        return offers;
    }

    public isIntegration(): boolean {
        return false;
    }

    public getPriceParams(): IHandlePriceParams | undefined {
        if (!this.saveData.calculate) {
            return undefined;
        }
        debugger;
        return {
            functionalType: FURNITURE_FT_HANDLE,
            catalogCode: 'handle',
            depth: this.getDepth(),
            width: this.getWidth(),
            height: this.getHeight(),
            furnitureType: this.saveData.furnitureType || FURNITURE_TYPE_NONE,
            count: 1,
            part: this.getOrderPart(),
            unitId: this.unit.getId(),
            cell: PRICE_CELL_HANDLE,
        };
    }

    public getHandleDataOfferId(externalId: 'externalGuid' | 'vendorCode'): string | undefined {
        return this.handleData[externalId];
    }

    public setLoadModel(type: string, details: Object3D[]) {
        let detail: Object3D;
        let newDetail: Object3D;

        for (detail of details) {
            newDetail = detail.clone();
            newDetail.matrixAutoUpdate = false;
            this.threeModel.add(newDetail);
        }
        this.updateViewType();
    }

    public getWidth(): number {
        return +this.getThreeModelData().width;
    }

    public getDepth(): number {
        return +this.getThreeModelData().depth;
    }

    public getHeight(): number {
        return +this.getThreeModelData().height;
    }

    public getOrderPart(): number | undefined {
        if (!this.service.getCanOrderPart()) {
            return undefined;
        }

        return 1;
    }

    public getModelType(): THandleModelType {
        if (!this.saveData.modelType) {
            this.saveData.modelType = HANDLE_MODEL_TYPE_DEFAULT;
        }

        return this.saveData.modelType;
    }

    public getData(): ISaveHandleData {
        let data: ISaveHandleData = super.getData();
        data.modelId = this.handleData.id;

        return data;
    }

    public getType(): THandleType {
        return this.saveData.location || HANDLE_TYPE_HORIZONTAL;
    }

    public isVisible(): boolean {
        if (this.isIntegration()) {
            return this.facade.isIntegrationHandle();
        } else {
            return this.view3d.visible;
        }
    }

    protected initThreeUnitSaveData(saveData: ISaveHandleData): ISaveHandleData {
        super.initThreeUnitSaveData(saveData);

        if (!saveData.align) {
            saveData.align = {x: ALIGN_CENTER, y: ALIGN_TOP};
        }
        if (!saveData.location) {
            saveData.location = HANDLE_TYPE_VERTICAL;
        }
        if (saveData.isDimensions === undefined) {
            saveData.isDimensions = false;
        }
        if (saveData.horizontalMove === undefined) {
            saveData.horizontalMove = true;
        }
        if (!saveData.modelType) {
            saveData.modelType = HANDLE_MODEL_TYPE_DEFAULT;
        }
        if (saveData.horizontalMove && this.facade) {
            if (this.facade.getOpenType() === FACADE_OPEN_TYPE_VERTICAL_PIVOT) {
                switch (this.facade.getSideType()) {
                    case ALIGN_LEFT:
                        saveData.align.x = ALIGN_RIGHT;
                        break;
                    case ALIGN_RIGHT:
                        saveData.align.x = ALIGN_LEFT;
                        break;
                }
            }
        }

        return saveData;
    }

    protected initHandleData(): IHandleData {
        let handleData: IHandleData | undefined;

        if (this.saveData.modelId) {
            handleData = this.service.getHandleData(this.saveData.modelId);
        }
        if (!handleData) {
            console.error('Error initHandleData')
            handleData = {
                id: 'default',
                title: i18n.t('Ручка'),
                models: []
            };
        }

        return handleData;
    }

    protected getThreeModelData(): IHandleModelData {
        let modelData: IHandleModelData | undefined;

        modelData = this.service.getHandleThreeModel(this.handleData, this.getModelType());

        if (!modelData) {
            throw new Error('error-ThreeHandle-getThreeModelData');
        }

        return modelData;
    }

    protected createThreeModel() {
        this.threeModel.name = 'threeModel';
        this.threeModel.matrixAutoUpdate = false;
        this.view3d.add(this.threeModel);
        this.service.loadHandleThreeModel(this);
    }

    protected initPosition(): Vector3 {
        let position: Vector3;

        position = new Vector3(0, 0, this.facade.getDepth());
        if (this.saveData.position) {
            if (this.saveData.position.x !== undefined && !isNaN(+this.saveData.position.x)) {
                position.x = +this.saveData.position.x;
            }
            if (this.saveData.position.y !== undefined && !isNaN(+this.saveData.position.y)) {
                position.y = +this.saveData.position.y;
            }
            if (this.saveData.position.z !== undefined && !isNaN(+this.saveData.position.z)) {
                position.z = +this.saveData.position.z;
            }

            return position;
        }
        if (this.saveData.align) {
            switch (this.saveData.align.x) {
                case ALIGN_LEFT:
                    position.x = this.getType() === HANDLE_TYPE_HORIZONTAL ?
                        -this.facade.getWidth() / 2 + this.getWidth() / 2 : -this.facade.getWidth() / 2 + this.getDepth() / 2;
                    position.x += this.saveData.margin && this.saveData.margin.x ? +this.saveData.margin.x : 0;
                    break;
                case ALIGN_RIGHT:
                    position.x = this.getType() === HANDLE_TYPE_HORIZONTAL ?
                        this.facade.getWidth() / 2 - this.getWidth() / 2 : this.facade.getWidth() / 2 - this.getDepth() / 2;
                    position.x -= this.saveData.margin && this.saveData.margin.x ? +this.saveData.margin.x : 0;
                    break;
            }
            switch (this.saveData.align.y) {
                case ALIGN_BOTTOM:
                    position.y = this.getType() === HANDLE_TYPE_HORIZONTAL ?
                        -this.facade.getHeight() / 2 + this.getHeight() / 2 : -this.facade.getHeight() / 2 + this.getWidth() / 2;
                    position.y += this.saveData.margin && this.saveData.margin.y ? +this.saveData.margin.y : 0;
                    break;
                case ALIGN_TOP:
                    position.y = this.getType() === HANDLE_TYPE_HORIZONTAL ?
                        this.facade.getHeight() / 2 - this.getHeight() / 2 : this.facade.getHeight() / 2 - this.getWidth() / 2;
                    position.y -= this.saveData.margin && this.saveData.margin.y ? +this.saveData.margin.y : 0;
                    break;
            }
            position.z = this.facade.getDepth();
            position.z += this.saveData.margin && this.saveData.margin.z ? +this.saveData.margin.z : 0;
        }
        if (this.facade.isFlipY()) {
            position.x = -position.x;
            position.y = -position.y;
        }

        return position;
    }

    protected initRotation(): Euler {
        let rotation: Euler;

        rotation = new Euler();
        if (this.saveData.rotation) {
            rotation.x = this.saveData.rotation.x ? +this.saveData.rotation.x : 0;
            rotation.y = this.saveData.rotation.y ? +this.saveData.rotation.y : 0;
            rotation.z = this.saveData.rotation.z ? +this.saveData.rotation.z : 0;

            return rotation;
        }
        if (this.saveData.align) {
            switch (this.saveData.align.y) {
                case ALIGN_BOTTOM:
                    rotation.z = this.getType() === HANDLE_TYPE_HORIZONTAL ? Math.PI : -Math.PI;
                    if (this.facade.isFlipY()) {
                        rotation.z += Math.PI;
                    }
                    break;
            }
            switch (this.saveData.align.x) {
                case ALIGN_LEFT:
                    rotation.z += this.getType() === HANDLE_TYPE_VERTICAL ? -Math.PI * 0.5 : 0;
                    break;
                case ALIGN_RIGHT:
                    rotation.z += this.getType() === HANDLE_TYPE_VERTICAL ? Math.PI * 0.5 : 0;
                    break;
                default:
                    rotation.z += this.getType() === HANDLE_TYPE_VERTICAL ? -Math.PI * 0.5 : 0;
                    break;
            }
        }

        return rotation;
    }

    protected addToScene() {
        this.view3d.name = 'ThreeHandle';
        this.unit.view3d.add(this.view3d);
    }
}