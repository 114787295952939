import React, {useEffect, useState} from 'react';
import {IClassNameProps} from "@bem-react/core";
import {useTranslation} from 'react-i18next';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {cnWizardSpec} from '../WizardSpec';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../redux/AppStore';
import {TKitchenImages} from '../../../../../common-code/types/TKitchenImages';
import {WizardSpecSnapshot} from '../Snapshot/WizardSpec-Snapshot';
import {Button} from '../../../components/Button';
import {TKitchenCuttingImages} from '../../../../3d/types/TKitchenCuttingImages';
import {WizardSpecImage} from '../Image/WizardSpec-Image';

export interface IWizardSpecImagesProps extends IClassNameProps {
    service: KitchenService;
}

export const WizardSpecImages: React.FC<IWizardSpecImagesProps> =
    ({
         service,
     }) => {
        const loadedTextures: number = useSelector((state: AppState) => state.wizard.loadedTextures);
        const [images, setImages] = useState<TKitchenImages | undefined>(undefined);
        const [cuttingImages, setCuttingImages] = useState<TKitchenCuttingImages | undefined>(undefined);
        const {t} = useTranslation();

        useEffect(() => {
            if (loadedTextures > 0) {
                service.createImages().then((images) => {
                    setImages(images);
                })
            }
        }, [service, loadedTextures]);

        useEffect(() => {
            if (loadedTextures > 0) {
                service.createCuttingImages().then((cuttingImages) => {
                    setCuttingImages(cuttingImages);
                })
            }
        }, [service, loadedTextures]);

        const onAddAspect = () => {

        }

        return (
            <div className={cnWizardSpec('Images')}>

            <div className={cnWizardSpec('Snapshots')}>
                {!images || <>
                    <WizardSpecSnapshot images={images} />
                    <div className={cnWizardSpec('AddSnapshot')}>
                        <Button
                            className={cnWizardSpec('Button')}
                            leftIcon={{path: 'plus', width: 20, height: 20, marginRight: 8}}
                            text={t('Добавить ракурс') || undefined}
                            callBack={onAddAspect}
                            mods={{
                                mods: {
                                    color: 'black',
                                    bkg: 'white',
                                    border: 'gray',
                                    fill: 'lightBlack'
                                },
                            }}
                            type={'button'}
                        />
                    </div>
                </>}
            </div>
            <div className={cnWizardSpec('Cutting')}>
                {!cuttingImages || <>
                    <div className={cnWizardSpec('TabletopCutting')}>
                        <WizardSpecImage image={cuttingImages.tabletop.image} type={'tabletop'}/>
                        <div className={cnWizardSpec('CuttingInfo')}>
                            {cuttingImages.tabletop.cuttingData.map(data => {
                                return (
                                    <div className={cnWizardSpec('CuttingItem')} key={data.index}>
                                        <span className={cnWizardSpec('CuttingIndex')}>№{data.index}:</span>
                                        <span className={cnWizardSpec('CuttingLength')}>{t('длина')} - {data.sizes.length} {t('мм')};</span>
                                        <span className={cnWizardSpec('CuttingHeight')}>{t('ширина')} - {data.sizes.width} {t('мм')};</span>
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                    <div className={cnWizardSpec('ApronCutting')}>
                        <WizardSpecImage image={cuttingImages.apron.image} type={'apron'}/>
                        <div className={cnWizardSpec('CuttingInfo')}>
                            {cuttingImages.apron.cuttingData.map(data => {
                                return (
                                    <div className={cnWizardSpec('CuttingItem')} key={data.index}>
                                        <span className={cnWizardSpec('CuttingIndex')}>№{data.index}:</span>
                                        <span className={cnWizardSpec('CuttingLength')}>{t('длина')} - {data.sizes.length} {t('мм')};</span>
                                        <span className={cnWizardSpec('CuttingHeight')}>{t('ширина')} - {data.sizes.width} {t('мм')};</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>}

            </div>
            </div>
        );
    };