import { ISaveBoxData } from "common-code/lib/interfaces/saveData/ISaveBoxData";

export interface INewBox  {
    length: string;
    height: string;
    depth: string;
    initPosition: boolean;
    initPositionX?: string | number;
    initPositionY?: string | number;
    thickness: string;
    bottomThickness: string;
    railGap: string;
    frontSide: boolean;
    sizes?: boolean;
    sizeLength?: string;
    sizeWidth?: string;
    sizeHeight?: string;
    error: string;
    modal: boolean
} 

export const initialBox = (box: ISaveBoxData | undefined) => {
    return box ? {
        length: box.length.replace("%", ""),
        height: box.height,
        depth: box.depth.replace("%", ""),
        initPosition: !!box?.initPosition?.y || !!box?.initPosition?.x,
        initPositionX: box.initPosition?.x || '0',
        initPositionY: box.initPosition?.y || '0',
        thickness: box.thickness.toString(),
        bottomThickness: box.bottomThickness.toString(),
        railGap: box.railGap.toString(),
        frontSide: box.frontSide,
        sizes: !!box?.sizes?.length || !!box?.sizes?.width || !!box?.sizes?.height,
        sizeLength: box?.sizes?.length.toString() || "0",
        sizeWidth: box?.sizes?.width.toString() || "0",
        sizeHeight: box?.sizes?.height.toString() || "0",
        error: '',
        modal: false
    }
    :
    {
    length: '100',
    height: '50',
    depth: '90',
    initPosition: false,
    initPositionX: '0',
    initPositionY: '0',
    thickness: "16",
    bottomThickness: "5",
    railGap: "10",
    frontSide: false,
    sizes: false,
    sizeLength: "0",
    sizeWidth: "0",
    sizeHeight: "0",
    error: '',
    modal: false
}
}
