import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {
    ACTION_COPY,
    PLANE_TYPE_FLOOR,
    PLANE_TYPE_HORIZONTAL,
    PLANE_TYPE_VERTICAL,
    PLANE_TYPE_WALL
} from '../../../../../constants';
import {i18n} from '../../../../../i18n';
import {TPlaneType} from '../../../../types/TPlaneType';
import {Vector3} from 'three';
import {TSelectItem} from '../../../../../../common-code/types/TSelectItem';
import {
    OPTION_TYPE_NUMBER, OPTION_TYPE_RADIOBUTTON,
    OPTION_TYPE_RANGE,
    OPTION_TYPE_SELECT,
    OPTION_TYPE_TEXT
} from '../../../../../../common-code/constants';
import {CommonHelper} from 'common-code';
import {TSizes} from '../../../../../../common-code/types/geometry/TSizes';
import {IContextIcon} from '../../../../../interfaces/IContextIcon';
import {IOptionGroup} from '../../../../../../common-code/interfaces/option/IOptionGroup';
import {ISettingGroupGeneral} from '../../../../../interfaces/settingData/ISettingGroupGeneral';
import {IOption} from '../../../../../../common-code/interfaces/option/IOption';
import {IOptionRadioButton} from '../../../../../../common-code/interfaces/option/IOptionRadioButton';
import {IOptionSelect} from '../../../../../../common-code/interfaces/option/IOptionSelect';

export class ThreeAccessoryUnit extends ThreeUnit {
    public getCorpusSizes(): TSizes {
        return {
            length: this.getWidth(),
            height: this.getHeight(),
            width: this.getDepth()
        };
    }

    public getContextIcons(): IContextIcon[] {
        let icons: IContextIcon[];
        let actionData = this.actionData();

        icons = [
            {
                channelName: 'ThreeKUnit',
                action: ACTION_COPY,
                actionData: actionData,
                popup: false,
                icon: 'newCopy',
                hide: true,
                title: i18n.t('Копировать'),
                sort: 0
            }
        ];
        icons = icons.concat(super.getContextIcons());

        return icons;
    }

    public getAvailableHeights(): number[] | undefined {
        throw new Error('replace getAvailableHeights');
    }

    protected getIntersectsTypes(): TPlaneType[] {
        return [
            PLANE_TYPE_WALL,
            PLANE_TYPE_VERTICAL,
            PLANE_TYPE_FLOOR,
            PLANE_TYPE_HORIZONTAL,
        ];
    }

    protected correctMovePosition(movePosition: Vector3) {
        this.correctTopUnitMovePosition(movePosition);
    }

    protected addSizesOptions(sizesGroup: IOptionGroup, settingGeneralGroupData: ISettingGroupGeneral) {
        let index: string;
        let optionItem: IOption | undefined;
        let optionListType: IOptionRadioButton | IOptionSelect;
        let listItem: TSelectItem;
        let firstActiveListItem: TSelectItem | undefined;
        let isSetNewValue: boolean;
        let availableHeights: number[] | undefined;

        availableHeights = this.getAvailableHeights();
        for (index in sizesGroup.options) {
            optionItem = undefined;
            switch (sizesGroup.options[index].type) {
                case OPTION_TYPE_NUMBER:
                case OPTION_TYPE_RANGE:
                    optionItem = CommonHelper.deepCopy(sizesGroup.options[index]);
                    if (optionItem) {
                        optionItem.value = optionItem.defaultValue = this.getSizeItemValue(optionItem.id);
                    }
                    break;
                case OPTION_TYPE_TEXT:
                    optionItem = CommonHelper.deepCopy(sizesGroup.options[index]);
                    if (optionItem) {
                        optionItem.value = optionItem.defaultValue = '' + this.getSizeItemValue(optionItem.id);
                    }
                    break;
                case OPTION_TYPE_SELECT:
                case OPTION_TYPE_RADIOBUTTON:
                    optionItem = CommonHelper.deepCopy(sizesGroup.options[index]);
                    if (optionItem) {
                        optionItem.value = optionItem.defaultValue = '' + this.getSizeItemValue(optionItem.id);
                    }
                    break;
            }
            if (optionItem) {
                if (optionItem.id === 'height' && 'items' in optionItem) {
                    optionListType = optionItem as IOptionRadioButton | IOptionSelect;
                    isSetNewValue = false;
                    for (listItem of optionListType.items) {
                        listItem.disabled = (availableHeights !== undefined && !availableHeights.includes(+listItem.id));
                        if (!listItem.disabled && !firstActiveListItem) {
                            firstActiveListItem = listItem;
                        }
                        if (listItem.id === optionListType.value && listItem.disabled) {
                            isSetNewValue = true;
                        }
                    }
                    if (isSetNewValue && firstActiveListItem) {
                        optionListType.defaultValue = optionListType.value = firstActiveListItem.id;
                    }
                }
                settingGeneralGroupData.sizes.push(optionItem);
            }
        }
    }
}