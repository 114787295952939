import {IClassNameProps} from '@bem-react/core';
import React, {ChangeEvent, useState} from 'react';
import './AccessoryList.css';
import {ObjectSelectItem} from '../../components/ObjectSelectItem/ObjectSelectItem';
import {cn} from '@bem-react/classname';
import {IMaterialData} from '../../../../common-code/interfaces/materials/IMaterialData';
import {Textinput} from '@yandex/ui/Textinput/desktop/bundle';
import {Button} from '../../components/Button';
import {useTranslation} from 'react-i18next';
import {searchInMaterialData} from '../../../helpers/searchInMaterialData';
import {IFacadeMaterialData} from '../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {isMaterialDisable} from '../../../helpers';

export const cnAccessoryList = cn('AccessoryList');

export interface IAccessoryListProps extends IClassNameProps {
    listMaterialData?: IMaterialData[];
    materialData: IMaterialData;
    onSelectObject: (objectData: IMaterialData) => void;
    onChangeDepth?: (material: IMaterialData, value: number) => void;
    title?: string;
    showObject?: boolean;
    facadeMaterials?: IFacadeMaterialData[];
    setShowModal?: (value: boolean) => void;
}

export const AccessoryList: React.FC<IAccessoryListProps> =
    ({
         className,
         listMaterialData,
         materialData,
         onSelectObject,
         showObject,
         title,
         onChangeDepth,
         facadeMaterials,
         setShowModal
     }) => {
        const {t} = useTranslation();
        const [searchText, setSearchText] = useState<string>('');
        const filterList: IMaterialData[] | undefined = listMaterialData?.filter(materialData => searchInMaterialData(materialData, searchText));
        const onSelectObjectList = (data: IMaterialData) => {
            if(materialData.id !== data.id && materialData.title !== data.title){                
                onSelectObject && onSelectObject(data);            
            }
            setShowModal && setShowModal(false);
        }

        const onChangeDepthList = (material: IMaterialData, value: number) => {
            onChangeDepth && onChangeDepth(material, value);
            setShowModal && setShowModal(false);
        }

        return (
            <div className={cnAccessoryList({}, [className])}>
                <div className={cnAccessoryList('Header')}>
                    {title ? <div className={cnAccessoryList('Title')}>{title}</div> : null}
                    <div className={cnAccessoryList('Search')}>
                        <Textinput
                            className={cnAccessoryList('SearchText')}
                            placeholder={t('Поиск по назв., коду, описанию')}
                            value={searchText}
                            minLength={3}
                            debounceTimeout={300}
                            onChange={(value: ChangeEvent<HTMLInputElement>) => {
                                setSearchText(value.target.value);
                            }}
                        />
                        <Button className={cnAccessoryList('SearchButton')}
                                type={'button'} mods={{}}
                                leftIcon={{path: 'search2', width: 20, height: 20, fill: 'lightBlack'}}
                                callBack={() => {
                                }}/>
                    </div>
                </div>
                <div className={cnAccessoryList('Wrapper')}>
                    {!filterList || filterList.length <= 0 ?
                        <div className={cnAccessoryList('Empty')}>{t('Не найдено ни одного объекта')}</div> :
                        filterList.map((material) => {
                            if (isMaterialDisable(material, facadeMaterials)) {
                                return null;
                            }
                            return (
                                <ObjectSelectItem
                                    key={material.id}
                                    className={cnAccessoryList('Item')}
                                    title={material.title}
                                    image={material.image}
                                    color={material.color}
                                    active={material.id === materialData.id}
                                    disabled={!showObject}
                                    detailData={material.id === materialData.id ? materialData : material}
                                    callBack={() => {
                                        onSelectObjectList(material);
                                    }}
                                    onChangeDepth={onChangeDepthList}
                                />
                            );
                        })
                    }
                    {}
                </div>
            </div>
        );
    }