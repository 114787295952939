import React from 'react';
import { cnFormMaterial, INewMaterialData } from '../FormMaterial';
import Trash from '../../icons/Trash';
import Edit from '../../icons/Edit';
import ModalTexture from './ModalTexture';
import { INewTexture, INewTextureData } from './Textures';

interface ITextureNewProps {
  texture: INewTextureData;
  index: number;
  setNewMaterial: (value: INewMaterialData) => void;
  newMaterial: INewMaterialData;
  createTextureNew: (value: INewTexture) => INewTextureData;
  validateTexture: (value: INewTexture) => string;
}

function TextureNew({
    texture, 
    index, 
    setNewMaterial, 
    newMaterial,
    createTextureNew,
    validateTexture
  } : ITextureNewProps) {

    const deleteTexture = () =>setNewMaterial({...newMaterial, texturesNew: newMaterial?.texturesNew?.filter((el:INewTextureData, i: number) => i !== index)});

    const initionalTexture: INewTexture  = {

      path: "",
      type: texture.type,
      repeatX: texture.repeat.x.toString(),
      repeatY: texture.repeat.y.toString(),
      file: "",

      fileNew: texture.fileNew,

      offset: !!texture.offset?.x || !!texture.offset?.y,
      offsetX: texture.offset?.x ? texture.offset?.x.toString() : "0",
      offsetY: texture.offset?.y ? texture.offset?.y.toString() : "0",

      sizes: !!texture.sizes?.width || !!texture.sizes?.height,
      sizesWidth: texture.sizes?.width ? texture.sizes?.width.toString() : "0",
      sizesHeight: texture.sizes?.height ? texture.sizes?.height.toString() : "0",

      visible: false,
      error: "",
      url: ''
    }

  const [newTexture, setNewTexture] = React.useState (initionalTexture);
  const handleToggleModal = () => setNewTexture({ ...newTexture, visible: !newTexture.visible });

  const handleSubmit = () => {
    const error = validateTexture(newTexture);
    if (error) {
      setNewTexture({ ...newTexture, error});
        return;
    }

    const textureNew = createTextureNew(newTexture);
    setNewMaterial({ ...newMaterial, texturesNew: newMaterial.texturesNew?.map((el:INewTextureData, i: number) => i === index ? textureNew : el)});
    setNewTexture(initionalTexture);
  }

  return (
    <div className={cnFormMaterial('Children')} >
        <span>{texture.fileNew.name}</span> 
        <img width={50} height={50}  src={ URL.createObjectURL(texture.fileNew)} alt="..." />
        <div className={cnFormMaterial('Box-Btn')}>
            <Edit handleToggleModal={handleToggleModal} />
            <Trash handleDeleteValue={deleteTexture} /> 
        </div>
        
        <ModalTexture 
            handleToggleModal={handleToggleModal}
            setNewTexture={setNewTexture}
            newTexture={newTexture}
            handleSubmit={handleSubmit}
            keyValue="editTexture"
        />

    </div>
  )
}

export default TextureNew
