import React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from "./app/main/App/App";
import reportWebVitals from './reportWebVitals';
import {CustomSprite} from "./app/ui/helpers/CustomSprite/CustomSprite";
import {Provider as ReduxProvider} from 'react-redux';
import {appStore} from "./app/redux/AppStore";
import 'normalize.css';
import './app/themes/default/root.css';
import './app/i18n';

const root = ReactDOM.createRoot(document.querySelector('.App') as HTMLElement);
root.render(
    <React.StrictMode>
        <CustomSprite/>
        <ReduxProvider store={appStore}>
            <App/>
        </ReduxProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
