import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const cnDeliveryObjectSelectSelf = cn('DeliveryObjectSelectSelf')

export interface IDeliveryObjectSelectSelfProps extends IClassNameProps {
}

export const DeliveryObjectSelectSelf: React.FC<IDeliveryObjectSelectSelfProps> = ({className}) => {
    const {t} = useTranslation();
    return (
        <div className={cnDeliveryObjectSelectSelf({}, [className])}>
            {t('Доставка товара со склада осуществляется силами заказчика')}
        </div>
    );
}