import {withBemMod} from "@bem-react/core";
import React, {ChangeEvent, useState} from "react";
import {IInputProps} from "../Input";
import {cnInput} from "../index";
import {INPUT_TYPE_TEXT} from "../../../constants";

export interface IInputTextProps<HTMLInputElement> extends IInputProps<HTMLInputElement> {
    value?: string;
    name?: string;
    placeholder?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

const InputText: React.FC<IInputTextProps<HTMLInputElement>> =
    ({
         className,
         onChange,
         value,
         name,
         placeholder,
         disabled,
         maxLength
    }) => {
    const [focus, setFocus] = useState<boolean>(false);

    return (
        <input
            className={cnInput({focus: focus}, [className])}
            type={'text'}
            value={value}
            maxLength={maxLength}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={() => {setFocus(true)}}
            onBlur={() => {setFocus(false)}}
            onChange={onChange}
        />
    );
};

export const withInputText = withBemMod<IInputTextProps<HTMLInputElement>>(
    cnInput(),
    { type: INPUT_TYPE_TEXT },
    () => InputText
);