import React from 'react';
import {cnSettingsMenu, ISettingsMenuGroupProps} from '../SettingsMenu';
import {CommonObjectHelper} from '../../../../3d/helpers/CommonObjectHelper/CommonObjectHelper';
import {ISettingGroupCorpus} from '../../../../interfaces/settingData/ISettingGroupCorpus';
import {ObjectOption} from '../../../components/ObjectOption/ObjectOption';

export interface ISettingsMenuCorpusGroupProps extends ISettingsMenuGroupProps {
    corpusData: ISettingGroupCorpus;
}

export const SettingsMenuCorpus: React.FC<ISettingsMenuCorpusGroupProps> =
    ({className, onRebuildData, corpusData}) => {

        const handleSizesChange = (fieldId: string, value: any) => {
            let changeFieldId: string | undefined;
            switch (fieldId) {
                case 'width':
                    changeFieldId = 'length';
                    break;
                case 'height':
                    changeFieldId = 'height';
                    break;
                case 'depth':
                    changeFieldId = 'width';
                    break;

            }
            if (changeFieldId && !isNaN(+value)) {
                onRebuildData('rebuildCorpusSizes', {name: changeFieldId, value: +value})
            } else {
                console.error('handleSizesChange', changeFieldId, value);
            }
        };

        return (
            <div className={cnSettingsMenu('Corpus', [className])}>
                {corpusData.sizes.map((option) => {
                    return (
                        <ObjectOption
                            option={CommonObjectHelper.prepareCreateOption(
                                option,
                                corpusData.disableSelects ? corpusData.disableSelects['corpus_' + option.id] : undefined)}
                            key={option.id}
                            type={option.type}
                            onChange={handleSizesChange}
                            createOptions={{}}
                        />
                    );
                })}
            </div>
        );
    }