import {ThreeConstructive} from '../ThreeConstructive/ThreeConstructive';
import {ThreeRoom} from '../../rooms/ThreeRoom/ThreeRoom';
import {BoxGeometry, DoubleSide, Mesh, MeshStandardMaterial, Vector3} from 'three';
import {IMaterialTextures} from '../../../../interfaces/IMaterialTextures';
import {SIDE_TYPE_BOTTOM} from '../../../../../../common-code/constants';
import {IMaterialData} from '../../../../../../common-code/interfaces/materials/IMaterialData';
import {ISavePillarData} from '../../../../../../common-code/interfaces/saveData/ISavePillarData';

export class ThreePillar extends ThreeConstructive {
    body: Mesh;
    material?: MeshStandardMaterial;
    materialData: IMaterialData;
    materialTextures: IMaterialTextures;

    constructor(options: ISavePillarData, room: ThreeRoom) {
        super(options, room);
        this.body = new Mesh();
        this.materialData = this.initMaterialData(options.materialId);
        this.materialTextures = this.loadTextures();
    }

    public createView(isRebuild?: boolean) {
        this.createBody();
        this.calculateGlobalFrontVector();
        super.createView(isRebuild);
    }

    public defaultYPosition(): number {
        return this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    protected setIsDimensions() {
        this.saveData.isDimensions = this.saveData.isDimensions ?? false;
    }

    protected setIsStick() {
        this.saveData.isStick = this.saveData.isStick ?? false;
    }

    protected setIsLevelStick() {
        this.saveData.isLevelStick = this.saveData.isLevelStick ?? false;
    }

    protected setIsWallStick() {
        this.saveData.isWallStick = this.saveData.isWallStick ?? false;
    }

    protected initMaterialData(materialId?: string): IMaterialData {
        return this.service.getWallMaterial(materialId);
    }

    protected loadTextures(): IMaterialTextures {
        return this.service.loadMaterialTextures(this.materialData.id, this.materialData.textures);
    }

    protected initPosition(): Vector3 {
        let initPosition: Vector3;

        initPosition = super.initPosition();
        if (initPosition.y === 0) {
            initPosition.y = this.defaultYPosition();
        }

        return initPosition;
    }

    protected createBody() {
        this.body = new Mesh(new BoxGeometry(this.getWidth(), this.getHeight(), this.getDepth()), this.getBodyMaterial())
        this.body.name = 'pillarBody';
        this.body.castShadow = true;
        this.addCoverPoints([
            new Vector3(-this.getWidth()/2, -this.getHeight()/2, -this.getDepth()/2),
            new Vector3(this.getWidth()/2, this.getHeight()/2, this.getDepth()/2),
        ]);
        this.view3d.add(this.body);
    }

    protected getBodyMaterial(): MeshStandardMaterial {
        if (!this.material) {
            this.material = new MeshStandardMaterial({
                    side: DoubleSide,
                    color: this.materialData.color || '#fffce6',
                    emissive: '#ffffff',
                    emissiveIntensity: 0.03,
                    map: this.materialTextures.texture || null,
                    normalMap: this.materialTextures.normal || null,
                    roughnessMap: this.materialTextures.roughness || null,
                }
            );        }

        return this.material;
    }
}