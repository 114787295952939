import React, {useState} from 'react';
import {IObjectSelectProps} from '../ObjectSelect';
import {IClassNameProps, withBemMod} from '@bem-react/core';
import {cnObjectSelect} from '../index';
import {useTranslation} from "react-i18next";
import {ICornerData} from '../../../../../common-code/interfaces/materials/ICornerData';
import {ObjectSelectSelect} from '../Select/ObjectSelect-Select';
import {OBJECT_SELECT_TYPE_CORNER} from '../../../../constants';
import {Modal} from '../../Modal';
import {AccessoryPlankList, cnAccessoryPlankList} from '../../../elements/AccessoryPlankList/AccessoryPlankList';

const ObjectSelectCorner: React.FC<IObjectSelectProps<ICornerData>> =
    ({
         data,
         list,
         title,
         onSelectObject,
         showObject,
         className,
        facadeMaterials,
     }) => {
        const {t} = useTranslation();

        const emptyObjectData: ICornerData = {
            id: 'none',
            title: t('Не выбрано'),
            materialType: 'none',
            height: 0,
            width: 0,
            length: 0,
            image: ''
        };
        const objectData: ICornerData = data && data.object ? data.object : emptyObjectData;
        const [showModal, setShowModal] = useState<boolean>(false);

        return (
            <div className={cnObjectSelect({}, [className])}>
                <ObjectSelectSelect
                    className={cnObjectSelect('Select')}
                    image={showObject && objectData.image ? {
                        path: objectData.image,
                        label: objectData.title,
                        width: 60,
                        height: 60
                    } : undefined}
                    color={showObject && !objectData.image && objectData.color ? {
                        path: objectData.color,
                        label: objectData.title,
                        width: 60,
                        height: 60
                    } : undefined}
                    icon={!showObject || (!objectData.image && !objectData.color) ? {
                        path: 'no-photo',
                        label: objectData.title,
                        width: 60,
                        height: 60
                    } : undefined}
                    title={title}
                    description={showObject ? objectData.title : t('Не выбрано') || undefined}
                    callBack={() => {
                        setShowModal(true);
                    }}
                />
                {showModal ?
                    <Modal
                        className={cnObjectSelect('Modal')}
                        visible={showModal}
                        callBack={setShowModal}
                        size={'full'}
                    >
                        {!list || !onSelectObject ?
                            <div className={cnObjectSelect('Error')}>{t('Загрузка данных')}...</div> :
                            <AccessoryPlankList
                                className={cnObjectSelect(cnAccessoryPlankList())}
                                listMaterialData={list}
                                materialData={objectData}
                                onSelectObject={onSelectObject}
                                showObject={showObject}
                                title={title}
                                facadeMaterials={facadeMaterials}
                                setShowModal={setShowModal}
                            />
                        }
                    </Modal> : null
                }
            </div>
        );
    }

export const withObjectSelectCorner = withBemMod<IObjectSelectProps<ICornerData>, IClassNameProps>(
    cnObjectSelect(),
    {type: OBJECT_SELECT_TYPE_CORNER},
    () => ObjectSelectCorner
);