import React, {ChangeEvent, useState} from 'react';
import {withBemMod} from '@bem-react/core';
import {applySendOrderForm, cnWizardOrderForm, IWizardOrderFormProps} from '../index';
import {
    DELIVERY_TYPE_SELF,
    ORDER_RESULT_ACCESS_DENIED,
    ORDER_RESULT_CREATE_CUSTOMER_ERROR,
    ORDER_RESULT_CREATE_ERROR,
    ORDER_RESULT_NEED_COPY,
    ORDER_RESULT_SUCCESS,
    ORDER_RESULT_UNKNOWN_ERROR,
    ORDER_USER_TYPE_CHOICE,
    ORDER_USER_TYPE_NEW,
    ROLE_MANAGER
} from '../../../../../common-code/constants';
import {Svg} from '../../../components/Svg';
import {useTranslation} from 'react-i18next';
import {RadioButton} from '../../../components/RadioButton/RadioButton';
import {UserObjectSelect} from '../../UserObjectSelect/UserObjectSelect';
import {DeliveryObjectSelect} from '../../DeliveryObjectSelect/DeliveryObjectSelect';
import {cnTextarea, Textarea} from '@yandex/ui/Textarea/Textarea@desktop';
import {Checkbox, cnCheckbox} from '../../../components/Checkbox';
import {Button, cnButton} from '../../../components/Button';
import {IProjectUserData} from '../../../../../common-code/interfaces/project/IProjectUserData';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../../redux/AppStore';
import {getProjectUserData} from '../../../../helpers';
import {IProjectDeliveryData} from '../../../../../common-code/interfaces/project/IProjectDeliveryData';
import {IProjectData} from '../../../../../common-code/interfaces/project/IProjectData';
import {IProjectManagerOrderFormData} from '../../../../../common-code/interfaces/project/IProjectManagerOrderFormData';
import {IUserData} from '../../../../../common-code/interfaces/IUserData';
import {TProjectUserType} from '../../../../../common-code/types/TProjectUserType';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {TLocationData} from '../../../../../common-code/types/TLocationData';
import {IProjectOrderResult} from '../../../../../common-code/interfaces/project/IProjectOrderResult';
import {ADD_MESSAGE, MESSAGE_TYPE_ERROR} from '../../../../constants';
import {TMessage} from '../../../../types/TMessage';

export const WizardOrderFormUserManager: React.FC<IWizardOrderFormProps> =
    ({
         className,
         service
     }) => {
        const {t} = useTranslation();
        const [formErrors, setFormErrors] = useState<any>({});
        const formFields: any = {
            orderType: {
                options: [{id: 'order', title: t('В заказ')}, {id: 'preorder', title: t('В наработку')}],
                defaultValue: 'order'
            },
            payment: {
                options: [{id: '1', title: 'Оплата в магазине'}, {id: '2', title: 'Картой онлайн'}, {
                    id: '3',
                    title: 'В рассрочку'
                }],
                defaultValue: '1'
            }
        };
        const [orderType, setOrderType] = useState<string>(formFields.orderType.defaultValue);
        const [payment, setPayment] = useState<string>(formFields.payment.defaultValue);
        const [userComment, setUserComment] = useState<string>('');
        const [checkStore, setCheckStore] = useState<boolean>(true);
        const projectData: IProjectData = useSelector((state: AppState) => state.wizard.project);
        const currentUser: IUserData = useSelector((state: AppState) => state.user);
        const [customerType, setCustomerType] =
            useState<TProjectUserType>(ORDER_USER_TYPE_CHOICE);
        const [userData, setUserData] =
            useState<IProjectUserData>(getProjectUserData(projectData));
        const [newUserData, setNewUserData] =
            useState<IProjectUserData>({type: ORDER_USER_TYPE_NEW});
        const [deliveryData, setDeliveryData] = useState<IProjectDeliveryData>({
            type: DELIVERY_TYPE_SELF
        })
        const currentLocation: TLocationData | undefined = useSelector((state: AppState) => state.location.current);
        const dispatch = useDispatch();
        const [projectOrderResult, setProjectOrderResult] =
            useState<IProjectOrderResult | undefined>(undefined);

        const trySendOrderForm = () => {
            if (!service) {
                return;
            }
            let newFormErrors: any = {};
            applySendOrderForm(
                service,
                getFormData(service),
                dispatch,
                t,
                newFormErrors
            ).then((result: IProjectOrderResult) => {
                afterSendOrderForm(result);
                setFormErrors(newFormErrors);
            }).catch(() => {
                setFormErrors(newFormErrors);
            });
        }
        const afterSendOrderForm = (result: IProjectOrderResult) => {
            setProjectOrderResult(result);
            switch (result.statusCode) {
                case ORDER_RESULT_SUCCESS:
                    break;
                case ORDER_RESULT_UNKNOWN_ERROR:
                case ORDER_RESULT_ACCESS_DENIED:
                case ORDER_RESULT_CREATE_CUSTOMER_ERROR:
                case ORDER_RESULT_CREATE_ERROR:
                case ORDER_RESULT_NEED_COPY:
                    dispatch({
                        type: ADD_MESSAGE,
                        payload: {
                            type: MESSAGE_TYPE_ERROR,
                            message: result.message
                        } as TMessage
                    });
                    break;
            }
        }
        const getFormData = (service: KitchenService): IProjectManagerOrderFormData => {
            return {
                user: currentUser,
                orderType: orderType,
                comment: userComment,
                customer: customerType === ORDER_USER_TYPE_NEW ? {
                    type: ORDER_USER_TYPE_NEW,
                    data: {
                        userName: userData.firstName || '',
                        userLastName: userData.lastName || '',
                        userPhone: userData.phone || ''
                    }
                } : {
                    type: ORDER_USER_TYPE_CHOICE,
                    data: {
                        id: userData.id || 0
                    }
                },
                payment: payment,
                delivery: {
                    type: deliveryData.type,
                    data: {
                        address: deliveryData.address || '',
                        floorNumber: deliveryData.floorNumber || 0,
                        isFloor: deliveryData.isFloor || false,
                        locationId: currentLocation ? currentLocation.id : 0,
                        locationName: currentLocation ? currentLocation.title : '',
                        serviceLift: deliveryData.serviceLift || false,
                        suggestionData: deliveryData.suggestionData
                    }
                },
                checkStore: checkStore,
                items: service.getProjectFormDataItems(),
                checkPersonal: true,
            };
        }

        const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
            switch (event.target.name) {
                case 'userComment':
                    setUserComment(event.target.value);
                    break;
            }
        }
        if (projectOrderResult && projectOrderResult.order && projectOrderResult.statusCode === ORDER_RESULT_SUCCESS) {
            return (<div className={cnWizardOrderForm({}, [className])}>
                <div className={cnWizardOrderForm('Order', {success: true})}>
                    {t('Заказ успешно оформлен')}. {t('Номер заказа')}: {projectOrderResult.order.id}
                </div>
            </div>);
        }

        return (
            <div className={cnWizardOrderForm({}, [className])}>
                <div className={cnWizardOrderForm('FiledGroup')}>
                    <div className={cnWizardOrderForm('Field', {full: true})}>
                        <div className={cnWizardOrderForm('FieldHeader')}>
                            <Svg icon={'settings'} className={cnWizardOrderForm('FieldIcon')}/>
                            <div className={cnWizardOrderForm('FieldTitle')}>{t('Оформление заказа в 1С')}</div>
                        </div>
                        <RadioButton
                            className={cnWizardOrderForm('RadioButton')}
                            name={'orderType'}
                            options={formFields.orderType.options}
                            value={orderType}
                            callBack={(value: string) => {
                                setOrderType(value)
                            }}
                        />
                    </div>
                    <div className={cnWizardOrderForm('Field', {full: true})}>
                        <div className={cnWizardOrderForm('FieldHeader')}>
                            <Svg icon={'man'} className={cnWizardOrderForm('FieldIcon')}/>
                            <div className={cnWizardOrderForm('FieldTitle')}>{t('Покупатель')}</div>
                        </div>
                        <UserObjectSelect userData={userData}
                                          customerType={customerType}
                                          setCustomerType={setCustomerType}
                                          newUserData={newUserData}
                                          setUserData={setUserData}
                                          setNewUserData={setNewUserData}
                                          className={cnWizardOrderForm('Customer')}
                                          errors={formErrors.customer}
                        />
                    </div>
                </div>
                <div className={cnWizardOrderForm('FiledGroup')}>
                    <div className={cnWizardOrderForm('Field', {full: true})}>
                        <div className={cnWizardOrderForm('FieldHeader')}>
                            <Svg icon={'ruble'} className={cnWizardOrderForm('FieldIcon')}/>
                            <div className={cnWizardOrderForm('FieldTitle')}>{t('Способ оплаты')}</div>
                        </div>
                        <RadioButton
                            className={cnWizardOrderForm('RadioButton')}
                            name={'payment'}
                            options={formFields.payment.options}
                            value={payment}
                            callBack={(value: string) => {
                                setPayment(value)
                            }}
                        />
                    </div>
                    <div className={cnWizardOrderForm('Field', {full: true})}>
                        <div className={cnWizardOrderForm('FieldHeader')}>
                            <Svg icon={'delivery'} className={cnWizardOrderForm('FieldIcon')}/>
                            <div className={cnWizardOrderForm('FieldTitle')}>{t('Способ доставки')}</div>
                        </div>
                        <DeliveryObjectSelect deliveryData={deliveryData}
                                              setDeliveryData={setDeliveryData}
                                              className={cnWizardOrderForm('Delivery')}
                                              errors={formErrors.delivery}
                        />
                    </div>
                </div>
                <div className={cnWizardOrderForm('Field', {full: true})}>
                    <div className={cnWizardOrderForm('FieldHeader')}>
                        <Svg icon={'comment'} className={cnWizardOrderForm('FieldIcon')}/>
                        <div className={cnWizardOrderForm('FieldTitle')}>{t('Ваш комментарий к проекту')}</div>
                    </div>
                    <Textarea
                        className={cnWizardOrderForm(cnTextarea({error: !!(formErrors && formErrors['userComment'])}))}
                        value={userComment}
                        name={'userComment'}
                        placeholder={t('Введите Ваш комментарий к проекту')}
                        onChange={onChangeInput}
                    />
                </div>
                <div className={cnWizardOrderForm('Field', {type: 'checkbox'})}>
                    <Checkbox
                        className={cnWizardOrderForm(cnCheckbox({color: 'lightBlack'}))}
                        checked={checkStore}
                        callBack={(event: ChangeEvent<HTMLInputElement>) => {
                            setCheckStore(event.target.checked);
                        }}
                        name={'checkStore'}
                        label={t("Учитывать доступность комплектующих при оформлении заказа")}
                    />
                </div>
                <div className={cnWizardOrderForm('Field', {type: 'buttons'})}>
                    <Button
                        className={cnWizardOrderForm(cnButton())}
                        leftIcon={{path: 'shop', width: 20, height: 20, marginRight: 8}}
                        text={projectData.orderId ? t('Переоформить заказ') : t('Оформить заказ')}
                        callBack={trySendOrderForm}
                        mods={{
                            mods: {color: 'lightGreen', bkg: 'white', border: 'green', fill: 'lightGreen'},
                            hover: {mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}}
                        }}
                        type={'button'}
                    />
                </div>
                {
                    projectData.orderId ?
                        <div>
                            {t('Номер заказа') + ': ' + projectData.orderId}
                        </div> : null
                }
            </div>
        );
    }

export const withWizardOrderFormUserManager = withBemMod<IWizardOrderFormProps>(
    cnWizardOrderForm(),
    {user: ROLE_MANAGER},
    () => WizardOrderFormUserManager
);
