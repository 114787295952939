import React from 'react'
import {  Controller, useFormContext } from 'react-hook-form'
import { cnCreateForm } from '../FormModule';
import { Select } from '../../../../components/Select/Select';
import { Option } from '@yandex/ui/Select';

interface ISelectFormProps {
  label: string
  options: Option[]
  name: string
}

function SelectForm({ options, label, name}: ISelectFormProps) {

  const { control, formState: { errors } } = useFormContext();

  return (
  <>
    <Controller
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <>
            <label>{label}</label>
            <Select
              className={cnCreateForm('Select')}
              placeholder={label}
              showPlaceholder={false}
              options={options}
              value={value}
              callBack={onChange}
            />
          </>
        )}
        name={name}
      />
      {errors?.[name]?.type === "required" && <p className='text-danger' role="alert">Не указана {label}</p>} 
  </>
  )
}

export default SelectForm