import {useSelector} from 'react-redux';
import {AppState} from '../redux/AppStore';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {TStep} from '../../common-code/types/TStep';
import {KitchenService} from '../3d/services/KitchenService/KitchenService';

export default function useCurrentStep(service?: KitchenService): {
    currentStep: TStep,
    changePrevStep: () => void,
    changeNextStep: () => void,
    changeStep: (stepId: string) => void,
    isFirstStep: boolean,
    isLastStep: boolean
} {
    const steps: TStep[] = useSelector((state: AppState) => state.wizard.steps);
    const [searchParams] = useSearchParams();
    const stepFromUrl = searchParams.get('step');
    const navigate = useNavigate();
    const location = useLocation();

    const currentStep = () => {
        for (let step of steps) {
            if (!stepFromUrl || stepFromUrl === step.id) {
                return step;
            }
        }
        throw new Error('editor-steps-error-empty')
    };

    const isFirstStep = () => {
        return currentStep().sort === 1;
    }
    const isLastStep = () => {
        return currentStep().sort === steps[steps.length - 1].sort;
    }

    const changePrevStep = () => {
        let currentIndex: number;

        currentIndex = steps.findIndex(step => currentStep().id === step.id);
        if (currentIndex !== -1 && currentIndex !== 0) {
            searchParams.set('step', steps[currentIndex - 1].id);
            service?.changeStep(steps[currentIndex - 1]);
            navigate(location.pathname + '?' + searchParams.toString());
        }
    };

    const changeNextStep = () => {
        let currentIndex: number;

        currentIndex = steps.findIndex(step => currentStep().id === step.id);
        if (currentIndex !== -1 && currentIndex !== steps.length - 1) {
            searchParams.set('step', steps[currentIndex + 1].id);
            service?.changeStep(steps[currentIndex + 1]);
            navigate(location.pathname + '?' + searchParams.toString());
        }
    };

    const changeStep = (stepId: string) => {
        let step: TStep;

        for (step of steps) {
            if (step.id === stepId && currentStep().id !== stepId) {
                searchParams.set('step', stepId);
                service?.changeStep(step);
                navigate(location.pathname + '?' + searchParams.toString());
                break;
            }
        }
    };

    return {
        currentStep: currentStep(),
        changePrevStep: changePrevStep,
        changeNextStep: changeNextStep,
        changeStep: changeStep,
        isFirstStep: isFirstStep(),
        isLastStep: isLastStep()
    };
}