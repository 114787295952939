import React from "react";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { Tumbler } from "../../../components/Tumbler/Tumbler";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select/Select";
import { cn } from "@bem-react/classname";
import { defaultValues, initialErrors, levels } from "./defaultValues";
import Trash from "../icons/Trash";
import InputFile from "./InputFile/InputFile";
import { IFacadeMaterialData } from "common-code/lib/interfaces/materials/IFacadeMaterialData";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import { IFacadeData } from '../../../../../common-code/interfaces/materials/IFacadeData';
import { IMaterialData } from "common-code/lib/interfaces/materials/IMaterialData";
import "./FormFacadeMaterial.css";

export interface INewFacadeMaterialData extends IFacadeMaterialData {
  imageNew?: File | null;
}

export const cnFormFacadeMaterial = cn("FormFacadeMaterial");

function FormFacadeMaterial({
  itemFacadeMaterial,
  createFacadeMaterial,
}: {
  itemFacadeMaterial: IFacadeMaterialData | null;
  createFacadeMaterial: (facadeMaterial: INewFacadeMaterialData) => void;
}) {
  // const itemFacadeMaterial = useSelector((state: AppState) => state.wizard.editMaterial.itemFacadeMaterial);
  const [newFacade, setNewFacade] = React.useState<INewFacadeMaterialData>(
    defaultValues(itemFacadeMaterial)
  );

const formRef = React.useRef<HTMLFormElement>(null);
const scrollToForm = () => formRef?.current?.scrollIntoView({ block: "start", behavior: "smooth" });

  const {
    // обязательные поля
    title,
    facade,
    material,
    
    // необязательные поля 
    material2,
    corpusMaterial,
    image,
    imageNew,
    level,
    isTopDefault,
    isBottomDefault,
    isCustom, 
    canChange,
  } = newFacade;

  const [errors, setErrors] = React.useState(initialErrors);
  const [isMaterial2, setIsMaterial2] = React.useState( !!material2);
  const [isLevel, setIsLevel] = React.useState (!!level);
  const [isCorpusMaterial, setIsCorpusMaterial] = React.useState( !!corpusMaterial);

  const facades = useSelector((state: AppState) => state.wizard.facades);
  const materials = useSelector((state: AppState) => state.wizard.materials);
  const corpusMaterials = useSelector((state: AppState) => state.wizard.corpusMaterials);

  const onInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNewFacade({ ...newFacade, [e.target.name]: e.target.value });
  };

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFacade({ ...newFacade, [e.target.name]: e.target.checked });
  };

  const onFocus = () =>{
    setNewFacade({ ...newFacade });
    setErrors(initialErrors);
  };

  const CreateFacadeMaterialNew = () => {
    const facadeMaterial: INewFacadeMaterialData = {
      id: `${facade}-${material}`,
      title: title,
      facade,
      material
    };

    if(material2){
      facadeMaterial.material2 = material2;
    }

    if (imageNew) {
      facadeMaterial.imageNew = imageNew;
    } else if (image) {
      facadeMaterial.image = image;
    }

    if (isLevel) {
      facadeMaterial.level = level;
    }

    if (isCorpusMaterial){
      facadeMaterial.corpusMaterial = corpusMaterial;
    }

    if (isTopDefault) {
      facadeMaterial.isTopDefault = isTopDefault;
    }
    if (isBottomDefault) {
      facadeMaterial.isBottomDefault = isBottomDefault;
    }
    if(isCustom){
      facadeMaterial.isCustom = isCustom;
    }
    if(canChange){
      facadeMaterial.canChange = canChange;
    }
    return facadeMaterial;
  };

  const validateFacade = () => {
    
    if (!title) {
      setErrors({
        ...errors,
        title: 'Заполните поле "Название материала"',
      });
      return false;
    } else if (title.length < 2) {
      setErrors({
        ...errors,
        title: "Название материала должно содержать более 2 символов",
      });
      return false;
    }

    if(!facade){
      setErrors({
        ...errors,
        facade: 'Укажите фасад',
      });
      return false;
    }

    if(!material){
      setErrors({
        ...errors,
        material: 'Укажите материал',
      });
      return false;
    }

    return true;
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateFacade()) {
      scrollToForm();
      return;
    }

    const facadeMaterial = CreateFacadeMaterialNew();
    createFacadeMaterial(facadeMaterial);
    console.log("facadeMaterial", facadeMaterial);
  };

  // add files
  const addFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewFacade({
      ...newFacade,
      imageNew: Array.from(e.target.files as FileList)[0],
      image: "",
    });
    setErrors(initialErrors);
  };

  // функция удаления выбранного фото
  const filterFunction = () => {
    setNewFacade({ ...newFacade, imageNew: null, image: "" });
    setErrors(initialErrors);
  };

  // функция удаления выбранного фото
  const deleteImage = () => {
    setNewFacade({ ...newFacade, image: "" });
  };

  const edit = !!itemFacadeMaterial;

  return (
    <div className={cnFormFacadeMaterial
  ("")}>
      <div className={cnFormFacadeMaterial
      ("Container")}>
        <h3 className={cnFormFacadeMaterial
        ("Header")}>
          {edit ? "Редактирование материалов фасада" : "Добавление материалов фасада"}
        </h3>

        <form
          className={cnFormFacadeMaterial
          ("Content")}
          onSubmit={(e) => handleSubmit(e)}
          ref={formRef}
        >
          <label htmlFor="title">Название материала фасада *</label>
          <Textinput
            className={cnFormFacadeMaterial
            ("Textinput")}
            value={title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e)
            }
            name="title"
            type="text"
            maxLength={50}
            onFocus={onFocus}
          />
          {errors.title && <p className="text-danger">{errors.title}</p>}

          <div>
              <label htmlFor="facade">Укажитете фасад *</label>
                  <Select
                      className={cnFormFacadeMaterial('Select')}
                      placeholder={"Выберите значение"}
                      showPlaceholder={false}
                      options={facades.map((el: IFacadeData) => ({value: el.id, content: el.title}))}
                      value={facade}
                      callBack={(e) => onInputChange({...e,target:{...e.target, name: 'facade'}})}
                  />
          </div>
          {errors.facade && <p className="text-danger">{errors.facade}</p>}

          <div>
              <label htmlFor="material">Укажитете материал *</label>
                  <Select
                      className={cnFormFacadeMaterial('Select')}
                      placeholder={"Выберите значение"}
                      showPlaceholder={false}
                      options={materials.map((el: IMaterialData) => ({value: el.id, content: el.title}))}
                      value={material}
                      callBack={(e) => onInputChange({...e,target:{...e.target, name: 'material'}})}
                  />
          </div>
          {errors.material && <p className="text-danger">{errors.material}</p>}

          <>
            <h5>Изображение материалов фасадов</h5>

            {image && !imageNew && (
              <div className={cnFormFacadeMaterial
            ("Children")}>
                <span>{image.split("/").at(-1) as string}</span>
                <img width={50} height={50} src={image} alt="..." />
                <div className={cnFormFacadeMaterial
                ("Box-Btn")}>
                  <Trash handleDeleteValue={deleteImage} />
                </div>
              </div>
            )}
            
            <InputFile
              file={imageNew as File | null}
              addFiles={addFiles}
              filterFunction={filterFunction}
            />
          </>

          <Tumbler
            className={cnFormFacadeMaterial("Tumbler")}
            checked={isLevel as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => setIsLevel(e.target.checked)}
            label="Уточнить уровень установки модулей"
            name="isLevel"
          />

          {isLevel && (
            <>
              <label htmlFor="levels">Уровень установки</label>
              <Select
                className={cnFormFacadeMaterial("Select")}
                placeholder={"Выберите значение"}
                showPlaceholder={false}
                options={levels}
                value={level as string}
                callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  onInputChange({
                    ...e,
                    target: { ...e.target, name: "level" },
                  })
                }
              />
            </>
          )}


          <Tumbler
            className={cnFormFacadeMaterial
            ("Tumbler")}
            checked={isMaterial2 as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => setIsMaterial2(e.target.checked)}
            label="Указать дополнительный материал"
            name="isMaterial2"
          />

          {isMaterial2 && <div>
              <label htmlFor="material2">Дополнительный материал</label>
                  <Select
                      className={cnFormFacadeMaterial('Select')}
                      placeholder={"Выберите значение"}
                      showPlaceholder={false}
                      options={materials.map((el: IMaterialData) => ({value: el.id, content: el.title}))}
                      value={material2 as string}
                      callBack={(e) => onInputChange({...e,target:{...e.target, name: 'material2'}})}
                  />
          </div>}

          <Tumbler
            className={cnFormFacadeMaterial("Tumbler")}
            checked={isCorpusMaterial as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => setIsCorpusMaterial(e.target.checked)}
            label="Указать материал для корпуса"
            name="isCorpusMaterial"
          />

          {isCorpusMaterial && (
            <>
              <label htmlFor="corpusMaterial">Материал корпуса</label>
              <Select
                className={cnFormFacadeMaterial("Select")}
                placeholder={"Выберите значение"}
                showPlaceholder={false}
                options={corpusMaterials.map((el: IMaterialData) => ({value: el.id, content: el.title}))}
                value={corpusMaterial as string}
                callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  onInputChange({
                    ...e,
                    target: { ...e.target, name: "corpusMaterial" },
                  })
                }
              />
            </>
          )}
          
          <Tumbler
            className={cnFormFacadeMaterial
            ("Tumbler")}
            checked={isTopDefault as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="По умолчанию верхний фасад"
            name="isTopDefault"
          />
          
          <Tumbler
            className={cnFormFacadeMaterial
            ("Tumbler")}
            checked={isBottomDefault as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="По умолчанию нижний фасад"
            name="isBottomDefault"
          />

          <Tumbler
            className={cnFormFacadeMaterial
            ("Tumbler")}
            checked={isCustom as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Изготовленный на заказ"
            name="isCustom"
          />

          <Tumbler
            className={cnFormFacadeMaterial
            ("Tumbler")}
            checked={canChange as boolean}
            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
            label="Возможность изменения материала фасада"
            name="canChange"
          />

          <div className={cnFormFacadeMaterial
          ("Footer")}>
            <Button
              className={cnFormFacadeMaterial
              ("Button")}
              type={"submit"}
              text={"Подтвердить изменения"}
              leftIcon={undefined}
              mods={{
                mods: {
                  bkg: "white",
                  color: "lightGreen",
                  border: "lightGreen",
                  fill: "lightGreen",
                },
                hover: {
                  mods: { bkg: "lightGreen", color: "white", fill: "white" },
                },
              }}
              callBack={() => {}}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default FormFacadeMaterial;
