import {cn} from '@bem-react/classname';
import React, {RefObject} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {IProjectData} from '../../../../common-code/interfaces/project/IProjectData';
import {useTranslation} from 'react-i18next';
import {Accordion} from '../../components/Accordion';
import {cnAccordion} from '../../components/Accordion';
import "./KitchenParams.css";
import {KitchenParamsCommon} from './Common/KitchenParams-Common';
import {KitchenParamsHandles} from './Handles/KitchenParams-Handles';
import {KitchenParamsFacades} from './Facades/KitchenParams-Facades';

export const cnKitchenParams = cn('KitchenParams');
export interface IKitchenParamsProps extends IClassNameProps {
    service: KitchenService;
    projectData: IProjectData;
    containerRef: RefObject<HTMLDivElement>;
}

export const KitchenParams: React.FC<IKitchenParamsProps> =
    ({
         service,
         className
     }) => {
        const {t} = useTranslation();

        const isChangeHangles = (): boolean => {
            return false;
        }

        return (
            <div className={cnKitchenParams({}, [className])}>
                <Accordion
                    className={cnKitchenParams(cnAccordion())}
                    text={t('Основные параметры')}
                    initActive={true}
                >
                    <KitchenParamsCommon service={service}/>
                </Accordion>
                <Accordion
                    className={cnKitchenParams(cnAccordion())}
                    text={t('Фасады')}
                    initActive={true}
                >
                    <KitchenParamsFacades service={service}/>
                </Accordion>
                {isChangeHangles() && <Accordion
                    className={cnKitchenParams(cnAccordion())}
                    text={t('Ручки')}
                    initActive={true}
                >
                    <KitchenParamsHandles service={service}/>
                </Accordion>}
            </div>
        );
    };