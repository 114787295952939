import {IClassNameProps} from "@bem-react/core";
import {cn} from "@bem-react/classname";
import React from "react";
import './PageZakaz.css';
import {useTranslation} from "react-i18next";
import {cnHeader, Header} from "../../ui/layouts/Header";

export const cnPageZakaz = cn('PageZakaz');

export interface IPageZakazProps extends IClassNameProps {

}

export const PageZakaz: React.FC<IPageZakazProps> = ({className}) => {
    const {t} = useTranslation();

    return (
        <div className={cnPageZakaz({}, [className])}>
            <Header className={cnPageZakaz(cnHeader())} title={t("Цвета корпуса для кухонь на заказ") || undefined} />
        </div>
    );
}