import {
  GROUP_ACCESSORIES,
  GROUP_BAR_COUNTERS,
  GROUP_BIG_EQUIPMENTS,
  GROUP_BOTTOM_ANGLE_UNITS,
  GROUP_BOTTOM_END_UNITS,
  GROUP_BOTTOM_EQUIPMENTS,
  GROUP_BOTTOM_NORMAL_UNITS,
  GROUP_BUILTIN_EQUIPMENTS,
  GROUP_FACADES,
  GROUP_PENAL_UNITS,
  GROUP_PORTABLE_EQUIPMENTS,
  GROUP_TOP_ANGLE_UNITS,
  GROUP_TOP_END_UNITS,
  GROUP_TOP_EQUIPMENTS,
  GROUP_TOP_NORMAL_UNITS,
} from "common-code/lib/constants";
import { ICreateGroup } from "common-code/lib/interfaces/createData/ICreateGroup";

export const unitsList: { [key: string]: ICreateGroup } = {
  bottomAngleUnits: {
    id: GROUP_BOTTOM_ANGLE_UNITS,
    icon: "down-coner",
    title: "Нижние угловые секции",
    sort: 0,
    isGroup: true,
    items: [],
  },
  bottomNormalUnits: {
    id: GROUP_BOTTOM_NORMAL_UNITS,
    icon: "down-module",
    title: "Нижние прямые секции",
    sort: 1,
    isGroup: true,
    items: [],
  },
  bottomEndUnits: {
    id: GROUP_BOTTOM_END_UNITS,
    icon: "down-end",
    title: "Нижние секции-окончания",
    sort: 2,
    isGroup: true,
    items: [],
  },
  bottomEquipments: {
    id: GROUP_BOTTOM_EQUIPMENTS,
    icon: "cooker",
    title: "Нижние секции под оборудование",
    sort: 3,
    isGroup: true,
    items: [],
  },
  topAngleUnits: {
    id: GROUP_TOP_ANGLE_UNITS,
    icon: "up-coner",
    title: "Верхние угловые секции",
    sort: 4,
    isGroup: true,
    items: [],
  },
  topNormalUnits: {
    id: GROUP_TOP_NORMAL_UNITS,
    icon: "up-module",
    title: "Верхние прямые секции",
    sort: 5,
    isGroup: true,
    items: [],
  },
  topEndUnits: {
    id: GROUP_TOP_END_UNITS,
    icon: "up-end",
    title: "Верхние секции-окончания",
    sort: 6,
    isGroup: true,
    items: [],
  },
  topEquipments: {
    id: GROUP_TOP_EQUIPMENTS,
    icon: "extract",
    title: "Верхние секции под оборудование",
    sort: 7,
    isGroup: true,
    items: [],
  },
  penalUnits: {
    id: GROUP_PENAL_UNITS,
    icon: "penal",
    title: "Пеналы",
    sort: 8,
    isGroup: true,
    items: [],
  },
  bigEquipments: {
    id: GROUP_BIG_EQUIPMENTS,
    icon: "fridge",
    title: "Большое оборудование",
    sort: 9,
    isGroup: true,
    items: [],
  },
  barCounters: {
    id: GROUP_BAR_COUNTERS,
    icon: "bar",
    title: "Барные стойки",
    sort: 10,
    isGroup: true,
    items: [],
  },
  portableEquipments: {
    id:GROUP_PORTABLE_EQUIPMENTS,
    icon: "up-module",
    title: "Портативное оборудование",
    sort: 11,
    isGroup: true,
    items: [],
  },
  builtInEquipments: {
    id: GROUP_BUILTIN_EQUIPMENTS,
    icon: "up-module",
    title: "Встроенное оборудование",
    sort: 12,
    isGroup: true,
    items: [],
  },
  accessories: {
    id: GROUP_ACCESSORIES,
    icon: GROUP_ACCESSORIES,
    title: "Столешницы/стеновые панели",
    sort: 13,
    isGroup: true,
    items: [],
  },
  facades: {
    id: GROUP_FACADES,
    icon: "facade",
    title: "Фасады",
    sort: 14,
    isGroup: true,
    items: [],
  },
};
