import React, {ChangeEvent} from 'react';
import {cnSettingsMenu, ISettingsMenuGroupProps} from "../SettingsMenu";
import {
    OBJECT_SELECT_TYPE_APRON,
} from '../../../../constants';
import {useSelector} from 'react-redux';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {ISettingGroupAprons} from '../../../../interfaces/settingData/ISettingGroupAprons';
import {IApronData} from '../../../../../common-code/interfaces/materials/IApronData';
import {AppState} from '../../../../redux/AppStore';
import {ISettingApron} from '../../../../interfaces/settingData/ISettingApron';
import {ObjectSelect} from '../../../components/ObjectSelect/ObjectSelect';
import {cnTumbler, Tumbler} from '../../../components/Tumbler/Tumbler';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';

export interface ISettingsMenuApronsGroupProps extends ISettingsMenuGroupProps {
    apronsData: ISettingGroupAprons;
    service: KitchenService;
}

export const SettingsMenuAprons: React.FC<ISettingsMenuApronsGroupProps> =
    ({className, onRebuildData, apronsData, service}) => {
        const aprons: IApronData[] | undefined = useSelector((state: AppState) => state.wizard.aprons);

        const onSelectApron = (apron: IApronData, apronSetting: ISettingApron) => {
            onRebuildData(
                'setApronItemMaterial',
                {apronMaterial: apron.id, apronSetting: apronSetting}
            );
        }

        const onChangeApronVisible = (event: ChangeEvent<HTMLInputElement>, apronSetting: ISettingApron) => {
            onRebuildData(
                'setApronItemSelfVisible',
                {apronSetting: apronSetting, visible: event.target.checked}
            );
        }

        return (
            <div className={cnSettingsMenu('Aprons', [className])}>
                {
                    apronsData.items.map((apronSetting, index) => {
                        // @ts-ignore
                        // @ts-ignore
                        return (
                            <div className={cnSettingsMenu('Apron', [cnSettingsMenu('Object')])} key={index}>
                                <Tumbler
                                    className={cnSettingsMenu(cnTumbler({size: 'm'}))}
                                    checked={apronSetting.visible}
                                    callBack={(event: ChangeEvent<HTMLInputElement>) => {
                                        onChangeApronVisible(event, apronSetting)}
                                    }
                                    label={apronSetting.visibleTitle}
                                />
                                <ObjectSelect
                                    type={OBJECT_SELECT_TYPE_APRON}
                                    className={cnSettingsMenu('Apron', {})}
                                    data={{type: OBJECT_SELECT_TYPE_APRON, object: apronSetting.material}}
                                    list={aprons}
                                    title={apronSetting.name}
                                    onSelectObject={(apron: IMaterialData) => {
                                        onSelectApron(apron as IApronData, apronSetting);
                                    }}
                                    service={service}
                                />
                            </div>
                        );
                    })
                }
            </div>
        );
    }
