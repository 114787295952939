import {ThreePlinth} from './ThreePlinth';
import {Euler, MeshStandardMaterial, Vector2, Vector3} from 'three';
import {TSideType} from '../../../../../../common-code/types/TSideType';
import {TPoint2D} from '../../../../../../common-code/types/TPoint2D';
import {CommonObjectHelper} from '../../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {ISaveKUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {MathHelper} from 'common-code';

export class ThreePlinthUnion extends ThreePlinth {
    plinths: ThreePlinth[];

    constructor(options: ISaveKUnitDetailData, plinths: ThreePlinth[]) {
        let firstPlinth: ThreePlinth;

        firstPlinth = plinths[0];
        if (!firstPlinth) {
            throw new Error('error-ThreePlinthUnion-constructor');
        }
        super(options, firstPlinth.unit);
        this.plinths = plinths;
    }

    public remove() {
        this.setIsUnionPlinths(false);
        this.service.removeFromSceneObject3D(this.view3d);
        super.remove();
    }

    public isCalculate(): boolean {
        return !!this.saveData.isCalculate;
    }

    public getChildren(): ThreePlinth[] {
        return this.plinths;
    }

    public getSortChildIds(): string[] {
        let ids: { [n: number]: number } = {};
        let plinth: ThreePlinth;

        for (plinth of this.plinths) {
            ids[plinth.getId()] = plinth.getId();
        }

        return Object.keys(ids).sort((a: string, b: string) => {
            return +a - +b;
        });
    }

    public hide() {
        super.hide();
        this.setIsUnionPlinths(false);
    }

    public show() {
        super.show();
        this.setIsUnionPlinths(true);
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        this.setIsUnionPlinths(true);
    }

    public setIsUnionPlinths(isUnion: boolean) {
        let plinth: ThreePlinth;

        for (plinth of this.plinths) {
            plinth.setIsUnion(isUnion);
        }
    }

    public getFirstPlinth() {
        return this.plinths[0];
    }


    public getBodyMaterial(): MeshStandardMaterial {
        return new MeshStandardMaterial({
            color: this.getFirstPlinth().materialData.color || '#ffffff',
            emissive: this.getFirstPlinth().materialData.emissiveColor || undefined,
            map: this.getFirstPlinth().materialTextures.texture || null,
            normalMap: this.getFirstPlinth().materialTextures.normal || null,
            roughnessMap: this.getFirstPlinth().materialTextures.roughness || null,
            roughness: 0.7,
            emissiveIntensity:0.35,
        })
    }

    public getGlobalPosition() {
        return this.view3d.position;
    }

    public getGlobalRotation(): Euler {
        return this.view3d.rotation;
    }

    public getLength(): number {
        let plinth: ThreePlinth;
        let length: number = 0;

        for (plinth of this.plinths) {
            length += plinth.getLength();
        }

        return length;
    }

    public setCenterPosition() {
        this.view3d.userData.centerPosition = this.view3d.position;
    }

    public getFrontVectorAngle(): number {
        let normalVector;
        let firstPlinth, lastPlinth;
        let firstPosition, lastPosition;
        let positionAngle, coverAngle;
        let globalPoints, direction;
        let angle;

        firstPlinth = this.plinths[0];
        lastPlinth = this.plinths[this.plinths.length - 1];
        globalPoints = firstPlinth.unit.getGlobalPoints();
        firstPosition = firstPlinth.getGlobalPosition();
        lastPosition = lastPlinth.getGlobalPosition();
        normalVector = new Vector2(
            lastPosition.x - firstPosition.x, lastPosition.z - firstPosition.z
        );
        angle = Math.atan2(normalVector.x, normalVector.y);
        if (angle < 0) {
            angle += 2 * Math.PI;
        }
        positionAngle = MathHelper.getNormalAngle({
            x: lastPosition.x - firstPosition.x,
            y: lastPosition.z - firstPosition.z
        });
        coverAngle = MathHelper.getNormalAngle({
            x: globalPoints.bottom.A.x - globalPoints.bottom.B.x,
            y: globalPoints.bottom.A.z - globalPoints.bottom.B.z
        });
        if (+positionAngle.toFixed(3) === +coverAngle.toFixed(3)) {
            direction = 1;
        } else {
            direction = -1;
        }
        angle = angle + direction * 0.5 * Math.PI;
        if (angle >= 2 * Math.PI) {
            angle -= 2 * Math.PI;
        }

        return angle;
    }

    public getDefaultPoints(type: TSideType): Vector2[] {
        let firstPlinth, lastPlinth,
            firstPosition, lastPosition,
            globalPoints,
            positionAngle, plinthsAngle,
            leftPoints, rightPoints;

        firstPlinth = this.plinths[0];
        lastPlinth = this.plinths[this.plinths.length - 1];

        firstPosition = firstPlinth.getGlobalPosition();
        lastPosition = lastPlinth.getGlobalPosition();
        globalPoints = firstPlinth.getGlobalMainPoints();
        positionAngle = MathHelper.getNormalAngle({
            x: lastPosition.x - firstPosition.x,
            y: lastPosition.z - firstPosition.z
        });
        plinthsAngle = MathHelper.getNormalAngle({
            x: globalPoints.bottom.pointB.x - globalPoints.bottom.pointA.x,
            y: globalPoints.bottom.pointB.z - globalPoints.bottom.pointA.z
        });
        if (+positionAngle.toFixed(3) === +plinthsAngle.toFixed(3)) {
            leftPoints = firstPlinth.getLeftPoints();
            rightPoints = lastPlinth.getRightPoints();
        } else {
            leftPoints = lastPlinth.getLeftPoints();
            rightPoints = firstPlinth.getRightPoints();
        }
        switch (type) {
            case 'left':
                return leftPoints;
            case 'right':
                return rightPoints;
            default:
                return super.getDefaultPoints(type);
        }
    }

    protected addToScene() {
        this.service.addToSceneObject3D(this.view3d);
    }

    protected initPosition(): Vector3 {
        let position2d: TPoint2D;
        let position: Vector3;

        position2d = CommonObjectHelper.calculateUnionPosition2DByDetails(this.plinths);
        position = new Vector3(position2d.x, this.unit.getUnionDetailYPosition(this.plinths[0]), position2d.y);

        return position;
    }

    protected initRotation(): Euler {
        return CommonObjectHelper.calculateUnionRotationByDetails(this.plinths);
    }
}