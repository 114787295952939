import {CommonObject} from '../CommonObject/CommonObject';
import {ISaveDetailData} from '../../../../common-code/interfaces/saveData/ISaveDetailData';

export class CommonDetail extends CommonObject {
    saveData: ISaveDetailData;
    unit: CommonObject;

    constructor(options: ISaveDetailData, commonObject: CommonObject) {
        super(options, commonObject.service);
        this.saveData = options;
        this.unit = commonObject;
    }
    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        this.view3d.matrixAutoUpdate = false;
        if (this.coverPoints.length > 0) {
            this.createSelectCover(isRebuild);
        }
        if (this.transparentForBack) {
            this.setTransparentForBack();
        }
        this.setPosition(this.initPosition());
        this.setRotation(this.initRotation());
        this.updateViewType();
        this.updateAllMatrices();
    }
}