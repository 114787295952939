import { TGeometryType } from 'common-code/lib/types/TGeometryType'
import { TDirectionSideType } from 'common-code/lib/types/TDirectionSideType'
import { TFacadeModelType } from 'common-code/lib/types/TFacadeModelType'
import { THandleType } from 'common-code/lib/types/THandleType'
import { THandleModelType } from 'common-code/lib/types/THandleModelType'
import { ISaveHingeData } from 'common-code/lib/interfaces/saveData/ISaveHingeData'
import { TAlign } from 'common-code/lib/types/TAlign'
import { TFacadeOpenType } from 'common-code/lib/types/TFacadeOpenType'
import { ISaveFacadeData } from 'common-code/lib/interfaces/saveData/ISaveFacadeData'
import {
    FACADE_CALCULATE_NONE,
    FACADE_OPEN_TYPE_VERTICAL_PIVOT,
    SIDE_TYPE_DEFAULT,
} from 'common-code/lib/constants'
import { TFacadeCalculateType } from 'common-code/lib/types/TFacadeCalculateType'
export interface IFacade {
    // Поля формы обязательные для заполнения согласно interface ISaveFacadeData
    initSizesWidth: string
    initSizesHeight: string
    geometryType: TGeometryType
    modelType: TFacadeModelType
    sideType: TDirectionSideType

    // Поля формы необязательные
    // Зазоры
    gap: boolean
    gapBottom: string
    gapLeft: string
    gapRight: string
    gapTop: string

    // Выравнивание
    align: boolean
    alignX: TAlign
    alignY: TAlign
    // Отступы
    margin: boolean
    marginX: string
    marginY: string
    marginZ: string
    // Для групповых фасадов
    isGroup: boolean
    isParent: boolean
    isChild: boolean
    // Способ расчёта стоимости фасада
    isCalculateType: boolean
    calculateType: TFacadeCalculateType
    // Id группы
    groupId: string

    functionalType: string

    openType?: TFacadeOpenType

    isCalcSizes: boolean
    calcSizesWidth: string
    calcSizesHeight: string

    facadeFor: boolean
    facadeMaterial: string
    // флаг для перевернутой фрезировки фасада
    isFlipY: boolean
    // Вращение фасада
    rotation: boolean
    rotationX: string
    rotationY: string
    rotationZ: string
    // Поля для ручки
    handle: boolean
    handleModelType: THandleModelType
    // выравнивание ручки
    handleAlign: boolean
    handleAlignX: TAlign
    handleAlignY: TAlign
    // отступы ручки
    handleMargin: boolean
    handleMarginX: string
    handleMarginY: string
    handleMarginZ: string
    // Вращение ручки
    handleRotation: boolean
    handleRotationX: string
    handleRotationY: string
    handleRotationZ: string

    location?: THandleType
    // Поля для петель
    hinges: boolean
    arrHinges: ISaveHingeData[]

    modal: boolean
    error: string
}

export const initionalFacade = (facade?: ISaveFacadeData) => {
    if (!facade) {
        return {
            initSizesWidth: '%100',
            initSizesHeight: '%100',
            geometryType: 'square',
            modelType: 'default',
            sideType: SIDE_TYPE_DEFAULT,

            gap: false,
            gapBottom: '2',
            gapLeft: '2',
            gapRight: '2',
            gapTop: '2',

            align: false,
            alignX: 'center',
            alignY: 'bottom',

            margin: false,
            marginX: '0',
            marginY: '0',
            marginZ: '0',

            isGroup: false,
            isParent: false,
            isChild: false,

            isCalculateType: false,
            calculateType: FACADE_CALCULATE_NONE,

            groupId: '',

            functionalType: '',

            openType: FACADE_OPEN_TYPE_VERTICAL_PIVOT,

            isCalcSizes: false,
            calcSizesWidth: '%100',
            calcSizesHeight: '%100',

            facadeFor: false,
            facadeMaterial: '',

            isFlipY: false,

            rotation: false,
            rotationX: '0',
            rotationY: '0',
            rotationZ: '0',

            handle: false,
            handleModelType: 'default',

            handleAlign: false,
            handleAlignX: 'center',
            handleAlignY: 'top',

            handleMargin: false,
            handleMarginX: '0',
            handleMarginY: '40',
            handleMarginZ: '0',

            handleRotation: false,
            handleRotationX: '0',
            handleRotationY: '0',
            handleRotationZ: '0',

            location: 'horizontal',

            hinges: false,
            arrHinges: [],

            modal: false,
            error: '',
        } as IFacade
    }
    const {
        initSizes,
        geometryType,
        modelType,
        sideType,
        gap,
        align,
        margin,
        functionalType,
        groupId,
        openType,
        calculateSizes,
        calculateType,
        facadeMaterial,
        isFlipY,
        handle,
        hinges,
    } = facade

    return {
        initSizesWidth: initSizes.width,
        initSizesHeight: initSizes.height,
        geometryType: geometryType,
        modelType: modelType,
        sideType: sideType,

        gap: !!gap,
        gapBottom: gap?.bottom?.toString() || '2',
        gapLeft: gap?.left?.toString() || '2',
        gapRight: gap?.right?.toString() || '2',
        gapTop: gap?.top?.toString() || '2',

        align: !!align,
        alignX: align?.x || 'center',
        alignY: align?.y || 'bottom',

        margin: !!margin,
        marginX: margin?.x?.toString() || '0',
        marginY: margin?.y?.toString() || '0',
        marginZ: margin?.z?.toString() || '0',

        isGroup: !!groupId,
        isParent:
            !!groupId &&
            !!functionalType &&
            !!(calculateSizes?.width || calculateSizes?.height),
        isChild:
            !!groupId &&
            !!functionalType &&
            calculateType === FACADE_CALCULATE_NONE,

        isCalculateType: !!calculateType,
        calculateType: calculateType || '',

        groupId: groupId?.toString() || '',

        functionalType: functionalType || '',

        openType: openType,

        isCalcSizes: !!calculateSizes?.width || !!calculateSizes?.width,
        calcSizesWidth: calculateSizes?.width?.toString() || '',
        calcSizesHeight: calculateSizes?.height?.toString() || '',

        facadeFor: !!facade.for,
        facadeMaterial: facadeMaterial || '',

        isFlipY: !!isFlipY,

        rotation: !!facade.rotation,
        rotationX: facade.rotation?.x
            ? ((facade.rotation?.x * 180) / Math.PI)?.toString()
            : '0',
        rotationY: facade.rotation?.y
            ? ((facade.rotation?.y * 180) / Math.PI)?.toString()
            : '0',
        rotationZ: facade.rotation?.z
            ? ((facade.rotation?.z * 180) / Math.PI)?.toString()
            : '0',

        handle: !!handle,
        handleModelType: handle?.modelType || 'default',
        location: handle?.location || 'horizontal',

        handleAlign: !!handle?.align,
        handleAlignX: handle?.align?.x || 'center',
        handleAlignY: handle?.align?.y || 'top',

        handleMargin: !!handle?.margin,
        handleMarginX: handle?.margin?.x?.toString() || '0',
        handleMarginY: handle?.margin?.y?.toString() || '40',
        handleMarginZ: handle?.margin?.z?.toString() || '0',

        handleRotation: !!facade.handle?.rotation,
        handleRotationX: facade.handle?.rotation?.x
            ? ((facade.handle?.rotation?.x * 180) / Math.PI)?.toString()
            : '0',
        handleRotationY: facade.handle?.rotation?.y
            ? ((facade.handle?.rotation?.y * 180) / Math.PI)?.toString()
            : '0',
        handleRotationZ: facade.handle?.rotation?.z
            ? ((facade.handle?.rotation?.z * 180) / Math.PI)?.toString()
            : '0',

        hinges: !!hinges,
        arrHinges: hinges || [],

        modal: false,
        error: '',
    } as IFacade
}
