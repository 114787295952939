import {ThreeUnit} from '../../../ThreeUnit/ThreeUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {Vector3} from 'three';
import {TDetail} from '../../../../../types/TDetail';
import {
    KDETAIL_PRICE_TYPE_COLLECTIONS,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_NONE,
} from '../../../../../../../common-code/constants';
import {ICoverPoints} from '../../../../../interfaces/ICoverPoints';
import {ThreeAccessoryUnit} from '../ThreeAccessoryUnit';
import {ISaveTabletopUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveTabletopUnitData';
import {ITabletopData} from '../../../../../../../common-code/interfaces/materials/ITabletopData';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';

export class ThreeTabletopUnit extends ThreeAccessoryUnit {
    saveData: ISaveTabletopUnitData;

    constructor(options: ISaveTabletopUnitData, service: KitchenService) {
        super(options, service);
        this.saveData = this.initSaveData(options);
    }

    public initState(isRebuild?: boolean) {
        this.addCoverPoints([
            new Vector3(-this.getWidth()/2, -this.getHeight()/2, -this.getDepth()/2),
            new Vector3(this.getWidth()/2, this.getHeight()/2, this.getDepth()/2)
        ]);
        super.initState(isRebuild);
    }

    public getUnionDetailYPosition(detail: TDetail): number {
        return this.view3d.position.y;
    }

    public defaultYPosition(): number {
        return this.service.getBottomUnitLegsHeight() + this.service.getBottomUnitHeight() + this.getSideDistance(SIDE_TYPE_BOTTOM);
    }

    public getAvailableHeights(): number[] | undefined {
        let availableHeights: number[] = [];
        let tabletopMaterial: ITabletopData | undefined;

        if (this.tabletops && this.tabletops[0]) {
            tabletopMaterial = this.tabletops[0].getMaterialData();
        }
        if (tabletopMaterial && tabletopMaterial.heights) {
            availableHeights = tabletopMaterial.heights;
        }

        return availableHeights.length ? availableHeights : undefined;
    }

    protected initSaveData(options: ISaveTabletopUnitData): ISaveTabletopUnitData{
        super.initSaveData(options);
        options.sizes.height = this.initHeight(options);

        return options;
    }

    protected initHeight(options: ISaveTabletopUnitData): number {
        let initHeight: number;
        let tabletop: ITabletopData | undefined;

        try {
            if (options.tabletops && options.tabletops[0] && options.tabletops[0].material) {
                tabletop = this.service.getTabletopMaterial(options.tabletops[0].material);
            }
            if (tabletop) {
                initHeight = (tabletop.heights && tabletop.heights.includes(options.sizes.height)) ? options.sizes.height : tabletop.height;
            } else {
                initHeight = this.service.getTabletopHeight();
            }
        } catch (e) {
            initHeight = options.sizes.height;
        }

        return initHeight;
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let tabletop: ISaveKUnitDetailData;

        if (!tabletops) {
            tabletops = [{
                id: 0,
                initPosition: {x: 0, y: 0, z: 0},
                priceType: KDETAIL_PRICE_TYPE_COLLECTIONS,
                positionType: SIDE_TYPE_NONE,
                isSelfVisible: true,
            }]
        }
        for (tabletop of tabletops) {
            tabletop.sizes = {
                height: this.getHeight(),
                width: this.getDepth(),
                length: this.getWidth()
            }
        }

        return tabletops;
    }

    protected getMoveLines(): number[] {
        let unitGlobalPoints: ICoverPoints;
        let lines: number[];
        let unit: ThreeUnit;

        lines = [];
        const tabletopUnits: ThreeUnit[] = this.service.getUnits(['tabletops']);
        lines.push(this.service.getInstallBottomUnitHeight());
        for (unit of tabletopUnits) {
            if (this.getId() === unit.getId()) {
                continue;
            }
            unitGlobalPoints = unit.getGlobalPoints(unit.selectCover);
            lines.push(Math.round(unitGlobalPoints.box.min.y));
            lines.push(Math.round((unitGlobalPoints.box.min.y + unitGlobalPoints.box.max.y)/2));
            lines.push(Math.round(unitGlobalPoints.box.max.y));
        }

        lines = lines.filter((item, index) => {
            return lines.indexOf(item) === index;
        });

        return lines;
    }
}