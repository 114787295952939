import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {ThreeBottomFullLengthSidewallCorpus} from '../../../details/ThreeCorpus/types/ThreeBottomFullLengthSidewallCorpus';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnitFullLengthSidewall extends ThreeBottomUnit {
    corpus: ThreeBottomFullLengthSidewallCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomFullLengthSidewallCorpus {
        return new ThreeBottomFullLengthSidewallCorpus(CommonHelper.deepCopy(corpusData), this)
    }
}