import {ThreeTopEquipment} from '../ThreeTopEquipment';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {SIDE_TYPE_BACK, } from '../../../../../../../common-code/constants';
import {
    ISaveExtractEquipmentData
} from '../../../../../../../common-code/interfaces/saveData/ISaveExtractEquipmentData';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';

export class ThreeExtractEquipment extends ThreeTopEquipment {
    saveData: ISaveExtractEquipmentData;

    constructor(options: ISaveExtractEquipmentData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
    }

    protected initApronsData(aprons?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        if (this.saveData.hasAprons) {
            return [
                {
                    id: 0,
                    positionType: SIDE_TYPE_BACK,
                },
            ];
        } else {
            return undefined;
        }
    }

    protected createAprons() {
        console.log('need create Apron in ThreeExtractEquipment')
    }

}