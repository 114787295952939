import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, BufferGeometry, Euler, ExtrudeGeometry, Shape, Vector3} from 'three';
import {BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_PANEL_WIDTH} from '../../../../../constants';
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import {
    ThreeBottomUnitAngleFullLengthSidewallNormalSink
} from "../../../units/ThreeBottomUnit/types/ThreeBottomUnitAngleFullLengthSidewallNormalSink";
import {
    ISaveBottomAngleFullLengthSidewallNormalSinkCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleFullLengthSidewallNormalSinkCorpusData';

export class ThreeBottomAngleFullLengthSidewallNormalSinkCorpus extends ThreeCorpus {
    saveData: ISaveBottomAngleFullLengthSidewallNormalSinkCorpusData;
    unit: ThreeBottomUnitAngleFullLengthSidewallNormalSink;

    constructor(options: ISaveBottomAngleFullLengthSidewallNormalSinkCorpusData, unit: ThreeBottomUnitAngleFullLengthSidewallNormalSink) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public getWidthFacade() {
        return this.saveData.widthFacade;
    }

    public getHeightBackThickness() {
        if (this.saveData.heightBackThickness) {
            return this.saveData.heightBackThickness;
        } else {
            return this.getHeight();
        }
    }

    public getTurnFrontPanel() {
        if (this.saveData.turnFrontPanel) {
            return this.saveData.turnFrontPanel;
        } else {
            return {width: 34, height: 720, depth: 16};
        }
    }

    protected createTriangleGeometry(): BufferGeometry {
        let triangleGeometry;
        let triangleShape = new Shape();
        let triangleExtrudeSettings = {
            steps: 1,
            depth: 4,
            bevelEnabled: false
        };

        triangleShape.moveTo(0, 0);
        triangleShape.lineTo(240, 0);
        triangleShape.lineTo(0, 300);
        triangleShape.lineTo(0, 0);

        triangleGeometry = new ExtrudeGeometry([triangleShape], triangleExtrudeSettings);

        return triangleGeometry;
    }

    public initState(isRebuild?: boolean) {
        this.createFrontPanels();
        super.initState(isRebuild);
    }

    protected createFrontPanels() {
        let position: Vector3;
        let turnPosition: Vector3;

        if (!this.saveData.frontPanel) {
            return;
        }
        position = new Vector3();
        turnPosition = new Vector3();
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                position.set(
                    this.getWidth()/2 - this.saveData.frontPanel.length/2 - this.getWidthFacade(),
                    (this.getHeight() - this.getHeightBackThickness()) / 2,
                    this.getDepth()/2 + this.getThickness()/2
                );
                turnPosition.set(
                    this.getWidth()/2 - this.saveData.frontPanel.length/2 - this.getWidthFacade(),
                    (this.getHeight() - this.getHeightBackThickness()) / 2,
                    this.getDepth() / 2 + this.getTurnFrontPanel().depth + this.getThickness()
                );
                break;
            case SIDE_TYPE_RIGHT:
                position.set(
                    -this.getWidth()/2 + this.saveData.frontPanel.length/2 + this.getWidthFacade(),
                    (this.getHeight() - this.getHeightBackThickness()) / 2,
                    this.getDepth()/2 + this.getThickness()/2
                );
                turnPosition.set(
                    -this.getWidth()/2 + this.saveData.frontPanel.length/2 + this.getWidthFacade(),
                    (this.getHeight() - this.getHeightBackThickness()) / 2,
                    this.getDepth() / 2 + this.getTurnFrontPanel().depth + this.getThickness()
                );
                break;
        }
        this.createPanel(
            new BoxGeometry(this.saveData.frontPanel.length, this.getHeight() - (this.getHeight() - this.getHeightBackThickness()), this.getThickness()),
            'frontPanel',
            position
        );

        if (this.saveData.turnFrontPanel) {
            this.createPanel(
                new BoxGeometry(this.getTurnFrontPanel().depth, this.getTurnFrontPanel().height, this.getTurnFrontPanel().width),
                'frontPanel',
                turnPosition
            );
        }
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth() - this.getBackThickness()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                0,
                this.getBackThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_PANEL_WIDTH, this.getThickness()),
            'front',
            new Vector3(
                0,
                this.getHeight() / 2 - BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_PANEL_WIDTH / 2,
                this.getDepth() / 2 - this.getThickness() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_PANEL_WIDTH, this.getThickness()),
            'back',
            new Vector3(
                0,
                this.getHeight() / 4,
                -this.getDepth() / 2 + this.getThickness() / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getDepth() - this.getBackThickness()),
            'bottom',
            new Vector3(
                0,
                this.getHeight() / 2 + this.getThickness() / 2 - this.getHeightBackThickness(),
                this.getBackThickness()/2
            )
        );
        this.createPanel(this.createTriangleGeometry(),
            'backThickness1',
            new Vector3(
                -this.getWidth()/2,
                -this.getHeight()/2 + 90,
                -this.getDepth()/2
            )
        );
        this.createPanel(this.createTriangleGeometry(),
            'backThickness2',
            new Vector3(
                this.getWidth()/2,
                -this.getHeight()/2 + 90,
                -this.getDepth()/2 + this.getBackThickness()
            ),
            new Euler(
                0,
                Math.PI,
                0
            )
        );
    }

    protected createBackPanels() {

    }
}