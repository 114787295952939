import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {TDirectionSideType} from '../../../../../../../common-code/types/TDirectionSideType';
import {ThreeTopEndOpenedCircleCorpus} from "../../../details/ThreeCorpus/types/ThreeTopEndOpenedCircleCorpus";
import {
    ISaveTopUnitEndOpenedCircleData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitEndOpenedCircleData';
import {
    ISaveTopEndOpenedCircleCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopEndOpenedCircleCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitEndOpenedCircle extends ThreeTopUnit {
    saveData: ISaveTopUnitEndOpenedCircleData;
    corpus: ThreeTopEndOpenedCircleCorpus;

    constructor(options: ISaveTopUnitEndOpenedCircleData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isEndUnit(): boolean {
        return true;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    protected initCorpus(corpusData: ISaveTopEndOpenedCircleCorpusData): ThreeTopEndOpenedCircleCorpus {
        return new ThreeTopEndOpenedCircleCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}