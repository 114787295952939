import React from 'react'
import { IClassNameProps } from '@bem-react/core'
import { useDispatch } from 'react-redux'
import { AppState } from '../../../../redux/AppStore'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    CHANGE_EDIT_MODE,
    HIDE_LOADING,
    INIT_EDIT_MATERIAL,
    SHOW_LOADING,
} from '../../../../constants'
import { Button, cnButton } from '../../../components/Button'
import { cn } from '@bem-react/classname'
import FormHandle, { INewHandleData } from '../FormHandle/FormHandle'
import { IHandleData } from '../../../../../common-code/interfaces/materials/IHandleData'
import { TEditMode } from '../../../../../common-code/types/TEditMode'
import { TMaterialEditMode } from '../../../../../../../common/types/TMaterialEditMode'
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService'
import './EditHandle.css'


interface IEditHandleProps extends IClassNameProps {
    saveHandle: (
        handleListNew: IHandleData[],
        handleMode: TMaterialEditMode
    ) => Promise<void>
    saveImagePreview: (
        imageNew: File,
        plinthId: string,
        materialMode: TMaterialEditMode
    ) => Promise<string | null>
    service: KitchenService
}

const cnEditHandle = cn('EditHandle')

function EditHandle({ saveHandle, saveImagePreview, service }: IEditHandleProps) {
    const { t } = useTranslation()
    const isEditMode = useSelector(
        (state: AppState) => state.wizard.editModule.isEditMode
    )
    const handles = useSelector((state: AppState) => state.wizard[isEditMode])
    const dispatch = useDispatch()
    const initialValue = useSelector(
        (state: AppState) => state.wizard.editMaterial.itemHandle
    )
    const [itemHandle, setItemHandle] = React.useState<INewHandleData | null>(
        initialValue || null
    )

    const handleToggleMode = (str: TEditMode) => {
        dispatch({ type: CHANGE_EDIT_MODE, payload: str })
        dispatch({ type: INIT_EDIT_MATERIAL })
    }

    const createHandle = (handle: INewHandleData) => {
        service.addToSceneHandle(handle)
        setItemHandle(handle)
    }

    const saveHandlesCollection = async (itemHandle: INewHandleData) => {
        // проверяем корректность заполнения формы
        if (!itemHandle) {
            alert('Необходимо корректно заполнить форму.')
            return
        }
        // если такое название уже есть
        if (
            handles.length > 0 &&
            handles.find((handle: IHandleData) => handle.id === itemHandle.id)
        ) {
            alert(
                'Ручка с такими параметрами уже существует в коллекции. Укажите другое название.'
            )
            return
        }
        dispatch({ type: SHOW_LOADING })
        // сохраняем preview материала
        if (itemHandle.imageNew) {
            const imageUrl = await saveImagePreview(
                itemHandle.imageNew,
                itemHandle.id,
                'handles'
            )
            if (!imageUrl) {
                alert('Ошибка при сохранении превью. Попробуйте еще раз.')
                dispatch({ type: HIDE_LOADING })
                return
            }
            itemHandle.image = imageUrl
            // удаляем ключ imageNew
            delete itemHandle.imageNew
        }

        // сохраняем ручки
        const handleListNew = [...handles, itemHandle]
        saveHandle(handleListNew, 'handles').then(() => {
            handleToggleMode('')
            dispatch({ type: HIDE_LOADING })
        })
    }

    const editHandlesCollection = async (itemHandle: INewHandleData) => {
        // проверяем корректность заполнения формы
        if (!itemHandle) {
            alert('Необходимо корректно заполнить форму.')
            return
        }
        // если такое название уже есть
        if (
            handles.length > 0 &&
            !handles.find((plinth: IHandleData) => plinth.id === itemHandle.id)
        ) {
            alert('Цоколя с таким наменованием нет. Укажите другое название.')
            return
        }
        dispatch({ type: SHOW_LOADING })

        // сохраняем preview материала
        if (itemHandle.imageNew) {
            const imageUrl = await saveImagePreview(
                itemHandle.imageNew,
                itemHandle.id,
                'handles'
            )
            if (!imageUrl) {
                alert('Ошибка при сохранении превью. Попробуйте еще раз.')
                dispatch({ type: HIDE_LOADING })
                return
            }
            itemHandle.image = imageUrl
            // удаляем ключ imageNew
            delete itemHandle.imageNew
        }

        // сохраняем ручки
        const handleListNew = handles.map((handle: IHandleData) =>
            handle.id === itemHandle.id ? itemHandle : handle
        )
        saveHandle(handleListNew, 'handles').then(() => {
            handleToggleMode('')
            dispatch({ type: HIDE_LOADING })
        })
    }

    return (
        <>
            <FormHandle itemHandle={itemHandle} createHandle={createHandle} />

            <div className={cnEditHandle('Buttons-Container')}>
                <Button
                    className={cnEditHandle(cnButton(), { type: 'prev' })}
                    leftIcon={{
                        path: 'arrow-left',
                        width: 20,
                        height: 20,
                        marginRight: 8,
                    }}
                    text={t('Предыдущий шаг') || undefined}
                    callBack={() => handleToggleMode('')}
                    mods={{
                        mods: {
                            color: 'black',
                            bkg: 'white',
                            border: 'black',
                            fill: 'black',
                        },
                    }}
                    type={'button'}
                />
            </div>

            {itemHandle && (
                <>
                    <div className={cnEditHandle('Button-Add')}>
                        <Button
                            className={cnEditHandle(cnButton())}
                            type={'button'}
                            text={'Добавить новую ручку в коллекцию'}
                            leftIcon={{
                                path: 'plus2',
                                width: 16,
                                height: 16,
                                marginRight: 8,
                            }}
                            mods={{
                                mods: {
                                    bkg: 'white',
                                    color: 'lightGreen',
                                    border: 'lightGreen',
                                    fill: 'lightGreen',
                                },
                                hover: {
                                    mods: {
                                        bkg: 'lightGreen',
                                        color: 'white',
                                        fill: 'white',
                                    },
                                },
                            }}
                            callBack={() => saveHandlesCollection(itemHandle)}
                        />
                        <Button
                            className={cnEditHandle(cnButton())}
                            type={'button'}
                            text={'Заменить редактируемую ручку в коллекции'}
                            leftIcon={undefined}
                            mods={{
                                mods: {
                                    bkg: 'white',
                                    color: 'lightGreen',
                                    border: 'lightGreen',
                                    fill: 'lightGreen',
                                },
                                hover: {
                                    mods: {
                                        bkg: 'lightGreen',
                                        color: 'white',
                                        fill: 'white',
                                    },
                                },
                            }}
                            callBack={() => editHandlesCollection(itemHandle)}
                        />
                    </div>
                </>
            )}
        </>
    )
}

export default EditHandle
