import React from 'react';
import {IClassNameProps} from "@bem-react/core";
import {cnWizardSpec} from '../WizardSpec';
import {ISpecItem} from '../../../../../common-code/interfaces/catalog/ISpecItem';
import {useTranslation} from 'react-i18next';
import {WizardSpecGroupItem} from '../GroupItem/WizardSpec-GroupItem';

export interface IWizardSpecGroupProps extends IClassNameProps {
    title: string;
    specItems: ISpecItem[];
}

export const WizardSpecGroup: React.FC<IWizardSpecGroupProps> =
    ({
         title,
         specItems,
     }) => {
    const {t} = useTranslation();

    if (specItems.length <= 0) {
        return null;
    }

        return (
            <div className={cnWizardSpec('Group')}>
                <div className={cnWizardSpec('GroupTitle')}>{title}</div>
                <table  className={cnWizardSpec('GroupContent')}>
                    <tbody>
                        <tr className={cnWizardSpec('GroupHeader')}>
                            <td className={cnWizardSpec('GroupCell', {header: true, size: 's'})}>{t('№')}</td>
                            <td className={cnWizardSpec('GroupCell', {header: true})}>{t('Код товара')}</td>
                            <td className={cnWizardSpec('GroupCell', {header: true, size: 'l'})}>{t('Наименование')}</td>
                            <td className={cnWizardSpec('GroupCell', {header: true})}>{t('Количество')}</td>
                            <td className={cnWizardSpec('GroupCell', {header: true})}>{t('Цена')}</td>
                            <td className={cnWizardSpec('GroupCell', {header: true})}>{t('Стоимость')}</td>
                            <td className={cnWizardSpec('GroupCell', {header: true, size: 'sm'})}>{t('Склад')}</td>
                            <td className={cnWizardSpec('GroupCell', {header: true})}>{t('* Поступление на')}</td>
                        </tr>
                        {
                            specItems.map((specItem: ISpecItem, index: number) => {
                                return (
                                    <WizardSpecGroupItem specItem={specItem} index={index + 1} key={title + '.'+index}/>
                                );
                            })
                        }
                    </tbody>
                </table>


            </div>
        );
    };