import {IMods} from "../interfaces/IMods";

export const useModsGenerator = () => {
    return (data: IMods) => {
        let mods: {} = {};

        if (data.mods) {
            mods = {...data.mods};
        }
        if (data.active?.state) {
            mods = {...mods, ...data.active.mods};
        }
        if (data.hover?.state) {
            mods = {...mods, ...data.hover.mods};
        }
        if (data.disabled?.state) {
            mods = {...mods, ...data.disabled.mods};
        }

        return mods;
    }
}