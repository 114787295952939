import React, {ChangeEvent, useState} from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_NUMBER} from '../../../../../common-code/constants';
import {Textinput} from '@yandex/ui/Textinput/desktop/bundle';
import {IObjectOptionProps} from '../ObjectOption';
import {useTranslation} from 'react-i18next';
import {IOptionNumber} from '../../../../../common-code/interfaces/option/IOptionNumber';
import {cnObjectOption} from '../index';


export interface IObjectOptionTypeNumberProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_NUMBER;
    option?: IOptionNumber;
    defaultValue?: number;

}

const ObjectOptionTypeNumber: React.FC<IObjectOptionTypeNumberProps> =
    ({
         option,
         group,
         className,
         onChange,
         defaultValue
     }) => {
        const [value, setValue] = useState<number>((defaultValue !== undefined && !isNaN(+defaultValue)) ? +defaultValue : (option && option.defaultValue ? option.defaultValue : 0));
        const {t} = useTranslation();

        if (!option || !onChange) {
            return null;
        }

        const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
            onChange(option.id, +event.target.value, group ? group.id : undefined);
            setValue(+event.target.value);
        }

        return (
            <div className={cnObjectOption({}, [className])}>
                <div className={cnObjectOption('Title', [className+'Title'])}>
                    {t(option.title)}
                </div>
                <div className={cnObjectOption('Value', [className+'Value'])}>
                    <Textinput
                        className={cnObjectOption('Textinput', [className])}
                        placeholder={t(option.title)}
                        debounceTimeout={300}
                        value={value}
                        onInput={onChangeInput}
                    />
                </div>
            </div>
        );
    }

export const withObjectOptionTypeNumber = withBemMod<IObjectOptionTypeNumberProps, IObjectOptionProps>(
    cnObjectOption(),
    {type: OPTION_TYPE_NUMBER},
    () => ObjectOptionTypeNumber
);