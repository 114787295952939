import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {useState} from "react";
import './ObjectSelect-Select.css';
import {IDataObjectSelectSelect} from '../../../../interfaces/IDataObjectSelectSelect';
import {Svg} from '../../Svg';
import {useTranslation} from 'react-i18next';

export const cnObjectSelectSelect = cn('ObjectSelectSelect');

export interface IObjectSelectSelectProps extends IClassNameProps {
    image?: IDataObjectSelectSelect | undefined,
    color?: IDataObjectSelectSelect | undefined,
    icon?: IDataObjectSelectSelect | undefined,
    hideArrow?: boolean;
    title?: string,
    description?: string,
    callBack: () => void;
}

export const ObjectSelectSelect: React.FC<IObjectSelectSelectProps> =
    ({
         className,
         image,
         color,
         icon,
         title,
         description,
         callBack
    }) => {
    const [hover, setHover] = useState<boolean>(false);
    const {t} = useTranslation();

    return (
        <div
            className={cnObjectSelectSelect({hover: hover}, [className])}
            onMouseEnter={() => {setHover(true)}}
            onMouseLeave={() => {setHover(false)}}
            onClick={callBack}
        >
            <Svg icon={'plus2'} className={cnObjectSelectSelect('PlusIcon')}/>
            <div className={cnObjectSelectSelect('Images')}>
                {image ? <img className={cnObjectSelectSelect('Image')} src={image.path} alt={image.label} style={{width: `${image.width}px`, height: `${image.height}px`}}/> :
                    color ? <div className={cnObjectSelectSelect('Color')} style={{width: `${color.width}px`, height: `${color.height}px`, backgroundColor: color.path}}/> :
                        icon ? <Svg className={cnObjectSelectSelect('Icon')} icon={icon.path} label={icon.path} style={{width: `${icon.width}px`, height: `${icon.height}px`, fill: `#${icon.fill}`}}/> :
                            null
                }
            </div>

            <div className={cnObjectSelectSelect('Texts')}>
                {title ? <p className={cnObjectSelectSelect('Title')}>{t('Коллекция')}:</p> : null}
                {description ? <p className={cnObjectSelectSelect('Description')}>{description}</p> : null}
            </div>
        </div>
    );
};