import {ThreePillar} from './ThreePillar';
import {ThreeWall} from '../../rooms/ThreeWall/ThreeWall';
import {ISavePillarData} from '../../../../../../common-code/interfaces/saveData/ISavePillarData';

export class ThreePillarHorizontal extends ThreePillar {
    wall: ThreeWall;
    constructor(options: ISavePillarData, wall: ThreeWall) {
        super(options, wall.room);
        this.wall = wall;
    }
    public getWidth(): number {
        return this.wall.getWidth();
    }
}