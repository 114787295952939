import React, {useEffect, useRef, useState} from 'react';
import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IAppConfig} from '../../../../common-code/interfaces/config/IAppConfig';
import {AppState} from '../../../redux/AppStore';
import {IUserData} from '../../../../common-code/interfaces/IUserData';
import {KitchenEditor} from '../../../3d/editors/KitchenEditor/KitchenEditor';
import {KitchenUserControls} from '../../../3d/userControls/KitchenUserControls/KitchenUserControls';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {IWizardOptions} from '../../../interfaces/IWizardOptions';
import {TWizardUrlOptions} from '../../../types/TWizardUrlOptions';
import useWizardUrlOptions from '../../../hooks/useWizardUrlOptions';
import {IProjectData} from '../../../../common-code/interfaces/project/IProjectData';
import {ISaveRoomData} from '../../../../common-code/interfaces/saveData/ISaveRoomData';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {IReplaceData} from '../../../interfaces/IReplaceData';
import {ICreateObjectDomElements} from '../../../interfaces/ICreateObjectDomElements';
import {useEffectDidMount} from '../../../hooks/useEffectDidMount';
import axios, {AxiosResponse} from 'axios';
import useMoveCreateObject from '../../../hooks/useMoveCreateObject';
import {EditorService} from '../../../3d/services/EditorService/EditorService';
import {DEFAULT_PROJECT_ID, DEFAULT_ROOM_ID} from '../../../../common-code/constants';
import {
    CHANGE_PROJECT_DATA,
    CHANGE_ROOM_DATA,
    EDITOR_INIT_OPTIONS,
    HIDE_REPLACE_DATA,
    HIDE_UNIT_SPEC
} from '../../../constants';
import {IEditorOptions} from '../../../interfaces/IEditorOptions';
import {IContextIcon} from '../../../interfaces/IContextIcon';
import {IContextAction} from '../../../interfaces/IContextAction';
import {ISettingsAction} from '../../../interfaces/settingData/ISettingsAction';
import {WizardCurrentStep} from './CurrentStep/Wizard-CurrentStep';
import {WizardSteps} from './Steps/Wizard-Steps';
import {WizardContextMenu} from './ContextMenu/Wizard-ContextMenu';
import {WizardSettingsMenu} from './SettingsMenu/Wizard-SettingsMenu';
import {WizardMoveObject} from './MoveObject/Wizard-MoveObject';
import {cnModal, Modal} from '../../components/Modal';
import {WizardReplaceMenu} from './ReplaceMenu/Wizard-ReplaceMenu';
import {CommonHelper} from 'common-code';
import "./Wizard.css";
import {WizardProjectPrice} from './ProjectPrice/Wizard-ProjectPrice';
import {WizardFlyMenu} from './FlyMenu/Wizard-FlyMenu';
import {WizardVerticalMenu} from './VerticalMenu/Wizard-VerticalMenu';
import {WizardHorizontalMenu} from './HorizontalMenu/Wizard-HorizontalMenu';
import {IUnitSpecData} from '../../../interfaces/IUnitSpecData';
import {WizardUnitSpec} from './UnitSpec/Wizard-UnitSpec';
import {cnHeader, Header} from '../../layouts/Header';
import {cnPageWizard} from '../../../pages/PageWizard/PageWizard';
import {WizardAdminMenu} from './AdminMenu/Wizard-AdminMenu';
import {IClientSessionData} from '../../../../common-code/interfaces/session/IClientSessionData';

export const cnWizard = cn('Wizard');

export interface IWizardProps extends IClassNameProps {

}

export const Wizard: React.FC<IWizardProps> = ({className}) => {
    const {t} = useTranslation();
    const [threeKitchenEditor, initThreeKitchenEditor] = useState<KitchenEditor | undefined>();
    const [userControls, initUserControls] = useState<KitchenUserControls | undefined>();
    const [kitchenService, initKitchenService] = useState<KitchenService | undefined>();
    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<HTMLDivElement>(null);
    const appConfig: IAppConfig = useSelector((state: AppState) => state.config);
    const user: IUserData = useSelector((state: AppState) => state.user);
    const kitchenOptions: IWizardOptions = useSelector((state: AppState) => state.wizard.options);
    const [hasWebGLError, setHasWebGLError] = useState(false);
    const urlOptions: TWizardUrlOptions = useWizardUrlOptions();
    const projectData: IProjectData | undefined = useSelector((state: AppState) => state.wizard.project);
    const roomData: ISaveRoomData | undefined = useSelector((state: AppState) => state.wizard.room);
    const [loadError, setLoadError] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const createObjects = useRef<ICreateObjectDomElements>({});
    const replaceData: IReplaceData = useSelector((state: AppState) => state.wizard.replaceData);
    const specData: IUnitSpecData = useSelector((state: AppState) => state.wizard.specData);
    const {mousePosition, grabbingObject} = useMoveCreateObject(kitchenService, createObjects, containerRef, [kitchenService]);
    const session: IClientSessionData | null = useSelector((state: AppState) => state.session);

    useEffect(() => {
        if (kitchenService && kitchenService.isReady() && editorRef.current instanceof HTMLDivElement && containerRef.current instanceof HTMLDivElement) {
            kitchenService.resizeCanvas();
        }
    }, [kitchenService]);

    useEffectDidMount(() => {
        const loadProjectData = (appConfig: IAppConfig, user: IUserData, projectId: string | null, collectionId: string | null): Promise<IProjectData> => {
            return new Promise<IProjectData>((resolve, reject) => {
                if (projectId !== null) {
                    axios.get<Promise<IProjectData>>('/api/project/get/' + projectId, {params: {}})
                        .then((response: AxiosResponse) => {
                            if (response.data) {
                                resolve(response.data);
                            } else {
                                reject();
                            }
                        })
                        .catch(() => {
                            reject();
                        });
                } else {
                    if (!collectionId) {
                        reject();
                    } else {
                        resolve(EditorService.getDefaultProjectData(appConfig, user, collectionId));
                    }
                }
            })
        };
        const loadRoomData = (
            appConfig: IAppConfig,
            user: IUserData,
            projectData: IProjectData,
            urlRoomId: string | null): Promise<ISaveRoomData> => {
            return new Promise<ISaveRoomData>((resolve, reject) => {
                let roomId: string | undefined;

                if (projectData.room !== DEFAULT_ROOM_ID) {
                    roomId = projectData.room;
                }
                if (urlRoomId && urlRoomId !== DEFAULT_ROOM_ID) {
                    roomId = urlRoomId;
                }
                if (roomId) {
                    axios.get<Promise<ISaveRoomData>>(
                        '/api/room/get/' + roomId, {params: {}})
                        .then((response: AxiosResponse) => {
                            if (response.data) {
                                resolve(response.data);
                            } else {
                                reject();
                            }
                        })
                        .catch(() => {
                            reject();
                        });
                } else {
                    resolve(EditorService.getDefaultRoomData());
                }
            })
        };

        loadProjectData(appConfig, user, urlOptions.project, urlOptions.collection)
            .then((projectData: IProjectData) => {
            loadRoomData(appConfig, user, projectData, urlOptions.room).then((roomData) => {
                dispatch({
                    type: CHANGE_PROJECT_DATA,
                    payload: projectData
                });
                dispatch({
                    type: CHANGE_ROOM_DATA,
                    payload: roomData
                });
            })
        }).catch(() => {
            setLoadError(true);
        });
    })

    useEffect(() => {
        if (!threeKitchenEditor) {
            let initOptions: IEditorOptions = {...EDITOR_INIT_OPTIONS};
            if (window.location.hostname.indexOf('localhost') !== -1) {
                initOptions.stats = true;
            }
            initThreeKitchenEditor(new KitchenEditor(window, dispatch, initOptions));
        }

    }, [threeKitchenEditor, dispatch]);

    useEffect(() => {
        if (threeKitchenEditor && !userControls) {
            initUserControls(new KitchenUserControls(threeKitchenEditor));
        }
    }, [threeKitchenEditor, userControls]);

    useEffect(() => {
        if (threeKitchenEditor && userControls && roomData && projectData && session && !kitchenService) {
            if (!projectData.collection) {
                setLoadError(true);
            } else {
                initKitchenService(new KitchenService(
                    threeKitchenEditor,
                    userControls,
                    dispatch,
                    urlOptions,
                    CommonHelper.deepCopy(kitchenOptions),
                    CommonHelper.deepCopy(appConfig),
                    CommonHelper.deepCopy(projectData),
                    session
                ));
            }

        }
    }, [threeKitchenEditor, userControls, appConfig, kitchenService, dispatch,
        urlOptions, kitchenOptions, roomData, projectData, session]);

    useEffect(() => {
        try {
            if (kitchenService &&
                !kitchenService.isReady() &&
                roomData &&
                editorRef.current instanceof HTMLDivElement &&
                containerRef.current instanceof HTMLDivElement) {
                kitchenService.initState(editorRef.current, roomData).then(() => {
                    kitchenService.startRender();
                });
            }
        } catch (e) {
            setHasWebGLError(true);
        }
        return () => {
            if (kitchenService) {
                kitchenService.remove();
                dispatch({
                    type: CHANGE_PROJECT_DATA,
                    payload: undefined
                });
                dispatch({
                    type: CHANGE_ROOM_DATA,
                    payload: undefined
                });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, kitchenService]);

    useEffect(() => {
        let isChangeUrl: boolean = false;
        if (projectData && projectData.id !== DEFAULT_PROJECT_ID &&
            searchParams.get('project') !== projectData.id) {
            searchParams.set('project', projectData.id);
            isChangeUrl = true;
        }
        if (roomData && roomData.id !== DEFAULT_ROOM_ID &&
            searchParams.get('room') !== roomData.id) {
            searchParams.set('room', roomData.id);
            isChangeUrl = true;
        }
        if (isChangeUrl) {
            navigate(location.pathname + '?' + searchParams.toString());

        }

    }, [projectData, roomData, navigate, searchParams, location.pathname]);


    const contextAction: IContextAction = (data: IContextIcon) => {
        if (kitchenService) {
            return kitchenService.applyContextAction(data);
        }
        return false;
    }
    const settingsAction: ISettingsAction = () => {
        // TODO доделать
        return false;
    }

    const onCloseModalReplace = () => {
        dispatch({type: HIDE_REPLACE_DATA});
    }

    const onCloseModalSpecData = () => {
        dispatch({type: HIDE_UNIT_SPEC});
    }

    if (!projectData || !roomData || loadError) {
        return (
            <div className={cnWizard({}, [className])}>
                <div className={cnWizard('LoadingContainer')}>
                    <div className={cnWizard('Loading', {error: loadError})}>
                        {loadError ? t('Ошибка загрузки проекта!') :
                            t('Загружаем проект') + '...'}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Header className={cnPageWizard(cnHeader())}
                    title={t("3D конструктор кухонных гарнитуров 'Народные кухни'") || undefined}
                    size={'small'}
                    service={kitchenService}
            />
            <div className={cnWizard({}, [className])} ref={containerRef}>
                <div className={cnWizard('Editor')} ref={editorRef}>
                    {hasWebGLError && t('При инициализации WebGL произошла ошибка')}
                </div>
                <div className={cnWizard('UI')}>
                    {kitchenService ?
                        <>
                            <WizardSteps service={kitchenService} className={cnWizard('Steps')}/>
                        </> : null
                    }
                    {kitchenService && containerRef.current instanceof HTMLDivElement ?
                        <WizardCurrentStep
                            service={kitchenService}
                            projectData={projectData}
                            roomData={roomData}
                            createObjects={createObjects}
                            containerRef={containerRef}
                            config={appConfig}
                        /> :
                        null
                    }
                    {kitchenService ?
                        <>
                            {/*<WizardEditorMenu  service={kitchenService}/>*/}
                        </> : null
                    }
                    <WizardContextMenu contextAction={contextAction}/>
                    {kitchenService ?
                        <WizardSettingsMenu action={settingsAction} service={kitchenService}/> :
                        null
                    }
                    {kitchenService ? <WizardProjectPrice className={cnWizard('ProjectPrice')} service={kitchenService}/> : null}
                    {kitchenService ? <WizardFlyMenu service={kitchenService} className={cnWizard('FlyMenu')}/> : null}
                    {kitchenService ? <WizardVerticalMenu service={kitchenService} className={cnWizard('VerticalMenu')}/> : null}
                    {kitchenService ? <WizardHorizontalMenu service={kitchenService} className={cnWizard('HorizontalMenu')}/> : null}
                    <WizardMoveObject grabbingObject={grabbingObject} mousePosition={mousePosition}/>
                    {replaceData.visible && kitchenService?
                        <Modal
                            className={cnWizard(cnModal())}
                            visible={replaceData.visible}
                            callBack={onCloseModalReplace}
                            size={'full'}
                        >
                            <WizardReplaceMenu replaceData={replaceData} service={kitchenService} createObjects={createObjects} onClose={onCloseModalReplace}/>
                        </Modal> : null
                    }
                    {specData.visible && kitchenService ?
                        <Modal
                            className={cnWizard(cnModal())}
                            visible={specData.visible}
                            callBack={onCloseModalSpecData}
                            size={'full'}
                        >
                            <WizardUnitSpec specData={specData} service={kitchenService} onClose={onCloseModalSpecData}/>
                        </Modal> : null
                    }
                    {kitchenService ? <WizardAdminMenu service={kitchenService} onClose={() => {}}/> : null}
                </div>
            </div>

        </>
    );
}