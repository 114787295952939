import * as ru from '../../common-code/i18n/ru/translation.json';
import * as en from '../../common-code/i18n/en/translation.json';

export const resources = {
    ru: {
        translation: ru
    },
    en: {
        translation: en
    },
} as const;