import { ThreeCorpus } from "../ThreeCorpus";
import { BoxGeometry, Euler, Mesh, Shape, Vector3 } from "three";
import { ThreeBottomUnitAngleCube } from "../../../units/ThreeBottomUnit/types/ThreeBottomUnitAngleCube";
import { ThreeShelf } from "../../ThreeShelf/ThreeShelf";
import { ISaveBottomAngleCubeCorpusData } from "../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleCubeCorpusData";
import { ISaveShelfData } from "../../../../../../../common-code/interfaces/saveData/ISaveShelfData";
import { CommonHelper } from "common-code";

export class ThreeBottomAngleCubeCorpus extends ThreeCorpus {
  saveData: ISaveBottomAngleCubeCorpusData;
  unit: ThreeBottomUnitAngleCube;
  backTriangleCathetus: number;

  constructor(
    options: ISaveBottomAngleCubeCorpusData,
    unit: ThreeBottomUnitAngleCube
  ) {
    super(options, unit);
    this.saveData = options;
    this.unit = unit;
    this.backTriangleCathetus = 175.44;
  }

  public getSideDepth(): number {
    return +this.saveData.sideDepth;
  }

  public getBackSideDepth(): number {
    return +this.saveData.backSideDepth;
  }

  public getBackGap(): number {
    return (
      this.service.getTabletopWidth() -
      this.service.getTabletopFrontGap() -
      this.getSideDepth()
    );
  }

  public getBackSideAngle90() {
    if (!this.saveData.backSideAngle90) {
      return 60;
    }
    return +this.saveData.backSideAngle90;
  }

  protected createShape() {
    this.shape = new Shape();
    this.shape.moveTo(
      -this.getWidth() / 2 + this.getBackThickness(),
      this.getDepth() / 2 - this.getThickness()
    );
    this.shape.lineTo(
      -this.getWidth() / 2 + this.getBackThickness(),
      -this.getDepth() / 2 + this.backTriangleCathetus
    );
    this.shape.lineTo(
      -this.getWidth() / 2 + this.backTriangleCathetus,
      -this.getDepth() / 2 + this.getBackThickness()
    );
    this.shape.lineTo(
      this.getWidth() / 2 - this.getThickness(),
      -this.getDepth() / 2 + this.getBackThickness()
    );
    this.shape.lineTo(
      this.getWidth() / 2 - this.getThickness(),
      -this.getDepth() / 2 + this.getSideDepth()
    );
    this.shape.lineTo(
      -this.getWidth() / 2 + this.getSideDepth(),
      this.getDepth() / 2 - this.getThickness()
    );
    this.shape.lineTo(
      -this.getWidth() / 2 + this.getBackThickness(),
      this.getDepth() / 2 - this.getThickness()
    );
  }

  protected createShapePanels() {
    let bottom: Mesh;

    bottom = this.createShapePanel();
    bottom.position.y = -this.getHeight() / 2 + this.getThickness() / 2;
    bottom.position.z = this.getBackThickness() / 2;
    bottom.name = "bottom";
    this.view3d.add(bottom);
  }

  protected createPanels() {
    let width;
    let back3Gap;

    width = this.getSideDepth() - this.getBackThickness();
    this.createPanel(
      new BoxGeometry(width, this.getHeight(), this.getThickness()),
      "left",
      new Vector3(
        -this.getWidth() / 2 + width / 2 + this.getBackThickness(),
        0,
        this.getDepth() / 2 - this.getThickness() / 2
      )
    );
    this.createPanel(
      new BoxGeometry(this.getThickness(), this.getHeight(), width),
      "right",
      new Vector3(
        this.getWidth() / 2 - this.getThickness() / 2,
        0,
        -this.getDepth() / 2 + this.getSideDepth() / 2 + this.getBackThickness()
      )
    );
    width = Math.sqrt(2 * Math.pow(this.getBackSideDepth(), 2));
    back3Gap = Math.sqrt(
      Math.pow(
        Math.sqrt(2 * Math.pow(this.getBackSideDepth(), 2)) / 2 +
          this.getThickness() / 2,
        2
      ) / 2
    );

    if (this.getBackSideAngle90() !== 60) {
      this.createPanel(
        new BoxGeometry(
          this.getThickness(),
          this.getHeight() - this.getThickness(),
          this.getBackSideAngle90()
        ),
        "backPanel1",
        new Vector3(
          -this.getWidth() / 2 +
            this.getBackSideAngle90() +
            this.getThickness() / 2,
          this.getThickness() / 2,
          -this.getDepth() / 2 + this.getBackSideAngle90() / 2
        )
      );
      this.createPanel(
        new BoxGeometry(
          this.getBackSideAngle90(),
          this.getHeight() - this.getThickness(),
          this.getThickness()
        ),
        "backPanel2",
        new Vector3(
          -this.getWidth() / 2 + this.getBackSideAngle90() / 2,
          this.getThickness() / 2,
          -this.getDepth() / 2 +
            this.getBackSideAngle90() -
            this.getThickness() / 2
        )
      );
      this.createPanel(
        new BoxGeometry(
          this.getThickness(),
          this.getBackSideAngle90(),
          this.getDepth() - this.getBackSideAngle90() - this.getThickness()
        ),
        "backPanelLeftTop",
        new Vector3(
          -this.getWidth() / 2 + this.getThickness() / 2,
          this.getHeight() / 2 - this.getBackSideAngle90() / 2,
          this.getBackSideAngle90() / 2 - this.getThickness() / 2
        )
      );
      this.createPanel(
        new BoxGeometry(
          this.getThickness(),
          this.getBackSideAngle90(),
          this.getDepth() - this.getBackSideAngle90() - this.getThickness()
        ),
        "backPanelLeftBottom",
        new Vector3(
          -this.getWidth() / 2 + this.getThickness() / 2,
          -this.getHeight() / 2 +
            this.getBackSideAngle90() / 2 +
            this.getThickness(),
          this.getBackSideAngle90() / 2 - this.getThickness() / 2
        )
      );
      this.createPanel(
        new BoxGeometry(
          this.getWidth() - this.getBackSideAngle90() - this.getThickness() * 2,
          this.getBackSideAngle90(),
          this.getThickness()
        ),
        "backPanelRightTop",
        new Vector3(
          this.getBackSideAngle90() / 2,
          this.getHeight() / 2 - this.getBackSideAngle90() / 2,
          -this.getDepth() / 2 + this.getThickness() / 2
        )
      );
      this.createPanel(
        new BoxGeometry(
          this.getWidth() - this.getBackSideAngle90() - this.getThickness() * 2,
          this.getBackSideAngle90(),
          this.getThickness()
        ),
        "backPanelRightBottom",
        new Vector3(
          this.getBackSideAngle90() / 2,
          -this.getHeight() / 2 +
            this.getBackSideAngle90() / 2 +
            this.getThickness(),
          -this.getDepth() / 2 + this.getThickness() / 2
        )
      );
    } else {
      const backPanelCatheterGap = this.backTriangleCathetus / 2 + this.getThickness() / 2;
      this.createPanel(
        new BoxGeometry(
          Math.sqrt(2 * Math.pow(this.backTriangleCathetus, 2)),
          this.getHeight() - this.getThickness(),
          this.getThickness()
        ),
        "backPanel",
        new Vector3(
          -this.getWidth() / 2 + backPanelCatheterGap,
          this.getThickness() / 2,
          -this.getDepth() / 2 + backPanelCatheterGap
        ),
        new Euler(0, 0.25 * Math.PI, 0)
      );
    }
  }

  protected createBackPanels() {
    if (this.getBackThickness() !== 0) {
      let width;
      width = this.getWidth() - this.backTriangleCathetus;
      this.createPanel(
        new BoxGeometry(width, this.getHeight(), this.getBackThickness()),
        "back1",
        new Vector3(
          this.getWidth() / 2 - width / 2,
          0,
          -this.getDepth() / 2 + this.getBackThickness()
        )
      );
      this.createPanel(
        new BoxGeometry(this.getBackThickness(), this.getHeight(), width),
        "back2",
        new Vector3(
          -this.getWidth() / 2 + this.getBackThickness() / 2,
          0,
          this.getDepth() / 2 - width / 2
        )
      );
    }
  }

  protected createShelves() {
    let shelfData: ISaveShelfData;
    let shelf: ThreeShelf;
    for (shelfData of this.saveData.shelves) {
      shelf = new ThreeShelf(
        CommonHelper.deepCopy(shelfData),
        this,
        this.shape
      );
      shelf.initState();
      shelf.createView();
      this.shelves.push(shelf);
    }
  }
}