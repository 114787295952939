import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {CSSProperties} from "react";
import './Svg.css';

export const cnSvg = cn('Svg');

export interface ISvgProps extends IClassNameProps {
    icon: string;
    label?: string;
    style?: CSSProperties;
}

export const Svg: React.FC<ISvgProps> = ({className, icon, label, style}) => {
    return (
        <svg
            className={cnSvg({}, [className])}
            aria-label={label}
            style={style}
        >
            <use xlinkHref={`#${icon}`}/>
        </svg>
    );
};