import {ThreePenalUnit} from '../ThreePenalUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {
    ThreePenalFullLengthSidewallCorpus
} from "../../../details/ThreeCorpus/types/ThreePenalFullLengthSidewallCorpus";
import {ISavePenalUnitData} from '../../../../../../../common-code/interfaces/saveData/ISavePenalUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreePenalUnitFullLengthSidewall extends ThreePenalUnit {
    corpus: ThreePenalFullLengthSidewallCorpus;

    constructor(options: ISavePenalUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        // this.view3d.userData.transparentAngle = -0.4;
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreePenalFullLengthSidewallCorpus {
        return new ThreePenalFullLengthSidewallCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}