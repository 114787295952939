import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Value from "./Value";
import { legsPosition } from "../../constants/constants";
import { cnCreateForm } from "../../FormModule";
import { Button } from "../../../../../components/Button";
import { CLASSNAME_SIDE_BOTTOM_FACADE_UNIT, CLASSNAME_SIDE_TOP_FACADE_UNIT, OPTION_TYPE_JSON } from "common-code/lib/constants";
import { IOptionHiddenJson } from "../../../../../../../common-code/interfaces/option/IOptionHiddenJson";
import { IOption } from "common-code/lib/interfaces/option/IOption";
import ModalValues from "./ModalValues";
import { initialValue, IParamsValue, IValue } from "./initialValue";
import { IFormInput } from "../../initialValue";
import { validateValue } from "./validateValue";
import { createValue } from "./createValue";
type TkeyValue = "legs" | "aprons" | "corners" | "plinths";

function Values({ name, keyValue, classNameValue }: { name: string; keyValue: TkeyValue, classNameValue: string }) {
  const { setValue, getValues } = useFormContext<IFormInput>();
  
  const isFacade = classNameValue === CLASSNAME_SIDE_BOTTOM_FACADE_UNIT || classNameValue === CLASSNAME_SIDE_TOP_FACADE_UNIT;
  
  // массив начальных значений
  const valueInitional =
    keyValue === "legs"
      ? (getValues(keyValue) as IOption)?.value.map(
          (el: { initPosition: { x: string; z: string } }) => ({
            ...el,
            positionType: Object.keys(legsPosition).find(
              (key) =>
                legsPosition[key]?.x === el.initPosition?.x &&
                legsPosition[key]?.z === el.initPosition?.z
            ),
          })
        )
      : (getValues(keyValue) as IOption)?.value;
  
  const [arrValues, setArrValues] = React.useState<IValue[]>(
    valueInitional && !isFacade ? valueInitional : []
  );

  const [paramsValue, setParamsValue] = React.useState<IParamsValue>(initialValue(null));

  const element: IOption | null =
    arrValues?.length > 0
      ? ({
          id: keyValue,
          type: OPTION_TYPE_JSON,
          title: name,
          sort: keyValue === "legs" ? 6 : 4,
          value:
            keyValue === "legs"
              ? arrValues?.map((el) => {
                  const { positionType, ...rest } = el;
                  return { ...rest, initPosition: legsPosition[positionType] };
                })
              : arrValues,
        } as IOptionHiddenJson)
      : null;

  useEffect(() => {
    setValue(keyValue, element);
    // eslint-disable-next-line
  }, [arrValues]);
 

  // добавление значения
  const handleSubmit = () => {
    const error = validateValue(paramsValue, arrValues);

    if (error) {
      setParamsValue({ ...paramsValue, error: error });
      return;
    }

    const valueNew = createValue(paramsValue);
    console.log("valueNew", valueNew);
    
    setArrValues([...arrValues, valueNew]);
    setParamsValue({ ...paramsValue, modal: false, positionType: "" });
  };

  // переключение модального окна
  const handleToggleModal = () => {
    setParamsValue({ ...paramsValue, modal: !paramsValue.modal });
  };

  return (
    <>
      <h4 className={cnCreateForm("Header")}>{`${name}`}</h4>

      {arrValues?.map((value, index) => (
        <Value
          arrValues={arrValues}
          setArrValues={setArrValues}
          name={name}
          value={value}
          index={index}
          key={`${value.positionType}
          ${value.initSizes?.length}
          ${value.initPosition?.x}
          ${value.initPosition?.z}${index}`}
          keyValue={keyValue}
        />
      ))}

      <Button
        className={cnCreateForm("Button")}
        type={"button"}
        text={`Добавить элемент ${name}`}
        leftIcon={{ path: "plus2", width: 16, height: 16, marginRight: 8 }}
        mods={{
          mods: {
            bkg: "white",
            color: "lightGreen",
            border: "lightGreen",
            fill: "lightGreen",
          },
          hover: { mods: { bkg: "lightGreen", color: "white", fill: "white" } },
        }}
        callBack={handleToggleModal}
      />

      <hr />

      <ModalValues
        handleToggleModal={handleToggleModal}
        handleSubmit={handleSubmit}
        paramsValue={paramsValue}
        setParamsValue={setParamsValue}
        name={name}
        keyValue={`adding${keyValue}`}
      />
    </>
  );
}

export default Values;
