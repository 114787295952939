import React, { useEffect, useState } from 'react'
import { cnCreateForm } from '../../../FormModule';
import { Tumbler } from '../../../../../../components/Tumbler/Tumbler';
import { ISaveHingeData } from 'common-code/lib/interfaces/saveData/ISaveHingeData';

function InputCheckbox({hinge, setHinge, code, setError} :
    {hinge: ISaveHingeData, setHinge: (hinge:ISaveHingeData) => void, code: string[], setError: (str: string) => void}) {

    const [value, setValue] = useState(false);

    const onChecked = (e:React.ChangeEvent<HTMLInputElement>) => {
        setError("");    
        if(e.target.checked){
            setHinge({ ...hinge, availableKitCodes: [...(hinge.availableKitCodes as string[]), e.target.name] });
            setValue(true);
        } else {
            setHinge({ ...hinge, availableKitCodes: (hinge.availableKitCodes as string[]).filter(el => el !== e.target.name) });
            setValue(false);
        }
    };

    useEffect(()=>{
        if((hinge.availableKitCodes as string[]).length === 0){
            setValue(false);
        }
        
    },[hinge])

  return (  
    <Tumbler
        className={cnCreateForm('Tumbler')}
        checked={value}
        callBack={(e) => onChecked(e)}
        label={code[1]}
        name={code[0]}
    />
  )
}

export default InputCheckbox