import React, {useRef, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import './Wizard-ProjectPrice.css'
import {cn} from '@bem-react/classname';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../redux/AppStore';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {IProjectData} from '../../../../../common-code/interfaces/project/IProjectData';
import {CurrencyHelper} from '../../../../../domain/CurrencyHelper/CurrencyHelper';
import {useTranslation} from 'react-i18next';
import {Svg} from '../../../components/Svg';
import {Popup} from '../../../components/Popup';
import {WizardProjectPriceTable} from './Table/WizardProjectPriceTable';

export const cnWizardProjectPrice = cn('WizardProjectPrice');
export interface IWizardProjectPriceProps extends IClassNameProps {
    service: KitchenService;
}

export const WizardProjectPrice: React.FC<IWizardProjectPriceProps> = ({className, service}) => {
    const loadingPrice: boolean = useSelector((state: AppState) => state.ui.loadingPrice);
    const projectData: IProjectData = useSelector((state: AppState) => state.wizard.project);
    const {t} = useTranslation();
    const [showPricePopup, setShowPricePopup] = useState<boolean>(false);
    const switcherElement = useRef<HTMLDivElement>(null);

    return (
        <div className={cnWizardProjectPrice({}, [className])}>
            <div className={cnWizardProjectPrice('Container')}>
                <div className={cnWizardProjectPrice('Info')}>
                    {(!loadingPrice && projectData) ?
                        <div className={cnWizardProjectPrice('PriceItem', {header: true})}>
                            <p className={cnWizardProjectPrice('PriceTitle', {header: true})}>{t('Итого')}:</p>
                            <p className={cnWizardProjectPrice('PriceValue', {header: true})}>{CurrencyHelper.formatValue(projectData.priceData.full)}</p>
                        </div> : null
                    }
                </div>
                <div className={cnWizardProjectPrice('Switcher')} onClick={() => {setShowPricePopup(!showPricePopup)}}
                     ref={switcherElement}
                >
                    <Svg
                        className={cnWizardProjectPrice('Icon', {active: showPricePopup})}
                        icon={'arrowDown'}
                        label={t('Подробнее') || undefined}
                        style={{width: `${16}px`, height: `${16}px`}}
                    />
                </div>
                <Popup
                    active={showPricePopup}
                    className={cnWizardProjectPrice('More')}
                    position={{x: -1, y: 50}}
                    setShowPopup={setShowPricePopup}
                    ignoreElements={[switcherElement]}
                >
                    <WizardProjectPriceTable service={service}/>
                </Popup>

                <div className={cnWizardProjectPrice('Error')}>
                    {t('При расчете проекта произошла ошибка!')}
                </div>
                <div className={cnWizardProjectPrice('inOrder')}>
                    {t('При расчете проекта произошла ошибка!')}
                </div>
            </div>
        </div>
    );
};