import {configureStore} from "@reduxjs/toolkit";
import {ConfigReducer} from './reducers/ConfigReducer';
import {LocationReducer} from './reducers/LocationReducer';
import {SessionReducer} from './reducers/SessionReducer';
import {UIReducer} from './reducers/UIReducer';
import {UserReducer} from './reducers/UserReducer';
import {WizardReducer} from './reducers/WizardReducer';

export const appStore = configureStore({
    reducer: {
        config: ConfigReducer,
        location: LocationReducer,
        session: SessionReducer,
        ui: UIReducer,
        user: UserReducer,
        wizard: WizardReducer,
    },
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //         immutableCheck: false,
    //         serializableCheck: false,
    //     }),
});

export type AppState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;
