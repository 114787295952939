import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {useState} from "react";
import './PageCollectionDetail.css';
import {useLocation} from "react-router-dom";
import axios from "axios";
import {useEffectDidMount} from "../../hooks/useEffectDidMount";
import {ICollectionDto} from "../../../common-code/interfaces/ICollectionDto";
import {cnHeader, Header} from "../../ui/layouts/Header";
import {useTranslation} from "react-i18next";
import {CollectionDetail} from '../../ui/elements/CollectionDetail/CollectionDetail';
import {cnPage} from '../../main/App/Content/App-Content';

export const cnPageCollectionDetail = cn('PageCollectionDetail');

export interface IPageCollectionDetailProps extends IClassNameProps {

}

export const PageCollectionDetail: React.FC<IPageCollectionDetailProps> = ({className}) => {
    const location = useLocation();
    const [collection, setCollection] = useState<ICollectionDto | undefined>(undefined);
    const {t} = useTranslation();

    useEffectDidMount(() => {

        // получаем список свойств выбранной коллекции
        axios.get('/api/collection/' + location.pathname.replace('/collection/', ''),)
            .then((response) => {
                if (response.data) {
                    setCollection(response.data);
                }
            })
            .catch(() => {
            });
    });

    return (
        <div className={cnPageCollectionDetail({}, [className])}>
            <Header className={cnPageCollectionDetail(cnHeader())}
                    title={collection ? collection.name : (t("Страница коллекции модульных гарнитуров") || undefined)} />
            <div className={cnPage('Wrapper')}>
                <div className={cnPage('Sections')}>
                    <CollectionDetail collection={collection} />
                </div>
            </div>

        </div>
    );
}