import {cn} from '@bem-react/classname';
import React, {RefObject, useRef} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {ISaveRoomData} from '../../../../common-code/interfaces/saveData/ISaveRoomData';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {IProjectData} from '../../../../common-code/interfaces/project/IProjectData';
import {useTranslation} from 'react-i18next';
import {Accordion} from '../../components/Accordion';
import {cnAccordion} from '../../components/Accordion';
import {RoomParamsSizes} from './Sizes/RoomParams-Sizes';
import "./RoomParams.css";
import {RoomParamsConstructiveList} from './ConstructiveList/RoomParams-ConstructiveList';
import {ICreateGroup} from '../../../../common-code/interfaces/createData/ICreateGroup';
import {useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {ICreateObjectDomElements} from '../../../interfaces/ICreateObjectDomElements';
import useMoveCreateObject from '../../../hooks/useMoveCreateObject';
import {WizardMoveObject} from '../Wizard/MoveObject/Wizard-MoveObject';
import {RoomParamsDecor} from './Decor/RoomParams-Decor';
import {IMaterialData} from '../../../../common-code/interfaces/materials/IMaterialData';

export const cnRoomParams = cn('RoomParams');
export interface IRoomParamsProps extends IClassNameProps {
    roomData: ISaveRoomData;
    service: KitchenService;
    projectData: IProjectData;
    containerRef: RefObject<HTMLDivElement>;
}

export const RoomParams: React.FC<IRoomParamsProps> =
    ({
         roomData,
         service,
         containerRef,
        className
     }) => {
        const {t} = useTranslation();
        const domConstructiveList = useRef<ICreateObjectDomElements>({});
        const constructiveList: ICreateGroup[] | undefined = useSelector((state: AppState) => state.wizard.constructiveList);
        const {
            mousePosition,
            grabbingObject
        } = useMoveCreateObject(service, domConstructiveList, containerRef, [service]);

        const floors: IMaterialData[] | undefined = useSelector((state: AppState) => state.wizard.floors);
        const selectFloor: IMaterialData | undefined = useSelector((state: AppState) => state.wizard.selectFloor);
        const onSelectFloor = (data: IMaterialData) => {
            service.setFloor(data, true);
        }

        const walls: IMaterialData[] | undefined = useSelector((state: AppState) => state.wizard.walls);
        const selectWall: IMaterialData | undefined = useSelector((state: AppState) => state.wizard.selectWall);
        const onSelectWall = (data: IMaterialData) => {
            service.setWall(data, undefined, true);
        }

        return (
            <div className={cnRoomParams({}, [className])}>
                <Accordion
                    className={cnRoomParams(cnAccordion())}
                    text={t('Размеры помещения')}
                    initActive={true}
                >
                    <RoomParamsSizes roomData={roomData} service={service}/>
                </Accordion>
                <Accordion
                    className={cnRoomParams(cnAccordion())}
                    text={t('Конструктивные элементы')}
                    initActive={false}
                >
                    {constructiveList ?
                        <RoomParamsConstructiveList
                            service={service}
                            constructiveList={constructiveList}
                                    createObjectDomElements={domConstructiveList}/> :
                        <div className={cnRoomParams('Note')}>{t('Загрузка данных')}...</div>
                    }
                </Accordion>
                <Accordion
                    className={cnRoomParams(cnAccordion())}
                    text={t('Материал стен')}
                    initActive={false}
                >
                    {walls ?
                        <RoomParamsDecor
                            objectList={walls}
                            selectObject={selectWall}
                            onSelectObject={onSelectWall}/> :
                        <div className={cnRoomParams('Note')}>{t('Загрузка данных')}...</div>
                    }
                </Accordion>
                <Accordion
                    className={cnRoomParams(cnAccordion())}
                    text={t('Материал пола')}
                    initActive={false}
                >
                    {floors ?
                        <RoomParamsDecor
                            objectList={floors}
                            selectObject={selectFloor}
                            onSelectObject={onSelectFloor}/> :
                        <div className={cnRoomParams('Note')}>{t('Загрузка данных')}...</div>
                    }
                </Accordion>
                <WizardMoveObject grabbingObject={grabbingObject} mousePosition={mousePosition}/>
            </div>
        );
    };