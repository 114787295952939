import {IClassNameProps} from "@bem-react/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {cnObjectSelectItem} from '../ObjectSelectItem';


export interface ICreateSelectObjectLengthsProps extends IClassNameProps {
    lengths?: number[];
}

export const ObjectSelectItemLengths: React.FC<ICreateSelectObjectLengthsProps> =
    ({
         className,
         lengths,
    }) => {
        const {t} = useTranslation();

    if (!lengths) {
        return null;
    }
    return (
        <div
            className={cnObjectSelectItem('Lengths', [className])}
        >
            {t('Длина')}: {lengths.map(value => {
                return value + t('мм') + '; ';
        })}
        </div>
    );
}