import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {Tabs} from '../../components/Tabs/Tabs';
import {ITabsMenuTabProps} from '@yandex/ui/TabsMenu/Tab/TabsMenu-Tab';
import {UserObjectSelectList} from './List/UserObjectSelect-List';
import {useTranslation} from 'react-i18next';
import {ITabsPanesPaneProps} from '@yandex/ui/TabsPanes/Pane/TabsPanes-Pane';
import {UserObjectSelectAdd} from './Add/UserObjectSelect-Add';
import {IProjectUserData} from '../../../../common-code/interfaces/project/IProjectUserData';
import {ORDER_USER_TYPE_CHOICE, ORDER_USER_TYPE_NEW} from '../../../../common-code/constants';
import {TProjectUserType} from '../../../../common-code/types/TProjectUserType';

export const cnUserObjectSelect = cn('UserObjectSelect')

export interface IUserObjectSelectProps extends IClassNameProps {
    userData: IProjectUserData;
    customerType: TProjectUserType;
    errors?: any;
    setCustomerType: (type: TProjectUserType) => void;
    newUserData: IProjectUserData;
    setUserData: (userData: IProjectUserData) => void;
    setNewUserData: (userData: IProjectUserData) => void;
}

export const UserObjectSelect: React.FC<IUserObjectSelectProps> =
    ({
         className,
         customerType,
         setCustomerType,
         userData,
         setUserData,
         newUserData,
         setNewUserData,
         errors,
     }) => {
        const {t} = useTranslation();

        const tabs: ITabsMenuTabProps[] = [
            {id: ORDER_USER_TYPE_CHOICE, content: t('Выбрать')},
            {id: ORDER_USER_TYPE_NEW, content: t('Добавить')},
        ];
        const tabsContent: ITabsPanesPaneProps[] = [
            {
                id: ORDER_USER_TYPE_CHOICE,
                content: <UserObjectSelectList userData={userData}
                                               setUserData={setUserData}
                                               errors={errors}
                />
            },
            {
                id: ORDER_USER_TYPE_NEW,
                content: <UserObjectSelectAdd userData={newUserData}
                                              setUserData={setNewUserData}
                                              errors={errors}
                />
            },
        ];
        return (
            <div className={cnUserObjectSelect({}, [className])}>
                <Tabs tabs={tabs}
                      tabsContent={tabsContent}
                      initActiveTab={customerType}
                      className={cnUserObjectSelect('Tabs')}
                      callbackSetActiveTab={(value: string) => {
                          setCustomerType(value as TProjectUserType);
                      }}
                />
            </div>
        );
    }