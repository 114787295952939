import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React from "react";
import './Breadcrumbs.css';
import {Link} from "../Link/Link";
import {LINK_TYPE_NAV} from "../../constants";
import {cnLink} from "../Link";
import {IBreadcrumbs} from "../../../interfaces/IBreadcrumbs";
import {Svg} from "../Svg";

export const cnBreadcrumbs = cn('Breadcrumbs');

export interface IBreadcrumbsProps extends IClassNameProps {
    breadcrumbs: IBreadcrumbs[];
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({className, breadcrumbs}) => {
    return (
        <div className={cnBreadcrumbs({}, [className])}>
            <Link
                className={cnBreadcrumbs(cnLink())}
                type={LINK_TYPE_NAV}
                href={'/'}
                icon={{path: 'home', width: 20, height: 20}}
                mods={{mods: {fill: 'lightBlack'}}}
            />
            {breadcrumbs.map((breadcrumb, index) => {
                return (
                    <div
                        key={index}
                        className={cnBreadcrumbs('Item')}
                    >
                        <Svg
                            className={cnBreadcrumbs('Icon')}
                            icon={'arrowRightThin'}
                            label={breadcrumb.text}
                            style={{width: `${24}px`, height: `${20}px`}}
                        />
                        {index === breadcrumbs.length - 1 ?
                            <p className={cnBreadcrumbs('Text')}>{breadcrumb.text}</p> :
                            <Link
                                className={cnBreadcrumbs(cnLink())}
                                type={LINK_TYPE_NAV}
                                href={breadcrumb.href}
                                text={breadcrumb.text}
                                mods={{mods: {color: 'red'}}}
                            />
                        }
                    </div>
                );
            })}
        </div>
    );
}