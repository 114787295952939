import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {useMemo, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {SET_USER, START_SESSION, TOGGLE_MENU} from "../../../constants";
import {ILinkTypeNav} from "../../../interfaces/ILinkTypeNav";
import {LINK_TYPE_NAV} from "../../constants";
import {useTranslation} from "react-i18next";
import {Link} from "../../components/Link/Link";
import {cnLink} from "../../components/Link";
import './UserMenu.css';
import {useComponentVisible} from "../../../hooks/useComponentVisible";
import {cnAuth} from "../Auth";
import axios from 'axios';
import {IUserData} from '../../../../common-code/interfaces/IUserData';
import {IClientSessionData} from '../../../../common-code/interfaces/session/IClientSessionData';

export const cnUserMenu = cn('UserMenu');

export interface IUserMenuProps extends IClassNameProps {

}

export const UserMenu: React.FC<IUserMenuProps> = ({className}) => {
    const session: IClientSessionData | null = useSelector((state: AppState) => state.session);
    const currentUser: IUserData = useSelector((state: AppState) => state.user);
    const isMenuOpen = useSelector((state: AppState) => state.ui.UserMenu.visible);
    const {ref} = useComponentVisible('UserMenu', [cnAuth()]);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const hideMenu = useCallback(() => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: 'UserMenu',
                value: false
            }
        });
    }, [dispatch]);

    const processLogout = useCallback(() => {
        if (!session || !session.seanceId) {
            return;
        }
        axios.post('/auth/logout', {sid: session.seanceId})
            .then((response: any) => {
                if (response.data) {
                    dispatch({
                        type: SET_USER,
                        payload: undefined,
                    });
                    dispatch({
                        type: START_SESSION,
                        payload: {success: true},
                    });
                } else {
                    debugger;
                }
            })
            .catch(() => {
                debugger;
            });
    }, [session, dispatch]);

    const userMenuItems: ILinkTypeNav[] = useMemo(() => (
        [
            {
                type: LINK_TYPE_NAV,
                href: '/projects',
                text: t('Мои проекты')
            } as ILinkTypeNav,
            {
                type: LINK_TYPE_NAV,
                href: '/logout',
                text: t('Выход'),
                callback: () => {
                    hideMenu();
                    processLogout();
                }
            } as ILinkTypeNav
        ]
    ), [hideMenu, t, processLogout]);

    if (!session) {
        return null;
    }

    return (
        <div ref={ref} className={cnUserMenu({visible: isMenuOpen}, [className])}>
            <div className={cnUserMenu('Login')}>
                <span className={cnUserMenu('LoginTitle')}>{currentUser.name} </span>
                <span className={cnUserMenu('LoginValue')}>{currentUser.lastName}</span>
            </div>
            {userMenuItems.map((menuItem, index) => {
                return (
                    <div
                        key={index}
                        className={cnUserMenu('Item')}
                    >
                        <Link
                            className={cnUserMenu(cnLink())}
                            type={menuItem.type}
                            href={menuItem.href}
                            text={menuItem.text}
                            mods={{mods: {color: 'lightBlack'}}}
                            onClick={'callback' in menuItem ? menuItem.callback : hideMenu}
                        />
                    </div>
                );
            })}
        </div>
    );
};