import React, {useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import {Svg} from '../Svg';
import {cnModal, Modal} from '../Modal';
import './ImageZoom.css';

export const cnImageZoom = cn('ImageZoom');
export interface IImageZoomProps extends IClassNameProps {
    src: string;
    title?: string;
}
export const ImageZoom: React.FC<IImageZoomProps> = ({src, title, className}) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            <div className={cnImageZoom({}, [className])} onClick={() => {setShowModal(!showModal)}}>
                <Svg icon={'search2'} className={cnImageZoom('Icon')} />
            </div>
            {!showModal || <Modal
                className={cnImageZoom(cnModal())}
                visible={showModal}
                callBack={setShowModal}
                size={'full'}
            >
                <div className={cnImageZoom('ImageContainer')}>
                    <img src={src} className={cnImageZoom('Image')}  alt={title}/>
                </div>
            </Modal>}
        </>
    );
}