import {UserControls} from '../UserControls/UserControls';
import {KitchenEditor} from '../../editors/KitchenEditor/KitchenEditor';
import {KitchenService} from '../../services/KitchenService/KitchenService';
import {DEFAULT_CLEAR_COLOR} from '../../constants';

export class KitchenUserControls extends UserControls {
    editor: KitchenEditor;

    constructor(editor: KitchenEditor) {
        super(editor);
        this.editor = editor;
    }

    public onKeyDown(event: KeyboardEvent) {
        super.onKeyDown(event);
        const service: KitchenService | undefined = this.editor.getService();
        let isApply: boolean = false;

        if (event.ctrlKey) {
            switch (event.code) {
                case 'KeyQ':
                    event.preventDefault();
                    if (service) {
                        if (event.shiftKey) {
                            service.showRoom();
                            service.getEditor().setClearColor(DEFAULT_CLEAR_COLOR);
                        } else {
                            service.hideRoom();
                            service.getEditor().setClearColor(0xffffff);
                        }
                    }
                    break;
                case 'KeyK':
                    if (event.shiftKey) {
                        service?.onStartVisualModulesScreen();
                    }
                    break;
                case 'KeyZ':
                    event.preventDefault();
                    if (event.shiftKey) {
                        service?.historyRedo();
                    } else {
                        service?.historyUndo();
                    }
                    break;
                default:
                    console.log('key code ' + event.code);
            }
        }
        switch (event.code) {
            case "Delete":
                service?.tryDeleteSelectObject();
                break;
            case "Escape":
                service?.clearSelectCovers();
                service?.hideMenus();
                break;
            case "Tab":
                const step: number = event.shiftKey ? 5 : 1;
                isApply = service?.trySetSelfVerticalRotationSelectObject(step, true) || false;
                break;
        }
        if (isApply) {
            event.preventDefault();
            return false;
        }
    }
}