import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {cnCollectionDetail} from '../CollectionDetail';
import {ICollectionDto} from '../../../../../common-code/interfaces/ICollectionDto';
import {useTranslation} from 'react-i18next';
import {Property} from '../../../components/Property/Property';

export interface ICollectionDetailPropertiesProps extends IClassNameProps {
    collection: ICollectionDto;
}
export const CollectionDetailProperties: React.FC<ICollectionDetailPropertiesProps> = ({collection}) => {
    const {t} = useTranslation();
    
    return (
        <div className={cnCollectionDetail('Section')}>
            <div className={cnCollectionDetail('Subtitle')}>{t('Характеристики')}:</div>
            <div className={cnCollectionDetail('Properties')}>
                {collection.properties.map((property) => {
                    return <Property key={property.code} property={property}/>;
                })}
            </div>

        </div>
    );
}