import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {TOptionType} from '../../../../common-code/types/TOptionType';
import {useTranslation} from 'react-i18next';
import {IOption} from '../../../../common-code/interfaces/option/IOption';
import {IOptionGroup} from '../../../../common-code/interfaces/option/IOptionGroup';
import {ICreateObjectDisableSelects} from '../../../../common-code/interfaces/createData/ICreateObjectDisableSelects';
import {ObjectOption} from '../../components/ObjectOption/ObjectOption';
import {CommonObjectHelper} from '../../../3d/helpers/CommonObjectHelper/CommonObjectHelper';
import {cnObjectOption} from '../../components/ObjectOption';

export const cnCreateOption = cn('CreateOption');
export interface ICreateOptionProps extends IClassNameProps {
    type: TOptionType;
    option: IOption;
    group?: IOptionGroup;
    onChange: (fieldId: string, value: any, groupId?: string) => void;
    createOptions: any;
    disableSelects?: ICreateObjectDisableSelects

}

export const CreateOption: React.FC<ICreateOptionProps> =
    ({
         className,
         option,
         onChange,
         group,
         createOptions,
         disableSelects
    }) => {
        const {t} = useTranslation();
        const groupDefaultValue = (group && createOptions[group.id][option.id]) ?
            (option.i18n ? t(createOptions[group.id][option.id]) : createOptions[group.id][option.id]): undefined;
        const defaultValue = createOptions[option.id] ? (option.i18n ? t(createOptions[option.id]) : createOptions[option.id]) : undefined;

        return (
            <ObjectOption
                type={option.type}
                option={CommonObjectHelper.prepareCreateOption(
                    option,
                    disableSelects && disableSelects[option.id] ? disableSelects[option.id] : undefined
                )}
                group={group}
                className={cnCreateOption(cnObjectOption(), [className])}
                onChange={onChange}
                defaultValue={groupDefaultValue || defaultValue}
            />
        );
}