import React from "react";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { Controller, useFormContext } from "react-hook-form";
import { cnCreateForm } from "../FormModule";
import { useTranslation } from "react-i18next";

interface IInputFormProps {
  label: string;
  name: string;
  onFocus: undefined | (() => void);
  required: boolean;
}

function InputForm({ label, name, onFocus, required }: IInputFormProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <div>
      <label>{t(label)}</label>
      <Controller
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value } }) => (
          <Textinput
            className={cnCreateForm("Textinput")}
            placeholder={t(label)}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
          />
        )}
        name={name}
      />
      {errors[name]?.type === "required" && (
        <p className="text-danger">{t("Необходимо заполнить поле")}</p>
      )}
    </div>
  );
}

export default InputForm;
