import React from "react";
import { cnCreateForm } from "../../../FormModule";
import { Select } from "../../../../../../components/Select/Select";
import { editOption } from "../../../../utils/editOption";
import { facadeMaterials } from "../../../constants/constants";
import { Tumbler } from "../../../../../../components/Tumbler/Tumbler";
import { IFacade } from "../initialFacade";

function FacadeFor({
  facadeNew,
  onInputChange,
  onChecked,
}: {
  facadeNew: IFacade;
  onInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { facadeFor, facadeMaterial } = facadeNew;

  return (
    <>
      <Tumbler
        className={cnCreateForm("Tumbler")}
        checked={facadeFor}
        callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
        label="Указать дополнительный материал фасада"
        name="facadeFor"
      />

      {facadeFor && (
        <>
          <label htmlFor="facadeMaterial">
            Небходимо выбрать дополнительный материал фасада
          </label>
          <Select
            className={cnCreateForm("Select")}
            placeholder={"Выберите значение"}
            showPlaceholder={false}
            options={editOption(facadeMaterials)}
            value={facadeMaterial}
            callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
              onInputChange({
                ...e,
                target: { ...e.target, name: "facadeMaterial" },
              })
            }
          />
        </>
      )}
    </>
  );
}

export default FacadeFor;
