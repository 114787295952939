import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {cnCollectionDetail} from '../CollectionDetail';
import {ICollectionDto} from '../../../../../common-code/interfaces/ICollectionDto';
import {useTranslation} from 'react-i18next';
import {CollectionDetailProperties} from '../Properties/CollectionDetail-Properties';

export interface ICollectionDetailDescriptionProps extends IClassNameProps {
    collection: ICollectionDto;
}
export const CollectionDetailDescription: React.FC<ICollectionDetailDescriptionProps> = ({collection}) => {
    const {t} = useTranslation();

    return (
        <div className={cnCollectionDetail('Description', [cnCollectionDetail('Section')])}>
            {collection.closed ? <div className={cnCollectionDetail('Notes', {color: 'red'})}>
                {t('Внимание! Коллекция снята с производства!')}
            </div> : null}
            {collection.byOrder ? <div className={cnCollectionDetail('Notes', {color: 'red'})}>
                {t('Коллекция только под заказ')}.
            </div> : null}
            <CollectionDetailProperties collection={collection}/>
            <div className={cnCollectionDetail('Section')}>
                <div className={cnCollectionDetail('Subtitle')}>
                    {t('Описание')}:
                </div>
                <div>
                    {collection.description}
                </div>
            </div>

        </div>
    );
}