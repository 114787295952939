import React, { useState} from 'react';
import {IObjectSelectProps} from '../ObjectSelect';
import {IClassNameProps, withBemMod} from '@bem-react/core';
import {cnObjectSelect} from '../index';
import {useTranslation} from "react-i18next";
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';
import {ObjectSelectSelect} from '../Select/ObjectSelect-Select';
import {OBJECT_SELECT_TYPE_CORPUS_MATERIAL} from '../../../../constants';
import {Modal} from '../../Modal';
import {AccessoryList, cnAccessoryList} from '../../../elements/AccessoryList/AccessoryList';

const ObjectSelectCorpusMaterial: React.FC<IObjectSelectProps<IMaterialData>> =
    ({
         data,
         list,
         title,
         onSelectObject,
         className,
        showObject,
         facadeMaterials
     }) => {
        const {t} = useTranslation();

        const emptyObjectData: IMaterialData = {
            id: 'none',
            title: t('Не выбрано'),
        };
        const objectData: IMaterialData = data && data.object ? data.object : emptyObjectData;
        const [showModal, setShowModal] = useState<boolean>(false);

        return (
            <div className={cnObjectSelect({}, [className])}>
                <ObjectSelectSelect
                    className={cnObjectSelect('Select')}
                    image={objectData.image ? {path: objectData.image, label: objectData.title, width: 60, height: 60} : undefined}
                    color={!objectData.image && objectData.color ? {path: objectData.color, label: objectData.title, width: 60, height: 60} : undefined}
                    icon={!objectData.image && !objectData.color ? {path: 'no-photo', label: objectData.title, width: 60, height: 60} : undefined}
                    title={title}
                    description={objectData.title}
                    callBack={() => {
                        setShowModal(true);
                    }}
                />
                {showModal ?
                    <Modal
                        className={cnObjectSelect('Modal')}
                        visible={showModal}
                        callBack={setShowModal}
                        size={'full'}
                    >
                        {!list || !onSelectObject ?
                            <div className={cnObjectSelect('Error')}>{t('Загрузка данных')}...</div> :
                            <AccessoryList
                                className={cnObjectSelect(cnAccessoryList())}
                                listMaterialData={list}
                                title={title}
                                materialData={objectData}
                                onSelectObject={onSelectObject}
                                showObject={showObject}
                                facadeMaterials={facadeMaterials}
                                setShowModal={setShowModal}
                            />
                        }
                    </Modal> : null
                }
            </div>
        );
    }

export const withObjectSelectCorpusMaterial = withBemMod<IObjectSelectProps<IMaterialData>, IClassNameProps>(
    cnObjectSelect(),
        { type: OBJECT_SELECT_TYPE_CORPUS_MATERIAL },
        () => ObjectSelectCorpusMaterial
);