import React from "react";
import { cnCreateForm } from "../../../FormModule";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { IFacade } from "../initialFacade";

function FacadeInitSizes({
  onInputChange,
  facadeNew,
}: {
  onInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  facadeNew: IFacade;
}) {
  const { initSizesWidth, initSizesHeight } = facadeNew;
  return (
    <>
      <h4 className={cnCreateForm("Title")}>Начальные размеры</h4>

      <small>
        * Для указания размера в процентах перед вводом значения указать %
        (например %100), для указания размера в мм указать число (например 200)
      </small>

      <div className={cnCreateForm("Box-Input")}>
        <div>
          <label htmlFor="initSizesWidth">Ширина фасада</label>
          <Textinput
            className={cnCreateForm("Textinput")}
            value={initSizesWidth}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e)
            }
            name="initSizesWidth"
          />
        </div>
        <div>
          <label htmlFor="initSizesHeight">Высота фасада</label>
          <Textinput
            className={cnCreateForm("Textinput")}
            value={initSizesHeight}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onInputChange(e)
            }
            name="initSizesHeight"
          />
        </div>
      </div>
    </>
  );
}

export default FacadeInitSizes;
