import {composeU, IClassNameProps} from '@bem-react/core';
import React from 'react';
import './ObjectSelect.css'
import {cnObjectSelect} from './index';
import {withObjectSelectCorpusMaterial} from './_type/ObjectSelect_type_corpusMaterial';
import {withObjectSelectTabletop} from './_type/ObjectSelect_type_tabletop';
import {withObjectSelectApron} from './_type/ObjectSelect_type_apron';
import {withObjectSelectCorner} from './_type/ObjectSelect_type_corner';
import {withObjectSelectPlinth} from './_type/ObjectSelect_type_plinth';
import {withObjectSelectFacadeMaterial} from "./_type/ObjectSelect_type_facadeMaterial";
import {TObjectSelectType} from '../../../types/TObjectSelectType';
import {IObjectSelectData} from '../../../interfaces/IObjectSelectData';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {IMaterialData} from '../../../../common-code/interfaces/materials/IMaterialData';
import {IFacadeMaterialData} from '../../../../common-code/interfaces/materials/IFacadeMaterialData';

export interface IObjectSelectProps<T> extends IClassNameProps {
    type?: TObjectSelectType;
    data?: IObjectSelectData<T>;
    list?: Array<T>;
    title?: string;
    onSelectObject?: (data: IMaterialData) => void;
    onChangeDepth?: (material: IMaterialData, value: number) => void;
    showObject?: boolean;
    onClickGroup?: () => void;
    service?: KitchenService;
    facadeMaterials?: IFacadeMaterialData[];
}


export const ObjectSelectBase: React.FC<IObjectSelectProps<IMaterialData>> =
    ({className, type}) => {
    return (
        <div className={cnObjectSelect({}, [className])}>
            Replace ObjectSelect {type}
        </div>
    );
};

export const ObjectSelect = composeU(
    withObjectSelectFacadeMaterial,
    withObjectSelectCorpusMaterial,
    withObjectSelectTabletop,
    withObjectSelectApron,
    withObjectSelectCorner,
    withObjectSelectPlinth
)(ObjectSelectBase);