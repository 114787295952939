
import { Button, cnButton } from "../../../components/Button";
import { ICreateGroup } from "common-code/lib/interfaces/createData/ICreateGroup";
import { cnModal, Modal } from "../../../components/Modal";
import { Select } from '../../../components/Select/Select';
import { useState } from "react";
import * as unitListServices from "../api/UnitListServices";
import { useDispatch } from "react-redux";
import { HIDE_LOADING, SHOW_LOADING } from "../../../../constants";
import { cn } from "@bem-react/classname";
import "./ExportGroupModules.css";
import { useUrlSearchParam } from "../../../../hooks/useSearchParams";

export const cnExportGroupModules = cn("ExportGroupModules");

const colectionsOptions = [
    {value:"28", content: "InteriorCenter28"},
    {value: "29", content: "InteriorCenter29"},
    {value: "40", content: "azbukaMebeli40"},
    {value: "7", content: "dsv7"},
    {value: "42", content: "gola42"},
    {value: "43", content: "Берта43"},
];

const ExportGroupModules = ({ groupData } : { groupData: ICreateGroup }) => {
    const dispatch = useDispatch();
    const currentIdCollection = useUrlSearchParam('collection')

    const [modal, setModal] = useState(false);
    const [collectionId, setCollectionId] = useState("");
    const [isExportedModules, setIsExportedModules] = useState(false);

    // запись коллекции модулей в файл
    const saveUnitList = async (unitListNew: ICreateGroup[]) =>
        await unitListServices
            .saveUnitList(unitListNew as ICreateGroup[], collectionId as string)
            .then((data: ICreateGroup[] | undefined) => {
                setIsExportedModules(true);
                dispatch({ type: HIDE_LOADING });
            })
            .catch(() => {
                alert( "Произошла ошибка при сохранении коллекции модулей. Попробуйте еще раз.");
                dispatch({ type: HIDE_LOADING });
            });
    // экспорт модулей
    const exportModules = async () => {
        dispatch({ type: SHOW_LOADING });
        // получение данных для выбранной коллекции модулей
        const dataGroupsModules = await unitListServices.initUnitList(collectionId);

        if (!dataGroupsModules) {
            alert("Произошла ошибка при экспорте модулей. Попробуйте еще раз.");
            dispatch({ type: HIDE_LOADING });
            return;
        }
        // проверка на повторяющиеся модули
        const currentGroup = dataGroupsModules.find((group)=> group.id === groupData.id);
        const hasRepeatingModule = currentGroup?.items?.find((item) => groupData.items.some((item2) => item.uid === item2.uid));
        if (hasRepeatingModule) {
            alert(`Обнаружен повторяющийся модуль. Укажите другой код по каталогу для модуля.  
            Наименование модуля: ${hasRepeatingModule.title}. 
            Код по каталогу: ${hasRepeatingModule.catalogCode}.`);
            dispatch({ type: HIDE_LOADING });
            return;
        }
        // добавление модулей
        const groupsModules = currentGroup ? 
            dataGroupsModules.map((group) => group.id === groupData.id ? 
            {...group, items: [...group.items, ...groupData.items]} : group) 
            : [...dataGroupsModules, groupData]
        // запись в файл
        saveUnitList(groupsModules);
    }
    // открытие/закрытие модального окна
    const handleToggleModal = () => {
        setModal(!modal);
        setIsExportedModules(false);
    }
    
  return (
    <>
    <Button
      className={cnExportGroupModules(cnButton())}
      type={"button"}
      text={"Эксп. группы модулей в другую коллекцию"}
      leftIcon={{ path: "plus2", width: 16, height: 16, marginRight: 8 }}
      mods={{
        mods: { bkg: "white", color: "lightGreen", border: "lightGreen", fill: "lightGreen"},
        hover: { mods: { bkg: "lightGreen", color: "white", fill: "white" } },
      }}
      callBack={handleToggleModal}
    />
        <Modal
            className={cnExportGroupModules(cnModal())}
            visible={modal}
            callBack={() => setModal(!modal)}
            key={"#exportGroupModules"}
        >

                <h4 className={cnExportGroupModules('Header')}>Экспорт группы модулей</h4>

                <div className={cnExportGroupModules('Content')}>
                    { isExportedModules ? <h2 className={cnExportGroupModules('Success-Message')}>Модули успешно экспортированы!</h2>  :
                        <>
                            <h4 className={cnExportGroupModules('Title')}>Коллекция назначения</h4>
                            <label htmlFor="functionalType">Небходимо выбрать коллекцию куда будут экспортированы модули</label>
                            <Select
                                className={cnExportGroupModules('Select')}
                                placeholder={"Выберите значение"}
                                showPlaceholder={false}
                                options={colectionsOptions.filter((el) => el.value !== currentIdCollection)}
                                value={collectionId}
                                callBack={(e) => setCollectionId(e.target.value) }
                            />  
                
                            {collectionId && <Button
                                className={cnExportGroupModules(cnButton())}
                                type={"button"}
                                text={"Начать экспорт"}
                                mods={{
                                    mods: { bkg: "white", color: "lightGreen", border: "lightGreen", fill: "lightGreen"},
                                    hover: { mods: { bkg: "lightGreen", color: "white", fill: "white" } },
                                }}
                                callBack={exportModules}
                            />}
                        </> 
                    }
                </div>
        </Modal>

    </>
  );
};

export default ExportGroupModules;
