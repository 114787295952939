import {ThreeTopUnit} from '../ThreeTopUnit';
import {ThreeTopCorpus} from '../../../details/ThreeCorpus/types/ThreeTopCorpus';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ISaveTopUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitNormal extends ThreeTopUnit {
    corpus: ThreeTopCorpus;

    constructor(options: ISaveTopUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeTopCorpus {
        return new ThreeTopCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}