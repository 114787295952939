import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import './Wizard-FlyMenu.css'
import {cn} from '@bem-react/classname';
import {Svg} from '../../../components/Svg';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../redux/AppStore';

export const cnWizardFlyMenu = cn('WizardFlyMenu');
export interface IWizardFlyMenuProps extends IClassNameProps {
    service: KitchenService;
}

export const WizardFlyMenu: React.FC<IWizardFlyMenuProps> = ({className, service}) => {
    // const offset: TElementOffset = {top: 120, left: 395, right: 15, bottom: 60};
    // const [start, setStart] = useState<undefined>(undefined);
    // const [position, setPosition] = useState<TElementPosition | undefined>(undefined);
    const undoActive: boolean = useSelector((state: AppState) => state.ui.history.undo);
    const redoActive: boolean = useSelector((state: AppState) => state.ui.history.redo);

    const onHistoryUndo = () => {
        if (undoActive) {
            service.historyUndo();
        }
    }
    const onHistoryRedo = () => {
        if (redoActive) {
            service.historyRedo();
        }
    }
    return (
        <div className={cnWizardFlyMenu({}, [className])}>
            <div className={cnWizardFlyMenu('Content')}>
                <div className={cnWizardFlyMenu('Drug')}>
                    <Svg icon={'move'} className={cnWizardFlyMenu('DrugIcon')}/>
                </div>
                <div className={cnWizardFlyMenu('Actions')}>
                    <div className={cnWizardFlyMenu('Action', {type: 'history', active: undoActive})}
                         onClick={onHistoryUndo}>
                        <Svg icon={'undo'}
                             className={cnWizardFlyMenu('Icon', {type: 'history', active: undoActive})}/>
                    </div>
                    <div className={cnWizardFlyMenu('Action', {type: 'history', active: redoActive})}
                         onClick={onHistoryRedo}>
                        <Svg icon={'redo'}
                             className={cnWizardFlyMenu('Icon', {type: 'history', active: redoActive})}/>
                    </div>
                </div>
            </div>
        </div>
    );
};