import React from "react";
import { cnCreateForm } from "../../../FormModule";
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { Tumbler } from "../../../../../../components/Tumbler/Tumbler";
import { IFacade } from "../initialFacade";

function FacadeGaps({
  facadeNew,
  onInputChange,
  onChecked,
}: {
  facadeNew: IFacade;
  onInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { gap, gapBottom, gapTop, gapLeft, gapRight } = facadeNew;
  return (
    <>
      <hr />
      <h4 className={cnCreateForm("Title")}>Зазоры</h4>

      <Tumbler
        className={cnCreateForm("Tumbler")}
        checked={gap}
        callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
        label="Добавить зазоры для фасада"
        name="gap"
      />

      {gap && (
        <div className={cnCreateForm("Box-Input")}>
          <div>
            <label htmlFor="gapBottom">Зазор снизу</label>
            <Textinput
              className={cnCreateForm("Textinput")}
              value={gapBottom}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="gapBottom"
              type="number"
            />
          </div>
          <div>
            <label htmlFor="gapLeft">Зазор слева</label>
            <Textinput
              className={cnCreateForm("Textinput")}
              value={gapLeft}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="gapLeft"
              type="number"
            />
          </div>
          <div>
            <label htmlFor="gapRight">Зазор справа</label>
            <Textinput
              className={cnCreateForm("Textinput")}
              value={gapRight}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="gapRight"
              type="number"
            />
          </div>
          <div>
            <label htmlFor="gapTop">Зазор сверху</label>
            <Textinput
              className={cnCreateForm("Textinput")}
              value={gapTop}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onInputChange(e)
              }
              name="gapTop"
              type="number"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default FacadeGaps;
