import {Reducer} from "redux";
import {i18n} from '../../i18n';
import {IWizardOptions} from '../../interfaces/IWizardOptions';
import {
    ADD_MESSAGE,
    CHANGE_ALL_CORPUS_MATERIALS,
    CHANGE_ALL_CORPUSES,
    CHANGE_ALL_FACADE_MATERIALS,
    CHANGE_APRONS,
    CHANGE_BOTTOM_FACADE_MATERIAL,
    CHANGE_BOTTOM_HANDLE,
    CHANGE_CONSTRUCTIVE_LIST,
    SHOW_CONTEXT_MENU,
    CHANGE_CORNERS,
    CHANGE_CORPUS_MATERIALS,
    CHANGE_FACADE_MATERIALS,
    CHANGE_FACADES,
    CHANGE_FLOOR,
    CHANGE_HANDLES,
    CHANGE_KIT_CODES,
    CHANGE_KITCHEN_CHECK_ERRORS_OBJECTS,
    CHANGE_KITCHEN_ENABLE_AUTO_SERVICES,
    CHANGE_KITCHEN_ENABLE_SERVICES,
    CHANGE_KITCHEN_EURO_ZAPIL,
    CHANGE_KITCHEN_NEED_SAVE,
    CHANGE_KITCHEN_SHOW_APRONS,
    CHANGE_KITCHEN_SHOW_CORNERS,
    CHANGE_KITCHEN_SHOW_PLINTHS,
    CHANGE_KITCHEN_SHOW_TABLETOPS,
    CHANGE_KITCHEN_SIZES_TYPE,
    CHANGE_KITCHEN_VIEW,
    CHANGE_MANUFACTURERS,
    CHANGE_PLINTHS,
    CHANGE_PROJECT_DATA,
    CHANGE_REPLACE_DATA,
    CHANGE_ROOM_DATA,
    CHANGE_ROOM_VISIBLE,
    CHANGE_SELECT_APRON,
    CHANGE_SELECT_CORNER,
    CHANGE_SELECT_CORPUS_MATERIAL,
    CHANGE_SELECT_FLOOR,
    CHANGE_SELECT_KIT_CODE,
    CHANGE_SELECT_PLINTH,
    CHANGE_SELECT_TABLETOP,
    CHANGE_SELECT_WALL,
    CHANGE_SERVICES,
    SHOW_SETTINGS_MENU,
    CHANGE_STEPS,
    CHANGE_TABLETOPS,
    CHANGE_TECHNOLOG_MAP,
    CHANGE_TOP_FACADE_MATERIAL,
    CHANGE_TOP_HANDLE,
    CHANGE_UNIT_LIST,
    CHANGE_UNIT_SPEC,
    CHANGE_WALL,
    HIDE_CONTEXT_MENU,
    HIDE_REPLACE_DATA,
    HIDE_SETTINGS_MENU,
    HIDE_UNIT_SPEC,
    KITCHEN_SIZES_TYPE_NONE,
    KITCHEN_VIEW_VISUAL,
    LOADED_PROJECT,
    LOADED_TEXTURES,
    REMOVE_MESSAGE,
    WIZARD_DEFAULT_CONTEXT_MENU,
    WIZARD_DEFAULT_REPLACE_DATA,
    WIZARD_DEFAULT_SETTINGS_MENU,
    WIZARD_DEFAULT_SPEC_DATA,
    CHANGE_KITCHEN_SHOW_LEGS,
    CHANGE_MATERIALS,
    CHANGE_INTEGRATED_HANDLES,
    CHANGE_SELECT_INTEGRATED_HANDLE,
    CHANGE_HINGES_FURNITURE_TYPES,
    CHANGE_FACADES_FUNCTION_TYPES,
    CHANGE_CATALOG_CODES,
    CHANGE_EDIT_MODE,
    CHANGE_ITEM_MODULE,
    CHANGE_ITEM_MATERIAL,
    CHANGE_ITEM_FACADE,
    CHANGE_ITEM_PLINTH,
    CHANGE_ITEM_HANDLE,
    INIT_EDIT_MATERIAL,
    CHANGE_ITEM_FACADE_MATERIAL,
    CHANGE_MATERIALS_MODE,
    INIT_EDIT_MODULE,
    CHANGE_PROJECT_PRICE_DATA,
    CHANGE_PROJECT_EXTRA_OFFERS_DATA,
    CHANGE_PROJECT_SERVICES_DATA,
    CHANGE_SELECT_GLASS, CHANGE_GLASSES
} from '../../constants';
import {TMessage} from '../../types/TMessage';


export const defaultSteps = [
    {
        id: 'room',
        sort: 1,
        title: i18n.t('План помещения'),
        icon: 'room'
    },
    {
        id: 'kitchen',
        sort: 2,
        title: i18n.t('Параметры кухни'),
        icon: 'kitchen'
    },
    {
        id: 'modules',
        sort: 3,
        title: i18n.t('Сборка'),
        icon: 'modules'
    },
    {
        id: 'accessories',
        sort: 4,
        title: i18n.t('Комплектующие'),
        icon: 'accessories'
    },
    {
        id: 'appliances',
        sort: 5,
        title: i18n.t('Бытовая техника'),
        icon: 'appliances'
    },
    {
        id: 'services',
        sort: 6,
        title: i18n.t('Услуги'),
        icon: 'services'
    },
    {
        id: 'spec',
        sort: 7,
        title: i18n.t('Спецификация'),
        icon: 'spec'
    },
    {
        id: 'buy',
        sort: 8,
        title: i18n.t('Заказ'),
        icon: 'buy'
    }
];
export const initState: IWizardOptions = {
    steps: defaultSteps,
    room: undefined,
    project: undefined,
    loadedProject: 0,
    loadedTextures: 0,
    contextMenu: WIZARD_DEFAULT_CONTEXT_MENU,
    settingsMenu: WIZARD_DEFAULT_SETTINGS_MENU,
    replaceData: WIZARD_DEFAULT_REPLACE_DATA,
    specData: WIZARD_DEFAULT_SPEC_DATA,
    options: {
        viewType: KITCHEN_VIEW_VISUAL,
        sizesType: KITCHEN_SIZES_TYPE_NONE,
        showTabletops: true,
        showAprons: true,
        showCorners: true,
        showPlinths: true,
        showIntegratedHandles: true,
        euroZapil: false,
        enableServices: true,
        enableAutoServices: true,
        roomVisible: true,
        needSave: false,
        checkPriceErrors: true,
        showLegs: true,
    },
    messages: [],
    editModule: {
        isEditMode: "",
        itemModule: null,
    },
    editMaterial: {
        itemMaterial: null,
        itemFacade: null,
        itemFacadeMaterial: null,
        itemPlinth: null,
        materialsMode: "",
    }
};
export const WizardReducer: Reducer = (state: IWizardOptions = initState, action) => {
    switch (action.type) {
        case LOADED_PROJECT:
            return {...state, loadedProject: +new Date()};
        case LOADED_TEXTURES:
            return {...state, loadedTextures: +new Date()};
        case CHANGE_ROOM_DATA:
            return {...state, room: action.payload};
        case CHANGE_PROJECT_DATA:
            return {...state, project: action.payload};
        case CHANGE_PROJECT_PRICE_DATA:
            return {...state, project: {...state.project, priceData: action.payload}};
        case CHANGE_PROJECT_EXTRA_OFFERS_DATA:
            return {...state, project: {...state.project, extraOffers: action.payload}};
        case CHANGE_PROJECT_SERVICES_DATA:
            return {...state, project: {...state.project, services: action.payload}};
        case SHOW_CONTEXT_MENU:
            return {...state, contextMenu: action.payload};
        case HIDE_CONTEXT_MENU:
            return {...state, contextMenu: WIZARD_DEFAULT_CONTEXT_MENU};
        case HIDE_SETTINGS_MENU:
            return {...state, settingsMenu: WIZARD_DEFAULT_SETTINGS_MENU};
        case SHOW_SETTINGS_MENU:
            return {...state, settingsMenu: action.payload};
        case CHANGE_REPLACE_DATA:
            return {...state, replaceData: action.payload};
        case CHANGE_UNIT_SPEC:
            return {...state, specData: action.payload};
        case HIDE_REPLACE_DATA:
            return {...state, replaceData: WIZARD_DEFAULT_REPLACE_DATA};
        case HIDE_UNIT_SPEC:
            return {...state, specData: WIZARD_DEFAULT_SPEC_DATA};
        case CHANGE_UNIT_LIST:
            return {...state, unitList: action.payload};
        case CHANGE_CONSTRUCTIVE_LIST:
            return {...state, constructiveList: action.payload};
        case CHANGE_KITCHEN_VIEW:
            return {...state, options: {...state.options, viewType: action.payload}};
        case CHANGE_KITCHEN_SIZES_TYPE:
            return {...state, options: {...state.options, sizesType: action.payload}};
        case CHANGE_KITCHEN_EURO_ZAPIL:
            return {...state, options: {...state.options, euroZapil: action.payload}};
        case CHANGE_KITCHEN_SHOW_TABLETOPS:
            return {...state, options: {...state.options, showTabletops: action.payload}};
        case CHANGE_KITCHEN_SHOW_APRONS:
            return {...state, options: {...state.options, showAprons: action.payload}};
        case CHANGE_KITCHEN_SHOW_CORNERS:
            return {...state, options: {...state.options, showCorners: action.payload}};
        case CHANGE_KITCHEN_SHOW_PLINTHS:
            return {...state, options: {...state.options, showPlinths: action.payload}};
        case CHANGE_KITCHEN_SHOW_LEGS:
            return {...state, options: {...state.options, showLegs: action.payload}};
        case CHANGE_KITCHEN_ENABLE_SERVICES:
            return {...state, options: {...state.options, enableServices: action.payload}};
        case CHANGE_KITCHEN_ENABLE_AUTO_SERVICES:
            return {...state, options: {...state.options, enableAutoServices: action.payload}};
        case CHANGE_KITCHEN_NEED_SAVE:
            return {...state, options: {...state.options, needSave: action.payload}};
        case CHANGE_KITCHEN_CHECK_ERRORS_OBJECTS:
            return {...state, options: {...state.options, checkPriceErrors: action.payload}};
        case CHANGE_ROOM_VISIBLE:
            return {...state, options: {...state.options, roomVisible: action.payload}};
        case CHANGE_TECHNOLOG_MAP:
            return {...state, technologMaps: action.payload};
        case CHANGE_TABLETOPS:
            return {...state, tabletops: action.payload};
        case CHANGE_SELECT_TABLETOP:
            return {...state, selectTabletop: action.payload};
        case CHANGE_APRONS:
            return {...state, aprons: action.payload};
        case CHANGE_SELECT_APRON:
            return {...state, selectApron: action.payload};
        case CHANGE_CORNERS:
            return {...state, corners: action.payload};
        case CHANGE_SELECT_CORNER:
            return {...state, selectCorner: action.payload};
        case CHANGE_INTEGRATED_HANDLES:
            return {...state, integratedHandles: action.payload};
        case CHANGE_SELECT_INTEGRATED_HANDLE:
            return {...state, selectIntegratedHandle: action.payload};
        case CHANGE_PLINTHS:
            return {...state, plinths: action.payload};
        case CHANGE_SELECT_PLINTH:
            return {...state, selectPlinth: action.payload};
        case CHANGE_FLOOR:
            return {...state, floors: action.payload};
        case CHANGE_SELECT_FLOOR:
            return {...state, selectFloor: action.payload};
        case CHANGE_WALL:
            return {...state, walls: action.payload};
        case CHANGE_SELECT_WALL:
            return {...state, selectWall: action.payload};
        case CHANGE_FACADES:
            return {...state, facades: action.payload};
        case CHANGE_HANDLES:
            return {...state, handles: action.payload};
        case CHANGE_SERVICES:
            return {...state, services: action.payload};
        case CHANGE_KIT_CODES:
            return {...state, kitCodes: action.payload};
        case CHANGE_SELECT_KIT_CODE:
            return {...state, selectKitCode: action.payload};
        case CHANGE_TOP_HANDLE:
            return {...state, topHandle: action.payload};
        case CHANGE_BOTTOM_HANDLE:
            return {...state, bottomHandle: action.payload};
        case CHANGE_CORPUS_MATERIALS:
            return {...state, corpusMaterials: action.payload};
        case CHANGE_GLASSES:
            return {...state, glasses: action.payload};
        case CHANGE_MATERIALS:
            return {...state, materials: action.payload};
        case CHANGE_ALL_CORPUS_MATERIALS:
            return {...state, allCorpusMaterials: action.payload};
        case CHANGE_SELECT_CORPUS_MATERIAL:
            return {...state, selectCorpusMaterial: action.payload};
        case CHANGE_SELECT_GLASS:
            return {...state, selectGlass: action.payload};
        case CHANGE_FACADE_MATERIALS:
            return {...state, facadeMaterials: action.payload};
        case CHANGE_ALL_FACADE_MATERIALS:
            return {...state, allFacadeMaterials: action.payload};
        case CHANGE_MANUFACTURERS:
            return {...state, manufacturers: action.payload};
        case CHANGE_ALL_CORPUSES:
            return {...state, corpuses: action.payload};
        case CHANGE_BOTTOM_FACADE_MATERIAL:
            return {...state, bottomFacadeMaterial: action.payload};
        case CHANGE_TOP_FACADE_MATERIAL:
            return {...state, topFacadeMaterial: action.payload};
        case ADD_MESSAGE:
            return {...state, messages: state.messages.concat([action.payload])};
        case REMOVE_MESSAGE:
            return {
                ...state,
                messages: state.messages.filter((message: TMessage) => action.payload !== message.params?.id)
            };
        case CHANGE_STEPS:
            return {...state, steps: action.payload};
        case CHANGE_HINGES_FURNITURE_TYPES:
            return {...state, editModule: {...state.editModule, hingesFurnTypes: action.payload}};
        case CHANGE_FACADES_FUNCTION_TYPES: 
            return {...state, editModule: {...state.editModule, facadesFuncTypes: action.payload}};
        case CHANGE_CATALOG_CODES: 
            return {...state, editModule: { ...state.editModule, catalogCodes: action.payload}};
        case CHANGE_EDIT_MODE:
            return {...state, editModule: { ...state.editModule, isEditMode: action.payload}};
        case CHANGE_ITEM_MODULE:  
            return {...state, editModule: { ...state.editModule, itemModule: action.payload}};
        case CHANGE_MATERIALS_MODE:
            return {...state, editMaterial: { ...state.editMaterial, materialsMode: action.payload}};
        case CHANGE_ITEM_MATERIAL:
            return {...state, editMaterial: { ...state.editMaterial, itemMaterial: action.payload}};
        case CHANGE_ITEM_FACADE:
            return {...state, editMaterial: { ...state.editMaterial, itemFacade: action.payload}};
        case CHANGE_ITEM_FACADE_MATERIAL:
            return {...state, editMaterial: { ...state.editMaterial, itemFacadeMaterial: action.payload}}; 
        case CHANGE_ITEM_PLINTH:
            return {...state, editMaterial: { ...state.editMaterial, itemPlinth: action.payload}};
        case CHANGE_ITEM_HANDLE:
            return {...state, editMaterial: { ...state.editMaterial, itemHandle: action.payload}};
        case INIT_EDIT_MATERIAL:
            return {...state, editMaterial: initState.editMaterial};
        case INIT_EDIT_MODULE:
            return {...state, editModule: initState.editModule};


        default:
            return state;
    }
};