import React from "react";
import { alignValues } from "../../../constants/constants";
import { Select } from "../../../../../../components/Select/Select";
import { cnCreateForm } from "../../../FormModule";
import { editOption } from "../../../../utils/editOption";
import { Tumbler } from "../../../../../../components/Tumbler/Tumbler";
import { IFacade } from "../initialFacade";

function FacadeAligns({
  facadeNew,
  onInputChange,
  onChecked,
}: {
  facadeNew: IFacade;
  onInputChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  onChecked: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { align, alignX, alignY } = facadeNew;  
  return (
    <>
      <hr />
      <h4 className={cnCreateForm("Title")}>Выравнивание</h4>

      <Tumbler
        className={cnCreateForm("Tumbler")}
        checked={align}
        callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
        label="Добавить выравнивание"
        name="align"
      />

      {align && (
        <div className={cnCreateForm("Box-Input")}>
          <div style={{ width: "50%" }}>
            <label htmlFor="alignX">По горизонтали</label>
            <Select
              className={cnCreateForm("Select")}
              placeholder={"Выберите значение"}
              showPlaceholder={false}
              options={editOption(alignValues).filter(el => el.value === "left" || el.value === "right")}
              value={alignX}
              callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                onInputChange({ ...e, target: { ...e.target, name: "alignX" } })
              }
            />
          </div>

          <div style={{ width: "50%" }}>
            <label htmlFor="alignY">По вертикали</label>
            <Select
              className={cnCreateForm("Select")}
              placeholder={"Выберите значение"}
              showPlaceholder={false}
              options={editOption(alignValues).filter(el => el.value === "top" || el.value === "bottom")}
              value={alignY}
              callBack={(e: React.ChangeEvent<HTMLSelectElement>) =>
                onInputChange({ ...e, target: { ...e.target, name: "alignY" } })
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

export default FacadeAligns;
