import {CommonObject} from '../../../CommonObject/CommonObject';
import {ThreeRoom} from '../ThreeRoom/ThreeRoom';
import {
    BackSide,
    BufferGeometry,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    Shape,
    ShapeGeometry,
    Vector2
} from 'three';
import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {ThreeRoomPoint} from '../../../../points/ThreeRoomPoint/ThreeRoomPoint';
import {IMaterialTextures} from '../../../../interfaces/IMaterialTextures';
import {ISaveFloorData} from '../../../../../../common-code/interfaces/saveData/ISaveFloorData';
import {IMaterialData} from '../../../../../../common-code/interfaces/materials/IMaterialData';
import {ISaveWallData} from '../../../../../../common-code/interfaces/saveData/ISaveWallData';
import {CommonHelper} from 'common-code';
import {PLANE_TYPE_FLOOR} from '../../../../../constants';

export class ThreeFloor extends CommonObject {
    saveData: ISaveFloorData;
    room: ThreeRoom;
    shape?: Shape;
    body?: Mesh;
    material?: MeshStandardMaterial;
    service: KitchenService;
    materialData: IMaterialData;
    materialTextures: IMaterialTextures;

    constructor(options: ISaveFloorData, room: ThreeRoom) {
        super(options, room.getService());
        this.saveData = options;
        this.service = room.getService();
        this.room = room;
        this.hasCover = false;
        this.materialData = this.initMaterialData(options.materialId);
        this.materialTextures = this.loadTextures();
    }

    public createView(isRebuild?: boolean) {
        this.createBody();
        super.createView(isRebuild);
    }

    public getData(): ISaveWallData {
        let data: ISaveWallData = CommonHelper.deepCopy(super.getData());
        data.materialId = this.materialData.id;
        return data;
    }

    public trySetMaterial(material: IMaterialData) {
        if (!this.body) {
            return;
        }
        this.materialData = this.initMaterialData(material.id);
        this.materialTextures = this.loadTextures();
        this.material = undefined;
        this.body.material = this.getBodyMaterial();
        this.body.material.needsUpdate = true;
    }

    public removeChildren() {
        this.body = undefined;
        super.removeChildren();
    }

    protected loadTextures(): IMaterialTextures {
        return this.service.loadMaterialTextures(this.materialData.id, this.materialData.textures);
    }

    protected initMaterialData(materialId?: string): IMaterialData {
        return this.service.getFloorMaterial(materialId);
    }

    private createBody() {
        this.body = new Mesh(this.getBodyGeometry(), this.getBodyMaterial());
        this.body.userData.type = PLANE_TYPE_FLOOR;
        this.body.userData.sketchMaterial = new MeshBasicMaterial({color: '#e7e7e7', side: BackSide});
        this.body.receiveShadow = true;
        this.body.rotation.x = Math.PI/2;
        this.body.matrixAutoUpdate = false;
        this.body.name = 'floor'
        this.view3d.add(this.body);
    }

    private getBodyMaterial(): MeshStandardMaterial {
        if (!this.material) {
            this.material = new MeshStandardMaterial({
                color: this.materialData.color || '#ffffff',
                emissive: this.materialData.emissiveColor || '',
                emissiveIntensity: 0.1,
                side: BackSide,
                map: this.materialTextures.texture || null,
                normalMap: this.materialTextures.normal || null,
                roughnessMap: this.materialTextures.roughness || null,
            }
            );
        }

        return this.material;
    }
    private getBodyGeometry(): BufferGeometry {
        let geometry: BufferGeometry = new BufferGeometry();

        this.createShape();
        if (this.shape) {
            geometry = new ShapeGeometry(this.shape);
        }

        return geometry;
    }

    private createShape() {
        let points: Vector2[];
        let point: ThreeRoomPoint;

        points = [];
        for (point of this.room.getPoints()) {
            points.push(new Vector2(point.value.x, point.value.z));
        }
        this.shape = new Shape(points);
    }
}