import React, {useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import {AppContent} from "./Content/App-Content";
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../redux/AppStore';
import {useEffectDidMount} from '../../hooks/useEffectDidMount';
import {CHANGE_CONFIG, SET_LOCATION, SET_LOCATIONS, SET_USER, START_SESSION} from '../../constants';
import {cn} from '@bem-react/classname';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {IAppConfig} from '../../../common-code/interfaces/config/IAppConfig';
import {LoadingApiData} from "../../ui/helpers/LoadingApiData/LoadingApiData";
import {theme} from '@yandex/ui/Theme/presets/default'
import {configureRootTheme} from '@yandex/ui/Theme';
import {TLocationData} from "../../../common-code/types/TLocationData";

import './App.css';
import './../../ui/components/Textinput/Textinput.css';
import './../../ui/components/Textarea/Textarea.css';
import {IClientSessionData} from '../../../common-code/interfaces/session/IClientSessionData';


export const cnApp = cn('App');

configureRootTheme({theme});

export const App = () => {
    const [configError, setConfigError] = useState<boolean>(false);
    const [currentLocationError, setCurrentLocationError] = useState<boolean>(false);
    const config: IAppConfig | null = useSelector((state: AppState) => state.config);
    const currentLocation: TLocationData | undefined = useSelector((state: AppState) => state.location.current);
    const session: IClientSessionData | null = useSelector((state: AppState) => state.session);
    const [sessionError, setSessionError] = useState<boolean>(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffectDidMount(() => {
        axios.get('/api/config/data')
            .then((response) => {
                dispatch({
                    type: CHANGE_CONFIG,
                    payload: response.data,
                });
            })
            .catch(() => {
                setConfigError(true);
            });
        axios.get('/api/location/current')
            .then((response) => {
                dispatch({
                    type: SET_LOCATION,
                    payload: response.data
                });
            })
            .catch(() => {
                setCurrentLocationError(true);
            });
        axios.get('/api/location/all')
            .then((response) => {
                if (response.data && response.data.length > 0) {
                    dispatch({
                        type: SET_LOCATIONS,
                        payload: response.data,
                    });
                }
            })
            .catch(reason => {
                console.error(reason);
            });
        axios.post('/auth/session-start')
            .then((response: any) => {
                let responseSession: IClientSessionData = response.data;
                dispatch({
                    type: SET_USER,
                    payload: responseSession.user,
                });
                dispatch({
                    type: START_SESSION,
                    payload: responseSession,
                });
            })
            .catch(() => {
                dispatch({
                    type: START_SESSION,
                    payload: undefined,
                });
                setSessionError(true);
            });
    });

    if (configError) {
        return (
            <div className={cnApp('Error')}>
                {t('Ошибка загрузки конфигурации приложения')}
            </div>
        );
    }

    if (currentLocationError) {
        return (
            <div className={cnApp('Error')}>
                {t('Ошибка загрузки местоположения')}
            </div>
        );
    }

    if (sessionError) {
        return (
            <div className={cnApp('Error')}>
                {t('Ошибка загрузки сессии пользователя')}
            </div>
        );
    }

    if (!config || !currentLocation || !session) {
        return <LoadingApiData loading={!config || !currentLocation || !session}/>;
    }

    return (
        <BrowserRouter basename={'/'}>
            <AppContent/>
        </BrowserRouter>
    );
}