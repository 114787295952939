import { ThreeCorpus } from "../ThreeCorpus";
import { BoxGeometry, Euler, Vector3 } from "three";
import { ThreeTopUnitAngleTwoFacades90 } from "../../../units/ThreeTopUnit/types/ThreeTopUnitAngleTwoFacades90";
import { ISaveTopAngleTwoFacades90CorpusData } from "../../../../../../../common-code/interfaces/saveData/ISaveTopAngleTwoFacades90CorpusData";

export class ThreeTopAngleTwoFacades90Corpus extends ThreeCorpus {
  saveData: ISaveTopAngleTwoFacades90CorpusData;
  unit: ThreeTopUnitAngleTwoFacades90;

  constructor(
    options: ISaveTopAngleTwoFacades90CorpusData,
    unit: ThreeTopUnitAngleTwoFacades90
  ) {
    super(options, unit);
    this.saveData = options;
    this.unit = unit;
  }

  public initState() {
        super.initState();
  }

  public getAmountShelves() {
    // Добавил мини костыль
    // TODO: Выяснить по дополению формы полем amountShelves

    if (this.saveData.amountShelves || this.saveData.shelves) {
      return this.saveData.amountShelves || this.saveData.shelves.length;
    } else {
      return 0;
    }
  }

  public getSidePanel() {
    return this.saveData.sidePanel;
  }

  protected getTopRightSizeX() {
    return (
      this.getWidth() -
      this.getBackThickness() -
      this.getSidePanel() -
      this.getThickness()
    );
  }

  protected getTopRightSizeZ() {
    return this.getSidePanel() + this.getBackThickness() - this.getThickness();
  }

  protected getBackSize() {
    return this.getWidth() - this.getBackThickness() - this.getThickness();
  }

  protected createPanels() {
    if (this.unit.getSideType() === "right") {
      this.createPanel(
        new BoxGeometry(
          this.getSidePanel(),
          this.getThickness(),
          this.getDepth() - this.getThickness() * 2
        ),
        "topLeft",
        new Vector3(
          this.getWidth() / 2 -
            this.getSidePanel() / 2 -
            this.getBackThickness(),
          this.getHeight() / 2 - this.getThickness() / 2,
          0
        ),
        new Euler(0, 0, 0),
        true
      );
    } else {
      this.createPanel(
        new BoxGeometry(
          this.getSidePanel(),
          this.getThickness(),
          this.getDepth() - this.getThickness() * 2
        ),
        "topLeft",
        new Vector3(
          -this.getWidth() / 2 +
            this.getSidePanel() / 2 +
            this.getBackThickness(),
          this.getHeight() / 2 - this.getThickness() / 2,
          0
        ),
        new Euler(0, 0, 0),
        true

      );
    }

    if (this.unit.getSideType() === "right") {
      this.createPanel(
        new BoxGeometry(
          this.getTopRightSizeX(),
          this.getThickness(),
          this.getTopRightSizeZ()
        ),
        "topRight",
        new Vector3(
          this.getWidth() / 2 -
            this.getBackThickness() -
            this.getSidePanel() -
            this.getTopRightSizeX() / 2,
          this.getHeight() / 2 - this.getThickness() / 2,
          -this.getDepth() / 2 +
            this.getTopRightSizeZ() / 2 +
            this.getThickness()
        ),
        new Euler(0, 0, 0),
        true
      );
    } else {
      this.createPanel(
        new BoxGeometry(
          this.getTopRightSizeX(),
          this.getThickness(),
          this.getTopRightSizeZ()
        ),
        "topRight",
        new Vector3(
          -this.getWidth() / 2 +
            this.getBackThickness() +
            this.getSidePanel() +
            this.getTopRightSizeX() / 2,
          this.getHeight() / 2 - this.getThickness() / 2,
          -this.getDepth() / 2 +
            this.getTopRightSizeZ() / 2 +
            this.getThickness()
        ),
        new Euler(0, 0, 0),
        true
      );
    }

    if (this.unit.getSideType() === "right") {
      this.createPanel(
        new BoxGeometry(
          this.getSidePanel(),
          this.getThickness(),
          this.getDepth() - this.getThickness() * 2
        ),
        "bottomLeft",
        new Vector3(
          this.getWidth() / 2 -
            this.getSidePanel() / 2 -
            this.getBackThickness(),
          -this.getHeight() / 2 + this.getThickness() / 2,
          0
        ),
        new Euler(0, 0, 0),
        true
      );
    } else {
      this.createPanel(
        new BoxGeometry(
          this.getSidePanel(),
          this.getThickness(),
          this.getDepth() - this.getThickness() * 2
        ),
        "bottomLeft",
        new Vector3(
          -this.getWidth() / 2 +
            this.getSidePanel() / 2 +
            this.getBackThickness(),
          -this.getHeight() / 2 + this.getThickness() / 2,
          0
        ),
        new Euler(0, 0, 0),
        true
      );
    }

    if (this.unit.getSideType() === "right") {
      this.createPanel(
        new BoxGeometry(
          this.getTopRightSizeX(),
          this.getThickness(),
          this.getTopRightSizeZ()
        ),
        "bottomRight",
        new Vector3(
          this.getWidth() / 2 -
            this.getBackThickness() -
            this.getSidePanel() -
            this.getTopRightSizeX() / 2,
          -this.getHeight() / 2 + this.getThickness() / 2,
          -this.getDepth() / 2 +
            this.getTopRightSizeZ() / 2 +
            this.getThickness()
        ),
        new Euler(0, 0, 0),
        true
      );
    } else {
      this.createPanel(
        new BoxGeometry(
          this.getTopRightSizeX(),
          this.getThickness(),
          this.getTopRightSizeZ()
        ),
        "bottomRight",
        new Vector3(
          -this.getWidth() / 2 +
            this.getBackThickness() +
            this.getSidePanel() +
            this.getTopRightSizeX() / 2,
          -this.getHeight() / 2 + this.getThickness() / 2,
          -this.getDepth() / 2 +
            this.getTopRightSizeZ() / 2 +
            this.getThickness()
        ),
        new Euler(0, 0, 0),
        true
      );
    }

    if (this.unit.getSideType() === "right") {
      this.createPanel(
        new BoxGeometry(
          this.getBackSize(),
          this.getHeight(),
          this.getThickness()
        ),
        "back",
        new Vector3(
          this.getWidth() / 2 -
            this.getBackThickness() -
            this.getBackSize() / 2,
          0,
          -this.getDepth() / 2 + this.getThickness() / 2
        )
      );
    } else {
      this.createPanel(
        new BoxGeometry(
          this.getBackSize(),
          this.getHeight(),
          this.getThickness()
        ),
        "back",
        new Vector3(
          -this.getWidth() / 2 +
            this.getBackThickness() +
            this.getBackSize() / 2,
          0,
          -this.getDepth() / 2 + this.getThickness() / 2
        )
      );
    }

    if (this.unit.getSideType() === "right") {
      this.createPanel(
        new BoxGeometry(
          this.getSidePanel(),
          this.getHeight(),
          this.getThickness()
        ),
        "left",
        new Vector3(
          this.getWidth() / 2 -
            this.getSidePanel() / 2 -
            this.getBackThickness(),
          0,
          this.getDepth() / 2 - this.getThickness() / 2
        )
      );
    } else {
      this.createPanel(
        new BoxGeometry(
          this.getSidePanel(),
          this.getHeight(),
          this.getThickness()
        ),
        "left",
        new Vector3(
          -this.getWidth() / 2 +
            this.getSidePanel() / 2 +
            this.getBackThickness(),
          0,
          this.getDepth() / 2 - this.getThickness() / 2
        )
      );
    }

    if (this.unit.getSideType() === "right") {
      this.createPanel(
        new BoxGeometry(
          this.getThickness(),
          this.getHeight(),
          this.getSidePanel() + this.getBackThickness()
        ),
        "right",
        new Vector3(
          -this.getWidth() / 2 + this.getThickness() / 2,
          0,
          -this.getDepth() / 2 +
            this.getSidePanel() / 2 +
            this.getBackThickness() / 2
        )
      );
    } else {
      this.createPanel(
        new BoxGeometry(
          this.getThickness(),
          this.getHeight(),
          this.getSidePanel() + this.getBackThickness()
        ),
        "right",
        new Vector3(
          this.getWidth() / 2 - this.getThickness() / 2,
          0,
          -this.getDepth() / 2 +
            this.getSidePanel() / 2 +
            this.getBackThickness() / 2
        )
      );
    }
  }

  protected createBackPanels() {
    if (this.unit.getSideType() === "right") {
      this.createPanel(
        new BoxGeometry(
          this.getBackThickness(),
          this.getHeight(),
          this.getDepth()
        ),
        "back",
        new Vector3(this.getWidth() / 2 - this.getBackThickness() / 2, 0, 0)
      );
    } else {
      this.createPanel(
        new BoxGeometry(
          this.getBackThickness(),
          this.getHeight(),
          this.getDepth()
        ),
        "back",
        new Vector3(-this.getWidth() / 2 + this.getBackThickness() / 2, 0, 0)
      );
    }
  }

  protected createShelves() {
    if (this.getAmountShelves() === 0 || this.getAmountShelves() < 0) {
      return;
    } else if (this.getAmountShelves() === 1) {
      if (this.unit.getSideType() === "right") {
        this.createPanel(
          new BoxGeometry(
            this.getSidePanel(),
            this.getThickness(),
            this.getDepth() - this.getThickness() * 2
          ),
          "shelvesLeft",
          new Vector3(
            this.getWidth() / 2 -
              this.getSidePanel() / 2 -
              this.getBackThickness(),
            0,
            0
          ),
          this.initRotation(),
          true

        );
      } else {
        this.createPanel(
          new BoxGeometry(
            this.getSidePanel(),
            this.getThickness(),
            this.getDepth() - this.getThickness() * 2
          ),
          "shelvesLeft",
          new Vector3(
            -this.getWidth() / 2 +
              this.getSidePanel() / 2 +
              this.getBackThickness(),
            0,
            0
          ),
          this.initRotation(),
          true
        );
      }

      if (this.unit.getSideType() === "right") {
        this.createPanel(
          new BoxGeometry(
            this.getTopRightSizeX(),
            this.getThickness(),
            this.getTopRightSizeZ()
          ),
          "shelvesRight",
          new Vector3(
            this.getWidth() / 2 -
              this.getBackThickness() -
              this.getSidePanel() -
              this.getTopRightSizeX() / 2,
            0,
            -this.getDepth() / 2 +
              this.getTopRightSizeZ() / 2 +
              this.getThickness()
          ),
          this.initRotation(),
          true
        );
      } else {
        this.createPanel(
          new BoxGeometry(
            this.getTopRightSizeX(),
            this.getThickness(),
            this.getTopRightSizeZ()
          ),
          "shelvesRight",
          new Vector3(
            -this.getWidth() / 2 +
              this.getBackThickness() +
              this.getSidePanel() +
              this.getTopRightSizeX() / 2,
            0,
            -this.getDepth() / 2 +
              this.getTopRightSizeZ() / 2 +
              this.getThickness()
          ),
          this.initRotation(),
          true
        );
      }
    } else {
      for (let i = 0; i < this.getAmountShelves(); i++) {
        let a = i;
        if (this.unit.getSideType() === "right") {
          this.createPanel(
            new BoxGeometry(
              this.getSidePanel(),
              this.getThickness(),
              this.getDepth() - this.getThickness() * 2
            ),
            "shelvesLeft",
            new Vector3(
              this.getWidth() / 2 -
                this.getSidePanel() / 2 -
                this.getBackThickness(),
              -this.getHeight() / 2 +
                (this.getHeight() / (this.getAmountShelves() + 1)) * (a + 1),
              0
            ),
            this.initRotation(),
            true
          );
        } else {
          this.createPanel(
            new BoxGeometry(
              this.getSidePanel(),
              this.getThickness(),
              this.getDepth() - this.getThickness() * 2
            ),
            "shelvesLeft",
            new Vector3(
              -this.getWidth() / 2 +
                this.getSidePanel() / 2 +
                this.getBackThickness(),
              -this.getHeight() / 2 +
                (this.getHeight() / (this.getAmountShelves() + 1)) * (a + 1),
              0
            ),
            this.initRotation(),
            true
          );
        }

        if (this.unit.getSideType() === "right") {
          this.createPanel(
            new BoxGeometry(
              this.getTopRightSizeX(),
              this.getThickness(),
              this.getTopRightSizeZ()
            ),
            "shelvesRight",
            new Vector3(
              this.getWidth() / 2 -
                this.getBackThickness() -
                this.getSidePanel() -
                this.getTopRightSizeX() / 2,
              -this.getHeight() / 2 +
                (this.getHeight() / (this.getAmountShelves() + 1)) * (a + 1),
              -this.getDepth() / 2 +
                this.getTopRightSizeZ() / 2 +
                this.getThickness()
            ),
            this.initRotation(),
            true
          );
        } else {
          this.createPanel(
            new BoxGeometry(
              this.getTopRightSizeX(),
              this.getThickness(),
              this.getTopRightSizeZ()
            ),
            "shelvesRight",
            new Vector3(
              -this.getWidth() / 2 +
                this.getBackThickness() +
                this.getSidePanel() +
                this.getTopRightSizeX() / 2,
              -this.getHeight() / 2 +
                (this.getHeight() / (this.getAmountShelves() + 1)) * (a + 1),
              -this.getDepth() / 2 +
                this.getTopRightSizeZ() / 2 +
                this.getThickness()
            ),
            this.initRotation(),
            true
          );
        }
      }
    }
  }
}
