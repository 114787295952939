import React from 'react';
import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import './LoadingApiData.css'
import {Spin} from '@yandex/ui/Spin/bundle';

export const cnLoadingApiData = cn('LoadingApiData');

export interface ILoadingApiDataProps extends IClassNameProps {
    loading: boolean;
}

export const LoadingApiData: React.FC<ILoadingApiDataProps> = ({className, loading}) => {
    return (
        <div className={cnLoadingApiData({visible: loading}, [className])}>
            <div className={cnLoadingApiData('Content')}>
                <Spin
                    className={cnLoadingApiData('Spin')}
                    view={'default'}
                    size={'m'}
                    progress={loading}
                />
            </div>
        </div>
    );
}