import {ThreeBottomUnit} from "../ThreeBottomUnit";
import {ThreeBottomSinkOpenedCorpus} from "../../../details/ThreeCorpus/types/ThreeBottomSinkOpenedCorpus";
import {KitchenService} from "../../../../../services/KitchenService/KitchenService";
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnitSinkOpened extends ThreeBottomUnit {
    corpus: ThreeBottomSinkOpenedCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomSinkOpenedCorpus {
        return new ThreeBottomSinkOpenedCorpus(CommonHelper.deepCopy(corpusData), this)
    }
}