import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';
import {ThreeBottomUnitAngleTwoFacades90} from "../../../units/ThreeBottomUnit/types/ThreeBottomUnitAngleTwoFacades90";
import {
    ISaveBottomAngleTwoFacades90CorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleTwoFacades90CorpusData';

export class ThreeBottomAngleTwoFacades90Corpus extends ThreeCorpus {
    saveData: ISaveBottomAngleTwoFacades90CorpusData;
    unit: ThreeBottomUnitAngleTwoFacades90;

    constructor(options: ISaveBottomAngleTwoFacades90CorpusData, unit: ThreeBottomUnitAngleTwoFacades90) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public getSidePanels() {
        return this.saveData.sidePanel
    }

    public getWidthRail() {
        return this.saveData.widthRail
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
    }

    protected getBottom2PanelZ() {
        return (this.getDepth() - this.getSidePanels() - this.getBackThickness())
    }

    protected getBackLeftPanelZ() {
        return (this.getDepth() - this.getThickness() - this.getBackThickness())
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getSidePanels()),
            'bottom1',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                -this.getDepth() / 2 + this.getSidePanels() / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getSidePanels(), this.getThickness(), this.getBottom2PanelZ()),
            'bottom2',
            new Vector3(
                -this.getWidth() / 2 + this.getSidePanels() / 2,
                -this.getHeight() / 2 + this.getThickness() / 2,
                this.getDepth() / 2 - this.getBottom2PanelZ() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getSidePanels(), this.getHeight() - this.getThickness(), this.getThickness()),
            'frontLeft',
            new Vector3(
                -this.getWidth()/2 + this.getSidePanels()/2,
                this.getThickness()/2,
                this.getDepth()/2 - this.getThickness()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getSidePanels()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                this.getThickness() / 2,
                -this.getDepth() / 2 + this.getSidePanels() / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getBackLeftPanelZ()),
            'backLeft',
            new Vector3(
                -this.getWidth() / 2 + this.getThickness() / 2,
                -this.getHeight() / 2 + (this.getHeight() - this.getThickness()) / 2 + this.getThickness(),
                -this.getDepth() / 2 + this.getBackLeftPanelZ() / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getWidthRail(), this.getThickness()),
            'railBack',
            new Vector3(
                0,
                -this.getHeight() / 2 + (this.getHeight() - this.getThickness())/2 - this.getWidthRail()/2,
                -this.getDepth() / 2 + this.getThickness() / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getWidthRail()),
            'railTopBack',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                -this.getDepth() / 2 + this.getWidthRail() / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, this.getThickness(), this.getWidthRail()),
            'railTopCenter',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                -this.getDepth() / 2 + this.getBackThickness() + this.getSidePanels() - this.getWidthRail()/2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidthRail(), this.getThickness(), this.getBottom2PanelZ() - this.getThickness()),
            'railTopFront',
            new Vector3(
                -this.getWidth() / 2 + this.getSidePanels() - this.getWidthRail()/2,
                this.getHeight() / 2 - this.getThickness() / 2,
                this.getDepth() / 2 - this.getThickness() - (this.getBottom2PanelZ() - this.getThickness())/2
            )
        );
    }

}