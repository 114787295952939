import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {TDirectionSideType} from '../../../../../../../common-code/types/TDirectionSideType';
import {ThreeTopEndPilasterCorpus} from "../../../details/ThreeCorpus/types/ThreeTopEndPilasterCorpus";
import {
    ISaveTopUnitEndPilasterData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitEndPilasterData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitEndPilaster extends ThreeTopUnit {
    saveData: ISaveTopUnitEndPilasterData;
    corpus: ThreeTopEndPilasterCorpus;

    constructor(options: ISaveTopUnitEndPilasterData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isEndUnit(): boolean {
        return true;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeTopEndPilasterCorpus {
        return new ThreeTopEndPilasterCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}