import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {TOGGLE_MENU} from "../../../constants";
import './MenuBurger.css';
import {TMenuTypes} from "../../../types/TMenuTypes";

export const cnMenuBurger = cn('MenuBurger');

export interface IMenuBurgerProps extends IClassNameProps {
    forMenu: TMenuTypes;
}

export const MenuBurger: React.FC<IMenuBurgerProps> = ({className, forMenu}) => {
    const isMenuOpen = useSelector((state: AppState) => state.ui[forMenu].visible);
    const [hover, setHover] = useState<boolean>(false);
    const dispatch = useDispatch();

    const toggleMenu = () => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: forMenu,
                value: !isMenuOpen
            },
        });
    };

    return (
        <div
            className={cnMenuBurger({active: isMenuOpen}, [className])}
            onMouseEnter={() => {setHover(true)}}
            onMouseLeave={() => {setHover(false)}}
            onClick={toggleMenu}
        >
            <div className={cnMenuBurger('Box')}>
                <span className={cnMenuBurger('Inner', {active: isMenuOpen, hover})}/>
                <span className={cnMenuBurger('Inner', {active: isMenuOpen, hover})}/>
                <span className={cnMenuBurger('Inner', {active: isMenuOpen, hover})}/>
            </div>
        </div>
    );
};