import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeEmptyCorpus} from '../../../details/ThreeCorpus/types/ThreeEmptyCorpus';
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {TClassName} from '../../../../../../../common-code/types/TClassName';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';
import {CLASSNAME_EQUIPMENT_BUILTIN_DISHWASHER} from '../../../../../../../common-code/constants';

export class ThreeBottomUnitDishwasher extends ThreeBottomUnit {
    corpus: ThreeEmptyCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected availableEquipments(): TClassName[] {
        return [CLASSNAME_EQUIPMENT_BUILTIN_DISHWASHER];
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeEmptyCorpus {
        return new ThreeEmptyCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}