import React from "react";

import { INewPlinthData, cnFormPlinth } from "../FormPlinth";
import ModalTexture from "../../FormMaterial/Textures/ModalTexture";
import Edit from "../../icons/Edit";
import Trash from "../../icons/Trash";
import { INewTexture, INewTextureData } from "./Textures";

function TextureNew({
  texture,
  index,
  setNewPlinth,
  newPlinth,
  createTextureNew,
  validateTexture,
}: {
  texture: INewTextureData;
  index: number;
  setNewPlinth: (value: INewPlinthData) => void;
  newPlinth: INewPlinthData;
  createTextureNew: (value: INewTexture) => INewTextureData;
  validateTexture: (value: INewTexture) => string;
}) {
  const deleteTexture = () =>
    setNewPlinth({
      ...newPlinth,
      texturesNew: newPlinth?.texturesNew?.filter(
        (el: INewTextureData, i: number) => i !== index
      ),
    });

  const initionalTexture: INewTexture = {
    path: "",
    type: texture.type,
    repeatX: texture.repeat.x.toString(),
    repeatY: texture.repeat.y.toString(),
    file: "",

    fileNew: texture.fileNew,

    offset: !!texture.offset?.x || !!texture.offset?.y,
    offsetX: texture.offset?.x ? texture.offset?.x.toString() : "0",
    offsetY: texture.offset?.y ? texture.offset?.y.toString() : "0",

    sizes: !!texture.sizes?.width || !!texture.sizes?.height,
    sizesWidth: texture.sizes?.width ? texture.sizes?.width.toString() : "0",
    sizesHeight: texture.sizes?.height ? texture.sizes?.height.toString() : "0",

    visible: false,
    error: "",
    url: "",
  };

  const [newTexture, setNewTexture] = React.useState(initionalTexture);
  const handleToggleModal = () =>
    setNewTexture({ ...newTexture, visible: !newTexture.visible });

  const handleSubmit = () => {
    const error = validateTexture(newTexture);
    if (error) {
      setNewTexture({ ...newTexture, error });
      return;
    }

    const textureNew = createTextureNew(newTexture);
    setNewPlinth({
      ...newPlinth,
      texturesNew: newPlinth.texturesNew?.map(
        (el: INewTextureData, i: number) => (i === index ? textureNew : el)
      ),
    });
    setNewTexture(initionalTexture);
  };

  return (
    <div className={cnFormPlinth("Children")}>
      <span>{texture.fileNew.name}</span>
      <img
        width={50}
        height={50}
        src={URL.createObjectURL(texture.fileNew)}
        alt="..."
      />
      <div className={cnFormPlinth("Box-Btn")}>
        <Edit handleToggleModal={handleToggleModal} />
        <Trash handleDeleteValue={deleteTexture} />
      </div>

      <ModalTexture
        handleToggleModal={handleToggleModal}
        setNewTexture={setNewTexture}
        newTexture={newTexture}
        handleSubmit={handleSubmit}
        keyValue="editTexture"
      />
    </div>
  );
}

export default TextureNew;
