import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeBottomSinkCorpus} from '../../../details/ThreeCorpus/types/ThreeBottomSinkCorpus';
import {ISaveBottomUnitData} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnitSink extends ThreeBottomUnit {
    corpus: ThreeBottomSinkCorpus;

    constructor(options: ISaveBottomUnitData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomSinkCorpus {
        return new ThreeBottomSinkCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}