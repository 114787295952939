import React from 'react'
import { SHELF_TYPES as typeShelf } from 'common-code/lib/constants';
import Trash from '../../../../icons/Trash';
import { cnCreateForm } from '../../../FormModule';
import Edit from '../../../../icons/Edit';
import { ISaveShelfData } from 'common-code/lib/interfaces/saveData/ISaveShelfData';
import { SHELF_TYPE_HORIZONTAL } from 'common-code/lib/constants';
import ModalShelves from './ModalShelves';
import { initialShelf } from './initialShelf';
import { useFormContext } from 'react-hook-form';
import { validateShelf } from './validateShelf';
import { IFormInput } from '../../../initialValue';

interface IShelfProps {
  index: number
  shelf: ISaveShelfData
  setArrShelves: (arr: ISaveShelfData[]) => void
  arrShelves: ISaveShelfData[]
  distanceShelf: Record<string, string>
  createShelf: (newShelf: Record<string, string | boolean>) => ISaveShelfData
}

function Shelf({
        index, 
        shelf,
        setArrShelves, 
        arrShelves, 
        distanceShelf, 
        createShelf
    } : IShelfProps) {

    const { getValues } = useFormContext<IFormInput>();
    const [modal, setModal] = React.useState<boolean>(false);
    const [newShelf, setNewShelf] = React.useState<Record<string, string | boolean>>(initialShelf(shelf));

    const handleToggleModal = () => setModal((prev) => !prev);

    const handleDeleteShelf = () =>
    setArrShelves(arrShelves.filter((el, ind) =>ind !== index)); 

    const handleEditTable = () => {
      const depthCorpus = getValues('depth');
      const maxHeight = getValues('height');
      const defaultWidth = getValues('defaultWidth');
      
      const error = validateShelf(newShelf, depthCorpus, maxHeight, defaultWidth );

        if (error) {
            setNewShelf({ ...newShelf, error });
            return;
        }

        const shelfEdit = createShelf(newShelf);
        
        setArrShelves(arrShelves.map((el) => el.initPosition === shelf.initPosition ? shelfEdit : el));
        setModal(false);
    }
        
  return (
    <div className={cnCreateForm('Children')}>
        <span>Полка {typeShelf[shelf.type]}</span>
        { (shelf.initPosition?.x === "%50" || shelf.initPosition?.y === "%50") &&  <span>По центру</span>}

        {((typeof shelf.initPosition?.x === "string" && shelf.initPosition.x.includes("=({%100} -")) || 
        (typeof shelf.initPosition?.y === "string" && shelf.initPosition?.y.includes("=({%100} -"))) &&
          <span>Расположение {newShelf.type === SHELF_TYPE_HORIZONTAL ? "сверху" : "справа"}</span>}

        {(typeof shelf.initPosition?.x === "number" || 
        typeof shelf.initPosition?.y === "number") &&
          <span>Расположение {newShelf.type === SHELF_TYPE_HORIZONTAL ? "снизу" : "слева"}</span>}

        <div className={cnCreateForm('Box-Btn')}>
            <Edit handleToggleModal={handleToggleModal} />
            <Trash handleDeleteValue={handleDeleteShelf} />
        </div>

        <ModalShelves
            modal={modal}
            handleToggleModal={handleToggleModal}
            handleSubmit={handleEditTable}
            newShelf={newShelf}
            distanceShelf={distanceShelf}
            setNewShelf={setNewShelf}
            keyValue={`#editShelf${index}`}
        />

    </div>
  )
}

export default Shelf
