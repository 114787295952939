import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import React, {useMemo} from 'react';
import {TImageData} from '../../../../common-code/types/TImageData';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import "./GalleryCarousel.css"
export const cnGalleryCarousel = cn('GalleryCarousel');
export interface ICarouselProps extends IClassNameProps {
    images: TImageData[];
}

type TCarouselImage = {
    src: string;
    srcset?: string,
    sizes?: string,
    alt?: string,
    thumbnail?: string;
};

export const GalleryCarousel: React.FC<ICarouselProps> = ({images, className}) => {
    const carouselImages: TCarouselImage[] = useMemo(() => {
        return images.map(image => {
            return {src: image.url};
        });
    }, [images]);
    return (
        <div className={cnGalleryCarousel({}, [className])}>
            <Carousel images={carouselImages} className={cnGalleryCarousel('Slider')}/>
        </div>
    );
}