import {IInstanceOfRootElement} from "../interfaces/IInstanceOfRootElement";

const useInstanceOfRootElement = () => {
    return ({instance, rootElement, ignoreElements}: IInstanceOfRootElement): any => {
        if (!instance || !rootElement) {
            return false;
        }

        if (ignoreElements) {
            for (let igroneElement of ignoreElements) {
                if (igroneElement.contains(instance)) {
                    return true;
                }
            }
        }

        return rootElement.contains(instance);
    };
}

export default useInstanceOfRootElement;