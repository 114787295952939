import { ThreePenalUnit } from "../ThreePenalUnit";
import { KitchenService } from "../../../../../services/KitchenService/KitchenService";
import { ThreePenalEndConsoleCorpus } from "../../../details/ThreeCorpus/types/ThreePenalEndConsoleCorpus";
import {
  SIDE_TYPE_RIGHT,
} from "../../../../../../../common-code/constants";
import { ISavePenalUnitEndConsoleData } from "../../../../../../../common-code/interfaces/saveData/ISavePenalUnitEndConsoleData";
import { TDirectionSideType } from "../../../../../../../common-code/types/TDirectionSideType";
import { ISaveCorpusData } from "../../../../../../../common-code/interfaces/saveData/ISaveCorpusData";
import { CommonHelper } from "common-code";
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';

export class ThreePenalUnitEndConsole extends ThreePenalUnit {
  saveData: ISavePenalUnitEndConsoleData;
  corpus: ThreePenalEndConsoleCorpus;

  constructor(options: ISavePenalUnitEndConsoleData, service: KitchenService) {
    super(options, service);
    this.saveData = options;
    this.corpus = this.initCorpus(options.corpus);
  }

  public initState(isRebuild?: boolean) {
    super.initState(isRebuild);
    // this.view3d.userData.transparentAngle = -0.4;
  }

  public isEndUnit(): boolean {
    return true;
  }

  public getSideType(): TDirectionSideType {
    return this.saveData.sideType;
  }

  protected initCorpus(
    corpusData: ISaveCorpusData
  ): ThreePenalEndConsoleCorpus {
    return new ThreePenalEndConsoleCorpus(
      CommonHelper.deepCopy(corpusData),
      this
    );
  }

  protected calculateInitPlinthsData(
    plinths?: ISaveKUnitDetailData[]
  ): ISaveKUnitDetailData[] | undefined {
    if (plinths) {
      let plinth: ISaveKUnitDetailData;
      for (plinth of plinths) {
        if(this.getSideType() === SIDE_TYPE_RIGHT){
          if(plinth.positionType === 'left' && plinth.margin?.x) {
            plinth.positionType = 'right'
            plinth.margin.x = Math.abs(+plinth.margin.x) * -1
          }
          if(plinth.positionType === 'front' && plinth.margin?.x) {
            plinth.margin.x = Math.abs(+plinth.margin.x) * -1
          }
        }
      }
      return plinths;
    }
  }

  // protected calculateInitLegsData(
  //   legs?: ISaveLegData[]
  // ): ISaveLegData[] | undefined {


  //   console.log("НОГИ",legs)



  //   if (legs) {
  //     let index;

  //     for (index in legs) {
  //       switch (+index) {
  //         case 0:
  //           switch (this.getSideType()) {
  //             case SIDE_TYPE_LEFT:
  //               legs[index].initPosition = { x: "50", z: "50" };
  //               break;
  //             case SIDE_TYPE_RIGHT:
  //               legs[index].initPosition = { x: "=({%100}-50)", z: "50" };
  //               break;
  //           }
  //           break;
  //         case 1:
  //           switch (this.getSideType()) {
  //             case SIDE_TYPE_LEFT:
  //               legs[index].initPosition = { x: "50", z: "=({%100}-50)" };
  //               break;
  //             case SIDE_TYPE_RIGHT:
  //               legs[index].initPosition = {
  //                 x: "=({%100}-50)",
  //                 z: "=({%100}-50)",
  //               };
  //               break;
  //           }
  //           break;
  //       }
  //     }
  //   }

  //   return legs;
  // }
}
