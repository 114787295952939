import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React, {ReactNode} from 'react';
import {IPager} from '../../../../common-code/interfaces/catalog/IPager';
import {Button} from '../Button';
import {useTranslation} from 'react-i18next';
import './Pager.css';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

export const cnPager = cn('Pager');

export interface IPagerProps extends IClassNameProps {
    pager: IPager;
    name: string;
}

export const Pager: React.FC<IPagerProps> =
    ({
         className,
         pager,
        name
     }) => {
        const {t} = useTranslation();
        const navigate = useNavigate();
        const location = useLocation();
        const [searchParams] = useSearchParams();

        if (!pager.NavPageCount) {
            return null;
        }

        const navigateToPage = (pageNumber: number) => {
            searchParams.set(name, '' + pageNumber);
            navigate(location.pathname + '?' + searchParams.toString());
        }

        const renderNumberButtons = (): ReactNode[] | null => {
            let buttons: ReactNode[] = [];
            let index: number;
            let min: number;
            let max: number;
            let pageNumber: number;
            let pageCount: number;


            pageNumber = pager.NavPageNomer || 1;
            pageCount = pager.NavPageCount || 1;
            if (pageNumber - 2 <= 0) {
                min = 1;
            } else {
                min = pageNumber - 2;
            }
            if (pageNumber + 2 >= pageCount) {
                max = pageCount;
            } else {
                max = pageNumber + 2;
            }
            for (index = min; index <= max; index++) {
                let pageNumber: number = index;
                buttons.push(<Button type={'button'}
                                     key={'pagerButton' + name + index}
                                     mods={{mods: {border: 'gray'}}}
                                     callBack={() => {
                                         navigateToPage(+pageNumber);
                                     }}
                                     className={cnPager('Button', {type: 'inner'})}
                                     disabled={index === pager.NavPageNomer}
                                     text={'' + index}
                />);
            }

            return buttons.length ? buttons : null;
        }

        return (
            <div className={cnPager({}, [className])}>
                <Button type={'button'}
                        mods={{mods: {border: 'gray'}}}
                        callBack={() => {
                            navigateToPage(1);
                        }}
                        leftIcon={{
                            path: 'arrow-left',
                            width: 12,
                            height: 12,
                            marginRight: 5,
                            fill: pager.NavPageNomer === 1 ?
                                'gray' : 'lightBlack'}}
                        className={cnPager('Button', {type: 'first'})}
                        text={t('В начало') || ''}
                        disabled={pager.NavPageNomer === 1}
                />
                {renderNumberButtons()}
                {!pager.NavPageCount || <Button type={'button'}
                                                mods={{mods: {border: 'gray'}}}
                                                rightIcon={{
                                                    path: 'arrow-right',
                                                    width: 12,
                                                    height: 12,
                                                    marginLeft: 5,
                                                    fill: pager.NavPageNomer === pager.NavPageCount ?
                                                        'gray' : 'lightBlack'}}
                                                callBack={() => {
                                                    if (pager.NavPageCount) {
                                                        navigateToPage(pager.NavPageCount);
                                                    }
                                                }}
                                                className={cnPager('Button', {type: 'last'})}
                                                text={t('В конец') || ''}
                                                disabled={pager.NavPageNomer === pager.NavPageCount}
                />}

            </div>
        );
    }