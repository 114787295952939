import {IClassNameProps} from '@bem-react/core';
import React, {useMemo} from 'react';
import {ICollectionDto} from '../../../../common-code/interfaces/ICollectionDto';
import {cn} from '@bem-react/classname';
import {useTranslation} from 'react-i18next';
import {Breadcrumbs, cnBreadcrumbs} from '../../components/Breadcrumbs ';
import {IBreadcrumbs} from '../../../interfaces/IBreadcrumbs';
import {GalleryCarousel} from '../../components/GalleryCarousel/GalleryCarousel';
import {TImageData} from '../../../../common-code/types/TImageData';
import "./CollectionDetail.css"
import {CollectionDetailButtons} from './Buttons/CollectionDetail-Buttons';
import {CollectionDetailDescription} from './Description/CollectionDetail-Description';
import {CollectionDetailKitchens} from './Projects/CollectionDetail-Kitchens';
import {CollectionDetailProjects} from './Kitchens/CollectionDetail-Projects';

export const cnCollectionDetail = cn('CollectionDetail');

export interface ICollectionDetailProps extends IClassNameProps {
    collection?: ICollectionDto;
}
export const CollectionDetail: React.FC<ICollectionDetailProps> = ({collection, className}) => {
    const {t} = useTranslation();

    const createCollectionHref = (collection?: ICollectionDto): string => {
        if (!collection) {
            return '#';
        }
        if (collection['3d']) {
            return '/collections/'+collection.id;
        } else  {
            return '/complect-collections/'+collection.id;
        }
    }
    const createCollectionsHref = (collection?: ICollectionDto): string => {
        if (!collection) {
            return '#';
        }
        if (collection['3d']) {
            return '/collections';
        } else  {
            return '/complect-collections';
        }
    }
    const createCollectionsTitle = (collection?: ICollectionDto): string => {
        if (collection && collection['3d']) {
            return t("Коллекции модульных гарнитуров");
        } else  {
            return t("Коллекции комплектных гарнитуров");
        }
    }



    const breadcrumbs = useMemo(() => {
        return [
            {text: createCollectionsTitle(collection), href: createCollectionsHref(collection)},
            {text: collection ? collection.name : t("Детальная страница коллекции"), href: createCollectionHref(collection)}
        ] as IBreadcrumbs[];
// eslint-disable-next-line
    }, [collection, t]);

    const collectionImages: TImageData[] = useMemo(() => {
        return collection ? collection.images : [];
    }, [collection]);

    if (!collection) {
        return <div className={cnCollectionDetail({}, [className])}>
            {t('Данные загружаются')}...
        </div>;
    }
    return (
        <>
            <Breadcrumbs className={cnCollectionDetail(cnBreadcrumbs())} breadcrumbs={breadcrumbs}/>
            <div className={cnCollectionDetail({}, [className])}>
                <div className={cnCollectionDetail('Slider')}>
                    <GalleryCarousel images={collectionImages}/>
                </div>
                <div className={cnCollectionDetail('Info')}>
                    <div className={cnCollectionDetail('FlexBlock')}>
                        <div className={cnCollectionDetail('Name')}>
                            {collection.name}
                        </div>
                        <div className={cnCollectionDetail('Price')}>
                            {t('Цена за погонный метр от')}: {collection.pmPrice} руб.
                        </div>
                    </div>
                    <CollectionDetailButtons collection={collection}/>
                    <CollectionDetailDescription collection={collection}/>
                </div>
                <CollectionDetailProjects collection={collection}/>
                <CollectionDetailKitchens collection={collection}/>
            </div>
        </>

    );
}