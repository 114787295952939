import {IClassNameProps} from "@bem-react/core";
import {cn} from "@bem-react/classname";
import React from "react";
import './PageAutofit.css';
import {useTranslation} from "react-i18next";
import {cnHeader, Header} from "../../ui/layouts/Header";

export const cnPageAutofit = cn('PageAutofit');

export interface IPageAutofitProps extends IClassNameProps {

}

export const PageAutofit: React.FC<IPageAutofitProps> = ({className}) => {
    const {t} = useTranslation();

    return (
        <div className={cnPageAutofit({}, [className])}>
            <Header className={cnPageAutofit(cnHeader())} title={t("Авто расчет гарнитура по индивидуальным параметрам") || undefined} />
        </div>
    );
}