import React, {useState} from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_RANGE} from '../../../../../common-code/constants';
import {IObjectOptionProps} from '../ObjectOption';
import {useTranslation} from 'react-i18next';
import {IOptionRange} from '../../../../../common-code/interfaces/option/IOptionRange';
import {cnObjectOption} from '../index';
import {RangeNumber} from '../../RangeNumber/RangeNumber';


export interface IObjectOptionTypeRangeProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_RANGE;
    option?: IOptionRange;
    defaultValue?: number;

}

const ObjectOptionTypeRange: React.FC<IObjectOptionTypeRangeProps> =
    ({
         option,
         group,
         className,
         onChange,
         defaultValue
     }) => {
        const [value, setValue] = useState<number>((defaultValue !== undefined && !isNaN(+defaultValue)) ? +defaultValue : (option && option.defaultValue ? option.defaultValue : 0));
        const {t} = useTranslation();

        if (!option || !onChange) {
            return null;
        }
        const onChangeInput = (value: number) => {
            onChange(option.id, value, group ? group.id : undefined);
            setValue(value);
        }
        return (
            <div className={cnObjectOption({}, [className])}>
                <div className={cnObjectOption('Title', [className+'Title'])}>
                    {t(option.title)}
                </div>
                <div className={cnObjectOption('Value', [className+'Value'])}>
                    <RangeNumber min={option.min} max={option.max}
                             value={value}
                             callBack={onChangeInput} name="length" viewControl={true} showSlider={false}/>
                </div>
            </div>
        );
    }

export const withObjectOptionTypeRange = withBemMod<IObjectOptionTypeRangeProps, IObjectOptionProps>(
        cnObjectOption(),
        {type: OPTION_TYPE_RANGE},
        () => ObjectOptionTypeRange
    )
;