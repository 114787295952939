import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeRackFrameCorpus} from "../../../details/ThreeCorpus/types/ThreeRackFrameCorpus";
import {
    ISaveTopUnitRackFrameData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitRackFrameData';
import {ISaveRackFrameCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveRackFrameCorpusData';
import {CommonHelper} from 'common-code';

export class ThreeTopUnitRackFrame extends ThreeTopUnit {
    corpus: ThreeRackFrameCorpus;

    constructor(options: ISaveTopUnitRackFrameData, service: KitchenService) {
        super(options, service);
        this.corpus = this.initCorpus(options.corpus);
    }

    protected initCorpus(corpusData: ISaveRackFrameCorpusData): ThreeRackFrameCorpus {
        return new ThreeRackFrameCorpus(CommonHelper.deepCopy(corpusData), this);
    }
}