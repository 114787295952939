import React from 'react';
import {IClassNameProps} from "@bem-react/core";
import {cnWizardSpec} from '../WizardSpec';
import {ISpecItem} from '../../../../../common-code/interfaces/catalog/ISpecItem';
import {CurrencyHelper} from '../../../../../domain/CurrencyHelper/CurrencyHelper';
import {TUserRole} from '../../../../../common-code/types/TUserRole';
import {ROLE_ADMIN} from '../../../../../common-code/constants';
import {useUserRole} from '../../../../hooks/useUserRole';

export interface IWizardSpecGroupProps extends IClassNameProps {
    specItem: ISpecItem;
    index: number;
    subIndex?: number;
}

export const WizardSpecGroupItem: React.FC<IWizardSpecGroupProps> =
    ({
         specItem,
         index,
         subIndex,
     }) => {
        const userRole: TUserRole = useUserRole();

        return (
            <>
                <tr className={cnWizardSpec('GroupRow', {subIndex: !!subIndex})}>
                    <td className={cnWizardSpec('GroupCell', {index: true, size: 's'})}>{index}{subIndex ? '.' +subIndex : ''}</td>
                    <td className={cnWizardSpec('GroupCell')}>
                        {specItem.vendorCode}{[ROLE_ADMIN].includes(userRole) ? ' / ' + specItem.externalGuid : null}
                    </td>
                    <td className={cnWizardSpec('GroupCell', {size: 'l'})}>{specItem.name}</td>
                    <td className={cnWizardSpec('GroupCell')}>{specItem.formatCount}</td>
                    <td className={cnWizardSpec('GroupCell')}>{CurrencyHelper.formatValue(specItem.price)}</td>
                    <td className={cnWizardSpec('GroupCell')}>{!!subIndex || CurrencyHelper.formatValue(specItem.count * specItem.price)}</td>
                    <td className={cnWizardSpec('GroupCell', {size: 'sm'})}>{specItem.formatStock}</td>
                    <td className={cnWizardSpec('GroupCell')}>{
                        specItem.stockInWay ? specItem.stockInWay.map(stockInWayItem => {
                            return <div className={cnWizardSpec('StockInWayItem')}>{stockInWayItem.date} + ': ' + {stockInWayItem.count}</div>;
                        }) : '-'
                    }</td>
                </tr>
                {specItem.children ? specItem.children.map((childSpecItem, subIndex) => {
                    return <WizardSpecGroupItem key={index + '.' + subIndex} specItem={childSpecItem} index={index} subIndex={subIndex + 1}/>
                }) : null}
            </>

        );
    };