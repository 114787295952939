import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {cnKitchenParams} from '../KitchenParams';
import {
    OBJECT_SELECT_TYPE_FACADE_MATERIAL
} from '../../../../constants';
import {ObjectSelect} from '../../../components/ObjectSelect/ObjectSelect';
import {Tip} from '../../../components/Tip/Tip';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../redux/AppStore';
import {useTranslation} from 'react-i18next';
import {IFacadeMaterialData} from '../../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';

export interface IKitchenParamsFacadesProps extends IClassNameProps {
    service: KitchenService;
}

export const KitchenParamsFacades: React.FC<IKitchenParamsFacadesProps> = ({className, service}) => {
    const {t} = useTranslation();
    const bottomFacadeMaterial: IFacadeMaterialData | undefined =
        useSelector((state: AppState) => state.wizard.bottomFacadeMaterial);
    const topFacadeMaterial: IFacadeMaterialData | undefined =
        useSelector((state: AppState) => state.wizard.topFacadeMaterial);
    const facadeMaterials: IFacadeMaterialData[] | undefined =
        useSelector((state: AppState) => state.wizard.facadeMaterials);

    const onSelectBottomFacadeMaterial = (facadeMaterial: IFacadeMaterialData) => {
        service.setBottomFacadeMaterial(facadeMaterial, true);
    }
    const onSelectTopFacadeMaterial = (facadeMaterial: IFacadeMaterialData) => {
        service.setTopFacadeMaterial(facadeMaterial, true);
    }
    return (
        <div className={cnKitchenParams('Facades', [className])}>
            {topFacadeMaterial && facadeMaterials ?
                <div className={cnKitchenParams('Item')}>
                    <div className={cnKitchenParams('ItemTitle')}>
                        {t('Верхние фасады')}
                        <Tip directionIcon={'right'} message={<>
                            <p>К <b>верхним фасадам</b> относятся фасады шкафов.
                                Вы можете выбрать фрезеровку из доступных для данной коллекции фасадов.
                                Для крашеных фасадов есть возможность выбрать <b>глянцевое или матовое окрашивание</b>.</p>
                            <p>Для каждой фрезеровки можно выбрать один из доступных материалов (цветов/пленок фасада).
                                Если вы выбрали фрезеровку, то цвет может измениться на доступный по-умолчанию для данной фрезеровки.</p>
                                <p>При выборе фасада, который может быть <b>запатинирован</b> у вас появится возможность выбрать окрас <b>патины</b>.
                                Если для выбранной фрезеровки есть возможность изготовления фасадов-витрин, вы можете выбрать стекло из списка доступных.</p>
                        </>} className={cnKitchenParams('TitleTip')}/>
                    </div>
                    <ObjectSelect
                        type={OBJECT_SELECT_TYPE_FACADE_MATERIAL}
                        className={cnKitchenParams('FacadeMaterial', {level: 'top'})}
                        data={{type: OBJECT_SELECT_TYPE_FACADE_MATERIAL, object: topFacadeMaterial}}
                        list={facadeMaterials}
                        title={t('Верхние фасады') || ''}
                        onSelectObject={(facadeMaterial: IMaterialData) => {
                            onSelectTopFacadeMaterial(facadeMaterial as IFacadeMaterialData);
                        }}
                        service={service}
                    />
                </div> : null}
            {bottomFacadeMaterial && facadeMaterials ?
                <div className={cnKitchenParams('Item')}>
                    <div className={cnKitchenParams('ItemTitle')}>
                        {t('Нижние фасады')}
                        <Tip directionIcon={'right'} message={<>
                            <p>К <b>нижним фасадам</b> относятся фасады шкафов.
                                Вы можете выбрать фрезеровку из доступных для данной коллекции фасадов.
                                Для крашеных фасадов есть возможность выбрать <b>глянцевое или матовое окрашивание</b>.</p>
                            <p>Для каждой фрезеровки можно выбрать один из доступных материалов (цветов/пленок фасада).
                                Если вы выбрали фрезеровку, то цвет может измениться на доступный по-умолчанию для данной фрезеровки.</p>
                            <p>При выборе фасада, который может быть <b>запатинирован</b> у вас появится возможность выбрать окрас <b>патины</b>.
                                Если для выбранной фрезеровки есть возможность изготовления фасадов-витрин, вы можете выбрать стекло из списка доступных.</p>
                        </>} className={cnKitchenParams('TitleTip')}/>
                    </div>
                    <ObjectSelect
                        type={OBJECT_SELECT_TYPE_FACADE_MATERIAL}
                        className={cnKitchenParams('FacadeMaterial', {level: 'bottom'})}
                        data={{type: OBJECT_SELECT_TYPE_FACADE_MATERIAL, object: bottomFacadeMaterial}}
                        list={facadeMaterials}
                        title={t('Нижние фасады') || ''}
                        onSelectObject={(facadeMaterial: IMaterialData) => {
                            onSelectBottomFacadeMaterial(facadeMaterial as IFacadeMaterialData);
                        }}
                        service={service}
                    />
                </div> : null}
        </div>
    );
};