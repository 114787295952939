import {i18n} from '../../app/i18n';

export class CurrencyHelper {
    public static formatValue(value: number, notNull?: boolean): string {
        let resultValue: string;

        if (isNaN(+value)) {
            return '';
        }
        if (notNull && +value <= 0) {
            return '-';
        }
        resultValue = (+value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        resultValue += ' ' + i18n.t('руб') + '.';

        return resultValue;
    }
}