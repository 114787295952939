import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Euler, Mesh, Shape, Vector3} from 'three';
import {ThreeKUnit} from '../../../units/ThreeKUnit/ThreeKUnit';
import {ThreeShelf} from '../../ThreeShelf/ThreeShelf';
import {
    ISaveTopAngleCubeCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopAngleCubeCorpusData';
import {ISaveShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfData';
import {CommonHelper} from 'common-code';

export class ThreeTopAngleCubeCorpus extends ThreeCorpus {
    saveData: ISaveTopAngleCubeCorpusData;

    constructor(options: ISaveTopAngleCubeCorpusData, unit: ThreeKUnit) {
        super(options, unit);
        this.saveData = options;
    }

    public getBackSideDepth(): number {
        return +this.saveData.backSideDepth;
    }

    public getSideDepth(): number {
        return +this.saveData.sideDepth;
    }

    public getDepth() {
        return +this.saveData.sizes.length;
    }

    protected createShape() {
        this.shape = new Shape();
        this.shape.moveTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            this.getDepth() / 2 - this.getThickness()
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            -this.getDepth() / 2 + this.getBackSideDepth()
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getBackSideDepth(),
            -this.getDepth() / 2 + this.getBackThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getBackThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getSideDepth()
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getSideDepth(),
            this.getDepth() / 2 - this.getThickness()
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            this.getDepth() / 2 - this.getThickness()
        );
    }

    protected createShelves() {
        let shelfData: ISaveShelfData;
        let shelf: ThreeShelf;
        for (shelfData of this.saveData.shelves) {
            shelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this, this.shape);
            shelf.initState();
            shelf.createView();
            this.shelves.push(shelf);
        }
    }

    protected createShapePanels() {
        let bottom: Mesh;
        let top: Mesh;
        // position y + 0.5 to avoid z-fighting
        bottom = this.createShapePanel();
        bottom.position.y = -this.getHeight() / 2 + this.getThickness() / 2 - 0.5;
        bottom.position.z = this.getBackThickness()/2;
        bottom.name = 'bottom';
        this.view3d.add(bottom);
        top = this.createShapePanel();
        top.position.y = this.getHeight() / 2 - this.getThickness() / 2 + 0.5;
        top.position.z = this.getBackThickness()/2;
        top.name = 'top';
        this.view3d.add(top);
    }

    protected createPanels() {
        let width;
        let back3Gap;

        width = this.getSideDepth() - this.getBackThickness();
        this.createPanel(
            new BoxGeometry(width, this.getHeight(), this.getThickness()),
            'left',
            new Vector3(
                -this.getWidth() / 2 + width / 2 + this.getBackThickness(),
                0,
                this.getDepth() / 2 - this.getThickness() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), width),
            'right',
            new Vector3(
                this.getWidth() / 2 - this.getThickness() / 2,
                0,
                -this.getDepth() / 2 + this.getSideDepth() / 2 + this.getBackThickness()
            )
        );
        width = Math.sqrt(2 * Math.pow(this.getBackSideDepth(), 2));
        back3Gap = Math.sqrt(
            (Math.pow(
                    Math.sqrt(2 * Math.pow(this.getBackSideDepth(), 2)) / 2 + this.getThickness() / 2, 2)
            ) / 2
        );
        this.createPanel(
            new BoxGeometry(width, this.getHeight(), this.getThickness()),
            'backPanel',
            new Vector3(
                -this.getWidth() / 2 + back3Gap,
                0,
                -this.getDepth() / 2 + back3Gap
            ),
            new Euler(0, 0.25 * Math.PI, 0)
        );
    }

    protected createBackPanels() {
        let width;

        width = this.getWidth() - this.getBackSideDepth();
        this.createPanel(
            new BoxGeometry(width, this.getHeight(), this.getBackThickness()),
            'back1',
            new Vector3(
                this.getWidth() / 2 - width / 2,
                0,
                -this.getDepth() / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getBackThickness(), this.getHeight(), width),
            'back2',
            new Vector3(
                -this.getWidth() / 2 + this.getBackThickness() / 2,
                0,
                this.getDepth() / 2 - width / 2
            )
        );
    }
}