import React, {MutableRefObject, RefObject, useRef} from 'react';
import {ICreateObjectDomElement} from '../interfaces/ICreateObjectDomElement';
import {TMessage} from '../types/TMessage';
import {ADD_MESSAGE, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_WARNING} from '../constants';
import {Intersection, Mesh, Vector3} from 'three';
import usePointerEvent from './usePointerEvent';
import {useDispatch} from 'react-redux';
import {i18n} from '../i18n';
import {ICreateObjectDomElements} from '../interfaces/ICreateObjectDomElements';
import {KitchenService} from '../3d/services/KitchenService/KitchenService';
import {ThreeUnit} from '../3d/objects/threeD/ThreeUnit/ThreeUnit';
import {ICreateObjectData} from '../../common-code/interfaces/createData/ICreateObjectData';
import {IModulePriceData} from '../../common-code/interfaces/catalog/IModulePriceData';
import {UserControls} from '../3d/userControls/UserControls/UserControls';
import {ThreeKUnit} from '../3d/objects/threeD/units/ThreeKUnit/ThreeKUnit';
import {TScreenPosition} from '../types/TScreenPosition';
import {setDocumentCursor} from '../helpers';
import {PointerEvent as ReactPointerEvent} from 'react';

export default function useMoveCreateObject (
    kitchenService: KitchenService | undefined,
    createObjects: MutableRefObject<ICreateObjectDomElements>,
    containerRef: RefObject<HTMLDivElement>,
    deps: any[]
) {
    const dispatch = useDispatch();
    const grabbingObject = useRef<ICreateObjectDomElement | undefined>(undefined);
    const creatingObject = useRef<ThreeUnit | ICreateObjectData | null | undefined>(undefined);
    const threeKUnit = useRef<ThreeKUnit | undefined>(undefined);
    const builtInUnits = useRef<ThreeUnit[]>([]);
    const startMove = useRef<boolean>(false);
    const [
        mousePosition,
        setMousePosition
    ] = React.useState<{x: number | null, y: number | null}>({ x: null, y: null });

    const tryCreateBuiltInObject = (createUnit: ICreateObjectData) => {
        if (!kitchenService) {
            return;
        }
        builtInUnits.current = kitchenService.tryCreateBuiltInEquipment(createUnit);
        if (builtInUnits.current.length > 0) {
            creatingObject.current = createUnit;
        } else {
            const messageData: TMessage = {
                message: i18n.t('Нет модулей для встройки данной техники'),
                type: MESSAGE_TYPE_WARNING,
                autoClose: true
            };
            dispatch({
                type: ADD_MESSAGE,
                payload: messageData
            })
            creatingObject.current = null;
            setMousePosition({ x: null, y: null });
            return;
        }
    }

    const tryCreateSeparateObject = (createUnit: ICreateObjectData) => {
        if (!kitchenService || !grabbingObject.current) {
            return;
        }
        const currentWidth = grabbingObject.current.width && !isNaN(+grabbingObject.current.width) ?
            +grabbingObject.current.width : undefined;
        const currentCorpusWidth = grabbingObject.current.corpusWidth && !isNaN(+grabbingObject.current.corpusWidth) ?
            +grabbingObject.current.corpusWidth : undefined;
         kitchenService.setCreateUnitDynamicOptions(createUnit, currentCorpusWidth || currentWidth);
        const defaultOptions: any = kitchenService.getDefaultOptions(createUnit, currentWidth, currentCorpusWidth);
        const modulePrice: IModulePriceData = kitchenService.calculateCreateObjectPrice(createUnit, defaultOptions);
        if (modulePrice.errors.length > 0) {
            console.error('tryCreateSeparateObject errors modulePrice', modulePrice);
            const messageData: TMessage = {
                message: i18n.t('Не удалось создать выбранный вами модуль, не нашли товар в каталоге'),
                type: MESSAGE_TYPE_ERROR,
                autoClose: true
            };
            dispatch({
                type: ADD_MESSAGE,
                payload: messageData
            })
            creatingObject.current = null;
            return;
        }
        const createObject: ThreeUnit | undefined = kitchenService.createDynamicCommonObject(
            grabbingObject.current.data.uid,
            defaultOptions,
            modulePrice,
            grabbingObject.current.data.catalogCode
        );
        if (!createObject) {
            const messageData: TMessage = {
                message: i18n.t('Не удалось создать выбранный вами модуль, попробуйте через добавить через модальное окно (кнопка "Добавить")'),
                type: MESSAGE_TYPE_ERROR,
                autoClose: true
            };
            dispatch({
                type: ADD_MESSAGE,
                payload: messageData
            })
            creatingObject.current = null;
            return;
        } else {
            creatingObject.current = createObject;
            kitchenService.loadPrices(kitchenService.getModuleOfferIds(modulePrice)).then(() => {
                kitchenService.calculateUnitsPrice({}, true);
                kitchenService.rebuildScene();
            });
            setMousePosition({ x: null, y: null });
        }
    }

    const tryCreateObject = () => {
        if (!kitchenService || !grabbingObject.current) {
            return;
        }
        const uid: string = grabbingObject.current.data.uid;
        let createUnit: ICreateObjectData | undefined = kitchenService.getCreateUnitByUid(uid);
        if (!createUnit) {
            createUnit = kitchenService.getCreateConstructiveByUid(uid);
        }
        if (!createUnit) {
            const messageData: TMessage = {
                message: i18n.t('Нельзя добавить модуль в проект'),
                type: MESSAGE_TYPE_ERROR,
                autoClose: true
            };
            dispatch({
                type: ADD_MESSAGE,
                payload: messageData
            })
            return;
        }
        if (createUnit.builtIn) {
            tryCreateBuiltInObject(createUnit);
        } else {
            tryCreateSeparateObject(createUnit);
        }
    }

    const trySetBuiltInObjectToMove = (event: ReactPointerEvent) => {
        if (!kitchenService || builtInUnits.current.length <= 0) {
            return;
        }
        let unit: ThreeUnit;
        let unitCovers: Mesh[] = [];
        let coverIntersection: Intersection[];

        setMousePosition({ x: event.clientX, y: event.clientY });
        for (unit of builtInUnits.current) {
            unitCovers.push(unit.cover);
        }
        const userControls: UserControls = kitchenService.getUserControls();
        userControls.setScreenPositionByEvent(event as unknown as PointerEvent);
        kitchenService.getEditor().setRayCaster(userControls.screenPosition);
        coverIntersection =  kitchenService.getEditor().getRayCasterIntersection(unitCovers);
        if (coverIntersection.length > 0) {
            let threeUnit: ThreeUnit | undefined;

            threeUnit = coverIntersection[0].object.userData.commonObject;
            if (threeUnit instanceof ThreeKUnit && (!threeKUnit.current || threeKUnit.current?.getId() !== threeUnit.getId())) {
                if (threeKUnit.current) {
                    threeKUnit.current.setSelectCoverSelectColor();
                }
                threeKUnit.current = threeUnit;
                threeKUnit.current.setSelectCoverSelectColor(kitchenService.getDetailSelectColor());
            }
        } else if (threeKUnit.current) {
            threeKUnit.current.setSelectCoverSelectColor();
            threeKUnit.current = undefined;
        }
    }
    const setBuiltInObjectToMove = () => {
        if (!kitchenService || threeKUnit.current === undefined || !creatingObject.current) {
            return;
        }
        threeKUnit.current.createEquipment(kitchenService.getDefaultOptions(creatingObject.current as ICreateObjectData), true);
        kitchenService.rebuildScene();
        startMove.current = true;
        setMousePosition({ x: null, y: null });
        kitchenService.clearCreateBuiltInEquipment();
    }
    const trySetSeparateObjectToMove = (event: ReactPointerEvent) => {
        if (!kitchenService || !(creatingObject.current instanceof ThreeUnit)) {
            return;
        }
        setMousePosition({ x: event.clientX, y: event.clientY });
        const userControls: UserControls = kitchenService.getUserControls();
        const coverObject: Mesh = creatingObject.current.cover;
        const position: Vector3 = creatingObject.current.getPosition().clone();
        position.project(kitchenService.getCamera());
        let rectContainer: DOMRect | undefined = kitchenService.getEditor().getRectContainer();
        if (rectContainer) {
            const screenPosition: TScreenPosition = {
                x: (( position.x + 1) * (rectContainer.width) / 2) + rectContainer.left,
                y: - ( position.y - 1) * (rectContainer.height) / 2 + rectContainer.top,
            };
            userControls.setScreenPositionByEvent({clientX: screenPosition.x, clientY: screenPosition.y} as PointerEvent);
            kitchenService.getEditor().setRayCaster(userControls.screenPosition);
            startMove.current = userControls.initialSelectingCover(coverObject);
            if (startMove.current) {
                setDocumentCursor('move');
                kitchenService.setCanvasCursor('move');
                userControls.setScreenPositionByEvent(event as unknown as PointerEvent);
                kitchenService.getEditor().setRayCaster(userControls.screenPosition);
                userControls.editor.setSelectingCover(coverObject);
                userControls.editor.setCurrentCover(coverObject);
            }
        }
    }

    const isCreateObjectDomElement = (targetElement: HTMLElement): boolean => {
        return !!targetElement.closest('.CreateCommonObject');
    }

    const downEffect = (event: ReactPointerEvent) => {
        if (!kitchenService) {
            return;
        }

        let targetElement: HTMLElement = event.target as HTMLElement;
        if (!isCreateObjectDomElement(targetElement)) {
            return true;
        }
        // Если это не левая кнопка мыши, ничего не делаем
        if (event.pointerType === "mouse" && event.button !== 0) {
            return true;
        }
        for (let index in createObjects.current) {
            if (createObjects.current[index].dom.contains(targetElement)) {
                kitchenService.clearSelectCovers();
                kitchenService.hideMenus();
                grabbingObject.current = createObjects.current[index];
                kitchenService.setDisableCanvasChangeCursor(true);
                kitchenService.setCanvasCursor(createObjects.current[index].data.builtIn ? 'copy' : 'progress');
                setDocumentCursor('copy');
                break;
            }
        }
    }

    const moveEffect = (event: ReactPointerEvent) => {
        let targetElement: HTMLElement = event.target as HTMLElement;

        if (!kitchenService) {
            return;
        }
        // Ставим позицию мышки для перемещения картинки объекта
        if (grabbingObject.current && creatingObject.current === undefined) {
            setMousePosition({ x: event.clientX, y: event.clientY });
        }
        if (!grabbingObject.current || targetElement !== kitchenService.getCanvas()) {
            return;
        }
        if (creatingObject.current === undefined) {
            // Пытаемся создать объект на сцене
            tryCreateObject();
        }
        // Пытаемся установить начальное положение созданного объекта для перемещения по сцене
        if (creatingObject.current instanceof ThreeUnit &&
            !startMove.current) {
            trySetSeparateObjectToMove(event);
        // Пытаемся установить встраиваемый объект в объекты
        } else if (creatingObject.current !== null &&
            creatingObject.current !== undefined &&
            !startMove.current) {
            trySetBuiltInObjectToMove(event);
        }
    }

    const upEffect = () => {
        if (threeKUnit.current) {
            setBuiltInObjectToMove();
        }
        const cursor: string = (creatingObject.current !== undefined &&
            creatingObject.current instanceof ThreeUnit) ? 'move' : 'default';
        if (kitchenService) {
            kitchenService.setDisableCanvasChangeCursor(false);
            kitchenService.setCanvasCursor(cursor);
        }
        setDocumentCursor('default');
        if (kitchenService && creatingObject.current !== undefined && !(creatingObject.current instanceof ThreeUnit)) {
            kitchenService.clearCreateBuiltInEquipment();
        }
        grabbingObject.current = undefined;
        creatingObject.current = undefined;
        threeKUnit.current = undefined;
        startMove.current = false;
        setMousePosition({ x: null, y: null });
    }

    usePointerEvent({downEffect, moveEffect, upEffect}, deps, containerRef.current);

    return {mousePosition, grabbingObject};
}