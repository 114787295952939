import {ThreeBuiltInEquipment} from '../ThreeBuiltInEquipment';
import {Box3, Vector3} from 'three';

export class ThreeOvenEquipment extends ThreeBuiltInEquipment {

    protected initPosition(): Vector3 {
        let cover: Box3;
        let initPosition: Vector3;
        let unitOvenPosition: Vector3 | undefined;

        initPosition = new Vector3();
        unitOvenPosition = this.unit.getOvenPosition();
        if (unitOvenPosition) {
            return unitOvenPosition;
        }      
        cover = this.unit.getCoverBox()
        initPosition.y = this.unit.getCorpusCoverBox().max.y - this.getHeight()/2;
        initPosition.z = this.unit.getDepth()/2;

        return initPosition;
    }
}