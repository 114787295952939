import {KitchenService} from "../KitchenService";
import {TDirectionSideType} from '../../../../../common-code/types/TDirectionSideType';
import {TClassName} from '../../../../../common-code/types/TClassName';
import {
    CHECK_CATALOG_NOT_STRICT,
    FACADE_MATERIAL_TYPE_CORPUS_MATERIAL,
    LEVEL_BOTTOM,
    NONE_EQUIPMENT
} from '../../../../../common-code/constants';
import {ISaveUnitData} from '../../../../../common-code/interfaces/saveData/ISaveUnitData';
import {IOption} from '../../../../../common-code/interfaces/option/IOption';
import {ISettingFacade} from '../../../../interfaces/settingData/ISettingFacade';
import {ISettingApron} from '../../../../interfaces/settingData/ISettingApron';
import {ISaveKUnitDetailData} from '../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {ISaveKUnitData} from '../../../../../common-code/interfaces/saveData/ISaveKUnitData';
import {ISettingEquipment} from '../../../../interfaces/settingData/ISettingEquipment';
import {ICreateObjectData} from '../../../../../common-code/interfaces/createData/ICreateObjectData';
import {ISaveBuiltInEquipmentData} from '../../../../../common-code/interfaces/saveData/ISaveBuiltInEquipmentData';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';
import {IHandleData} from '../../../../../common-code/interfaces/materials/IHandleData';
import {ISettingTabletop} from '../../../../interfaces/settingData/ISettingTabletop';
import {degToRad} from 'three/src/math/MathUtils';
import {ISaveFacadeData} from '../../../../../common-code/interfaces/saveData/ISaveFacadeData';
import {IFacadeMaterialData} from '../../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {IFacadeData} from '../../../../../common-code/interfaces/materials/IFacadeData';

export class RebuildUnitManager {
    service: KitchenService;

    constructor(service: KitchenService) {
        this.service = service;
    }

    public getAllMethods(): any {
        return Object.getPrototypeOf(this);
    }

    public rebuildIsDimensions(saveData: ISaveUnitData, value: boolean): ISaveUnitData {
        return {
            ...saveData,
            isDimensions: value
        }
    }

    public rebuildIsStick(saveData: ISaveUnitData, value: boolean): ISaveUnitData {
        return {
            ...saveData,
            isStick: value
        }
    }

    public rebuildIsLevelStick(saveData: ISaveUnitData, value: boolean): ISaveUnitData {
        return {
            ...saveData,
            isLevelStick: value
        }
    }

    public rebuildIsWallStick(saveData: ISaveUnitData, value: boolean): ISaveUnitData {
        return {
            ...saveData,
            isWallStick: value
        }
    }

    public rebuildOtherOption(saveData: ISaveUnitData, value: {option: IOption, fieldId: string, value: any}) {
        if (value.option.groupId && value.option.groupId in saveData) {
            // @ts-ignore
            let group = saveData[value.option.groupId];

            return {
                ...saveData,
                [value.option.groupId]: {
                    ...group,
                    [value.fieldId]: value.value
                }
            }
        }
        return {
            ...saveData,
            [value.fieldId]: value.value
        }
    }

    public rebuildSelfVerticalRotation(saveData: ISaveUnitData, value: {value: number}): ISaveUnitData {
        return {
            ...saveData,
            selfVerticalRotation: degToRad(value.value)
        };
    }

    public tryDeleteFacadeItem(saveData: ISaveUnitData, value: { facade: ISettingFacade }): ISaveUnitData {
        if (saveData.facades) {
            let newFacades: ISaveFacadeData[];
            let isChange: boolean = false;

            newFacades = saveData.facades.filter((facadeItem: ISaveFacadeData) => {
                if ((value.facade.groupId && facadeItem.groupId && value.facade.groupId === facadeItem.groupId) ||
                    (value.facade.id === facadeItem.id)) {
                    isChange = true;
                    return undefined;
                }
                return facadeItem;
            });
            if (isChange) {
                saveData.checkCatalogType = CHECK_CATALOG_NOT_STRICT;
            }
            return {
                ...saveData,
                facades: newFacades
            }
        }
        return saveData;
    }

    public rebuildCorpusMaterial(saveData: ISaveUnitData, value: {value: string}): ISaveUnitData {
        let corpusMaterial: IMaterialData;

        corpusMaterial = this.service.getCorpusMaterial(value.value);
        if (corpusMaterial.id !== value.value) {
            return saveData;
        }
        if (saveData.corpus) {
            return {
                ...saveData,
                corpus: {
                    ...saveData.corpus,
                    material: value.value
                },
                plinths: saveData.plinths ? saveData.plinths.map(plinthData => {
                    if (corpusMaterial.plinthMaterial) {
                        return {
                            ...plinthData,
                            material: corpusMaterial.plinthMaterial
                        }
                    }
                    return plinthData;
                }) : undefined,
            }
        }

        return saveData;
    }

    public rebuildCorpusSizes(saveData: ISaveUnitData, value: { name: string, value: number }): ISaveUnitData {
        return {
            ...saveData,
            corpus: saveData.corpus ? {
                ...saveData.corpus,
                sizes: {
                    ...saveData.corpus.sizes,
                    [value.name]: value.value
                }
            } : undefined,
            tabletops: saveData.tabletops ? saveData.tabletops.map(tabletopData => {
                if (tabletopData.sizes && tabletopData.sizes.length !== undefined && tabletopData.sizes.length > 0) {
                    return {
                        ...tabletopData,
                        sizes: {
                            ...tabletopData.sizes,
                            length: undefined
                        }
                    }
                }
                return tabletopData;
            }) : undefined,
            plinths: saveData.plinths ? saveData.plinths.map(plinthData => {
                if (plinthData.sizes && plinthData.sizes.length !== undefined && plinthData.sizes.length > 0) {
                    return {
                        ...plinthData,
                        sizes: {
                            ...plinthData.sizes,
                            length: undefined
                        }
                    }
                }
                return plinthData;
            }) : undefined,
            aprons: saveData.aprons ? saveData.aprons.map(apronData => {
                if (apronData.sizes && apronData.sizes.length !== undefined && apronData.sizes.length > 0) {
                    return {
                        ...apronData,
                        sizes: {
                            ...apronData.sizes,
                            length: undefined
                        }
                    }
                }
                return apronData;
            }) : undefined,
            corners: saveData.corners ? saveData.corners.map(cornerData => {
                if (cornerData.sizes && cornerData.sizes.length !== undefined && cornerData.sizes.length > 0) {
                    return {
                        ...cornerData,
                        sizes: {
                            ...cornerData.sizes,
                            length: undefined
                        }
                    }
                }
                return cornerData;
            }) : undefined,
            sizes: {
                ...saveData.sizes,
                [value.name]: value.value
            },
            legs: saveData.legs ? saveData.legs.map(legData => {
                return {
                    ...legData,
                    position: undefined
                }
            }) : undefined
        }
    }

    public tryUnitSetFacadeMaterial(
        saveData: ISaveUnitData,
        value: { facadeMaterial: string, settingFacade: ISettingFacade }
    ): ISaveUnitData {
        let isChange: boolean = false;
        let newFacades: ISaveFacadeData[];

        if (saveData.facades) {
            newFacades = saveData.facades.map(facadeItem => {
                let facadeMaterial: IFacadeMaterialData;
                let facadeData: IFacadeData | undefined;
                let handleData: IHandleData | undefined;
                let newFacadeMaterial: string;
                if (value.settingFacade.id === facadeItem.id ||
                    (facadeItem.groupId && value.settingFacade.groupId === facadeItem.groupId)) {
                    facadeMaterial = this.service.getFacadeMaterial(facadeItem.level || LEVEL_BOTTOM, value.facadeMaterial);
                    newFacadeMaterial = facadeItem.facadeMaterialType === FACADE_MATERIAL_TYPE_CORPUS_MATERIAL ?
                        facadeMaterial.material : facadeMaterial.id;
                    facadeData = this.service.getFacadeData(facadeMaterial.facade);
                    handleData = undefined;
                    if (facadeItem.handle && facadeItem.handle.modelId) {
                        handleData = this.service.getHandleData(facadeItem.handle.modelId);
                    }
                    if (facadeItem.handle && facadeData?.handle && handleData && handleData.notPrice) {
                        facadeItem.handle.modelId = facadeData.handle.id;
                    }
                    delete facadeItem.handle?.position;
                    delete facadeItem.handle?.rotation;
                    delete facadeItem.handle?.horizontalMove;
                    isChange = true;
                    return {
                        ...facadeItem,
                        facadeMaterial: newFacadeMaterial,
                        handle: this.service.calculateHandleData(
                            facadeItem.handle,
                            facadeData?.handle,
                            facadeItem.openType,
                            facadeItem.level,
                            facadeItem.sizes,
                            facadeItem.sideType
                        )
                    }
                }

                return facadeItem;
            });
            if (isChange) {
                if ((value.settingFacade.groupId !== undefined && saveData.facades.filter(item => item.groupId !== value.settingFacade.groupId).length) ||
                    (value.settingFacade.groupId === undefined && saveData.facades.filter(item => item.id !== value.settingFacade.id).length))
                    saveData.checkCatalogType = CHECK_CATALOG_NOT_STRICT;
            }
            return {
                ...saveData,
                facades: newFacades
            }
        }

        return saveData;
    }

    public trySetFacadeSideType(
        saveData: ISaveUnitData,
        value: { sideType: TDirectionSideType, settingFacade: ISettingFacade }): ISaveUnitData {
        if (saveData.facades) {
            let sideType: TDirectionSideType | undefined;
            if (saveData.sideType) {
                sideType = saveData.sideType;
            }
            if (saveData.syncSideTypes !== false && sideType) {
                sideType = value.sideType;
            }
            return {
                ...saveData,
                sideType: sideType,
                facades: saveData.facades.map(facadeItem => {
                    let handleData: IHandleData | undefined;
                    if (value.settingFacade.id === facadeItem.id ||
                        (facadeItem.groupId && value.settingFacade.groupId === facadeItem.groupId)) {
                        handleData = undefined;
                        if (facadeItem.handle && facadeItem.handle.modelId) {
                            handleData = this.service.getHandleData(facadeItem.handle.modelId);
                        }
                        delete facadeItem.handle?.position;
                        delete facadeItem.handle?.rotation;

                        return {
                            ...facadeItem,
                            sideType: value.sideType,
                            handle: this.service.calculateHandleData(
                                facadeItem.handle,
                                handleData,
                                facadeItem.openType,
                                facadeItem.level,
                                facadeItem.sizes,
                                value.sideType
                            )
                        }
                    }
                    return facadeItem;
                })
            }
        }

        return {
            ...saveData,
            sideType: saveData.sideType ? value.sideType : undefined
        };
    }

    public setApronItemMaterial(saveData: ISaveUnitData, value: {
        apronMaterial: string,
        apronSetting: ISettingApron,
        height?: number
    }): ISaveUnitData {
        if (saveData.aprons) {
            return {
                ...saveData,
                aprons: saveData.aprons.map((apronData: ISaveKUnitDetailData) => {
                    if (apronData.id === value.apronSetting.id) {
                        return {
                            ...apronData,
                            material: value.apronMaterial,
                            sizes: value.height !== undefined && !isNaN(+value.height) ? {
                                ...apronData.sizes,
                                height: value.height
                            } : apronData.sizes
                        }
                    }

                    return apronData;
                })
            };
        }
        return saveData;
    }

    public setApronItemSelfVisible(saveData: ISaveUnitData, value: {
        apronSetting: ISettingApron,
        visible: boolean
    }): ISaveUnitData {
        if (saveData.aprons) {
            return {
                ...saveData,
                aprons: saveData.aprons.map((apronData: ISaveKUnitDetailData) => {
                    if (apronData.id === value.apronSetting.id) {
                        return {
                            ...apronData,
                            isSelfVisible: value.visible
                        }
                    }

                    return apronData;
                })
            };
        }

        return saveData;
    }

    public setTabletopItemMaterial(saveData: ISaveUnitData, value: {
        tabletopMaterial: string,
        tabletopSetting: ISettingTabletop,
        height?: number
    }): ISaveUnitData {
        if (saveData.tabletops) {
            return {
                ...saveData,
                tabletops: saveData.tabletops.map((tabletopData: ISaveKUnitDetailData) => {
                    if (tabletopData.id === value.tabletopSetting.id) {
                        return {
                            ...tabletopData,
                            material: value.tabletopMaterial,
                            sizes: value.height !== undefined && !isNaN(+value.height) ? {
                                ...tabletopData.sizes,
                                height: value.height
                            } : tabletopData.sizes
                        }
                    }

                    return tabletopData;
                })
            };
        }
        return saveData;
    }

    public setTabletopItemSelfVisible(saveData: ISaveUnitData, value: {
        tabletopSetting: ISettingTabletop,
        visible: boolean
    }): ISaveUnitData {
        if (saveData.tabletops) {
            return {
                ...saveData,
                tabletops: saveData.tabletops.map((tabletopData: ISaveKUnitDetailData) => {
                    if (tabletopData.id === value.tabletopSetting.id) {
                        return {
                            ...tabletopData,
                            isSelfVisible: value.visible
                        }
                    }

                    return tabletopData;
                })
            };
        }

        return saveData;
    }

    public setTabletopItemCanUnion(saveData: ISaveUnitData, value: {
        tabletopSetting: ISettingTabletop,
        canUnion: boolean
    }): ISaveUnitData {
        if (saveData.tabletops) {
            return {
                ...saveData,
                tabletops: saveData.tabletops.map((tabletopData: ISaveKUnitDetailData) => {
                    if (tabletopData.id === value.tabletopSetting.id) {
                        return {
                            ...tabletopData,
                            canUnion: value.canUnion
                        }
                    }

                    return tabletopData;
                })
            };
        }

        return saveData;
    }

    public tryChangeEquipment(saveData: ISaveKUnitData, value: {
        uid: TClassName | typeof NONE_EQUIPMENT,
        setting: ISettingEquipment
    }): ISaveKUnitData {
        let createEquipment: ICreateObjectData | undefined;
        let equipmentSaveData: ISaveBuiltInEquipmentData | undefined;
        let index: string;
        let isSet: boolean;
        let newEquipments: ISaveBuiltInEquipmentData[] | undefined;

        createEquipment = this.service.getCreateUnitByUid(value.uid);
        if (createEquipment) {
            equipmentSaveData = this.service.getDefaultOptions(createEquipment);
        }
        isSet = false;
        if (saveData.equipments) {
            newEquipments = [];
            for (index in saveData.equipments) {
                if (value.setting.cellName === saveData.equipments[index].cellName) {
                    if (equipmentSaveData) {
                        newEquipments.push(equipmentSaveData);
                    }
                    isSet = true;
                } else {
                    newEquipments.push({...saveData.equipments[index]});
                }
            }
        }
        if (!isSet && equipmentSaveData) {
            if (!newEquipments) {
                newEquipments = [equipmentSaveData];
            } else {
                newEquipments.push(equipmentSaveData);
            }
        }

        return {
            ...saveData,
            equipments: newEquipments
        };
    }

    public tryChangeShowLegs(saveData: ISaveKUnitData, value: { value: boolean }): ISaveUnitData {
        return {
            ...saveData,
            showLegs: value.value
        };
    }

    public tryChangeLegsHeight(saveData: ISaveUnitData, value: { height: number }): ISaveUnitData {
        if (saveData.legs) {
            let oldHeight: number | undefined;
            let newSaveData: ISaveUnitData;
            newSaveData = {
                ...saveData,
                legs: saveData.legs.map((legData) => {
                    oldHeight = legData.height;
                    return {
                        ...legData,
                        height: value.height
                    }
                }),
                plinths: saveData.plinths ? saveData.plinths.map((plinth) => {
                    return {
                        ...plinth,
                        sizes: {
                            ...plinth.sizes,
                            width: undefined,
                        }
                    }
                }) : undefined,
            }
            if (oldHeight && oldHeight !== value.height && newSaveData.position && newSaveData.position.y) {
                newSaveData.position.y += value.height - oldHeight;
            }

            return newSaveData;
        }
        return saveData;
    }
}