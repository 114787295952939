import {IClassNameProps} from '@bem-react/core';
import React, {useState} from 'react';
import {cn} from '@bem-react/classname';
import {TProjectUserType} from '../../../../common-code/types/TProjectUserType';
import {ORDER_USER_TYPE_CHOICE, ORDER_USER_TYPE_NEW, ROLE_ADMIN, ROLE_MANAGER} from '../../../../common-code/constants';
import {IProjectUserData} from '../../../../common-code/interfaces/project/IProjectUserData';
import {addFormError, getProjectUserData, processCreateUser, validatePhone} from '../../../helpers';
import {IProjectData} from '../../../../common-code/interfaces/project/IProjectData';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {UserObjectSelect} from '../UserObjectSelect/UserObjectSelect';
import {Button} from '../../components/Button';
import {useTranslation} from 'react-i18next';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';
import {
    ADD_MESSAGE,
    HIDE_LOADING,
    MESSAGE_TYPE_ERROR,
    MESSAGE_TYPE_SUCCESS,
    MESSAGE_TYPE_WARNING,
    SHOW_LOADING
} from '../../../constants';
import './SetProjectOwner.css';
import {TUserRole} from '../../../../common-code/types/TUserRole';
import {useUserRole} from '../../../hooks/useUserRole';

export const cnSetProjectOwner = cn('SetProjectOwner');

export interface ISetProjectOwnerProps extends IClassNameProps {
    projectUserId?: number;
    service: KitchenService;
    successCallBack: () => void;
    errorCallBack: () => void;
}

export const SetProjectOwner: React.FC<ISetProjectOwnerProps> =
    ({
         className,
         service,
         projectUserId,
         successCallBack,
         errorCallBack,
     }) => {
        const {t} = useTranslation();
        const projectData: IProjectData = useSelector((state: AppState) => state.wizard.project);
        const [userType, setUserType] =
            useState<TProjectUserType>(ORDER_USER_TYPE_CHOICE);
        const [userData, setUserData] =
            useState<IProjectUserData>(getProjectUserData(projectData));
        const [newUserData, setNewUserData] =
            useState<IProjectUserData>({type: ORDER_USER_TYPE_NEW});
        const [formErrors, setFormErrors] = useState<any>({});
        const dispatch = useDispatch();
        const userRole: TUserRole = useUserRole();

        const validateSetOwner = (): boolean => {
            let newFormErrors: any = {};

            switch (userType) {
                case ORDER_USER_TYPE_CHOICE:
                    if ('id' in userData && !userData.id) {
                        addFormError(
                            newFormErrors,
                            t('Не выбран покупатель для заказа'),
                            ORDER_USER_TYPE_CHOICE,
                        );
                    }
                    break;
                case ORDER_USER_TYPE_NEW:
                    if (!newUserData.firstName) {
                        addFormError(
                            newFormErrors,
                            t('Не заполнено имя покупателя'),
                            'firstName',
                        );
                    }
                    if (!newUserData.phone) {
                        addFormError(
                            newFormErrors,
                            t('Не заполнен телефон покупателя'),
                            'phone',
                        );
                    } else if (!validatePhone(newUserData.phone as string)) {
                        addFormError(
                            newFormErrors,
                            t('Формат телефона должен быть') + ' +7 (9xx) xxx-xx-xx',
                            'phone',
                        );
                    }
                    break;
            }
            setFormErrors(newFormErrors);

            return !Object.keys(newFormErrors).length;
        }

        const trySetProjectOwner = () => {
            if (!validateSetOwner()) {
                dispatch({
                    type: ADD_MESSAGE,
                    payload: {
                        type: MESSAGE_TYPE_WARNING,
                        message: t('Не все поля заполнены'),
                        autoClose: true
                    }
                });
                return;
            }
            dispatch({type: SHOW_LOADING});
            getOwnerUserId().then((getOwnerUserId: number) => {
                if (getOwnerUserId === projectUserId) {
                    successCallBack();
                    dispatch({type: HIDE_LOADING});
                    return;
                }
                service.sendProjectToSave(undefined, undefined, true).then(projectId => {
                    dispatch({type: HIDE_LOADING});
                    service.setProjectOwner(getOwnerUserId, projectId).then(() => {
                        dispatch({type: HIDE_LOADING});
                        dispatch({
                            type: MESSAGE_TYPE_SUCCESS,
                            message: t('Пользователь проекта успешно изменен'),
                            autoClose: true
                        });
                        successCallBack();
                    }).catch(() => {
                        dispatch({type: HIDE_LOADING});
                        dispatch({
                            type: MESSAGE_TYPE_ERROR,
                            message: t('При назначении покупателя проекту произошла ошибка'),
                            autoClose: true
                        });
                        errorCallBack();
                    })
                }).catch(() => {
                    dispatch({type: HIDE_LOADING});
                    dispatch({
                        type: MESSAGE_TYPE_ERROR,
                        message: t('При сохранении проекта произошла ошибка'),
                        autoClose: true
                    });
                });
            }).catch(() => {
                dispatch({type: HIDE_LOADING});
                dispatch({
                    type: ADD_MESSAGE,
                    payload: {
                        type: MESSAGE_TYPE_ERROR,
                        message: t('При создании покупателя произошла ошибка'),
                        autoClose: true
                    }
                });
            })
        }

        const getOwnerUserId = (): Promise<number> => {
            return new Promise<number>((resolve, reject) => {
                switch (userType) {
                    case ORDER_USER_TYPE_CHOICE:
                        if (userData.id) {
                            resolve(userData.id);
                        }
                        return;
                    case ORDER_USER_TYPE_NEW:
                        processCreateUser(newUserData).then((userId: number) => {
                            resolve(userId);
                        }).catch(() => {
                            reject();
                        })
                        return;
                }
                reject();
            })
        }

        if (![ROLE_ADMIN, ROLE_MANAGER].includes(userRole)) {
            return null;
        }
        return (
            <div className={cnSetProjectOwner({}, [className])}>
                <UserObjectSelect userData={userData}
                                  customerType={userType}
                                  setCustomerType={setUserType}
                                  newUserData={newUserData}
                                  setUserData={setUserData}
                                  setNewUserData={setNewUserData}
                                  className={cnSetProjectOwner('UserSelect')}
                                  errors={formErrors}
                />
                <div className={cnSetProjectOwner('Buttons')}>
                    <Button type={'button'}
                            leftIcon={{path: 'save', width: 12, height: 12, marginRight: 10}}
                            mods={{
                                mods: {
                                    bkg: 'white', fill: 'lightGreen',
                                    color: 'lightGreen', border: 'lightGreen'
                                }
                            }}
                            callBack={trySetProjectOwner}
                            text={t('Сохранить')}
                            className={cnSetProjectOwner('Button')}
                    />
                </div>
            </div>
        );
    }
