import React, { useEffect} from 'react'
import { useFormContext } from 'react-hook-form';
import { BOX_TYPE_NORMAL} from 'common-code/lib/constants';
import Box from './Box';
import { cnCreateForm } from '../../../FormModule';
import { Button } from '../../../../../../components/Button';
import { OPTION_TYPE_JSON } from "common-code/lib/constants";
import { IOption } from 'common-code/lib/interfaces/option/IOption';
import { IOptionHiddenJson } from '../../../../../../../../common-code/interfaces/option/IOptionHiddenJson';
import { ISaveBoxData } from 'common-code/lib/interfaces/saveData/ISaveBoxData';
import ModalBoxes from './ModalBoxes';
import { INewBox, initialBox } from './initialBox';
import { validateBox } from './validateBox';
import { IFormInput } from '../../../initialValue';

function Boxes () {
    const { setValue, getValues }= useFormContext<IFormInput>();
    const boxesInitional = getValues("boxes") ? getValues("boxes")?.value : [];
    const [arrBoxes, setArrBoxes] = React.useState <ISaveBoxData[]> (boxesInitional);
    const [newBox, setNewBox] = React.useState<INewBox>(initialBox(undefined));

    const boxes :IOption  =  {
        id: 'boxes',
        title: 'Ящики',
        sort: 4,
        type: OPTION_TYPE_JSON,
        value: arrBoxes,
      } as  IOptionHiddenJson;

    useEffect(() => {
        if(arrBoxes.length > 0 ){
            setValue("boxes", boxes );
        } else {
            setValue("boxes", null );
        }
        // eslint-disable-next-line
    }, [boxes]);

   

    const createBox = ({ length, height, depth, initPositionX, initPositionY, thickness, 
        bottomThickness, railGap, frontSide, sizes, sizeLength, sizeWidth, sizeHeight } : 
        INewBox) => {

        const boxNew : ISaveBoxData = {
            id: 0,
            length: "%" + length,
            height:  height,
            depth: "%" + depth,
            type: BOX_TYPE_NORMAL,
            initPosition: {x: initPositionX, y: initPositionY},
            thickness: Number(thickness),
            bottomThickness: Number(bottomThickness),
            railGap: Number(railGap),
            frontSide: frontSide
        }

        if(sizes){
            boxNew.sizes = {
                length: Number(sizeLength),
                width: Number(sizeWidth), 
                height: Number(sizeHeight)
            };  
        }

        return boxNew
    }

    const handleSubmit =  () => {
        const maxHeight = getValues('height') ? Number(getValues('height')) : 500;
        const maxWidth = getValues('defaultWidth') ? Number(getValues('defaultWidth')) : 500;
        const error = validateBox(newBox,maxHeight,maxWidth);
        if (error) {
            setNewBox({ ...newBox, error});
            return;
        }

        const boxNew = createBox(newBox);
        setArrBoxes([...arrBoxes, boxNew]);
        setNewBox({ ...newBox, modal: false});
    }
    const handleToggleModal = () => {
        setNewBox({ ...newBox, modal: !newBox.modal});
    }

  return (
    <>  
        <h3 className={cnCreateForm('Header')}>Ящики</h3>

        {arrBoxes.map((box, index) => 
            <Box  
                arrBoxes={arrBoxes} 
                setArrBoxes={setArrBoxes}  
                box={box} 
                key={`${index}${box.initPosition?.x}
                ${box.initPosition?.y}`}
                index={index}
                createBox={createBox}
            />                 
        )}

        <Button
            className={cnCreateForm('Button')}
            type={'button'}
            text={`Добавить ящик`}
            leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
            mods={{
                mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
            }}
            callBack={handleToggleModal}
        />

        <ModalBoxes 
            handleToggleModal={handleToggleModal}
            setNewBox={setNewBox}
            newBox={newBox}
            handleSubmit={handleSubmit}
            keyValue={'#addingBox'}
        />
        <hr/>
    </>
  )
}

export default Boxes