import {BoxGeometry, DoubleSide, Group, Mesh, MeshBasicMaterial, Object3D, Vector3} from "three";
import {
    onAfterRenderTransparentForBack,
    onBeforeRenderTransparentForBack
} from "../../../../helpers/ThreeHelper/ThreeHelper";
import {ThreeConstructive} from '../ThreeConstructive/ThreeConstructive';
import {ISaveConstructiveData} from '../../../../../../common-code/interfaces/saveData/ISaveConstructiveData';
import {IConstructiveModelData} from '../../../../../../common-code/interfaces/room/IConstructiveModelData';
import {ThreeRoom} from '../../rooms/ThreeRoom/ThreeRoom';
import {IContextIcon} from '../../../../../interfaces/IContextIcon';
import {ACTION_DELETE, ACTION_SETTINGS} from '../../../../../constants';
import {i18n} from '../../../../../i18n';


export class ThreeConstructiveModel extends ThreeConstructive {
    saveData: ISaveConstructiveData;
    threeModelData: IConstructiveModelData;
    threeModel: Group;
    dummy: Mesh;

    constructor(options: ISaveConstructiveData, room: ThreeRoom) {
        super(options, room);
        this.saveData = options;
        this.dummy = new Mesh();
        this.threeModelData = this.initThreeModelData();
        this.threeModel = new Group();
    }

    public initState(isRebuild?: boolean) {
        this.createDummy();
        this.createThreeModel();
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        super.createView(isRebuild);
    }

    public getThreeModelId(): string {
        return this.saveData.model;
    }

    public setLoadModel(type: string, details: Object3D[]) {
        let detail: Object3D;
        let newDetail: Object3D;

        for (detail of details) {
            newDetail = detail.clone();
            newDetail.matrixAutoUpdate = false;
            this.threeModel.add(newDetail);
        }
        this.dummy.visible = false;
        this.scaleThreeModel();
        this.updateViewType();
        if (this.transparentForBack) {
            this.setTransparentForBack();
        }
    }

    public setTransparentForBack() {
        this.threeModel.traverse((child) => {
            if (child.userData.notTransparentForBack) {
                return;
            }
            child.userData.parent = this.view3d;
            if (child instanceof Mesh) {
                child.onBeforeRender = onBeforeRenderTransparentForBack;
                child.onAfterRender = onAfterRenderTransparentForBack;
            }
        })
    }

    public scaleThreeModel() {
        this.threeModel.scale.set(
            this.getWidth() / this.threeModelData.width,
            this.getHeight() / this.threeModelData.height,
            this.getDepth() / this.threeModelData.depth
        );
        this.updateAllMatrices();
    }

    public getContextIcons(): IContextIcon[] {
        let icons: IContextIcon[];
        let actionData = this.actionData();

        icons = [
            {
                channelName: 'ThreeConstructiveModel',
                action: ACTION_SETTINGS,
                actionData: actionData,
                popup: true,
                icon: 'newSettings',
                hide: false,
                sort: 0,
                title: i18n.t('Свойства')
            },
            {
                channelName: 'CommonObject',
                action: ACTION_DELETE,
                actionData: actionData,
                popup: false,
                icon: 'newDelete',
                hide: true,
                sort: 1,
                title: i18n.t('Удалить')
            }
        ];

        return icons;
    }

    protected initThreeModelData(): IConstructiveModelData {
        return this.service.getConstructiveModel(this);
    }

    protected createDummy() {
        let geometry;
        let coverPoints: Vector3[];

        geometry = new BoxGeometry(this.getWidth(), this.getHeight(), this.getDepth());
        this.dummy = new Mesh(
            geometry,
            new MeshBasicMaterial({
                side: DoubleSide,
                color: '#757575',
                wireframe: true,
            })
        );
        coverPoints = [
            new Vector3(-this.getWidth() / 2, -this.getHeight() / 2, -this.getDepth() / 2),
            new Vector3(this.getWidth() / 2, this.getHeight() / 2, this.getDepth() / 2),
        ];
        this.view3d.add(this.dummy);
        this.addCoverPoints(coverPoints);
    }

    protected createThreeModel() {
        this.threeModel.name = 'threeModel';
        this.threeModel.matrixAutoUpdate = false;
        this.view3d.add(this.threeModel);
        this.service.loadConstructiveThreeModel(this);
    }
}