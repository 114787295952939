import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {
    OPTION_TYPE_CHECKBOX, OPTION_TYPE_CORPUS_MATERIAL, OPTION_TYPE_FACADE_MATERIAL,
    OPTION_TYPE_NUMBER,
    OPTION_TYPE_RADIOBUTTON,
    OPTION_TYPE_RANGE,
    OPTION_TYPE_SELECT,
    OPTION_TYPE_TEXT
} from '../../../../common-code/constants';
import './CreateOptionGroup.css'
import {CreateOption} from '../CreateOption/CreateOption';
import {IOptionGroup} from '../../../../common-code/interfaces/option/IOptionGroup';
import {ICreateObjectDisableSelects} from '../../../../common-code/interfaces/createData/ICreateObjectDisableSelects';
import {CommonObjectHelper} from '../../../3d/helpers/CommonObjectHelper/CommonObjectHelper';

export const cnCreateOptionGroup = cn('CreateOptionGroup');
export interface ICreateOptionGroupProps extends IClassNameProps {
    group: IOptionGroup;
    onChange: (fieldId: string, value: any, groupId?: string) => void;
    createOptions: any;
    disableSelects?: ICreateObjectDisableSelects;
}

export const CreateOptionGroup: React.FC<ICreateOptionGroupProps> =
    ({className, group, onChange, createOptions, disableSelects}) => {
    const isVisibleTitle = (): boolean => {
        return group.options.filter((option) => {
            return [
                OPTION_TYPE_TEXT,
                OPTION_TYPE_NUMBER,
                OPTION_TYPE_SELECT,
                OPTION_TYPE_CHECKBOX,
                OPTION_TYPE_RANGE,
                OPTION_TYPE_RADIOBUTTON,
                OPTION_TYPE_CORPUS_MATERIAL,
                OPTION_TYPE_FACADE_MATERIAL,
            ].indexOf(option.type) !== -1
        }).length > 0
    };
    return (
        <div className={cnCreateOptionGroup({visible: isVisibleTitle()}, [className])}>
            <div className={cnCreateOptionGroup('Options')}>
                {group.options.map((option) => {
                    return <CreateOption
                        key={group.id + '_' + option.id}
                        type={option.type}
                        option={CommonObjectHelper.prepareCreateOption(
                            option,
                            disableSelects && disableSelects[group.id + '_' + option.id] ? disableSelects[group.id + '_' + option.id] : undefined
                        )}
                        group={group}
                        onChange={onChange}
                        createOptions={createOptions}/>
                })}
            </div>
        </div>
    );
}