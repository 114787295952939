import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React, {ChangeEvent} from 'react';
import './UserObjectSelect-Add.css';
import {cnInput} from '../../../components/Input';
import {INPUT_TYPE_PHONE, INPUT_TYPE_TEXT} from '../../../constants';
import {Input} from '../../../components/Input/Input';
import {useTranslation} from 'react-i18next';
import {ORDER_USER_TYPE_NEW} from '../../../../../common-code/constants';
import {IProjectUserData} from '../../../../../common-code/interfaces/project/IProjectUserData';

export const cnUserObjectSelectAdd = cn('UserObjectSelectAdd')

export interface IUserObjectSelectAddProps extends IClassNameProps {
    userData: IProjectUserData;
    setUserData: (userData: IProjectUserData) => void;
    errors?: any;
}

export const UserObjectSelectAdd: React.FC<IUserObjectSelectAddProps> =
    ({
         className,
         userData,
         setUserData,
         errors
    }) => {
    const {t} = useTranslation();

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        let newUserData: IProjectUserData;
        let fieldName: keyof IProjectUserData;

        fieldName = event.target.name as keyof IProjectUserData;

        newUserData = {...userData};
        newUserData.type = ORDER_USER_TYPE_NEW;
        switch (fieldName) {
            case 'firstName':
                newUserData.firstName = event.target.value;
                break;
            case 'lastName':
                newUserData.lastName = event.target.value;
                break;
            case 'phone':
                newUserData.phone = event.target.value;
                break;
        }
        setUserData(newUserData);
    }

    return (
        <div className={cnUserObjectSelectAdd({}, [className])}>
            <div className={cnUserObjectSelectAdd('Field')}>
                <Input
                    className={cnUserObjectSelectAdd(cnInput(), {error: !!(errors && errors.firstName)})}
                    type={INPUT_TYPE_TEXT}
                    value={userData.type === ORDER_USER_TYPE_NEW ? userData.firstName : ''}
                    name={'firstName'}
                    placeholder={t('Имя покупателя') + ' *'}
                    onChange={onChangeInput}
                />
                {
                    errors && errors.firstName ?
                    <div className={cnUserObjectSelectAdd('Error')}>{errors.firstName}</div> : null
                }
            </div>
            <div className={cnUserObjectSelectAdd('Field')}>
                <Input
                    className={cnUserObjectSelectAdd(cnInput(), {error: !!(errors && errors.lastName)})}
                    type={INPUT_TYPE_TEXT}
                    value={userData.type === ORDER_USER_TYPE_NEW ? userData.lastName : ''}
                    name={'lastName'}
                    placeholder={t('Фамилия покупателя') || ''}
                    onChange={onChangeInput}
                />
                {
                    errors && errors.lastName ?
                        <div className={cnUserObjectSelectAdd('Error')}>{errors.lastName}</div> : null
                }
            </div>

            <div className={cnUserObjectSelectAdd('Field')}>
                <Input
                    className={cnUserObjectSelectAdd(cnInput(), {error: !!(errors && errors.phone)})}
                    type={INPUT_TYPE_PHONE}
                    value={userData.type === ORDER_USER_TYPE_NEW ? userData.phone : ''}
                    name={'phone'}
                    placeholder={t('Телефон покупателя') || ''}
                    onChange={onChangeInput}
                />
                {
                    errors && errors.phone ?
                        <div className={cnUserObjectSelectAdd('Error')}>{errors.phone}</div> : null
                }
            </div>

        </div>
    );
}