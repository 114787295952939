import { Button, cnButton } from '../../../components/Button'
import { useState } from 'react'
import { ITechnologMap } from '../../../../../common-code/interfaces/ITechnologMap'
import { useDispatch } from 'react-redux'
import {
    CHANGE_TECHNOLOG_MAP,
    HIDE_LOADING,
    SHOW_LOADING,
} from '../../../../constants'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../redux/AppStore'
import {
    editTechnologMap,
    initialTechnologMap,
    validateTechnologMap,
} from './initialTecnologMap'
import FormTechnologMap from '../FormTechnologMap/FormTechnologMap'
import { saveTecnologMaps } from '../api/TecnologMapsServices'
import { ITechnologMaps } from '../../../../../common-code/interfaces/ITechnologMaps'
import { cn } from '@bem-react/classname'
import { useUrlSearchParam } from '../../../../hooks/useSearchParams'
import './EditTechnologMap.css'

export const cnEditTechnologMap = cn('EditTechnologMap')

const EditTechnologMap = ({
    map,
}: {
    map: [key: string, value: ITechnologMap]
}) => {
    const dispatch = useDispatch()

    const technologMaps: Record<string, ITechnologMap> = useSelector(
        (state: AppState) => state.wizard.technologMaps
    )
    const [technologMapEdit, setTechnologMapEdit] = useState(
        initialTechnologMap(map[1])
    )
    const collectionId = useUrlSearchParam('collection')

    const { modal, error } = technologMapEdit

    // открытие/закрытие модального окна
    const handleToggleModal = () => {
        setTechnologMapEdit({ ...technologMapEdit, modal: !modal, error: '' })
    }

    const saveTechnologMapToServer = (newTechnologMap: ITechnologMaps) => {
        saveTecnologMaps(
            newTechnologMap,
            'technolog-maps',
            collectionId as string
        )
            .then((res: ITechnologMaps | undefined) => {
                if (res) {
                    dispatch({ type: CHANGE_TECHNOLOG_MAP, payload: res })
                    setTechnologMapEdit({ ...technologMapEdit, modal: false })
                }
                dispatch({ type: HIDE_LOADING })
            })
            .catch((err) => {
                dispatch({ type: HIDE_LOADING })
                console.log('saveTechnologMapToServer error', err)
            })
    }

    const saveTechnologMap = () => {
        if (error) return
        dispatch({ type: SHOW_LOADING })
        const validateError = validateTechnologMap(technologMapEdit)
        if (validateError) {
            dispatch({ type: HIDE_LOADING })
            setTechnologMapEdit({ ...technologMapEdit, error: validateError })
            return
        }
        // изменение технологической карты
        const technologMapNew = editTechnologMap(technologMapEdit)
        console.log('technologMapNew', technologMapNew)
        const technologMapsNew = { ...technologMaps, [map[0]]: technologMapNew }
        // запись в файл
        saveTechnologMapToServer(technologMapsNew)
    }

    return (
        <>
            <Button
                className={cnEditTechnologMap(cnButton())}
                type={'button'}
                text={`Редактирование технологической карты`}
                mods={{
                    mods: {
                        bkg: 'white',
                        color: 'lightGreen',
                        border: 'lightGreen',
                        fill: 'lightGreen',
                    },
                    hover: {
                        mods: {
                            bkg: 'lightGreen',
                            color: 'white',
                            fill: 'white',
                        },
                    },
                }}
                callBack={handleToggleModal}
            />
            <FormTechnologMap
                technologMapEdit={technologMapEdit}
                setTechnologMapEdit={setTechnologMapEdit}
                saveTechnologMap={saveTechnologMap}
                keyValue={map[0]}
            />
        </>
    )
}

export default EditTechnologMap
