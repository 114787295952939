import {IClassNameProps} from "@bem-react/core";
import React, {useState} from "react";
import {Svg} from '../../../components/Svg';
import {IUserData} from '../../../../../common-code/interfaces/IUserData';
import {cnAuth} from '../Auth';
import {THeaderType} from '../../../../types/THeaderType';
import {useHeaderType} from '../../../../hooks/useHeaderType';
import {HEADER_TYPE_BIG} from '../../../constants';

export interface IAuthUserProps extends IClassNameProps {
    isMenuOpen: boolean;
    userData: IUserData;
    toggleMenu: () => void;
}
export const AuthUser: React.FC<IAuthUserProps> = ({className, isMenuOpen, toggleMenu, userData}) => {
    const [hover, setHover] = useState<boolean>(false);
    const headerType: THeaderType = useHeaderType();

    return (
        <div
            className={cnAuth('User', {active: isMenuOpen, hover: hover}, [className])}
            onMouseEnter={() => {setHover(true)}}
            onMouseLeave={() => {setHover(false)}}
            onClick={toggleMenu}
        >
            <Svg
                className={cnAuth('Icon')}
                icon={'man'}
                label={'man'}
                style={{width: `${25}px`, height: `${25}px`, marginRight: `${8}px`}}
            />
            {headerType === HEADER_TYPE_BIG ? <div className={cnAuth('Text')}>{`${userData.lastName} ${userData.name}`}</div> : null}

            <Svg
                className={cnAuth('Icon')}
                icon={'arrowDown'}
                label={'arrowDown'}
                style={{width: `${15}px`, height: `${15}px`, marginLeft: `${3}px`, transform: `rotateZ(${isMenuOpen ? 180 : 0}deg)`}}
            />
        </div>
    );
};