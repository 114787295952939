import {KitchenService} from '../KitchenService';
import {IHistoryState} from '../../../interfaces/history/IHistoryState';
import {
    HISTORY_STATE_TYPE_ACCESSORY,
    HISTORY_STATE_TYPE_CHANGE,
    HISTORY_STATE_TYPE_CREATE,
    HISTORY_STATE_TYPE_DELETE,
    HISTORY_STATE_TYPE_EURO_ZAPIL, HISTORY_STATE_TYPE_FACADE, HISTORY_STATE_TYPE_HANDLE,
    HISTORY_STATE_TYPE_INIT,
    HISTORY_STATE_TYPE_MOVE,
    HISTORY_STATE_TYPE_ROOM,
    HISTORY_STATE_TYPE_SERVICE
} from '../../../constants';
import {ThreeUnit} from '../../../objects/threeD/ThreeUnit/ThreeUnit';
import {IHistoryMoveState} from '../../../interfaces/history/IHistoryMoveState';
import {Euler, Vector3} from 'three';
import {ThreeWall} from '../../../objects/threeD/rooms/ThreeWall/ThreeWall';
import {CHANGE_HISTORY_REDO, CHANGE_HISTORY_UNDO, MESSAGE_TYPE_WARNING} from '../../../../constants';
import {i18n} from '../../../../i18n';
import {IHistoryChangeObjectsState} from '../../../interfaces/history/IHistoryChangeObjectsState';
import {IHistoryRoomState} from '../../../interfaces/history/IHistoryRoomState';
import {ISaveUnitData} from '../../../../../common-code/interfaces/saveData/ISaveUnitData';
import {IPositionInfo} from '../../../interfaces/IPositionInfo';
import {IHistoryObjectMoveData} from '../../../interfaces/history/IHistoryObjectMoveData';
import {IHistoryCreateObjectsState} from '../../../interfaces/history/IHistoryCreateObjectsState';
import {IHistoryEuroZapilState} from '../../../interfaces/history/IHistoryEuroZapilState';
import {IHistoryAccessoryState} from '../../../interfaces/history/IHistoryAccessoryState';
import {IHistoryServiceState} from '../../../interfaces/history/IHistoryServiceState';
import {IHistoryObjectData} from '../../../interfaces/history/IHistoryObjectData';
import {
    GOOD_TYPE_APRON,
    GOOD_TYPE_CORNER,
    GOOD_TYPE_FACADE,
    GOOD_TYPE_FLOOR,
    GOOD_TYPE_PLINTH,
    GOOD_TYPE_TABLETOP,
    GOOD_TYPE_WALL,
    LEVEL_BOTTOM,
    LEVEL_TOP
} from '../../../../../common-code/constants';
import {IApronData} from '../../../../../common-code/interfaces/materials/IApronData';
import {ITabletopData} from '../../../../../common-code/interfaces/materials/ITabletopData';
import {ICornerData} from '../../../../../common-code/interfaces/materials/ICornerData';
import {IPlinthData} from '../../../../../common-code/interfaces/materials/IPlinthData';
import {IFacadeMaterialData} from '../../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {IHistoryFacadeState} from '../../../interfaces/history/IHistoryFacadeState';
import {IHistoryHandleState} from '../../../interfaces/history/IHistoryHandleState';

export class HistoryManager {
    service: KitchenService;

    protected storage: IHistoryState[];
    protected position: number;

    constructor(service: KitchenService) {
        this.service = service;
        this.storage = [];
        this.position = 0;
    }

    public remove() {
        this.resetStorage();
    }

    public resetHistoryState() {
        this.resetStorage();
    }

    public addState(state: IHistoryState) {
        this.addToStorage(state);
        this.updateControls();
    }

    public updateControls() {
        this.service.sendToRedux({
            type: CHANGE_HISTORY_UNDO,
            payload: (this.position > 0)
        });
        this.service.sendToRedux({
            type: CHANGE_HISTORY_REDO,
            payload: (this.position < this.storage.length - 1)
        });
    }

    public undoState() {
        let state: IHistoryState | undefined;
        let isApply: boolean;

        isApply = false;
        state = this.storage[this.position];
        if (state) {
            switch (state.type) {
                case HISTORY_STATE_TYPE_INIT:
                    isApply = false;
                    break;
                case HISTORY_STATE_TYPE_CREATE:
                    isApply = this.deleteObjects(state as IHistoryCreateObjectsState);
                    break;
                case HISTORY_STATE_TYPE_DELETE:
                    isApply = this.restoreObjects(state as IHistoryCreateObjectsState);
                    break;
                case HISTORY_STATE_TYPE_MOVE:
                    isApply = this.undoMoveObject(state as IHistoryMoveState);
                    break;
                case HISTORY_STATE_TYPE_CHANGE:
                    isApply = this.undoChangeObject(state as IHistoryChangeObjectsState);
                    break;
                case HISTORY_STATE_TYPE_ROOM:
                    isApply = this.undoChangeRoom(state as IHistoryRoomState);
                    break;
                case HISTORY_STATE_TYPE_EURO_ZAPIL:
                    isApply = this.undoChangeEuroZapil(state as IHistoryEuroZapilState);
                    break;
                case HISTORY_STATE_TYPE_ACCESSORY:
                    isApply = this.undoChangeAccessory(state as IHistoryAccessoryState);
                    break;
                case HISTORY_STATE_TYPE_SERVICE:
                    isApply = this.undoChangeService(state as IHistoryServiceState);
                    break;
                case HISTORY_STATE_TYPE_FACADE:
                    isApply = this.undoChangeFacade(state as IHistoryFacadeState);
                    break;
                case HISTORY_STATE_TYPE_HANDLE:
                    isApply = this.undoChangeHandle(state as IHistoryHandleState);
                    break;
                default:
                    debugger;
                    isApply = false;
                    break;
            }
        }
        if (isApply) {
            this.decrementPosition();
            this.service.setNeedSave(true);
            this.service.hideMenus();
            this.service.clearSelectCovers();
            this.updateControls();
        } else {
            this.service.showMessage({
                type: MESSAGE_TYPE_WARNING,
                message: i18n.t('Не удалось отменить действие!'),
                autoClose: true
            });
        }
    }

    public redoState() {
        let state: IHistoryState | undefined;
        let isApply: boolean;
        let redoPosition: number;

        redoPosition = this.position + 1;
        state = this.storage[redoPosition];
        isApply = false;
        if (state) {
            switch (state.type) {
                case HISTORY_STATE_TYPE_INIT:
                    isApply = false;
                    break;
                case HISTORY_STATE_TYPE_CREATE:
                    isApply = this.restoreObjects(state as IHistoryCreateObjectsState);
                    break;
                case HISTORY_STATE_TYPE_DELETE:
                    isApply = this.deleteObjects(state as IHistoryCreateObjectsState);
                    break;
                case HISTORY_STATE_TYPE_MOVE:
                    isApply = this.redoMoveObject(state as IHistoryMoveState);
                    break;
                case HISTORY_STATE_TYPE_CHANGE:
                    isApply = this.redoChangeObject(state as IHistoryChangeObjectsState);
                    break;
                case HISTORY_STATE_TYPE_ROOM:
                    isApply = this.redoChangeRoom(state as IHistoryRoomState);
                    break;
                case HISTORY_STATE_TYPE_EURO_ZAPIL:
                    isApply = this.redoChangeEuroZapil(state as IHistoryEuroZapilState);
                    break;
                case HISTORY_STATE_TYPE_ACCESSORY:
                    isApply = this.redoChangeAccessory(state as IHistoryAccessoryState);
                    break;
                case HISTORY_STATE_TYPE_SERVICE:
                    isApply = this.redoChangeService(state as IHistoryServiceState);
                    break;
                case HISTORY_STATE_TYPE_FACADE:
                    isApply = this.redoChangeFacade(state as IHistoryFacadeState);
                    break;
                case HISTORY_STATE_TYPE_HANDLE:
                    isApply = this.redoChangeHandle(state as IHistoryHandleState);
                    break;
                default:
                    debugger;
                    isApply = false;
                    break;
            }
        }
        if (isApply) {
            this.incrementPosition();
            this.service.setNeedSave(true);
            this.service.hideMenus();
            this.service.clearSelectCovers();
            this.updateControls();
        } else {
            this.service.showMessage({
                type: MESSAGE_TYPE_WARNING,
                message: i18n.t('Не удалось вернуть действие!'),
                autoClose: true
            });
        }
    }

    protected addToStorage(state: IHistoryState) {
        if (this.position < this.storage.length - 1) {
            this.storage.splice(++this.position, this.storage.length - this.position);
        }
        this.storage.push(state);
        this.position = this.storage.length - 1;
        if (state.type !== HISTORY_STATE_TYPE_INIT) {
            this.service.setNeedSave(true);
        }
    }

    protected decrementPosition() {
        this.position--;
        if (this.position < 0) {
            this.position = 0;
        }
    }

    protected incrementPosition() {
        if (this.position < 0) {
            this.position = 0;
        }
        this.position++;
    }

    protected resetStorage() {
        this.storage = [];
        this.position = 0;
    }

    protected restoreObjects(state: IHistoryCreateObjectsState): boolean {
        let unitData: ISaveUnitData;
        let isApply: boolean;

        isApply = true;
        if (state && state.data && state.data.objects) {
            for (unitData of state.data.objects) {
                isApply = isApply && !!this.service.createCommonObject(unitData);
            }
        } else {
            isApply = false;
        }

        return isApply;
    }

    protected deleteObjects(state: IHistoryCreateObjectsState): boolean {
        let unit: ThreeUnit | undefined;
        let unitData: ISaveUnitData;
        let isApply: boolean;

        isApply = true;
        if (state && state.data && state.data.objects) {
            for (unitData of state.data.objects) {
                unit = this.service.getObjectById(unitData.id);
                if (unit) {
                    isApply = isApply && this.service.deleteCommonObject(unit.getId());
                }
            }
        } else {
            isApply = false;

        }

        return isApply;
    }

    protected undoChangeRoom(state: IHistoryRoomState): boolean {
        if (!state.data.oldData) {
            return false;
        }
        this.service.setRoomData(state.data.oldData);

        return true;
    }

    protected redoChangeRoom(state: IHistoryRoomState): boolean {
        if (!state.data.newData) {
            return false;
        }
        this.service.setRoomData(state.data.newData);

        return true;
    }

    protected undoChangeObject(state: IHistoryChangeObjectsState): boolean {
        let threeUnit: ThreeUnit | undefined;
        let isApply: boolean;
        let objectData: IHistoryObjectData;
        let saveData: ISaveUnitData;

        isApply = true;
        if (!state.data.objects) {
            return false;
        }
        for (objectData of state.data.objects) {
            saveData = objectData.oldData;
            threeUnit = this.service.getObjectById(saveData.id);
            if (threeUnit) {
                isApply = isApply && this.service.rebuildObject(threeUnit, saveData);
            }
        }

        return isApply;
    }

    protected redoChangeObject(state: IHistoryChangeObjectsState): boolean {
        let threeUnit: ThreeUnit | undefined;
        let isApply: boolean;
        let objectData: IHistoryObjectData;
        let saveData: ISaveUnitData;

        isApply = true;
        if (!state.data.objects) {
            return false;
        }
        for (objectData of state.data.objects) {
            saveData = objectData.newData;
            threeUnit = this.service.getObjectById(saveData.id);
            if (threeUnit) {
                isApply = isApply && this.service.rebuildObject(threeUnit, saveData);
            }
        }

        return isApply;
    }

    protected redoMoveObject(state: IHistoryMoveState): boolean {
        let threeUnit: ThreeUnit | undefined;
        let isApply: boolean;
        let objectMoveData: IHistoryObjectMoveData;

        isApply = true;
        if (!state.data.objects) {
            return false;
        }
        for (objectMoveData of state.data.objects) {
            threeUnit = this.service.getObjectById(objectMoveData.objectId);
            if (!threeUnit) {
                return false;
            }
            this.applyMoveObject(threeUnit, objectMoveData.newPosition);
        }

        return isApply;
    }

    protected undoMoveObject(state: IHistoryMoveState): boolean {
        let threeUnit: ThreeUnit | undefined;
        let isApply: boolean;
        let objectMoveData: IHistoryObjectMoveData;

        isApply = true;
        if (!state.data.objects) {
            return false;
        }
        for (objectMoveData of state.data.objects) {
            threeUnit = this.service.getObjectById(objectMoveData.objectId);
            if (!threeUnit) {
                return false;
            }
            if (objectMoveData.oldPosition) {
                this.applyMoveObject(threeUnit, objectMoveData.oldPosition);
            }
        }

        return isApply;
    }

    protected undoChangeEuroZapil(state: IHistoryEuroZapilState): boolean {
        this.service.setEuroZapil(state.data.oldData);
        return true;
    }

    protected redoChangeEuroZapil(state: IHistoryEuroZapilState): boolean {
        this.service.setEuroZapil(state.data.newData);
        return true;
    }

    protected undoChangeAccessory(state: IHistoryAccessoryState): boolean {
        let isApply: boolean;

        isApply = false;
        switch (state.data.type) {
            case GOOD_TYPE_APRON:
                if (state.data.material) {
                    if (state.data.material.oldData) {
                        this.service.setApron(state.data.material.oldData as IApronData);
                    }
                    isApply = true;
                }
                if (state.data.booleanData) {
                    this.service.setShowAprons(state.data.booleanData.oldData);
                    isApply = true;
                }
                break;
            case GOOD_TYPE_TABLETOP:
                if (state.data.material) {
                    if (state.data.material.oldData) {
                        this.service.setTabletop(state.data.material.oldData as ITabletopData);
                    }
                    isApply = true;
                }
                if (state.data.booleanData) {
                    this.service.setShowTabletops(state.data.booleanData.oldData);
                    isApply = true;
                }
                break;
            case GOOD_TYPE_CORNER:
                if (state.data.material) {
                    if (state.data.material.oldData) {
                        this.service.setCorner(state.data.material.oldData as ICornerData);
                    }
                    isApply = true;
                }
                if (state.data.booleanData) {
                    this.service.setShowCorners(state.data.booleanData.oldData);
                    isApply = true;
                }
                break;
            case GOOD_TYPE_PLINTH:
                if (state.data.material) {
                    if (state.data.material.oldData) {
                        this.service.setPlinth(state.data.material.oldData as IPlinthData);
                    }
                    isApply = true;
                }
                if (state.data.booleanData) {
                    this.service.setShowPlinths(state.data.booleanData.oldData);
                    isApply = true;
                }
                break;
            case GOOD_TYPE_FACADE:
                if (state.data.material) {
                    switch (state.data.level) {
                        case LEVEL_TOP:
                            if (state.data.material.oldData) {
                                this.service.setTopFacadeMaterial(state.data.material.oldData as IFacadeMaterialData);
                            }
                            isApply = true;
                            break;
                        case LEVEL_BOTTOM:
                            if (state.data.material.oldData) {
                                this.service.setBottomFacadeMaterial(state.data.material.oldData as IFacadeMaterialData);
                            }
                            isApply = true;
                            break;
                    }
                }
                break;
            case GOOD_TYPE_FLOOR:
                if (state.data.material) {
                    if (state.data.material.oldData) {
                        this.service.setFloor(state.data.material.oldData);
                    }
                    isApply = true;
                }
                break;
            case GOOD_TYPE_WALL:
                if (state.data.material) {
                    if (state.data.material.oldData) {
                        this.service.setWall(state.data.material.oldData);
                    }
                    isApply = true;
                }
                break;
            case 'kitCode':
                if (state.data.selectData) {
                    if (state.data.selectData.oldData) {
                        this.service.setSelectKitCode(state.data.selectData.oldData);
                    }
                    isApply = true;
                }
                break;
        }

        return isApply;
    }

    protected redoChangeAccessory(state: IHistoryAccessoryState): boolean {
        let isApply: boolean;

        isApply = false;
        switch (state.data.type) {
            case GOOD_TYPE_APRON:
                if (state.data.material) {
                    this.service.setApron(state.data.material.newData as IApronData);
                    isApply = true;
                }
                if (state.data.booleanData) {
                    this.service.setShowAprons(state.data.booleanData.newData);
                    isApply = true;
                }
                break;
            case GOOD_TYPE_TABLETOP:
                if (state.data.material) {
                    this.service.setTabletop(state.data.material.newData as ITabletopData);
                    isApply = true;
                }
                if (state.data.booleanData) {
                    this.service.setShowTabletops(state.data.booleanData.newData);
                    isApply = true;
                }
                break;
            case GOOD_TYPE_CORNER:
                if (state.data.material) {
                    this.service.setCorner(state.data.material.newData as ICornerData);
                    isApply = true;
                }
                if (state.data.booleanData) {
                    this.service.setShowCorners(state.data.booleanData.newData);
                    isApply = true;
                }
                break;
            case GOOD_TYPE_PLINTH:
                if (state.data.material) {
                    this.service.setPlinth(state.data.material.newData as IPlinthData);
                    isApply = true;
                }
                if (state.data.booleanData) {
                    this.service.setShowPlinths(state.data.booleanData.newData);
                    isApply = true;
                }
                break;
            case GOOD_TYPE_FACADE:
                if (state.data.material) {
                    switch (state.data.level) {
                        case LEVEL_TOP:
                            this.service.setTopFacadeMaterial(state.data.material.newData as IFacadeMaterialData);
                            isApply = true;
                            break;
                        case LEVEL_BOTTOM:
                            this.service.setBottomFacadeMaterial(state.data.material.newData as IFacadeMaterialData);
                            isApply = true;
                            break;
                    }
                }
                break;
            case GOOD_TYPE_FLOOR:
                if (state.data.material) {
                    this.service.setFloor(state.data.material.newData);
                    isApply = true;
                }
                break;
            case GOOD_TYPE_WALL:
                if (state.data.material) {
                    this.service.setWall(state.data.material.newData);
                    isApply = true;
                }
                break;
            case 'kitCode':
                if (state.data.selectData) {
                    this.service.setSelectKitCode(state.data.selectData.newData);
                    isApply = true;
                }
                break;
        }

        return isApply;
    }

    protected undoChangeService(state: IHistoryServiceState): boolean {
        switch (state.data.type) {
            case 'all':
                this.service.setEnableServices(state.data.oldData);
                break;
            case 'auto':
                this.service.setEnableAutoServices(state.data.oldData)
        }
        return true;
    }

    protected redoChangeService(state: IHistoryServiceState): boolean {
        switch (state.data.type) {
            case 'all':
                this.service.setEnableServices(state.data.newData);
                break;
            case 'auto':
                this.service.setEnableAutoServices(state.data.newData)
        }
        return true;
    }

    protected undoChangeFacade(state: IHistoryFacadeState): boolean {
        if (state.data.oldData) {
            this.service.setSelectFacade(state.data.oldData.facadeId, state.data.oldData.level);
        }
        return true;
    }

    protected redoChangeFacade(state: IHistoryFacadeState): boolean {
        this.service.setSelectFacade(state.data.newData.facadeId, state.data.newData.level);
        return true;
    }

    protected undoChangeHandle(state: IHistoryHandleState): boolean {
        if (state.data.oldData) {
            switch (state.data.oldData.level) {
                case LEVEL_BOTTOM:
                    this.service.setBottomHandle(state.data.oldData.handle);
                    break;
                case LEVEL_TOP:
                    this.service.setTopHandle(state.data.oldData.handle);
                    break;
            }
        }
        return true;
    }

    protected redoChangeHandle(state: IHistoryHandleState): boolean {
        switch (state.data.newData.level) {
            case LEVEL_BOTTOM:
                this.service.setBottomHandle(state.data.newData.handle);
                break;
            case LEVEL_TOP:
                this.service.setTopHandle(state.data.newData.handle);
                break;
        }
        return true;
    }

    protected applyMoveObject(threeUnit: ThreeUnit, moveData: IPositionInfo) {
        let wall: ThreeWall | undefined;

        threeUnit.setPosition(new Vector3(
            moveData.position.x,
            moveData.position.y,
            moveData.position.z
        ));
        threeUnit.setRotation(new Euler(
            moveData.rotation.x,
            moveData.rotation.y,
            moveData.rotation.z
        ));
        if (moveData.wall) {
            wall = this.service.getWallById(moveData.wall);
        }
        threeUnit.setWall(wall);
        threeUnit.setCenterPosition();
        threeUnit.afterHistoryMove();
    }
}