import React from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_HIDDEN_TEXT} from '../../../../../common-code/constants';
import {IObjectOptionProps} from '../ObjectOption';
import {IOptionHiddenText} from '../../../../../common-code/interfaces/option/IOptionHiddenText';
import {cnObjectOption} from '../index';


export interface IObjectOptionTypeHiddenTextProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_HIDDEN_TEXT;
    option?: IOptionHiddenText;
}

const ObjectOptionTypeHiddenText: React.FC<IObjectOptionTypeHiddenTextProps> =
    ({
         option,
         className
    }) => {
    if (!option) {
        return null;
    }
    return (
        <input type={'hidden'} className={cnObjectOption('HiddenNumber', [className])} value={option.value}/>
    );
}

export const withObjectOptionTypeHiddenText = withBemMod<IObjectOptionTypeHiddenTextProps, IObjectOptionProps>(
    cnObjectOption(),
    { type: OPTION_TYPE_HIDDEN_TEXT },
    () => ObjectOptionTypeHiddenText
);