import {IClassNameProps} from "@bem-react/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {cnAuth} from '../Auth';
import {cnModal, Modal} from '../../../components/Modal';
import {cnCity} from '../../City';
import {cnLoginForm, LoginForm} from '../../LoginForm';
import {Svg} from "../../../components/Svg";
import {THeaderType} from '../../../../types/THeaderType';
import {useHeaderType} from '../../../../hooks/useHeaderType';
import {HEADER_TYPE_BIG} from '../../../constants';

export interface IAuthGuestProps extends IClassNameProps {

}

export const AuthGuest: React.FC<IAuthGuestProps> = ({className}) => {
    const {t} = useTranslation();
    const [showModal, onShowModal] = useState(false);
    const [hover, setHover] = useState(false);
    const headerType: THeaderType = useHeaderType();

    const showModalLoginForm = () => {
        onShowModal(!showModal);
    };

    return (
        <div className={cnAuth('Guest', [className])}>
            <div
                className={cnAuth('User', {hover})}
                onClick={showModalLoginForm}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
            >
                <Svg
                    className={cnAuth('Icon')}
                    icon={'login'}
                    label={t('Войти') || undefined}
                    style={{width: `${25}px`, height: `${25}px`, marginRight: `${8}px`}}
                />
                {headerType === HEADER_TYPE_BIG ? <p className={cnAuth('Text')}>{t('Войти')}</p> : null}
            </div>
            <Modal
                className={cnCity(cnModal())}
                visible={showModal}
                callBack={onShowModal}
            >
                <LoginForm className={cnCity(cnLoginForm())}/>
            </Modal>
        </div>
    );
};