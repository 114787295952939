import {ThreeCorpus} from '../ThreeCorpus';

export class ThreeEmptyCorpus extends ThreeCorpus {

    protected createShapePanels() {

    }
    protected createPanels() {

    }
    protected createFrames() {

    }
    protected createShelves() {

    }
    protected createBoxes() {

    }
    protected createBackPanels() {

    }
}