import React from 'react'
import { Select } from '../../../../../components/Select/Select'
import { cnCreateForm } from '../../FormModule'
import { editOption } from '../../../utils/editOption'
import { CLASSNAME_LIST as classNamesList } from 'common-code/lib/constants'
import { ChangeEvent } from '@yandex/ui/Menu/Menu'
import { OPTION_TYPE_HIDDEN_TEXT } from "common-code/lib/constants"
import { useFormContext } from 'react-hook-form'
import { t } from 'i18next'
import { IOptionHiddenText } from '../../../../../../../common-code/interfaces/option/IOptionHiddenText'

function ClassName({classNameValue, handleChangeClass} : 
    {classNameValue: string, handleChangeClass: (e: ChangeEvent<HTMLSelectElement>) => void}) {

    const {setValue} = useFormContext();
   
      const className = {
        id: "className",
        value: classNameValue,
        sort: 4,
        title: "Класс объекта",
        type: OPTION_TYPE_HIDDEN_TEXT,
      } as IOptionHiddenText;

      React.useEffect(() => {        
        setValue("className", className);
        // eslint-disable-next-line
      }, [classNameValue]);

  return (
    <div>
        <label>{t('Класс объекта')}</label>
        <Select
            className={cnCreateForm('Select')}
            placeholder={  "Выберите наименование класса модулей *"}
            showPlaceholder={false}
            options={editOption(classNamesList)}
            value={classNameValue}
            callBack={handleChangeClass}
        />
    </div>
  )
}

export default ClassName