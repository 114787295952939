import React, {useEffect, useState} from "react";
import {IClassNameProps} from "@bem-react/core";
import './Wizard-AdminMenu.css';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {cnWizard} from '../Wizard';
import {Modal} from '../../../components/Modal';
import {Button} from '../../../components/Button';
import {useTranslation} from 'react-i18next';
import {HIDE_LOADING, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS, SHOW_LOADING} from '../../../../constants';
import {useDispatch} from 'react-redux';
import {showMessage} from '../../../../helpers';

export interface IWizardAdminMenuProps extends IClassNameProps {
    service: KitchenService;
    onClose: (value: boolean) => void;
}

export const WizardAdminMenu: React.FC<IWizardAdminMenuProps> = ({className, onClose, service}) => {
    const [showAdminMenu, setShowAdminMenu] = useState<boolean>(false);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.shiftKey) {
                switch (event.code) {
                    case "KeyA":
                        event.preventDefault();
                        setShowAdminMenu(true);
                        break;
                }
            }
        }
        window.addEventListener('keydown', onKeyDown, false);
        return () => {
            window.removeEventListener('keydown', onKeyDown, false);
        }
    }, []);

    const closeModal = () => {
        setShowAdminMenu(false);
        onClose(false);
    }

    const tryExportModulesFeed = (createImages: boolean) => {
        dispatch({type: SHOW_LOADING});
        setShowAdminMenu(false);
        service.createModulesFeed(createImages, 'default').then(() => {
            dispatch({type: HIDE_LOADING});
            showMessage({type: MESSAGE_TYPE_SUCCESS, message: t('Формирование фида модулей завершено успешно')}, dispatch);
        }).catch(() => {
            showMessage({type: MESSAGE_TYPE_ERROR, message: t('Ошибка на стороне сервера')}, dispatch);
            dispatch({type: HIDE_LOADING});
        })
    }

    const disableCatalog = () => {
        service.disableCatalog();
    }

    return (
        <Modal
            className={cnWizard('AdminMenuModal')}
            visible={showAdminMenu}
            callBack={closeModal}
            key={'AdminMenuModal'}
        >
        <div className={cnWizard('AdminMenu', [className])}>
            <Button
                className={cnWizard('AdminMenuButton')}
                type={'button'}
                rightIcon={{path: 'plus', width: 15, height: 15, marginLeft: 3}}
                text={t('Пересчитать каталог')}
                mods={{mods: {color: 'lightBlack', bkg: 'white', border: 'gray', fill: 'lightBlack'}}}
                callBack={disableCatalog}
            />
            <Button
                className={cnWizard('AdminMenuButton')}
                type={'button'}
                rightIcon={{path: 'plus', width: 15, height: 15, marginLeft: 3}}
                text={t('Экспорт модулей с картинками')}
                mods={{mods: {color: 'lightBlack', bkg: 'white', border: 'gray', fill: 'lightBlack'}}}
                callBack={() => {
                    tryExportModulesFeed(true)
                }}
            />
            <Button
                className={cnWizard('AdminMenuButton')}
                type={'button'}
                rightIcon={{path: 'plus', width: 15, height: 15, marginLeft: 3}}
                text={t('Экспорт модулей без картинок')}
                mods={{mods: {color: 'lightBlack', bkg: 'white', border: 'gray', fill: 'lightBlack'}}}
                callBack={() => {
                    tryExportModulesFeed(false)
                }}
            />
        </div>
        </Modal>
    );
};