import {ThreeApron} from './ThreeApron';
import {Euler, MeshStandardMaterial, Vector2, Vector3} from 'three';
import {TPoint2D} from '../../../../../../common-code/types/TPoint2D';
import {CommonObjectHelper} from '../../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {TSideType} from '../../../../../../common-code/types/TSideType';
import {ISaveKUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {MathHelper} from 'common-code';

export class ThreeApronUnion extends ThreeApron {
    aprons: ThreeApron[];

    constructor(options: ISaveKUnitDetailData, aprons: ThreeApron[]) {
        let firstApron: ThreeApron;

        firstApron = aprons[0];
        if (!firstApron) {
            throw new Error('error-ThreeApronUnion-constructor');
        }
        super(options, firstApron.unit);
        this.aprons = aprons;
    }

    public getChildren(): ThreeApron[] {
        return this.aprons;
    }

    public getSortChildIds(): string[] {
        let ids: { [n: number]: number } = {};
        let apron: ThreeApron;

        for (apron of this.aprons) {
            ids[apron.getId()] = apron.getId();
        }

        return Object.keys(ids).sort((a: string, b: string) => {
            return +a - +b;
        });
    }

    public hide() {
        super.hide();
        this.setIsUnionAprons(false);

    }

    public show() {
        super.show();
        this.setIsUnionAprons(true);

    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        this.setIsUnionAprons(true);
    }

    public remove() {
        this.setIsUnionAprons(false);
        this.service.removeFromSceneObject3D(this.view3d);
        super.remove();
    }

    public setIsUnionAprons(isUnion: boolean) {
        let apron: ThreeApron;

        for (apron of this.aprons) {
            apron.setIsUnion(isUnion);
        }
    }
    public getFirstPlinth() {

    }

    public getBodyMaterial(): MeshStandardMaterial {
        return new MeshStandardMaterial({
            color: this.materialData.color || '#ffffff',
            map: this.materialTextures.texture || null,
            normalMap: this.materialTextures.normal || null,
            roughnessMap: this.materialTextures.roughness || null,
            envMapIntensity: 5
        })
    }

    public getFirstApron(): ThreeApron {
        return this.aprons[0];
    }

    public getGlobalPosition() {
        return this.view3d.position;
    }

    public getGlobalRotation(): Euler {
        return this.view3d.rotation;
    }

    public getLength(): number {
        let apron: ThreeApron;
        let length: number = 0;

        for (apron of this.aprons) {
            length += apron.getLength();
        }

        return length;
    }

    public setCenterPosition() {
        this.view3d.userData.centerPosition = this.view3d.position;
    }

    public getFrontVectorAngle(): number {
        let normalVector;
        let firstApron, lastApron;
        let firstPosition, lastPosition;
        let positionAngle, coverAngle;
        let globalPoints, direction;
        let angle;

        firstApron = this.aprons[0];
        lastApron = this.aprons[this.aprons.length - 1];
        globalPoints = firstApron.unit.getGlobalPoints();
        firstPosition = firstApron.getGlobalPosition();
        lastPosition = lastApron.getGlobalPosition();
        normalVector = new Vector2(
            lastPosition.x - firstPosition.x, lastPosition.z - firstPosition.z
        );
        angle = Math.atan2(normalVector.x, normalVector.y);
        if (angle < 0) {
            angle += 2 * Math.PI;
        }
        positionAngle = MathHelper.getNormalAngle({
            x: lastPosition.x - firstPosition.x,
            y: lastPosition.z - firstPosition.z
        });
        coverAngle = MathHelper.getNormalAngle({
            x: globalPoints.bottom.A.x - globalPoints.bottom.B.x,
            y: globalPoints.bottom.A.z - globalPoints.bottom.B.z
        });
        if (+positionAngle.toFixed(3) === +coverAngle.toFixed(3)) {
            direction = 1;
        } else {
            direction = -1;
        }
        angle = angle + direction * 0.5 * Math.PI;
        if (angle >= 2 * Math.PI) {
            angle -= 2 * Math.PI;
        }

        return angle;
    }

    public getDefaultPoints(type: TSideType): Vector2[] {
        let firstApron, lastApron,
            firstPosition, lastPosition,
            globalPoints,
            positionAngle, apronsAngle,
            leftPoints, rightPoints;

        firstApron = this.aprons[0];
        lastApron = this.aprons[this.aprons.length - 1];

        firstPosition = firstApron.getGlobalPosition();
        lastPosition = lastApron.getGlobalPosition();
        globalPoints = firstApron.getGlobalMainPoints();
        positionAngle = MathHelper.getNormalAngle({
            x: lastPosition.x - firstPosition.x,
            y: lastPosition.z - firstPosition.z
        });
        apronsAngle = MathHelper.getNormalAngle({
            x: globalPoints.bottom.pointB.x - globalPoints.bottom.pointA.x,
            y: globalPoints.bottom.pointB.z - globalPoints.bottom.pointA.z
        });
        if (+positionAngle.toFixed(3) === +apronsAngle.toFixed(3)) {
            leftPoints = firstApron.getLeftPoints();
            rightPoints = lastApron.getRightPoints();
        } else {
            leftPoints = lastApron.getLeftPoints();
            rightPoints = firstApron.getRightPoints();
        }
        switch (type) {
            case 'left':
                return leftPoints;
            case 'right':
                return rightPoints;
            default:
                return super.getDefaultPoints(type);
        }
    }

    protected addToScene() {
        this.service.addToSceneObject3D(this.view3d);
    }

    protected initPosition(): Vector3 {
        let position2d: TPoint2D;
        let position: Vector3;

        position2d = CommonObjectHelper.calculateUnionPosition2DByDetails(this.aprons);
        position = new Vector3(position2d.x, this.unit.getUnionDetailYPosition(this.aprons[0]), position2d.y);

        return position;
    }

    protected initRotation(): Euler {
        return CommonObjectHelper.calculateUnionRotationByDetails(this.aprons);
    }

}