import {ThreeTabletop} from './ThreeTabletop';
import {Euler, MeshStandardMaterial, Vector2, Vector3} from 'three';
import {TPoint2D} from '../../../../../../common-code/types/TPoint2D';
import {CommonObjectHelper} from '../../../../helpers/CommonObjectHelper/CommonObjectHelper';
import {TSideType} from '../../../../../../common-code/types/TSideType';
import {ISaveKUnitDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {MathHelper} from 'common-code';

export class ThreeTabletopUnion extends ThreeTabletop {
    tabletops: ThreeTabletop[];

    constructor(options: ISaveKUnitDetailData, tabletops: ThreeTabletop[]) {
        let firstTabletop: ThreeTabletop;

        firstTabletop = tabletops[0];
        if (!firstTabletop) {
            throw new Error('error-ThreeTabletopUnion-constructor');
        }
        super(options, firstTabletop.unit);
        this.tabletops = tabletops;
    }

    public getChildren(): ThreeTabletop[] {
        return this.tabletops;
    }

    public getSortChildIds(): string[] {
        let ids: { [n: number]: number } = {};
        let tabletop: ThreeTabletop;

        for (tabletop of this.tabletops) {
            ids[tabletop.getId()] = tabletop.getId();
        }

        return Object.keys(ids).sort((a: string, b: string) => {
            return +a - +b;
        });
    }

    public hide() {
        super.hide();
        this.setIsUnionTabletops(false);

    }

    public show() {
        super.show();
        this.setIsUnionTabletops(true);
    }

    public setIsUnionTabletops(isUnion: boolean) {
        let tabletop: ThreeTabletop;

        for (tabletop of this.tabletops) {
            tabletop.setIsUnion(isUnion);
        }
    }

    public initState(isRebuild?: boolean) {
        super.initState(isRebuild);
        this.setIsUnionTabletops(true);
    }

    protected addToScene() {
        this.service.addToSceneObject3D(this.view3d);
    }

    public remove() {
        this.setIsUnionTabletops(false);
        this.service.removeFromSceneObject3D(this.view3d);
        super.remove();
    }

    public getBodyMaterial(): MeshStandardMaterial {
        return new MeshStandardMaterial({
            color: this.getFirstTabletop().materialData.color || '#ffffff',
            map: this.getFirstTabletop().materialTextures.texture || null,
            normalMap: this.getFirstTabletop().materialTextures.normal || null,
            roughnessMap: this.getFirstTabletop().materialTextures.roughness || null,
            envMapIntensity: 5
        })
    }

    public getFirstTabletop(): ThreeTabletop {
        if (!this.tabletops[0] || !(this.tabletops[0] instanceof ThreeTabletop)) {
            throw new Error('error-ThreeTabletopUnion-getFirstTabletop');
        }
        return this.tabletops[0];
    }

    public getLastTabletop(): ThreeTabletop {
        if (!this.tabletops[this.tabletops.length - 1] || !(this.tabletops[this.tabletops.length - 1] instanceof ThreeTabletop)) {
            throw new Error('error-ThreeTabletopUnion-getLastTabletop');
        }
        return this.tabletops[this.tabletops.length - 1];
    }

    public getGlobalPosition() {
        return this.view3d.position;
    }

    public getGlobalRotation(): Euler {
        return this.view3d.rotation;
    }

    public getLength(): number {
        let tabletop: ThreeTabletop;
        let length: number = 0;

        for (tabletop of this.tabletops) {
            length += tabletop.getLength();
        }

        return length;
    }

    public setCenterPosition() {
        this.view3d.userData.centerPosition = this.view3d.position;
    }

    protected initPosition(): Vector3 {
        let position2d: TPoint2D;
        let position: Vector3;

        position2d = CommonObjectHelper.calculateUnionPosition2DByDetails(this.tabletops);
        position = new Vector3(position2d.x, this.unit.getUnionDetailYPosition(this.tabletops[0]), position2d.y);

        return position;
    }

    protected initRotation(): Euler {
        return CommonObjectHelper.calculateUnionRotationByDetails(this.tabletops);
    }

    public getDefaultPoints(type: TSideType): Vector2[] {
        let firstTabletop, lastTabletop,
            firstPosition, lastPosition,
            globalPoints,
            positionAngle, tabletopsAngle,
            leftPoints, rightPoints;

        firstTabletop = this.getFirstTabletop();
        lastTabletop = this.getLastTabletop();

        firstPosition = firstTabletop.getGlobalPosition();
        lastPosition = lastTabletop.getGlobalPosition();
        globalPoints = firstTabletop.getGlobalMainPoints();
        positionAngle = MathHelper.getNormalAngle({
            x: lastPosition.x - firstPosition.x,
            y: lastPosition.z - firstPosition.z
        });
        tabletopsAngle = MathHelper.getNormalAngle({
            x: globalPoints.back.pointB.x - globalPoints.back.pointA.x,
            y: globalPoints.back.pointB.z - globalPoints.back.pointA.z
        });
        if (+positionAngle.toFixed(3) === +tabletopsAngle.toFixed(3)) {
            leftPoints = firstTabletop.getLeftPoints();
            rightPoints = lastTabletop.getRightPoints();
        } else {
            leftPoints = lastTabletop.getLeftPoints();
            rightPoints = firstTabletop.getRightPoints();
        }
        switch (type) {
            case 'left':
                return leftPoints;
            case 'right':
                return rightPoints;
            default:
                return super.getDefaultPoints(type);
        }
    }
}