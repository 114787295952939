import React, { useEffect, useState } from 'react'
import Shelf from './Shelf';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../../../../components/Button';
import { cnCreateForm } from '../../../FormModule';
import { OPTION_TYPE_JSON } from "common-code/lib/constants";
import { IOption } from 'common-code/lib/interfaces/option/IOption';
import { IOptionHiddenJson } from '../../../../../../../../common-code/interfaces/option/IOptionHiddenJson';
import { ISaveShelfData } from 'common-code/lib/interfaces/saveData/ISaveShelfData';
import { SHELF_TYPE_HORIZONTAL } from 'common-code/lib/constants';
import { TShelfType } from 'common-code/lib/types/TShelfType';
import ModalShelves from './ModalShelves';
import { initialShelf } from './initialShelf';
import { validateShelf } from './validateShelf';
import { IFormInput } from '../../../initialValue';

function Shelves() {

    const { setValue, getValues } = useFormContext<IFormInput>();
    const shelvesInitional = getValues("shelves") ? getValues("shelves")?.value : [];
    const [arrShelves, setArrShelves] = React.useState <ISaveShelfData[]> (shelvesInitional);
    const [modal, setModal] = React.useState<boolean>(false);
    
    const shelvesData: IOption  = 
    {
        id: "shelves",
        title: "Полки",
        sort: 4,
        type: OPTION_TYPE_JSON,
        value: arrShelves,
    } as  IOptionHiddenJson;

    useEffect(() => {
        if(arrShelves.length > 0) {        
            setValue("shelves", shelvesData);
        } else {
            setValue("shelves", null);
        }
        // eslint-disable-next-line
    }, [arrShelves]);

    const [newShelf, setNewShelf] = useState<Record<string, string | boolean>>(initialShelf(null));
  
    const {  type } = newShelf;
    
    const distanceShelf = {
        'top' : `Указать расстояние в мм ${type === SHELF_TYPE_HORIZONTAL ? "от верха" : "справа"}`,
        'center' : "Установка полки по центру",
        'bottom' : `Указать расстояние в мм ${type === SHELF_TYPE_HORIZONTAL ? "от низа" : "от левой стенки корпуса"}`,
        'percent' :  `Указать расстояние в % ${type === SHELF_TYPE_HORIZONTAL ? "от низа" : "от левой стенки корпуса"}`
    };

    const calculatePosition = (distance: string, position: string) => {
        switch (distance) {
            case 'center':
                return '%50';
            case 'top':
                return `=({%100} - ${position})`;
            case 'bottom':
                return position;
            case 'percent':
                return `%${position}`;
        }
    }

    const createShelf = ({ length, depth, thickness, initPosition, type, distance, fixed, rotation, shelfRotationX, shelfRotationY, shelfRotationZ }: Record<string, string | boolean>) => {

        const axis = type === SHELF_TYPE_HORIZONTAL ? "y" : "x";
        const dataShelf: ISaveShelfData = {
            id: 0,
            length: `%${length}`,
            depth: depth ? `=({%100} - ${depth})` : '%100',
            initPosition: {[axis] : calculatePosition(distance as string, initPosition as string)},
            type: type as TShelfType,
            thickness: Number(thickness),
            fixed: fixed as boolean
        };
        if(rotation) {
            dataShelf.rotation = {
                x: Number(shelfRotationX)/180 * Math.PI,
                y: Number(shelfRotationY)/180 * Math.PI,
                z: Number(shelfRotationZ)/180 * Math.PI,
            }
        }
        return dataShelf
    }

    const handleSubmit =  () => {
        const depthCorpus = getValues('depth');
        const maxHeight = getValues('height');
        const defaultWidth = getValues('defaultWidth');
        
        const error = validateShelf(newShelf, depthCorpus, maxHeight, defaultWidth );
        if (error) {
            setNewShelf({ ...newShelf, error });
            return;
        }

        const dataShelf = createShelf(newShelf);
        
        setArrShelves([...arrShelves, dataShelf]);
        setModal(false);
    }

    const handleToggleModal = () => {
        setModal(!modal);
    }
    
  return (
    <>  
        <h3 className={cnCreateForm('Header')}>Полки</h3>

        {arrShelves.map((shelf, index) =>
                <Shelf 
                    arrShelves={arrShelves} 
                    setArrShelves={setArrShelves} 
                    shelf={shelf} 
                    key={`${index}${shelf.initPosition?.x}
                    ${shelf.initPosition?.y}
                    ${shelf.initPosition?.z}`} 
                    index={index}
                    distanceShelf={distanceShelf} 
                    createShelf={createShelf}
                />
            )
        }

        <Button
            className={cnCreateForm('Button')}
            type={'button'}
            text={`Добавить полку`}
            leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
            mods={{
                mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
            }}
            callBack={handleToggleModal}
        />

        <ModalShelves
            modal={modal}
            handleToggleModal={handleToggleModal}
            handleSubmit={handleSubmit}
            newShelf={newShelf}
            distanceShelf={distanceShelf}
            setNewShelf={setNewShelf}
            keyValue={`#addShelf`}
        />

        <hr/>
    </>
  )
}

export default Shelves