import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Vector3} from 'three';
import {ThreeBottomUnitEndPilaster} from "../../../units/ThreeBottomUnit/types/ThreeBottomUnitEndPilaster";
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from "../../../../../../../common-code/constants";
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';


export class ThreeBottomEndPilasterCorpus extends ThreeCorpus {
    unit: ThreeBottomUnitEndPilaster;

    constructor(options: ISaveCorpusData, unit: ThreeBottomUnitEndPilaster) {
        super(options, unit);
        this.unit = unit;
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth()),
            'top',
            new Vector3(
                0,
                this.getHeight() / 2 - this.getThickness() / 2,
                0
            )
        );
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                this.createPanel(
                    new BoxGeometry(this.getThickness(), this.getWidth(), this.getDepth()),
                    'leftTop',
                    new Vector3(
                        this.getWidth() / 2 - this.getThickness() / 2,
                        this.getHeight() / 2 - this.getThickness() - this.getWidth() / 2,
                        0
                    )
                );
                this.createPanel(
                    new BoxGeometry(this.getThickness(), this.getWidth(), this.getDepth()),
                    'leftBottom',
                    new Vector3(
                        this.getWidth() / 2 - this.getThickness() / 2,
                        -this.getHeight() / 2 + this.getThickness() + this.getWidth() / 2,
                        0
                    )
                );
                this.createPanel(
                    new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness() * 2, this.getDepth()),
                    'right',
                    new Vector3(
                        -this.getWidth() / 2 + this.getThickness() / 2,
                        0,
                        0
                    )
                );
                break;

            case SIDE_TYPE_RIGHT:
                this.createPanel(
                    new BoxGeometry(this.getThickness(), this.getWidth(), this.getDepth()),
                    'leftTop',
                    new Vector3(
                        -this.getWidth() / 2 + this.getThickness() / 2,
                        this.getHeight() / 2 - this.getThickness() - this.getWidth() / 2,
                        0
                    )
                );
                this.createPanel(
                    new BoxGeometry(this.getThickness(), this.getWidth(), this.getDepth()),
                    'leftBottom',
                    new Vector3(
                        -this.getWidth() / 2 + this.getThickness() / 2,
                        -this.getHeight() / 2 + this.getThickness() + this.getWidth() / 2,
                        0
                    )
                );
                this.createPanel(
                    new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness() * 2, this.getDepth()),
                    'right',
                    new Vector3(
                        this.getWidth() / 2 - this.getThickness() / 2,
                        0,
                        0
                    )
                );
                break;
        }
    };

    protected createBackPanels() {

    }
}