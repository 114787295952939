import axios from "axios";
import { ITechnologMaps } from "../../../../../common-code/interfaces/ITechnologMaps";

export const saveTecnologMaps = async (TechnologMaps: ITechnologMaps, path: string, id: string): Promise<ITechnologMaps | undefined> => {
    const response = await axios.post<ITechnologMaps>(
      `/api/entity/${path}/${id}`,
      JSON.stringify(TechnologMaps),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
  
    return response.data;
  };
