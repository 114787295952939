import { LEVEL_BOTTOM, LEVEL_TOP } from "common-code/lib/constants";
import { IMaterialData } from "common-code/lib/interfaces/materials/IMaterialData";

export const levels = [
    { value: LEVEL_BOTTOM, content: "Нижний уровень" },
    { value: LEVEL_TOP, content: "Верхний уровень" },
  ];

export const initialErrors = {
    title: "",
  };

export const defaultValues = (item: IMaterialData | null) =>{
    return item ? {
        id: item.id,
        title: item.title, 
        level: item.level ? item.level :  undefined,
        color: item.color ? item.color : '',
        emissiveColor: item.emissiveColor ? item.emissiveColor : '',
        isDefault: item.isDefault ? item.isDefault : false,
        textures: item.textures ? item.textures :[],
        texturesNew: [],
        image: item.image ? item.image : '',
        imageNew: null,
        enableFacades: item.enableFacades ? item.enableFacades : [],
        disableFacades: item.disableFacades ? item.disableFacades : [],
        plinthMaterial: item.plinthMaterial ? item.plinthMaterial : '',
    } : {
        id: '',
        title: '' , 
        level: undefined,
        color: '',
        emissiveColor: '',
        isDefault: false,
        textures: [],
        texturesNew: [],
        image: '',
        imageNew: null,
        enableFacades: [],
        disableFacades: [],
        plinthMaterial: '',
    }
}
