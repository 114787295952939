import React, { MutableRefObject } from 'react'
import { IClassNameProps } from '@bem-react/core'
import { KitchenService } from '../../../../3d/services/KitchenService/KitchenService'
import { ICreateObjectDomElements } from '../../../../interfaces/ICreateObjectDomElements'
import { ICreateGroup } from '../../../../../common-code/interfaces/createData/ICreateGroup'
import { WizardEditModules } from '../EditModules/Wizard-Edit-Modules'
import { WizardCollection } from '../WizardCollection/WizardCollection'
import { cn } from '@bem-react/classname'
import {
    EDIT_MODE_CORPUS_MATERIALS,
    EDIT_MODE_FACADE_MATERIALS,
    EDIT_MODE_FACADES,
    EDIT_MODE_HANDLES,
    EDIT_MODE_MATERIALS,
    EDIT_MODE_PLINTHS,
    GROUP_BOTTOM_ANGLE_UNITS,
    GROUP_BOTTOM_END_UNITS,
    GROUP_BOTTOM_NORMAL_UNITS,
    GROUP_PENAL_UNITS,
    GROUP_TOP_ANGLE_UNITS,
    GROUP_TOP_END_UNITS,
    GROUP_TOP_NORMAL_UNITS,
} from 'common-code/lib/constants'
import * as unitListServices from '../api/UnitListServices'
import * as materialServices from '../api/MaterialServices'
import { useEffectDidMount } from '../../../../hooks/useEffectDidMount'
import { useDispatch } from 'react-redux'
import {
    CHANGE_CORPUS_MATERIALS,
    CHANGE_EDIT_MODE,
    CHANGE_FACADE_MATERIALS,
    CHANGE_FACADES,
    CHANGE_HANDLES,
    CHANGE_MATERIALS,
    CHANGE_PLINTHS,
    HIDE_LOADING,
    INIT_EDIT_MATERIAL,
    INIT_EDIT_MODULE,
} from '../../../../constants'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../redux/AppStore'
import { IMaterialData } from 'common-code/lib/interfaces/materials/IMaterialData'
import WisardEditEditMode from '../WisardEditMode/WisardEditMode'
import { useUrlSearchParam } from '../../../../hooks/useSearchParams'
import './WizardEditCurrent.css'
import { TMaterialEditMode } from '../../../../../../../common/types/TMaterialEditMode'

export const cnWizardEditCurrent = cn('WizardEditCurrent')

export interface IWizardEditCurrentProps extends IClassNameProps {
    service: KitchenService
    createObjects: MutableRefObject<ICreateObjectDomElements>
    isInitState?: boolean
}

export const WizardEditCurrent: React.FC<IWizardEditCurrentProps> = ({
    service,
    createObjects,
    isInitState,
}) => {
    const dispatch = useDispatch()

    const isEditMode = useSelector(
        (state: AppState) => state.wizard.editModule.isEditMode
    )

    // коллекции модулей
    const [unitListState, setUnitListState] = React.useState<
        ICreateGroup[] | undefined
    >(undefined)

    // получаем все модули для коллекции из сервера
    const unitList = service?.getCreateGroupUnits([
        GROUP_BOTTOM_ANGLE_UNITS,
        GROUP_BOTTOM_END_UNITS,
        GROUP_BOTTOM_NORMAL_UNITS,
        GROUP_PENAL_UNITS,
        GROUP_TOP_ANGLE_UNITS,
        GROUP_TOP_END_UNITS,
        GROUP_TOP_NORMAL_UNITS,
    ])
    const collectionId = useUrlSearchParam('collection')

    // проверка наличия модулей в коллекции в файле
    useEffectDidMount(() => {
        unitListServices
            .initUnitList(collectionId as string)
            .then((data: ICreateGroup[] | undefined) => {
                if (data && data.length > 0) {
                    setUnitListState(data)
                }
            })
        dispatch({ type: INIT_EDIT_MODULE })
        dispatch({ type: INIT_EDIT_MATERIAL })
    })

    React.useEffect(() => {
        if (isInitState && isEditMode && service) {
            service.startRender()
        } else if (isInitState && !isEditMode && service) {
            service.clearEditorScene()
        }
        // eslint-disable-next-line
    }, [isEditMode])

    // запись коллекции модулей в файл
    const saveUnitList = async (unitListNew: ICreateGroup[]) =>
        await unitListServices
            .saveUnitList(unitListNew as ICreateGroup[], collectionId as string)
            .then((data: ICreateGroup[] | undefined) => {
                setUnitListState(data as ICreateGroup[])
                dispatch({ type: CHANGE_EDIT_MODE, payload: '' })
                dispatch({ type: HIDE_LOADING })
            })
            .catch(() => {
                alert(
                    'Произошла ошибка при сохранении коллекции модулей. Попробуйте еще раз.'
                )
                dispatch({ type: HIDE_LOADING })
            })
    const typeAction = (materialMode: TMaterialEditMode) => {
        switch (materialMode) {
            case EDIT_MODE_MATERIALS:
                return CHANGE_MATERIALS
            case EDIT_MODE_CORPUS_MATERIALS:
                return CHANGE_CORPUS_MATERIALS
            case EDIT_MODE_FACADE_MATERIALS:
                return CHANGE_FACADE_MATERIALS
            case EDIT_MODE_FACADES:
                return CHANGE_FACADES
            case EDIT_MODE_PLINTHS:
                return CHANGE_PLINTHS
            case EDIT_MODE_HANDLES:
                return CHANGE_HANDLES
        }
    }

    // сохраням материалы
    const saveMaterial = async (
        materialListNew: IMaterialData[],
        materialMode: TMaterialEditMode
    ) => {
        // сохраням материалы на сервер
        await materialServices
            .saveMaterial(materialListNew, materialMode, collectionId as string)
            .then(() => {
                dispatch({
                    type: typeAction(materialMode),
                    payload: materialListNew,
                })
            })
    }

    const props = {
        service,
        createObjects,
        modules: unitListState ? unitListState : unitList,
        saveUnitList,
        saveMaterial,
    }

    return (
        <>
            <WizardEditModules {...props} />
            <WizardCollection saveMaterial={saveMaterial} />
            <WisardEditEditMode {...props} />
        </>
    )
}
