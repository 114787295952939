import React, {useEffect, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './RangeNumber.css';
import {Slider} from '@yandex/ui/Slider/desktop/bundle';
import {Number} from '../Number/Number';

export const cnRangeNumber = cn('RangeNumber');

export interface IRangeNumberProps extends IClassNameProps {
    min: number;
    max: number;
    value: number;
    callBack: (value: number, name?: string) => void;
    showSlider: boolean;
    step?: number;
    viewControl?: boolean;
    name?: string;
    disabled?: boolean;
}

export const RangeNumber: React.FC<IRangeNumberProps> =
    ({
         className,
         min,
         max,
         showSlider,
         step,
         value,
         callBack,
         viewControl,
         name,
         disabled
     }) => {

        const [newValue, setNewValue] = useState<number>(value);

        useEffect(() => {
            setNewValue(value);
        }, [value]);

        const eventInput = (event: React.ChangeEvent, value: number[]) => {
            setNewValue(value[0]);
        };

        const eventChange = (event: React.ChangeEvent<HTMLInputElement>, value: number[]) => {
            callBack(value[0], name);
        };

        return (
            <div className={cnRangeNumber({disabled : disabled}, [className])}>
                <Number
                    className={cnRangeNumber('Input')}
                    min={min}
                    max={max}
                    step={step}
                    value={newValue}
                    viewControl={viewControl}
                    callBack={callBack}
                    name={name}
                    disabled={disabled}
                />
                {
                    !showSlider || <div className={cnRangeNumber('Range')}>
                        <Slider
                            view={'default'}
                            filled={true}
                            min={min}
                            max={max}
                            step={step}
                            value={[newValue]}
                            onInput={eventInput}
                            onChange={eventChange}
                            name={name}
                        />
                    </div>
                }
            </div>
        );
    };