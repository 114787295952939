import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React from "react";
import './Auth.css';
import {TOGGLE_MENU} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../redux/AppStore";
import {TMenuTypes} from "../../../types/TMenuTypes";
import {IUserData} from '../../../../common-code/interfaces/IUserData';
import {AuthGuest} from './guest/Auth-Guest';
import {AuthUser} from './user/Auth-User';
import {ROLE_GUEST} from '../../../../common-code/constants';

export const cnAuth = cn('Auth');

export interface IAuthProps extends IClassNameProps {
    forMenu: TMenuTypes;
}

export const Auth: React.FC<IAuthProps> = ({className, forMenu}) => {
    const userData: IUserData = useSelector((state: AppState) => state.user);
    const dispatch = useDispatch();
    const isMenuOpen = useSelector((state: AppState) => state.ui[forMenu].visible);

    const toggleMenu = () => {
        dispatch({
            type: TOGGLE_MENU,
            payload: {
                forMenu: forMenu,
                value: !isMenuOpen
            },
        });
    };

    if (userData.roles.includes(ROLE_GUEST)) {
        return <div className={cnAuth({}, [className])}>
            <AuthGuest />
        </div>
    }

    return (
        <div className={cnAuth({}, [className])}>
            <AuthUser userData={userData} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu}/>
        </div>
    );
};