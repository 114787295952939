import {CommonDetail} from '../../../CommonDetail/CommonDetail';
import {ThreeUnit} from '../../ThreeUnit/ThreeUnit';
import {ISaveThreeDetailData} from '../../../../../../common-code/interfaces/saveData/ISaveThreeDetailData';

export class ThreeDetail extends CommonDetail {
    saveData: ISaveThreeDetailData;
    unit: ThreeUnit;

    constructor(options: ISaveThreeDetailData, unit: ThreeUnit) {
        super(options, unit);
        this.unit = unit;
        this.saveData = options;
    }

    public getWidth() {
        return +this.saveData.sizes.length;
    }

    public getDepth() {
        return +this.saveData.sizes.width;
    }

    public getHeight() {
        return +this.saveData.sizes.height;
    }
}