import React from 'react';
import {cnSettingsMenu, ISettingsMenuGroupProps} from "../SettingsMenu";
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {ISettingGroupEquipments} from '../../../../interfaces/settingData/ISettingGroupEquipments';
import {ISettingEquipment} from '../../../../interfaces/settingData/ISettingEquipment';
import {RadioButton} from '../../../components/RadioButton/RadioButton';
import {cnObjectOption} from '../../../components/ObjectOption';
import EquipmentPositionSlider from './EquipmentPositionSlider';
import { ISaveKUnitData } from '../../../../../common-code/interfaces/saveData/ISaveKUnitData';

export interface ISettingsMenuEquipmentsGroupProps extends ISettingsMenuGroupProps {
    equipmentsData: ISettingGroupEquipments;
    service: KitchenService;
    data: ISaveKUnitData;
    unitId: number;
}

export const SettingsMenuEquipments: React.FC<ISettingsMenuEquipmentsGroupProps> =
    ({className, onRebuildData, equipmentsData, service, data, unitId}) => {

        const onChangeEquipment = (value: string, settingEquipment: ISettingEquipment) => {
            onRebuildData('tryChangeEquipment', {uid: value, setting: settingEquipment})
        }

        return (
            <div className={cnSettingsMenu('Equipments', [className])}>
                {equipmentsData.items.map((settingEquipment: ISettingEquipment) => {
                    return (<div className={cnSettingsMenu('EquipmentItem')} key={settingEquipment.cellName}>
                        <div className={cnSettingsMenu('Title')}>{settingEquipment.select.title}</div>
                        <RadioButton
                            className={cnObjectOption('RadioButton', [cnSettingsMenu('Value')])}
                            options={settingEquipment.select.items}
                            value={settingEquipment.select.value}
                            callBack={(value: string) => {
                                onChangeEquipment(value, settingEquipment)
                            }}
                        />                        
                    </div>);
                })}
                <EquipmentPositionSlider data={data} service={service} unitId={unitId}></EquipmentPositionSlider>
            </div>
        );
    }
