import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import {KitchenService} from "../../../3d/services/KitchenService/KitchenService";
import React, {MutableRefObject, useState} from "react";
import './ReplaceMenu.css';
import {useTranslation} from "react-i18next";
import {IReplaceData} from '../../../interfaces/IReplaceData';
import {ICreateObjectDomElements} from '../../../interfaces/ICreateObjectDomElements';
import {ICreateObjectData} from '../../../../common-code/interfaces/createData/ICreateObjectData';
import {CreateCommonObject} from '../CreateCommonObject/CreateCommonObject';
import {CommonHelper} from 'common-code';

export const cnReplaceMenu = cn('ReplaceMenu');

export interface IReplaceMenuProps extends IClassNameProps {
    replaceData: IReplaceData
    service: KitchenService;
    createObjects: MutableRefObject<ICreateObjectDomElements>;
    onClose: (value: boolean) => void;
}

export const ReplaceMenu: React.FC<IReplaceMenuProps> = ({className, replaceData, service, createObjects, onClose}) => {
    const {t} = useTranslation();
    const [searchText] = useState<string>('');

    const onCreateObject = (objectData: ICreateObjectData, width: string | null, corpusWidth: string | null, createOptions: any) => {
        if (replaceData.unitId) {
            const isReplaceObject =
                service.tryReplaceCommonObject(replaceData.unitId, {objectData, createOptions}, true);
            if (isReplaceObject) {
                onClose(true);
            }
        }
    }

    return (
        <div className={cnReplaceMenu({}, [className])}>
            <div className={cnReplaceMenu('Title')}>{t('Заменить модуль на другой')}</div>
            <div className={cnReplaceMenu('Body')}>
                {replaceData.data?.length ?
                    <div className={cnReplaceMenu('Items')}>
                        {replaceData.data.map(itemData => {
                            return (
                                <CreateCommonObject
                                    className={cnReplaceMenu('Item')}
                                    objectData={CommonHelper.deepCopy(itemData.objectData)}
                                    key={itemData.objectData.uid}
                                    createAction={onCreateObject}
                                    service={service}
                                    createObjects={createObjects}
                                    createOptions={CommonHelper.deepCopy(itemData.createOptions)}
                                    searchText={searchText}
                                />

                            );
                        })}
                    </div>
                    :
                    <div className={cnReplaceMenu('Descr')}>
                        {t('Для выбранного модуля нет ни одной замены')}
                    </div>
                }
            </div>
        </div>
    );
};