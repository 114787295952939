import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React from "react";
import './ProjectItem.css';
import {useTranslation} from "react-i18next";
import {ICollectionProject} from '../../../../common-code/interfaces/ICollectionProject';
import {ICollectionKitchen} from '../../../../common-code/interfaces/ICollectionKitchen';
import {Button, cnButton} from '../../components/Button';
import {Link} from '../../components/Link/Link';
import {LINK_TARGET_BLANK, LINK_TYPE_DEFAULT} from '../../constants';

export const cnProjectItem = cn('ProjectItem');

export interface IProjectItemProps extends IClassNameProps {
    project: ICollectionProject | ICollectionKitchen;
}

export const ProjectItem: React.FC<IProjectItemProps> = ({className, project}) => {
    const {t} = useTranslation();

    return (
        <>
            <div className={cnProjectItem({}, [className])}>
                <div className={cnProjectItem('Images')}>
                    <img className={cnProjectItem('Image')} src={project.image} alt={project.name}/>
                </div>
                <div className={cnProjectItem('Content')}>
                    <div className={cnProjectItem('Info')}>
                        <div className={cnProjectItem('Title')}>{project.name}</div>
                        {project.article ?
                            <div className={cnProjectItem('Article')}>{t('Код товара')}: {project.article}</div> : null
                        }
                        <div className={cnProjectItem('PriceContainer')}>
                            <div className={cnProjectItem('Price')}>{`${t('от')} ${project.price} ${t('руб')}`}</div>
                            {project.pageUrl ?
                                <Link href={project.pageUrl}
                                      target={LINK_TARGET_BLANK}
                                      type={LINK_TYPE_DEFAULT}
                                >
                                <Button
                                    className={cnProjectItem(cnButton())}
                                    leftIcon={{path: 'shop', width: 20, height: 20, marginRight: 8}}
                                    text={t('Купить') || undefined}
                                    callBack={() => {}}
                                    mods={{mods: {color: 'white', bkg: 'lightGreen', border: 'green', fill: 'white'}}}
                                    type={'button'}
                                />
                            </Link> : null }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}