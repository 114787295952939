import {ThreeBottomUnit} from '../ThreeBottomUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTabletop} from '../../../details/ThreeTabletop/ThreeTabletop';
import {Box3, Vector3} from 'three';
import {
    ThreeBottomAngleTwoFacades90Corpus
} from "../../../details/ThreeCorpus/types/ThreeBottomAngleTwoFacades90Corpus";
import {
    ISaveBottomUnitAngleTwoFacades90Data
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleTwoFacades90Data';
import {
    ISaveBottomAngleTwoFacades90CorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleTwoFacades90CorpusData';
import {CommonHelper} from 'common-code';

export class ThreeBottomUnitAngleTwoFacades90 extends ThreeBottomUnit {
    corpus: ThreeBottomAngleTwoFacades90Corpus;
    saveData: ISaveBottomUnitAngleTwoFacades90Data;

    constructor(options: ISaveBottomUnitAngleTwoFacades90Data, service: KitchenService) {
        super(options, service);
        this.saveData =options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isAngleUnit(): boolean {
        return true;
    }

    public getInitTabletopPosition(tabletop: ThreeTabletop): Vector3 {
        let position: Vector3;
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        position = new Vector3(
            (coverBox.min.x + coverBox.max.x) / 2,
            coverBox.max.y + tabletop.getHeight()/2,
            this.getZInitTabletopPosition(tabletop)
        );

        return position;
    }

    protected initCorpus(corpusData: ISaveBottomAngleTwoFacades90CorpusData): ThreeBottomAngleTwoFacades90Corpus {
        return new ThreeBottomAngleTwoFacades90Corpus(CommonHelper.deepCopy(corpusData), this)
    }
}