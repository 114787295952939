import {ThreeTopUnit} from '../ThreeTopUnit';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {ThreeTopAngleNormalCorpus} from '../../../details/ThreeCorpus/types/ThreeTopAngleNormalCorpus';
import {
    ALIGN_CENTER,
    ALIGN_LEFT,
    ALIGN_RIGHT, SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT
} from '../../../../../../../common-code/constants';
import {TDirectionSideType} from "../../../../../../../common-code/types/TDirectionSideType";
import {Vector3} from "three";
import {
    ISaveTopUnitAngleNormalData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopUnitAngleNormalData';
import {
    ISaveTopAngleNormalCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopAngleNormalCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveFacadeData} from '../../../../../../../common-code/interfaces/saveData/ISaveFacadeData';

export class ThreeTopUnitAngleNormal extends ThreeTopUnit {
    corpus: ThreeTopAngleNormalCorpus;
    saveData: ISaveTopUnitAngleNormalData;

    constructor(options: ISaveTopUnitAngleNormalData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    public isAngleUnit(): boolean {
        return true;
    }

    protected initCorpus(corpusData: ISaveTopAngleNormalCorpusData): ThreeTopAngleNormalCorpus {
        return new ThreeTopAngleNormalCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected calculateInitFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        if (facades) {
            let facade: ISaveFacadeData;
            for (facade of facades) {
                facade.align = this.getSideType() === SIDE_TYPE_RIGHT ?
                    {x: ALIGN_LEFT, y: ALIGN_CENTER} : {x: ALIGN_RIGHT, y: ALIGN_CENTER};
                if (facade.margin) {
                    facade.margin.x = this.getSideType() === SIDE_TYPE_RIGHT ?
                        Math.abs(facade.margin.x) : Math.abs(facade.margin.x) * -1;
                }
                if (facade.rotation && facade.rotation.y !== undefined && !isNaN(facade.rotation.y)) {
                    facade.rotation.y = this.getSideType() === SIDE_TYPE_RIGHT ?
                        Math.abs(+facade.rotation.y) - Math.PI :
                        Math.abs(+facade.rotation.y);
                }
            }
        }

        return facades;
    }

    protected initGapCoverPoints() {
        if (!this.saveData.sideWallGap) {
            return;
        }
        switch (this.getSideType()) {
            case SIDE_TYPE_LEFT:
                this.addCoverPoints([new Vector3(-this.getWidth()/2 - this.saveData.sideWallGap, 0, 0)]);
                break;
            case SIDE_TYPE_RIGHT:
                this.addCoverPoints([new Vector3(this.getWidth()/2 + this.saveData.sideWallGap, 0, 0)]);
                break;
        }
    }
}
