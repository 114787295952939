import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, BufferGeometry, Euler, ExtrudeGeometry, Mesh, Shape, Vector3} from 'three';
import {ThreeShelf} from '../../ThreeShelf/ThreeShelf';
import {
    ThreeBottomUnitAngleFullLengthSidewallCubeSink
} from "../../../units/ThreeBottomUnit/types/ThreeBottomUnitAngleFullLengthSidewallCubeSink";
import {
    ISaveBottomAngleFullLengthSidewallCubeSinkCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleFullLengthSidewallCubeSinkCorpusData';
import {ISaveShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfData';
import {CommonHelper} from 'common-code';

export class ThreeBottomAngleFullLengthSidewallCubeSinkCorpus extends ThreeCorpus {
    saveData: ISaveBottomAngleFullLengthSidewallCubeSinkCorpusData;
    unit: ThreeBottomUnitAngleFullLengthSidewallCubeSink;

    constructor(options: ISaveBottomAngleFullLengthSidewallCubeSinkCorpusData, unit: ThreeBottomUnitAngleFullLengthSidewallCubeSink) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public getRearPlinth() {
       return this.saveData.rearPlinth;
    }

    public getTsarga() {
        return this.saveData.tsarga;
    }

    public getRail() {
        return this.saveData.rail;
    }

    public getSideDepth(): number {
        return +this.saveData.sideDepth;
    }

    protected createTriangleGeometry(): BufferGeometry {
        let triangleGeometry;
        let triangleShape = new Shape();
        let triangleExtrudeSettings = {
            steps: 1,
            depth: 4,
            bevelEnabled: false
        };

        triangleShape.moveTo(0, 0);
        triangleShape.lineTo(240, 0);
        triangleShape.lineTo(0, 300);
        triangleShape.lineTo(0, 0);

        triangleGeometry = new ExtrudeGeometry([triangleShape], triangleExtrudeSettings);

        return triangleGeometry;
    }

    protected createShape() {
        this.shape = new Shape();
        this.shape.moveTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            this.getDepth() / 2 - this.getThickness()
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            -this.getDepth() / 2 + this.getBackThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getBackThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getSideDepth()
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getSideDepth(),
            this.getDepth() / 2 - this.getThickness()
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getBackThickness(),
            this.getDepth() / 2 - this.getThickness()
        );
    }

    protected createShapePanels() {
        let bottom: Mesh;

        bottom = this.createShapePanel();
        bottom.position.y = -this.getHeight() / 2 + this.getRearPlinth().height + this.getThickness() / 2;
        bottom.position.z = this.getBackThickness()/2;
        bottom.name = 'bottom';
        this.view3d.add(bottom);
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getSideDepth(), this.getHeight(), this.getThickness()),
            'left',
            new Vector3(
                -this.getWidth() / 2 + this.getSideDepth() / 2 + this.getBackThickness(),
                0,
                this.getDepth() / 2 - this.getThickness() / 2
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getSideDepth()),
            'right',
            new Vector3(
                this.getWidth() / 2 - this.getThickness() / 2,
                0,
                -this.getDepth() / 2 + this.getSideDepth() / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getRearPlinth().depth, this.getRearPlinth().height, this.getRearPlinth().width),
            'rearPlinthLeft',
            new Vector3(
                -this.getWidth() / 2 + this.getSideDepth() / 3,
                -this.getHeight() / 2 + this.getRearPlinth().height / 2,
                this.getDepth() / 2 - this.getRearPlinth().width / 2 - this.getThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getRearPlinth().width, this.getRearPlinth().height, this.getRearPlinth().depth),
            'rearPlinthRight',
            new Vector3(
                this.getDepth() / 2 - this.getRearPlinth().width / 2 - this.getThickness(),
                -this.getHeight() / 2 + this.getRearPlinth().height / 2,
                -this.getDepth() / 2 + this.getSideDepth() / 3
            )
        );
        this.createPanel(
            new BoxGeometry(this.getTsarga().depth, this.getTsarga().height, this.getTsarga().width),
            'tsargaLeft',
            new Vector3(
                -this.getWidth() / 2 + this.getTsarga().depth / 2 + this.getBackThickness(),
                this.getHeight() / 2 - this.getTsarga().height / 2,
                this.getDepth() / 2 - this.getTsarga().width / 2 - this.getThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getTsarga().width, this.getTsarga().height, this.getTsarga().depth),
            'tsargaRight',
            new Vector3(
                this.getWidth() / 2 - this.getTsarga().width / 2 - this.getThickness(),
                this.getHeight() / 2 - this.getTsarga().height / 2,
                -this.getDepth() / 2 + this.getTsarga().depth / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getRail().width + this.getRail().depth, this.getRail().height, this.getRail().depth),
            'rail1',
            new Vector3(
                -this.getWidth() / 2 + this.getRail().width / 2 + this.getRail().depth / 2 + this.getBackThickness(),
                -this.getHeight() / 2 + this.getRail().height / 2 + this.getRearPlinth().height + this.getThickness(),
                -this.getDepth() / 2 + this.getRail().width + this.getRail().depth / 2 + this.getBackThickness()
            )
        );
        this.createPanel(
            new BoxGeometry(this.getRail().depth, this.getRail().height, this.getRail().width),
            'rail2',
            new Vector3(
                -this.getWidth() / 2 + this.getRail().width + this.getRail().depth / 2 + this.getBackThickness(),
                -this.getHeight() / 2 + this.getRail().height / 2 + this.getRearPlinth().height + this.getThickness(),
                -this.getDepth() / 2 + this.getRail().width / 2 + this.getBackThickness()
            )
        );
    }

    protected createBackPanels() {
        this.createPanel(this.createTriangleGeometry(),
            'backThickness1',
            new Vector3(
                this.getWidth()/2,
                -this.getHeight()/2 + this.getRearPlinth().height,
                -this.getDepth()/2 + this.getBackThickness()
            ),
            new Euler(
                0,
                Math.PI,
                0
            )
        );
        this.createPanel(this.createTriangleGeometry(),
            'backThickness2',
            new Vector3(
                -this.getWidth()/2,
                -this.getHeight()/2 + this.getRearPlinth().height,
                this.getDepth()/2
            ),
            new Euler(
                0,
                Math.PI / 2,
                0
            )
        );
    }

    protected createShelves() {
        let shelfData: ISaveShelfData;
        let shelf: ThreeShelf;
        for (shelfData of this.saveData.shelves) {
            shelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this, this.shape);
            shelf.initState();
            shelf.createView();
            this.shelves.push(shelf);
        }
    }
}