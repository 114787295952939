import {TPoint3D} from '../../../types/TPoint3D';
import {TPoint2D} from '../../../types/TPoint2D';
import {TLine} from '../../../types/TLine';
import {TDirectEquation} from '../../../types/TDirectEquation';
import {
    AXIS_X,
    AXIS_Y,
    DIRECT_EQUATION_TYPE_HORIZONTAL,
    DIRECT_EQUATION_TYPE_NORMAL,
    DIRECT_EQUATION_TYPE_VERTICAL,
    INTERSECTION_LINE_COINCIDENT,
    INTERSECTION_LINE_INTERSECT,
    INTERSECTION_LINE_NO_INTERSECT,
    INTERSECTION_LINE_PARALLEL
} from '../../../constants';
import {TDirectEquationType} from '../../../types/TDirectEquationType';
import {TLine3D} from '../../../types/TLine3D';
import {TAxisType} from '../../../types/TAxisType';
import {TAxis2DType} from '../../../types/TAxis2DType';
import {TIntersectionLineType} from '../../../types/math/TIntersectionLineType';
import {TCircle} from '../../../types/TCircle';
import {TCommonInterval} from '../../../types/TCommonInterval';
import {TCommonIntervalLines} from '../../../types/TCommonIntervalLines';


export class MathHelper {
    /**
     * Метод возвращает длину векторов (расстояние между точками).
     *
     * @param pointA
     * @param pointB
     * @param fractionDigits
     */
    public static getLength(pointA: TPoint3D, pointB: TPoint3D, fractionDigits?: number): number {
        let result = 0;

        if (pointA && pointB) {
            if (pointA.x !== undefined && pointB.x !== undefined) {
                result += Math.pow((pointB.x - pointA.x), 2);
            }
            if (pointA.y !== undefined && pointB.y !== undefined) {
                result += Math.pow((pointB.y - pointA.y), 2);
            }
            if (pointA.z !== undefined && pointB.z !== undefined) {
                result += Math.pow((pointB.z - pointA.z), 2);
            }
            result = Math.sqrt(result);
        }
        if (fractionDigits) {
            result = +result.toFixed(fractionDigits);
        }

        return result;
    }

    /**
     * Получение координат точки на отрезке между startPoint и endPoint
     * на расстоянии равном коэффициенту длины ratio
     * относительно длины отрезка startPoint-endPoint
     *
     * @param startPoint
     * @param endPoint
     * @param ratio
     * @param isInclude
     */
    public static getPointByRatio(
        startPoint: TPoint3D, endPoint: TPoint3D, ratio: number, isInclude: boolean = true): TPoint3D {
        let lambda: number;
        let point: TPoint3D;

        if (ratio >= 1 && isInclude) {
            return endPoint;
        }
        if (ratio === 1) {
            return endPoint;
        }
        lambda = ratio / (1 - ratio);
        point = {
            x: +((startPoint.x + lambda * endPoint.x) / (1 + lambda)).toFixed(3),
            y: +((startPoint.y + lambda * endPoint.y) / (1 + lambda)).toFixed(3),
            z: +((startPoint.z + lambda * endPoint.z) / (1 + lambda)).toFixed(3)
        };

        return point;
    }

    public static getPointByRatio2D(
        startPoint: TPoint2D, endPoint: TPoint2D, ratio: number, isInclude: boolean = true): TPoint2D {
        let lambda: number;
        let point: TPoint2D;

        if (ratio >= 1 && isInclude) {
            return endPoint;
        }
        if (ratio === 1) {
            return endPoint;
        }
        lambda = ratio / (1 - ratio);
        point = {
            x: +((startPoint.x + lambda * endPoint.x) / (1 + lambda)).toFixed(3),
            y: +((startPoint.y + lambda * endPoint.y) / (1 + lambda)).toFixed(3),
        };

        return point;
    }

    public static turnVector2D(vector: TPoint2D, angle: number, pointCenter?: TPoint2D): TPoint2D {
        if (pointCenter === undefined) {
            pointCenter = {x: 0, y: 0};
        }

        return {
            x: +(pointCenter.x + (vector.x) * Math.cos(angle) - (vector.y) * Math.sin(angle)).toFixed(3),
            y: +(pointCenter.y + (vector.x) * Math.sin(angle) + (vector.y) * Math.cos(angle)).toFixed(3)
        };
    }

    /**
     * Метод возвращает угол между двумя векторами
     *
     * @param vector1
     * @param vector2
     * @returns number
     */
    public static getAngle2D(vector1: TPoint2D, vector2: TPoint2D): number {
        let scalarSum = 0,
            scalarPow1 = 0,
            scalarPow2 = 0,
            scalarPow,
            result,
            cos;

        if (vector1.x !== undefined && vector2.x !== undefined) {
            scalarSum += vector1.x * vector2.x;
            scalarPow1 += Math.pow(vector1.x, 2);
            scalarPow2 += Math.pow(vector2.x, 2);
        }
        if (vector1.y !== undefined && vector2.y !== undefined) {
            scalarSum += vector1.y * vector2.y;
            scalarPow1 += Math.pow(vector1.y, 2);
            scalarPow2 += Math.pow(vector2.y, 2);
        }

        scalarPow1 = Math.sqrt(scalarPow1);
        scalarPow2 = Math.sqrt(scalarPow2);

        scalarPow = scalarPow1 * scalarPow2;

        if (scalarPow === 0) {
            cos = 0;
        } else {
            cos = scalarSum / scalarPow;
        }
        if (cos > 1) {
            cos = 1;
        }
        if (cos < -1) {
            cos = -1;
        }
        result = +Math.acos(cos).toFixed(15);

        return result;
    }

    /**
     * Возвращает true, если векторы сонаправлены.
     * @param vector1
     * @param vector2
     */
    public static isCoDirectionVectors(vector1: TPoint2D, vector2: TPoint2D): boolean {
        return (+vector1.x * +vector2.x + +vector1.y * +vector2.y > 0);
    }

    /**
     * Метод возвращает угол между переданным вектором и нормалью в радианах
     *
     * @param vector
     * @param isNull
     * @returns number
     */
    public static getNormalAngle(vector: TPoint2D, isNull?: boolean): number {
        let angle;

        if (vector.x === 0 && vector.y === 0) {
            if (isNull) {
                return 0;
            }
            throw new Error('getNormalAngle');
        }

        angle = Math.acos(vector.x / Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2)));
        if (vector.y < 0) {
            angle = 360 * Math.PI / 180 - angle;
        }
        return +angle.toFixed(16);
    }

    /**
     * Метод возвращает координаты точки пересечения прямых line1 и line2, заданных формулой y = kx + b,
     * или undefined для параллельных прямых.
     *
     * @param line1
     * @param line2
     * @param epsilon
     */
    public static getIntersectionPoint(
        line1: TLine,
        line2: TLine,
        epsilon?: number
    ): TPoint2D | undefined {
        let d: number, d1: number, d2: number, x: number | undefined, y: number | undefined;
        let directEquation1: TDirectEquation;
        let directEquation2: TDirectEquation;

        directEquation1 = this.directEquation(line1.pointA, line1.pointB);
        directEquation2 = this.directEquation(line2.pointA, line2.pointB);


        switch (directEquation1.type) {
            case DIRECT_EQUATION_TYPE_HORIZONTAL:
                y = directEquation1.y;
                if (directEquation2.type === DIRECT_EQUATION_TYPE_VERTICAL) {
                    x = directEquation2.x;
                } else if (directEquation2.type === DIRECT_EQUATION_TYPE_HORIZONTAL) {
                    return undefined;
                } else {
                    x = (y - directEquation2.b) / directEquation2.k;
                }
                break;
            case DIRECT_EQUATION_TYPE_VERTICAL:
                x = directEquation1.x;
                if (directEquation2.type === DIRECT_EQUATION_TYPE_HORIZONTAL) {
                    y = directEquation2.y;
                } else if (directEquation2.type === DIRECT_EQUATION_TYPE_VERTICAL) {
                    return undefined;
                } else {
                    y = directEquation2.k * x + directEquation2.b;
                }
                break;
            default:
                // Два отрезка, лежащие на одной прямой, пересекаются везде, вызывает ошибку.
                if (this.isEqualLines(directEquation1, directEquation2)) {
                    return undefined;
                }
                switch (directEquation2.type) {
                    case DIRECT_EQUATION_TYPE_HORIZONTAL:
                        y = directEquation2.y;
                        x = (y - directEquation1.b) / directEquation1.k;
                        break;
                    case DIRECT_EQUATION_TYPE_VERTICAL:
                        x = directEquation2.x;
                        y = directEquation1.k * x + directEquation1.b;
                        break;
                    default:
                        d = (directEquation1.k * -1) - (directEquation2.k * -1);
                        d1 = (-directEquation1.b * -1) - (-directEquation2.b * -1);
                        d2 = directEquation1.k * (-directEquation2.b) - directEquation2.k * (-directEquation1.b);
                        // Если точка пересечения ооооочень далеко где-то в горизонте, то считаем,
                        // что линии параллельны
                        d = +d.toFixed(epsilon || 6);
                        if (!!d) {
                            x = d1 / d;
                            y = d2 / d;
                        }
                }
        }
        if (x === undefined || y === undefined ||
            isNaN(x) || isNaN(y) ||
            !isFinite(x) || !isFinite(y)) {
            return undefined;
        }

        return {x: x, y: y};
    }

    /**
     * Получение формулы прямой y = k*x + b по двум точкам
     *
     * @param pointA
     * @param pointB
     * @returns TDirectEquation
     */
    public static directEquation(pointA: TPoint2D, pointB: TPoint2D): TDirectEquation {
        let type: TDirectEquationType;
        let k;
        let b = 0;

        if (this.isEqualPoints2D(pointA, pointB)) {
            throw new Error('directEquation can not create for same points!')
        }
        if (pointB.x - pointA.x < 0.01 && pointA.x - pointB.x < 0.01) {
            type = DIRECT_EQUATION_TYPE_VERTICAL;
            k = 1;
        } else if (pointB.y - pointA.y < 0.01 && pointA.y - pointB.y < 0.01) {
            type = DIRECT_EQUATION_TYPE_HORIZONTAL;
            k = 0;
            b = pointB.y;
        } else {
            type = DIRECT_EQUATION_TYPE_NORMAL;
            k = (pointB.y - pointA.y) / (pointB.x - pointA.x);
            b = pointB.y - k * pointB.x;
        }

        return {
            type: type,
            k: k,
            b: b,
            normalAngle: this.getNormalAngle({x: pointB.x - pointA.x, y: pointB.y - pointA.y}),
            x: +pointA.x,
            y: +pointA.y
        }
    }

    public static isEqualPoints(point1: TPoint3D, point2: TPoint3D, epsilon?: number): boolean {
        if (!point1 || !point2 ||
            point1.x === undefined || point2.x === undefined ||
            point1.y === undefined || point2.y === undefined ||
            point1.z === undefined || point2.z === undefined) {
            return false;
        }
        return (this.isEqual(point1.x, point2.x, epsilon) && this.isEqual(point1.y, point2.y, epsilon) && this.isEqual(point1.z, point2.z, epsilon));
    }

    /**
     * Возвращает true, если разница между координатами точек не превышает epsilon
     *
     * @param point1
     * @param point2
     * @param epsilon
     * @returns boolean
     */
    public static isEqualPoints2D(point1: TPoint2D, point2: TPoint2D, epsilon?: number): boolean {
        if (!point1 || !point2 ||
            point1.x === undefined || point2.x === undefined ||
            point1.y === undefined || point2.y === undefined) {
            return false;
        }
        return (this.isEqual(point1.x, point2.x, epsilon) && this.isEqual(point1.y, point2.y, epsilon));
    }

    /**
     * Возвращает true, если разница между value1 и value2 не превышает epsilon
     *
     * @param value1
     * @param value2
     * @param epsilon
     * @returns boolean
     */
    public static isEqual(value1: number, value2: number, epsilon?: number): boolean {
        value1 = +value1;
        value2 = +value2;
        if (!epsilon) {
            epsilon = 0;
        } else {
            epsilon = +epsilon;
        }

        return (value1 === value2 ||
            (value1 > value2 && value1 <= epsilon + value2) ||
            (value2 > value1 && value2 <= epsilon + value1)
        );
    }

    /**
     * Метод возвращает true, если прямые равны друг другу (являются одной прямой).
     *
     * @param line1
     * @param line2
     * @returns boolean
     */
    public static isEqualLines(line1: TDirectEquation, line2: TDirectEquation): boolean {
        if (line1.type !== line2.type) {
            return false;
        }
        if (line1.k.toFixed(4) !== line2.k.toFixed(4)) {
            return false;
        }

        if (line1.b.toFixed(4) !== line2.b.toFixed(4)) {
            return false;
        }
        return (
            line1.normalAngle.toFixed(8) === line2.normalAngle.toFixed(8) ||
            (line1.normalAngle - Math.PI * 2).toFixed(8) === line2.normalAngle.toFixed(8) ||
            (line1.normalAngle + Math.PI * 2).toFixed(8) === line2.normalAngle.toFixed(8)
        );
    }

    public static line3DtoLine2D(line: TLine3D, axe1: TAxisType = AXIS_X, axe2: TAxisType = AXIS_Y): TLine {
        return {
            pointA: this.point3Dto2D(line.pointA, axe1, axe2),
            pointB: this.point3Dto2D(line.pointB, axe1, axe2)
        };
    }

    public static point3Dto2D(vector: TPoint3D, axe1: TAxisType = AXIS_X, axe2: TAxisType = AXIS_Y): TPoint2D {
        return {x: +vector[axe1], y: +vector[axe2]};
    }

    public static getNearPoint2D(point: TPoint2D, points: TPoint2D[]): TPoint2D {
        let selectedPoint: TPoint2D | undefined,
            currentPoint: TPoint2D | undefined,
            onePoint: TPoint2D;

        if (point && points && points.length > 0) {
            for (onePoint of points) {
                currentPoint = onePoint;
                if (selectedPoint === undefined) {
                    selectedPoint = currentPoint;
                } else if (selectedPoint &&
                    this.getLength2D(point, currentPoint) < this.getLength2D(point, selectedPoint)) {
                    selectedPoint = currentPoint;
                }
            }
            if (selectedPoint) {
                return selectedPoint;
            }
        }

        throw new Error('error-MathHelper-getNearPoint2D');
    }


    public static getLength2D(vector1: TPoint2D, vector2: TPoint2D): number {
        let result = 0;

        if (vector1 && vector2) {
            if (vector1.x !== undefined && vector2.x !== undefined) {
                result += Math.pow((vector2.x - vector1.x), 2);
            }
            if (vector1.y !== undefined && vector2.y !== undefined) {
                result += Math.pow((vector2.y - vector1.y), 2);
            }
            result = Math.sqrt(result);
        }

        return result;
    }

    public static getParallelPoints(line: TLine, shift: number): TLine {
        let pointA;
        let pointB;

        pointA = this.getShiftPoint2D(line.pointA, line.pointA, line.pointB, shift);
        pointB = this.getShiftPoint2D(line.pointB, line.pointA, line.pointB, shift);

        return {pointA: pointA, pointB: pointB};
    }

    public static getShiftPoint2D(point: TPoint2D, pointA: TPoint2D, pointB: TPoint2D, shift: number): TPoint2D {
        let normalAngle;

        normalAngle = this.getNormalAngle({x: (pointB.x - pointA.x), y: (pointB.y - pointA.y)});
        if (isNaN(normalAngle)) {
            throw new Error('getShiftPoint2D  normalAngle isNaN!!!');
        }
        // Отнимаем от нормали 90 градусов в радианах
        normalAngle = normalAngle - 90 * Math.PI / 180;

        return {
            x: +(point.x + Math.cos(normalAngle) * shift).toFixed(10),
            y: +(point.y + Math.sin(normalAngle) * shift).toFixed(10)
        };
    }

    /**
     * Возвращает координаты точки посредине между точками A и B.
     */
    static getCenterPoint2D(pointA: TPoint2D, pointB: TPoint2D) {
        return {
            x: Math.abs(pointA.x - pointB.x) / 2 + (pointA.x < pointB.x ? pointA.x : pointB.x),
            y: Math.abs(pointA.y - pointB.y) / 2 + (pointA.y < pointB.y ? pointA.y : pointB.y)
        };
    }

    /**
     * Возвращает значение ratio для точки point между точками A и B.
     */
    static getRatio(point: TPoint3D, pointA: TPoint3D, pointB: TPoint3D): number {
        return this.getLength(point, pointA) / this.getLength(pointA, pointB);
    }

    static projectionPoint2D(point: TPoint2D, pointA: TPoint2D, pointB: TPoint2D): TPoint2D {
        let outPoint: TPoint2D;
        let x, y;

        if (this.isEqualPoints2D(pointA, pointB)) {
            return pointA;
        }
        let line = this.directEquation(pointA, pointB);
        switch (line.type) {
            case DIRECT_EQUATION_TYPE_VERTICAL:
                outPoint = {x: pointA.x, y: point.y};
                break;
            case DIRECT_EQUATION_TYPE_HORIZONTAL:
                outPoint = {x: point.x, y: pointA.y};
                break;
            case DIRECT_EQUATION_TYPE_NORMAL:
                x = (
                        pointA.x * Math.pow((pointB.y - pointA.y), 2) +
                        point.x * Math.pow((pointB.x - pointA.x), 2) +
                        (pointB.x - pointA.x) * (pointB.y - pointA.y) * (point.y - pointA.y)) /
                    (Math.pow((pointB.y - pointA.y), 2) +
                        Math.pow((pointB.x - pointA.x), 2));
                y = ((pointB.x - pointA.x) * (point.x - x) / (pointB.y - pointA.y)) + point.y;

                outPoint = {
                    x: x,
                    y: y
                };
                break;
        }
        if (!outPoint) {
            throw new Error('error-MathHelper-projectionPoint2D');
        }

        return outPoint;
    }

    static isPointInLine(point: TPoint2D, line: TLine, inclusive: boolean) {
        let x, y, x1, x2, y1, y2,
            directEquation, result;

        if (!point || !line || !line.pointA || !line.pointB ||
            typeof point.x === 'undefined' ||
            isNaN(point.x) ||
            typeof point.y === 'undefined' ||
            isNaN(point.y) ||
            typeof line.pointA.x === 'undefined' ||
            isNaN(line.pointA.x) ||
            typeof line.pointA.y === 'undefined' ||
            isNaN(line.pointA.y) ||
            typeof line.pointB.x === 'undefined' ||
            isNaN(line.pointB.x) ||
            typeof line.pointB.y === 'undefined' ||
            isNaN(line.pointB.y)) {
            return false;
        }

        x = +point.x.toFixed(5);
        y = +point.y.toFixed(5);
        x1 = +line.pointA.x.toFixed(5);
        y1 = +line.pointA.y.toFixed(5);
        x2 = +line.pointB.x.toFixed(5);
        y2 = +line.pointB.y.toFixed(5);
        if (inclusive && ((x === x1 && y === y1) || (x === x2 && y === y2))) {
            result = true;
        } else {
            directEquation = this.directEquation(line.pointA, line.pointB);

            if (directEquation.type === DIRECT_EQUATION_TYPE_VERTICAL) {
                result = ((y1 < y && y < y2) || (y2 < y && y < y1)) && x.toFixed(5) === x1.toFixed(5);

            } else if (directEquation.type === DIRECT_EQUATION_TYPE_HORIZONTAL) {
                result = ((x1 < x && x < x2) || (x2 < x && x < x1)) && y.toFixed(5) === y1.toFixed(5);
            } else {
                //(x-x1)(y2-y1)-(y-y1)(x2-x1) = 0
                result = (
                    +(((x - x1) * (y2 - y1) - (y - y1) * (x2 - x1)).toFixed(0)) === 0 &&
                    ((x1 < x && x < x2) || (x2 < x && x < x1))
                );
            }
        }

        return result;
    }

    static getParallelLinePoints(pointA: TPoint2D, pointB: TPoint2D, distance: number): TLine {
        let pointStart,
            pointFinish;

        if (pointA.x === pointB.x && pointA.y === pointB.y) {
            throw new Error('error-MathHelper-getParallelLinePoints');
        }
        pointStart = this.getShiftPoint2D(pointA, pointA, pointB, distance);
        pointFinish = this.getShiftPoint2D(pointB, pointA, pointB, distance);

        return {
            pointA: pointStart,
            pointB: pointFinish
        };
    }

    static isPointInsidePolygon(point: TPoint2D, polygon: TPoint2D[]): boolean {
        let index1: number = polygon.length - 1;
        let index2: string;
        let isInside: boolean = false;

        for (index2 in polygon) {
            if (!polygon.hasOwnProperty(index2)) {
                continue;
            }
            if ((((polygon[index2].y <= point.y) && (point.y < polygon[index1].y)) ||
                    ((polygon[index1].y <= point.y) && (point.y < polygon[index2].y))) &&
                (point.x > (polygon[index1].x - polygon[index2].x) * (point.y - polygon[index2].y) /
                    (polygon[index1].y - polygon[index2].y) + polygon[index2].x)) {
                isInside = !isInside;
            }
            index1 = +index2;
        }

        return isInside;
    }

    static isLineIntersectPolygon(pointA: TPoint2D, pointB: TPoint2D, polygon: TPoint2D[]): boolean {
        let length = polygon.length;
        let i;
        let pointC;
        let pointD;
        let intersectLineLine: TIntersectionLineType;
        let sortAxis: TAxis2DType;
        let pointsAB;
        let pointsCD;

        for (i = 0; i < length; i++) {
            pointC = polygon[i];
            pointD = polygon[(i + 1) % length];
            intersectLineLine = this.intersectLineLineType(
                {pointA: pointA, pointB: pointB},
                {pointA: pointC, pointB: pointD}
            );
            if (intersectLineLine === INTERSECTION_LINE_INTERSECT) {
                return true;
            } else if (intersectLineLine === INTERSECTION_LINE_COINCIDENT) {
                sortAxis = Math.abs(pointB.y - pointA.y) > Math.abs(pointB.x - pointA.x) ? AXIS_Y : AXIS_X;
                pointsAB = [pointA, pointB];
                // eslint-disable-next-line no-loop-func
                pointsAB.sort((a: TPoint2D, b: TPoint2D) => {
                    return a[sortAxis] - b[sortAxis];
                });
                pointsCD = [pointC, pointD];
                // eslint-disable-next-line no-loop-func
                pointsCD.sort((a: TPoint2D, b: TPoint2D) => {
                    return a[sortAxis] - b[sortAxis];
                });

                if ((pointsAB[1][sortAxis] >= pointsCD[0][sortAxis] && pointsAB[0][sortAxis] <= pointsCD[0][sortAxis]) ||
                    (pointsCD[1][sortAxis] >= pointsAB[0][sortAxis] && pointsCD[0][sortAxis] <= pointsAB[0][sortAxis])) {
                    return true;
                }
            }
        }

        return false;
    }

    static intersectLineLineType(line1: TLine, line2: TLine): TIntersectionLineType {
        let result: TIntersectionLineType;
        let ua_t = (line2.pointB.x - line2.pointA.x) * (line1.pointA.y - line2.pointA.y) -
            (line2.pointB.y - line2.pointA.y) * (line1.pointA.x - line2.pointA.x);
        let ub_t = (line1.pointB.x - line1.pointA.x) * (line1.pointA.y - line2.pointA.y) -
            (line1.pointB.y - line1.pointA.y) * (line1.pointA.x - line2.pointA.x);
        let u_b = (line2.pointB.y - line2.pointA.y) * (line1.pointB.x - line1.pointA.x) -
            (line2.pointB.x - line2.pointA.x) * (line1.pointB.y - line1.pointA.y);

        if (u_b !== 0) {
            let ua = ua_t / u_b;
            let ub = ub_t / u_b;

            if (0 <= ua && ua <= 1 && 0 <= ub && ub <= 1) {
                result = INTERSECTION_LINE_INTERSECT;
            } else {
                result = INTERSECTION_LINE_NO_INTERSECT;
            }
        } else {
            if (ua_t === 0 || ub_t === 0) {
                result = INTERSECTION_LINE_COINCIDENT;
            } else {
                result = INTERSECTION_LINE_PARALLEL;
            }
        }

        return result;
    }

    static isParallelLines(line1: TLine, line2: TLine): boolean {
        return !this.getIntersectionPoint(line1, line2);
    }

    /**
     * Метод возвращает точки пересечения двух окружностей
     *
     * @param circle1   - объект содержит координаты центра и радиус первой окружности
     * @param circle2   - объект содержит координаты центра и радиус второй окружности
     * @returns {[]}
     */
    static intersectCircles(circle1: TCircle, circle2: TCircle) {
        let intersectionPoints = [],
            radiusDistance = 0,         // Расстояние, на котором две точки считаются одной
            delta,
            sign,
            position,
            a, b, d, h;

        d = this.getLength2D(circle1.center, circle2.center);
        circle1.radius = Math.abs(circle1.radius);
        circle2.radius = Math.abs(circle2.radius);

        if (Math.abs(d - Math.abs(circle1.radius - circle2.radius)) < radiusDistance / 2) {
            sign = Number(circle1.radius - circle2.radius > 0) - Number(circle1.radius - circle2.radius < 0);
            delta = (d - Math.abs(circle1.radius - circle2.radius)) / 2;
            intersectionPoints.push({
                x: circle1.center.x + (circle1.radius - delta) * (circle2.center.x - circle1.center.x) / d * sign,
                y: circle1.center.y + (circle1.radius - delta) * (circle2.center.y - circle1.center.y) / d * sign
            });
        } else if (Math.abs(d - (circle1.radius + circle2.radius)) < radiusDistance / 2) {
            delta = (d - Math.abs(circle1.radius + circle2.radius)) / 2;
            intersectionPoints.push({
                x: circle1.center.x + (circle1.radius + delta) * (circle2.center.x - circle1.center.x) / d,
                y: circle1.center.y + (circle1.radius + delta) * (circle2.center.y - circle1.center.y) / d
            });
        } else if (d < circle1.radius + circle2.radius && d > Math.abs(circle1.radius - circle2.radius)) {
            b = (circle2.radius * circle2.radius - circle1.radius * circle1.radius + d * d) / (2 * d);
            a = d - b;
            h = Math.sqrt(circle1.radius * circle1.radius - a * a);
            position = {
                x: circle1.center.x + a / d * (circle2.center.x - circle1.center.x),
                y: circle1.center.y + a / d * (circle2.center.y - circle1.center.y)
            };
            intersectionPoints.push({
                x: position.x + h / d * (circle2.center.y - circle1.center.y),
                y: position.y - h / d * (circle2.center.x - circle1.center.x)
            });
            intersectionPoints.push({
                x: position.x - h / d * (circle2.center.y - circle1.center.y),
                y: position.y + h / d * (circle2.center.x - circle1.center.x)
            });
        }
        return intersectionPoints;
    }

    static intersectLineCirclePoints(line: TLine, center: TPoint2D, radius: number): TPoint2D[] {
        let intersects: TPoint2D[] = [], k, b, d, x1, x2, y1, y2;

        // y = k*x + b
        if ((+line.pointB.x.toFixed(1) - +line.pointA.x.toFixed(1)) === 0) {
            if (+(Math.abs(center.x - line.pointA.x)).toFixed(3) > +(Math.abs(radius)).toFixed(3)) {
                return [];
            } else {
                d = Math.pow(2 * center.y, 2) - 4 * (Math.pow(center.y, 2) +
                    Math.pow((line.pointA.x - center.x), 2) - Math.pow(radius, 2));
                if (+d.toFixed(1) === 0) {
                    d = 0;
                }
                if (d < 0) {
                    return [];
                }

                y1 = +((Math.sqrt(d) + 2 * center.y) / 2).toFixed(11);
                y2 = +((-Math.sqrt(d) + 2 * center.y) / 2).toFixed(11);
                x1 = line.pointB.x;
                x2 = line.pointB.x;
                if (y1 === y2) {
                    intersects.push({x: x1, y: y1});
                } else {
                    intersects.push({x: x1, y: y1});
                    intersects.push({x: x2, y: y2});
                }
            }
        } else if ((+line.pointB.y.toFixed(1) - +line.pointA.y.toFixed(1)) === 0) {
            if (+(Math.abs(center.y - line.pointA.y)).toFixed(3) > +(Math.abs(radius)).toFixed(3)) {
                return [];
            } else {
                d = Math.pow(2 * center.x, 2) - 4 * (Math.pow(center.x, 2) +
                    Math.pow((line.pointA.y - center.y), 2) - Math.pow(radius, 2));
                if (+d.toFixed(1) === 0) {
                    d = 0;
                }
                if (d < 0) {
                    return [];
                }

                x1 = +((Math.sqrt(d) + 2 * center.x) / 2).toFixed(11);
                x2 = +((-Math.sqrt(d) + 2 * center.x) / 2).toFixed(11);
                y1 = line.pointB.y;
                y2 = line.pointB.y;
                if (x1 === x2) {
                    intersects.push({x: x1, y: y1});
                } else {
                    intersects.push({x: x1, y: y1});
                    intersects.push({x: x2, y: y2});
                }
            }
        } else {
            k = (line.pointB.y - line.pointA.y) / (line.pointB.x - line.pointA.x);
            b = line.pointB.y - k * line.pointB.x;
            //(x - a)2 + (y - b)2 = r2
            //находим дискриминант квадратного уравнения
            d = (Math.pow((2 * k * b - 2 * center.x - 2 * center.y * k), 2) -
                (4 + 4 * k * k) *
                (b * b - radius * radius + center.x * center.x + center.y * center.y - 2 * center.y * b));
            //если он равен 0, уравнение не имеет решения
            if (+d.toFixed(1) === 0) {
                d = 0;
            }
            if (d < 0) {
                return [];
            }

            //иначе находим корни квадратного уравнения
            x1 = ((-(2 * k * b - 2 * center.x - 2 * center.y * k) - Math.sqrt(d)) / (2 + 2 * k * k));
            x2 = ((-(2 * k * b - 2 * center.x - 2 * center.y * k) + Math.sqrt(d)) / (2 + 2 * k * k));
            //находим ординаты точек пересечения
            y1 = k * x1 + b;
            y2 = k * x2 + b;
            //если абсциссы точек совпадают, то пересечение только в одной точке
            if (x1 === x2) {
                intersects.push({x: x1, y: y1});
            } else {
                intersects.push({x: x1, y: y1});
                intersects.push({x: x2, y: y2});
            }
        }

        return intersects;
    }

    static isNearPoints(point1: TPoint3D, point2: TPoint3D, epsilon: number): boolean {
        let distance;

        epsilon = epsilon || 0;
        distance = this.getLength(point1, point2);
        return distance <= epsilon;
    }

    static getCenterPoint(pointA: TPoint2D, pointB: TPoint2D): TPoint2D {
        return {
            x: Math.abs(pointA.x - pointB.x) / 2 + (pointA.x < pointB.x ? pointA.x : pointB.x),
            y: Math.abs(pointA.y - pointB.y) / 2 + (pointA.y < pointB.y ? pointA.y : pointB.y)
        };
    }

    static getCommonInterval(line1: TLine3D, line2: TLine3D, minInterval: number): TCommonInterval | undefined {
        let pointsInLine: TCommonIntervalLines | undefined;
        let extremumPoints: TCommonInterval | undefined

        pointsInLine = this.calculateCommonIntervalLines(line1, line2);
        if (pointsInLine) {
            extremumPoints = this.findExtremumPoints(pointsInLine);
            if (!extremumPoints || extremumPoints.length < minInterval) {
                return undefined;
            }
            return extremumPoints;
        }

        return undefined;
    }

    static findExtremumPoints(intervalsLines: TCommonIntervalLines): TCommonInterval | undefined {
        let box1: {min: TPoint3D, max: TPoint3D}, box2: {min: TPoint3D, max: TPoint3D};
        let minPoint: TPoint3D, maxPoint: TPoint3D;

        box1 = {
            min: {
                x: Math.min(intervalsLines.line1.pointA.x, intervalsLines.line1.pointB.x),
                y: Math.min(intervalsLines.line1.pointA.y, intervalsLines.line1.pointB.y),
                z: Math.min(intervalsLines.line1.pointA.z, intervalsLines.line1.pointB.z)
            },
            max: {
                x: Math.max(intervalsLines.line1.pointA.x, intervalsLines.line1.pointB.x),
                y: Math.max(intervalsLines.line1.pointA.y, intervalsLines.line1.pointB.y),
                z: Math.max(intervalsLines.line1.pointA.z, intervalsLines.line1.pointB.z)
            }
        };
        box2 = {
            min: {
                x: Math.min(intervalsLines.line2.pointA.x, intervalsLines.line2.pointB.x),
                y: Math.min(intervalsLines.line2.pointA.y, intervalsLines.line2.pointB.y),
                z: Math.min(intervalsLines.line2.pointA.z, intervalsLines.line2.pointB.z)
            },
            max: {
                x: Math.max(intervalsLines.line2.pointA.x, intervalsLines.line2.pointB.x),
                y: Math.max(intervalsLines.line2.pointA.y, intervalsLines.line2.pointB.y),
                z: Math.max(intervalsLines.line2.pointA.z, intervalsLines.line2.pointB.z)
            }
        };
        minPoint = {x: -Infinity, y: -Infinity, z: -Infinity}
        maxPoint = {x: Infinity, y: Infinity, z: Infinity}
        if (minPoint.x < box1.min.x) {
            minPoint.x = box1.min.x
        }
        if (minPoint.x < box2.min.x) {
            minPoint.x = box2.min.x
        }
        if (minPoint.y < box1.min.y) {
            minPoint.y = box1.min.y
        }
        if (minPoint.y < box2.min.y) {
            minPoint.y = box2.min.y
        }
        if (minPoint.z < box1.min.z) {
            minPoint.z = box1.min.z
        }
        if (minPoint.z < box2.min.z) {
            minPoint.z = box2.min.z
        }
        if (minPoint.x < box1.min.x) {
            minPoint.x = box1.min.x
        }
        if (minPoint.x < box2.min.x) {
            minPoint.x = box2.min.x
        }
        if (minPoint.y < box1.min.y) {
            minPoint.y = box1.min.y
        }
        if (minPoint.y < box2.min.y) {
            minPoint.y = box2.min.y
        }
        if (minPoint.z < box1.min.z) {
            minPoint.z = box1.min.z
        }
        if (minPoint.z < box2.min.z) {
            minPoint.z = box2.min.z
        }

        if (maxPoint.x > box1.max.x) {
            maxPoint.x = box1.max.x
        }
        if (maxPoint.x > box2.max.x) {
            maxPoint.x = box2.max.x
        }
        if (maxPoint.y > box1.max.y) {
            maxPoint.y = box1.max.y
        }
        if (maxPoint.y > box2.max.y) {
            maxPoint.y = box2.max.y
        }
        if (maxPoint.z > box1.max.z) {
            maxPoint.z = box1.max.z
        }
        if (maxPoint.z > box2.max.z) {
            maxPoint.z = box2.max.z
        }
        if (!isFinite(minPoint.x) || !isFinite(minPoint.y) || !isFinite(minPoint.z) ||
            !isFinite(maxPoint.x) || !isFinite(maxPoint.y) || !isFinite(maxPoint.z)) {
            return undefined;
        }

        return {
            pointA: minPoint,
            pointB: maxPoint,
            length: this.getLength(minPoint, maxPoint, 2)
        }
    }

    static calculateCommonIntervalLines(line1: TLine3D, line2: TLine3D): TCommonIntervalLines | undefined {
        let projectPointA2: TPoint3D | undefined, projectPointB2: TPoint3D | undefined, result;
        projectPointA2 = this.projectionPoint3D(line2.pointA, line1.pointA, line1.pointB);
        projectPointB2 = this.projectionPoint3D(line2.pointB, line1.pointA, line1.pointB);
        if (!projectPointA2 || !projectPointB2) {
            return undefined;
        }

        result = (this.isEqualIntervals(projectPointA2, projectPointB2, line1.pointA, line1.pointB, 0.0001) ||
            this.isPointInInterval3D(projectPointA2, line1.pointA, line1.pointB) ||
            this.isPointInInterval3D(projectPointB2, line1.pointA, line1.pointB) ||
            this.isPointInInterval3D(line1.pointA, projectPointA2, projectPointB2) ||
            this.isPointInInterval3D(line1.pointB, projectPointA2, projectPointB2));

        if (result) {
            return {
                line1: {pointA: line1.pointA, pointB: line1.pointB},
                line2: {pointA: projectPointA2, pointB: projectPointB2}
            };
        } else {
            return undefined;
        }

    }

    static isPointInInterval3D(point: TPoint3D, pointA: TPoint3D, pointB: TPoint3D, inclusive?: boolean): boolean {
        inclusive = inclusive !== undefined ? inclusive : false;

        if (inclusive) {
            return ((point.x <= pointA.x && point.x >= pointB.x) || (point.x >= pointA.x && point.x <= pointB.x)) &&
                ((point.y <= pointA.y && point.y >= pointB.y) || (point.y >= pointA.y && point.y <= pointB.y)) &&
                ((point.z <= pointA.z && point.z >= pointB.z) || (point.z >= pointA.z && point.z <= pointB.z));
        } else {
            return ((point.x < pointA.x && point.x > pointB.x) ||
                    (point.x > pointA.x && point.x < pointB.x) ||
                    (point.x === pointA.x && point.x === pointB.x)) &&
                ((point.y < pointA.y && point.y > pointB.y) ||
                    (point.y > pointA.y && point.y < pointB.y) ||
                    (point.y === pointA.y && point.y === pointB.y)) &&
                ((point.z < pointA.z && point.z > pointB.z) ||
                    (point.z > pointA.z && point.z < pointB.z) ||
                    (point.z === pointA.z && point.z === pointB.z));
        }
    }

    static projectionPoint3D(point: TPoint3D, pointA: TPoint3D, pointB: TPoint3D): TPoint3D | undefined {
        let projectPoint: TPoint3D, subPointA: TPoint3D, subPointB: TPoint3D, scalarPoint: TPoint3D, dotValue: number,
            dotValue2: number;

        subPointA = {x: pointA.x - point.x, y: pointA.y - point.y, z: pointA.z - point.z};
        subPointB = {x: pointB.x - pointA.x, y: pointB.y - pointA.y, z: pointB.z - pointA.z};
        dotValue = subPointA.x * subPointB.x + subPointA.y * subPointB.y + subPointA.z * subPointB.z;
        dotValue2 = subPointB.x * subPointB.x + subPointB.y * subPointB.y + subPointB.z * subPointB.z;
        if (dotValue2 === 0) {
            return undefined;
        }
        scalarPoint = {
            x: subPointB.x * (dotValue / dotValue2),
            y: subPointB.y * (dotValue / dotValue2),
            z: subPointB.z * (dotValue / dotValue2)
        }
        projectPoint = {x: pointA.x - scalarPoint.x, y: pointA.y - scalarPoint.y, z: pointA.z - scalarPoint.z};

        return projectPoint;
    }

    static isEqualIntervals(pointA: TPoint3D, pointB: TPoint3D, pointC: TPoint3D, pointD: TPoint3D, epsilon?: number) {
        return (this.isEqualPoints(pointA, pointC, epsilon) && this.isEqualPoints(pointB, pointD, epsilon)) ||
            (this.isEqualPoints(pointA, pointD, epsilon) && this.isEqualPoints(pointB, pointC, epsilon));
    }

    static getPointDirectionByLine(point: TPoint2D, line: TLine): number {
        return +((line.pointB.x - line.pointA.x) * (point.y - line.pointA.y) -
            (line.pointB.y - line.pointA.y) * (point.x - line.pointA.x)).toFixed(6);
    }

    static normalizeAngle(angle: number): number {
        while (angle < 0) {
            angle += 2 * Math.PI;
        }
        while (angle >= 2 * Math.PI) {
            angle -= 2 * Math.PI;
        }

        return angle;
    }
}
