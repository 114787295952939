import {IClassNameProps} from "@bem-react/core";
import {cn} from "@bem-react/classname";
import React from "react";
import './PageWizard.css';
import {Wizard} from '../../ui/elements/Wizard/Wizard';

export const cnPageWizard = cn('PageWizard');

export interface IPageWizardProps extends IClassNameProps {

}

export const PageWizard: React.FC<IPageWizardProps> = ({className}) => {
    // const needSave: boolean = useSelector((state: AppState) => state.wizard.options.needSave);

    return (
        <div className={cnPageWizard({}, [className])}>
            <Wizard />
        </div>
    );
}