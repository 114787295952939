import { IParamsValue, IValue } from "./initialValue";

export  const validateValue = ( paramsValue: IParamsValue, arrValues: IValue[], initPositionType?: string) => {
    
    const {
      positionType,
      initSizes,
      initSizesLength,
      rotation,
      margin,
      initPosition,
      initPositionX,
      initPositionY,
      initPositionZ,
      marginX,
      marginY,
      marginZ,
      rotationX,
      rotationY,
      rotationZ,
    } = paramsValue;
    if (!positionType)
      return 'Поле "Позиция для элемента" обязательно для заполнения';

    // if (
    //   arrValues.some((el) => el.positionType === positionType) &&
    //   initPositionType !== positionType
    // )
    //   return "Такая позиция уже существует";

    if (initSizes && !initSizesLength)
      return 'Поле "Размеры" обязательно для заполнения';

    if (
      initSizes &&
      (Number(initSizesLength) <= 0 || Number(initSizesLength) > 1000)
    )
      return 'Поле "Указать длину в мм" должно быть больше 0 и меньше 1000';

    if (rotation) {
      if (
        !rotationX ||
        Number(rotationX) > 180 ||
        Number(rotationX) < -180 ||
        !rotationY ||
        Number(rotationY) > 180 ||
        Number(rotationY) < -180 ||
        !rotationZ ||
        Number(rotationZ) > 180 ||
        Number(rotationZ) < -180
      ) {
        return "Необходимо указать значения в диапазоне от -180° до 180°";
      }
    }

    if (initPosition && (!initPositionX || !initPositionY || !initPositionZ))
      return 'Поле "Расположение" обязательно для заполнения';

    if (
      initPosition &&
      (Number(initPositionX) > 1000 ||
        Number(initPositionX) < -1000 ||
        Number(initPositionY) > 1000 ||
        Number(initPositionY) < -1000 ||
        Number(initPositionZ) > 1000 ||
        Number(initPositionZ) < -1000)
    ) {
      return 'Необходимо указать значения поля "Расположение" в диапазоне от -1000 до 1000';
    }

    if (margin && (!marginX || !marginY || !marginZ))
      return 'Поле "Отступы" обязательно для заполнения';

    if (
      margin &&
      (Number(marginX) > 400 ||
        Number(marginX) < -400 ||
        Number(marginY) > 400 ||
        Number(marginY) < -400 ||
        Number(marginZ) > 400 ||
        Number(marginZ) < -400)
    ) {
      return 'Необходимо указать значения поля "Отступы" в диапазоне от -400 до 400';
    }

    return "";
  };