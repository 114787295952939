import {Wall} from '../../../Wall/Wall';
import {ISaveWallData} from '../../../../../../common-code/interfaces/saveData/ISaveWallData';
import {ThreeRoom} from '../ThreeRoom/ThreeRoom';
import {
    ExtrudeGeometry, FrontSide, LineSegments,
    Mesh, MeshBasicMaterial,
    MeshStandardMaterial, Object3D, PlaneGeometry, Shape,
    Vector2,
    Vector3
} from 'three';
import {CommonHelper} from 'common-code';
import {ThreeMathHelper} from '../../../../helpers/ThreeMathHelper/ThreeMathHelper';
import {onAfterRenderHideForBack, onBeforeRenderHideForBack} from '../../../../helpers/ThreeHelper/ThreeHelper';
import {KitchenService} from '../../../../services/KitchenService/KitchenService';
import {ThreeRoomPoint} from '../../../../points/ThreeRoomPoint/ThreeRoomPoint';
import {ThreeSize} from '../../ThreeSize/ThreeSize';
import {MathHelper} from 'common-code';
import {TLine} from '../../../../../../common-code/types/TLine';
import {TPoint2D} from '../../../../../../common-code/types/TPoint2D';
import {
    AXIS_X,
    AXIS_Z,
    CONNECTION_TYPE_DEFAULT,
    CONNECTION_TYPE_MAIN_LEFT,
    CONNECTION_TYPE_MAIN_RIGHT,
    SIZE_TYPE_HEIGHT,
    SIZE_TYPE_WIDTH
} from '../../../../../../common-code/constants';
import {UNIT_SIZE_TEXT_SIZE, WALL_STICK_LENGTH} from '../../../../constants';
import {IMaterialTextures} from '../../../../interfaces/IMaterialTextures';
import {KITCHEN_SIZES_TYPE_ALL, PLANE_TYPE_WALL} from '../../../../../constants';
import {TDetail} from '../../../../types/TDetail';
import {IMaterialData} from '../../../../../../common-code/interfaces/materials/IMaterialData';
import {ISaveSizeData} from '../../../../../../common-code/interfaces/saveData/ISaveSizeData';
import {ISegmentPoints} from '../../../../interfaces/ISegmentPoints';
import {ThreeWallIsland} from '../../constructive/ThreeWallIsland/ThreeWallIsland';

export class ThreeWall extends Wall {
    pointA: ThreeRoomPoint;
    pointB: ThreeRoomPoint;
    room: ThreeRoom;
    service: KitchenService;
    rightNeighbor?: ThreeWall;
    leftNeighbor?: ThreeWall;
    materialData: IMaterialData;
    materialTextures: IMaterialTextures;
    material?: MeshStandardMaterial;
    plinthShape?: Shape;
    plinthMaterial?: MeshStandardMaterial;
    wallIsland?: ThreeWallIsland;

    sizes: ThreeSize[];

    bottomPlinth?: Mesh;
    readonly SIZE_GAP: number = 6.5;

    constructor(wallData: ISaveWallData, room: ThreeRoom) {
        super(wallData, room.getService());
        this.service = room.getService();
        this.room = room;
        this.pointA = this.service.findPointById(wallData.pointA);
        this.pointB = this.service.findPointById(wallData.pointB);
        this.materialData = this.initMaterialData(wallData.materialId);
        this.materialTextures = this.loadTextures();
        this.sizes = [];
    }

    public initState(isRebuild?: boolean) {
        this.setFrontPoints();
        this.calculatePoints();
        this.calculateVisiblePoints();
        this.calculateStickPoints();
        super.initState(isRebuild);
    }

    public trySetMaterial(material: IMaterialData) {
        if (!this.body) {
            return;
        }
        this.materialData = this.initMaterialData(material.id);
        this.materialTextures = this.loadTextures();
        this.material = undefined;
        this.body.material = this.createBodyMaterial();
        this.body.material.needsUpdate = true;
        if (this.bottomPlinth) {
            this.plinthMaterial = undefined;
            this.bottomPlinth.material = this.getPlinthMaterial();
            this.bottomPlinth.material.needsUpdate = true;
        }
    }

    public createView(isRebuild?: boolean) {
        this.createBody();
        this.createFront();
        this.createPlinthShape();
        this.createPlinths();
        this.createSizes();
        super.createView(isRebuild);
        this.ready = true;
    }

    public getData(): ISaveWallData {
        let data: ISaveWallData = CommonHelper.deepCopy(this.saveData);
        data.materialId = this.materialData.id;
        delete data.isDimensions;
        delete data.isLevelStick;
        delete data.isStick;
        delete data.isWallStick;

        return data;
    }

    public remove() {
        this.wallIsland = undefined;
        super.remove();
    }

    public getWallIsland(): ThreeWallIsland | undefined {
        return this.wallIsland;
    }

    public setWallIsland(wallIsland: ThreeWallIsland | undefined) {
        this.wallIsland = wallIsland;
    }

    public setCenterPosition() {
        if (!this.view3d.userData.centerPosition ||
            !(this.view3d.userData.centerPosition instanceof Vector3)) {
            this.view3d.userData.centerPosition = new Vector3()
        }
        this.view3d.userData.centerPosition = this.view3d.userData.centerPosition.copy(this.frontPoints.pointA);
    }

    public calculatePoints() {
        // Если уже рассчитали точки, то не нужно заново считать
        if (this.points) {
            return;
        }
        let localPointA;
        let localPointB;

        localPointA = ThreeMathHelper.toVector2D(this.pointA.value);
        localPointB = ThreeMathHelper.toVector2D(this.pointB.value);
        this.points = {
            A: this.calculateShiftPoint(localPointA, localPointA, localPointB, 0),
            B: this.calculateShiftPoint(localPointB, localPointA, localPointB, 0),
            C: this.calculateShiftPoint(localPointA, localPointA, localPointB, this.depth),
            D: this.calculateShiftPoint(localPointB, localPointA, localPointB, this.depth),
        };
    }

    public rebuildSizes(unitsPolygon?: TPoint2D[]) {
        let size: ThreeSize;
        let localPointA;
        let localPointB;
        let pointA;
        let pointB;
        let point;

        localPointA = ThreeMathHelper.toVector2D(this.pointA.value);
        localPointB = ThreeMathHelper.toVector2D(this.pointB.value);
        pointA = MathHelper.getShiftPoint2D(localPointA, localPointA, localPointB, -this.SIZE_GAP);
        pointB = MathHelper.getShiftPoint2D(localPointB, localPointA, localPointB, -this.SIZE_GAP);
        point = MathHelper.getPointByRatio2D(pointA, pointB, this.SIZE_GAP/MathHelper.getLength2D(pointA, pointB));
        for (size of this.sizes) {
            size.hide();
            if (this.service.getOptions().sizesType === KITCHEN_SIZES_TYPE_ALL) {
                if (size.getType() === SIZE_TYPE_WIDTH || !unitsPolygon || !MathHelper.isPointInsidePolygon(
                    point,
                    unitsPolygon
                )) {
                    size.show();
                }
            }
        }
    }

    public getUnionDetailYPosition(detail: TDetail): number {
        return 0;
    }

    protected createSizes() {
        let sizesData: ISaveSizeData[];
        let sizeData: ISaveSizeData;

        sizesData = this.initSizesData();
        for (sizeData of sizesData) {
            this.createSize(sizeData);
        }
    }

    protected getSketchViewMaterial(): MeshBasicMaterial {
        return this.service.getRoomSketchViewMaterial()
    }

    protected createSize(sizeData: ISaveSizeData) {
        let size: ThreeSize;

        size = new ThreeSize(sizeData, this);
        size.initState();
        size.createView();
        this.view3d.add(size.view3d);
        this.sizes.push(size);
    }

    protected initSizesData(): ISaveSizeData[] {
        let sizesData: ISaveSizeData[] = [];
        let localPointA;
        let localPointB;
        let pointA;
        let pointB;

        localPointA = ThreeMathHelper.toVector2D(this.pointA.value);
        localPointB = ThreeMathHelper.toVector2D(this.pointB.value);
        pointA = ThreeMathHelper.getShiftPoint2D(localPointA, localPointA, localPointB, -this.SIZE_GAP);
        pointB = ThreeMathHelper.getShiftPoint2D(localPointB, localPointA, localPointB, -this.SIZE_GAP);
        if (ThreeMathHelper.getLength(this.pointA.value, this.pointB.value) > 100) {
            sizesData.push({
                id: 0,
                pointA: {x: pointA.x, y: this.room.getHeight(), z: pointA.y},
                pointB: {x: pointB.x, y: this.room.getHeight(), z: pointB.y},
                type: SIZE_TYPE_WIDTH,
                decimal: 0,
                textSize: UNIT_SIZE_TEXT_SIZE,
                rotation: {y: this.getFrontVectorAngle(), z: Math.PI},
                textInvert: true,
            });
            sizesData.push({
                id: 0,
                pointA: {x: pointA.x, y: 0, z: pointA.y},
                pointB: {x: pointA.x, y: this.room.getHeight(), z: pointA.y},
                type: SIZE_TYPE_HEIGHT,
                decimal: 0,
                rotation: {y: this.getFrontVectorAngle(), z: -Math.PI/2},
                textSize: UNIT_SIZE_TEXT_SIZE,
            });
        }

        return sizesData;
    }

    protected initMaterialData(materialId?: string): IMaterialData {
        return this.service.getWallMaterial(materialId);
    }

    protected loadTextures(): IMaterialTextures {
        return this.service.loadMaterialTextures(this.materialData.id, this.materialData.textures);
    }

    protected calculateStickPoints() {
        if (!this.points) {
            throw new Error('error-ThreeWall-calculateStickPoints')
        }
        let shiftPoints = ThreeMathHelper.getParallelLine(
            {
                pointA: ThreeMathHelper.toVector2D(this.points.A),
                pointB: ThreeMathHelper.toVector2D(this.points.B)
            },
            -WALL_STICK_LENGTH
        );
        let pointC = new Vector3(shiftPoints.pointA.x, 0, shiftPoints.pointA.y);
        let pointD = new Vector3(shiftPoints.pointB.x, 0, shiftPoints.pointB.y);
        if (this.leftNeighbor) {
            let intersectPoint = ThreeMathHelper.getIntersectionPoint(
                {
                    pointA: ThreeMathHelper.toVector2D(pointC),
                    pointB: ThreeMathHelper.toVector2D(pointD)
                },
                {
                    pointA: ThreeMathHelper.toVector2D(this.leftNeighbor.pointA.value),
                    pointB: ThreeMathHelper.toVector2D(this.leftNeighbor.pointB.value)
                }
            );
            if (intersectPoint) {
                pointC = new Vector3(intersectPoint.x, 0, intersectPoint.y);
            }
        }
        if (this.rightNeighbor) {
            let intersectPoint = ThreeMathHelper.getIntersectionPoint(
                {
                    pointA: ThreeMathHelper.toVector2D(pointC),
                    pointB: ThreeMathHelper.toVector2D(pointD)
                },
                {
                    pointA: ThreeMathHelper.toVector2D(this.rightNeighbor.pointA.value),
                    pointB: ThreeMathHelper.toVector2D(this.rightNeighbor.pointB.value)
                }
            );
            if (intersectPoint) {
                pointD = new Vector3(intersectPoint.x, 0, intersectPoint.y);
            }
        }

        this.stickPoints = {
            A: this.points.A.clone(),
            B: this.points.B.clone(),
            C: pointC,
            D: pointD,
        }
    }

    public removeChildren() {
        this.body = undefined;
        if (this.size) {
            this.size.remove();
            this.size = undefined;
        }
        super.removeChildren();
    }

    public setLeftNeighbor(wall: ThreeWall): void {
        this.leftNeighbor = wall;
    }

    public setRightNeighbor(wall: ThreeWall): void {
        this.rightNeighbor = wall;
    }

    public calculateVisiblePoints() {
        if (!this.points) {
            return;
        }
        this.visiblePoints = {...this.points} as ISegmentPoints;

        if (this.leftNeighbor) {
            this.calculateLeftNeighborIntersectPoints();
        }
        if (this.rightNeighbor) {
            this.calculateRightNeighborIntersectPoints();
        }
    }

    public getPoints2D(): TLine {
        return {
            pointA: {x: +this.pointA.value.x, y: +this.pointA.value.z},
            pointB: {x: +this.pointB.value.x, y: +this.pointB.value.z}
        };
    }

    public getStickPolygon(): TPoint2D[] {
        let points: TPoint2D[] = [];

        if (!this.stickPoints) {
            throw new Error('error-ThreeWall-getStickPolygon');
        }
        points.push({x: this.stickPoints.A.x, y: this.stickPoints.A.z});
        points.push({x: this.stickPoints.B.x, y: this.stickPoints.B.z});
        points.push({x: this.stickPoints.D.x, y: this.stickPoints.D.z});
        points.push({x: this.stickPoints.C.x, y: this.stickPoints.C.z});

        return points;
    }

    protected setFrontPoints() {
        let pointA: Vector3;
        let pointB2D: Vector2;
        let pointB: Vector3;

        pointA = new Vector3(
            (this.pointA.value.x + this.pointB.value.x) / 2,
            (this.pointA.value.y + this.pointB.value.y) / 2,
            (this.pointA.value.z + this.pointB.value.z) / 2
        );

        pointB2D = ThreeMathHelper.getShiftPoint2D(
            ThreeMathHelper.toVector2D(pointA),
            ThreeMathHelper.toVector2D(this.pointA.value),
            ThreeMathHelper.toVector2D(this.pointB.value),
            -100
        );
        pointB = new Vector3(pointB2D.x, this.pointA.value.y, pointB2D.y);

        this.frontPoints = {
            pointA: pointA,
            pointB: pointB
        };
    }

    protected calculateShiftPoint(
        point: Vector2,
        pointA: Vector2,
        pointB: Vector2,
        shift: number
    ): Vector3 {
        let shiftPoint: Vector2;

        shiftPoint = ThreeMathHelper.getShiftPoint2D(point, pointA, pointB, shift);

        return new Vector3(
            shiftPoint.x,
            0,
            shiftPoint.y
        );
    }

    protected calculateLeftNeighborIntersectPoints() {
        let intersectPoint;
        if (!this.points || !this.visiblePoints || !this.leftNeighbor) {
            return;
        }
        this.leftNeighbor.calculatePoints();
        if (this.leftNeighbor.points) {
            switch (this.pointA.connectionType) {
                case CONNECTION_TYPE_DEFAULT:
                    intersectPoint = ThreeMathHelper.getIntersectionPoint(
                        {
                            pointA: ThreeMathHelper.toVector2D(this.points.A, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.points.B, AXIS_X, AXIS_Z)
                        },
                        {
                            pointA: ThreeMathHelper.toVector2D(this.leftNeighbor.points.A, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.leftNeighbor.points.B, AXIS_X, AXIS_Z)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.A = new Vector3(intersectPoint.x, 0, intersectPoint.y);
                    }
                    intersectPoint = ThreeMathHelper.getIntersectionPoint(
                        {
                            pointA: ThreeMathHelper.toVector2D(this.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.points.D, AXIS_X, AXIS_Z)
                        },
                        {
                            pointA: ThreeMathHelper.toVector2D(this.leftNeighbor.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.leftNeighbor.points.D, AXIS_X, AXIS_Z)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.C = new Vector3(intersectPoint.x, 0, intersectPoint.y);
                    }
                    break;
                case CONNECTION_TYPE_MAIN_LEFT:
                    intersectPoint = ThreeMathHelper.getIntersectionPoint(
                        {
                            pointA: ThreeMathHelper.toVector2D(this.points.A, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.points.B, AXIS_X, AXIS_Z)
                        },
                        {
                            pointA: ThreeMathHelper.toVector2D(this.leftNeighbor.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.leftNeighbor.points.D, AXIS_X, AXIS_Z)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.A = new Vector3(intersectPoint.x, 0, intersectPoint.y);
                    }
                    intersectPoint = ThreeMathHelper.getIntersectionPoint(
                        {
                            pointA: ThreeMathHelper.toVector2D(this.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.points.D, AXIS_X, AXIS_Z)
                        },
                        {
                            pointA: ThreeMathHelper.toVector2D(this.leftNeighbor.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.leftNeighbor.points.D, AXIS_X, AXIS_Z)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.C = new Vector3(intersectPoint.x, 0, intersectPoint.y);
                    }
                    break;
            }
        }
    }

    protected calculateRightNeighborIntersectPoints() {
        let intersectPoint;
        if (!this.points || !this.visiblePoints || !this.rightNeighbor) {
            return;
        }
        this.rightNeighbor.calculatePoints();
        if (this.rightNeighbor.points) {
            switch (this.pointB.connectionType) {
                case CONNECTION_TYPE_DEFAULT:
                    intersectPoint = ThreeMathHelper.getIntersectionPoint(
                        {
                            pointA: ThreeMathHelper.toVector2D(this.points.A, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.points.B, AXIS_X, AXIS_Z)
                        },
                        {
                            pointA: ThreeMathHelper.toVector2D(this.rightNeighbor.points.A, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.rightNeighbor.points.B, AXIS_X, AXIS_Z)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.B = new Vector3(intersectPoint.x, 0, intersectPoint.y);
                    }
                    intersectPoint = ThreeMathHelper.getIntersectionPoint(
                        {
                            pointA: ThreeMathHelper.toVector2D(this.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.points.D, AXIS_X, AXIS_Z)
                        },
                        {
                            pointA: ThreeMathHelper.toVector2D(this.rightNeighbor.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.rightNeighbor.points.D, AXIS_X, AXIS_Z)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.D = new Vector3(intersectPoint.x, 0, intersectPoint.y);
                    }
                    break;
                case CONNECTION_TYPE_MAIN_RIGHT:
                    intersectPoint = ThreeMathHelper.getIntersectionPoint(
                        {
                            pointA: ThreeMathHelper.toVector2D(this.points.A, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.points.B, AXIS_X, AXIS_Z)
                        },
                        {
                            pointA: ThreeMathHelper.toVector2D(this.rightNeighbor.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.rightNeighbor.points.D, AXIS_X, AXIS_Z)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.B = new Vector3(intersectPoint.x, 0, intersectPoint.y);
                    }
                    intersectPoint = ThreeMathHelper.getIntersectionPoint(
                        {
                            pointA: ThreeMathHelper.toVector2D(this.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.points.D, AXIS_X, AXIS_Z)
                        },
                        {
                            pointA: ThreeMathHelper.toVector2D(this.rightNeighbor.points.C, AXIS_X, AXIS_Z),
                            pointB: ThreeMathHelper.toVector2D(this.rightNeighbor.points.D, AXIS_X, AXIS_Z)
                        }
                    );
                    if (intersectPoint) {
                        this.visiblePoints.D = new Vector3(intersectPoint.x, 0, intersectPoint.y);
                    }
                    break;
            }
        }
    }

    protected createBody() {
        if (!this.visiblePoints) {
            return;
        }
        let carcass: LineSegments;

        this.body = new Mesh(this.createBodyGeometry(), this.createBodyMaterial());
        this.body.renderOrder = 2;
        this.body.name = 'body';
        this.body.onBeforeRender = onBeforeRenderHideForBack;
        this.body.onAfterRender = onAfterRenderHideForBack;
        this.body.matrixAutoUpdate = false;
        this.body.userData.sketchMaterial = new MeshBasicMaterial({color: '#e7e7e7'});
        this.body.receiveShadow = true;
        carcass = this.createMeshCarcass(this.body);
        carcass.userData.parent = this.view3d;
        carcass.onBeforeRender = onBeforeRenderHideForBack;
        carcass.onAfterRender = onAfterRenderHideForBack;
        this.view3d.add(this.body);
    }

    public getWidth(): number {
        if (!this.visiblePoints) {
            return 0;
        }
        return this.visiblePoints.A.clone().distanceTo(this.visiblePoints.B);
    }

    public getCenter(): Vector3 {
        let center = new Vector3();
        if (!this.visiblePoints) {
            return center;
        }
        center.x = (this.visiblePoints.B.x + this.visiblePoints.A.x)/2;
        center.y = this.getHeight()/2;
        center.z = (this.visiblePoints.B.z + this.visiblePoints.A.z)/2;

        return center;
    }

    public getHeight(): number {
        return this.room.getHeight();
    }

    public getFront(): Object3D | undefined {
        return this.front;
    }

    protected createFront() {
        if (!this.visiblePoints) {
            return;
        }
        this.front = new Mesh(new PlaneGeometry(this.getWidth(), this.getHeight(), 1, 1), new MeshBasicMaterial({
            visible: false,
            side: FrontSide,
        }))
        this.front.position.copy(this.getCenter());
        this.front.rotation.y = this.getFrontVectorAngle();
        this.front.name = 'front';
        this.front.userData.wall = this;
        this.front.userData.notSwitchView = true;
        this.front.userData.type = PLANE_TYPE_WALL;
        // let textMesh = this.service.createTextSizeMesh('' + this.id);
        // textMesh.position.setZ(5);
        // this.front.add(textMesh);
        this.view3d.add(this.front);
    }

    protected createBodyGeometry() {
        let topPoints: ISegmentPoints;
        if (!this.points || !this.visiblePoints) {
            throw new Error('error-ThreeWall-createBodyGeometry')
        }
        topPoints = {
            A: this.visiblePoints.A.clone(),
            B: this.visiblePoints.B.clone(),
            C: this.visiblePoints.C.clone(),
            D: this.visiblePoints.D.clone(),
        };
        topPoints.A.y = this.room.getHeight();
        topPoints.B.y = this.room.getHeight();
        topPoints.C.y = this.room.getHeight();
        topPoints.D.y = this.room.getHeight();

        return ThreeMathHelper.createBoxGeometry({points1: this.visiblePoints, points2: topPoints});
    }

    protected createBodyMaterial(): MeshStandardMaterial {
        if (!this.material) {
            this.material = new MeshStandardMaterial({
                    color: this.materialData.color || '#fffce6',
                    emissive: this.materialData.emissiveColor || '',
                    emissiveIntensity: 0.1,
                    map: this.materialTextures.texture || null,
                    normalMap: this.materialTextures.normal || null,
                    roughnessMap: this.materialTextures.roughness || null,
                }
            );
        }

        return this.material;
    }

    protected createPlinths() {
        let plinthGeometry;
        let carcass: LineSegments;

        plinthGeometry = new ExtrudeGeometry(this.plinthShape, {
            bevelEnabled: false,
            depth: ThreeMathHelper.getLength(this.pointA.value, this.pointB.value)
        });
        this.bottomPlinth = new Mesh(plinthGeometry, this.getPlinthMaterial());
        this.bottomPlinth.receiveShadow = true
        this.bottomPlinth.rotation.set(0, this.getFrontVectorAngle() - 0.5 * Math.PI, 0);
        this.bottomPlinth.position.copy(this.pointB.value);
        this.bottomPlinth.renderOrder = 2;
        this.bottomPlinth.name = 'bottomPlinth';
        this.bottomPlinth.onBeforeRender = onBeforeRenderHideForBack;
        this.bottomPlinth.onAfterRender = onAfterRenderHideForBack;
        this.bottomPlinth.matrixAutoUpdate = false;
        this.bottomPlinth.userData.sketchMaterial = new MeshBasicMaterial({color: '#e7e7e7'});
        carcass = this.createMeshCarcass(this.bottomPlinth);
        carcass.userData.parent = this.view3d;
        carcass.renderOrder = 2;
        carcass.onBeforeRender = onBeforeRenderHideForBack;
        carcass.onAfterRender = onAfterRenderHideForBack;
        this.view3d.add(this.bottomPlinth);
    }

    protected createPlinthShape() {
        this.plinthShape = new Shape();
        this.plinthShape.moveTo(0, 0);
        this.plinthShape.lineTo(0, 80);
        this.plinthShape.lineTo(12, 80);
        this.plinthShape.lineTo(14, 78);
        this.plinthShape.lineTo(16, 76);
        this.plinthShape.lineTo(16, 0);
        this.plinthShape.lineTo(0, 0);
    }

    protected getPlinthMaterial(): MeshStandardMaterial {
        if (!this.plinthMaterial) {
            this.plinthMaterial = new MeshStandardMaterial({
                color: this.materialData.color || '#fffce6',
                emissive: '#ffffff',
                emissiveIntensity: 0.03,
                map: this.materialTextures.texture || null,
                normalMap: this.materialTextures.normal || null,
                roughnessMap: this.materialTextures.roughness || null,
            });
        }

        return this.plinthMaterial;
    }

}