import React from "react";
import Trash from "../../../icons/Trash";
import Edit from '../../../icons/Edit';
import { cnCreateForm } from "../../FormModule";
import { ISaveFacadeData } from "common-code/lib/interfaces/saveData/ISaveFacadeData";
import ModalFacades from "./ModalFacades";
import { IFacade, initionalFacade } from "./initialFacade";
import { validateFacade } from "./validateFacade";
import { createFacade } from "./createFacade";

interface IFacadeProps {
    objFacades: Record<string,ISaveFacadeData[]>;
    setObjFacades: (arr: Record<string,ISaveFacadeData[]>) => void;
    facade: ISaveFacadeData;
    index: number;
    maxDimensions: {
      maxHeight: number,
      maxWidth: number,
      maxDepth: number
    };
    IsEndUnit: boolean;
  }

function Facade({
    objFacades,
    setObjFacades,
    facade,
    index,
    maxDimensions,
    IsEndUnit
  }: IFacadeProps) {

  const keyFacade = facade.groupId ? facade.groupId.toString() : "single";
  const initialValFacade = initionalFacade(facade);
  
  const [facadeNew, setFacadeNew] = React.useState<IFacade>(initialValFacade);  

  const handleDelete = () => {    
    const filtered = objFacades[keyFacade].filter((_, i) => i !== index);
    
    if (
      facade.groupId &&
      index === 0 &&
      objFacades[facade.groupId].length > 1 &&
      (facade.calculateSizes?.width || facade.calculateSizes?.height)
    ) {
      if(window.confirm(
        "Если удалить родительский фасад группы, то все дочерние фасады будут удалены. Продолжить?"
      )) {
        const newObjFacades = { ...objFacades };
        delete newObjFacades[facade.groupId];
        setObjFacades(newObjFacades);
      }
      return;
    }
    if(filtered.length === 0) {
      const newObjFacades = { ...objFacades };
      delete newObjFacades[keyFacade];
      setObjFacades(newObjFacades);
      return;
    }
    setObjFacades({
      ...objFacades,
      [keyFacade]: filtered,
    });
  };

  const handleSubmit =  () => {    
    const error = validateFacade(facadeNew,maxDimensions);
    if (error) {
        setFacadeNew({ ...facadeNew, error});
        return;
    }

    const dataFacade = createFacade(facadeNew, IsEndUnit, index);

    setObjFacades({...objFacades, [keyFacade]: objFacades[keyFacade]
      .map((el, i) => i === index ? dataFacade : el)});
    setFacadeNew({ ...facadeNew, modal: false, error: ""});
  };

  const handleToggleModal = () => {        
    setFacadeNew({ ...facadeNew, modal: !facadeNew.modal });
  };

  return (
    <div className={cnCreateForm("Children")}>
      <span>Фасад {facade.groupId && facade.functionalType && (facade.calculateSizes?.width || facade.calculateSizes?.height) ? " родитель" : "" }</span>

      {facade.functionalType}

      <span>Ширина: &#160; {facade.initSizes.width}</span>

      <span>Высота: &#160; {facade.initSizes.height}</span>

      <div className={cnCreateForm('Box-Btn')}>
          <Edit handleToggleModal={handleToggleModal} />
          <Trash handleDeleteValue={handleDelete} />
      </div>
      <ModalFacades
          handleSubmit={handleSubmit}
          facadeNew={facadeNew}
          setFacadeNew={setFacadeNew}
          handleTogleModal={handleToggleModal}
          keyValue={`#editFacade-${keyFacade}-${index}`}
      />
    </div>
  );
}

export default Facade;
