import React from 'react';
import {IClassNameProps} from "@bem-react/core";
import {cnWizardSpec} from '../WizardSpec';
import {Image} from '@yandex/ui/Image';
import {ImageZoom} from '../../../components/ImageZoom/ImageZoom';

export interface IWizardSpecImageProps extends IClassNameProps {
    image: string;
    type: string;
}

export const WizardSpecImage: React.FC<IWizardSpecImageProps> =
    ({
        image,
        type,
        className
     }) => {

        return (
            <div className={cnWizardSpec('ImageContainer', {type: type}, [className])}>
                <Image src={image} className={cnWizardSpec('Image')}/>
                <ImageZoom src={image} className={cnWizardSpec('ImageZoom')}/>
            </div>
        );
    };