import {TUserRole} from '../../common-code/types/TUserRole';
import {IUserData} from '../../common-code/interfaces/IUserData';
import {useSelector} from 'react-redux';
import {AppState} from '../redux/AppStore';
import {ROLE_MANAGER, ROLE_GUEST, ROLE_ADMIN} from '../../common-code/constants';

export const useUserRole = (): TUserRole => {
    const user: IUserData = useSelector((state: AppState) => state.user);

    if (user.roles.includes(ROLE_ADMIN.toUpperCase() as TUserRole)) {
        return ROLE_ADMIN;
    }
    if (user.roles.includes(ROLE_MANAGER.toUpperCase() as TUserRole)) {
        return ROLE_MANAGER;
    }

    return ROLE_GUEST;
}