import {IFacadeMaterialData} from '../../common-code/interfaces/materials/IFacadeMaterialData';
import {useSelector} from 'react-redux';
import {AppState} from '../redux/AppStore';

export const useProjectFacadeMaterials = (): IFacadeMaterialData[] | undefined  => {
    const bottomFacadeMaterial: IFacadeMaterialData | undefined =
        useSelector((state: AppState) => state.wizard.bottomFacadeMaterial);
    const topFacadeMaterial: IFacadeMaterialData | undefined =
        useSelector((state: AppState) => state.wizard.topFacadeMaterial);

    if (!bottomFacadeMaterial && !topFacadeMaterial) {
        return undefined;
    }
    let facadeMaterials: IFacadeMaterialData[] = [];

    if (bottomFacadeMaterial) {
        facadeMaterials.push(bottomFacadeMaterial);
    }
    if (topFacadeMaterial) {
        facadeMaterials.push(topFacadeMaterial);
    }

    return facadeMaterials;
}