import {Reducer} from "redux";
import {SET_USER} from '../../constants';
import {i18n} from '../../i18n';
import {IUserData} from '../../../common-code/interfaces/IUserData';
import {ROLE_GUEST} from '../../../common-code/constants';

const userGuest: IUserData = {
    id: 0,
    name: i18n.t('Гость'),
    roles: [ROLE_GUEST],
    phone: '',
    email: '',
    login: ROLE_GUEST,
    lastName: ''
};

export const UserReducer:Reducer = (state = userGuest, action) => {
    switch (action.type) {
        case SET_USER: {
            if (action.payload && action.payload.id) {
                state = action.payload;
            } else {
                state = userGuest;
            }

            return state;
        }
        default: {
            return state;
        }
    }
};