import React, { useEffect } from 'react'
import { Tumbler } from '../../../../../components/Tumbler/Tumbler'
import { cnCreateForm } from '../../FormModule'
import {
    CATALOG_CALCULATE_TYPE_DETAILS,
    CATALOG_CALCULATE_TYPE_MODULE,
} from 'common-code/lib/constants'
import SelectForm from '../../components/SelectForm'
import { useFormContext } from 'react-hook-form'
import { IFormInput } from '../../initialValue'

const CalculateType = () => {
    const { getValues, setValue } = useFormContext<IFormInput>()
    const [isCalculateType, setIsCalculateType] = React.useState<boolean>(
        !!getValues('calculateType')
    )
    const arrOptions = [
        {
            value: CATALOG_CALCULATE_TYPE_MODULE,
            content: 'Расчет стоимости по модулю',
        },
        {
            value: CATALOG_CALCULATE_TYPE_DETAILS,
            content: 'Расчет стоимости по деталям',
        },
    ]
    useEffect(() => {
        setValue("isCalculateType", isCalculateType)
        // eslint-disable-next-line
    }, [isCalculateType])
    return (
        <>
            <Tumbler
                className={cnCreateForm('Tumbler')}
                checked={isCalculateType}
                callBack={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setIsCalculateType(e.target.checked)
                }
                label="Указать способ расчёта стоимости"
            />
            {isCalculateType && (
                <SelectForm
                    options={arrOptions}
                    label="Способ расчёта стоимости"
                    name="calculateType"
                />
            )}
        </>
    )
}

export default CalculateType
