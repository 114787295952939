import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/AppStore';
import { IFormInput, initialValue } from './initialValue';
import { FormProvider, useForm } from 'react-hook-form';

function HookFormProvider({children} : PropsWithChildren) {    

  const methods = useForm<IFormInput>({
    defaultValues: initialValue(useSelector((state: AppState) => state.wizard.editModule.itemModule)),
    mode: "onChange"
  });

  return (
    <FormProvider {...methods}>{children}</FormProvider>
  )
}

export default HookFormProvider