import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from 'react';
import {IClassNameProps} from '@bem-react/core';
import {cn} from '@bem-react/classname';
import './Number.css';
import {Textinput} from '@yandex/ui/Textinput/desktop/bundle';
import {Svg} from '../Svg';

export const cnNumber = cn('Number');

export interface INumberProps extends IClassNameProps {
    min: number,
    max: number,
    step?: number,
    viewControl?: boolean,
    activeControl?: boolean,
    disabled?: boolean,
    value: number,
    callBack: (value: number, name?: string) => void,
    name?: string,
}

export const Number: React.FC<INumberProps> =
    ({
         className,
         min,
         max,
         step,
         viewControl,
         activeControl,
         disabled,
         value,
         callBack,
         name
     }) => {

        const [newValue, setNewValue] = useState<number>(value);
        const [focused, setFocused] = useState<boolean>(false);
        const [activeDecrement, setActiveDecrement] = useState<boolean>(true);
        const [activeIncrement, setActiveIncrement] = useState<boolean>(true);

        useEffect(() => {
            if (!focused) {
                setNewValue(value);
            }
        }, [focused, value]);

        useEffect(() => {
            if (activeControl === false || value === min) {
                setActiveDecrement(false);
            } else {
                setActiveDecrement(true);
            }
        }, [activeControl, value, min]);

        useEffect(() => {
            if (activeControl === false || value === max) {
                setActiveIncrement(false);
            } else {
                setActiveIncrement(true);
            }
        }, [activeControl, value, max]);

        const eventFocus = () => {
            setFocused(true);
        };

        const eventKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
            const regExp = new RegExp('\\d', 'g');

            if (!regExp.test(event.key)) {
                event.preventDefault();
            }
        };

        const eventInput = (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.length > 1 && event.target.value[0] === '0') {
                event.target.value = event.target.value.substring(1, event.target.value.length);
            }
            if (+event.target.value > max) {
                event.target.value = '' + max;
            }
            setNewValue(+event.target.value);
        };

        const eventBlur = (event: ChangeEvent<HTMLInputElement>) => {
            if (+event.target.value > max) {
                event.target.value = '' + max;
                callBack(max, name);
            }
            if (+event.target.value < min) {
                event.target.value = '' + min;
                callBack(min, name);
            }
            if (+event.target.value <= max && +event.target.value >= min) {
                callBack(+event.target.value, name);
            }
            if (step && step > 0) {
                event.target.value = '' + (Math.trunc((+event.target.value - min) / step) * step + min);
                callBack(+event.target.value, name);
            }
            setFocused(false);
            setNewValue(+event.target.value);
        };

        const decrement = () => {
            let tempValue;

            if (step && step > 0) {
                tempValue = Math.trunc((value - min) / step) * step + min;
                value = value !== tempValue ? tempValue : tempValue - step;
            } else {
                value -= 1;
            }
            if (value <= min) {
                value = min;
            }
            callBack(value, name);
        };

        const increment = () => {
            if (step && step > 0) {
                value = (Math.trunc((value - min) / step) * step + min) + step;
            } else {
                value += 1;
            }
            if (value >= max) {
                value = max;
            }
            callBack(value, name);
        };

        return (
            <div className={cnNumber({
                bordered: true,
                bkg: 'lightGray',
                color: 'lightBlack',
                size: 'lg',
                disabled: disabled
            }, [className])}>
                {viewControl ?
                    activeDecrement ?
                        <span
                            className={cnNumber('Decrement', {active: activeDecrement})}
                            onClick={decrement}
                        ><Svg icon={'minus2'} className={cnNumber('Icon', {active: activeDecrement})} label={'minus2'}/></span>
                        :
                        <span
                            className={cnNumber('Decrement')}
                        ><Svg icon={'minus2'} className={cnNumber('Icon')} label={'minus2'}/></span>
                    : null
                }
                <Textinput
                    value={newValue}
                    type={'text'}
                    debounceTimeout={300}
                    focused={focused}
                    disabled={disabled}
                    onFocus={eventFocus}
                    onKeyPress={eventKeyPress}
                    onInput={eventInput}
                    onBlur={eventBlur}
                    name={name}
                />
                {viewControl ?
                    activeIncrement ?
                        <span
                            className={cnNumber('Increment', {active: activeIncrement})}
                            onClick={increment}
                        ><Svg icon={'plus2'} className={cnNumber('Icon', {active: activeIncrement})} label={'plus2'}/></span>
                        :
                        <span
                            className={cnNumber('Increment')}
                        ><Svg icon={'plus2'} className={cnNumber('Icon')} label={'plus2'}/></span>
                    : null
                }
            </div>
        );
    };