import React, {useState} from 'react';
import {IObjectSelectProps} from '../ObjectSelect';
import {IClassNameProps, withBemMod} from '@bem-react/core';
import {cnObjectSelect} from '../index';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {IFacadeMaterialData} from '../../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {IFacadeData} from '../../../../../common-code/interfaces/materials/IFacadeData';
import {AppState} from '../../../../redux/AppStore';
import {ObjectSelectSelect} from '../Select/ObjectSelect-Select';
import {Modal} from '../../Modal';
import {FacadeMaterials} from '../../../elements/FacadeMaterials/FacadeMaterials';
import {cnFacadeMaterials} from '../../../elements/FacadeMaterials';
import {
    OBJECT_SELECT_TYPE_FACADE_MATERIAL
} from '../../../../constants';

const ObjectSelectFacadeMaterial: React.FC<IObjectSelectProps<IFacadeMaterialData>> =
    ({
         data,
         list,
         onSelectObject,
         service,
         className,
        title,
     }) => {
        const {t} = useTranslation();
        const facades: IFacadeData[] | undefined = useSelector((state: AppState) => state.wizard.facades);
        const [showModal, setShowModal] = useState<boolean>(false);

        if (!facades) {
            return null;
        }

        const emptyObjectData: IFacadeMaterialData = {
            id: 'none',
            title: t('Не выбрано'),
            facade: 'none',
            material: 'none'
        };
        const objectData: IFacadeMaterialData = data && data.object ? data.object : emptyObjectData;


        return (
            <div className={cnObjectSelect({}, [className])}>
                <ObjectSelectSelect
                    className={cnObjectSelect('Select')}
                    image={objectData.image ? {
                        path: objectData.image,
                        label: objectData.title,
                        width: 60,
                        height: 60
                    } : undefined}
                    color={!objectData.image && objectData.color ? {
                        path: objectData.color,
                        label: objectData.title,
                        width: 60,
                        height: 60
                    } : undefined}
                    icon={!objectData.image && !objectData.color ? {
                        path: 'no-photo',
                        label: objectData.title,
                        width: 60,
                        height: 60
                    } : undefined}
                    title={t('Фасад') || undefined}
                    description={objectData.title}
                    callBack={() => {
                        setShowModal(true)
                    }}
                />
                {showModal ?
                    <Modal
                        className={cnObjectSelect('Modal')}
                        visible={showModal}
                        callBack={setShowModal}
                        size={'full'}
                    >
                        {!list || !onSelectObject ?
                            <div className={cnObjectSelect('Error')}>{t('Загрузка данных')}...</div> :
                            <FacadeMaterials
                                className={cnObjectSelect(cnFacadeMaterials())}
                                listMaterialData={list}
                                materialData={objectData}
                                listFacadeData={facades}
                                title={title}
                                onSelectObject={onSelectObject}
                                service={service}
                            />
                        }
                    </Modal> : null
                }
            </div>
        );
    }

export const withObjectSelectFacadeMaterial = withBemMod<IObjectSelectProps<IFacadeMaterialData>, IClassNameProps>(
    cnObjectSelect(),
    {type: OBJECT_SELECT_TYPE_FACADE_MATERIAL},
    () => ObjectSelectFacadeMaterial
);