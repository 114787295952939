import React, { useState } from 'react'
import { INewHandleModelData, initialModel } from '../defaultValues'
import { Button } from '../../../../components/Button'
import HadleModel from './HandleModel'
import { cnFormHandle, INewHandleData } from '../FormHandle'
import ModalHandleModel from './ModalHandleModel'
import { THandleModelType } from 'common-code/lib/types/THandleModelType'
import { TModelExtension } from '../../../../../../common-code/types/TModelExtension'

const HandleModels = ({
    newHandle,
    setNewHandle,
}: {
    newHandle: INewHandleData
    setNewHandle: (newHandle: INewHandleData) => void
}) => {
    const [newHandleModel, setNewHandleModel] = useState<INewHandleModelData>(
        initialModel(null)
    )
    const [modal, setModal] = React.useState<boolean>(false)
    const { models } = newHandle

    const handleToggleModal = () => {
        setModal(!modal)
    }

    const validateModel = (newHandleModel: INewHandleModelData) => {
        const { width, height, depth, type, ext, error } = newHandleModel
        if (!width || width === '0') return 'Укажите ширину'
        if (!height || height === '0') return 'Укажите высоту'
        if (!depth || depth === '0') return 'Укажите глубину'
        if (!type ) return 'Укажите тип'
        return ''
    }

    const createModel = (newHandleModel: INewHandleModelData) => {
        const { width, height, depth, type, ext } = newHandleModel
        return {
            id: `${type}_${width}-${depth}-${height}`,
            width: Number(width),
            height: Number(height),
            depth: Number(depth),
            type: type as THandleModelType,
            ext: ext as TModelExtension,
        }
    }

    const handleSubmit = () => {
        const error = validateModel(newHandleModel)
        if (error) {
            setNewHandleModel({ ...newHandleModel, error })
            return
        }
        const modelNew = createModel(newHandleModel)
        setNewHandle({ ...newHandle, models: [...models, modelNew] })
        setModal(false)
    }
    return (
        <>
            <h4 className={cnFormHandle('title')}>Модели ручек</h4>
            {models.map((model, index) => (
                <HadleModel
                    model={model}
                    key={`${index}${model.id}`}
                    index={index}
                    createModel={createModel}
                    setNewHandle={setNewHandle}
                    newHandle={newHandle}
                    validateModel={validateModel}
                />
            ))}

            <ModalHandleModel
                modal={modal}
                handleToggleModal={handleToggleModal}
                newHandleModel={newHandleModel}
                handleSubmit={handleSubmit}
                setNewHandleModel={setNewHandleModel}
                keyValue={`#addShelf`}
            />

            <Button
                className={cnFormHandle('Button')}
                type={'button'}
                text={`Добавить модель ручки`}
                leftIcon={{
                    path: 'plus2',
                    width: 16,
                    height: 16,
                    marginRight: 8,
                }}
                mods={{
                    mods: {
                        bkg: 'white',
                        color: 'lightGreen',
                        border: 'lightGreen',
                        fill: 'lightGreen',
                    },
                    hover: {
                        mods: {
                            bkg: 'lightGreen',
                            color: 'white',
                            fill: 'white',
                        },
                    },
                }}
                callBack={handleToggleModal}
            />
        </>
    )
}

export default HandleModels
