import React from 'react';
import { editOption } from '../../../utils/editOption';
import { cnModal, Modal } from '../../../../../components/Modal';
import { Select } from '../../../../../components/Select/Select';
import { cnCreateForm } from '../../FormModule';
import { Button } from '../../../../../components/Button';
import { sideTypeDefault } from './SideTypes';

function ModalSideTypes({
        visible,
        handleToggle,
        value,
        handleChange,
        stateError,
        handleSubmit
    } :{
        visible: boolean,
        handleToggle: () => void,
        value: string,
        handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
        stateError: string,
        handleSubmit: () => void
    }) {

  return (
    <Modal
        className={cnCreateForm(cnModal())}
        visible={visible}
        callBack={handleToggle}
        key={'#addingWidth'}
    >
        <h4 className={cnCreateForm('Header')}>Добавление стороны открывания</h4>

        <div className={cnCreateForm('Content')}>
        
            <>
                <label htmlFor="value">Сторона открывания</label>
                <Select
                    className={cnCreateForm('Select')}
                    placeholder={"Выберите значение"}
                    showPlaceholder={false}
                    options={editOption(sideTypeDefault)}
                    value={value}
                    callBack={handleChange}
                />
            </>   
    
            {stateError && <div className='text-danger'>{stateError}</div>}

            <Button
                className={cnCreateForm('Button')}
                type={'button'}
                text={`Добавить`}
                leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                mods={{
                    mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                    hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                }}
                callBack={handleSubmit}
            />
        </div>
    </Modal>
  )
}

export default ModalSideTypes