import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import {TSelectItem} from '../../../../common-code/types/TSelectItem';
import {TRadioButtonType} from '../../../../common-code/types/TRadioButtonType';

export const cnRadioButton = cn('RadioButton');

export interface IRadioButtonProps extends IClassNameProps {
    type?: TRadioButtonType;
    options?: TSelectItem[];
    value?: string;
    callBack?: (value: string, name?: string) => void;
    name?: string;
}