import React, {ChangeEvent, useEffect, useState} from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import {useTranslation} from 'react-i18next';
import axios, {AxiosResponse} from "axios";
import {useDispatch} from "react-redux";
import {clearPhone, processLogin, validatePhone} from '../../../../helpers';
import {IUserData} from '../../../../../common-code/interfaces/IUserData';
import {HIDE_LOADING, SEND_SMS_TIMEOUT_SECONDS, SET_USER, SHOW_LOADING, START_SESSION} from '../../../../constants';
import {ISendResponse} from '../../../../interfaces/ISendResponse';
import {Input} from '../../../components/Input/Input';
import {
    INPUT_TYPE_PASSWORD,
    INPUT_TYPE_PHONE,
    INPUT_TYPE_TEXT,
    LINK_TARGET_BLANK,
    LINK_TYPE_DEFAULT
} from '../../../constants';
import {cnInput} from '../../../components/Input';
import {Checkbox, cnCheckbox} from '../../../components/Checkbox';
import {Link} from '../../../components/Link/Link';
import {cnLink} from '../../../components/Link';
import {Button, cnButton} from '../../../components/Button';
import {IClientSessionData} from '../../../../../common-code/interfaces/session/IClientSessionData';

export const cnLoginForm = cn('LoginForm');

export interface ILoginFormUserProps extends IClassNameProps {
}

export const LoginFormUser: React.FC<ILoginFormUserProps> = () => {
    const [userPhone, setUserPhone] = useState('');
    const [userData, setUserData] = useState({});
    const [password, setPassword] = useState('');
    const [userName, setUserName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [checkPersonal, setCheckPersonal] = useState(true);
    const [checkOffer, setCheckOffer] = useState(true);
    const [formErrors, setFormErrors] = useState<any>({});
    const [sendingSMS, setSendingSMS] = useState(false);
    const [timerSeconds, setTimerSeconds] = useState<number>(0);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onLogin = () => {
        if (!validateLoginForm()) {
            return;
        }
        let newFormErrors: any = {};
        dispatch({type: SHOW_LOADING});
        processLogin(userPhone, password, dispatch, t, newFormErrors).then(() => {
            setFormErrors(newFormErrors);
            dispatch({type: HIDE_LOADING});
        }).catch(() => {
            newFormErrors.serverError = t('Произошла неизвестная ошибка');
            setFormErrors(newFormErrors);
            dispatch({type: HIDE_LOADING});
        });
    };

    const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (formErrors && formErrors[event.target.name]) {
            delete formErrors[event.target.name];
            setFormErrors(formErrors);
        }
        switch (event.target.name) {
            case "checkPersonal":
                setCheckPersonal(event.target.checked);
                break;
            case "checkOffer":
                setCheckOffer(event.target.checked);
                break;
        }
    };

    const processRegister = () => {
        if (!validateRegisterForm()) {
            return;
        }
        axios.post('/auth/register', {
            phone: userPhone,
            firstName: userName,
            secondName: userLastName,
            email: userEmail,
            check_offer: checkOffer,
            check_personal: checkPersonal,
        })
            .then((response) => {
                const userData: IUserData | undefined = response.data.user;
                const sessionId: string | undefined = response.data.sid;
                const session: IClientSessionData | undefined = (sessionId !== undefined && userData !== undefined) ? {
                    user: userData,
                    seanceId: sessionId
                } : undefined;
                if (!userData) {
                    setFormErrors({register: 'Не удалось зарегистрировать пользователя на сервере.'})
                }
                dispatch({
                    type: SET_USER,
                    payload: userData,
                });
                dispatch({
                    type: START_SESSION,
                    payload: session,
                });
            });
    };

    const validateRegisterForm = (): boolean => {
        let newFormErrors: any = {};

        if (userName.length <= 0) {
            newFormErrors['userName'] = t('Имя обязательно для заполнения');
        }
        if (userPhone.length <= 0) {
            newFormErrors['userPhone'] = t('Телефон обязателен для заполнения');
        }
        if (userEmail.length > 0 &&
            !(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(userEmail))) {
            newFormErrors['userEmail'] = t('Введен неверный email');
        }
        if (!checkPersonal) {
            newFormErrors['checkPersonal'] = t('Необходимо согласиться у условиями обработки персональных данных');
        }
        if (!checkOffer) {
            newFormErrors['checkOffer'] = t('Необходимо согласие с офертой при регистрации');
        }
        setFormErrors(newFormErrors);

        return Object.keys(newFormErrors).length <= 0;
    };

    const validateLoginForm = (): boolean => {
        let newFormErrors: any = {};

        if (userPhone.length <= 0 || !validatePhone(userPhone)) {
            newFormErrors['userPhone'] = t('Необходимо ввести логин');
        }
        if (password.length <= 0) {
            newFormErrors['password'] = t('Необходимо ввести пароль');
        }
        setFormErrors(newFormErrors);

        return Object.keys(newFormErrors).length <= 0;
    };

    const onChangeUserPhoneInput = (event: ChangeEvent<HTMLInputElement>) => {
        let newPhone = event.target.value;
        setUserPhone(newPhone);
        if (formErrors && formErrors['userPhone']) {
            delete formErrors['userPhone'];
            setFormErrors(formErrors);
        }
        if (validatePhone(newPhone)) {
            axios.get('/auth/user?phone=' + clearPhone(newPhone))
                .then((response: AxiosResponse) => {
                    if (response.data) {
                        setUserData(response.data);
                    } else {
                        setUserData({isNew: true});
                    }
                })
                .catch(reason => {
                    console.error(reason);
                });
        }
    };

    const onChangePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (formErrors && formErrors[event.target.name]) {
            delete formErrors[event.target.name];
            setFormErrors(formErrors);
        }
        switch (event.target.name) {
            case "userName":
                setUserName(event.target.value);
                break;
            case "userLastName":
                setUserLastName(event.target.value);
                break;
            case "userEmail":
                setUserEmail(event.target.value);
                break;
        }
    };
    useEffect(() => {
        if (timerSeconds > 0) {
            setTimeout(() => setTimerSeconds(timerSeconds - 1), 1000);
        } else {
            setSendingSMS(false);
        }
    }, [timerSeconds]);

    const sendSMSTimerPassword = () => {
        if (sendingSMS) {
            return;
        }
        setSendingSMS(true);
        axios.post<any>('/auth/send-sms', {
            phone: userPhone
        })
            .then((response) => {
                const responseData: ISendResponse = response.data;
                if (responseData.success) {
                    setTimerSeconds(SEND_SMS_TIMEOUT_SECONDS);
                } else {
                    setFormErrors({sendSMSTimer: t('Не удалось отправить СМС, попробуйте снова')});
                }
            })
            .catch(() => {
                setSendingSMS(false);
            });
    };


    return (
        <>
            <div className={cnLoginForm('Group')}>
                <p className={cnLoginForm('Title')}>{t('Телефон')}:</p>
                <p className={cnLoginForm('Notes')}>{t('Введите свой номер мобильного телефона')}</p>
                <Input
                    className={cnLoginForm(cnInput({error: !!(formErrors && formErrors['userPhone'])}))}
                    type={INPUT_TYPE_PHONE}
                    value={userPhone}
                    onChange={onChangeUserPhoneInput}
                />
            </div>
            {userData.hasOwnProperty('id') &&
                <div className={cnLoginForm('Group')}>
                    <p className={cnLoginForm('Title')}>{t('Пароль')}:</p>
                    <Input
                        className={cnLoginForm(cnInput({error: !!(formErrors && formErrors['password'])}))}
                        type={INPUT_TYPE_PASSWORD}
                        value={password}
                        onChange={onChangePasswordInput}
                    />
                </div>
            }
            {userData.hasOwnProperty('isNew') &&
                <>
                    <div className={cnLoginForm('Group')}>
                        <p className={cnLoginForm('Title')}>{t('Имя')}:</p>
                        <Input
                            className={cnLoginForm(cnInput({error: !!(formErrors && formErrors['userName'])}))}
                            type={INPUT_TYPE_TEXT}
                            value={userName}
                            name={'userName'}
                            onChange={onChangeInput}
                        />
                    </div>
                    <div className={cnLoginForm('Group')}>
                        <p className={cnLoginForm('Title')}>{t('Фамилия')}:</p>
                        <Input
                            className={cnLoginForm(cnInput({error: !!(formErrors && formErrors['userLastName'])}))}
                            type={INPUT_TYPE_TEXT}
                            value={userLastName}
                            name={'userLastName'}
                            onChange={onChangeInput}
                        />
                    </div>
                    <div className={cnLoginForm('Group')}>
                        <p className={cnLoginForm('Title')}>{t('Email')}:</p>
                        <p className={cnLoginForm('Notes')}>{t('Укажите адрес электронной почты, если хотите получать рассылки')}:</p>
                        <Input
                            className={cnLoginForm(cnInput({error: !!(formErrors && formErrors['userEmail'])}))}
                            type={INPUT_TYPE_TEXT}
                            value={userEmail}
                            name={'userEmail'}
                            onChange={onChangeInput}
                        />
                    </div>
                    <div className={cnLoginForm('Group')}>
                        <Checkbox
                            className={cnLoginForm(cnCheckbox({
                                size: 'sm',
                                error: !!(formErrors && formErrors['checkPersonal'])
                            }))}
                            checked={checkPersonal}
                            callBack={onChangeCheckbox}
                            name={'checkPersonal'}
                            label={
                                <>
                                    {`${t('Я СОГЛАСЕН на обработку моих персональных данных в соответствие с ФЗ от 27.07.2006 г. № 152-ФЗ «О персональных данных».')} `}
                                    <Link
                                        className={cnLoginForm(cnLink())}
                                        type={LINK_TYPE_DEFAULT}
                                        href={'https://narod-kuhni.ru/for-buyers/'}
                                        target={LINK_TARGET_BLANK}
                                        text={t('подробнее') || undefined}
                                        mods={{mods: {color: 'red'}}}
                                    />
                                </>
                            }
                        />
                    </div>
                    <div className={cnLoginForm('Group')}>
                        <Checkbox
                            className={cnLoginForm(cnCheckbox({
                                size: 'sm',
                                error: !!(formErrors && formErrors['checkOffer'])
                            }))}
                            checked={checkOffer}
                            callBack={onChangeCheckbox}
                            name={'checkOffer'}
                            label={
                                <>
                                    {`${t('Ознакомлен с условиями договора публичной оферты, возврата товара, инструкцией по эксплуатации мебели')} `}
                                    <Link
                                        className={cnLoginForm(cnLink())}
                                        type={LINK_TYPE_DEFAULT}
                                        href={'https://narod-kuhni.ru/for-buyers/'}
                                        target={LINK_TARGET_BLANK}
                                        text={t('подробнее') || undefined}
                                        mods={{mods: {color: 'red'}}}
                                    />
                                </>
                            }
                        />
                    </div>
                </>
            }
            {(Object.keys(formErrors).length > 0) &&
                <div className={cnLoginForm('Errors')}>
                    {Object.keys(formErrors).map(key =>
                        <p
                            key={key}
                            className={cnLoginForm('Error')}
                        >
                            {formErrors[key]}
                        </p>
                    )}
                </div>
            }
            {Object.keys(userData).length === 0 ||
                <Button
                    className={cnLoginForm(cnButton())}
                    text={(userData.hasOwnProperty('isNew') ? t('Зарегистрироваться') : t('Войти')) || undefined}
                    callBack={() => {
                        userData.hasOwnProperty('isNew') ? processRegister() : onLogin()
                    }}
                    mods={{mods: {color: 'white', bkg: 'lightGreen', border: 'green'}}}
                    type={'button'}
                />
            }
            {userData.hasOwnProperty('id') &&
                <>
                    <p className={cnLoginForm('Notes')}>{t('Забыли пароль')}?</p>
                    {sendingSMS ||
                        <Link
                            className={cnLoginForm('SendSMS')}
                            type={LINK_TYPE_DEFAULT}
                            text={t('Выслать новый пароль') || undefined}
                            mods={{mods: {color: 'black'}}}
                            onClick={sendSMSTimerPassword}
                        />
                    }
                    {timerSeconds <= 0 ||
                        <p className={cnLoginForm('Notes')}>{t('Новый пароль выслан по СМС. Новый пароль вы можете сгенерировать через')} {timerSeconds} {t('сек.')}</p>
                    }
                </>
            }
        </>
    );
};