import {ILinkProps} from "../Link";
import {LINK_TYPE_DEFAULT} from "../../../constants";
import {IIcon} from "../../../../interfaces/IIcon";
import {IMods} from "../../../../interfaces/IMods";
import React, {ReactNode, useState} from "react";
import {useModsGenerator} from "../../../../hooks/useModsGenerator";
import {Link as YandexLink} from '@yandex/ui/Link';
import {Svg} from "../../Svg";
import {withBemMod} from "@bem-react/core";
import {TLinkTarget} from "../../../../types/TLinkTarget";
import {cnLink} from "../index";

export interface ILinkTypeDefaultProps extends ILinkProps {
    type?: typeof LINK_TYPE_DEFAULT;
    href?: string;
    target?: TLinkTarget;
    icon?: IIcon;
    text?: string;
    mods?: IMods;
    children?: ReactNode;
    onClick?(): void;
}

const LinkTypeDefault: React.FC<ILinkTypeDefaultProps> =
    ({
        className,
        href,
        target,
        icon,
        text,
        mods,
        children,
        onClick
    }) => {
    const [hover, setHover] = useState<boolean>(false);
    const modsGenerator = useModsGenerator();

    if (mods?.hover) {
        mods.hover.state = hover;
    }

    return (
        <YandexLink
            className={cnLink({...modsGenerator(mods || {})}, [className])}
            href={href}
            target={target}
            onClick={onClick}
        >
            <div
                className={cnLink('Wrapper')}
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
            >
                <>
                    {icon &&
                        <Svg
                            className={cnLink('Icon')}
                            icon={icon.path}
                            label={text || icon.path}
                            style={{width: `${icon.width}px`, height: `${icon.height}px`, marginRight: `${icon.marginRight}px`}}
                        />
                    }
                    {text && <p className={cnLink('Text')}>{text}</p>}
                    {children && <div className={cnLink('Children', {stretch: !(icon && text)})}>{children}</div>}
                </>
            </div>
        </YandexLink>
    );
};

export const withLinkTypeDefault = withBemMod<ILinkTypeDefaultProps, ILinkProps>(
    cnLink(),
    {type: LINK_TYPE_DEFAULT},
    () => LinkTypeDefault
);