import React, {ReactNode, useEffect} from 'react';
import {IClassNameProps} from '@bem-react/core';
import './Modal.css';
import {Modal as YandexModal} from '@yandex/ui/Modal/desktop/bundle';
import {Button} from '../Button';
import {cn} from "@bem-react/classname";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE_MODAL} from "../../../constants";
import {AppState} from '../../../redux/AppStore';
import {LoadingApiData} from '../../helpers/LoadingApiData/LoadingApiData';

export const cnModal = cn('ModalUI');
export interface IModalProps extends IClassNameProps {
    visible: boolean;
    callBack: (value: boolean) => void;
    children: ReactNode;
    size?: 'full';
    closeButton?: boolean;
}

export const Modal: React.FC<IModalProps> =
    ({
         className,
         children,
         visible,
         callBack,
         size,
         closeButton
    }) => {
    const dispatch = useDispatch();
        const apiLoading: boolean = useSelector((state: AppState) => state.ui.apiLoading);

    useEffect(() => {
        dispatch({
            type: TOGGLE_MODAL,
            payload: {
                value: visible
            }
        });

        return () => {
            dispatch({
                type: TOGGLE_MODAL,
                payload: {
                    value: false
                }
            });
        }
    }, [dispatch, visible]);

    return (
        <>
            <YandexModal
                className={cnModal({}, [className])}
                theme={'normal'}
                hasAnimation={false}
                keepMounted={false}
                contentVerticalAlign={'middle'}
                onClose={() => {callBack(!visible)}}
                visible={visible}
            >
                {closeButton === false || <Button
                    className={cnModal('CloseButton')}
                    type={'button'}
                    leftIcon={{path: 'cross', width: 20, height: 20}}
                    mods={{
                        mods: {bkg: 'white', fill: 'lightBlack'}
                    }}
                    callBack={() => {callBack(!visible)}}
                />}
                <div className={cnModal('Wrapper', {size: size})}>
                    {children}
                </div>
                {apiLoading ? <LoadingApiData loading={apiLoading}/> : null}
            </YandexModal>
        </>

    );
};