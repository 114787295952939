import { TCatalogCalculateType } from 'common-code/lib/types/TCatalogCalculateType'
import { ITechnologMap } from '../../../../../common-code/interfaces/ITechnologMap'
import { CATALOG_CALCULATE_TYPE_MODULE } from 'common-code/lib/constants'

export interface ITechnologMapEdit {
    bottomUnitsDefaultDepth: number
    bottomUnitsDefaultHeight: number
    bottomUnitsLegsHeight: number
    topUnitsDefaultDepth: number
    topUnitsDefaultHeight: number
    tabletopsFrontGap: number
    tabletopsDefaultWidth: number
    plinths: boolean
    plinthsCheckRebuild: boolean
    hob: boolean
    hobMinWidth: number
    legs: boolean
    legsHeights: number[]
    legsHeights0: number
    legsHeights1: number
    legsHeights2: number
    legsHeights3: number
    legsDefaultHeight: number
    apronsInstallHeight: number
    isCalculate: boolean
    calculateType: TCatalogCalculateType
    modal: boolean
    error: string
}

export const initialTechnologMap = (map: ITechnologMap): ITechnologMapEdit => ({
    // bottomUnits
    bottomUnitsDefaultDepth: map.bottomUnits.defaultDepth,
    bottomUnitsDefaultHeight: map.bottomUnits.defaultHeight,
    bottomUnitsLegsHeight: map.bottomUnits.legsHeight,
    // topUnits
    topUnitsDefaultDepth: map.topUnits.defaultDepth,
    topUnitsDefaultHeight: map.topUnits.defaultHeight,
    // tabletops
    tabletopsFrontGap: map.tabletops.frontGap,
    tabletopsDefaultWidth: map.tabletops.defaultWidth,
    // aprons
    apronsInstallHeight: map.aprons.installHeight,
    // plinths
    plinths: map.plinths.hasOwnProperty('checkRebuild'),
    plinthsCheckRebuild: Boolean(map.plinths?.checkRebuild),
    // hob
    hob: Boolean(map.hob?.minWidth),
    hobMinWidth: map.hob?.minWidth || 0,
    // legs
    legs: Boolean(map.legs),
    legsHeights: map.legs?.heights || [0],
    legsHeights0: map.legs?.heights[0] || 0,
    legsHeights1: map.legs?.heights[1] || 0,
    legsHeights2: map.legs?.heights[2] || 0,
    legsHeights3: map.legs?.heights[3] || 0,
    legsDefaultHeight: map.legs?.defaultHeight || 0,
    // calculate
    isCalculate: Boolean(map.calculateType),
    calculateType: map.calculateType || CATALOG_CALCULATE_TYPE_MODULE,
    // modal
    modal: false,
    // error
    error: '',
})

export const validateTechnologMap = (technologMapEdit: ITechnologMapEdit) => {
    const {
        bottomUnitsDefaultDepth,
        bottomUnitsDefaultHeight,
        bottomUnitsLegsHeight,
        topUnitsDefaultDepth,
        topUnitsDefaultHeight,
        tabletopsFrontGap,
        tabletopsDefaultWidth,
        apronsInstallHeight,
        legs,
        legsHeights0,
        legsDefaultHeight,
    } = technologMapEdit

    if (
        !bottomUnitsDefaultDepth ||
        !bottomUnitsDefaultHeight ||
        !bottomUnitsLegsHeight
    ) {
        return 'Необходимо заполнить обязательные поля параметры нижних модулей'
    }
    if (!tabletopsFrontGap || !tabletopsDefaultWidth) {
        return 'Необходимо заполнить обязательные поля параметры столешниц'
    }
    if (!topUnitsDefaultDepth || !topUnitsDefaultHeight) {
        return 'Необходимо заполнить обязательные поля параметры верхних модулей'
    }
    if (!apronsInstallHeight) {
        return 'Необходимо заполнить обязательные поля параметры стеновых панелей'
    }
    if (legs) {
        if (!legsHeights0) {
            return 'Необходимо указать высоту ножки'
        }
        if (!legsDefaultHeight) {
            return 'Необходимо указать высоту по умолчанию для ножек'
        }
    }
    return ''
}

export const editTechnologMap = (
    technologMapEdit: ITechnologMapEdit
): ITechnologMap => {
    const {
        bottomUnitsDefaultDepth,
        bottomUnitsDefaultHeight,
        bottomUnitsLegsHeight,
        topUnitsDefaultDepth,
        topUnitsDefaultHeight,
        tabletopsFrontGap,
        tabletopsDefaultWidth,
        apronsInstallHeight,
        plinths,
        plinthsCheckRebuild,
        legs,
        legsHeights0,
        legsHeights1,
        legsHeights2,
        legsHeights3,
        legsDefaultHeight,
        hob,
        hobMinWidth,
        calculateType,
        isCalculate,
    } = technologMapEdit
    const technologMapNew: ITechnologMap = {
        bottomUnits: {
            defaultDepth: Number(bottomUnitsDefaultDepth),
            defaultHeight: Number(bottomUnitsDefaultHeight),
            legsHeight: Number(bottomUnitsLegsHeight),
        },
        tabletops: {
            frontGap: Number(tabletopsFrontGap),
            defaultWidth: Number(tabletopsDefaultWidth),
        },
        topUnits: {
            defaultDepth: Number(topUnitsDefaultDepth),
            defaultHeight: Number(topUnitsDefaultHeight),
        },
        aprons: {
            installHeight: Number(apronsInstallHeight),
        },
        plinths: {},
    }
    if (plinths) {
        technologMapNew.plinths.checkRebuild = plinthsCheckRebuild
    }
    if (hob) {
        technologMapNew.hob = {
            minWidth: Number(hobMinWidth),
        }
    }
    if (legs) {
        technologMapNew.legs = {
            heights: [Number(legsHeights0)],
            defaultHeight: Number(legsDefaultHeight),
        }
        if (legsHeights1) {
            technologMapNew.legs.heights.push(Number(legsHeights1))
        }
        if (legsHeights2) {
            technologMapNew.legs.heights.push(Number(legsHeights2))
        }
        if (legsHeights3) {
            technologMapNew.legs.heights.push(Number(legsHeights3))
        }
    }
    if (isCalculate) {
        technologMapNew.calculateType = calculateType
    }
    return technologMapNew
}
