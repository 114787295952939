import {IClassNameProps} from "@bem-react/core";
import {cn} from "@bem-react/classname";
import React, {useMemo} from "react";
import './PageProjects.css';
import {useTranslation} from "react-i18next";
import {cnHeader, Header} from "../../ui/layouts/Header";
import {Projects} from '../../ui/elements/Projects/Projects';
import {cnPage} from '../../main/App/Content/App-Content';
import {Breadcrumbs, cnBreadcrumbs} from '../../ui/components/Breadcrumbs ';
import {IBreadcrumbs} from '../../interfaces/IBreadcrumbs';

export const cnPageProjects = cn('PageProjects');

export interface IPageProjectsProps extends IClassNameProps {

}

export const PageProjects: React.FC<IPageProjectsProps> = ({className}) => {
    const {t} = useTranslation();

    const breadcrumbs = useMemo(() => {
        return [
            {text: t("Мои проекты"), href: '/projects'}
        ] as IBreadcrumbs[];
    }, [t]);
    return (
        <div className={cnPageProjects({}, [className])}>
            <Header className={cnPageProjects(cnHeader())} title={t("Мои проекты") || undefined} />
            <div className={cnPage('Wrapper')}>
                <div className={cnPage('Sections')}>
                    <Breadcrumbs className={cnPageProjects(cnBreadcrumbs())} breadcrumbs={breadcrumbs}/>
                    <h1 className={cnPage('Title')}>{t("Мои проекты")}</h1>
                    <Projects />
                </div>
            </div>
        </div>
    );
}