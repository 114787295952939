import { useEffect, useState } from "react";
import { ISaveKUnitData } from "../../../../../common-code/interfaces/saveData/ISaveKUnitData";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { cnSettingsMenu } from "../SettingsMenu";

interface IEquipmentPositionSliderProps {
  data: ISaveKUnitData;
  service: KitchenService;
  unitId: number;
}

const EquipmentPositionSlider: React.FC<IEquipmentPositionSliderProps> = ({
  data,
  service,
  unitId,
}) => {
  const [positionValue, setPositionValue] = useState(0);
  const [name, setName] = useState("");
  const handleChangePosition = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setPositionValue(Number(e.target.value));
    setName(name);
  };

  useEffect(() => {
    service.onSetPosition(positionValue, unitId, name);
  }, [positionValue]);

  return (
    <div>
      {data.equipments &&
        data.equipments.length > 0 &&
        data.equipments.map((equipment, index) => (
          <div className={cnSettingsMenu("TumblerItem")} key={index}>
            <input
              type="range"
              min={-data.sizes.width / 2}
              max={data.sizes.width / 2}
              onChange={(e) => handleChangePosition(e, equipment.name)}
            />
            <p className={cnSettingsMenu("TumblerTitle")}>{`${equipment.name}`}</p>
          </div>
        ))}
    </div>
  );
};

export default EquipmentPositionSlider;
