import { TLevel } from 'common-code/lib/types/TLevel';
import React, { useMemo } from 'react';
import SelectForm from '../../components/SelectForm';
import { editOption } from '../../../utils/editOption';
import { Button, cnButton } from '../../../../../components/Button';
import InputForm from '../../components/InputForm';
import { useFormContext } from 'react-hook-form';
import AddOptionInSelect from '../../components/AddOptionInSelect';
import { fetchOptions, saveOptions } from '../../../api/OptionsServices';
import { useDispatch } from 'react-redux';
import { CHANGE_CATALOG_CODES } from '../../../../../../constants';
import { useEffectDidMount } from '../../../../../../hooks/useEffectDidMount';
import { cn } from '@bem-react/classname';
import { AppState } from '../../../../../../redux/AppStore';
import { useSelector } from 'react-redux';
import "./CatalogCodes.css";

export const cnCatalogCodes = cn('CatalogCodes');

function CatalogCodes({level}:{level: TLevel}) {
  const dispatch = useDispatch();
  const {getValues} = useFormContext();
  
  const [catalogCodes, setCatalogCodes] = React.useState<Record<string, string>>(useSelector((state: AppState) => state.wizard.editModule?.catalogCodes) || {});
  const isCatalogCode = useMemo(() => catalogCodes.hasOwnProperty(getValues("catalogCode")), [catalogCodes]); 
  const [show,  setShow] = React.useState<boolean>( getValues("isEdit") && getValues("catalogCode") && !isCatalogCode ? false : true  );

  React.useEffect(() => {           
    setShow( getValues("isEdit") && getValues("catalogCode") && !isCatalogCode ? false : true ); 
    // eslint-disable-next-line
}, [isCatalogCode]);

  // добавление кодов каталога
  const addCatalogCodes = (catalogCodeNew: Record<string, string>) => {
    setCatalogCodes(catalogCodeNew); 
    saveOptions(catalogCodeNew, "catalog-codes").then((res:  Record<string, string> | undefined) => {
        if(res) dispatch({ type: CHANGE_CATALOG_CODES, payload: res });
    });
  }

  // если коды каталога еще не получены загружаем их с сервера
  useEffectDidMount(() => {
    if (Object.keys(catalogCodes).length === 0) {
        fetchOptions("catalog-codes").then(
        (res: Record<string, string> | undefined) => {
          if (res) {
            setCatalogCodes(res);
            dispatch({ type: CHANGE_CATALOG_CODES, payload: res });
          }
        }
      );
    }
  });

  React.useEffect(() => {           
      setCatalogCodes( catalogCodes); 
      // eslint-disable-next-line
  }, [level]);

  const toggleShowField = () => {
    setShow(prevShow => !prevShow);
  }

  return (
    <>
        { show ?
          <SelectForm  options={editOption(catalogCodes)} label="Код по каталогу (значение должно быть уникальным) *" name="catalogCode" />
          :
          <InputForm  label="Код по каталогу" name="catalogCode" required={true} onFocus={undefined}/>
        }

        <Button
            className={cnCatalogCodes(cnButton())}
            type={'button'}
            text={`${show ? "Добавить свой код по каталогу" : "Выбрать код по каталогу из списка"}`}
            leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
            mods={{
                mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
            }}
            callBack={toggleShowField}
        />

        <AddOptionInSelect 
              options={catalogCodes} 
              setOptions={addCatalogCodes} 
              isReverse={true}
          />
     
    </>
  )
}

export default CatalogCodes