import {ThreeBottomEquipment} from '../ThreeBottomEquipment';
import {Vector3} from 'three';
import {KitchenService} from '../../../../../services/KitchenService/KitchenService';
import {SIDE_TYPE_BACK, SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import {ISaveCookerEquipmentData} from '../../../../../../../common-code/interfaces/saveData/ISaveCookerEquipmentData';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';

export class ThreeCookerEquipment extends ThreeBottomEquipment {
    saveData: ISaveCookerEquipmentData;

    constructor(options: ISaveCookerEquipmentData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
    }

    public initState(isRebuild?: boolean) {
        this.addCoverPoints([new Vector3(0, this.getHeight()/2 + this.getTabletopHeight(), 0)]);
        super.initState(isRebuild);
    }

    public getTabletopHeight(): number {
        return this.service.getTabletopHeight();
    }

    protected initApronsData(aprons?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        if (this.saveData.hasAprons) {
            return [
                aprons && aprons[0] ? aprons[0] : {
                    id: 0,
                    positionType: SIDE_TYPE_BACK,
                },
                aprons && aprons[1] ? aprons[1] : {
                    id: 0,
                    positionType: SIDE_TYPE_LEFT,
                },
                aprons && aprons[2] ? aprons[2] : {
                    id: 0,
                    positionType: SIDE_TYPE_RIGHT,
                }
            ];
        } else {
            return undefined;
        }
    }
}