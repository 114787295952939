import React, {useEffect, useState} from 'react';
import './WizardBuy.css'
import {useTranslation} from 'react-i18next';
import {cnWizardBuy, IWizardBuyProps} from './index';
import {composeU} from '@bem-react/core';
import {withWizardBuyUserManager} from './_user/WizardBuy_user_manager';
import {TKitchenImages} from '../../../../common-code/types/TKitchenImages';
import {useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {WizardSpecImage} from '../WizardSpec/Image/WizardSpec-Image';
import {WizardProjectPriceTable} from '../Wizard/ProjectPrice/Table/WizardProjectPriceTable';
import {WizardOrderForm} from '../WizardOrderForm/WizardOrderForm';

export const WizardBuyBase: React.FC<IWizardBuyProps> =
    ({
         service,
         className,
         user
     }) => {
        const {t} = useTranslation();
        const loadedTextures: number = useSelector((state: AppState) => state.wizard.loadedTextures);
        const [images, setImages] = useState<TKitchenImages | undefined>(undefined);


        useEffect(() => {
            if (loadedTextures > 0 && service) {
                service.createImages().then((images) => {
                    setImages(images);
                })
            }
        }, [service, loadedTextures]);

        return (
            <div className={cnWizardBuy({}, [className])}>
                <div className={cnWizardBuy('Title')}>{t('Предзаказ кухни')}</div>
                <div className={cnWizardBuy('Header')}>
                    <div className={cnWizardBuy('Images')}>
                        {!images ||
                            <WizardSpecImage image={images.visual} type={'visual'}
                                         className={cnWizardBuy('Image', {type: 'visual'})}/>}
                    </div>
                    <div className={cnWizardBuy('Info')}>
                        {!service || <WizardProjectPriceTable service={service}/> }
                    </div>
                </div>
                <div className={cnWizardBuy('Form')}>
                    {!service || <WizardOrderForm service={service} user={user}/> }
                </div>
            </div>
        );
    };

export const WizardBuy = composeU(
    withWizardBuyUserManager,
)(WizardBuyBase);