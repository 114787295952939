import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ITabsMenuTabProps} from '@yandex/ui/TabsMenu/Tab/TabsMenu-Tab';
import {ITabsPanesPaneProps} from '@yandex/ui/TabsPanes/Pane/TabsPanes-Pane';
import {Tabs} from '../../components/Tabs/Tabs';
import {DeliveryObjectSelectSelf} from './Self/DeliveryObjectSelect-Self';
import {DeliveryObjectSelectHome} from './Home/DeliveryObjectSelect-Home';
import {IProjectDeliveryData} from '../../../../common-code/interfaces/project/IProjectDeliveryData';
import {TDeliveryType} from '../../../../common-code/types/TDeliveryType';

export const cnDeliveryObjectSelect = cn('DeliveryObjectSelect')

export interface IDeliveryObjectSelectProps extends IClassNameProps {
    deliveryData: IProjectDeliveryData;
    setDeliveryData: (data: IProjectDeliveryData) => void;
    errors?: any;
}

export const DeliveryObjectSelect: React.FC<IDeliveryObjectSelectProps> =
    ({
         className,
         deliveryData,
         setDeliveryData,
         errors
     }) => {
        const {t} = useTranslation();

        const tabs: ITabsMenuTabProps[] = [
            {id: 'self', content: t('Без доставки')},
            {id: 'home', content: t('На адрес')},
        ];
        const tabsContent: ITabsPanesPaneProps[] = [
            {id: 'self', content: <DeliveryObjectSelectSelf/>},
            {
                id: 'home',
                content: <DeliveryObjectSelectHome deliveryData={deliveryData}
                                                   setDeliveryData={setDeliveryData}
                                                   errors={errors}
                />
            },
        ];

        return (
            <div className={cnDeliveryObjectSelect({}, [className])}>
                <Tabs tabs={tabs}
                      tabsContent={tabsContent}
                      initActiveTab={'self'}
                      callbackSetActiveTab={(value: string) => {
                          if (deliveryData.type !== value) {
                              let newDeliveryData: IProjectDeliveryData;

                              newDeliveryData = {...deliveryData};
                              newDeliveryData.type = value as TDeliveryType;
                              setDeliveryData(newDeliveryData);
                          }
                      }}
                />
            </div>
        );
    }