import { IClassNameProps } from "@bem-react/core";
import React from "react";
import { cnWizard } from "../Wizard";
import { useSelector } from "react-redux";
import "./Wizard-SettingsMenu.css";
import { ISettingsAction } from "../../../../interfaces/settingData/ISettingsAction";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { ISettingsMenu } from "../../../../interfaces/settingData/ISettingsMenu";
import { AppState } from "../../../../redux/AppStore";
import { cnSettingsMenu, SettingsMenu } from "../../SettingsMenu/SettingsMenu";

export interface IWizardSettingsMenuProps extends IClassNameProps {
  action: ISettingsAction;
  service: KitchenService;
}

export const WizardSettingsMenu: React.FC<IWizardSettingsMenuProps> = ({
  className,
  action,
  service,
}) => {
  const settingsMenu: ISettingsMenu = useSelector(
    (state: AppState) => state.wizard.settingsMenu
  );

  return (
    <div
      className={cnWizard(
        cnSettingsMenu(),
        { animated: true, visible: settingsMenu.visible },
        [className]
      )}
    >
      {settingsMenu.unitId && settingsMenu.data && settingsMenu.groups ? (
        <SettingsMenu
          action={action}
          unitId={settingsMenu.unitId}
          data={settingsMenu.data}
          createObjectData={settingsMenu.createObjectData}
          price={settingsMenu.priceData?.price || 0}
          groups={settingsMenu.groups}
          service={service}
        />
      ) : null}
    </div>
  );
};
