import React from "react";
import {IClassNameProps} from "@bem-react/core";
import './Wizard-UnitSpec.css';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {IUnitSpecData} from '../../../../interfaces/IUnitSpecData';
import {cn} from '@bem-react/classname';
import {WizardSpecGroup} from '../../WizardSpec/Group/WizardSpec-Group';
import {useTranslation} from 'react-i18next';

export const cnWizardUnitSpec = cn('WizardUnitSpec');
export interface IWizardUnitSpecProps extends IClassNameProps {
    specData: IUnitSpecData;
    service: KitchenService;
    onClose: (value: boolean) => void;
}

export const WizardUnitSpec: React.FC<IWizardUnitSpecProps> =
    ({
         className,
         specData,
    }) => {
    const {t} = useTranslation();
    return (
        <div className={cnWizardUnitSpec({}, [className])}>
            {specData.data?.length ?
                <WizardSpecGroup
                    title={t('Спецификация модуля')}
                    specItems={specData.data}
                    className={cnWizardUnitSpec('List')}
                /> :
                <div className={cnWizardUnitSpec('Note', {empty: true})}>
                    {t('У модуля нет товаров в спецификации')}
                </div>
            }
        </div>
    );
};