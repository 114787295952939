import React from 'react';
import { Modal, cnModal } from '../../../../../../components/Modal';
import { Button } from '../../../../../../components/Button';
import { cnCreateForm } from '../../../FormModule';
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle';

interface IModalWidthsProps {
    visible: boolean,
    handleToggleModal: () => void,
    widtValue: string,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    stateError: string,
    onFocused: () => void,
    handleEditWidth: () => void,
    keyValue: string
}

function ModalWidths({
    visible, 
    handleToggleModal, 
    widtValue, 
    handleChange, 
    stateError, 
    onFocused, 
    handleEditWidth,
    keyValue
}: IModalWidthsProps) {
    
    const isEdit = keyValue.includes('edit');

  return (
        <Modal
            className={cnCreateForm(cnModal())}
            visible={visible}
            callBack={handleToggleModal}
            key={keyValue}
            >
                <h4 className={cnCreateForm('Header')}>{isEdit ? 'Редактирование ' : 'Добавление'} ширины модуля</h4>

                <div className={cnCreateForm('Content')}>
                    <div>
                        <label htmlFor="value">Ширина модуля в мм</label>
                        <Textinput
                            className={cnCreateForm('Textinput')}
                            value={widtValue}
                            onChange={handleChange}
                            onFocus={onFocused}
                            type="number"
                            maxLength={4}
                        />
                    </div>

                    {stateError && <div className='text-danger'>{stateError}</div>}

                    <Button
                        className={cnCreateForm('Button')}
                        type={'button'}
                        text={`${isEdit ? 'Редактировать' : 'Сохранить'}`}
                        leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
                        mods={{
                            mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                            hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
                        }}
                        callBack={handleEditWidth}
                    />
                </div>
        </Modal>
  )
}

export default ModalWidths