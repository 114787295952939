import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Euler, Vector3} from 'three';
import {BOTTOM_UNIT_TOP_PANEL_WIDTH} from '../../../../../constants'; 
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import {  ThreeBottomUnitAngleNormalBerta } from '../../../units/ThreeBottomUnit/types/ThreeBottomUnitAngleNormalBerta';
import { ISaveBottomAngleNormalBertaCorpusData } from '../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleNormalBertaCorpusData';

export class ThreeBottomAngleNormalBertaCorpus extends ThreeCorpus {
    saveData: ISaveBottomAngleNormalBertaCorpusData;
    unit: ThreeBottomUnitAngleNormalBerta;

    constructor(options: ISaveBottomAngleNormalBertaCorpusData, unit: ThreeBottomUnitAngleNormalBerta) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }


    public initState(isRebuild?: boolean) {
        this.createFrontPanels();
        this.createVerticalPanels();
        super.initState(isRebuild);
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth() - this.getBackThickness()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                this.getBackThickness()/2
            ),
            new Euler(0, 0, 0),
            true
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                this.getThickness() / 2,
                this.getBackThickness()/2
            )
        );
    
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                this.getThickness() / 2,
                this.getBackThickness()/2
            ),
        );
       
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, BOTTOM_UNIT_TOP_PANEL_WIDTH, this.getThickness()),
            'back',
            new Vector3(
                0,
                0,
                -this.getDepth()/2 + this.getThickness()/2
            ),
            new Euler(0, 0, 0),
            true
        );
    }

    protected createFrontPanels() {

        let position: Vector3;

        if (!this.saveData.frontPanel) {
            return;
        }
        position = new Vector3();
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                position.set(
                    -this.getWidth()/2 + this.saveData.frontPanel.length/2,
                    0,
                    this.getDepth()/2 + this.getThickness()/2
                );
                break;
            case SIDE_TYPE_RIGHT:
                position.set(
                    this.getWidth()/2 - this.saveData.frontPanel.length/2,
                    0,
                    this.getDepth()/2 + this.getThickness()/2
                );
                break;
        }
        this.createPanel(
            new BoxGeometry(100, this.getHeight(), this.getThickness()),
            'frontPanel',
            position
        );
    }

    protected createVerticalPanels() {

        if (!this.saveData.verticalPanel) {
            return;
        }

        const verticalPanelGap: number = this.saveData.verticalPanel.gap; 
        let positionLeft: Vector3 = new Vector3();
        let positionRight: Vector3 = new Vector3();

        if(this.saveData.frontPanel && this.saveData.frontPanel.length) 

        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                positionLeft.set(
                    -this.getWidth()/2 + this.saveData.frontPanel.length/2 + verticalPanelGap,
                    0,
                    this.getDepth()/2 + this.saveData.verticalPanel.length/2 + this.getThickness()
                );
                positionRight.set(
                    -this.getWidth()/2 + this.saveData.frontPanel.length/2 - 100 < -this.getWidth()/2 ? -this.getWidth()/2 : -this.getWidth()/2 + this.saveData.frontPanel.length/2 - 200,
                    0,
                    this.getDepth()/2 + this.saveData.verticalPanel.length/2
                );
                break;
            case SIDE_TYPE_RIGHT:
                positionLeft.set(
                    this.getWidth()/2 - this.saveData.frontPanel.length/2 + verticalPanelGap,
                    0,
                    this.getDepth()/2 + this.saveData.verticalPanel.length/2 + this.getThickness()
                );
                positionRight.set(
                    this.getWidth()/2 - this.saveData.frontPanel.length/2 + 100 > this.getWidth()/2 ? this.getWidth()/2 : this.getWidth()/2 - this.saveData.frontPanel.length/2 + 200,
                    0,
                    this.getDepth()/2 + this.saveData.verticalPanel.length/2
                );
                break;
        }
        this.createPanel(
            new BoxGeometry(this.saveData.verticalPanel.length, this.getHeight(), this.getThickness()),
            'verticalLeftPanel',
            positionLeft,
            new Euler(0, Math.PI / 2, 0)
        );
        this.createPanel(
            new BoxGeometry(this.saveData.verticalPanel.length + this.getThickness()*2, this.getHeight(), this.getThickness()),
            'verticalRightPanel',
            positionRight,
            new Euler(0, Math.PI / 2, 0)
        );
    }

    protected createBackPanels() {
    }
}