import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";
import {resources} from "./languages";
import {DEFAULT_LANGUAGE} from '../../common-code/constants';

document.documentElement.lang = DEFAULT_LANGUAGE;

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        lng: DEFAULT_LANGUAGE,
        resources
    });

export {i18n};