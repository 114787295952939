import React, {useState} from 'react';
import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import {useTranslation} from 'react-i18next';
import {useDispatch} from "react-redux";
import {processLogin} from '../../../../helpers';
import {Input} from '../../../components/Input/Input';
import {
    INPUT_TYPE_PASSWORD,
    INPUT_TYPE_TEXT,
} from '../../../constants';
import {cnInput} from '../../../components/Input';
import {Button, cnButton} from '../../../components/Button';

export const cnLoginForm = cn('LoginForm');

export interface ILoginFormUserProps extends IClassNameProps {
}

export const LoginFormManager: React.FC<ILoginFormUserProps> = () => {
    const [userLogin, setUserLogin] = useState('');
    const [password, setPassword] = useState('');
    const [formErrors, setFormErrors] = useState<any>({});
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const onLogin = () => {
        if (!validateLoginForm()) {
            return;
        }
        let newFormErrors: any = {};
        processLogin(userLogin, password, dispatch, t, newFormErrors).then(() => {
            setFormErrors(newFormErrors);
        }).catch(() => {
            newFormErrors.serverError = t('Произошла неизвестная ошибка');
            setFormErrors(newFormErrors);
        });
    };

    const validateLoginForm = (): boolean => {
        let newFormErrors: any = {};

        if (userLogin.length <= 0) {
            newFormErrors['userLogin'] = t('Необходимо ввести логин');
        }
        if (password.length <= 0) {
            newFormErrors['password'] = t('Необходимо ввести пароль');
        }
        setFormErrors(newFormErrors);

        return Object.keys(newFormErrors).length <= 0;
    };

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (formErrors && formErrors[event.target.name]) {
            delete formErrors[event.target.name];
            setFormErrors(formErrors);
        }
        switch (event.target.name) {
            case "userLogin":
                setUserLogin(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
        }
    };

    return (
        <>
            <div className={cnLoginForm('Group')}>
                <p className={cnLoginForm('Title')}>{t('Логин')}:</p>
                <p className={cnLoginForm('Notes')}>{t('Введите логин')}</p>
                <Input
                    className={cnLoginForm(cnInput({error: !!(formErrors && formErrors['userLogin'])}))}
                    type={INPUT_TYPE_TEXT}
                    value={userLogin}
                    name={'userLogin'}
                    onChange={onChangeInput}
                />
            </div>
            <div className={cnLoginForm('Group')}>
                <p className={cnLoginForm('Title')}>{t('Пароль')}:</p>
                <Input
                    className={cnLoginForm(cnInput({error: !!(formErrors && formErrors['password'])}))}
                    type={INPUT_TYPE_PASSWORD}
                    value={password}
                    name={'password'}
                    onChange={onChangeInput}
                />
            </div>
            {(Object.keys(formErrors).length > 0) &&
                <div className={cnLoginForm('Errors')}>
                    {Object.keys(formErrors).map(key =>
                        <p
                            key={key}
                            className={cnLoginForm('Error')}
                        >
                            {formErrors[key]}
                        </p>
                    )}
                </div>
            }
            <Button
                className={cnLoginForm(cnButton())}
                text={t('Войти')}
                callBack={onLogin}
                mods={{mods: {color: 'white', bkg: 'lightGreen', border: 'green'}}}
                type={'button'}
            />
        </>
    );
};