import React from 'react'
import { SHELF_TYPES as typeShelf } from 'common-code/lib/constants';
import { cnModal, Modal } from '../../../../../../components/Modal';
import { cnCreateForm } from '../../../FormModule';
import { Textinput } from '@yandex/ui/Textinput/desktop/bundle';
import { Select } from '../../../../../../components/Select/Select';
import { Tumbler } from '../../../../../../components/Tumbler/Tumbler';
import { Button } from '../../../../../../components/Button';
import { editOption } from '../../../../utils/editOption';

interface IShelfProps {
    modal: boolean,
    handleToggleModal: () => void,
    handleSubmit: () => void,
    distanceShelf: Record<string, string>,
    setNewShelf: (newShelf: Record<string, string | boolean>)=> void,
    newShelf: Record<string, string | boolean>,
    keyValue: string
}

function ModalShelves({ 
        modal,
        handleToggleModal,
        handleSubmit,
        distanceShelf,
        setNewShelf,
        newShelf,
        keyValue
    } : IShelfProps) {

    const onInputChange = (e:  React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        setNewShelf({ ...newShelf, [e.target.name]: e.target.value });   
    };
    
    const onChecked = (e:React.ChangeEvent<HTMLInputElement>) => {
        setNewShelf({ ...newShelf, [e.target.name]: e.target.checked });
    }

    const onFocus = () =>  setNewShelf({ ...newShelf, error: ''}); 

    const { length, depth, thickness, initPosition, type, fixed, distance, rotation, shelfRotationX, shelfRotationY, shelfRotationZ, error } = newShelf;

    const edit = keyValue.includes('edit')

  return (
    <Modal
        className={cnCreateForm(cnModal())}
        visible={modal}
        callBack={handleToggleModal}
        key={keyValue}
    >

        <h4 className={cnCreateForm('Header')}>{edit ? 'Редактирование полки' : 'Добавление полки'}</h4>

        <div className={cnCreateForm('Content')}>

            <div>
                <label htmlFor="length">Длина полки в %</label>
                <Textinput
                    className={cnCreateForm('Textinput')}
                    value={length}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                    name="length"
                    type="number"
                    maxLength={3}
                    onFocus={onFocus}
                />
            </div>
            <div>
                <label htmlFor="depth">Величина на которую глубина полки меньше 100% в мм</label>
                <Textinput
                    className={cnCreateForm('Textinput')}
                    value={depth}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                    name="depth"
                    type="number"
                    maxLength={3}
                    onFocus={onFocus}
                />
            </div>

            <label htmlFor="type">Положение полки в пространстве (для более точной конфигурации необходимо изменить поля положение полки в модуле) </label>
            <Select
                className={cnCreateForm('Select')}
                placeholder={"Выберите значение"}
                showPlaceholder={false}
                options={editOption(typeShelf)}
                value={type as string}
                callBack={(e: React.ChangeEvent<HTMLSelectElement>) => onInputChange({...e,target:{...e.target, name: 'type'}})}
            />
        
            <Tumbler
                className={cnCreateForm('Tumbler')}
                checked={fixed as boolean}
                callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
                label="Положене полки в модуле зафиксировано"
                name="fixed"
            />
            
            {fixed && 
                <>
                    <label htmlFor="distance">Высота установки полки</label>
                    <Select
                        className={cnCreateForm('Select')}
                        placeholder={"Выберите значение"}
                        showPlaceholder={false}
                        options={editOption(distanceShelf)}
                        value={distance as string}
                        callBack={(e: React.ChangeEvent<HTMLSelectElement>) => onInputChange({...e,target:{...e.target, name: 'distance'}})}
                    />

                    { distance !== 'center' && 
                    <div>
                        <label htmlFor="initPosition">Величена отступа</label>
                        <Textinput
                            className={cnCreateForm('Textinput')}
                            value={initPosition}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                            name="initPosition"
                            type="number"
                            maxLength={3}
                            onFocus={onFocus}
                        />
                    </div>
                    }
                </> }
                <div>
                    <label htmlFor="thickness">Толщина полки в мм</label>
                    <Textinput
                        className={cnCreateForm('Textinput')}
                        value={thickness}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                        name="thickness"
                        type="number"
                        maxLength={2}
                        onFocus={onFocus}
                    />
                </div>

            <Tumbler
                className={cnCreateForm('Tumbler')}
                checked={rotation as boolean}
                callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
                label="Изменить положение полки в модуле"
                name="rotation"
            />
            
            {rotation && 
                <>
                    <div>
                        <label htmlFor="shelfRotationX">Указать вращение полки оси x (градусы от -90 до 90)</label>
                        <Textinput
                            className={cnCreateForm('Textinput')}
                            value={shelfRotationX}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                            name="shelfRotationX"
                            type="number"
                            maxLength={3}
                            onFocus={onFocus}
                        />
                    </div>
                    <div>
                        <label htmlFor="shelfRotationY">Указать вращение полки оси y (градусы от -90 до 90)</label>
                        <Textinput
                            className={cnCreateForm('Textinput')}
                            value={shelfRotationY}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                            name="shelfRotationY"
                            type="number"
                            maxLength={3}
                            onFocus={onFocus}
                        />
                    </div>
                    <div>
                        <label htmlFor="shelfRotationZ">Указать вращение полки оси z (градусы от -90 до 90)</label>
                        <Textinput
                            className={cnCreateForm('Textinput')}
                            value={shelfRotationZ}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                            name="shelfRotationZ"
                            type="number"
                            maxLength={3}
                            onFocus={onFocus}
                        />
                    </div>
                   
                </> }

                {error && <div className='text-danger'>{error}</div>}

        </div>

        <Button
            className={cnCreateForm("Button")}
            type={'button'}
            text={edit ? 'Сохранить' : 'Добавить полку'}
            leftIcon={ {path: 'plus2', width: 16, height: 16, marginRight: 8} }
            mods={{
                mods: {bkg: 'white', color: 'lightGreen', border: 'lightGreen', fill: 'lightGreen'},
                hover: {mods: {bkg: 'lightGreen', color: 'white', fill: 'white'}}
            }}
            callBack={handleSubmit}
        />
    </Modal>
  )
}

export default ModalShelves