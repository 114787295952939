import {ThreeBottomUnit} from "../ThreeBottomUnit";
import {KitchenService} from "../../../../../services/KitchenService/KitchenService";
import {TDirectionSideType} from "../../../../../../../common-code/types/TDirectionSideType";
import {
    SIDE_TYPE_LEFT,
    SIDE_TYPE_RIGHT
} from "../../../../../../../common-code/constants";
import {
    ThreeBottomEndFullLengthSidewallConsoleCorpus
} from "../../../details/ThreeCorpus/types/ThreeBottomEndFullLengthSidewallConsoleCorpus";
import {
    ISaveBottomUnitEndFullLengthSidewallConsoleData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndFullLengthSidewallConsoleData';
import {ISaveCorpusData} from '../../../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveKUnitDetailData} from '../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData';
import {ISaveLegData} from '../../../../../../../common-code/interfaces/saveData/ISaveLegData';


export class ThreeBottomUnitEndFullLengthSidewallConsole extends ThreeBottomUnit {
    saveData: ISaveBottomUnitEndFullLengthSidewallConsoleData;
    corpus: ThreeBottomEndFullLengthSidewallConsoleCorpus;

    constructor(options: ISaveBottomUnitEndFullLengthSidewallConsoleData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.corpus = this.initCorpus(options.corpus);
    }

    public isEndUnit(): boolean {
        return true;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType;
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeBottomEndFullLengthSidewallConsoleCorpus {
        return new ThreeBottomEndFullLengthSidewallConsoleCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected initTabletopsData(tabletops?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        if (tabletops) {
            let tabletop: ISaveKUnitDetailData;

            for (tabletop of tabletops) {
                if (tabletop.margin) {
                    switch (this.getSideType()) {
                        case SIDE_TYPE_LEFT:
                            tabletop.margin.x = tabletop.margin.x && !isNaN(+tabletop.margin.x) ? -Math.abs(+tabletop.margin.x) : 0;
                            break;
                        case SIDE_TYPE_RIGHT:
                            tabletop.margin.x = tabletop.margin.x && !isNaN(+tabletop.margin.x) ? Math.abs(+tabletop.margin.x) : 0;
                            break;
                    }
                }
            }
        }

        return tabletops;
    }

    protected calculateInitLegsData(legs?: ISaveLegData[]): ISaveLegData[] | undefined {
        if (legs) {
            let index;

            for (index in legs) {
                switch (+index) {
                    case 0:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                                legs[index].initPosition = {x: '=({%100}-8)', z: '50'};
                                break;
                            case SIDE_TYPE_RIGHT:
                                legs[index].initPosition = {x: '8', z: '50'};
                                break;
                        }
                        break;
                    case 1:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                                legs[index].initPosition = {x: '=({%100}-8)', z: '=({%100}-50)'};
                                break;
                            case SIDE_TYPE_RIGHT:
                                legs[index].initPosition = {x: '8', z: '=({%100}-50)'};
                                break;
                        }
                        break;
                }
            }
        }

        return legs;
    }

    protected calculateInitPlinthsData(plinths?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        let index;

        if (plinths) {
            for (index in plinths) {
                switch (plinths[index].positionType) {
                    case SIDE_TYPE_LEFT:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_RIGHT:
                                plinths[index].isVisible = false;
                                break;
                            case SIDE_TYPE_LEFT:
                                plinths[index].isVisible = true;
                                break;
                        }
                        break;
                    case SIDE_TYPE_RIGHT:
                        switch (this.getSideType()) {
                            case SIDE_TYPE_LEFT:
                                plinths[index].isVisible = false;
                                break;
                            case SIDE_TYPE_RIGHT:
                                plinths[index].isVisible = true;
                                break;
                        }
                        break;
                }
            }
        }

        return plinths;
    }

}