import {ThreeCorpus} from '../ThreeCorpus';

export class ThreeShelfUnitCorpus extends ThreeCorpus {
    public getInnerDepth() {
        return this.saveData.sizes.width;
    }

    public getInnerWidth() {
        return this.saveData.sizes.length;
    }

    public getInnerHeight() {
        return this.saveData.sizes.height;
    }

    protected createBackPanels() {
    }

    protected createPanels() {
    }
}