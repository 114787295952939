import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Mesh, Shape, Vector3} from 'three';
import {ThreeTopUnitEndNormal} from '../../../units/ThreeTopUnit/types/ThreeTopUnitEndNormal';
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import {ThreeShelf} from '../../ThreeShelf/ThreeShelf';
import {
    ISaveTopEndNormalCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveTopEndNormalCorpusData';
import {TSizes} from '../../../../../../../common-code/types/geometry/TSizes';
import {ISaveShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfData';
import {CommonHelper} from 'common-code';

export class ThreeTopEndNormalCorpus extends ThreeCorpus {
    saveData: ISaveTopEndNormalCorpusData;
    unit: ThreeTopUnitEndNormal;

    constructor(options: ISaveTopEndNormalCorpusData, unit: ThreeTopUnitEndNormal) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public getSmallWidth(): number {
        return +this.saveData.smallWidth;
    }

    public getSmallDepth(): number {
        return +this.saveData.smallDepth;
    }

    protected createShape() {
        this.shape = new Shape();
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_RIGHT:
                this.createRightShape();
                break;
            case SIDE_TYPE_LEFT:
                this.createLeftShape();
        }
    }

    protected createRightShape() {
        this.shape.moveTo(
            -this.getWidth() / 2 + this.getThickness(),
            this.getDepth() / 2
        );
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            -this.getDepth() / 2 + this.getBackThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getBackThickness()
        );
        if (this.getSmallDepth() > 0) {
            this.shape.lineTo(
                this.getWidth() / 2 - this.getThickness(),
                -this.getDepth() / 2 + this.getSmallDepth()
            );
        }
        if (this.getSmallWidth() > 0) {
            this.shape.lineTo(
                -this.getWidth() / 2 + this.getSmallWidth(),
                this.getDepth() / 2
            );
        }
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            this.getDepth() / 2
        );
    }

    protected createLeftShape() {
        this.shape.moveTo(
            -this.getWidth() / 2 + this.getThickness(),
            -this.getDepth() / 2 + this.getBackThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getBackThickness()
        );
        this.shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            this.getDepth() / 2
        );
        if (this.getSmallWidth() > 0) {
            this.shape.lineTo(
                this.getWidth() / 2 - this.getSmallWidth(),
                this.getDepth() / 2
            );
        }
        if (this.getSmallDepth() > 0) {
            this.shape.lineTo(
                -this.getWidth() / 2 + this.getThickness(),
                -this.getDepth() / 2 + this.getSmallDepth()
            );
        }
        this.shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            -this.getDepth() / 2 + this.getBackThickness()
        );
    }

    protected createShapePanels() {
        let bottom: Mesh;
        let top: Mesh;

        bottom = this.createShapePanel();
        bottom.position.y = -this.getHeight() / 2 + this.getThickness() / 2;
        bottom.name = 'bottom';
        this.view3d.add(bottom);

        top = this.createShapePanel();
        top.position.y = this.getHeight() / 2 - this.getThickness() / 2;
        top.name = 'top';
        this.view3d.add(top);
    }

    protected createPanels() {
        let sideSizes: {left: TSizes, right: TSizes};

        sideSizes = this.calculateSideSizes();
        this.createPanel(
            new BoxGeometry(sideSizes.left.length, sideSizes.left.height, sideSizes.left.width),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                this.getHeight()/2 - sideSizes.left.height/2,
                -this.getDepth()/2 + this.getBackThickness() + sideSizes.left.width/2
            )
        );
        this.createPanel(
            new BoxGeometry(sideSizes.right.length, sideSizes.right.height, sideSizes.right.width),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                this.getHeight()/2 - sideSizes.right.height/2,
                -this.getDepth()/2 + this.getBackThickness() + sideSizes.right.width/2
            )
        );
    }

    protected calculateSideSizes(): {left: TSizes, right: TSizes} {
        let sideSizes: {left: TSizes, right: TSizes};

        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
            default:
                sideSizes = {
                    left: {
                        length: this.getThickness(),
                        height: this.getHeight(),
                        width: this.getSmallDepth() - this.getBackThickness()
                    },
                    right: {
                        length: this.getThickness(),
                        height: this.getHeight(),
                        width: this.getDepth() - this.getBackThickness()
                    }
                };
                break;
            case SIDE_TYPE_RIGHT:
                sideSizes = {
                    left: {
                        length: this.getThickness(),
                        height: this.getHeight(),
                        width: this.getDepth() - this.getBackThickness()
                    },
                    right: {
                        length: this.getThickness(),
                        height: this.getHeight(),
                        width: this.getSmallDepth() - this.getBackThickness()
                    }
                };
                break;
        }

        return sideSizes;
    }

    protected createShelves() {
        let shelfData: ISaveShelfData;
        let shelf: ThreeShelf;
        for (shelfData of this.saveData.shelves) {
            shelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this, this.shape);
            shelf.initState();
            shelf.createView();
            this.shelves.push(shelf);
        }
    }
}