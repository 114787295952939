import { FACADE_CALCULATE_AMOUNT, LEVEL_BOTTOM, LEVEL_TOP } from "common-code/lib/constants";
import { IFacadeData } from "../../../../../common-code/interfaces/materials/IFacadeData";
import { TFacadeCalculateType } from "common-code/lib/types/TFacadeCalculateType";

export const levels = [
    { value: LEVEL_BOTTOM, content: "Нижний уровень" },
    { value: LEVEL_TOP, content: "Верхний уровень" },
  ];
  
export const calculateTypes = [
    { value: "amount", content: "Расчет суммарной стоимости группы фасадов" },
    { value: "none", content: "Без расчета стоимости" },
    { value: "self_amount", content: "Расчет стоимости каждого фасада поштучно" },
  ];

export const initialErrors = {
    title: "",
    depth: "",
    image: "",
    models: "",
    offers: "",
}
    
export const defaultValues = (item: IFacadeData | null) =>{
    
    return item ? {
        id: item.id,
        title: item.title,
        depth: item.depth ? item.depth : 16,
        calculateType: item.calculateType ? item.calculateType : FACADE_CALCULATE_AMOUNT,
        models: item.models ? item.models : [],
        offers: item.offers ? item.offers : [],

        handle: item.handle ? item.handle : undefined,
        level: item.level ? item.level :  undefined,
        isDefault: item.isDefault ? item.isDefault : false,
        image: item.image ? item.image : '',
        imageNew: null,
        plinthitem: item.plinthMaterial ? item.plinthMaterial : '',
        isTopFlipY: item.isTopFlipY ? item.isTopFlipY : false,
        isBottomFlipY: item.isBottomFlipY ? item.isBottomFlipY : false,
    } : {
        id: '',
        title: '' , 
        depth: 16,
        calculateType: FACADE_CALCULATE_AMOUNT as TFacadeCalculateType,
        models: [],
        offers: [],

        handle: undefined,
        level: undefined,
        isDefault: false,
        image: '',
        imageNew: null,
        plinthMaterial: '',
        isTopFlipY: false,
        isBottomFlipY: false,
    }
}
