import {ThreeConstructive} from '../ThreeConstructive/ThreeConstructive';
import {ThreeWall} from '../../rooms/ThreeWall/ThreeWall';
import {ISaveWallConstructiveData} from '../../../../../../common-code/interfaces/saveData/ISaveWallConstructiveData';

export class ThreeWallConstructive extends ThreeConstructive {
    wall: ThreeWall;
    saveData: ISaveWallConstructiveData;

    constructor(data: ISaveWallConstructiveData, wall: ThreeWall) {
        super(data, wall.room);
        this.saveData = data;
        this.wall = wall;
    }
}