import * as i18n from 'i18next';
import {DEFAULT_LANGUAGE} from '../../../constants';
import * as ru from '../../../i18n/ru/translation.json';
import * as en from '../../../i18n/en/translation.json';

const resources = {
    ru: {
        translation: ru
    },
    en: {
        translation: en
    },
}
i18n.init({
    debug: false,
    lng: DEFAULT_LANGUAGE,
    resources,
});

export {i18n};