import {IMaterialData} from '../../common-code/interfaces/materials/IMaterialData';
import {IFacadeMaterialData} from '../../common-code/interfaces/materials/IFacadeMaterialData';
import {IProjectData} from '../../common-code/interfaces/project/IProjectData';
import {IProjectUserData} from '../../common-code/interfaces/project/IProjectUserData';
import {ORDER_USER_TYPE_CHOICE} from '../../common-code/constants';
import axios from 'axios';
import {IUserData} from '../../common-code/interfaces/IUserData';
import {
    ADD_MESSAGE, AVAILABLE_DOCUMENT_CURSORS, DEFAULT_SHOW_MESSAGE_INTERVAL,
    HIDE_LOADING,
    MESSAGE_TYPE_ERROR,
    REMOVE_MESSAGE,
    SET_USER,
    SHOW_LOADING,
    START_SESSION
} from '../constants';
import {TMessage} from '../types/TMessage';
import {Action, Dispatch} from 'redux';
import {TFunction} from 'i18next';
import {ISendUserData} from '../../common-code/interfaces/user/ISendUserData';
import {TProjectLocalSaveData} from '../../common-code/types/project/TProjectLocalSaveData';
import {IClientSessionData} from '../../common-code/interfaces/session/IClientSessionData';

export const isMaterialDisable = (material: IMaterialData, facadeMaterials?: IFacadeMaterialData[]): boolean => {
    if (material.disable === true) {
        return true;
    }

    if (facadeMaterials) {
        if (material.enableFacades || material.disableFacades) {
            if (facadeMaterials.filter((facadeMaterial) => {
                if (material.enableFacades && !material.enableFacades.includes(facadeMaterial.facade)) {
                    return true;
                }

                return !!(material.disableFacades && material.disableFacades.includes(facadeMaterial.facade));
            }).length === facadeMaterials.length) {
                return true;
            }
        }
    }

    return false;
}

export const getProjectUserData = (projectData: IProjectData): IProjectUserData => {
    let userData: IProjectUserData;

    userData = {
        type: ORDER_USER_TYPE_CHOICE
    };
    if (projectData.customer) {
        userData = {
            type: ORDER_USER_TYPE_CHOICE,
            id: projectData.customer.id,
            email: projectData.customer.email,
            lastName: projectData.customer.lastName,
            firstName: projectData.customer.name,
            phone: projectData.customer.phone,
        }
    }

    return userData;
}

export const validatePhone = (phoneValue: string) => {
    phoneValue = clearPhone(phoneValue);

    return (phoneValue.length === 11) && phoneValue[0] === '8' && phoneValue[1] === '9';
};

export const clearPhone = (phone: string): string => {
    return phone.replace(/(\+7)/, '8')
        .replaceAll(/(\+7)|(\()|(\))|(-)|(_)|(\s)/g, '');
};

export const processLogin = (
    userPhone: string,
    password: string,
    dispatch: Dispatch<Action>,
    t: TFunction<"translation", undefined, "translation">,
    formErrors: any,
): Promise<IUserData | undefined> => {
    dispatch({type: SHOW_LOADING});
    return axios.post<any>('/auth/login', {
        phone: userPhone,
        password: password
    })
        .then((response) => {
            const userData: IUserData | undefined = response.data ? response.data.user : undefined;
            const sessionId: string | undefined = response.data ? response.data.sid : undefined;
            const session: IClientSessionData | undefined = (sessionId !== undefined && userData !== undefined) ? {
                user: userData,
                seanceId: sessionId
            } : undefined;
            if (!userData) {
                formErrors.password = t('Вы ввели неверный пароль')
            } else {
                dispatch({
                    type: SET_USER,
                    payload: userData,
                });
            }
            if (session) {
                dispatch({
                    type: START_SESSION,
                    payload: session,
                });
            }
            dispatch({type: HIDE_LOADING});
            return userData;
        }).catch(() => {
            dispatch({
                type: ADD_MESSAGE,
                payload: {
                    type: MESSAGE_TYPE_ERROR,
                    message: t('Ошибка авторизации пользователя. Проверьте правильность ввода логина и пароля'),
                    params: {id: 'loginUser'},
                } as TMessage
            });
            dispatch({type: HIDE_LOADING});
            return undefined;
        });
};

export const addFormError = (errors: any, message: string, id: string, group?: string) => {
    if (group) {
        if (!errors[group]) {
            errors[group] = {};
        }
        errors[group][id] = message;
    } else {
        errors[id] = message;
    }
}

export const processCreateUser = (userData: IProjectUserData): Promise<number> => {
    return new Promise((resolve, reject) => {
        let sendData: ISendUserData;

        if (!userData.phone || !userData.firstName) {
            reject();
            return;
        }
        sendData = {
            action: 'create',
            name: userData.firstName,
            lastName: userData.lastName,
            phone: userData.phone
        };

        axios.post('/api/user/create/', sendData).then((response) => {
            if (response.data && response.data.status && response.data.newUser) {
                resolve(response.data.newUser);
            } else {
                reject();
            }
        }).catch(() => {
            reject();
        })
    })
}

export const showMessage = (messageData: TMessage, dispatch: Dispatch) => {
    if (!messageData.params) {
        messageData.params = {
            id: '' + (+new Date())
        }
    }
    dispatch({
        type: ADD_MESSAGE,
        payload: messageData
    });
    if (messageData.autoClose) {
        setTimeout(() => {
            dispatch({
                type: REMOVE_MESSAGE,
                payload: messageData.params ? messageData.params.id : undefined
            });

        }, messageData.interval || DEFAULT_SHOW_MESSAGE_INTERVAL)
    }
}

export const checkLocalProjectSaveTime = (
    localSaveData: TProjectLocalSaveData,
    currentTime: number,
    localProjectSaveTime?: number
): boolean => {
    if (localProjectSaveTime) {
        if (Math.ceil((currentTime - localSaveData.time) / 1000) > localProjectSaveTime) {
            return false;
        }
    }
    return true;
}

export const getInLocalStorage = (name: string): string | undefined => {
    try {
        let value: string | null;

        if (!window || !window.localStorage) {
            return undefined;
        }
        value = window.localStorage.getItem(name);

        return value ? value : undefined;
    } catch (e) {
        return undefined;
    }

}

export const deleteFromLocalStorage = (name: string): void => {
    try {
        if (!window || !window.localStorage) {
            return;
        }
        window.localStorage.removeItem(name);
    } catch (e) {
    }
}

export const setToLocalStorage = (name: string, value: string): void => {
    try {
        if (!window || !window.localStorage) {
            return;
        }
        window.localStorage.setItem(name, value);
    } catch (e) {
    }
}

export const isMobile = (): boolean => {
    let isMobile: boolean;

    isMobile = false; //initiate as false
    if (!navigator || !navigator.userAgent) {
        return isMobile;
    }
    // device detection
    // eslint-disable-next-line
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
        // eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
        isMobile = true;
    }

    return isMobile;
}

export const setDocumentCursor = (value: string) => {
    if (!window || !window.document || !window.document.body || !window.document.body.style) {
        return;
    }
    if (!AVAILABLE_DOCUMENT_CURSORS.includes(value)) {
        console.log('not available document cursor', value);
        return;
    }
    window.document.body.style.cursor = value;
}

export const isDevelop = (): boolean => {
    if (window && window.location && window.location.hostname) {
        return window.location.hostname.includes('localhost');
    }

    return false;
}