import { IPlinthData } from "../../../../../common-code/interfaces/materials/IPlinthData";
import { INewPlinthData } from "./FormPlinth";


export const initialErrors = {
    title: "",
    depth: "",
    height: "",
    image: "",
}




export const defaultValues = (item: IPlinthData | null): INewPlinthData => {
  return item
    ? {
        id: item.id,
        title: item.title,
        depth: item.depth ? item.depth : 16,
        height: item.height,
        disableFacades: item.disableFacades,
        color: item.color ? item.color : "",
        colorFrom1C: item.colorFrom1C ? item.colorFrom1C : "",
        emissiveColor: item.emissiveColor ? item.emissiveColor : "",
        isDefault: item.isDefault ? item.isDefault : false,
        textures: item.textures ? item.textures : [],
        texturesNew: [],
        image: item.image ? item.image : "",
        imageNew: null,
      }
    : {
        id: "",
        title: "",
        depth: 16,
        height: 100,
        disableFacades: [],
        color: "",
        colorFrom1C: "",
        emissiveColor: "",
        isDefault: false,
        textures: [],
        texturesNew: [],
        image: "",
        imageNew: null,
      };
};
