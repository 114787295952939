import {cn} from "@bem-react/classname";
import {IClassNameProps} from "@bem-react/core";
import React from "react";
import './Header.css';
import {
    HEADER_TYPE_BIG, HEADER_TYPE_SMALL,
    LINK_TARGET_BLANK,
    LINK_TYPE_DEFAULT,
    LINK_TYPE_NAV,
    MENU_TYPE_MAIN_MENU,
    MENU_TYPE_USER_MENU,
} from "../../constants";
import {cnMenuBurger, MenuBurger} from "../../components/MenuBurger";
import {Link} from "../../components/Link/Link";
import {cnMainMenu, MainMenu} from "../../elements/MainMenu";
import {City, cnCity} from "../../elements/City";
import {Auth, cnAuth} from "../../elements/Auth";
import {cnUserMenu, UserMenu} from "../../elements/UserMenu";
import {useSelector} from 'react-redux';
import {AppState} from '../../../redux/AppStore';
import {IAppConfig} from "../../../../common-code/interfaces/config/IAppConfig";
import {THeaderType} from '../../../types/THeaderType';
import {useHeaderType} from '../../../hooks/useHeaderType';
import {KitchenService} from '../../../3d/services/KitchenService/KitchenService';

export const cnHeader = cn('Header');

export interface IHeaderProps extends IClassNameProps {
    title?: string;
    size?: string;
    service?: KitchenService;
}

export const Header: React.FC<IHeaderProps> = ({className, title, size, service}) => {
    const config: IAppConfig = useSelector((state: AppState) => state.config);
    const headerType: THeaderType = useHeaderType();

    return (
        <header className={cnHeader({size: size}, [className])}>
            <div className={cnHeader('LogoMenu')}>
                <Link
                    className={cnHeader('Logo')}
                    type={LINK_TYPE_NAV}
                    href={'/'}
                    icon={{path: 'logo', width: 25, height: 25}}
                    mods={{mods: {fill: 'red'}}}
                />
                <MenuBurger
                    className={cnHeader(cnMenuBurger())}
                    forMenu={MENU_TYPE_MAIN_MENU}
                />
                <MainMenu className={cnHeader(cnMainMenu())}/>
            </div>
            <div className={cnHeader('PhoneContainer', [cnHeader('Place'), cnHeader('FlexContainer')])}>
                <Link
                    className={cnHeader('Phone')}
                    type={LINK_TYPE_DEFAULT}
                    href={'tel:'+config.headerPhoneValue}
                    icon={{path: 'phone', width: 25, height: 25, marginRight: 8}}
                    text={headerType !== HEADER_TYPE_SMALL ? config.headerPhoneTitle : ''}
                    mods={{mods: {color: 'lightBlack', fill: 'black'}}}
                />
            </div>
            <div className={cnHeader('WaContainer', [cnHeader('Place'), cnHeader('FlexContainer')])}>
                <Link
                    className={cnHeader('WhatsApp')}
                    type={LINK_TYPE_DEFAULT}
                    href={'https://api.whatsapp.com/send?phone='+config.whatsAppPhone+'&text='+
                        encodeURIComponent('Интересует кухня ( start_PpPI7pzu )')+
                        '&source=&data='}
                    target={LINK_TARGET_BLANK}
                    icon={{path: 'whatsapp', width: 25, height: 25}}
                    mods={{mods: {fill: 'black'}}}
                />
            </div>
            {headerType === HEADER_TYPE_BIG ?
                <div className={cnHeader('TitleContainer', [cnHeader('Place'), cnHeader('FlexContainer')])}>
                    <div className={cnHeader('Title')}>{title}</div>
                </div> : null
            }
            <div className={cnHeader('CityContainer', [cnHeader('Place'), cnHeader('FlexContainer')])}>
                <City className={cnHeader(cnCity())} service={service}/>
            </div>
            <div className={cnHeader('UserContainer', [cnHeader('Place', {border: true}), cnHeader('FlexContainer')])}>
                <Auth
                    className={cnHeader(cnAuth())}
                    forMenu={MENU_TYPE_USER_MENU}
                />
                <UserMenu className={cnHeader(cnUserMenu())}/>
            </div>
        </header>
    );
};