import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Mesh, Shape, Vector2, Vector3} from 'three';
import {SHELF_TYPE_VERTICAL, SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import {ThreeShelf} from '../../ThreeShelf/ThreeShelf';
import {ThreeBottomUnitEndOpenedCircle} from "../../../units/ThreeBottomUnit/types/ThreeBottomUnitEndOpenedCircle";
import {
    ISaveBottomEndOpenedCircleCorpusData
} from '../../../../../../../common-code/interfaces/saveData/ISaveBottomEndOpenedCircleCorpusData';
import {TPoint2D} from '../../../../../../../common-code/types/TPoint2D';
import {MathHelper} from 'common-code';
import {TSizes} from '../../../../../../../common-code/types/geometry/TSizes';
import {ISaveShelfData} from '../../../../../../../common-code/interfaces/saveData/ISaveShelfData';
import {CommonHelper} from 'common-code';
import {KitchenHelper} from 'common-code';

export class ThreeBottomEndOpenedCircleCorpus extends ThreeCorpus {
    saveData: ISaveBottomEndOpenedCircleCorpusData;
    unit: ThreeBottomUnitEndOpenedCircle;

    constructor(options: ISaveBottomEndOpenedCircleCorpusData, unit: ThreeBottomUnitEndOpenedCircle) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }

    public getThicknessBack(): number {
        return +this.saveData.backThickness;
    }

    public getSmallWidth(): number {
        return +this.saveData.smallWidth;
    }

    public getSmallDepth(): number {
        return +this.saveData.smallDepth;
    }

    protected createShape() {
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_RIGHT:
                this.shape = this.createRightShape();
                break;
            case SIDE_TYPE_LEFT:
                this.shape = this.createLeftShape();
        }
    }

    protected createRightShape(): Shape {
        let start: TPoint2D, end: TPoint2D, intersects, center, index,
            startAngle, endAngle, clockWise = false, minLength = Infinity, length;

        const shape: Shape = new Shape();

        shape.moveTo(
            -this.getWidth() / 2 + this.getThickness(),
            this.getDepth() / 2
        );
        shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            -this.getDepth() / 2 + this.getThickness()
        );
        shape.lineTo(
            this.getWidth() / 2,
            -this.getDepth() / 2 + this.getThickness()
        );
        if (this.getSmallDepth() > 0) {
            shape.lineTo(
                this.getWidth() / 2,
                -this.getDepth() / 2 + this.getSmallDepth()
            );
            start = {x: this.getWidth() / 2, y: -this.getDepth() / 2 + this.getSmallDepth()};
        } else {
            start = {x: this.getWidth() / 2, y: -this.getDepth() / 2 + this.getThickness()};
        }
        if (this.getSmallWidth() > 0) {
            end = {x: -this.getWidth() / 2 + this.getThickness() + this.getSmallWidth(), y: this.getDepth() / 2};
        } else {
            end = {x: -this.getWidth() / 2 + this.getThickness(), y: this.getDepth() / 2};
        }
        intersects = MathHelper.intersectCircles(
            {center: start, radius: 200},
            {center: end, radius: 200}
        );
        for (index in intersects) {
            length = MathHelper.getLength2D(
                new Vector2(intersects[index].x, intersects[index].y),
                new Vector2( -this.getWidth() / 2 + this.getThickness(), -this.getDepth() / 2 + this.getThickness())
            );
            if (length < minLength) {
                minLength = length;
                center = intersects[index];
            }
        }
        if (center !== undefined) {
            startAngle = MathHelper.getNormalAngle(new Vector2(start.x - center.x, start.y - center.y) );
            endAngle = MathHelper.getNormalAngle(new Vector2(end.x - center.x, end.y - center.y) );
            shape.absarc(center.x, center.y, 200, startAngle, endAngle, clockWise);
        }
        if (this.getSmallWidth() > 0) {
            shape.lineTo(
                -this.getWidth() / 2 + this.getThickness() + this.getSmallWidth(),
                this.getDepth() / 2
            );
        }
        shape.lineTo(
            -this.getWidth() / 2 + this.getThickness(),
            this.getDepth() / 2
        );

        return shape;
    }

    protected createLeftShape(): Shape {
        let start: TPoint2D, end: TPoint2D, intersects, center, index,
            startAngle, endAngle, clockWise = false, minLength = Infinity, length;

        const shape: Shape = new Shape();

        shape.moveTo(
            -this.getWidth() / 2,
            -this.getDepth() / 2 + this.getThickness()
        );
        shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            -this.getDepth() / 2 + this.getThickness()
        );
        shape.lineTo(
            this.getWidth() / 2 - this.getThickness(),
            this.getDepth() / 2
        );
        if (this.getSmallWidth() > 0) {
            shape.lineTo(
                this.getWidth() / 2 - this.getSmallWidth() - this.getThickness(),
                this.getDepth() / 2
            );
            start = {x: this.getWidth() / 2 - this.getSmallWidth() - this.getThickness(), y: this.getDepth() / 2};
        } else {
            start = {x: this.getWidth() / 2 - this.getThickness(), y: this.getDepth() / 2};
        }
        if (this.getSmallDepth() > 0) {
            end = {x: -this.getWidth() / 2, y: -this.getDepth() / 2 + this.getSmallDepth()};
        } else {
            end = {x: -this.getWidth() / 2, y: -this.getDepth() / 2 + this.getThickness()};
        }

        intersects = MathHelper.intersectCircles(
            {center: start, radius: 200},
            {center: end, radius: 200}
        );

        for (index in intersects) {
            length = MathHelper.getLength2D(
                new Vector2(intersects[index].x, intersects[index].y),
                new Vector2(this.getWidth() / 2 - this.getThickness(), -this.getDepth() / 2 + this.getThickness())
            );
            if (length < minLength) {
                minLength = length;
                center = intersects[index];
            }
        }

        if (center !== undefined) {
            startAngle = MathHelper.getNormalAngle( new Vector2(start.x - center.x, start.y - center.y) );
            endAngle = MathHelper.getNormalAngle( new Vector2(end.x - center.x, end.y - center.y));
            shape.absarc(center.x, center.y, 200, startAngle, endAngle, clockWise);
        }

        if (this.getSmallDepth() > 0) {
            shape.lineTo(
                -this.getWidth() / 2,
                -this.getDepth() / 2 + this.getSmallDepth()
            );
        }
        shape.lineTo(
            -this.getWidth() / 2,
            -this.getDepth() / 2 + this.getThickness()
        );

        return shape;
    }

    protected createShapePanels() {
        let bottom: Mesh;
        let top: Mesh;

        bottom = this.createShapePanel();
        bottom.position.y = -this.getHeight() / 2 + this.getThickness() / 2;
        bottom.name = 'bottom';
        this.view3d.add(bottom);

        top = this.createShapePanel();
        top.position.y = this.getHeight() / 2 - this.getThickness() / 2;
        top.name = 'top';
        this.view3d.add(top);
    }

    protected calculateSideSizes(): {left: TSizes | undefined, right: TSizes | undefined} {
        let sideSizes: {left: TSizes | undefined, right: TSizes | undefined};

        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
            default:
                sideSizes = {
                    left: undefined,
                    right: {
                        length: this.getThickness(),
                        height: this.getHeight(),
                        width: this.getDepth()
                    }
                };
                break;
            case SIDE_TYPE_RIGHT:
                sideSizes = {
                    left: {
                        length: this.getThickness(),
                        height: this.getHeight(),
                        width: this.getDepth()
                    },
                    right: undefined
                };
                break;
        }

        return sideSizes;
    }

    protected createPanels() {
        let sideSizes: {left: TSizes | undefined, right: TSizes | undefined};

        sideSizes = this.calculateSideSizes();
        if (sideSizes.left) {
            this.createPanel(
                new BoxGeometry(sideSizes.left.length, sideSizes.left.height, sideSizes.left.width),
                'left',
                new Vector3(
                    -this.getWidth()/2 + this.getThickness() / 2,
                    this.getHeight()/2 - sideSizes.left.height/2,
                    -this.getDepth()/2 + sideSizes.left.width/2
                )
            );
        }
        if (sideSizes.right) {
            this.createPanel(
                new BoxGeometry(sideSizes.right.length, sideSizes.right.height, sideSizes.right.width),
                'right',
                new Vector3(
                    this.getWidth()/2 - this.getThickness() / 2,
                    this.getHeight()/2 - sideSizes.right.height/2,
                    -this.getDepth()/2 + sideSizes.right.width/2
                )
            );
        }
    }

    protected createShelves() {
        let shelfData: ISaveShelfData;
        let shelf: ThreeShelf;
        let depth: number;
        let shape: Shape;

        for (shelfData of this.saveData.shelves) {

            if (shelfData.type === SHELF_TYPE_VERTICAL) {
                switch (this.unit.getSideType()) {
                    case SIDE_TYPE_LEFT:
                        shelfData.initPosition = {...shelfData.initPosition, x: `=({%50}-${this.getThickness() / 2})`};
                        break;
                    case SIDE_TYPE_RIGHT:
                        shelfData.initPosition = {...shelfData.initPosition, x: `=({%50}+${this.getThickness() / 2})`};
                        break;
                }
                shelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this);
            } else {
                depth = KitchenHelper.calculateSizeByParent(shelfData.length, this.getInnerDepth(), this.service.getDataForSizeByParent());
                if (depth !== this.getInnerDepth()) {
                    shape = this.unit.getSideType() === SIDE_TYPE_LEFT ? this.createLeftShape() : this.createRightShape();
                } else {
                    shape = this.shape
                }
                shelf = new ThreeShelf(CommonHelper.deepCopy(shelfData), this, shape);
            }
            shelf.initState();
            shelf.createView();
            this.shelves.push(shelf);
        }
    }

    protected createBackPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness(), this.getHeight(), this.getThickness()),
            'back',
            new Vector3(
                (this.unit.getSideType() === SIDE_TYPE_RIGHT) ? this.getThickness()/2 : -this.getThickness()/2,
                0,
                -this.getDepth()/2 + this.getThickness()/2
            )
        );
    }
}