import {IAppConfig} from '../../../interfaces/config/IAppConfig';
import {TOfferExternalId} from '../../../types/TOfferExternalId';
import {IUnitKitPrices} from '../../../interfaces/catalog/IUnitKitPrices';

export class CatalogHelper {
    public static async getUnitKitPrices(
        corpusId: string,
        config: IAppConfig,
        priceId: string,
        ids: string[],
        urlExternalId?: TOfferExternalId
    ): Promise<IUnitKitPrices> {

        console.log('process.env', process.env);
        return {};
        // axios
        //     .post(
        //         this.appService.getApiUri() + '/v3/prices.php',
        //         {priceId: priceId, ids: ids},
        //         {
        //             headers: this.appService.getApiHeaders(request)
        //         }
        //     ).then((response: AxiosResponse) => {
        //     const prices: {[key: string]: IProductPrice} = response.data;
        //     if (prices && Object.values(prices).length > 0) {
        //         return prices;
        //     } else {
        //         return {};
        //     }
        // })
        //     .catch(() => {
        //         return {};
        //     });
        //
        // let catalogId: string;
        // let catalogIds: string[] | undefined;
        // let productPrices: IUnitKitPrices = {};
        // let externalId: TOfferExternalId;
        // let priceIdName: 'guid' | 'vendorCode';
        // let apiProductPrices: IProductPrice[] = [];
        // let apiProductPrice: IProductPrice;
        // let pricesPath: string;
        // let itemId: string | undefined;
        //
        // switch (corpusId) {
        //     case '2':
        //         if (["bffb1e53-f886-4012-bbb8-f58b2eef82eb"].indexOf(config.id) !== -1) {
        //             catalogIds = ['surskayaSelf'];
        //         } else {
        //             catalogIds = ['surskayaNonton', 'nonton'];
        //         }
        //         break;
        //     case '3':
        //         catalogIds = ['interiorCenterNonton', 'nonton'];
        //         break;
        //     case '5':
        //         catalogIds =['imperialSelf'];
        //         break;
        //     case '6':
        //         catalogIds =['piramidaSelf'];
        //         break;
        // }
        // if (!catalogIds) {
        //     return productPrices;
        // }
        // if (urlExternalId) {
        //     externalId = urlExternalId;
        // } else if (config && config.catalog.offerExternalId) {
        //     externalId = config.catalog.offerExternalId;
        // } else {
        //     externalId = 'externalGuid';
        // }
        // switch (externalId) {
        //     case 'externalGuid':
        //         priceIdName = 'guid';
        //         break;
        //     case 'vendorCode':
        //     default:
        //         priceIdName = 'vendorCode';
        // }
        // apiProductPrices = [];
        // for (catalogId of catalogIds) {
        //     pricesPath = path.resolve(__dirname, "../../../../../../common/data/catalog/" + catalogId + "/prices/" + priceId + ".json")
        //     if (fs.existsSync(pricesPath)) {
        //         try {
        //             apiProductPrices = apiProductPrices.concat(JSON.parse(fs.readFileSync(pricesPath, {encoding: 'utf8'})));
        //         } catch (e) {
        //             return productPrices;
        //         }
        //     }
        // }
        //
        // if (apiProductPrices) {
        //     for (apiProductPrice of apiProductPrices) {
        //         itemId = apiProductPrice[priceIdName];
        //         if (!itemId) {
        //             continue;
        //         }
        //         if (!ids.length || ids.includes(itemId)) {
        //             productPrices[itemId] = {
        //                 id: itemId,
        //                 price: apiProductPrice.price,
        //                 oldPrice: apiProductPrice.oldPrice,
        //                 active: apiProductPrice.active,
        //                 remains: apiProductPrice.remains
        //             };
        //         }
        //     }
        // }
        //
        // return productPrices;
    }
}