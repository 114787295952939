import {IClassNameProps} from '@bem-react/core';
import React, {ChangeEvent, useRef, useState} from 'react';
import {cn} from '@bem-react/classname';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {IFacadeData} from '../../../../../common-code/interfaces/materials/IFacadeData';
import {AppState} from '../../../../redux/AppStore';
import {IFacadeMaterialData} from '../../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {Button, cnButton} from '../../../components/Button';
import {Popup} from '@yandex/ui/Popup/desktop/bundle';
import {Select} from '../../../components/Select/Select';
import {TLevel} from '../../../../../common-code/types/TLevel';
import {Option} from '@yandex/ui/Select';
import {LEVEL_BOTTOM, LEVEL_TOP} from '../../../../../common-code/constants';
import './ModulesCatalog-Filter.css';

export const cnModulesCatalogFilter = cn('ModulesCatalogFilter');

export interface IModulesCatalogFilterProps extends IClassNameProps {
    service: KitchenService;
}

export const ModulesCatalogFilter: React.FC<IModulesCatalogFilterProps> =
    ({
         className,
         service,
     }) => {
        const {t} = useTranslation();
        const facades: IFacadeData[] | undefined = useSelector((state: AppState) => state.wizard.facades);
        const bottomFacadeMaterial: IFacadeMaterialData | undefined = useSelector((state: AppState) => state.wizard.bottomFacadeMaterial);
        const topFacadeMaterial: IFacadeMaterialData | undefined = useSelector((state: AppState) => state.wizard.topFacadeMaterial);
        const filterRef = useRef<HTMLDivElement>(null);
        const [modalVisible, setModalVisible] = useState<boolean>(false);

        const getSelectOptions = (facades: IFacadeData[]): Option[] => {
            let options: Option[] = [];
            let facade: IFacadeData;

            for (facade of facades) {
                options.push({
                    value: facade.id,
                    content: facade.title
                })
            }
            return options;
        }
        const getSelectFacade = (facadeMaterial?: IFacadeMaterialData): string => {
            if (facadeMaterial) {
                return facadeMaterial.facade;
            }
            return '';
        }

        const onChangeFacade = (value: string, level: TLevel) => {
            service.setSelectFacade(value, level, true);
        }
        if (!facades) {
            return null;
        }

        return (
            <div className={cnModulesCatalogFilter({}, [className])}>
                <div className={cnModulesCatalogFilter('Title')} ref={filterRef}>
                    {topFacadeMaterial ?
                        <div className={cnModulesCatalogFilter('Facade', {level: 'top'})}>
                            <div className={cnModulesCatalogFilter('FacadeTitle')}>{t('Верхние фасады')}:</div>
                            <div className={cnModulesCatalogFilter('FacadeValue')}>{t(topFacadeMaterial.title)}</div>
                        </div> : null}
                    {bottomFacadeMaterial ?
                        <div className={cnModulesCatalogFilter('Facade', {level: 'bottom'})}>
                            <div className={cnModulesCatalogFilter('FacadeTitle')}>{t('Нижние фасады')}:</div>
                            <div className={cnModulesCatalogFilter('FacadeValue')}>{t(bottomFacadeMaterial.title)}</div>
                        </div> : null}
                </div>
                <div className={cnModulesCatalogFilter('ButtonFilterWrapper')} ref={filterRef}>
                    <Button
                        className={cnModulesCatalogFilter('ButtonFilter')}
                        type={'button'}
                        leftIcon={{path: 'filter', width: 20, height: 20}}
                        mods={ modalVisible ?
                            {
                                mods: {bkg: 'white', color: 'red', fill: 'red', border: 'red'},
                            } :
                            {
                                mods: {bkg: 'white', fill: 'lightBlack', border: 'lightBlack'},
                                hover: {mods: {bkg: 'white', color: 'red', fill: 'red', border: 'red'}}
                            }}
                        callBack={() => {
                            setModalVisible(!modalVisible)
                        }}
                    />
                </div>

                <Popup anchor={filterRef}
                       visible={modalVisible}
                       target="anchor"
                       view="default"
                       onClose={() => {setModalVisible(false)}} hasTail={true}>
                    <div className={cnModulesCatalogFilter('Modal')}>
                        <div className={cnModulesCatalogFilter('SelectFacade', {level: 'top'})}>
                            <div>{t('Верхние фасады')}</div>
                            <Select
                                className={cnModulesCatalogFilter('Select')}
                                placeholder={t('Выбрать коллекцию')}
                                showPlaceholder={false}
                                options={getSelectOptions(facades)}
                                value={getSelectFacade(topFacadeMaterial)}
                                opened={false}
                                callBack={(event: ChangeEvent<HTMLSelectElement>) => {
                                    onChangeFacade(event.target.value, LEVEL_TOP)}}
                            />
                        </div>
                        <div className={cnModulesCatalogFilter('SelectFacade', {level: 'bottom'})}>
                            <div>{t('Нижние фасады')}</div>
                            <Select
                                className={cnModulesCatalogFilter('Select')}
                                placeholder={t('Выбрать коллекцию')}
                                showPlaceholder={false}
                                options={getSelectOptions(facades)}
                                value={getSelectFacade(bottomFacadeMaterial)}
                                opened={false}
                                callBack={(event: ChangeEvent<HTMLSelectElement>) => {
                                    onChangeFacade(event.target.value, LEVEL_BOTTOM)}}
                            />
                        </div>

                        <div className={cnModulesCatalogFilter('ModalButtons')}>
                            <Button
                                className={cnModulesCatalogFilter(cnButton())}
                                type={'button'}
                                text={t('Ок') || ''}
                                mods={{
                                    mods: {bkg: 'white', color: 'red', border: 'red'},
                                }}
                                callBack={() => {setModalVisible(false)}}
                            />
                        </div>
                    </div>
                </Popup>
            </div>
        );
    }