import React, {RefObject} from 'react';
import {IClassNameProps} from "@bem-react/core";
import './Wizard-Room.css'
import {IProjectData} from '../../../../../common-code/interfaces/project/IProjectData';
import {ISaveRoomData} from '../../../../../common-code/interfaces/saveData/ISaveRoomData';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {cnWizard} from '../Wizard';
import {RoomParams} from '../../RoomParams/RoomParams';

export interface IWizardRoomProps extends IClassNameProps {
    projectData: IProjectData;
    roomData: ISaveRoomData;
    service: KitchenService;
    containerRef: RefObject<HTMLDivElement>;
}

export const WizardRoom: React.FC<IWizardRoomProps> =
    ({
         projectData,
         roomData,
         service,
         containerRef
     }) => {
        return (
            <div className={cnWizard('Room', {})}>
                <RoomParams service={service} roomData={roomData} projectData={projectData} containerRef={containerRef}/>
            </div>
        );
    };