
import { LEVEL_BOTTOM, LEVEL_TOP } from "common-code/lib/constants";
import { IFacadeMaterialData } from "common-code/lib/interfaces/materials/IFacadeMaterialData";
export const levels = [
    { value: LEVEL_BOTTOM, content: "Нижний уровень" },
    { value: LEVEL_TOP, content: "Верхний уровень" },
  ];
  
export const initialErrors = {
    title: "",
    facade: "",
    material: "",
};

export const defaultValues = (item: IFacadeMaterialData | null) =>{
       
    return item ? {
        id: item.id,
        title: item.title,
        facade: item.facade,
        material: item.material,
        isMaterial2: !!item.material2,
        material2: item.material2,
        isCorpusMaterial: !!item.corpusMaterial,
        corpusMaterial: item.corpusMaterial || "",
        image: item.image || '',
        imageNew: null,
        isLevel: !!item.level,
        level: item.level || undefined,
        isTopDefault: item.isTopDefault || false,
        isBottomFlipY: item.isBottomDefault || false,
        isCustom: !!item.isCustom, 
        canChange: !!item.canChange,
    } : {
        id: '',
        title: '' ,
        facade: '', 
        material: '',
        isMaterial2: false,
        material2: '',
        isCorpusMaterial:  false,
        corpusMaterial:  "",
        image: '',
        imageNew: null,
        isLevel: false,
        level: undefined,
        isTopDefault: false,
        isBottomDefault: false,
        isCustom: false, 
        canChange: false,
    }
}
