import {ThreeCorpus} from "../ThreeCorpus";
import {BoxGeometry, Vector3} from "three";
import {BOTTOM_UNIT_TOP_PANEL_WIDTH_V2} from "../../../../../constants";

export class ThreeBottomSinkOpenedCorpus extends ThreeCorpus {
    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth()),
            'left',
            new Vector3(
                -this.getWidth()/2 + this.getThickness() / 2,
                0,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getThickness(), this.getHeight(), this.getDepth()),
            'right',
            new Vector3(
                this.getWidth()/2 - this.getThickness() / 2,
                0,
                0
            )
        );
        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, BOTTOM_UNIT_TOP_PANEL_WIDTH_V2, this.getThickness()),
            'back',
            new Vector3(
                0,
                0,
                -this.getDepth()/2 + this.getThickness()/2
            )
        );
    }

    protected createBackPanels() {
    }
}