import React from 'react';
import { cn } from "@bem-react/classname";
import { HexColorInput, HexColorPicker } from 'react-colorful';
import './ColorPicker.css';

export const cnColorPicker = cn('ColorPicker');

function ColorPicker({
    color,
    onChange,
}:{
    color: string | undefined,
    onChange: (color: string) => void
}) {
  return (
    <div className={cnColorPicker('')}>
        <section className={cnColorPicker('Section')} >
        <div className={cnColorPicker('Preview')} style={{backgroundColor: color}}></div>
            <HexColorPicker color={color} onChange={onChange} />
        </section>
        <HexColorInput color={color} onChange={onChange} prefixed className={cnColorPicker('Input')} />
    </div>
  )
}

export default ColorPicker