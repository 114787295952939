import {ThreeEquipment} from '../ThreeEquipment/ThreeEquipment';
import {TEquipmentCellName} from '../../../../../../common-code/types/TEquipmentCellName';
import {ThreeKUnit} from '../../units/ThreeKUnit/ThreeKUnit';
import {ISaveBuiltInEquipmentData} from '../../../../../../common-code/interfaces/saveData/ISaveBuiltInEquipmentData';
import {CommonHelper} from 'common-code';
import {
    ACTION_DELETE,
    ACTION_SETTINGS,
    ACTION_SHOW_UNIT_SPEC,
    SETTING_GROUP_GENERAL,
    SETTING_GROUP_POSITION
} from '../../../../../constants';
import {i18n} from '../../../../../i18n';
import {IContextIcon} from '../../../../../interfaces/IContextIcon';
import {Group, Mesh} from 'three';
import {ISettingGroup} from '../../../../../interfaces/settingData/ISettingGroup';
import {ISettingGroupGeneral} from '../../../../../interfaces/settingData/ISettingGroupGeneral';

export class ThreeBuiltInEquipment extends ThreeEquipment {
    saveData: ISaveBuiltInEquipmentData;
    unit: ThreeKUnit;
    hole?: Mesh;

    constructor(options: ISaveBuiltInEquipmentData, unit: ThreeKUnit) {
        super(options, unit.service);
        this.saveData = options;
        this.unit = unit;
        this.canMove = false;
    }

    public createView(isRebuild?: boolean) {
        this.view3d.matrixAutoUpdate = false;
        if (this.coverPoints.length > 0) {
            this.createSelectCover(isRebuild);
        }
        if (this.transparentForBack) {
            this.setTransparentForBack();
        }
        this.createSticker();
        this.setPosition(this.initPosition());
        this.setRotation(this.initRotation());
        this.updateViewType();
        this.updateAllMatrices();
        this.unit.view3d.add(this.view3d);
    }

    public getCellName(): TEquipmentCellName {
        return this.saveData.cellName;
    }

    public getSelectColor() {
        return this.service.getDetailSelectColor();
    }

    // TODO нужно добавить изменение во встраиваемой технике, сейчас это не работает
    public getSettingsGroups(): {[key: string]: ISettingGroup} {
        let groups: {[key: string]: ISettingGroup};
        let generalGroup: ISettingGroup | undefined;
        let generalGroupData: ISettingGroupGeneral | undefined;

        groups = super.getSettingsGroups();
        generalGroup = groups[SETTING_GROUP_GENERAL];
        if (generalGroup && generalGroup.data) {
            generalGroupData = generalGroup.data as ISettingGroupGeneral;
        }
        if (generalGroupData) {
            delete generalGroupData.isStick;
            delete generalGroupData.isDimensions;
            delete generalGroupData.isLevelStick;
            delete generalGroupData.isWallStick;
            delete generalGroupData.selfVerticalRotation;
            generalGroupData.parentUnit = this.unit.getId();
        }
        delete groups[SETTING_GROUP_POSITION];

        return groups;
    }

    public getData(): ISaveBuiltInEquipmentData {
        let data: ISaveBuiltInEquipmentData = CommonHelper.deepCopy(super.getData());

        delete data.isStick;
        delete data.isWallStick;
        delete data.isLevelStick;

        return data;
    }

    public getContextIcons(): IContextIcon[] {
        let icons: IContextIcon[];
        let actionData = this.actionData();

        icons = [
            {
                channelName: "ThreeKUnit",
                action: ACTION_SETTINGS,
                actionData: actionData,
                popup: true,
                icon: "settings-object",
                hide: false,
                title: i18n.t("Свойства"),
                sort: 100,
            },
            {
                channelName: "ThreeUnit",
                action: ACTION_SHOW_UNIT_SPEC,
                actionData: actionData,
                popup: true,
                icon: "list",
                hide: false,
                title: i18n.t("Спецификация"),
                sort: 130,
            },
            {
                channelName: 'CommonObject',
                action: ACTION_DELETE,
                actionData: actionData,
                popup: false,
                icon: 'delete',
                hide: true,
                title: i18n.t('Удалить'),
                sort: 500
            }
        ];

        return icons;
    }

    protected createSelectCover(isRebuild?: boolean) {
        super.createSelectCover(isRebuild);
        this.selectCover.userData.commonObject = this;
    }

    protected setTabletopHole() {
        this.threeModel.traverse(child => {
            if (child instanceof Mesh) {
                if (child.name === 'hole') {
                    child.material.colorWrite = false;
                    child.renderOrder = 1;
                    child.userData.notSwitchView = true;
                    child.userData.notCreateCarcass = true;
                    this.hole = child;
                }
            }
        })
        if (!this.hole) {
            const hole: Mesh | undefined = this.createTabletopHole(this.threeModel);
            if (hole) {
                this.threeModel.add(hole);
                this.hole = hole;
            }
        }
    }

    protected createTabletopHole(threeModel: Group): Mesh | undefined {
        console.log('Need create hole ', threeModel.name);
        return undefined;
        // let hole: Mesh | undefined;
        // let resultGeometry: BufferGeometry = new BufferGeometry();
        // try {
        //     threeModel.traverse(child => {
        //         if (child instanceof Mesh) {
        //             resultGeometry = child.geometry.clone().merge(resultGeometry);
        //         }
        //     })
        //     resultGeometry.scale(0.99, 0.99, 0.99);
        //     hole = new Mesh(resultGeometry, new MeshBasicMaterial());
        //     (hole.material as MeshBasicMaterial).colorWrite = false;
        //     hole.renderOrder = 1;
        //     hole.userData.notSwitchView = true;
        //     hole.userData.notCreateCarcass = true;
        // } catch (e) {
        //     debugger;
        //     return undefined;
        // }
        //
        // return hole;
    }

}