import React, {ChangeEvent, useState} from 'react'
import {withBemMod} from '@bem-react/core'
import {OPTION_TYPE_TEXT} from '../../../../../common-code/constants';
import {Textinput} from '@yandex/ui/Textinput/desktop/bundle';
import {IObjectOptionProps} from '../ObjectOption';
import {useTranslation} from 'react-i18next';
import {IOptionText} from '../../../../../common-code/interfaces/option/IOptionText';
import {cnObjectOption} from '../index';


export interface IObjectOptionTypeTextProps extends IObjectOptionProps {
    type?: typeof OPTION_TYPE_TEXT;
    option?: IOptionText;
    defaultValue?: string;
}

const ObjectOptionTypeText: React.FC<IObjectOptionTypeTextProps> =
    ({
         option,
         group,
         className,
         onChange,
         defaultValue
     }) => {
        const [value, setValue] = useState<string>(defaultValue !== undefined ? defaultValue : (option && option.defaultValue ? option.defaultValue : ''));
        const {t} = useTranslation();

        if (!option || !onChange) {
            return null;
        }

        const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
            onChange(option.id, event.target.value, group ? group.id : undefined);
            setValue(event.target.value);
        }

        return (
            <div className={cnObjectOption({}, [className])}>
                <div className={cnObjectOption('Title', [className+'Title'])}>
                    {t(option.title)}
                </div>
                <div className={cnObjectOption('Value', [className+'Value'])}>
                    <Textinput
                        className={cnObjectOption('Textinput', [className])}
                        placeholder={t(option.title)}
                        value={value}
                        minLength={3}
                        debounceTimeout={300}
                        onChange={onChangeInput}
                    />
                </div>
            </div>
        );
    }

export const withObjectOptionTypeText = withBemMod<IObjectOptionTypeTextProps, IObjectOptionProps>(
    cnObjectOption(),
    {type: OPTION_TYPE_TEXT},
    () => ObjectOptionTypeText
);