import React from 'react';
import {IClassNameProps} from '@bem-react/core';
import './Wizard-HorizontalMenu.css'
import {cn} from '@bem-react/classname';
import {KitchenService} from '../../../../3d/services/KitchenService/KitchenService';
import {useDispatch} from 'react-redux';
import {Button, cnButton} from '../../../components/Button';
import {useTranslation} from 'react-i18next';
import {HIDE_LOADING, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS, SHOW_LOADING} from '../../../../constants';
import {useUserRole} from '../../../../hooks/useUserRole';
import {TUserRole} from '../../../../../common-code/types/TUserRole';
import {ROLE_ADMIN} from '../../../../../common-code/constants';

export const cnWizardHorizontalMenu = cn('WizardHorizontalMenu');

export interface IWizardHorizontalMenuProps extends IClassNameProps {
    service: KitchenService;
}

export const WizardHorizontalMenu: React.FC<IWizardHorizontalMenuProps> = ({className, service}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const userRole: TUserRole = useUserRole();

    const copyProject = () => {
        dispatch({type: SHOW_LOADING});
        service.sendProjectToSave(false, true, true).then(projectId => {
            dispatch({
                type: HIDE_LOADING
            });
            service.showMessage({
                type: MESSAGE_TYPE_SUCCESS,
                message: t('Проект успешно скопирован') + ' №' + projectId,
                autoClose: true
            });
        }).catch(() => {
            dispatch({
                type: HIDE_LOADING
            });
            service.showMessage({
                type: MESSAGE_TYPE_ERROR,
                message: t('При копировании проекта произошла ошибка'),
                autoClose: true
            });
        });
    }

    const disableCatalog = () => {
        service.disableCatalog();
    }

    const saveProject = (isTemplate?: boolean) => {
        dispatch({type: SHOW_LOADING});
        service.sendProjectToSave(isTemplate, undefined, true).then(projectId => {
            dispatch({type: HIDE_LOADING});
            dispatch({
                type: MESSAGE_TYPE_SUCCESS,
                message: isTemplate ? t('Шаблон проекта успешно сохранен') + ' №' + projectId : t('Проект успешно сохранен') + ' №' + projectId,
                autoClose: true
            });
        }).catch(() => {
            dispatch({
                type: HIDE_LOADING
            });
            dispatch({
                type: MESSAGE_TYPE_ERROR,
                message: isTemplate ?
                    t('При сохранении шаблона проекта произошла ошибка') : t('При сохранении проекта произошла ошибка'),
                autoClose: true
            });
        });
    }

    return (
        <div className={cnWizardHorizontalMenu({}, [className])}>
            <Button
                className={cnWizardHorizontalMenu(cnButton())}
                leftIcon={{path: 'copy2', width: 20, height: 20, marginRight: 8}}
                text={t('Копировать') || undefined}
                callBack={copyProject}
                mods={{
                    mods: {
                        color: 'black',
                        bkg: 'white',
                        border: 'black',
                        fill: 'black'
                    },
                }}
                type={'button'}
            />
            <Button
                className={cnWizardHorizontalMenu(cnButton())}
                leftIcon={{path: 'save', width: 20, height: 20, marginRight: 8}}
                text={t('Сохранить') || undefined}
                callBack={() => {saveProject()}}
                mods={{
                    mods: {
                        color: 'black',
                        bkg: 'white',
                        border: 'black',
                        fill: 'black'
                    },
                }}
                type={'button'}
            />
            {[ROLE_ADMIN].includes(userRole) ? <Button
                className={cnWizardHorizontalMenu(cnButton())}
                leftIcon={{path: 'save', width: 20, height: 20, marginRight: 8}}
                text={t('Пересчитать каталог') || undefined}
                callBack={disableCatalog}
                mods={{
                    mods: {
                        color: 'black',
                        bkg: 'white',
                        border: 'black',
                        fill: 'black'
                    },
                }}
                type={'button'}
            /> : null}
            {/*{[ROLE_ADMIN, ROLE_MANAGER].includes(userMod) ? <Button*/}
            {/*    className={cnWizardHorizontalMenu(cnButton())}*/}
            {/*    leftIcon={{path: '3d-model', width: 20, height: 20, marginRight: 8}}*/}
            {/*    text={t('Сохранить шаблон') || undefined}*/}
            {/*    callBack={() => {saveProject(true)}}*/}
            {/*    mods={{*/}
            {/*        mods: {*/}
            {/*            color: 'black',*/}
            {/*            bkg: 'white',*/}
            {/*            border: 'black',*/}
            {/*            fill: 'black'*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    type={'button'}*/}
            {/*/> : null}*/}
        </div>
    );
};