import {ThreeUnit} from "../../ThreeUnit/ThreeUnit";
import {KitchenService} from "../../../../services/KitchenService/KitchenService";
import {ThreeCorpus} from "../../details/ThreeCorpus/ThreeCorpus";
import {IContextIcon} from "../../../../../interfaces/IContextIcon";
import {i18n} from "../../../../../i18n";
import {
    ACTION_COPY,
    ACTION_REPLACE,
    MESSAGE_TYPE_WARNING,
    SETTING_GROUP_EQUIPMENTS,
} from "../../../../../constants";
import {Box3, Vector3} from "three";
import {ThreeFacade} from "../../details/ThreeFacade/ThreeFacade";
import {TEquipmentCellName} from "../../../../../../common-code/types/TEquipmentCellName";
import {ThreeBuiltInEquipment} from "../../equipments/ThreeBuiltInEquipment/ThreeBuiltInEquipment";
import {
    CATALOG_CALCULATE_TYPE_MODULE,
    CLASSNAME_EQUIPMENT_BUILTIN_DISHWASHER,
    CLASSNAME_EQUIPMENT_BUILTIN_EXTRACT,
    CLASSNAME_EQUIPMENT_BUILTIN_SINK,
    CLASSNAME_EQUIPMENT_BUILTIN_WASHER,
    CLASSNAME_EQUIPMENT_HOB,
    CLASSNAME_EQUIPMENT_MOUNTED_EXTRACT,
    CLASSNAME_EQUIPMENT_OVEN,
    CLASSNAME_EQUIPMENT_SEPARATE_SINK,
    EQUIPMENT_CELL_NAME_TABLETOP,
    NONE_MATERIAL,
    OPTION_TYPE_SELECT,
    PRICE_CELL_CORPUS,
    PRICE_CELL_FURNITURE,
} from "../../../../../../common-code/constants";
import {ThreeBuiltInSinkEquipment} from "../../equipments/ThreeBuiltInEquipment/types/ThreeBuiltInSinkEquipment";
import {ThreeSeparateSinkEquipment} from "../../equipments/ThreeBuiltInEquipment/types/ThreeSeparateSinkEquipment";
import {ThreeHobEquipment} from "../../equipments/ThreeBuiltInEquipment/types/ThreeHobEquipment";
import {ThreeOvenEquipment} from "../../equipments/ThreeBuiltInEquipment/types/ThreeOvenEquipment";
import {ThreeBuiltinExtractEquipment} from "../../equipments/ThreeBuiltInEquipment/types/ThreeBuiltinExtractEquipment";
import {ThreeMountedExtractEquipment} from "../../equipments/ThreeBuiltInEquipment/types/ThreeMountedExtractEquipment";
import {TClassName} from "../../../../../../common-code/types/TClassName";
import {ISaveKUnitData} from "../../../../../../common-code/interfaces/saveData/ISaveKUnitData";
import {ISaveUnitData} from "../../../../../../common-code/interfaces/saveData/ISaveUnitData";
import {CommonHelper} from "common-code";
import {ICorpusPriceParams} from "../../../../../../common-code/interfaces/catalog/ICorpusPriceParams";
import {IFurniturePriceParams} from "../../../../../../common-code/interfaces/catalog/IFurniturePriceParams";
import {IMaterialData} from "../../../../../../common-code/interfaces/materials/IMaterialData";
import {IModulePriceParams} from "../../../../../../common-code/interfaces/catalog/IModulePriceParams";
import {IModulePriceData} from "../../../../../../common-code/interfaces/catalog/IModulePriceData";
import {
    BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT,
    COVER_CORRECTION_SIZE,
    HISTORY_STATE_TYPE_CHANGE,
} from "../../../../constants";
import {ISaveBuiltInEquipmentData} from "../../../../../../common-code/interfaces/saveData/ISaveBuiltInEquipmentData";
import {ICreateObjectData} from "../../../../../../common-code/interfaces/createData/ICreateObjectData";
import {ISettingGroup} from "../../../../../interfaces/settingData/ISettingGroup";
import {ISettingEquipment} from "../../../../../interfaces/settingData/ISettingEquipment";
import {ISaveCorpusData} from "../../../../../../common-code/interfaces/saveData/ISaveCorpusData";
import {IOptionCorpusMaterial} from "../../../../../../common-code/interfaces/option/IOptionCorpusMaterial";
import {
    ThreeBuiltinDishwasherEquipment
} from "../../equipments/ThreeBuiltInEquipment/types/ThreeBuiltinDishwasherEquipment";
import {ISaveBoxData} from "../../../../../../common-code/interfaces/saveData/ISaveBoxData";
import {IHistoryChangeObjectsState} from "../../../../interfaces/history/IHistoryChangeObjectsState";
import {TSizes} from "../../../../../../common-code/types/geometry/TSizes";
import {IOfferPriceParam} from "../../../../../../common-code/interfaces/catalog/IOfferPriceParam";
import {IProjectOffers} from "../../../../../../common-code/interfaces/project/IProjectOffers";
import {KitchenHelper} from "common-code";
import {ThreeBuiltinWasherEquipment} from "../../equipments/ThreeBuiltInEquipment/types/ThreeBuiltinWasherEquipment";
import {TOptionalPoint3D} from "../../../../../../common-code/types/TOptionalPoint3D";
import {TKitchenView} from '../../../../../types/TKitchenView';
import {IMaterialTextures} from '../../../../interfaces/IMaterialTextures';

export class ThreeKUnit extends ThreeUnit {
    saveData: ISaveKUnitData;
    equipments: ThreeBuiltInEquipment[];
    corpus: ThreeCorpus;
    facades: ThreeFacade[];

    constructor(options: ISaveKUnitData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.equipments = [];
        this.corpus = this.initCorpus(options.corpus);
        this.facades = [];
    }

    public removeChildren() {
        this.corpus.remove();
        this.removeEquipments();
        super.removeChildren();
    }

    public getData(): ISaveKUnitData {
        let data: ISaveKUnitData;
        let parentData: ISaveUnitData;
        parentData = super.getData();
        data = CommonHelper.deepCopy(parentData);
        data.corpus = this.corpus.getData();
        data.equipments = this.getEquipmentsData();

        return data;
    }

    public initState(isRebuild?: boolean) {
        this.createCorpus(isRebuild);
        super.initState(isRebuild);
        this.createEquipments();
    }

    public createView(isRebuild?: boolean) {
        super.createView(isRebuild);
        this.initAvailableEquipments();
    }

    public setViewType(viewType: TKitchenView) {
        super.setViewType(viewType);
        if (this.equipments) {
            let equipment: ThreeBuiltInEquipment;

            for (equipment of this.equipments) {
                equipment.setViewType(viewType);
            }
        }
    }

    public createViewEDIT(isRebuild?: boolean) {
        super.createViewEDIT(isRebuild);
        // this.initAvailableEquipments();
    }

    public getExtraOffersPriceParams(): IOfferPriceParam[] | undefined {
        let offersParams: IOfferPriceParam[] | undefined;
        let equipment: ThreeBuiltInEquipment;
        let extraOffers: IProjectOffers | undefined;
        let index: string;

        offersParams = super.getExtraOffersPriceParams();
        if (this.equipments) {
            for (equipment of this.equipments) {
                extraOffers = equipment.getExtraOffers();
                if (extraOffers) {
                    for (index in extraOffers) {
                        if (!offersParams) {
                            offersParams = [];
                        }
                        offersParams.push({
                            id: extraOffers[index].id,
                            count: extraOffers[index].count,
                        });
                    }
                }
            }
        }

        return offersParams;
    }

    public showOnlyFacades() {
        this.corpus.remove();
        this.removeEquipments();
        super.showOnlyFacades();
    }

    public hasBoxes(): boolean {
        return this.corpus.hasBoxes();
    }

    public getBoxes(): ISaveBoxData[] {
        return this.corpus.getBoxes();
    }

    public getCorpusMaterial(): IMaterialData {
        return this.corpus.materialData;
    }

    public getCorpusMaterialTextures(): IMaterialTextures {
        return this.corpus.materialTextures;
    }

    public getCorpusShapeMaterialTextures(): IMaterialTextures {
        return this.corpus.shapeMaterialTextures;
    }

    public getCorpusMaterialId(): string {
        return this.corpus.materialData.id;
    }

    protected getCorpusMaterialSettings(
        createObjectData: ICreateObjectData
    ): IOptionCorpusMaterial | undefined {
        return this.service.calculateCreateObjectCorpusColors(
            createObjectData,
            undefined,
            this.getCorpusMaterialId()
        );
    }

    public getFrameMaterialId(): string | undefined {
        return this.corpus.getFrameMaterialId();
    }

    public getPanelMaterialId(): string | undefined {
        return this.corpus.getPanelMaterialId();
    }

    public getCorpusPriceParams(): ICorpusPriceParams | undefined {
        let corpusPriceParams: ICorpusPriceParams | undefined;

        if (this.getCalculateType() === CATALOG_CALCULATE_TYPE_MODULE) {
            return undefined;
        }
        let corpusMaterialId: string;
        let corpusSizes: TSizes;
        let goodData: IFurniturePriceParams;

        corpusMaterialId = this.getCorpusMaterialId();
        if (corpusMaterialId === NONE_MATERIAL) {
            return undefined;
        }
        corpusSizes = this.getCorpusSizes();

        corpusPriceParams = {
            material: corpusMaterialId,
            height: corpusSizes.height,
            depth: corpusSizes.width,
            width: corpusSizes.length,
            catalogCode: this.corpus.getCatalogCode(),
            frameMaterial: this.corpus.getFrameMaterialId(),
            panelMaterial: this.corpus.getPanelMaterialId(),
            part: this.corpus.getOrderPart(),
            unitId: this.getId(),
            cell: PRICE_CELL_CORPUS,
            canStretch: this.canStretch(),
            isStretch: this.isStretch(),
            sideType: this.getSideType(),
        };
        if (corpusPriceParams && this.saveData.corpus.furniture) {
            corpusPriceParams.furniture = [];
            for (goodData of this.saveData.corpus.furniture) {
                corpusPriceParams.furniture.push({
                    height: KitchenHelper.calculateSizeByParent(
                        goodData.height,
                        corpusPriceParams.height,
                        this.service.getDataForSizeByParent()
                    ),
                    depth: KitchenHelper.calculateSizeByParent(
                        goodData.depth,
                        corpusPriceParams.depth,
                        this.service.getDataForSizeByParent()
                    ),
                    width: KitchenHelper.calculateSizeByParent(
                        goodData.width,
                        corpusPriceParams.width,
                        this.service.getDataForSizeByParent()
                    ),
                    catalogCode: goodData.catalogCode,
                    functionalType: goodData.functionalType,
                    count: goodData.count,
                    kitCode: goodData.kitCode,
                    kitAmount: goodData.kitAmount,
                    furnitureType: goodData.furnitureType,
                    part: goodData.part,
                    unitId: this.getId(),
                    cell: PRICE_CELL_FURNITURE,
                    canStretch: this.canStretch(),
                    isStretch: this.isStretch(),
                });
            }
        }

        return corpusPriceParams;
    }

    public getCorpusSizeItemValue(id: string): number | undefined {
        switch (id) {
            case 'width':
                return this.corpus.getWidth();
            case 'height':
                return this.corpus.getHeight();
            case 'depth':
                return this.corpus.getDepth();
        }
    }

    public trySetCorpusMaterial(corpusMaterial: IMaterialData, notCheckEnable?: boolean): boolean {
        let newPriceParams: IModulePriceParams;
        let newModulePrice: IModulePriceData;

        newPriceParams = this.service.calculateUnitPriceParams(this);
        newPriceParams.corpusMaterial = corpusMaterial.id;
        if (newPriceParams.corpus) {
            if (newPriceParams.corpus.material !== NONE_MATERIAL) {
                newPriceParams.corpus.material = corpusMaterial.id;
            }
        }

        newModulePrice = this.service.calculatePrice(newPriceParams);
        if (!notCheckEnable && !this.service.checkCalculatePrice(newPriceParams, newModulePrice)) {
            this.service.showMessage({
                type: MESSAGE_TYPE_WARNING,
                message: i18n.t('Не удалось сменить корпус - не найден товар в каталоге'),
                params: { id: 'notSetCorpusMaterial' }
            });
            return false;
        }
        this.saveData.offerId = newModulePrice.id;
        this.saveData.modulePrice = newModulePrice;
        this.corpus.setMaterial(corpusMaterial);

        return true;
    }

    public getSpecName(): string {
        let name: string;

        name = super.getSpecName();
        name += ' ' + i18n.t('цвет корпуса') + ': ' + i18n.t(this.corpus.materialData.title);
        if (this.facades && this.facades[0]) {
            name += ' ' + i18n.t('фасад') + ': ' + i18n.t(this.facades[0].facadeMaterialData.title);
        }

        return name;
    }

    public isStretch(): boolean {
        return this.saveData.isStretch === true;
    }

    public getContextIcons(): IContextIcon[] {
        let icons: IContextIcon[];
        let actionData = this.actionData();

        icons = [
            {
                channelName: "ThreeKUnit",
                action: ACTION_COPY,
                actionData: actionData,
                popup: false,
                icon: "copy-object",
                hide: true,
                title: i18n.t("Копировать"),
                sort: 110,
            },
            {
                channelName: "ThreeKUnit",
                action: ACTION_REPLACE,
                actionData: actionData,
                popup: false,
                icon: "replace",
                hide: true,
                title: i18n.t("Заменить"),
                sort: 120,
            },
        ];
        icons = icons.concat(super.getContextIcons());

        return icons;
    }

    public getCorpusCoverBox(gap: number = COVER_CORRECTION_SIZE): Box3 {
        return this.corpus.getCoverBox(gap);
    }

    public getCorpusSizes(): TSizes {
        return this.corpus.getSizes();
    }

    public getCorpusPosition(): Vector3 {
        return this.corpus.view3d.position.clone();
    }

    public isVisibleTabletops(): boolean {
        let equipment: ThreeBuiltInEquipment;

        if (this.equipments) {
            for (equipment of this.equipments) {
                if (equipment.getClassName() === CLASSNAME_EQUIPMENT_SEPARATE_SINK) {
                    return false;
                }
            }
        }

        return true;
    }

    public createEquipment(
        equipmentData: ISaveBuiltInEquipmentData,
        setState?: boolean
    ) {
        let equipment: ThreeBuiltInEquipment | undefined;
        let cover: Box3;
        let createEquipments: ICreateObjectData[];
        let createEquipment: ICreateObjectData | undefined;
        let defaultEquipmentData: ISaveBuiltInEquipmentData;
        let index: keyof ISaveBuiltInEquipmentData;
        let oldData: ISaveUnitData;
        let newData: ISaveUnitData;
        let historyState: IHistoryChangeObjectsState;
        let corpusSizes: TSizes;

        if (!equipmentData.uid || equipmentData.uid === "") {
            if (equipmentData.className) {
                createEquipments = this.service.getCreateEquipmentsFromClassName(
                    equipmentData.className
                );
                if (createEquipments.length > 0) {
                    createEquipment = createEquipments[0];
                }
            }
            if (!createEquipment && equipmentData.catalogCode) {
                createEquipment = this.service.getCreateUnitByCatalogCode(
                    equipmentData.catalogCode
                );
            }
            if (createEquipment) {
                defaultEquipmentData = this.service.getDefaultOptions(createEquipment);
                for (index in defaultEquipmentData) {
                    // @ts-ignore
                    equipmentData[index] = defaultEquipmentData[index];
                }
            }
        }
        if (!this.checkCreateEquipment(equipmentData.className)) {
            return;
        }
        oldData = this.getData();
        this.deleteDuplicateEquipments(equipmentData.cellName);

        switch (equipmentData.className) {
            case CLASSNAME_EQUIPMENT_BUILTIN_SINK:
                if (!equipmentData.sizes) {
                    equipmentData.sizes = {
                        length: this.getWidth() - 100,
                        height: 300,
                        width: this.getDepth() - 100,
                    };
                }
                equipment = new ThreeBuiltInSinkEquipment(equipmentData, this);
                break;
            case CLASSNAME_EQUIPMENT_SEPARATE_SINK:
                if (!equipmentData.sizes) {
                    cover = this.getCoverBox();
                    equipmentData.sizes = {
                        length: this.getWidth(),
                        height: 300,
                        width: cover.max.z - cover.min.z,
                    };
                }
                equipment = new ThreeSeparateSinkEquipment(equipmentData, this);
                break;
            case CLASSNAME_EQUIPMENT_HOB:
                if (!equipmentData.sizes) {
                    cover = this.getCoverBox();
                    equipmentData.sizes = {
                        length: this.getWidth() > 600 ? 590 : this.getWidth() - 80,
                        height: 100,
                        width: cover.max.z - cover.min.z - 80,
                    };
                }
                equipment = new ThreeHobEquipment(equipmentData, this);
                break;
            case CLASSNAME_EQUIPMENT_OVEN:
                if (!equipmentData.sizes) {
                    corpusSizes = this.getCorpusSizes();
                    equipmentData.sizes = {
                        length: corpusSizes.length - 4,
                        height: 600,
                        width: corpusSizes.width,
                    };
                }
                equipment = new ThreeOvenEquipment(equipmentData, this);
                break;
            case CLASSNAME_EQUIPMENT_BUILTIN_DISHWASHER:
                corpusSizes = this.getCorpusSizes();
                if (!equipmentData.sizes) {
                    equipmentData.sizes = {
                        length: corpusSizes.length - 32,
                        height: corpusSizes.height,
                        width: corpusSizes.width,
                    };
                }
                equipment = new ThreeBuiltinDishwasherEquipment(equipmentData, this);
                break;
            case CLASSNAME_EQUIPMENT_BUILTIN_WASHER:
                corpusSizes = this.getCorpusSizes();
                if (!equipmentData.sizes) {
                    equipmentData.sizes = {
                        length: corpusSizes.length - 50,
                        height: corpusSizes.height - 50,
                        width: corpusSizes.width - 50,
                    };
                }
                equipment = new ThreeBuiltinWasherEquipment(equipmentData, this);
                break;
            case CLASSNAME_EQUIPMENT_BUILTIN_EXTRACT:
                if (!equipmentData.sizes) {
                    cover = this.getCoverBox();
                    equipmentData.sizes = {
                        length: this.getWidth() - 32,
                        height: 720,
                        width: this.getDepth(),
                    };
                }
                equipment = new ThreeBuiltinExtractEquipment(equipmentData, this);
                break;
            case CLASSNAME_EQUIPMENT_MOUNTED_EXTRACT:
                if (!equipmentData.sizes) {
                    cover = this.getCoverBox();
                    equipmentData.sizes = {
                        length: this.getWidth() - 32,
                        height: 100,
                        width: this.getDepth(),
                    };
                }
                equipment = new ThreeMountedExtractEquipment(equipmentData, this);
                break;
            default:
                debugger;
                break;
        }
        if (equipment) {
            equipment.initState();
            equipment.createView();
            this.equipments.push(equipment);
            newData = this.getData();
            if (setState && !CommonHelper.deepCompare(oldData, newData)) {
                historyState = {
                    type: HISTORY_STATE_TYPE_CHANGE,
                    data: {
                        objects: [{oldData: oldData, newData: this.getData()}]
                    }
                }
                this.service.setHistoryState(historyState);
            }
        }
    }

    public deleteDuplicateEquipments(cellName: TEquipmentCellName): boolean {
        let equipment: ThreeBuiltInEquipment;
        let isDelete = false;

        for (equipment of this.equipments) {
            if (equipment.getCellName() === cellName) {
                this.deleteEquipment(equipment.getId());
                isDelete = true;
            }
        }

        return isDelete;
    }

    public deleteEquipment(
        equipmentId: number
    ): ISaveBuiltInEquipmentData | undefined {
        let index;
        let equipmentData: ISaveBuiltInEquipmentData;

        for (index in this.equipments) {
            if (this.equipments[index].getId() === equipmentId) {
                equipmentData = this.equipments[index].getData();
                this.equipments[index].remove();
                this.equipments.splice(+index, 1);

                return equipmentData;
            }
        }

        return undefined;
    }

    public getOvenPosition(): Vector3 | undefined {
        let facade: ThreeFacade;
        let facadeBox: Box3;
        let corpusBox: Box3;
        let nextFacadeBox: Box3;
        let intervalId: string;
        let facadeIntervals: { [key: string]: Box3 } = {};
        let facadeIntervalsArray: Box3[];
        let index: number;

        if (this.getWidth() < 450) {
            return undefined;
        }
        for (facade of this.facades) {
            facadeBox = facade.getDummyBox();
            intervalId = facadeBox.min.y + "_" + facadeBox.max.y;
            if (!facadeIntervals[intervalId]) {
                facadeIntervals[intervalId] = facadeBox;
            }
        }
        corpusBox = this.corpus.getCoverBox();
        facadeIntervals[corpusBox.min.y + "_" + corpusBox.min.y] = new Box3(
            corpusBox.min,
            corpusBox.min
        );
        facadeIntervals[corpusBox.max.y + "_" + corpusBox.max.y] = new Box3(
            corpusBox.max,
            corpusBox.max
        );
        facadeIntervalsArray = Object.values(facadeIntervals).sort((a, b) => {
            return a.max.y - b.max.y;
        });
        for (index = 0; index < facadeIntervalsArray.length - 1; index++) {
            facadeBox = facadeIntervalsArray[index];
            nextFacadeBox = facadeIntervalsArray[index + 1];
            if (nextFacadeBox.min.y - facadeBox.max.y >= 600) {
                // позиционирование по переднему краю корпуса (corpusBox.max.z)
                return new Vector3(
                    0,
                    this.integratedHandles
                        ? facadeBox.max.y + 300 + BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT / 2
                        : facadeBox.max.y + 300,
                    corpusBox.max.z
                );
            }
        }

        return undefined;
    }

    public getCellEquipment(
        cellName: TEquipmentCellName
    ): ThreeBuiltInEquipment | undefined {
        let equipment: ThreeBuiltInEquipment;

        for (equipment of this.equipments) {
            if (equipment.getCellName() === cellName) {
                return equipment;
            }
        }

        return undefined;
    }

    public getEquipmentInitPosition(
        cellName: TEquipmentCellName
    ): TOptionalPoint3D {
        let position: TOptionalPoint3D;
        let cover: Box3;

        position = {};
        switch (cellName) {
            case EQUIPMENT_CELL_NAME_TABLETOP:
                cover = this.getCoverBox();
                position.y = cover.max.y;
        }

        return position;
    }

    protected removeEquipments() {
        let equipment: ThreeBuiltInEquipment;

        if (!this.equipments) {
            return;
        }
        for (equipment of this.equipments) {
            equipment.remove();
        }

        this.equipments = [];
    }

    protected getCreateEquipmentsFromClassName(
        className: TClassName
    ): ICreateObjectData[] {
        return this.service.getCreateEquipmentsFromClassName(className);
    }

    protected getSettingEquipments(): ISettingGroup | undefined {
        let group: ISettingGroup | undefined;
        let availableEquipments: TClassName[];
        let className: TClassName;
        let cellEquipment: ThreeBuiltInEquipment | undefined;
        let createEquipments: ICreateObjectData[];
        let createEquipment: ICreateObjectData;
        let createEquipmentData: ISaveBuiltInEquipmentData;
        let settingEquipmentList: { [cellName: string]: ISettingEquipment } = {};

        availableEquipments = this.availableEquipments();
        if (availableEquipments.length <= 0) {
            return undefined;
        }
        for (className of availableEquipments) {
            createEquipments = this.getCreateEquipmentsFromClassName(className);
            if (createEquipments.length) {
                for (createEquipment of createEquipments) {
                    createEquipmentData = this.service.getDefaultOptions(createEquipment);
                    cellEquipment = this.getCellEquipment(createEquipmentData.cellName);
                    if (!settingEquipmentList[createEquipmentData.cellName]) {
                        settingEquipmentList[createEquipmentData.cellName] = {
                            cellName: createEquipmentData.cellName,
                            unitId: this.getId(),
                            active: cellEquipment ? cellEquipment.getData() : undefined,
                            select: {
                                type: OPTION_TYPE_SELECT,
                                id: createEquipmentData.cellName,
                                items: [
                                    {
                                        id: "none",
                                        title: i18n.t("Нет"),
                                    },
                                    {
                                        id: createEquipment.uid,
                                        title: i18n.t(createEquipment.title),
                                    },
                                ],
                                sort: 0,
                                title: i18n.t("equipment-" + createEquipmentData.cellName),
                                value: cellEquipment ? cellEquipment.getUid() : "none",
                            },
                        };
                    } else {
                        settingEquipmentList[
                            createEquipmentData.cellName
                            ].select.items.push({
                            id: createEquipment.uid,
                            title: i18n.t(createEquipment.title),
                        });
                    }
                }
            }
        }
        group = {
            id: SETTING_GROUP_EQUIPMENTS,
            title: i18n.t("Бытовая техника"),
            data: {
                items: Object.values(settingEquipmentList),
            },
        };

        return group;
    }

    protected initAvailableEquipments() {
        if (!this.saveData.availableEquipments) {
            this.saveData.availableEquipments = [];
        }
        if (
            this.getOvenPosition() &&
            this.saveData.availableEquipments.indexOf(CLASSNAME_EQUIPMENT_OVEN) === -1
        ) {
            this.saveData.availableEquipments.push(CLASSNAME_EQUIPMENT_OVEN);
        }
    }

    protected getEquipmentsData(): ISaveBuiltInEquipmentData[] | undefined {
        let equipment: ThreeBuiltInEquipment;
        let equipmentData: ISaveBuiltInEquipmentData[] = [];

        if (!this.equipments) {
            return undefined;
        }

        for (equipment of this.equipments) {
            equipmentData.push(equipment.getData());
        }

        return equipmentData;
    }

    protected calculateChildrenGlobalFrontVector() {
        super.calculateChildrenGlobalFrontVector();
        let facade: ThreeFacade;

        if (this.facades) {
            for (facade of this.facades) {
                facade.calculateGlobalFrontVector();
            }
        }

        this.corpus.calculateGlobalFrontVector();
    }

    protected initCorpus(corpusData: ISaveCorpusData): ThreeCorpus {
        return new ThreeCorpus(CommonHelper.deepCopy(corpusData), this);
    }

    protected createCorpus(isRebuild?: boolean) {
        if (isRebuild && !CommonHelper.deepCompare(this.corpus.saveData, this.saveData.corpus)) {
            this.corpus.saveData = this.saveData.corpus;
            this.corpus.coverPoints = [];
        }
        this.corpus.initState(isRebuild);
        this.corpus.createView(isRebuild);
    }

    protected createEquipments() {
        let equipmentData: ISaveBuiltInEquipmentData;

        if (!this.saveData.equipments) {
            return;
        }

        for (equipmentData of this.saveData.equipments) {
            this.createEquipment(equipmentData);
        }
    }
}
