import React from 'react';
import {IClassNameProps} from "@bem-react/core";
import {cnWizardSpec} from '../WizardSpec';
import {WizardSpecImage} from '../Image/WizardSpec-Image';
import {Button} from '../../../components/Button';
import {useTranslation} from 'react-i18next';
import {TKitchenImages} from '../../../../../common-code/types/TKitchenImages';

export interface IWizardSpecSnapshotProps extends IClassNameProps {
    images: TKitchenImages;
}

export const WizardSpecSnapshot: React.FC<IWizardSpecSnapshotProps> =
    ({
         images,
     }) => {
        const {t} = useTranslation();
        const onShowAspect = () => {

        }
        return (
            <div className={cnWizardSpec('Snapshot')}>
                <WizardSpecImage image={images.visual} type={'visual'}
                                 className={cnWizardSpec('SnapshotImage', {type: 'visual'})}/>
                <WizardSpecImage image={images.sketch} type={'sketch'}
                                 className={cnWizardSpec('SnapshotImage', {type: 'sketch'})}/>
                <div className={cnWizardSpec('ShowAspect')}>
                    <Button
                        className={cnWizardSpec('Button')}
                        leftIcon={{path: 'rotate', width: 20, height: 20, marginRight: 8}}
                        text={t('Сменить ракурс') || undefined}
                        callBack={onShowAspect}
                        mods={{
                            mods: {
                                color: 'black',
                                bkg: 'white',
                                border: 'gray',
                                fill: 'lightBlack'
                            },
                        }}
                        type={'button'}
                    />
                </div>
            </div>
        );
    };