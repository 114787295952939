import {cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import React, {useState} from 'react';
import {TabsMenu} from '@yandex/ui/TabsMenu/desktop/bundle'
import {ITabsPanesPaneProps} from '@yandex/ui/TabsPanes/Pane/TabsPanes-Pane';
import {TabsPanes} from '@yandex/ui/TabsPanes/desktop/bundle'
import {ITabsMenuTabProps} from '@yandex/ui/TabsMenu/Tab/TabsMenu-Tab';
import './Tabs.css';

export const cnTabs = cn('Tabs')

export interface ITabsProps extends IClassNameProps {
    initActiveTab: string;
    tabs: ITabsMenuTabProps[];
    tabsContent: ITabsPanesPaneProps[];
    callbackSetActiveTab?: (value: string) => void;
}

export const Tabs: React.FC<ITabsProps> =
    ({
         className,
         initActiveTab,
         tabs,
         tabsContent,
         callbackSetActiveTab,
    }) => {
    const [activeTab, setActiveTab] = useState(initActiveTab);
    const tabItems = tabs.map(tab => {
        tab.onClick = () => {
            setActiveTab(tab.id);
            if (callbackSetActiveTab) {
                callbackSetActiveTab(tab.id);
            }
        };
        return tab;
    })
    return (
        <div className={cnTabs({}, [className])}>
            <TabsMenu size="m"
                      view="default"
                      layout="horiz"
                      activeTab={activeTab}
                      tabs={tabItems}
            />
            <TabsPanes
                activePane={activeTab}
                panes={tabsContent}
            />
        </div>
    );
}